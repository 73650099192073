import { Injectable } from "@angular/core";
import { LazyDepartments } from "./lazy-departments.service";

@Injectable({
    providedIn: 'root'
})
export class AppData {

    constructor(protected lazyDepartments: LazyDepartments) {}

    get departments() {
        return this.lazyDepartments;
    }
}
