import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DispatchedService } from '@beaconlite/models';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { PunchTask } from './punch-task.interface';
import { Helpers as $helpers } from '@beaconlite/services/helpers.service';

@Component({
  selector: 'app-punch-button',
  templateUrl: './punch-button.component.html',
  styleUrls: ['./punch-button.component.scss']
})
export class PunchButtonComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('punchButton') punchButtonElement: ElementRef<any>;
  @ViewChild('punchButtonListWrapper') punchButtonListWrapperElement: ElementRef<any>;
  @ViewChild('outer') outerElement: ElementRef<any>;
  @ViewChild('inner') innerElement: ElementRef<any>;
  @ViewChild('loader') loaderElement: ElementRef<any>;

  @Input() projects: any[] = [];           
  @Input() tasks: PunchTask[];
  @Input() preselectedProject: any;
  @Input() preselectedTask: string;
  @Input() currentProject: any;
  @Input() currentTask: string;    
  @Input() radius: number = 23;        
  @Input() callback: (selection: DispatchedService) => Promise<void>; 

  selection: any = {};
  endTask: PunchTask = null;
  
  skipProjectSelection: boolean;
  skipTaskSelection: boolean;
  isActive = false;
  selectorShown = false;
  isPunching = false;

  _defaultProject: any = null;
  filteredProjects: any[];
  projectSearchText: string;
  taskSearchText: string;

  removeBodyListener: () => void;
  removeListWrapperListener: () => void;

  constructor(
    protected elementRef: ElementRef, 
    protected renderer: Renderer2,
  ) { }

  ngOnInit(): void 
  {    
    this.skipProjectSelection = !!this.preselectedProject || !this.projects.length;
    this.skipTaskSelection = !!this.preselectedTask;    

    this.tasks = $helpers.deepCopy(this.tasks);
    this.isActive = !!this.currentTask;

    if (!!this.projects && !!this.preselectedProject) 
    {
      this.selection.project = this.projects.find( project => project.id == this.preselectedProject);
    }

    if (!!this.tasks && !!this.preselectedTask) 
    {
      this.selection.task = this.tasks.find(task => task.id == this.preselectedTask);
    }

    const endTaskIndex = this.tasks.findIndex(task => task.slug == 'end' || task.slug == 'stop' );
    // TODO: throw error for missing end task
    if (endTaskIndex >= 0) 
    {        
      this.endTask = this.tasks.splice(endTaskIndex, 1)[0];
    }

    if (this.isActive && this.skipProjectSelection && this.skipTaskSelection)
    {
      this.selection.task = this.endTask;
    }

    // Ordering
    this.onProjectSearch();
      
  }

  ngAfterViewInit(): void 
  {
    // Apply radius setting
    const diameter = this.radius * 2;
    const border_width = Math.ceil(this.radius / 24); // border
    const inside_width = diameter - (border_width * 2);

    this.renderer.setStyle(this.outerElement.nativeElement, 'width',        `${inside_width}px}`);
    this.renderer.setStyle(this.outerElement.nativeElement, 'border-width', `${border_width}px}`);
    this.renderer.setStyle(this.outerElement.nativeElement, 'min-width',    `${diameter}px}`);
    this.renderer.setStyle(this.outerElement.nativeElement, 'min-height',   `${diameter}px}`);

    const inside_third = Math.floor(inside_width/3);
    const adjusted_third = inside_width - (inside_third * 2);

    // this.renderer.setStyle(this.innerElement.nativeElement, 'top',          `${inside_third}px`);
    // this.renderer.setStyle(this.innerElement.nativeElement, 'left',         `${inside_third}px`);
    this.renderer.setStyle(this.innerElement.nativeElement, 'width',        `${adjusted_third}px`);
    this.renderer.setStyle(this.innerElement.nativeElement, 'min-width',    `${adjusted_third}px`);
    this.renderer.setStyle(this.innerElement.nativeElement, 'min-height',   `${adjusted_third}px`);

    const loader_adjustment = Math.floor(diameter * 0.1);
    const loader_width = diameter + (loader_adjustment * 2);

    this.renderer.setStyle(this.loaderElement.nativeElement, 'top',        `-${loader_adjustment}px`);
    this.renderer.setStyle(this.loaderElement.nativeElement, 'left',       `-${loader_adjustment}px`);
    this.renderer.setStyle(this.loaderElement.nativeElement, 'width',      `${loader_width}px`);
    this.renderer.setStyle(this.loaderElement.nativeElement, 'height',     `${loader_width}px`);

    this.renderer.setStyle(this.punchButtonElement.nativeElement, 'width',     `${diameter}px`);

    this.removeListWrapperListener = 
      this.renderer.listen(this.punchButtonListWrapperElement.nativeElement, 'click', (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
      });
  }

  ngOnChanges()
  {
    this.isActive = !!this.currentTask;
  }

  ngOnDestroy(): void 
  {
    // Clean up all event listeners
    if (!! this.removeBodyListener) 
    {
      this.removeBodyListener();
    }

    if (!! this.removeListWrapperListener) 
    {
      this.removeListWrapperListener();
    }
  }

  isLiveProject(project: any): boolean
  {
      return project.id == this.currentProject;
  }

  isLiveTask(task: PunchTask): boolean
  {
      return task.id == this.currentTask;
  }

  onButtonClick(event: MouseEvent): void
  {
    event.stopPropagation();
    event.preventDefault();

    if( this.selectorShown ){ return; }

    this.showSelector(true);

    // Setup the click hide handler
    this.removeBodyListener = this.renderer.listen(document.body, 'click', () => {
      this.showSelector(false);
    });
  }

  showSelector(shouldShow: boolean): void
  {
      // If all is preset
      if(shouldShow && this.skipProjectSelection && this.skipTaskSelection)
      {
          this.submit();
          return;
      }

      this.selectorShown = !!shouldShow;

      // If hiding selector, reset
      if(!shouldShow)
      {
          this.resetState();
          return;
      }

      // If showing and already active set default project to currently selected
      if ( this.isActive && this.currentProject)
      {
          this._defaultProject = this.projects.find( project => project.id == this.currentProject.id );
      }
  }

  selectProject(project?)
  {
      if ( this.skipProjectSelection ) { return; }

      this.selection.project = project;

      if ( this.skipTaskSelection ) { this.submit(); }
  }

  selectTask(task)
  {
      if (! this.selection.project)
      {
          this.selection.project = this._defaultProject;
      }

      this.selection.task = task;

      this.submit();
  }

  onProjectSearch(): void
  {
    this.filteredProjects = this.projects
      .filter(this.projectSearchFilter)
      .sort(orderBy('-default', 'name'));
  }

  projectSearchFilter(object)
  {
      if(!this.projectSearchText){ return true; }

      const filterText = this.projectSearchText.toLowerCase();

      if(object.name && object.name.toLowerCase().indexOf(filterText) != -1)
      {
          return true;
      }

      if( object.primary_location.address &&
          object.primary_location.address.formatted_address.toLowerCase().indexOf(filterText) != -1)
      {
          return true
      }

      return false;
  }

  resetState()
  {
      this.selection = {};
      this.projectSearchText = '';
      this.taskSearchText = '';
      this._defaultProject = null;
  }

  async submit(): Promise<void>
  {
      this.isPunching = true;
      const selection = $helpers.deepCopy(this.selection);
      this.showSelector(false);

      try
      {
        await this.callback(selection) 
      }
      finally
      {
        this.isPunching = false;
      }
  }
}
