import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';

@Component({
  selector: 'app-user-dispatches-single',
  templateUrl: './user-dispatches-single.component.html',
  styleUrls: ['./user-dispatches-single.component.scss']
})
export class UserDispatchesSingleComponent {
  @Input() modelId: string;

  constructor(
    protected authService: AuthenticationService,
  ) { }
}
