<mat-card class="large-card settings">

  <mat-card-title>
    <span>Sage50 Exports</span>
  </mat-card-title>

  <mat-divider></mat-divider>
  <div fxLayout="row" [fxShow]="this.loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div [fxShow]="!this.isIntegrationEnabled && !this.loading">
    <div class="settings__body" layoutPadding fxLayout="column">
      <div fxLayout="row">
        <span class="mat-title" layoutPadding>The Sage 50 integraiton must be active to view exports.</span>
      </div>
    </div>
  </div>

  <div [fxShow]="this.isIntegrationEnabled">
    <div class="settings__body" layoutPadding fxLayout="column">
      <div fxLayout="row">
        <span class="mat-title" layoutPadding>Create Export</span>
      </div>
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="50">
          <div fxLayout="column" fxLayoutGap="8px" layoutPadding>
            <mat-slide-toggle [(ngModel)]="this.isDateRangeAuto" 
              name="isDateRangeAuto"
              (ngModelChange)="this.onDateRangeManualChange()">
              Automatic (recommended)
            </mat-slide-toggle>
          </div>
          <div fxLayout="row" fxLayoutGap="20px" layoutPadding class="datepicker__row">
            <mat-form-field [fxShow]="!this.isDateRangeAuto" fxFlex="50">
              <mat-label>Start Date</mat-label>
              <input type="text"
                [matDatepicker]="startDatePicker"
                [(ngModel)]="this.startDate" 
                name="startDatePickerField"
                #startDatePickerField="ngModel"
                [max]="this.endDate"
                matInput>
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="startDatePickerField.hasError('required')">This is required.</mat-error>
              <mat-error *ngIf="startDatePickerField.hasError('matDatepickerMin')">Date is too early.</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-label>End Date</mat-label>
              <input type="text"
                [matDatepicker]="endDatePicker"
                [(ngModel)]="this.endDate" 
                name="endDatePickerField"
                #endDatePickerField="ngModel"
                [min]="this.startDate"
                matInput>
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="endDatePickerField.hasError('required')">This is required.</mat-error>
              <mat-error *ngIf="endDatePickerField.hasError('matDatepickerMin')">Date is too early.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50">
          <div fxFlex="row" layoutPadding fxLayoutAlign="end end">
            <mat-progress-spinner *ngIf="this.exporting" mode="indeterminate" diameter="36"></mat-progress-spinner>
            <button 
              type="button" mat-raised-button 
              [disabled]="this.exporting || this.loading"
              (click)="this.create()">
              Export
            </button>
          </div>
          <div fxLayout="row" [fxShow]="this.loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="settings__body" 
      fxLayout="column" 
      layoutPadding 
      cdkScrollable
      *ngIf="!this.loading">
      <span class="mat-title" layoutPadding>Exports</span>
      <div [fxShow]="!this.loading && !this.exports.length" fxLayout="row" layoutPadding>
        <span class="mat-caption">No exports have been created.</span>
      </div>
      <mat-table class="mat-selectable-table"
        fxFlex="100"
        [dataSource]="this.exports"
        [fxShow]="!this.loading && this.exports.length">
        <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef fxFlex="20">Invoices issued between</mat-header-cell>
            <mat-cell *matCellDef="let export" fxFlex="20">
              {{ export.from_date | dateFilter:'MMM d, yyyy' }} - {{ export.to_date | dateFilter:'MMM d, yyyy' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="export_type">
          <mat-header-cell *matHeaderCellDef fxFlex="13">Export Type</mat-header-cell>
          <mat-cell *matCellDef="let export" fxFlex="13">{{ this.exportType(export) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="count">
          <mat-header-cell *matHeaderCellDef fxFlex="12">Processed<br>Invoices</mat-header-cell>
          <mat-cell *matCellDef="let export" fxFlex="12">{{ export.quantity }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="exported_by">
          <mat-header-cell *matHeaderCellDef fxFlex="15">Exported By</mat-header-cell>
          <mat-cell *matCellDef="let export" fxFlex="15">
            {{ export.exporter.fullname }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef fxFlex="10">Created At</mat-header-cell>
          <mat-cell *matCellDef="let export" fxFlex="10">
            {{ export.created_at | dateFilter:'MMM d, yyyy' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sage50_import">
          <mat-header-cell *matHeaderCellDef fxFlex="10" fxLayout="column" fxLayoutAlign="center center" class="sage50_import">Sage 50 Export</mat-header-cell>
          <mat-cell *matCellDef="let export" fxFlex="10">
            <button [fxShow]="this.showDownload(export, 'sage50_import', '.IMP')" 
              mat-menu-item class="download_icon"
              (click)="this.download(export, 'sage50_import', '.IMP')">
              <mat-icon>cloud_download</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sage50_import_summary">
          <mat-header-cell *matHeaderCellDef fxFlex="10" fxLayout="column" fxLayoutAlign="center center" class="sage50_import_summary">Export Summary</mat-header-cell>
          <mat-cell *matCellDef="let export" fxFlex="10">
            <button [fxShow]="this.showDownload(export, 'sage50_import_summary', '.CSV')"
              mat-menu-item class="download_icon" 
              (click)="this.download(export, 'sage50_import_summary', '.CSV')">
              <mat-icon>cloud_download</mat-icon>
            </button>              
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="excluded_invoices_summary">
          <mat-header-cell *matHeaderCellDef fxFlex="10" fxLayout="column" fxLayoutAlign="center center" class="excluded_invoices_summary">Excluded Invoices</mat-header-cell>
          <mat-cell *matCellDef="let export" fxFlex="10">
            <button [fxShow]="this.showDownload(export, 'excluded_invoices_summary', '.CSV')"
              mat-menu-item class="download_icon"
              (click)="this.download(export, 'excluded_invoices_summary', '.CSV')">
              <mat-icon>cloud_download</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="exportTableColumns"></mat-header-row>
        <mat-row *matRowDef="let export; columns: exportTableColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</mat-card>
