import * as moment from "moment";
import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { BranchCollection, ClientCollection, EmployeeCollection, WorkOrderCollection } from '@beaconlite/models/collections';
import { Branch, Client, Department, Employee, WorkOrder } from '@beaconlite/models';
import { AppData } from '../../../../services/data/shared-data.departments';
import { WorkOrderEditorService } from '../work-order-single/tab-main/work-order-editor/work-order-editor.service';
import { Helpers as $helpers } from '../../../../services/helpers.service';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility'
import { orderBy } from "@beaconlite/utilities/Sort.utility";
import { SnackbarNotificationService } from "@beaconlite/services/notification/snackbar-notification.service";

@Component({
  selector: 'app-work-order-index',
  templateUrl: './work-order-index.component.html',
  styleUrls: ['./work-order-index.component.scss'],
})
export class WorkOrderIndexComponent implements OnInit {

  loading: boolean = false;
  collection = new WorkOrderCollection();
  workOrders = new CollectionDataSource(this.collection);
  
  departments: Department[] = null;
  promisedBranches: Promise<Branch[]>;
  promisedClients: Promise<Client[]>;
  promisedEmployees: Promise<Employee[]>;
  
  searchAll: boolean = false;

  branchSearchText = '';
  clientSearchText = '';
  employeeSearchText = '';
  selectedClient: Client = null;
  selectedBranch: Branch = null;
  selectedEmployee: Employee = null;
  selectedDispatch: string = null;
  selectedDepartment: string = null;  
  selectedState = ''; 
  selectedIssueLevel = '';
  selectedStartDate: Date = null; 
  selectedEndDate: number = null; 
  selectedModelId: string =  this.$stateGlobals.params.modelId;

  orderBy: string = 'date';
  order: string = 'desc';
  tag: string = null;
  keyword: string = null;

  constructor(
    protected appData: AppData,
    protected workOrderEditor: WorkOrderEditorService,
    protected $state: StateService,
    protected $stateGlobals: UIRouterGlobals,
    protected hotkeysService: HotkeysService,
    protected snackbarNotifications: SnackbarNotificationService,
  ) { }


  async ngOnInit(): Promise<void> 
  {
    this.departments = await this.appData.departments.getAll();
    this.onSearch();

    this._sortItems();
    this._setupHotKeys();
  }

  onSearch() 
  {
    const params: any = {
      search_all: this.searchAll,
      order:      this.order, 
      order_by:   this.orderBy,
    };    
    if (this.keyword)
    {
        params.keyword = this.keyword;
    }

    if (this.selectedBranch)
    {
        params.branch_id = this.selectedBranch.id;
    }

    if (this.selectedClient)
    {
        params.client_id = this.selectedClient.id;
    }

    if (this.selectedEmployee)
    {
        params.employee_id = this.selectedEmployee.id;
    }

    if (this.selectedDepartment)
    {
        params.department_ids = [this.selectedDepartment]
    }

    if (this.tag)
    {
        params.tag = this.tag;
    }

    if (this.selectedState)
    {
        params.state = this.selectedState;
    }

    if (this.selectedIssueLevel)
    {
      params.issue_level = this.selectedIssueLevel;
    }

    if (this.selectedDispatch)
    {
        params.dispatch = this.selectedDispatch;
    }

    if (this.selectedStartDate)
    {
        params.date_start = $helpers.dateToTimestamp(this.selectedStartDate);
    }

    if (this.selectedEndDate)
    {
        params.date_end = moment(this.selectedEndDate).endOf('day').unix();
    }

    this.collection.search(params);
  }

  async onNewWorkOrder() 
  {
    const onUpdate = async (workOrder: WorkOrder) =>
    {
      if (! workOrder) { return; }

      await workOrder.save();
      this.snackbarNotifications.saved();
      this.viewWorkOrder(workOrder);
    }

    await this.workOrderEditor.open({
      onUpdate: onUpdate
    });
  }

  viewWorkOrder(workOrder: WorkOrder) 
  {
    this.selectedModelId = workOrder.id
    this.$state.go('protected.work-orders.single', { modelId: workOrder.id });
  }

  onQueryBranches(searchText: string): void
  {
    this.promisedBranches = (new BranchCollection()).all({name: searchText});
  }

  onQueryClients(searchText: string): void
  {
    this.promisedClients = (new ClientCollection()).all({name:searchText});
  }

  onQueryEmployees(searchText: string): void
  {
    this.promisedEmployees = (new EmployeeCollection()).all({
      keyword: searchText,
      roles: ['administrator'],
      order:  'asc',
    });
  }

  onBranchOptionSelected(branch: Branch) 
  {
    this.selectedBranch = branch;
  }

  onClientOptionSelected(client: Client) 
  {
    this.selectedClient = client;
  }

  onEmployeeOptionSelected(employee: Employee)
  {
    this.selectedEmployee = employee;
  }

  displaySelectedBranch(branch: Branch|null): string|null 
  {
    return branch?.name || null;
  }

  displaySelectedClient(client: Client|null): string|null 
  {
    return client?.name || null;
  }

  displaySelectedEmployee(employee?: Employee): string
  {
    return !!employee ? employee.fullname : '';
  }

  clearBranchSelection(): void
  {
    this.branchSearchText = '';
    this.selectedBranch = null;
  }

  clearClientSelection(): void
  {
    this.clientSearchText = '';
    this.selectedClient = null;
  }

  clearEmployeeSelection(): void
  {
    this.employeeSearchText = '';
    this.selectedEmployee = null;
  }

  _setupHotKeys() 
  {
    this.hotkeysService.add(new Hotkey('option+w', (): boolean => {
        this.onNewWorkOrder();
        return false; // Prevent bubbling
    }, undefined, 'Add a new Work Order'));
  }

  protected _sortItems(): void
  {
    this.departments.sort( orderBy('name') );
  }
}
