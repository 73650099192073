<div>
    <span class="booked-time-indicator" 
        [style.background]="this.timeIndicatorBackground">
        {{ this.bookedTimeInHours }}h
    </span>
</div>
<mat-icon 
    class="is-booked-indicator"
    *ngIf="this.employee.is_booked"
    color="primary"
    matTooltip="Busy"
    matTooltipPosition="above">
    event_busy
</mat-icon>
<span>{{ this.employee.fullname }}</span>   
