import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChargeDefinition, RentalDefinition, ServiceDefinition, Variant, VariantRental, VariantService } from '@beaconlite/models';
import { ChargeDefinitionCollection, RentalDefinitionCollection, ServiceDefinitionCollection, VariantRentalCollection, VariantServiceCollection} from '@beaconlite/models/collections';
import { Sage50OverrideEditorData } from './sage50-override-editor-data.interface';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { OverrideableItem } from '@beaconlite/models/contracts/OverrideableItem.interface';
import { Sage50MappedAccount, Sage50OverrideEditorAccount } from '../sage50-inferaces/sage50-interfaces';
import { ItemTypeConstants } from '@beaconlite/models/constants/ItemTypes';
import { OverrideLevel, OverrideLevelConstants } from '@beaconlite/models/constants/OverrideLevels';
import { Helpers as $helpers } from '@beaconlite/services/helpers.service';

type Rental = RentalDefinition | VariantRental;
type Service = ServiceDefinition | VariantService;

@Component({
  selector: 'app-sage50-override-editor',
  templateUrl: './sage50-override-editor.component.html',
  styleUrls: ['./sage50-override-editor.component.scss']
})
export class Sage50OverrideEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  original = this.data.original;
  branch = this.data.branch;
  accounts = this.data.accounts;
  itemType = this.data.itemType;
  branchOverrides = this.data.branchOverrides;
  onUpdate = this.data.onOverrideUpdate;
  onRemove = this.data.onOverrideRemove;

  saving = false;

  override: Sage50OverrideEditorAccount = {
    id: '',
    name: '',
    account: '',
    itemDefId: '',
    itemDefName: ''
  };
  overrideLevel: OverrideLevel;
  isItemDefOverride: boolean = false;

  searchText: string = '';
  selectedItem: OverrideableItem;
  promisedItems: Promise<OverrideableItem[]> = null;

  readonly OVERRIDE_VARIANT = OverrideLevelConstants.OVERRIDE_VARIANT;
  readonly OVERRIDE_ITEM_DEF = OverrideLevelConstants.OVERRIDE_ITEM_DEF;
  readonly RENTALS = ItemTypeConstants.RENTALS;
  readonly SERVICES = ItemTypeConstants.SERVICES;
  readonly CHARGES = ItemTypeConstants.CHARGES;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: Sage50OverrideEditorData,
    public dialogRef: MatDialogRef<Sage50OverrideEditorComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  ngOnInit(): void {
    if (!! this.original)
    {
      // Only variants will also pass in the related item definition id.
      this.isItemDefOverride = this.original.itemDefId === '' ? true : false;
      this.override = $helpers.deepCopy(this.original);
    }

    this._sortItems();
  }

  clearSelectedItem(): void {
    this.promisedItems = null;
    this.selectedItem = null;
    this.searchText = null;
  }

  onItemTypeChange(): void {
    this.clearSelectedItem();

    if (this.itemType === this.CHARGES) {
      this.isItemDefOverride = true;
      this.overrideLevel = this.OVERRIDE_ITEM_DEF;
    }
  }

  onOverrrideLevelChange(overrideLevel: OverrideLevel): void {
    this.clearSelectedItem();
    this.isItemDefOverride = overrideLevel === this.OVERRIDE_ITEM_DEF ? true : false;
  }

  onQueryItems(): void {
    switch(this.itemType) {
      case this.CHARGES:
        this.promisedItems = this._queryCharges();
        break;
      case this.RENTALS:
        this.promisedItems = this._queryRentals();
        break;
      case this.SERVICES:
        this.promisedItems = this._queryServices();
        break;
    }
  }

  protected async _queryCharges(): Promise<ChargeDefinition[]> {
    return (new ChargeDefinitionCollection()).all({
      order:  'asc', 
      label:  this.searchText, 
    });
  }

  protected async _queryRentals(): Promise<Rental[]> {
    if (this.isItemDefOverride) {
      return (new RentalDefinitionCollection()).all({
        order: 'asc', 
        label: this.searchText
      });
    } else {
      return (new VariantRentalCollection()).all({
        order: 'asc', 
        name: this.searchText,
        exclude_item_def_for_root_variants: true
      });
    }
  }

  protected async _queryServices(): Promise<Service[]> {
    if (this.isItemDefOverride) {
      return (new ServiceDefinitionCollection()).all({
        order: 'asc', 
        label: this.searchText
      });
    } else {
      return (new VariantServiceCollection()).all({
        order: 'asc', 
        name: this.searchText,
        exclude_item_def_for_root_variants: true
      });
    }
  }

  getAccountString(account: Sage50MappedAccount): string {
    return account.value.description 
      ? account.value.id + ' - ' + account.value.description 
      : account.value.id;
  }

  async onItemSelected(item: OverrideableItem): Promise<void> {
    // Original items cannot change their item info, only account.
    if (!!this.original) { return; }

    this.selectedItem = item;

    if (this.isItemDefOverride) {
      this.override.id = item.id;
      this.override.name = item.getDisplayName();
      this.override.itemDefId = '';
      this.override.itemDefName = '';
    } else {
      this.override.id = (item as Variant).variant_id;
      this.override.name = item.getDisplayName();

      const ItemDefintionCollection = this.itemType === this.RENTALS ? new RentalDefinitionCollection() : new ServiceDefinitionCollection();
      const itemDef = (await (ItemDefintionCollection).all({ variant_id: this.override.id }))[0];
      this.override.itemDefId = itemDef.id;
      this.override.itemDefName = itemDef.name;
    }

    let existOverridesToCheck;

    if (this.isItemDefOverride) {
      existOverridesToCheck = this.branchOverrides[this.itemType].item_definition_overrides;
    } else {
      if (!(this.override.itemDefId in this.branchOverrides[this.itemType].item_definition_overrides)) { 
        return; 
      }
      existOverridesToCheck = this.branchOverrides[this.itemType].item_definition_overrides[this.override.itemDefId].variant_overrides;
    }

    if (this.override.id in existOverridesToCheck) {
      this.editorForm.controls.searchTextField.setErrors({ alreadyPresent: true });
    } else {
      this.editorForm.control.setErrors(null);
    }
  }

  displaySelectedItem(item: OverrideableItem): string { 
    return item ? item.getDisplayName() : '';
  }

  async save(): Promise<void | boolean> {
    if (this.editorForm.invalid) { return false; }
    
    this.saving = true;

    try {
      await this.onUpdate(this.override, this.itemType);
      this.dialogRef.close();
    } finally {
      this.saving = false;
    }
  }

  async remove(): Promise<void> {
    this.saving = true;

    try {
      if (await this.dialogNotification.removeConfirm()) {
        await this.onRemove();
        this.dialogRef.close();
      }
    } finally {
      this.saving = false;
    }
  }

  protected _sortItems(): void {
    this.accounts.sort(orderBy('value.name'));
  }
}
