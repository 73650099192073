import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class D3Service {

  private readonly _sdkReady = new BehaviorSubject<boolean>(false);

  constructor() 
  { 
    // this.loadSdk();
  }

  loadSdk()
  {
    let js = document.createElement('script') as any;
    js.type = 'text/javascript';
    js.async = true;
    js.src = 'https://d3js.org/d3.v5.js'

    js.addEventListener('readystatechange', function() {
      if (this.readyState == 'complete') { this.onload() };
    });

    js.onload = this.onScriptLoad.bind(this);
    document.body.appendChild(js);
  }

  onScriptLoad() 
  {
    this._sdkReady.next(true);
    this._sdkReady.complete();
  }

  isReady(): boolean
  {
    return this._sdkReady.getValue();
  }

  async whenReady(): Promise<any>
  {
    await this._sdkReady.toPromise();
    return (window as any).d3;
  }
}
