import { HttpResponse } from "../../services/network/http-response";
import { Branch } from "../Branch.model";
import { BaseCollection } from "./Base.collection";
import { AppInjector } from "../../services/app-injector.service";
import { RouteMap } from "../../services/network/route-map.service";

export class BranchCollection extends BaseCollection<Branch> {

    constructor(options?: any) {
        super(Branch, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchBranches(params);
    }
}
