import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { RentalSingleComponent } from "./rental-single.component";

export const RentalSingleState: Ng2StateDeclaration = {
  name: 'protected.rentals.single',
  url: '/:modelId',
  component: RentalSingleComponent,
  data: {
      pageTitle: 'Rentals',
      requiresAuth: true,
  },
  resolve: [
    { 
      token: 'modelId',
      deps: [Transition],
      resolveFn: (transition: Transition) => transition.params().modelId,
    },
  ],
}
