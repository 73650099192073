import * as moment from 'moment';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StateService } from '@uirouter/core';
import { Client, Invoice, InvoiceType, WorkOrder } from '@beaconlite/models';
import { LockVaultService } from '../../../services/lock-vault.service';
import { EmailEditorService } from '../email-editor/email-editor.service';
import { ReasonEditorService } from '../reason-editor/reason-editor.service';
import { InvoiceEditorData } from './invoice-editor-data.interface';
import { EmailHistoryService } from '@beaconlite/components/email-history/email-history.service'

@Component({
	selector: 'app-invoice-editor',
	templateUrl: './invoice-editor.component.html',
	styleUrls: ['./invoice-editor.component.scss'],
})
export class InvoiceEditorComponent implements OnInit, OnDestroy {

	workOrder = this.data.workOrder;
	original = this.data.original;
	presets = this.data.presets;
	isInvoiceType = this.data.isInvoiceType;
	onUpdate = this.data.onInvoiceUpdate;
	onRemove = this.data.onInvoiceRemove;
	// Set InvoiceType as a fallback to lowest impact type.
	type: InvoiceType = 'estimate';

	loaded = false;
	loading = false;
	processing = false;
	sending = false;
	isFromScheduleView = false;

	customEndDate: Date = null;
	minimumCustomEndDate: Date = null
	issueDate: Date = moment().startOf('day').toDate();

	invoice: Invoice = null;

	constructor(
	@Inject(MAT_DIALOG_DATA) protected data: InvoiceEditorData,
	public dialogRef: MatDialogRef<InvoiceEditorComponent>,
	protected lockVault: LockVaultService,
	protected emailEditor: EmailEditorService,
	protected reasonEditor: ReasonEditorService,
	protected emailHistory: EmailHistoryService,
	protected $state: StateService,
	) { }

	async ngOnInit(): Promise<void> 
	{
		this.isFromScheduleView = !!this.presets;
		this._resetDates();

		// Types for a new invoice must be explicitly set. Other types are set in the modal select field.
		if (this.isInvoiceType) 
		{
			this.type = 'invoice';
		}

		// We have Invoice data
		if (!!this.original)
		{
			this.loading = true;

			try
			{
				// Get its details
				this.invoice = await Invoice.get(this.data.original.id);
				this.type = this.invoice.type;
				await this.lockVault.requestLock(this.invoice);
			}
			finally
			{
				this.loading = false;
				this.loaded = true;
			}
		}

		// Previewing scheduled invoice
		if (this.isFromScheduleView)
		{
			this.loading = true;
			this.issueDate = this.data.presets.issueDate;
			this.customEndDate = this.data.presets.endDate;

			this.onPreview();
		}
	}

	ngOnDestroy(): void
	{
		this.lockVault.revokeLock(this.invoice);

		if (this.isFromScheduleView)
		{
			this.lockVault.revokeLock(this.workOrder);
		}
	}

	async onPreview(): Promise<void> 
	{
		const payload = {
			work_order_id: this.workOrder.id,
			issued_at: this.issueDate.getTime() / 1000,
			custom_end_date: this.customEndDate ? this.customEndDate.getTime() / 1000 : null,
			type: this.type,
		};

		this.processing = true;

		try
		{
			if (this.isInvoiceType)
			{
				await this.lockVault.requestLock(this.workOrder);
			}

			this.invoice = await Invoice.preview(payload);
		
			this.loading = false;
			this.loaded = true;
		}
		catch(e)
		{
			console.log(e)
		}
		finally
		{
			this.processing = false;
		}
	}

	invoiceExists(): boolean
	{
		return !!this.invoice && this.invoice.exists();
	}

	canCreate(): boolean
	{
		return !this.invoiceExists();
	}

	async create(): Promise<void>
	{
		const payload = {
			work_order_id: this.workOrder.id,
			issued_at: this.issueDate.getTime() / 1000,
			custom_end_date: this.customEndDate ? this.customEndDate.getTime() / 1000: null,
			type: this.type,
		};

		this.processing = true;

		try
		{
			this.invoice = await Invoice.create(payload);
			await this.lockVault.requestLock(this.invoice);
			await this.onUpdate?.();

		}
		finally
		{
			this.processing = false;
		}
	}

	async onSend(): Promise<boolean>
	{
		const client: Client = await Client.get(this.invoice.client_id);
		return this.emailEditor.open({
			defaults: {
				to: [ client.email ],
				appendableModels: this.invoice.dispatches
			},
			sendableModel: this.invoice,
			onSendableUpdate: this.onSendableUpdate,
		});
	}

	// Must be a function expression to "bake in" the value of "this" (this.workOrder and this.invoice).
	// If it were an declaration, "this" would refer to whatever is calling it, e.g. email-editor.component which does not have a workOrder.
	onSendableUpdate = async (): Promise<void> =>
	{
		await this.workOrder.reload();
		this.invoice = await Invoice.get(this.invoice.id);
	}

	async onShowEmailHistory(): Promise<void>
	{
		await this.emailHistory.open({
		sendableModel: this.invoice,
		});
	}

	async onMarkSent(): Promise<void>
	{
		await this.invoice.markSent();
		await this.onUpdate?.();
	}

	canPrint(): boolean
	{
		return !this.invoice.discarded;
	}

	onPrint(): void
	{
		const url = this.$state.href('print.invoice', { modelId: this.invoice.id });
		window.open(url, '_blank');
	}

	async onDownload(): Promise<void>
	{
		const invoice = await this.invoice.getPdf();
		invoice.pdf.download();	
	}

	async onVoid(): Promise<void>
	{
		const reason: string = await this.reasonEditor.open();

		if (! reason) return;

		await this.invoice.void(reason);
		await this.onUpdate?.();
	}

	async onDiscard(): Promise<void>
	{
		const reason: string = await this.reasonEditor.open();

		if (! reason) return;

		await this.invoice.discard(reason);
		await this.onRemove();
	}

	protected _resetDates()
	{
		// Set minimumCustomEndDate
		if (!! this.workOrder.previous_invoice)
		{
			this.minimumCustomEndDate = moment.unix(this.workOrder.minimum_allowable_date).toDate();
		}
		else if (!! this.workOrder.started_at)
		{
			this.minimumCustomEndDate = moment.unix(this.workOrder.started_at).toDate();
		}

		// if the work order has an end date use it as the invoice end date
		if (!! this.workOrder.ended_at)
		{
			this.customEndDate = moment.unix(this.workOrder.ended_at).toDate();
		}
		else
		{
			this.customEndDate = moment().toDate();
		}

		if ( moment(this.customEndDate).isBefore(this.minimumCustomEndDate) )
		{
			this.customEndDate = moment(this.minimumCustomEndDate).toDate();
		}
	}

}
