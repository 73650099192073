<mat-table fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Work Order</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.serial_id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ongoing">
        <mat-header-cell *matHeaderCellDef>Ongoing</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.ongoing }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let item">
            <active-item-client-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </active-item-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rentalItemDetail" *ngIf="this.nestedLayer == 'items'">
        <mat-cell *matCellDef="let item">
            <active-item-rental-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </active-item-rental-row>         
        </mat-cell>
    </ng-container>

  
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'items'">
        <mat-row *matRowDef="let nested; columns: ['rentalItemDetail']" ></mat-row>
    </ng-container>
</mat-table>