import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Dispatch, WorkOrder } from '@beaconlite/models';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';

@Component({
  selector: 'dispatch-summary',
  templateUrl: './dispatch-summary.component.html',
  styleUrls: ['./dispatch-summary.component.scss'],
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `dispatch.component.scss` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,
})
export class DispatchSummaryComponent implements OnInit {

  @Input() workOrder: WorkOrder;
  @Input() dispatch: Dispatch;
  @Input() shouldShowDepartment: boolean;
  @Input() stateOnView: string;

  displayDepartments: string;

  constructor(
    protected $state: StateService, 
    protected authService: AuthenticationService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this._mapNotificationStatusToEmployees();
    this.shouldShowDepartment = this.shouldShowDepartment && !!this.dispatch.departments.length;
    this.displayDepartments = this.dispatch.departments.map( department => department.name).sort().join(', ');
  }

  displayType(): string {
    return this.dispatch.getDisplayType();
  }

  headerClass(): string {
    return this.dispatch.getHeaderClass();
  }

  onView(): void
  {
    this.$state.go(this.stateOnView, { modelId: this.dispatch.id });
  }

  protected _mapNotificationStatusToEmployees(): void
  {
    this.dispatch.employees.forEach(employee => {
      const notificationStatus = this.dispatch.notification_summary.find( status => employee.id === status.recipient_id)
      employee.temps.notification_status = notificationStatus || null
    })
  }
}
