import { HttpResponse } from "../../services/network/http-response";
import { ChargeDefinition } from "../ChargeDefinition.model";
import { BaseCollection } from "./Base.collection";
import { AppInjector } from "../../services/app-injector.service";
import { RouteMap } from "../../services/network/route-map.service";

export class ChargeDefinitionCollection extends BaseCollection<ChargeDefinition> {

    constructor(options?: any) {
        super(ChargeDefinition, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchChargeDefinitions(params);
    }
}
