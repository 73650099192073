<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
  <mat-toolbar>
      <h2 class="mat-title">{{ this.accountValue.id ? 'Edit Account ID': 'New Account' }}</h2>
  </mat-toolbar>

  <mat-dialog-content class="dialog-padding">
      <div fxLayout="column">
          <mat-form-field fxflex>
              <mat-label>Account ID</mat-label>
              <input 
                type="text"
                (ngModelChange)="this.accountIdChange($event)"
                [ngModel]="this.accountValue.id"
                name="accountValueId"
                #accountValueId="ngModel"
                [minlength]="4"
                [maxlength]="13"
                matInput
                required>
            <mat-error *ngIf="accountValueId.hasError('required')">Account ID is required.</mat-error>
            <mat-error *ngIf="accountValueId.hasError('minlength')">Account ID must be at least 4 characters.</mat-error>
            <mat-error *ngIf="accountValueId.hasError('maxlength')">Account ID must be less than 14 characters.</mat-error>
            <mat-error *ngIf="accountValueId.hasError('alreadyPresent')">Account ID already in list of accounts.</mat-error>
          </mat-form-field>
          <mat-form-field fxflex>
            <mat-label>Account Description</mat-label>
            <input type="text"
              [(ngModel)]="this.accountValue.description"
              name="accountValueDescription"
              #accountValueDescription="ngModel"
              matInput>
        </mat-form-field>
      </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button type="button" mat-icon-button *ngIf="this.original && this.fallbackAccountName">
        <mat-icon (click)="this.delete()" color="primary">delete</mat-icon>
    </button>

    <span fxFlex></span>
      <mat-progress-spinner *ngIf="this.isSaving" mode="indeterminate" diameter="36"></mat-progress-spinner>
      <button type="button" mat-raised-button mat-dialog-close>
          Cancel
      </button>
      <button type="submit" mat-raised-button color="accent" [disabled]="this.isSaving">
          Save
      </button>
  </mat-dialog-actions>
</form>
