import { HttpResponse } from "../../services/network/http-response";
import type { Constructor } from '@beaconlite/types';

export type SearchFn = (params: {}) => Promise<HttpResponse>;

export interface Page<T> {
    number: number,
    loading: boolean,
    promise: Promise<Page<T>>,
    pagination: {},
    elements: Array<T>,
}

export class Paginator<T> {

    // Pagination properties
    protected _currentPage: number = 1;
    protected _lastPage: number = null;
    protected _totalResults: number = 0;

    // Search parameters
    protected _params: object = {};

    // Page buffer properties
    protected _buffer: Array<Page<T>> = [];
    // protected _bufferSize: number = 0;

    constructor(
        protected _searchFn: SearchFn, 
        protected _modelClass: Constructor<T>, 
        protected _resetCallback: Function,
        protected _perPage: number = 25, 
        protected _bufferSize: number = 0 ) {
    }

    set params(params: object) {
        this._params = params;
    }

    get perPage(): number {
        return this._perPage;
    }

    set perPage(newSize: number) {
        // Don't do anything if the value remains the same
        if ( this._perPage === newSize ) return;

        this.reset();

        // Ensure minimum size
        this._perPage = Math.max(newSize, 1);
    }

    get bufferSize(): number {
        return this._bufferSize
    }

    set bufferSize(newSize: number) {
         // Don't do anything if the value remains the same
         if ( this._bufferSize === newSize ) return;
 
         // Ensure minimum size
         this._bufferSize = Math.max(newSize, 0);
    }

    get currentPage(): number {
        return this._currentPage;
    }

    get lastPage(): number {
        return this._lastPage;
    }

    // Alias for fn:lastPage.
    get totalPages(): number {
        return this.lastPage;
    }

    get totalResults(): number {
        return this._totalResults;
    }
    
    /**
     * Resets pagination and clears the buffer.
     */
    reset(): void {
        this._currentPage   = 1;
        this._lastPage      = null;
        this._totalResults  = 0;
        this._buffer        = [];
        this._params        = {};

        this._resetCallback();
    }

    /**
     * Verify if the specified page number is within the results' range. If the results' size or total page count is
     * not known then it is assumed that the page may be within range.
     *
     * @param {number} pageNumber
     * @returns {boolean}
     */
    isPageWithinRange(pageNumber: number): boolean {
        return pageNumber > 0 && 
            ( this._lastPage === null || 
              pageNumber <= this._lastPage );
    }

    /**
     * Update pagination to the first page.
     *
     * @returns {Promise<Page<T>>}
     */
    toFirstPage(): Promise<Page<T>> {
        this._currentPage = 1;

        return this._getPage(this._currentPage);
    }

    /**
     * Updated pagination to the previous page.
     *
     * @returns {Promise<Page<T>>}
     */
    toPreviousPage(): Promise<Page<T>> {
        if (this._currentPage > 1)
        {
            this._currentPage--;

            return this._getPage(this._currentPage);
        }

        return Promise.resolve(null);
    }

    /**
     * Updated pagination to the specified page.
     *
     * @param {number} pageNumber
     * @returns {Promise<Page<T>>}
     */
    toPage(pageNumber: number): Promise<Page<T>> {
        if (this.isPageWithinRange(pageNumber))
        {
            this._currentPage = pageNumber;

            return this._getPage(pageNumber);
        }

        return Promise.resolve(null)
    }

    /**
     * Update pagination to next page.
     *
     * @returns {Promise<Page<T>>}
     */
    toNextPage(): Promise<Page<T>> {
        if (this._lastPage === null || 
            this._currentPage < this._lastPage) 
        {
            this._currentPage++;

            return this._getPage(this._currentPage);
        }

        return Promise.resolve(null);
    }

    /**
     * Update pagination to the last page in the set.
     *
     * @returns {Promise<Page<T>>}
     */
    toLastPage(): Promise<Page<T>> {
        if (this._lastPage !== null)
        {
            this._currentPage = this._lastPage;

            return this._getPage(this._currentPage);
        }

        return Promise.resolve(null);
    }

    /**
     * Verify if the specified page exists in the buffer.
     *
     * @param {number} pageNumber
     * @returns {boolean}
     */
    hasPage(pageNumber: number): boolean {
        return this._buffer.hasOwnProperty(pageNumber);
    }
    /**
     * Get the specified page from the buffer. Defaults to the current page if no page number is specified.
     *
     * @param {number} pageNumber
     * @returns {Page}
     */
    getPage(pageNumber?: number): Page<T> {
        pageNumber = pageNumber || this._currentPage;

        if (this.hasPage(pageNumber))
        {
            return this._buffer[pageNumber];
        }

        return null;
    }

    /**
     * Fetch a page of results for the collection, cast the response data, and add page to the paginator's buffer.
     *
     * @param {number} pageNumber
     * @returns {Promise<Page<T>>}
     */
    protected async _fetch(pageNumber: number): Promise<Page<T>> {
        console.debug(`[Model:Paginator] fetching page ${pageNumber} from remote...`);

        // Bundle search parameters and pagination parameters into a single object
        const params = { ...this._params, per_page: this._perPage, current_page: pageNumber }

        // Create a new page, keep reference for promise closure
        const page: Page<T> = {
            number: pageNumber,
            loading: true,
            promise: null,
            pagination: {},
            elements: []
        };

        // Add empty page to buffer
        this._buffer[pageNumber] = page;

        return page.promise = this._searchFn(params).then( response => {

            const responsePagination = response.data().pagination;
            const responseCollection = response.data().collection;

            // Cast collection data from plain objects to configured model class
            const castCollection = responseCollection.map(data => new this._modelClass(data));

            // Update pagination result globals in case they case for the same search
            this._lastPage      = responsePagination.last_page;
            this._totalResults  = responsePagination.total;

            // Update buffered page (closed over)
            page.loading        = false;
            page.pagination     = responsePagination;
            page.elements       = castCollection;
            
            console.debug(`[Model:Paginator] page ${pageNumber} has been fetched from remote`);

            return this.getPage(pageNumber);
        });
    }

    /**
     * Get a page of results for the collection and potentially buffer additional pages.
     *
     * @param {number} pageNumber
     * @returns {Promise<Page<T>>}
     */
    protected _getPage(pageNumber: number): Promise<Page<T>> {
        let promise: Promise<Page<T>>

        // Fetch page from the buffer if present, otherwise fetch page data from remote
        if (this.hasPage(pageNumber))
        {
            console.debug(`[Model:Paginator] fetching page ${pageNumber} from buffer...`)

            promise = this.getPage(pageNumber).promise;
        }
        else
        {
            promise = this._fetch(pageNumber);
        }

        // Check if additional pages need to be buffered
        let bufferPageNumber = pageNumber;        

        // Buffering currently only happens for pages following the current page and not preceding pages
        for (var i = 0; i < this._bufferSize; i++)
        {
            bufferPageNumber++;

            // Buffer the page if it is within the total results' range and isn't already in the buffer
            if ( this.isPageWithinRange(pageNumber) && !this.hasPage(bufferPageNumber) )
            {
                this._fetch(bufferPageNumber);
            }
        }

        return promise;
    }

}
