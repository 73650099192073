import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentEditorData } from './document-editor-data.interface';
import { DocumentEditorService } from './document-editor.service';
import { Account, Document } from '@beaconlite/models';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';

@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
})
export class DocumentEditorComponent implements OnInit {

  original = this.data.original;
  linkOnly = this.data.linkOnly;
  documentableModel = this.data.documentableModel;
  onUpdate = this.data.onDocumentUpdate;
  onRemove = this.data.onDocumentRemove;

  document: Document;
  account = new Account();

  downloading = false;
  sending = false;
  loaded = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DocumentEditorData,
    public dialogRef: MatDialogRef<DocumentEditorService>,
    protected auth: AuthenticationService,
    protected dialogNotification: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    if (!! this.original)
    {
      this.document = this.original.copy();
    }

    this.account = await this.auth.getCurrentUser();

    this.loaded = true;
  }

  displayReferences(): string
  {
    return this.document.associations.length ? this.document.associations.join(', ') : 'None'
  }

  async download(): Promise<void>
  {
    this.downloading = true

    try
    {
      await this.document.attachment.download()
    }
    finally
    {
      this.downloading = false;
    }
  }

  isDocumentModifiable(): boolean
  {
    const isAdmin = this.account.isAdmin() 
    const isOriginalUploader = this.document.uploaded_by === this.account.id

    return isAdmin || isOriginalUploader
  }

  async save(): Promise<void>
  {
    this.sending = true;

    try
    {
      await this.document.save();
      await this.onUpdate();
      this.dialogRef.close();
    }
    finally
    {
      this.sending = false;
    }
  }

  async remove(): Promise<void>
  {
    // Show for any associations
    if (!! this.document.associations.length)
    {
      const shouldContinue: boolean = await this.dialogNotification.confirm({
        title: 'Confirm Linked Document Deletion?',
        textContent: `This Document is linked to these items: ${this.document.associations.join(', ')} <br><br> Are you sure you would like delete it?`,
      });

      if (! shouldContinue) return;
    }

    const shouldContinue: boolean = await this.dialogNotification.confirm({
      title: 'Confirm Document Deletion?',
      textContent: 'Are you sure you would like delete this Document from the Work Order?',
    });

    if (! shouldContinue) return;

    this.sending = true;

    try
    {
      await this.document.delete();
      await this.onRemove();
      this.dialogRef.close();
    }
    finally
    {
      this.sending = false;
    }
  }
}
