<form class="mat-dialog-editor" #editorForm (ngSubmit)="onSave()" novalidate>
    <mat-dialog-content class="dialog-padding">
        <div fxLayout="column">
            <mat-form-field>
                <mat-label>Tax name</mat-label>
                <mat-select [(ngModel)]="this.selectedTaxIndex" name="taxNameField">
                    <mat-option *ngFor="let tax of this.taxList; let i = index;" [value]="i">
                        <strong>{{ tax.name }}</strong> - {{ tax.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.saving">
            Save
        </button>
    </mat-dialog-actions>
</form>
