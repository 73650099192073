<div class="request-tab" fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="16px">
    
    <mat-card class="large-card" fxLayout="column">
        <mat-table class="mat-selectable-table"
            [dataSource]="workOrder.invoices"
            [fxShow]="workOrder.hasInvoices()">
            <ng-container matColumnDef="date-issued">
                <mat-header-cell *matHeaderCellDef>Date Issued</mat-header-cell>
                <mat-cell *matCellDef="let invoice">{{ invoice.issued_at | dateFilter:'dateMedium':'TBD' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef>Number</mat-header-cell>
                <mat-cell *matCellDef="let invoice">{{ invoice.formatted_serial_id }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="invoice-period">
                <mat-header-cell *matHeaderCellDef>Invoice Period</mat-header-cell>
                <mat-cell *matCellDef="let invoice">{{ invoice.started_at | dateFilter:'dateMedium' }}&nbsp;&mdash;&nbsp;{{ invoice.ended_at | dateFilter:'dateMedium' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="last-sent">
                <mat-header-cell *matHeaderCellDef>Last Sent</mat-header-cell>
                <mat-cell *matCellDef="let invoice">{{ this.getMarkedSentLabel(invoice) }} {{ this.getSentOrMarkedSentAt(invoice) | dateFilter:'dateTimeMedium':'N/A' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="synced">
                <mat-header-cell fxFlex="10" *matHeaderCellDef>Synced</mat-header-cell>
                <mat-cell fxFlex="10" *matCellDef="let invoice" class="mat-col--icon">
                    <div *ngIf="invoice.hasAccountingExportable()">
                        <mat-icon *ngIf="!invoice.getAccountingExportable().invalidated_at" color="primary">sync</mat-icon>
                        <mat-icon *ngIf="invoice.getAccountingExportable().invalidated_at" color="primary">sync_problem</mat-icon>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="grand-total">
                <mat-header-cell class="mat-header-cell--numeric" fxFlex="10" *matHeaderCellDef>Grand Total</mat-header-cell>
                <mat-cell class="mat-cell--numeric" fxFlex="10" *matCellDef="let invoice">{{ invoice.total | currencyFilter:'parentheses' }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="invoiceTableColumns"></mat-header-row>
            <mat-row *matRowDef="let invoice; columns: invoiceTableColumns" (click)="onEditInvoice(invoice)"></mat-row>
        </mat-table>
    </mat-card>

    <div fxLayout="row" 
        fxLayoutAlign="end end" 
        class="request-tab__add-button-wrapper">
        <button mat-fab
            [disabled]="workOrder.invoiced" 
            (click)="onAddInvoice()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

</div>
