<button type="button" mat-icon-button (click)="this.onClose()" fxFlexAlign="end">
    <mat-icon color="primary" fxFlexAlign="end">close</mat-icon>
</button>

<dispatch-component class="large-card"
    *ngIf="this.dispatch.exists()"
    [dispatch]="this.dispatch"
    [workOrder]="this.workOrder"
    [shouldShowDepartment]="this.departments.length > 1"
    [isAdmin]="this.isAdmin"
    [canEdit]="this.canEdit"
    [onUpdate]="this.onDispatchUpdate"
    [onRemove]="this.onDispatchRemove">
</dispatch-component>
