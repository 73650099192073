import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentPreviewData } from './document-preview-data.interface';
import { DocumentPreviewService } from './document-preview.service';
import { Account, Document } from '@beaconlite/models';
import { DocumentEditorService } from '../document-editor/document-editor.service';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentPreviewComponent implements OnInit {

  original = this.data.original;
  linkOnly = this.data.linkOnly;
  documentableModel = this.data.documentableModel;
  onUpdate = this.data.onDocumentUpdate;
  onRemove = this.data.onDocumentRemove;

  document = new Document();
  account = new Account();
  downloading = false;
  trustedUrl: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DocumentPreviewData,
    public dialogRef: MatDialogRef<DocumentPreviewService>,
    protected auth: AuthenticationService,
    protected documentEditor: DocumentEditorService,
    protected sanitizer: DomSanitizer,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.document = this.original;
    this.account = await this.auth.getCurrentUser();

    if (this.docIsImageType() || this.docIsApplicationType())
    {
      await this.document.attachment.getUrl();
    }

    this.trustedUrl = this.setTrustedUrl(this.document.attachment.url);
  }

  docIsImageType(): boolean
  {
    return this.document.attachment.type.substring(0,5) == 'image';
  }

  docIsApplicationType(): boolean
  {
    return this.document.attachment.type.substring(0,11) == 'application';
  }

  setTrustedUrl(rawURL: string): SafeResourceUrl
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(rawURL)}&embedded=true`);
  }

  async download(): Promise<void>
  {
    this.downloading = true

    try
    {
      await this.document.attachment.download();
    }
    finally
    {
      this.downloading = false; 
    }
  }

  async editDocument(): Promise<void>
  {
    this.dialogRef.close();

    await this.documentEditor.open({
      original: this.document,
      linkOnly: this.linkOnly,
      documentableModel: this.documentableModel,
      onDocumentUpdate: this.onUpdate,
      onDocumentRemove: this.onRemove,
    });
  }
}
