<nav class="app-navigation" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center">
        <img src="assets/img/{{ this.company.logo_mark }}" alt="BeaconLite RMS" class="navigation-logo">
    </div>
    <a uiSref="protected.work-orders" uiSrefActive="active" matTooltip="Work Orders" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Work Orders">inbox</mat-icon>
    </a>

    <a uiSref="protected.invoices.schedule" uiSrefActive="active" matTooltip="Invoices" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Invoices">assignment</mat-icon>
    </a>

    <a [matMenuTriggerFor]="dispatchMenu" matTooltip="Dispatches" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Dispatches">headset_mic</mat-icon>
    </a>
    <mat-menu class="app-navigation__dispatch-menu" #dispatchMenu="matMenu">
        <button mat-menu-item uiSref="protected.dispatch-schedule">
            <mat-icon>list_alt</mat-icon>
            Dispatches
        </button>
        <button mat-menu-item uiSref="protected.employee-schedule">
            <mat-icon>event</mat-icon>
            Employees
        </button>
    </mat-menu>

    <a uiSref="protected.clients.single" uiSrefActive="active" matTooltip="Clients" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Clients" >business</mat-icon>
    </a>

    <a uiSref="protected.rentals" uiSrefActive="active" matTooltip="Rental Items" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Rental Items" >warning</mat-icon>
    </a>

    <a uiSref="protected.services" uiSrefActive="active" matTooltip="Services" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Services">build</mat-icon>
    </a>

    <a uiSref="protected.line-items" uiSrefActive="active" matTooltip="Line Items" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Line Items">sell</mat-icon>
    </a>

    <a uiSref="protected.charges.single" uiSrefActive="active" matTooltip="Charges" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Charges">attach_money</mat-icon>
    </a>

    <a uiSref="protected.employees.single" uiSrefActive="active" matTooltip="Employees" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Employees">person</mat-icon>
    </a>

    <a uiSref="protected.reports" uiSrefActive="active" matTooltip="Reports" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Reports">assessment</mat-icon>
    </a>

    <a uiSref="protected.user-dispatches" uiSrefActive="active" matTooltip="My Dispatches" matTooltipPosition="right" *ngIf="!this.isAdmin()">
        <mat-icon aria-label="Dispatch Slips">local_shipping</mat-icon>
    </a>

    <a uiSref="protected.upcoming-dispatches" uiSrefActive="active" matTooltip="Upcoming Dispatches" matTooltipPosition="right" *ngIf="!this.isAdmin()">
        <mat-icon aria-label="Upcoming Dispatches">supervisor_account</mat-icon>
    </a>

    <span fxFlex></span>

    <a [matMenuTriggerFor]="settingsMenu" matTooltip="Settings" matTooltipPosition="right" *ngIf="this.isAdmin()">
        <mat-icon aria-label="Settings">settings</mat-icon>
    </a>
    <mat-menu #settingsMenu="matMenu" class="app-navigation__settings-menu">
        <button mat-menu-item uiSref="protected.branches">
            <mat-icon>account_tree</mat-icon>
            Branches
        </button>
        <a mat-menu-item [matMenuTriggerFor]="sage50Menu" matTooltipPosition="right" *ngIf="this.isAdmin()">
            <mat-icon>attach_money</mat-icon>
            Sage 50
        </a>
        <mat-menu class="app-settings-menu__sub-menu" #sage50Menu="matMenu">
            <button mat-menu-item uiSref="protected.settings-sage50">
                <mat-icon>settings</mat-icon>
                Sage 50 Settings
            </button>
            <button mat-menu-item uiSref="protected.exports-sage50">
                <mat-icon>drive_file_move</mat-icon>
                Sage 50 Exports
            </button>
        </mat-menu>
        <button mat-menu-item uiSref="protected.settings-quickbooks">
            <mat-icon>attach_money</mat-icon>
            QuickBooks
        </button>
        <button mat-menu-item uiSref="protected.settings">
            <mat-icon>settings</mat-icon>
            General
        </button>
    </mat-menu>
    
    <a (click)="onLogout($event)" matTooltip="Logout" matTooltipPosition="right">
        <mat-icon aria-label="Logout">exit_to_app</mat-icon>
    </a>
</nav>

<ui-view fxFlex></ui-view>
