import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { ChargeSingleComponent } from "./charge-single.component";

export const ChargeSingleState: Ng2StateDeclaration = {
    name: 'protected.charges.single',
    url: '/:modelId',
    component: ChargeSingleComponent,
    data: {
        pageTitle: 'Charges',
        requiresAuth: true,
    },
    params: {
        modelId: 'new'
    },
    resolve: [
        { 
          token: 'modelId', 
          deps: [Transition],
          resolveFn: (transition: Transition) => transition.params().modelId,
        },
    ],
}
