import { AppInjector } from "@beaconlite/services/app-injector.service";
import { RouteMap } from "@beaconlite/services/network/route-map.service";
import { HttpResponse } from "../../services/network/http-response";
import { Dispatch } from "../Dispatch.model";
import { BaseCollection } from "./Base.collection";

export class DispatchCollection extends BaseCollection<Dispatch> {

    constructor(options?: any) {
        super(Dispatch, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchDispatches(params);
    }
}
