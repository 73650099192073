import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LineItemEditorComponent } from './line-item-editor.component';
import { LineItemEditorData } from './line-item-editor-data.interface';

@Injectable({
  providedIn: 'root'
})
export class LineItemEditorService {

  constructor(protected dialog: MatDialog) {}
  
  async open(data: LineItemEditorData): Promise<boolean>
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data
    }

    return this.dialog.open(LineItemEditorComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}