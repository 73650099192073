import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, Injectable, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Department } from '@beaconlite/models';
import { GroupDefinition } from '@beaconlite/models/contracts/GroupDefinition';
import { GroupItem } from '@beaconlite/models/contracts/GroupItem.interface';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

export interface GroupDefinitionEditorData {
  original: GroupDefinition,
  departments: Department[],
  onUpdate: (groupDef: GroupDefinition) => Promise<void>
}

@Injectable({
  providedIn: 'root'
})
export class GroupDefinitionEditorService {

  constructor(protected dialog: MatDialog) {}

  async open(data?: GroupDefinitionEditorData): Promise<GroupDefinition> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data,
    }

    return this.dialog.open(GroupDefinitionEditorComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-group-definition-editor',
  templateUrl: './group-definition-editor.component.html',
  styleUrls: ['./group-definition-editor.component.scss']
})
export class GroupDefinitionEditorComponent implements OnInit {
  @ViewChild('editorForm') editorForm: NgForm;

  original = this.data.original;
  departments = this.data.departments;
  onUpdate = this.data.onUpdate;
  groupDef: GroupDefinition;

  loading = false;
  locked = false;
  canRemoveItem = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: GroupDefinitionEditorData,
    public dialogRef: MatDialogRef<GroupDefinitionEditorComponent>,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.groupDef = this.original.copy();
    this.setCanRemoveItem();
  }

  lockAndToad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = !!this.groupDef.deleted_at;
  }
  
  addItem() {
    this.groupDef.group_items.push(this.groupDef.getNewGroupItem());
    this.setCanRemoveItem();
  }

  setItem(updatedItem: GroupItem) {
    const idx = this.groupDef.group_items.findIndex(item => item.ordinal === updatedItem.ordinal);
    this.groupDef.group_items[idx] = updatedItem;
  }


  onDropped(event: CdkDragDrop<GroupItem>)
  {
    const movedItem = this.groupDef.group_items[event.previousIndex];

    this.groupDef.group_items.splice(event.previousIndex, 1);
    this.groupDef.group_items.splice(event.currentIndex, 0, movedItem);
    this.groupDef.group_items.map((_, idx) => this.groupDef.group_items[idx].ordinal = idx);
  }

  removeItem(removedItem: GroupItem) {
    const idx = this.groupDef.group_items.findIndex(item => item.ordinal === removedItem.ordinal);
    this.groupDef.group_items.splice(idx, 1);

    if (this.groupDef.group_items.length > 0) {
      this.groupDef.group_items.map((_, idx) => this.groupDef.group_items[idx].ordinal = idx);
      this.setCanRemoveItem();
    }
  }

  protected setCanRemoveItem(): void {
    this.canRemoveItem = this.groupDef.group_items.length > GroupDefinition.MIN_GROUP_COUNT ? true : false;
  }

  async save(): Promise<GroupDefinition>
  {
    if (this.editorForm.invalid) { return; }

    try
    {
      this.lockAndToad();
      await this.onUpdate(this.groupDef);
      this.snackbarNotification.saved();
      this.dialogRef.close();
    }
    finally
    {
      this.unlockAndUnload();
    }

    return (this.groupDef);
  }

  async onClose(): Promise<void>
  {
    this.dialogRef.close();
  }
}
