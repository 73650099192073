<mat-card class="medium-card">
<div class="card-inner" fxLayout="row wrap">
    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-title" fxFlex>Variants</div>
        <mat-form-field class="inline-input" 
            *ngIf="this.itemDefinition.variants_enabled">
            <mat-label>
                <mat-icon>filter_list</mat-icon>
            </mat-label>
            <input type="text" 
                name="keywordFilterField"
                [(ngModel)]="this.keywordFilter"
                matInput>
        </mat-form-field>
    </div> 

    <div fxLayout="column" fxLayoutAlign="center center" [fxHide]="this.itemDefinition.variants_enabled">
        Variants must be enabled before creating variants.
    </div>

    <div class="list" fxLayout="column" fxFlex="100" 
        [fxShow]="this.itemDefinition.variants_enabled && !!this.itemDefinition.variants.length"
        *ngFor="let variant of this.filteredVariants(); first as isFirst;">
        <div class="list-item" [class.list-item--first]="isFirst" fxLayout="column" fxFlex="100" fxLayoutGap="16px"
            (click)="this.onEdit(variant)">
            <div fxLayout="row" fxFlex fxLayoutGap="10px">
                <div fxLayout="column">
                    <div class="thumbnail-container">
                        <img *ngIf="variant.thumbnail !== null" 
                        [src]="variant.thumbnail.small.url">
                        <mat-icon *ngIf="variant.thumbnail === null" color="primary">image</mat-icon>
                    </div>
                </div>
                <div  fxLayout="column">
                    <p class="mat-subheading-2">{{ variant.display_name }}</p>
                </div>
            </div>
            <div class="list-item__row" fxLayout="row" fxFlex fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="0 0 calc(20% - 8px)">
                    <span class="mat-caption">Name</span>
                    <p class="mat-body-1">{{ variant.name }}</p>
                </div>
                <div fxLayout="column" fxFlex="0 0 calc(40% - 8px)">
                    <span class="mat-caption">Description</span>
                    <p class="mat-body-1">{{ variant.description }}</p>
                </div>
                <div fxLayout="column" fxFlex="0 0 calc(20% - 8px)">
                    <ng-container *ngIf="this.shouldDisplayQuantity()">
                    <span class="mat-caption">Quantity</span>
                    <p class="mat-body-1">{{ variant.quantity }}</p>
                    </ng-container>
                </div>
            </div>
            <div class="list-item__row" fxLayout="row" fxFlex fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="0 0 calc(20% - 8px)">
                    <span class="mat-caption">Code</span>
                    <p class="mat-body-1">{{ variant.code }}</p>
                </div>
                <div fxLayout="column" fxFlex="0 0 calc(20% - 8px)">
                    <span class="mat-caption">Tags</span>
                    <p class="mat-body-1">{{ variant.tags }}</p>
                </div>
                <div fxLayout="column" fxFlex="0 0 calc(20% - 8px)"
                    *ngFor="let attribute of this.itemDefinition.variant_fields">
                    <span class="mat-caption capitalize">{{ this.formatAttributeLabel(attribute) }}</span>
                    <p *ngIf="attribute !== 'default_pricing_type'" class="mat-body-1">{{ variant[attribute] | currencyFilter }}</p>
                    <p *ngIf="attribute === 'default_pricing_type'" class="mat-body-1">{{ this.getPriceTypeLabel(variant[attribute]) }}</p>
                </div>
            </div>
            <div class="list-item__row" fxLayout="row" fxFlex fxLayoutGap="8px"
                *ngIf="this.itemDefinition.variant_property_definitions.length">
                <div fxLayout="column" fxFlex="0 0 calc(20% - 8px)"
                    *ngFor="let def of this.itemDefinition.variant_property_definitions">
                    <span class="mat-caption capitalize">{{ this.formatAttributeLabel(def.name) }}</span>
                    <p class="mat-body-1" *ngIf="def.isText()">
                        {{ this.getPropFromDefinition(def, variant)?.value }}
                    </p>
                    <p class="mat-body-1" *ngIf="def.isDropdown()">
                        {{  this.getOptionFromDefinition(def, variant)?.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>


    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end end">
        <button type="button"
            mat-mini-fab
            (click)="this.onAdd()"
            [disabled]="!this.itemDefinition.variants_enabled">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
</mat-card>
