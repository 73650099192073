import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ChargeEditorData } from "./charge-editor-data.interface";
import { ChargeEditorComponent } from "./charge-editor.component";

@Injectable({
    providedIn: 'root'
})
export class ChargeEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: ChargeEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            data,
        }

        return this.dialog.open(ChargeEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
  