import 'reflect-metadata';
import { DateTimeUtility } from '../utilities/DateTime.utility'

export interface ModelDates {
    [index: string]: DateTimeUtility
}
export interface HasModelDates {
    _dates: ModelDates
}

export function date(target: HasModelDates, property: string): void {

    if ( !target._dates )
    {
        target._dates = {};
    }

    if ( !target.hasOwnProperty("_dates") )
    {
        target._dates = Object.assign({}, target._dates);
    }

    // Add entry to the prototype map
    target._dates[property] = null;

}