import { CurrencyPipe  } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: '[timeOfDayInput][ngModel]',  
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeOfDayInputDirective), multi: true },
    ]
})
export class TimeOfDayInputDirective implements ControlValueAccessor {
    onChange = (value: number) => {};
    onTouch = () => {};

    constructor(
        protected renderer: Renderer2,
        protected elementRef: ElementRef,
        protected currencyPipe: CurrencyPipe,
    ) {}

    /**
     * Parse time string in HH:mm format to seconds from midnight
     * 
     * @param {Sring} value 
     * @returns {Number}
     */
    protected parse(value: string): number 
    {
        const hours = value.split(':')[0];
        const minutes = value.split(':')[1];

        const hoursInSeconds = parseInt(hours) * 3600
        const minutesInSeconds = parseInt(minutes) * 60

        return hoursInSeconds + minutesInSeconds;
    }

    /**
     * 
     * Format seconds from midnight to HH:mm
     * 
     * @param {Number} value 
     */
    protected format(value: number): void 
    {
        const formattedValue: string = moment.duration(value, 'seconds').format('HH:mm');

        const element: HTMLInputElement = this.elementRef.nativeElement;

        this.renderer.setProperty(element, 'value', formattedValue);
    }

    @HostListener('input', ['$event.target.value'])
    input(value: string) 
    {
        this.onChange(this.parse(value));
    }

    // When this directive is applied to an element which also implements matInput, onTouch() is not being called for some reason.
    // Even though we register it successfully below.  This causes input validation issues, ie. <mat-error> doesn't show pop up with
    // any existing errors.  Feel like this is hacky but have tried many different methods of getting this working.  This solution has 
    // less overhead than the others.
    @HostListener('focusout', ['$event.target'])
    onFocusout() 
    {
        this.onTouch();
    }

    // ControlValueAccessor implemented methods

    writeValue(value: number): void 
    {
        this.format(value);
    }

    registerOnChange(fn: () => void): void 
    {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void 
    {
        this.onTouch = fn;
    }
}
