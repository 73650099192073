import { HttpResponse } from "../../../services/network/http-response";
import { BaseCollection } from "../Base.collection";
import { AppInjector } from "../../../services/app-injector.service";
import { RouteMap } from "../../../services/network/route-map.service";
import { ResourceItem, ResourceItemCollection } from "@beaconlite/models/contracts/ResourceItem.interface";
import { RentalResource } from "@beaconlite/models/RentalResource.model";

export class RentalGroupDefinitionResourceCollection extends BaseCollection<ResourceItem> implements ResourceItemCollection {

    constructor(options?: any) {
        super(RentalResource, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchRentalGroupDefinitionResources(params);
    }
}
