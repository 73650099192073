import { Component, Input, OnInit } from '@angular/core';
import { Branch, Client, ServiceOverride } from '@beaconlite/models';
import { ServiceOverrideEditorService } from './service-override-editor/service-override-editor.service';

@Component({
  selector: 'app-service-overrides',
  templateUrl: './service-overrides.component.html',
  styleUrls: ['./service-overrides.component.scss'],
})
export class ServiceOverridesComponent implements OnInit {

  @Input('overridableModel') model: Branch | Client;
  @Input('modelLabel') label: string;
  @Input() onServiceUpdate: (override: ServiceOverride, updatedOverride: ServiceOverride) => Promise<void>;
  @Input() onServiceRemove: (override: ServiceOverride) => Promise<void>;
  
  rateTableColumns = ['item', 'regular-rate', 'overtime-rate'];
  percentTableColumns = ['item', 'type', 'percent'];

  constructor(protected serviceOverrideEditor: ServiceOverrideEditorService) { }

  ngOnInit(): void {}

  async onEdit(override: ServiceOverride = new ServiceOverride({})): Promise<void>
  {
    this.serviceOverrideEditor.open({
      original: override,
      onServiceUpdate: this.onServiceUpdate,
      onServiceRemove: this.onServiceRemove, 
    });
  }

  hasRateOverrides()
  {
    return !!this.model.service_rate_overrides.length;
  }

  hasPercentOverrides()
  {
    return !!this.model.service_percent_overrides.length;
  }
}
