import { Injectable } from "@angular/core";
import * as moment from "moment";
import { DepartmentCollection } from '@beaconlite/models/collections';

@Injectable({
    providedIn: 'root'
})
export class LazyDepartments {
    protected _promise = null;
    protected _lastFetched: moment.Moment = null;
    protected _isFetching: boolean = null;

    async retrieve(): Promise<any> {
        this._isFetching = true;
        this._promise = (new DepartmentCollection().all())

        try
        {
            return await this._promise;
        }
        finally
        {
            this._lastFetched = moment();
            this._isFetching = false;
        }
    }

    async getAll(): Promise<any> {
        if (this._isFetching === true ||
            (this._lastFetched !== null && moment().diff(this._lastFetched) < 1000 * 3600))
        {
            return this._promise;
        }

        return this._promise = this.retrieve();
    }
}
