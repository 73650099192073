import { Injectable } from '@angular/core';
import { ResourceLock, ResourceLockService } from '@beaconlite/models';
import { AuthenticationService } from './auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LockVaultService {

  constructor(
    protected resourceLockService: ResourceLockService,
    protected authService: AuthenticationService
  ) { }

  protected _vault = new Map();

  async requestLock(resource, tag: string = 'none'): Promise<ResourceLock> {

    const resourceLock = await this.resourceLockService.get(resource.lockType, resource.id)

    resource.temps.lock = resourceLock;
    resourceLock.resource = resource;
    resourceLock.service = this;

    if (! this.ownResourceLock(resource)) return;

    // Allow the lock to auto refresh
    resourceLock.selfRefresh(true);

    // Update Vault
    let _vaultBox = this._vault.get(tag);

    if (! _vaultBox)
    {
      _vaultBox = new Map();
      this._vault.set(tag, _vaultBox);
    }

    _vaultBox.set(resourceLock.id, resource);

    return resourceLock;
  }

  async revokeLock(resource, tag: string = 'none'): Promise<void> {

    // Not your lock to revoke
    if (! this.ownResourceLock(resource)) return;

    await resource.temps.lock.revoke();

    // Update vault
    let _vaultBox = this._vault.get(tag);

    if (! _vaultBox) return;

    _vaultBox.delete(resource.temps.lock.id);

    // Update Model
    resource.temps.lock = null;
  }

  async revokeLocks(tag: string = 'none'): Promise<void> {
    let _vaultBox = this._vault.get(tag);

    if (! _vaultBox) return; 

    _vaultBox.forEach( (resource) => {
        this.revokeLock(resource, tag);
    });
  }

  ownResourceLock(resource): boolean {
    return this.hasResourceLock(resource) && this.authService.isCurrentUser(resource.temps.lock.account.id);
  }

  hasResourceLock(resource): boolean {
    return !!resource?.temps?.lock;
  }

  getLockOwner(resource): string {
    if (this.ownResourceLock(resource))
    {
      return 'You';
    }
    else if (this.hasResourceLock(resource))
    {
      return resource.temps.lock.account.fullname;
    }

    return 'Error';
  }
}
