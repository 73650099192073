<div class="index-sidebar" fxLayout="column">
    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="this.onSearch()" fxLayout="column" novalidate>
        <mat-form-field fxFlex>
            <mat-label class="mat-icon-label">
                <mat-icon>search</mat-icon>
            </mat-label>
            <input type="text"
                name="keywordField"
                [(ngModel)]="this.keyword" 
                matInput>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="end center">
            <button type="button" (click)="this.onAdd()" mat-mini-fab color="accent">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [fxShow]="this.charges.loading"></mat-progress-bar>

    <cdk-virtual-scroll-viewport itemSize="70">
        <mat-list dense>
            <mat-list-item class="index-list"
                [class.index-list__item--selected]="selectedModelId === charge.id"
                *cdkVirtualFor="let charge of this.charges"
                (click)="this.onEdit(charge)"
                matRipple>

                <div class="mat-list-text" fxLayout="column">
                    <h3>{{ charge.label }}</h3>
                    <h4>
                        <span>{{ charge.percent ? 'Percent ' : 'Flat ' }}</span>
                        <span>{{ charge.discount ? 'Discount' : 'Charge' }}</span>
                    </h4>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>

</div>

<mat-divider vertical></mat-divider>

<!-- Charge Single -->
<ui-view fxFlex fxLayout="column"></ui-view>
