import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { ServiceEditorService } from './service-editor/service-editor.service';
import { Service, WorkOrder } from '@beaconlite/models';
import { orderBy } from '../../../../../utilities/Sort.utility';

@Component({
  selector: 'work-order-tab-services',
  templateUrl: './tab-services.component.html',
})
export class TabServicesComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;
  @Input() shouldShowDepartment: boolean;
  @Input() onSave: () => Promise<void>;

  constructor(protected serviceEditor: ServiceEditorService) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  async onAddService(): Promise<Service|void>
  {
    if (! this.workOrder.canAddServices()) return;

    const newService = new Service({ 
      location:   this.workOrder.location,
      ordered_by: this.workOrder.ordered_by,
      started_at: this.workOrder.default_request_start,
    });

    return this.serviceEditor.open({
      workOrder: this.workOrder,
      original: newService,
      onServiceUpdate: this.onServiceUpdate,
      onServiceRemove: null,
    }); 
  }

  onServiceUpdate = async (service: Service): Promise<any> => 
  {
    if (! service) return;

    if (! service.exists())
    {
      this.workOrder.addService(service)
    }

    await this.onSave();
  }

  onServiceRemove = async (service: Service): Promise<any> => 
  {
    this.workOrder.removeService(service);
    await this.onSave();
  }

  protected _sortItems()
  {
    this.workOrder.services?.sort(orderBy('started_at', 'created_at'));
  }

}
