import { Component, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Client } from '@beaconlite/models';
import { ClientCollection } from '@beaconlite/models/collections';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { ExcludedClient } from '../sage50-inferaces/sage50-interfaces';

export interface Sage50ExcludedClientData {
  original: Client,
  excludedClients: ExcludedClient[],
  onUpdate(client: Client): Promise<void>,
  onDelete(id: string): Promise<void>
}

@Injectable({
  providedIn: 'root'
})
export class Sage50ExcludedClientService {

  constructor(protected dialog: MatDialog) {}

  async open(data: Sage50ExcludedClientData): Promise<boolean> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data
    }

    return this.dialog.open(Sage50ExcludedClientEditorComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-sage50-excluded-client-editor',
  templateUrl: './sage50-excluded-client-editor.component.html',
  styleUrls: ['./sage50-excluded-client-editor.component.scss']
})
export class Sage50ExcludedClientEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('clientSearchTextField') clientSearchTextField: NgModel;

  original = this.data.original;
  excludedClients = this.data.excludedClients;
  onUpdate = this.data.onUpdate;
  onDelete = this.data.onDelete;

  promisedClients: Promise<Client[]>;
  clientSearchText: string = null;
  selectedClient: Client = null;
  isSaving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: Sage50ExcludedClientData,
    public dialogRef: MatDialogRef<Sage50ExcludedClientEditorComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  ngOnInit(): void {
    this.selectedClient = this.original;
  }

  onQueryClients(): void
  {
    this.promisedClients = (new ClientCollection()).all({
      order: 'asc',
      name: this.clientSearchText,
    });
  }

  onClientSelected(client: Client): void
  {
    this.selectedClient = null;

    if (!! client)
    {
      this.selectedClient = client;
    }
    this._validateClientField();
  }

  protected _validateClientField(): void
  {
    if (! this.selectedClient) { return };

    if (this.selectedClient.suspended == true)
    {
      this.editorForm.controls.clientSearchTextField.setErrors({ suspended: true });
    }

    if (this.selectedClient.discarded == true) 
    { 
      this.editorForm.controls.clientSearchTextField.setErrors({ discarded: true });
    }

    if (this.excludedClients.find(element => element.id === this.selectedClient.id ))
    {
      this.editorForm.controls.clientSearchTextField.setErrors({ alreadyPresent: true });
    }
  }

  displaySelectedClient = (client?: Client): string =>
  {
    if (!!this.original && this.clientSearchTextField.pristine)
    {
      this.clientSearchTextField.control.setErrors(null);
      return this.original.name;
    }

    return client?.name || '';
  }

  async save(): Promise<void>
  {
    if (this.editorForm.invalid) { return; }

    this.isSaving = true; 

    await this.onUpdate(this.selectedClient);
    this.dialogRef.close();

    this.isSaving = false;
  }

  async delete(): Promise<void>
  {
    this.isSaving = true;

    if (await this.dialogNotification.removeConfirm())
    {
      await this.onDelete(this.selectedClient.id);
      this.dialogRef.close();
    }

    this.isSaving = false;
  }
}
