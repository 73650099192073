import * as d3 from 'd3';
import * as moment from 'moment';
import { AfterViewInit, Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GanttBase } from '../../../components/gantt-base/gantt-base';
import { Dispatch } from '@beaconlite/models';
import { DispatchCollection } from '@beaconlite/models/collections';
import { UnixTimestamp } from '@beaconlite/types';

export interface Datum
{
    id: string,
    work_order_id: string,
    travel_to: Date,
    travel_from: Date,
    scheduled_at: Date,
    scheduled_end_at: Date,
    is_projected: boolean,
    state_name: string,
}

type Chunk = Datum[];

@Component( {
    selector: 'gantt-dispatch',
    templateUrl: '../../../components/gantt-base/gantt-base.html',
} )
export class GanttDispatchComponent extends GanttBase<Chunk> implements OnInit, DoCheck, OnDestroy, AfterViewInit
{
    @ViewChild( 'graphContainer' ) graphContainer: ElementRef<any>
    @Input() sourceData: Dispatch[] = [];
    @Input() clickHandler: ( event: any, datum: Datum ) => void;
    @Input() scrollHandler: ( event: any ) => void

    private _isFirstRender = true;
    private _isFirstScroll = true
    private _sourceLength = 0;
    private _intervalId: ReturnType<typeof setInterval>;
    private _scrollIntervalId: ReturnType<typeof setInterval>;
    private _targetX: number;


    // Render Properties
    private _renderData: Datum[] = [];
    private _yValues: Dispatch[] = [];
    private _yIndexMap: Map<string, number>;

    constructor(
        protected renderer: Renderer2
    ) { super( renderer ) }

    ngAfterViewInit()
    {
        this.graphContainer.nativeElement.classList.add('schedule-graph--dispatches');

        this.renderer.listen( this.graphContainer.nativeElement, 'scroll', this.scrollHandler );

        this.onResize();
        this.primeScaleValues();
    }

    ngDoCheck(): void
    {
        if ( this._sourceLength === this.sourceData.length ) { return; }

        this._sourceLength = this.sourceData.length
        this.render()
    }

    ngOnDestroy()
    {
        super.ngOnDestroy();
        clearInterval( this._intervalId );
        clearInterval( this._scrollIntervalId );
    }

    onGraphScroll( event: any )
    {
        this.adjustHorizontalScroll( event.target )
    }

    async loadInitialChunks(date: Date): Promise<Chunk[]>
    {
        return;
    }

    async fetchChunk( start: UnixTimestamp, end: UnixTimestamp ): Promise<Chunk>
    {
        return;
    }

    private _processDispatches( dispatches: Dispatch[] ): void
    {
        const chunk: Chunk = new Array<Datum>()

        dispatches.forEach( dispatch =>
        {

            const { id, work_order_id, scheduled_at, scheduled_end_at, travel_time, state_name } = dispatch;
            const startDate = moment.unix( scheduled_at );
            let endDate: moment.Moment;
            let isProjected = false;

            if ( scheduled_end_at )
            {
                endDate = moment.unix( scheduled_end_at );
            }
            else
            {
                // Project 4 hours
                // TODO: make configurable
                endDate = moment.unix( scheduled_at + 3600 * 4 );
                isProjected = true;
            }

            const dispatchData: Datum = {
                id,
                work_order_id,
                travel_to: startDate.clone().subtract( travel_time, 's' ).toDate(),
                travel_from: endDate.clone().add( travel_time, 's' ).toDate(),
                scheduled_at: startDate.toDate(),
                scheduled_end_at: endDate.toDate(),
                is_projected: isProjected,
                state_name,
            }

            chunk.push( dispatchData );

        } );

        Array.prototype.push.apply( this._renderData, chunk );
    }

    protected _gatherRenderData(): void
    {
        // Grab the disaptches
        this._yValues = this.sourceData;

        // Setup the index map
        this._yIndexMap = this._yValues.reduce( ( map, yValue, index ) =>
        {
            map.set( yValue.id, index );
            return map;
        }, new Map<string, number>() );

        // Process new dispatches.
        if ( this._renderData.length !== this._yValues.length )
        {
            const newDispatches = this._yValues.slice( this._renderData.length );
            this._processDispatches( newDispatches );
        }
    }

    protected _primeForRender()
    {
        // Grab the disaptches
        this._yValues = this.sourceData;

        const graphEnd = moment(this._renderData[0].scheduled_end_at).startOf('day').add(7, 'days');
        const graphStart = moment(this._renderData[this._renderData.length-1].scheduled_at).startOf('day').subtract(7, 'days'); 

        // const graphEnd = moment( this._rendered_end ).startOf( 'day' )
        // const graphStart = moment( this._rendered_start ).startOf( 'day' )
        const dateDiff = graphEnd.diff( graphStart, 'days' )

        // Date diff will be one day short since we set the end to be the start of day.
        const days = dateDiff;

        this._contentWidth = days * this.ticksPerDay * this._tickWidth || this._viewWidth;
        this._width = this._contentWidth + this._margin.left + this._margin.right;

        this._contentHeight = this._yValues.length * this.yHeight
        this._height = Math.max( this._contentHeight + this._margin.top + this._margin.bottom, this._viewHeight - this._xAxisHeight );

        // Prep X axis
        this._xFunction = d3.scaleUtc()
            .domain( [ d3.timeDay( graphStart.toDate() ), d3.timeDay( graphEnd.toDate() ) ] )
            .range( [ this._margin.left, this._margin.left + this._contentWidth ] )

        // Prep Y axis
        const paddingRatio = ( this.yHeight - this._desiredBandWidth ) / this.yHeight;
        const bandRangeStart = this._margin.top + this._indexListOffset;
        this._yFunction = d3.scaleBand()
            .domain( this._yValues.map( ( { id } ) => id ) )
            .range( [ bandRangeStart, bandRangeStart + this._contentHeight ] )
            .paddingOuter( paddingRatio / 2 )
            .paddingInner( paddingRatio );

    }

    protected render() 
    {
        this.onResize();
        this._isFirstScroll = true;

        this._gatherRenderData();
        this._primeForRender();

        // Clear, prep for re-draw
        // TODO: try and find a better alternative than a cast to any
        d3.select( ".schedule-graph" ).selectAll( [ 'path', 'g', 'line' ] as any ).remove();

        // TODO: Common to all gants?
        const xAxis = g => g
            .attr( "transform", `translate(0,${this._xAxisHeight})` )
            .attr( 'class', 'x-axis' )
            .call( d3.axisTop( this._xFunction )
                .ticks( this.ticks )
                .tickSize( 0 )
                .tickPadding( 5 )
                .tickFormat( this.hourFormat )
            )
            .call( g => g.select( ".domain" ).remove() )
            .call( g => g.selectAll( '.tick' ).insert( 'rect', ':first-child' )
                .attr( 'width', this._tickWidth )
                .attr( 'height', 17 )
                .attr( 'y', -18 )
            )
            .call( g => g.selectAll( '.tick text' )
                .attr( 'transform', 'translate(5, 0)' )
            )
            .call( g => g.selectAll( `.tick:nth-of-type(${this.ticksPerDay}n+1) text` ).clone()
                .attr( 'y', '-30' )
                .attr( 'transform', '' ) // Removed cloned transform
                .text( ( d: Date ) => this.dateFormat( d ) )
            )
            .call( g => g.selectAll( '.tick text' ).attr( 'text-anchor', 'start' ) );

        // TODO: Common to all gants?
        // Vertical lines in the graph
        const contentAxis = g => g
            .attr( 'class', 'x-axis' )
            .call( d3.axisTop( this._xFunction )
                .ticks( this.ticks )
                .tickSize( 0 )
                .tickSizeInner( -this._height )
            )
            .call( g => g.select( ".domain" ).remove() )
            .call( g => g.select( ".tick text" ).remove() );

        // TODO: Common to all gants?
        // Header appended separately to remain vertically fixed
        const header = d3.select( ".schedule-graph .graph__header" )
            .attr( "viewBox", [ 0, 0, this._width, this._xAxisHeight ] as any )
            .attr( "width", this._width )
            .attr( "height", this._xAxisHeight )

        header.append( 'rect' )
            .attr( 'width', '100%' )
            .attr( 'height', this._xAxisHeight )
            .attr( 'fill', '#FAFAFA' )

        header.append( 'g' )
            .call( xAxis );

        // TODO: Common to all gants?
        const svg = d3.select( ".schedule-graph .graph__content" )
            .attr( "viewBox", [ 0, 0, this._width, this._height ] as any )
            .attr( "width", this._width )
            .attr( "height", this._height )

        svg.append( 'g' )
            .call( contentAxis );

        // TODO: Common to all gants?
        // Now line(s)
        header.append( "line" )
            .attr( 'class', 'now' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y1', 32 )
            .attr( 'y2', this._xAxisHeight )
        svg.append( "line" )
            .attr( 'class', 'now' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y2', this._height );

        // Keep the now line updated
        if ( this._isFirstRender )
        {
            this._intervalId = setInterval( () =>
            {
                d3.selectAll( ".schedule-graph .now" )
                    .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` );
            }, 1000 * 60 ) // 1 Minute
        }

        // TODO: Common to all gants?
        // Add the time portion of the mouse move time
        header.select( '.x-axis' ).append( 'g' )
            .attr( 'class', 'mousetime' )
            .attr( 'style', 'display:none' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, -30)` )
            .call( g => g.append( 'rect' )
                .attr( 'width', '100' )
                .attr( 'height', '14' )
                .attr( 'fill', '#FAFAFA' )
                .attr( 'transform', `translate(-50, -11)` )
            )
            .call( g => g.append( 'text' )
                .attr( 'fill', 'currentColor' )
                .text( this.hourFormat( new Date() ) )
                .call( text =>
                {
                    // const textWidth = text.node().getComputedTextLength() + 10;
                    // Hardcoded due to performance hit. >300ms vs <1ms
                    // TODO: Determine a better way to calculate this value
                    const textWidth = 53;
                    g.select( 'rect' )
                        .attr( 'width', textWidth )
                        .attr( 'transform', `translate(-${textWidth / 2}, -11)` )
                } )
            )

        // Add the mouse move time lines
        header.append( "line" )
            .attr( 'class', 'mousetime' )
            .attr( 'style', 'display:none' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y1', 32 )
            .attr( 'y2', this._xAxisHeight )
        svg.append( "line" )
            .attr( 'class', 'mousetime' )
            .attr( 'style', 'display:none' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y2', this._height );

        const handleMouseLeaveTime = () =>
        {
            d3.selectAll( "line.mousetime" )
                .attr( 'style', 'display:none' );

            header.select( 'g.mousetime' )
                .attr( 'style', 'display:none' );
        }

        const handleMouseMoveTime = ( event ) =>
        {
            // Offset mx by 2 so the cusror does not visually overlap
            const mx = d3.pointer( event )[ 0 ] - 2;

            // Update the line's position
            d3.selectAll( "line.mousetime" )
                .attr( 'style', '' )
                .attr( 'transform', `translate(${mx}, 0)` )

            // Update the time's position and value
            header.select( 'g.mousetime' )
                .attr( 'style', '' )
                .attr( 'transform', `translate(${mx}, -30)` )
                .select( 'text' )
                // For some reason the mouse-x positon is off by one
                .text( this.hourFormat( this._xFunction.invert( mx - 1 ) ) );
        }

        header.on( 'touchmove mousemove', handleMouseMoveTime );
        header.on( 'touchend mouseleave', handleMouseLeaveTime );
        svg.on( 'touchmove mousemove', handleMouseMoveTime );
        svg.on( 'touchend mouseleave', handleMouseLeaveTime );

        // Gantt Bars
        svg.selectAll<SVGGElement, Datum>( '.dispatch' )
            .data<Datum>( this._renderData, d => d.scheduled_at.valueOf() )
            .join( 'g' )
            .attr( 'class', d =>
            {
                let clazz = `dispatch ${d.state_name}`
                if ( d.is_projected ) { clazz += ' projected' }
                if ( this._isOverlapping( this._renderData, d ) ) { clazz += ' overlapping' };
                return clazz;
            } )
            .on( 'click', ( event, d ) => this.clickHandler( event, d ) )
            // Primary section of the bar
            .call( g => g.append( 'rect' )
                .attr( 'rx', d => d.travel_to.getTime() == d.scheduled_at.getTime() ? this._radius : 0 )
                .attr( 'y', d => this._yFunction( d.id ) )
                .attr( 'x', d => this._xFunction( d.scheduled_at ) )
                .attr( 'height', this._yFunction.bandwidth() )
                .attr( 'width', d =>
                {
                    return this._xFunction( d.scheduled_end_at ) - this._xFunction( d.scheduled_at )
                } )
            )
            // Starting portion (lefthand bookend)
            .call( g =>
            {
                g.insert( 'path', ':first-child' )
                    .attr( 'class', 'bookend' )
                    .attr( 'd', d => `M ${this._xFunction( d.scheduled_at )} ${this._yFunction( d.id )} 
                        H ${this._xFunction( d.travel_to ) + this._radius} 
                        a ${this._radius} ${this._radius} 90 0 0 -${this._radius} ${this._radius} 
                        v ${this._yFunction.bandwidth() - 2 * this._radius} 
                        a ${this._radius} ${this._radius} 90 0 0 ${this._radius} ${this._radius} 
                        H ${this._xFunction( d.scheduled_at )} 
                        v ${-this._yFunction.bandwidth()}` )
            } )
            // Ending portion (righthand bookend)
            .call( g =>
            {
                g.append( 'path' )
                    .attr( 'class', 'bookend' )
                    .attr( 'd', d =>
                    {
                        // No end (point)
                        if ( d.is_projected )
                        {
                            return `M ${this._xFunction( d.scheduled_end_at ) - this._radius} ${this._yFunction( d.id )}
                                    h ${this._radius}
                                    l ${this._yFunction.bandwidth() / 4} ${this._yFunction.bandwidth() / 2}
                                    l -${this._yFunction.bandwidth() / 4} ${this._yFunction.bandwidth() / 2}
                                    h -${this._radius}
                                    v ${-this._yFunction.bandwidth()}`
                            // Has end (rounded)
                        } else
                        {
                            return `M ${this._xFunction( d.scheduled_end_at ) - this._radius} ${this._yFunction( d.id )} 
                                    H ${this._xFunction( d.travel_from ) - this._radius} 
                                    a ${this._radius} ${this._radius} 90 0 1 ${this._radius} ${this._radius} 
                                    v ${this._yFunction.bandwidth() - 2 * this._radius} 
                                    a ${this._radius} ${this._radius} 90 0 1 -${this._radius} ${this._radius} 
                                    H ${this._xFunction( d.scheduled_end_at )} 
                                    v ${-this._yFunction.bandwidth()}`
                        }
                    } )
            } )

        const element = this.graphContainer.nativeElement;
        this.adjustHorizontalScroll(element);

        this._isFirstRender = false;
    }

    private _isOverlapping( renderData: Datum[], datum: Datum )
    {
        return false;
    }

    protected adjustHorizontalScroll( target: Element ): void
    {
        const earliestIndex = this._getTopDisplayedIndex( target )
        if ( earliestIndex == undefined ) { return; }
        const targetX = this._getScrollXTarget( target, earliestIndex )
        if ( targetX == undefined ) { return; }

        if ( this._isFirstScroll )
        {
            clearInterval( this._scrollIntervalId );
            this._isFirstScroll = false
            target.scrollLeft = targetX
        }
        else if ( this._targetX != targetX )
        {
            clearInterval( this._scrollIntervalId );
            // Every 5ms for 50 iterations
            const animateScroll = this._animateScroll( target, targetX, 50 );
            this._scrollIntervalId = setInterval( animateScroll, 5 );
        }

        this._targetX = targetX
    }

    private _getTopDisplayedIndex( target: Element ): number
    {
        const eachBand = this._yFunction.step();
        //                      ScrollY minus list padding 
        const scrollYLocation = target.scrollTop - this._margin.top
        const index = Math.round( Math.max( 0, scrollYLocation ) / eachBand );
        return index;
    }

    private _getScrollXTarget( target: Element, earliestIndex: number ): number
    {
        if ( earliestIndex == undefined ) { return; }
        if ( this._renderData.length == 0 ) { return; }
        const dispatch = this._renderData[ earliestIndex ]
        // Rough
        const startX = this._xFunction( dispatch.travel_to )
        const endX = this._xFunction( dispatch.travel_from )

        let minScrollLeft = startX - this._viewWidth + ( endX - startX + 25 );
        let maxY = target.scrollTop + this._viewHeight - this._xAxisHeight;


        let leftMostX = startX;
        for ( let i = 1; earliestIndex + i <= this._renderData.length; i++ )
        {
            let checkingIndex = earliestIndex + i
            if ( checkingIndex >= this._renderData.length ) { break; };
            let checkingX = this._xFunction( this._renderData[ checkingIndex ].travel_to )
            let checkingY = this._yFunction( this._renderData[ checkingIndex ].id ) - this._yFunction.bandwidth()

            if ( checkingX < minScrollLeft ||
                checkingY > maxY )
            {
                break;
            }

            leftMostX = checkingX;
        }

        let targetX = minScrollLeft - ( minScrollLeft - leftMostX ) / 2;
        return targetX
    }

    private _animateScroll( target: Element, targetX: number, expectedSteps: number )
    {
        function linear( t: number ) { return t }
        let counter = 1
        const startX = target.scrollLeft
        const distance = targetX - startX
        const step = distance / expectedSteps

        return () =>
        {
            if ( counter >= expectedSteps )
            {
                clearInterval( this._scrollIntervalId );
            }
            const val = counter * step
            const ratio = val / distance
            target.scrollLeft = startX + linear( ratio ) * distance
            counter++;
        }
    }

}
