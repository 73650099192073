import { HttpResponse } from "../../services/network/http-response";
import { VariantRental } from "../VariantRental.model";
import { BaseCollection } from "./Base.collection";
import { AppInjector } from "../../services/app-injector.service";
import { RouteMap } from "../../services/network/route-map.service";

export class VariantRentalCollection extends BaseCollection<VariantRental> {

    constructor(options?: any) {
        super(VariantRental, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchVariantRentals(params);
    }
}
