import { Component, Input } from "@angular/core";
import { VariantDefinition } from "@beaconlite/models/contracts/VariantDefinition.interface";
import { VariantConfigurationModalService } from '@beaconlite/components/variants/variant-configuration-modal/variant-configuration-modal.component';

export interface VariantConfigurationData {
  variantsEnabled: boolean;
  rateVariantsEnabled: boolean;
}

@Component({
  selector: 'app-variant-configuration',
  templateUrl: './variant-configuration.component.html',
  styleUrls: ['./variant-configuration.component.scss']
})
export class VariantConfigurationComponent {
  
  @Input() itemDefinition: VariantDefinition;
  @Input() onVariantConfigUpdate: (variantConfiguration: VariantConfigurationData) => Promise<void>;

  variantsActiveString = 'Disabled';
  rateVariantsActiveString = 'Disabled';

  constructor(
    protected variantConfigurationModalService: VariantConfigurationModalService
  ){}

  ngOnInit() 
  {
    this._updateVariantStatusStrings();
  }

  async onEditVariantConfig(): Promise<void> 
  {
    await this.variantConfigurationModalService.open({
      itemDefinition: this.itemDefinition,
      onVariantConfigUpdate: this.onVariantConfigUpdate
    });
    
    this._updateVariantStatusStrings();
  }

  protected _updateVariantStatusStrings(): void
  {
    this.variantsActiveString = this.itemDefinition.variants_enabled ? 'Enabled' : 'Disabled';
    this.rateVariantsActiveString = this.itemDefinition.variant_rates_enabled ? 'Enabled' : 'Disabled';
  }
}
