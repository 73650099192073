<div class="index-sidebar" fxLayout="column">
    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="this.onSearch()" novalidate>
        <div fxLayout="column" fxLayoutGap="10px">
            <mat-form-field>
                <mat-label class="mat-icon-label">
                    <mat-icon>search</mat-icon>
                </mat-label>
                <input type="text" 
                    name="keywordField"
                    [(ngModel)]="this.keyword"
                    matInput>
            </mat-form-field>

            <div class="tab" fxLayout="row wrap">
                <mat-checkbox class="search-all" 
                    [(ngModel)]="this.searchAll"
                    #searchAllField="ngModel"
                    name="searchAllField">
                    <span class="mat-caption">Search all fields</span>
                </mat-checkbox>
                <input id="tab-one" type="checkbox" name="tabs">
                <label class="tab-one-label" for="tab-one" fxFlex>More Options</label>

                <div class="tab-content" fxLayout="column" fxFlex="100">
                    <mat-form-field fxFlex>
                        <mat-label>Branch</mat-label>
                        <input type="text"
                            [(ngModel)]="this.branchSearchText"
                            name="branchSearchTextField"
                            #branchSearchTextField="ngModel"
                            (ngModelChange)="this.onQueryBranches()"
                            (blur)="this.clearSearchText(selectedBranch, 'branch')"
                            [matAutocomplete]="branchAuto"
                            matInput>
                        <button type="button" 
                            mat-icon-button 
                            matSuffix
                            *ngIf="this.branchSearchText"
                            (click)="this.clearBranchSelection()"> 
                            <mat-icon color="primary">close</mat-icon>
                        </button>
                        <mat-autocomplete #branchAuto="matAutocomplete"
                            (optionSelected)="this.onBranchOptionSelected($event.option.value)"
                            [displayWith]="this.displaySelectedBranch">
                            <mat-option *ngFor="let branch of promisedBranches | async" [value]="branch">
                                {{ branch.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Client</mat-label>
                        <input type="text"
                            [(ngModel)]="this.clientSearchText"
                            name="clientSearchTextField"
                            #clientSearchTextField="ngModel"
                            (ngModelChange)="this.onQueryClients()"
                            (blur)="this.clearSearchText(selectedClient, 'client')"
                            [matAutocomplete]="clientAuto"
                            matInput>
                        <button type="button" 
                            mat-icon-button 
                            matSuffix
                            *ngIf="this.clientSearchText"
                            (click)="this.clearClientSelection()"> 
                            <mat-icon color="primary">close</mat-icon>
                        </button>
                        <mat-autocomplete #clientAuto="matAutocomplete"
                            (optionSelected)="this.onClientOptionSelected($event.option.value)"
                            [displayWith]="this.displaySelectedClient">
                            <mat-option *ngFor="let client of promisedClients | async" [value]="client">
                                {{ client.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <mat-form-field fxFlex="48">
                            <mat-label>Start Date</mat-label>
                            <input type="text"
                                [matDatepicker]="startDatePicker"
                                [(ngModel)]="this.selectedStartDate"
                                name="selectedStartDateField"
                                (focus)="startDatePicker.open()"
                                matInput>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="48">
                            <mat-label>End Date</mat-label>
                            <input type="text"
                                [matDatepicker]="endDatePicker"
                                [(ngModel)]="this.selectedEndDate"
                                name="selectedEndDateField"
                                (focus)="endDatePicker.open()"
                                matInput>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div> 

                    <mat-form-field fxFlex>
                        <mat-label>Filter by State</mat-label>
                        <mat-select [(ngModel)]="this.selectedState" name="selectedStateField">
                            <mat-option value="''">All</mat-option>
                            <mat-option value="draft">Draft</mat-option>
                            <mat-option value="open">Open</mat-option>
                            <mat-option value="active">In Progress</mat-option>
                            <mat-option value="dispatched">Dispatched</mat-option>
                            <mat-option value="actioned">Actioned</mat-option>
                            <mat-option value="complete">Complete</mat-option>

                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Filter by Type</mat-label>
                        <mat-select [(ngModel)]="this.selectedType" name="selectedTypeField">
                            <mat-option value="''">All</mat-option>
                            <mat-option value="delivery">Delivery</mat-option>
                            <mat-option value="retrieval">Retrieval</mat-option>

                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex *ngIf="this.departments.length > 1">
                        <mat-label>Filter by Department</mat-label>
                        <mat-select [(ngModel)]="this.selectedDepartment" name="selectedDepartmentField">
                            <mat-option value="''">All</mat-option>
                            <mat-option *ngFor="let department of this.departments" [value]="department.id">
                                {{ department.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Tag</mat-label>
                        <input type="text"
                            name="tagField"
                            [(ngModel)]="this.tag"
                            matInput>
                    </mat-form-field>
                </div>
            </div>


            <div fxLayout="row" fxLayoutAlign="end center" color="accent">
                <button type="submit" mat-raised-button color="primary">Search</button>
            </div>
        </div>
    </form>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [fxShow]="this.dispatchSource.loading"></mat-progress-bar>

    <cdk-virtual-scroll-viewport [itemSize]="this.indexItemSize" #indexContainer>
        <mat-list dense>
            <mat-list-item class="index-list" 
                *cdkVirtualFor="let dispatch of this.dispatchSource" 
                [class.index-list__item--selected]="selectedModelId === dispatch.id"
                (click)="this.onView(dispatch)" 
                matRipple>
                <div matListAvatar class="dispatch-list-state {{ dispatch.state_name }}">{{ dispatch.state_letter }}</div>
                <div class="mat-list-text" fxLayout="column">
                    <div fxLayout="row">
                        <h3 fxFlex>{{ dispatch.client.name }}</h3> 
                        <p>{{ dispatch.formatted_serial_id }}</p>
                    </div>
                    <div fxLayout="row">
                        <h4 fxFlex>{{ dispatch.scheduled_at | dateFilter:'medium' }}</h4>
                        <p>{{ this.getWOSerial(dispatch) }}</p>
                    </div>
                    <p>{{ dispatch.location }}</p>
                </div>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>
</div>

<mat-divider vertical></mat-divider>

<ui-view fxFlex [fxHide]="this.isOnIndex"></ui-view>
<div [fxShow]="this.isOnIndex" fxLayout="column" fxFlex>
    <div class="view-toggle-panel" fxLayout="row" fxLayoutAlign="end start" 
        [class.view-toggle-panel--summary]="this.scheduleView == this.SCHEDULE_VIEW_SUMMARY">
        <mat-button-toggle-group [(ngModel)]="this.scheduleView"
            (ngModelChange)="this.onScheduleViewChanged()">
            <mat-button-toggle [value]="this.SCHEDULE_VIEW_GANTT">
                <mat-icon color="primary">view_timeline</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="this.SCHEDULE_VIEW_SUMMARY">
                <mat-icon color="primary">view_agenda</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div> 
    <cdk-virtual-scroll-viewport class="summary-view-viewport" 
        [itemSize]="this.summaryItemSize"
        (scroll)="this.onScheduleScroll($event)"
        #summaryContainer
        [fxShow]="this.scheduleView == this.SCHEDULE_VIEW_SUMMARY"> 
        <dispatch-summary class="large-card"
            *cdkVirtualFor="let dispatch of this.dispatchSource"
            [shouldShowDepartment]="this.departments.length > 1"
            [dispatch]="dispatch"
            [stateOnView]="'protected.dispatch-schedule.single'">
        </dispatch-summary>
    </cdk-virtual-scroll-viewport> 
    <gantt-dispatch 
        fxFlex
        [fxShow]="this.scheduleView == this.SCHEDULE_VIEW_GANTT"
        [sourceData]="this.dispatchSource.cachedData" 
        [scrollHandler]="this.onScheduleScroll"
        [clickHandler]="this.onGanttItemClick"
        yHeight="70" ></gantt-dispatch>
</div>
