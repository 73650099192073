import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntegrationTaxGroupEditorData } from './integration-tax-group-editor-data.interface';

// TODO: Jira task BL-509. This editor only works for quickbooks, not all integrations. Relocate
// into quickbooks directory and rename appropriately.
@Component({
  selector: 'app-tax-group-editor',
  templateUrl: './integration-tax-group-editor.component.html',
})
export class IntegrationTaxGroupEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  taxRate = this.data.taxRate;
  taxList = this.data.taxList;
  settings = this.data.settings;
  onUpdate = this.data.onUpdate;

  saving = false;
  selectedTaxIndex: number = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: IntegrationTaxGroupEditorData,
    public dialogRef: MatDialogRef<IntegrationTaxGroupEditorComponent>,
  ) { }

  ngOnInit(): void 
  {
    const externalName = this.settings.taxes[this.taxRate.tag]?.name;
    this.selectedTaxIndex = this.taxList.findIndex(taxItem => taxItem.name === externalName);
  }

  async onSave(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) return false;    

    this.saving = true;

    try
    {
      await this.onUpdate(this.taxList[this.selectedTaxIndex]);
      this.dialogRef.close();
    }
    finally
    {
      this.saving = false;
    }
  }

}
