import { BaseModel } from "../Base.model";
import { Thumbnail } from "../Thumbnail.model";
import { GroupItem } from "./GroupItem.interface";

export abstract class GroupDefinition extends BaseModel {
    abstract save();
    abstract delete();
    abstract reload();
    abstract getNewGroupItem(): GroupItem;
    name: string;
    description: string;
    tags: string;
    code: string;
    department_id: string;
    group_items: GroupItem[];
    thumbnail?: Thumbnail;
    deleted_at?: number;
    static readonly MIN_GROUP_COUNT = 2;
    static readonly GROUP_DEFINITION_ICON = 'dataset';
}
