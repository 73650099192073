
import { Ng2StateDeclaration } from '@uirouter/angular';
import { PasswordResetComponent } from './password-reset.component';

/**
 * This state allows the user to set their application preferences
 */
export const PasswordResetState: Ng2StateDeclaration = {
  
  name: 'resetPassword',
  url: '/password/reset?email?token',
  component: PasswordResetComponent,
  data: { 
    requiresAuth: false,
    pageTitle: 'Reset Password', 
  }
}
