<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
<div class="login-form">
    <img src="/assets/img/{{ company.logo_colour }}" alt="Company Logo" class="login-form-logo">
    <mat-card>
        <mat-progress-bar mode="indeterminate" [fxShow]="pending"></mat-progress-bar>
        <div fxLayout="column">
        <mat-card-title class="text-center">
          <span class="md-headline">Send password reset email</span>
        </mat-card-title>
        <mat-card-content [ngSwitch]="resetWasSent">
          <form #requestResetForm="ngForm" (ngSubmit)="onRequestReset()" *ngSwitchDefault>
              <mat-form-field class="md-block" appearance="outline" hideRequiredMarker>
                  <mat-label>Email</mat-label>
                  <input [(ngModel)]="user.username" 
                  name="username" 
                  #username="ngModel" 
                  type="email"
                  matInput
                  required
                  email>
                  <mat-error *ngIf="username.hasError('email')">Invalid email format.</mat-error>
                  <mat-error *ngIf="username.hasError('required')">Email is required.</mat-error>
                  <mat-error *ngIf="username.hasError('failed')">Email does not exist.</mat-error>
              </mat-form-field>

              <div fxLayout="row" fxLayoutAlign="center center">
                  <button mat-raised-button type="submit" color="accent" [disabled]="pending">
                      Reset password
                  </button>
              </div>
          </form>
          <p *ngSwitchCase="true">A password reset link has been sent to the provided email.</p>
        </mat-card-content>
    </div>
    </mat-card>
</div>

<!-- Padding to offset vertical centering -->
<span fxFlex="25"></span>

</div>