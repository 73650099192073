<div class="work-order-toolbar" fxLayout="column">
    <div class="work-order-toolbar__row" fxLayout="row" fxLayoutAlign=" center">
        <span class="mat-headline no-mb" fxFlex>{{ workOrder.formatted_serial_id }}</span>

        <div fxLayout="row" fxLayoutAlign=" center" [fxShow]="lockVault.hasResourceLock(workOrder) && !loading">
            <mat-icon>lock_outline</mat-icon> 
            <span class="mat-caption">
                Locked by <strong>{{ lockVault.getLockOwner(workOrder) }}</strong>
            </span>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="workOrderMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #workOrderMenu="matMenu">
            <button mat-menu-item 
                (click)="onCancel()">
                <mat-icon>arrow_back</mat-icon>
                Back
            </button>

            <button mat-menu-item 
                (click)="onDuplicateWorkOrder()">
                <mat-icon>content_copy</mat-icon>
                Duplicate
            </button>

            <button mat-menu-item
                *ngIf="workOrder.estimate"
                (click)="onTransformEstimate()">
                <mat-icon>move_to_inbox</mat-icon>
                Transform to Work Order
            </button>

            <button mat-menu-item
                *ngIf="!workOrder.invoiced"
                (click)="onAddIssue()">
                <mat-icon>outlined_flag</mat-icon>
                Add Issue
            </button>

            <button mat-menu-item
                *ngIf="!workOrder.locked && workOrder.discarded_at"
                (click)="onRecover()">
                <mat-icon>unarchive</mat-icon>
                Recover
            </button>

            <button mat-menu-item
                *ngIf="!workOrder.invoiced"
                (click)="onClose()">
                <mat-icon>done_all</mat-icon>
                Attempt to Close
            </button>

            <button mat-menu-item
                *ngIf="!workOrder.locked && !workOrder.discarded_at"
                (click)="onDiscard()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>

        </mat-menu>
    </div>


    <div class="work-order-toolbar__row large-card__lead {{ getHeaderClass() }}" fxLayout="row" fxLayoutAlign="space-between center">
        <p class="mat-body-1 work-order-client-info" fxFlex="80">
            {{ workOrder.client?.name }}<br>
            {{ workOrder.location }}
        </p>

        <div class="toolbar-row-hack" fxFlex="20" [fxHide]="loading">
            <mat-list fxFlex dense>
                <mat-list-item fxFlex>
                    <div fxFlex fxLayout="row" fxLayoutAlign=" center">
                        <div class="work-order-list-state {{ workOrder.state_name }}" matListAvatar>
                            {{ workOrder.state_letter }}
                        </div>
                        <span class="capitalize mat-body-1 work-order-state-name">{{ workOrder.state_name }}</span>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div> 
</div>


<mat-divider></mat-divider>

<mat-tab-group class="work-order-tabs" mat-align-tabs="start" 
    color="accent"
    [selectedIndex]="selectedTab"
    (selectedIndexChange)="selectedTab = $event">
    <mat-tab>
        <ng-template mat-tab-label>Main</ng-template>
        <work-order-tab-main
            *ngIf="workOrder.exists()"
            [workOrder]="workOrder"
            [shouldShowDepartment]="shouldShowDepartment"
            [onSave]="onSave">
        </work-order-tab-main>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            Rentals
            <span class="work-order-tabs__count" [fxShow]="rentalCount() > 0">{{ rentalCount() }}</span>
        </ng-template>
        <work-order-tab-rentals
            [workOrder]="workOrder"
            [shouldShowDepartment]="shouldShowDepartment"
            [onSave]="onSave">
        </work-order-tab-rentals>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            Services
            <span class="work-order-tabs__count" [fxShow]="serviceCount() > 0">{{ serviceCount() }}</span>
        </ng-template>
        <work-order-tab-services
            [workOrder]="workOrder"
            [shouldShowDepartment]="shouldShowDepartment"
            [onSave]="onSave">
        </work-order-tab-services>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            Line Items
            <span class="work-order-tabs__count" [fxShow]="lineItemCount() > 0">{{ lineItemCount() }}</span>
        </ng-template>
        <app-tab-line-items
            [workOrder]="workOrder"
            [shouldShowDepartment]="shouldShowDepartment"
            [onReload]="onReload">
        </app-tab-line-items>
    </mat-tab>

    <mat-tab *ngIf="workOrder.exists() && !workOrder.estimate" 
        [disabled]="!workOrder.hasRentalRequests() && !workOrder.hasServices()">
        <ng-template mat-tab-label>
            Dispatches
            <span class="work-order-tabs__count" [fxShow]="dispatchCount() > 0">{{ dispatchCount() }}</span>
        </ng-template>
        <work-order-tab-dispatches
            [workOrder]="workOrder"
            [shouldShowDepartment]="shouldShowDepartment">
        </work-order-tab-dispatches>
    </mat-tab>

    <mat-tab *ngIf="workOrder.exists() && !workOrder.estimate">
        <ng-template mat-tab-label>
            Invoices
            <span class="work-order-tabs__count" [fxShow]="invoiceCount() > 0">{{ invoiceCount() }}</span>
        </ng-template>
        <work-order-tab-invoices [workOrder]="workOrder"></work-order-tab-invoices>
    </mat-tab>

    <mat-tab *ngIf="workOrder.estimate || workOrder.quotes || workOrder.quotes.length">
        <ng-template mat-tab-label>
            Quotes
            <span class="work-order-tabs__count" [fxShow]="quoteCount() > 0">{{ quoteCount() }}</span>
        </ng-template>
        <work-order-tab-quotes [workOrder]="workOrder"></work-order-tab-quotes>
    </mat-tab> 
</mat-tab-group>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

