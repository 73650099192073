import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared.module';
import { DispatchPrintComponent } from './dispatch-print/dispatch-print.component';
import { DispatchPrintState } from './dispatch-print/dispatch-print.state';
import { InvoicePrintComponent } from './invoice-print/invoice-print.component';
import { InvoicePrintState } from './invoice-print/invoice-print.state';
import { PrintComponent } from './print.component';
import { PrintState } from './print.state';


@NgModule({
  imports: [
    SharedModule,
    UIRouterModule.forChild({ states: [DispatchPrintState, InvoicePrintState, PrintState] })
  ],
  declarations: [ 
    DispatchPrintComponent,
    InvoicePrintComponent,
    PrintComponent,
  ],
})
export class PrintModule {}
