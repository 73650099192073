import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DispatchedRentalRequest, Account } from '@beaconlite/models';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { DispatchSlipRequestEditorData } from './dispatch-slip-request-editor-data.interface';
import { DispatchedFlattenedRentals } from '@beaconlite/models/contracts/DispatchedFlattenedRentals.interface';
import { RentalFlattenBuilder } from '@beaconlite/services/RentalFlattenBuilder.service';

@Component({
  selector: 'app-dispatch-slip-request-editor',
  templateUrl: './dispatch-slip-request-editor.component.html',
  styles: ['.mat-caption{color:#757575}']
})
export class DispatchSlipRequestEditorComponent implements OnInit {

  original = this.data.original;
  dispatch = this.data.dispatch;
  isFlattenedView = this.data.isFlattenedView;
  onQuantityUpdate = this.data.onQuantityUpdate;
  
  loading = false;
  saving = false;

  request = new DispatchedRentalRequest();
  account = new Account();
  flattenedRentals: DispatchedFlattenedRentals;

  actionLabel: string;
  displayType: string;
  getAllButtonLabel: string;

  tableColumns = ['item', 'requested', 'dispatched-quantity'];

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DispatchSlipRequestEditorData,
    public dialogRef: MatDialogRef<DispatchSlipRequestEditorComponent>,
    protected snackbarNotification: SnackbarNotificationService,
    protected auth: AuthenticationService,
    protected rentalFlattenBuilder: RentalFlattenBuilder,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    if (!! this.original )
    {
      this.request = this.original.copy();

      // Easier to remap flattened rentals than to pass into editor and copy.
      if (this.isFlattenedView) {
        this.flattenedRentals = this.rentalFlattenBuilder.computeDispatchedFlattenedRentals(this.request);
      }
    }     

    this.actionLabel = this.dispatch.getActionLabel();
    this.displayType = this.dispatch.getDisplayType();
    this.getAllButtonLabel = this.dispatch.getAllButtonLabel();
    
    this._sortItems();

    this.account = await this.auth.getCurrentUser();
  }

  actionAll(): void
  {
      this.request.rentals.forEach( rental => rental.actioned_quantity = rental.dispatched_quantity);

      if (this.isFlattenedView) {
        this.flattenedRentals = this.rentalFlattenBuilder.computeDispatchedFlattenedRentals(this.request);
      }
  }

  canActionAll(): boolean 
  {
      return this.account.isAdmin();
  }

  // TODO Jira BL-724: No check to see if Delivered or Picked Up quantity > Requested.
  async onSave()
  {
    this.saving = true;

    if (this.isFlattenedView) {
      this.distributeActionedQuantity();
    }

    // TODO: Too aggresive? Iterate and map each rentals attributes instead?
    this.original.map(this.request.flush());

    // Update dispatch-slip flattenedRentals
    this.onQuantityUpdate();

    try
    {
      await this.dispatch.save();
      this.dialogRef.close();
      this.snackbarNotification.saved();
    }
    finally
    {
      this.saving = false;
    }
  }

  protected _sortItems(): void
  {
    // TODO Jira BL-718/BL-720 update for flattened rentals
    this.request.rentals.sort(orderBy('source.position'));
  }

  protected distributeActionedQuantity() {
    let flattenedRentalsVals = this.flattenedRentals.values();

    // Assumed that the order in which action rentals are distributed to rentals is not important.
    for (let flatRental of flattenedRentalsVals) {
      let availableActionQuant = flatRental.actioned_quantity;

      for (let i=0; i<flatRental.dispatchedRentals.length; i+=1) {
        let rentalActionQuant = Math.min(availableActionQuant, flatRental.dispatchedRentals[i].dispatched_quantity);

        flatRental.dispatchedRentals[i].actioned_quantity = rentalActionQuant;
        availableActionQuant -= rentalActionQuant;
      }
    }
  }

  getArrayFromMapIterable(map): any[]{
    return Array.from(map);
  }
}
