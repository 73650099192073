import { Ng2StateDeclaration } from '@uirouter/angular';
import { ActiveItemComponent } from './active-item.component';

export const ActiveItemsReportState: Ng2StateDeclaration = {
  name: 'protected.reports.active-item',
  url: '/active-item',
  component: ActiveItemComponent,
  data: { 
    pageTitle: 'Active Item Report', 
    requiresAuth: true,
  },
}
