<div class="mat-dialog-editor" fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row">
        <h2 class="mat-title">Document details</h2>
        <span fxFlex></span>
        <button mat-icon-button color="primary" (click)="this.download()" [disabled]="this.downloading">
            <mat-icon>cloud_download</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="this.remove()" [disabled]="this.sending || !this.isDocumentModifiable()">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row wrap">
            <mat-icon *ngIf="this.loaded" class="thumbnail--large" color="primary">image</mat-icon>
            <div class="mat-body-1" fxFlex fxLayout="column">
                <div fxLayout="row">
                    <div class="file-details__label">Filename</div>
                    <div >{{this.document.attachment.filename}}</div>
                </div>
                <div fxLayout="row">
                    <div class="file-details__label">Size</div>
                    <div >{{this.document.attachment.bytes | bytesFilter}}</div>
                </div>
                <div fxLayout="row">
                    <div class="file-details__label">Uploaded By</div>
                    <div >{{this.document.uploader.fullname}}</div>
                </div>
                <div fxLayout="row">
                    <div class="file-details__label">Upload Date</div>
                    <div >{{this.document.created_at | dateFilter:'medium'}}</div>
                </div>
                <div fxLayout="row">
                    <div class="file-details__label">References</div>
                    <div >{{this.displayReferences()}}</div>
                </div>
            </div>
        </div>        

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Title</mat-label>
                <input type="text"
                    name="titleField"
                    [(ngModel)]="this.document.attachment.name"
                    maxlength="255"
                    [disabled]="!this.isDocumentModifiable()"
                    matInput>
                <mat-hint align="end">{{ this.document.attachment.name?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Description</mat-label>
                <input type="text"
                    name="descriptionField"
                    [(ngModel)]="this.document.attachment.description"
                    maxlength="255"
                    [disabled]="!this.isDocumentModifiable()"
                    matInput>
                <mat-hint align="end">{{ this.document.attachment.description?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <mat-progress-spinner *ngIf="this.sending" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button 
            color="accent"
            [disabled]="this.sending || !this.isDocumentModifiable()"
            (click)="this.save()">
            Save
        </button>
    </mat-dialog-actions>
</div>
