import { Component, OnInit, Injectable, Inject, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ServiceDefinition, Thumbnail, Department } from '@beaconlite/models';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

export interface ServiceDefinitionEditorData {
  original: ServiceDefinition,
  departments: Department[],
  onUpdate: (serviceDefinition: ServiceDefinition) => Promise<void>
}

@Injectable({
  providedIn: 'root'
})
export class ServiceDefinitionEditorService {

  constructor(protected dialog: MatDialog) {}

  async open(data?: ServiceDefinitionEditorData): Promise<ServiceDefinition> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data,
    }

    return this.dialog.open(ServiceDefinitionEditorComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-service-definition-editor',
  templateUrl: './service-definition-editor.component.html',
  styleUrls: ['./service-definition-editor.component.scss']
})
export class ServiceDefinitionEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @Input() modelId: string;

  original = this.data.original;
  departments = this.data.departments;
  onUpdate = this.data.onUpdate;

  loading = false;
  locked = false;
  serviceDefinition: ServiceDefinition;

  readonly PRICING_HOUR = ServiceDefinition.PRICING_HOUR;
  readonly PRICING_UNIT = ServiceDefinition.PRICING_UNIT;
  readonly PRICING_MANUAL = ServiceDefinition.PRICING_MANUAL;

  pricingTypes = [
    this.PRICING_HOUR,
    this.PRICING_UNIT,
    this.PRICING_MANUAL
  ];

  uploadThumbnail: () => Promise<Thumbnail>;
  removeThumbnail: () => Promise<void>;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ServiceDefinitionEditorData,
    public dialogRef: MatDialogRef<ServiceDefinitionEditorComponent>,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.serviceDefinition = this.original.copy();
  }

  getPriceTypeLabel(priceType) 
  {
    return this.serviceDefinition.getPriceTypeLabel(priceType);
  }

  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.serviceDefinition.discarded;
  }

  async save(): Promise<ServiceDefinition>
  {
    if (this.editorForm.invalid) { return; }

    this.serviceDefinition.applyPricingDefaults();

    try
    {
      this.lockAndLoad();
      await this.uploadThumbnail?.();
      await this.removeThumbnail?.();
      await this.onUpdate(this.serviceDefinition);
      this.snackbarNotification.saved();
      this.dialogRef.close();
    }
    finally
    {
      this.unlockAndUnload();
    }

    return (this.serviceDefinition);
  }

  async onClose(): Promise<void>
  {
    this.dialogRef.close();
  }

  onThumbnailSelected(uploadThumbnail: () => Promise<Thumbnail>)
  {
    this.uploadThumbnail = uploadThumbnail;
    this.removeThumbnail = null;
  }

  onThumbnailRemoved(removeThumbnail: () => Promise<void>)
  {
    this.removeThumbnail = removeThumbnail;
    this.uploadThumbnail = null;
    this.serviceDefinition.thumbnail = null;
  }

  onThumbnailUpdated(thumbnail: Thumbnail): void
  {
    this.serviceDefinition.thumbnail = thumbnail;
  }
}
