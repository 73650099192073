import { Component, Input, OnInit } from "@angular/core";
import { Thumbnail } from "@beaconlite/models";

@Component({
    selector: 'app-request-search-option',
    templateUrl: './request-search-option.component.html',
    styleUrls: ['./request-search-option.component.scss'],
})
export class RequestSearchOptionComponent {

    @Input() text: string;
    @Input() thumbnail: Thumbnail;
    @Input() icon: string;

    async ngOnInit(): Promise<void>
    {
        if (this.thumbnail === null) { return; }
  
        await this.thumbnail.small.getUrl();                
    }  
}
