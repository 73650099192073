<div class="mat-dialog-editor" fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Email History</h2>
    </mat-toolbar>

    <mat-dialog-content>
        <!-- INDEX -->
        <div class="index-panel" fxLayout="column" fxFlex="25">
            <div class="index-wrapper" fxLayout="column">
                <mat-list dense fxFlex>
                    <mat-list-item
                        *ngFor="let email of this.sendableModel.emails"
                        [class.index-list__item--selected]="this.selectedEmail?.id == email.id"
                        (click)="this.selectEmail(email)">
                        <div class="mat-list-text">
                            {{ email.sent_at | dateFilter:'dateTimeMedium' }}
                        </div>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>

        <mat-divider vertical></mat-divider>

        <!-- INFO PANEL -->
        <div class="info-panel" fxLayout="column" fxFlex>
            <div class="email__meta-item" *ngIf="this.selectedEmail.to.length">
                <span class="email__meta-title">To:</span>
                <span *ngFor="let emailAddress of this.selectedEmail.to; last as isLast">
                    {{ emailAddress.email }}<span *ngIf="!isLast">, </span>
                </span>
            </div>
            <mat-divider *ngIf="this.selectedEmail.to.length"></mat-divider>
            <div class="email__meta-item" *ngIf="this.selectedEmail.cc.length">
                <span class="email__meta-title">Cc:</span>
                <span *ngFor="let emailAddress of this.selectedEmail.cc; last as isLast">
                    {{ emailAddress.email }}<span *ngIf="!isLast">, </span>
                </span>
            </div>
            <mat-divider *ngIf="this.selectedEmail.cc.length"></mat-divider>
            <div class="email__meta-item" *ngIf="this.selectedEmail.bcc.length">
                <span class="email__meta-title">Bcc:</span>
                <span *ngFor="let emailAddress of this.selectedEmail.bcc; last as isLast">
                    {{ emailAddress.email }}<span *ngIf="!isLast">, </span>
                </span>
            </div>
            <mat-divider *ngIf="this.selectedEmail.bcc.length"></mat-divider>
            <div class="email__meta-item">
                <span class="email__meta-title">Subject:</span>
                {{ this.emailSubject }}
            </div>
            <mat-divider></mat-divider>
            <div class="email__meta-item" *ngIf="this.selectedEmail.attachments.length">
                <span class="email__meta-title">Attachments:</span>
                <span *ngFor="let attachment of this.selectedEmail.attachments; last as isLast">
                    <a href="javascript:void(0);" (click)="attachment.download()">{{ attachment.name }}</a><span *ngIf="!isLast">, </span>
                </span>
            </div>
            <mat-divider *ngIf="this.selectedEmail.attachments.length"></mat-divider>
            <div class="email__message" [innerHTML]="this.emailMessage | innerHTMLBodyContent"></div> 
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <span fxFlex></span>
        <button mat-raised-button [mat-dialog-close]>
            OK
        </button>
    </mat-dialog-actions>
</div>
