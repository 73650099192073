import { 
  Component, 
  Inject, 
  Injectable
} from '@angular/core';

import { 
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';

interface SnackbarData {
  textContent: string,
  icon: string,
  style: string
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarNotificationService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; 
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar) { }

  saved( textConent?: string ): void
  {
    this.show({
      textContent: textConent || 'Saved',
      icon: 'check',
      style: 'color-success'
    });
  }

  warning( textConent?: string ): void
  {
    this.show({
      textContent: textConent || 'Unsuccessful',
      icon: 'warning',
      style: 'color-warning'
    })
  }

  private show( data: SnackbarData ): void
  {
    this.snackBar.openFromComponent(SnackbarNotificationComponent, {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      data
    })
  }
}

@Component({
  selector: 'app-snackbar-notification',
  templateUrl: './snackbar-notification.component.html'
})
export class SnackbarNotificationComponent {
  constructor( @Inject( MAT_SNACK_BAR_DATA ) public data: SnackbarData) {}
}
