import { Title } from '@angular/platform-browser';
import { HookMatchCriteria, Transition, TransitionService } from '@uirouter/core';
import { LoginState } from 'src/app/views/public/login/login.state';
import { AuthenticationService } from './authentication.service';

/**
 * Registers a Transition Hook which protects a
 * route that requires authentication.
 *
 * This hook redirects to /login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
export function registerAuthHook( transitionService: TransitionService )
{
    // Matches if the destination state's data property has a truthy 'requiresAuth' property
    const requiresAuthCriteria = {
        to: ( state ) => state.data && state.data.requiresAuth
    };
    // State transition criteria
    const matchCriteria: HookMatchCriteria = { to: 'protected.**' };
    // TODO: replace this with requireAuth declarations within the states
    const printViewCriteria: HookMatchCriteria = { to: 'print.**' };

    // Function that returns a redirect for the current transition to the login state
    // if the user is not currently authenticated (according to the AuthService)
    const redirectToLogin = async ( transition: Transition ) =>
    {
        const authService: AuthenticationService = transition.injector().get( AuthenticationService );
        await authService.initializing;

        if ( !authService.isAuthenticated() )
        {
            const $state = transition.router.stateService;
            return $state.target( LoginState );
        }
    };

    // Register the "requires auth" hook with the TransitionsService
    transitionService.onBefore( requiresAuthCriteria, redirectToLogin, { priority: 10 } );
    transitionService.onBefore( matchCriteria, redirectToLogin, { priority: 10 } );
    transitionService.onBefore( printViewCriteria, redirectToLogin, { priority: 10 } );
}

/**
 * Registers a Transition Hook which redirects
 * authenticated users away from the login page.
 *
 */
export function registerLoginHook( transitionService: TransitionService )
{
    // State transition criteria
    const matchCriteria: HookMatchCriteria = { to: LoginState.name };

    const redirectToDefault = async ( transition: Transition ) =>
    {
        const authService: AuthenticationService = transition.injector().get( AuthenticationService );
        await authService.initializing;

        // If authenticated, go directly to the DEFAULT_STATE if not already there
        if ( authService.isAuthenticated() ) 
        {
            const defaultState = await authService.getDefaultState();
            const $state = transition.router.stateService;

            if ( !$state.is( defaultState ) )
            {
                return $state.target( defaultState );
            }
        }

        return true;
    };

    transitionService.onBefore( matchCriteria, redirectToDefault, { priority: 10 } );
}

/**
 * Registers a Transition Hook which updates the page title.
 *
 */
export function registerTitleHook( transitionService: TransitionService )
{
    const defaultTitle = "BL2"
    // State transition criteria
    const matchCriteria: HookMatchCriteria = { to: '**' };

    const updateTitle = ( transition: Transition ): void =>
    {
        const tilteService: Title = transition.injector().get( Title );
        const state = transition.to();
        const title = state.data?.pageTitle || defaultTitle;
        tilteService.setTitle( title );
    };

    transitionService.onSuccess( matchCriteria, updateTitle );
}
