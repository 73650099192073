import * as moment from 'moment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Branch } from '@beaconlite/models';
import { BranchCollection } from '@beaconlite/models/collections';
import { LostItemReport } from '@beaconlite/models/reports/LostItemReport.model';
import { ReportFilter } from '../report-filter.interface';
import { ReportFilterEditorService } from '../report-filter-editor/report-filter-editor.service';

@Component({
  selector: 'app-lost-item',
  templateUrl: './lost-item.component.html',
})
export class LostItemComponent implements OnInit {

  @ViewChild('branchSearchTextField') branchSearchTextField: NgModel;

  readonly REPORT_TYPE = 'lost_item';
  reportType = 'lost-item';

  loading = false;

  selectedBranch: Branch = null;
  branchSearchText = '';
  selectedStartDate: Date = null;
  selectedEndDate: Date = null;

  promisedBranches: Promise<Branch[]>;
  
  filters: ReportFilter[] = [];

  report: LostItemReport = null;

  listLevel = 0;
  listLevels: string[] = ['items', null, null];

  constructor(
    protected $state: StateService,
    protected filterEditor: ReportFilterEditorService,
  ) { }

  ngOnInit(): void {}

  onReportTypeChanged(): void
  {
    this.$state.go(`protected.reports.${this.reportType}`)
  }

  onQueryBranches(): void
  {
    this.promisedBranches = (new BranchCollection()).all({name:this.branchSearchText});
  }

  clearSearchText(selection: Branch, field: string): null|void
  {
    if (!!selection) return; 
    
    switch(field)
    {
        case 'branch':
          this.branchSearchText = '';
        break;
    }
  }
  async addFilter(): Promise<void>
  {
    await this.filterEditor.open({
      filterTypes: ['rentals', 'clients'],
      onAddition: this._onAddition,
    });
  }

  protected _onAddition = (filter: ReportFilter) => 
  {
    this.filters.push(filter);
  }

  isListOption(slug: string, nestLevel: number): boolean
  {
    return this.listLevels.indexOf(slug) < 0 ||    // Not found OR
           this.listLevels.indexOf(slug) >= nestLevel // Already last level of nesting
  }

  addListLevel(): void
  {
    if ( this.listLevel == 2) { return; }

    this.listLevel++;
  }

  resetListLevels(level: number): void|null
  {
    let resetting = this.listLevels[level] == null
    if(resetting)
    {
        this.listLevel = level;
    }

    this.listLevels.forEach((value, index, array) => {
        if( index < level ) { return } // Don't reset upper levels
        if( array[index] == null ) { return } // Already null

        if( resetting ) {
            array[index] = null
        }
        else if( !this.isListOption(array[index], index) ) {
            // Reset everything after the first discrepancy
            if( !resetting ) {
                resetting = true
                this.listLevel = index
            }
            array[index] = null
        }
    })
  }


  onListChange(level: number): void
  {
    if( this.listLevels[level] == 'null' ) 
    {
      this.listLevels[level] = null
    }

    this.resetListLevels(level);
  }

  onExport()
  {
    return this.onGenerate(true);
  }

  async onGenerate(exporting: boolean = false): Promise<void>
  {
    // Default params
    var params = {
        report_type: this.REPORT_TYPE,
        branch_id: this.selectedBranch ? this.selectedBranch.id : null,
        start_date: this.selectedStartDate ? moment(this.selectedStartDate).unix() : null,
        end_date: this.selectedEndDate ? moment(this.selectedEndDate).endOf('day').unix() : null,
        list_by: this.listLevels.filter((element) => !!element ),
    } as any

    // Grab the IDs from the chips array
    let client_filter = [];
    let rental_filter = [];
    this.filters.forEach(element => {
        switch(element.type)
        {
            case 'client':
                client_filter.push(element.id);
                break
            case 'rental':
                rental_filter.push(element.id);
                break
        }
    });

    // Add to params
    if(client_filter.length)
    {
      params.client_filter = client_filter
    }

    // Add to params
    if(rental_filter.length)
    {
      params.rental_filter = rental_filter
    }

    if(exporting)
    {
      LostItemReport.export(params);
    }
    try
    {
      this.loading = true;
      this.report = await LostItemReport.generate(params);
    }
    finally
    {
      this.loading = false;
    }  
  }

  onBranchSelected(branch: Branch)
  {
    this.selectedBranch = branch;
  }

  displaySelectedBranch(branch: Branch): string|null
  {
    return branch?.name || null;
  }

  onClearBranchSelection()
  {
    this.selectedBranch = null;
    this.branchSearchTextField.reset();
  }

  onRemoveFilterChip(filter: ReportFilter): null|void
  {
    if (! this.filters.length) return

    this.filters.splice(this.filters.indexOf(filter), 1);
  }

}
