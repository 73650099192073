import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dispatch, DispatchedService, Service } from '@beaconlite/models';
import { SyncErrorStateMatcher } from '@beaconlite/utilities/sync-error-state-matcher.utility';
import { SnackbarNotificationService } from '../../../services/notification/snackbar-notification.service';
import { DispatchSlipServiceEditorData } from './dispatch-slip-service-editor-data.interface';

@Component({
  selector: 'app-dispatch-slip-service-editor',
  templateUrl: './dispatch-slip-service-editor.component.html',
  styleUrls: ['./dispatch-slip-service-editor.component.scss']
})
export class DispatchSlipServiceEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  original: DispatchedService = this.data.original;
  dispatch: Dispatch = this.data.dispatch;
  service: DispatchedService;

  saving = false;

  qtyInputStep = Service.QTY_INPUT_STEP_UNIT;
  qtyStepError = Service.QTY_STEP_ERROR_UNIT;

  syncErrorStateMatcher = new SyncErrorStateMatcher();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DispatchSlipServiceEditorData,
    public dialogRef: MatDialogRef<DispatchSlipServiceEditorComponent>,
    protected snackbarNotification: SnackbarNotificationService,
  ) { }


  ngOnInit(): void 
  {
    this.service = this.original.copy();

    // TODO: this was in the ajs component but was it a mistake? workOrder doens't exist in the component
    // if( $ctrl.workOrder )
    // {
    //     $ctrl.isReview = true;
    //     $ctrl.minDate = moment.unix($ctrl.workOrder.minimumAllowableDate).toDate();
    //     $ctrl.client = $ctrl.workOrder.client;
    // }

    // Prefill
    if (this.service.actioned_quantity == null)
    { 
      this.service.actioned_quantity = this.service.dispatched_quantity;
    }

  }

  async save(): Promise<boolean|void>
  {
    if (this.editorForm.invalid) return false;

    this.saving = true;

    try
    {
      this.original.mapAttributes(this.service.flushAttributes())
      await this.dispatch.save();
      this.dialogRef.close();
      this.snackbarNotification.saved();
    }
    finally
    {
      this.saving = false;
    }
  }

}
