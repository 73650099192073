import { Account } from "./Account.model";
import { Attachment } from "./Attachment.model";
import { BaseModel } from "./Base.model";
import { Contact } from '@beaconlite/models/contracts/Contact.interface';
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'

export class Email extends BaseModel {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() to?: Contact[] = null;
    @dto() from?: string = null;
    @dto() label?: string = null;
    @dto() cc?: Contact[] = null;
    @dto() bcc?: Contact[] = null;
    @dto() subject?: string = null;
    @dto() content_html?: string = null;
    @dto() content_plain?: string = null;
    @dto() @date sent_at?: number = null;
    @dto() sent_by?: string = null;

    @dto(Account) sender: Account =  null;
    @dto(Attachment) attachments: Array<Attachment> = [];

    get name(): string {
        return this.label;
    }
}
