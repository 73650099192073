import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Thumbnail } from "@beaconlite/models";
import { SnackbarNotificationService } from "@beaconlite/services/notification/snackbar-notification.service";
import { FileItem, FileUploader } from "ng2-file-upload";

@Component({
    selector: 'app-thumbnail-control',
    templateUrl: './thumbnail-control.component.html',
    styleUrls: ['./thumbnail-control.component.scss'],
    providers: [{ 
        provide: NG_VALUE_ACCESSOR, 
        useExisting: ThumbnailControlCompnent, 
        multi: true }]
})
export class ThumbnailControlCompnent implements OnInit, ControlValueAccessor {
    @Input() original: Thumbnail;
    @Output() thumbnailSelected = new EventEmitter<() => Promise<any>>();
    @Output() thumbnailRemoved = new EventEmitter<() => Promise<void>>();
    @Output() thumbnailUpdated = new EventEmitter<Thumbnail | null>();

    // Component State
    // TODO: not used in this component?
    loading = false;
    thumbnail: Thumbnail = null;
    thumbnailUrl: string = null;
    uploader: FileUploader = null;
    protected fileItem: FileItem = null;
    // ControlValueAccessor members
    onChange = (thumbnail: Thumbnail) => {}
    onTouched = () => {}


    constructor(protected snackbarNotification: SnackbarNotificationService) {}

    async ngOnInit(): Promise<void> 
    {  
        // TODO: Jira task BL-492.
        // When variant-editor is closed, it calls ngOnInit() again.
        // It will have an original at this point but the .id isn't set so it tries to getUrl()
        // with no id and throws an error. Added ID check to avoid this.
        // Issue appears when editor moves from loading to not loading, rerendering component
        if (!!this.original && !!this.original.id)
        {
            this.loading = true;

            try
            {
                await this.original.small.getUrl();                
                this.thumbnailUrl = this.original.small.url;
            }
            finally
            {
                this.loading = false;
            }

            this.thumbnail = this.original.copy();
        }
        else
        {
            this.thumbnail = new Thumbnail;
        }

        this.uploader = new FileUploader({
            method: 'PUT',
            allowedFileType: ['image'],
            // queueLimit: 1,
            disableMultipart: true,
        });

        this.uploader.onAfterAddingFile = () => 
        {
            // This essentially sets this.uploader.queueLimit to 1, while allowing the
            // single queue item to be overwritten.  Setting this.uploader.queueLimit to 1
            // does NOT overwrite the first item in the queue.  We need the ability to overwrite
            // the first item if a user selects multiple thumbnails before deciding to save.
            const latestFile = this.uploader.queue[this.uploader.queue.length - 1] // should always be 0 in this case.

            this.uploader.clearQueue();
            this.uploader.queue.push(latestFile);
        }
    }

    async onFileSelectedControl(fileList: FileList): Promise<void>
    {
        this.onTouched();

        const urlData = await Thumbnail.getUploadUrl();
        this._prepareFileItemForUpload(urlData);
        await this._setThumbnail(urlData);

        this.onChange(this.thumbnail);

        this.thumbnailSelected.emit(this.uploadThumbnail);
        this.thumbnailUpdated.emit(this.thumbnail);
    }

    /**
     * Callback function which wraps ng2-file-upload uploading process in a promise.
     * 
     * @returns Promise
     */
    protected uploadThumbnail = async (): Promise<Thumbnail> =>
    {
        return new Promise((resolve, reject) => 
        {
            this.fileItem.upload();

            this.uploader.onSuccessItem = () => resolve(this.thumbnail);

            this.uploader.onErrorItem = (item: FileItem, response: string) => reject(response);
        });
    }

    onThumbnailRemoved()
    {
        const thumbnailToRemove = this.thumbnail;

        const removeThumbnail = async(): Promise<void> =>
        {
            await Thumbnail.delete(thumbnailToRemove);
        }

        if (!!this.original)
        {
            this.thumbnailRemoved.emit(removeThumbnail);
        }
        this._resetThumbnailAndFileUpload();
    }

    canRemoveThumbnail(): boolean
    {
        return (!!this.thumbnailUrl ? true : false);
    }

    /**
     * Image processing happens in the background.  When an Thumbnail is
     * first uploaded, the url saved in memory will be the one pointing
     * to S3 /incoming folder.  When processing completes, /incoming object
     * is deleted, but the in-memory url may still be pointing to the now
     * deleted object.  In these situations we bind to <img /> onerror and 
     * reload Thumbnail from the api.
     * 
     */
    async onImgError()
    {
        this.thumbnail.small.url = null;
        this.thumbnail.small.url_expires_at = null;

        await this.thumbnail.small.getUrl();    
                    
        this.thumbnailUrl = this.thumbnail.small.url;
    }

    // ControlValueAccessor implemented methods
    
    writeValue(thumbnail: Thumbnail): void 
    {
        this.thumbnail = thumbnail;
    }

    registerOnChange(fn: any): void 
    {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void 
    {
        this.onTouched = fn;
    }

    // End ControlValueAccessor implemented methods

    private _prepareFileItemForUpload(urlData): void
    {
        this.fileItem = this.uploader.queue[0];
        this.fileItem.url = urlData.url;
        this.fileItem.withCredentials = false;
        this.fileItem.headers = [{ name: 'Content-Type', value: this.fileItem.file.type }]
    }

    protected async _setThumbnail(urlData): Promise<void>
    {
        this.thumbnail.name = this.fileItem.file.name;
        this.thumbnail.key = urlData.key;
        this.thumbnailUrl = await this._fileToBase64(this.fileItem._file);
    }

    protected _resetThumbnailAndFileUpload(): void
    {
        this.fileItem = null;
        this.thumbnail = null;
        this.thumbnailUrl = null;
    }

    private _fileToBase64(file: File): Promise<any>
    {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

}
