import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { LineItemSingleComponent } from "./line-item-single.component";

export const LineItemSingleState: Ng2StateDeclaration = {
    name: 'protected.line-items.single',
    url: '/:modelId',
    component: LineItemSingleComponent,
    data: {
        pageTitle: 'Line Items',
        requiresAuth: true,
    },
    params: {
        modelId: 'new'
    },
    resolve: [
        { 
          token: 'modelId', 
          deps: [Transition],
          resolveFn: (transition: Transition) => transition.params().modelId,
        },
      ],
}
