<form class="mat-dialog-editor" #editorForm (ngSubmit)="this.onSave()" novalidate>
    <mat-toolbar>
        <h2 class="mat-title">{{ this.branch.name }}</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="column">
            <mat-form-field fxflex>
                <mat-label>Default Account</mat-label>
                <mat-select [(ngModel)]="this.settings.default_account" name="defaultAccountField" required>
                    <mat-option *ngFor="let account of this.accounts" [value]="account.key">
                        {{ getAccountString(account) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxflex>
                <mat-label>Rental Account</mat-label>
                <mat-select [(ngModel)]="this.settings.rentals.default_account" name="rentalAccountField" required>
                    <mat-option *ngFor="let account of this.accounts" [value]="account.key">
                        {{ getAccountString(account) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxflex>
                <mat-label>Service Account</mat-label>
                <mat-select [(ngModel)]="this.settings.services.default_account" name="serviceAccountField" required>
                    <mat-option *ngFor="let account of this.accounts" [value]="account.key">
                        {{ getAccountString(account) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxflex>
                <mat-label>Charge Account</mat-label>
                <mat-select [(ngModel)]="this.settings.charges.default_account" name="chargeAccountField" required>
                    <mat-option *ngFor="let account of this.accounts" [value]="account.key">
                        {{ getAccountString(account) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.saving">
            Save
        </button>
    </mat-dialog-actions>
</form>
