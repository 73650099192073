<mat-table fxFlex="100" [dataSource]="this.requests" multiTemplateDataRows>
    <ng-container matColumnDef="serial_id">
        <mat-header-cell *matHeaderCellDef>Work Order</mat-header-cell>
        <mat-cell *matCellDef="let request">{{ request.serial_id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let request">
            <dispatched-request-client-row fxFlex="100"
                [report]="this.report"
                [requests]="this.getNestedRequests(request)"
                [layer]="this.nestedLayer">
            </dispatched-request-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestDetail" *ngIf="this.nestedLayer == 'requests'">
        <mat-cell *matCellDef="let request">
            <dispatched-request-request-row fxFlex="100"
                [report]="this.report"
                [requests]="this.getNestedRequests(request)"
                [layer]="this.nestedLayer">
            </dispatched-request-request-row>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let request; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'requests'">
        <mat-row *matRowDef="let nested; columns: ['requestDetail']" ></mat-row>
    </ng-container>
</mat-table>