import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Department, RentalDefinition, VariantRental } from '@beaconlite/models';
import { AppData } from '../../../../services/data/shared-data.departments';
import { DialogNotificationService } from '../../../../services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '../../../../services/notification/snackbar-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { prefs } from "@beaconlite/services/preferences.service";
import { VariantRentalPropertyDefinition } from '@beaconlite/models/VariantRentalPropertyDefinition.model';
import { RentalDefinitionEditorService } from '../rental-definition-editor/rental-definition-editor.component';
import { VariantConfigurationData } from '@beaconlite/components/variants/variant-configuration/variant-configuration.component';

@Component({
  selector: 'app-rental-single',
  templateUrl: './rental-single.component.html',
  styleUrls: ['./rental-single.component.scss']
})
export class RentalSingleComponent implements OnInit {

  @Input() modelId: string;

  loading = false;
  locked = false;
  original = new RentalDefinition();
  departments: Department[];
  departmentName: string;
  isPercentDiscount = true;
  weeklyDiscount = 0;
  monthlyDiscount = 0;
  weekThreshold: number;
  monthThreshold: number;

  readonly PRICING_RATED = RentalDefinition.PRICING_RATED;
  readonly PRICING_FIXED = RentalDefinition.PRICING_FIXED;
  readonly PRICING_FLAT  = RentalDefinition.PRICING_FLAT;

  constructor(
    protected appData: AppData,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
    protected $state: StateService,
    protected rentalDefinitionEditorService: RentalDefinitionEditorService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this.lockAndLoad();

    try
    {
      this.original = await RentalDefinition.get(this.modelId);
      await this.original.loadVariants();

      if (this._shouldSetThumbnail()) 
      {
        await this._setThumbnailUrl();
      }
      
      // Get all departments for the rental definition editor.
      this.departments = await this.appData.departments.getAll();
      this.departments?.sort(orderBy('name'));
      this.departmentName = this._getDepartmentName(this.original.department_id);

      [this.weekThreshold, this.monthThreshold] = await Promise.all([
        prefs('pricing.week_threshold'), 
        prefs('pricing.month_threshold')
      ]);
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.original.discarded;
  }

  private _shouldSetThumbnail(): boolean
  {
    return !this.original.discarded && !this.original.variants_enabled;
  }

  protected async _setThumbnailUrl(): Promise<void>
  {
    if (this.original.thumbnail === null) { return; }

    await this.original.thumbnail.small.getUrl();                
  }

  protected _getDepartmentName(targetId: string): string 
  {
    return this.departments.find(({ id }) => id === targetId)['name'];
  }

  async edit(): Promise<void>
  {
    await this.rentalDefinitionEditorService.open({
      original: this.original,
      departments: this.departments,
      weekThreshold: this.weekThreshold,
      monthThreshold: this.monthThreshold,
      onUpdate: this.updateRentalDefinition
    });
  }

  updateRentalDefinition = async (rental: RentalDefinition): Promise<void> =>
  {
    await rental.save();
    this.original = rental.copy();
    if (this._shouldSetThumbnail())
    {
      await this._setThumbnailUrl();
    }
  }

  async discard(): Promise<void>
  {
    if (! await this.dialogNotification.discardConfirm()) { return; }

    this.lockAndLoad();

    try
    {
      await this.original.discard();
      this.$state.go('protected.rentals');
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  calculateRate(discount: number, days: number): number
  {
    return RentalDefinition.calculateRateFromDiscount(this.original.daily_rate, discount, days);
  }

  onVariantConfigUpdate = async (variantConfiguration: VariantConfigurationData): Promise<void> => 
  {
    this.lockAndLoad();

    try
    {
      this.original.variants_enabled = variantConfiguration.variantsEnabled;
      this.original.variant_rates_enabled = variantConfiguration.rateVariantsEnabled;
      await this.original.save();
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  onPropUpdate = async (updatedProp: VariantRentalPropertyDefinition) =>  
  {
    await updatedProp.save();
    await this._reloadOriginal();
  }

  onPropRemove = async (prop: VariantRentalPropertyDefinition) =>  
  {
    await prop.discard();
    await this._reloadOriginal();
  }

  onVariantUpdate = async (updatedVariant: VariantRental) =>
  {
    await updatedVariant.save();
    await this._reloadOriginal();
  }

  onVariantRemove = async (variant: VariantRental) =>
  {
    await variant.discard();
    await this._reloadOriginal();
  }

  protected async _reloadOriginal(): Promise<void>
  {
    await this.original.reload();
    await this.original.loadVariants();
  }
}
