<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="onSave()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Variant Configuration</h2>
    </mat-toolbar>
    <mat-dialog-content class="dialog-padding">
        <div class="fieldset">
            <div fxLayout="column">
                <span class="mat-subheading-2">Warning</span>
                <span class="mat-caption">Editing these fields will change the pricing on variant and variant properties.</span>
            </div>
            <div class="field__wrapper">
                <mat-slide-toggle name="variantsEnabledField" 
                [(ngModel)]="this.variantConfig.variantsEnabled"
                (change)="this.onVariantsEnabledChange()"
                [disabled]="this.itemDefinition.variants_enabled">
                Variants
                </mat-slide-toggle>
                <div class="form-instruction">Create new items from a starting template while inheriting common attributes.</div>
            </div>
            <div class="field__wrapper">
                <mat-slide-toggle name="variantRatesEnabledField"
                [(ngModel)]="this.variantConfig.rateVariantsEnabled"
                [disabled]="!this.variantConfig.variantsEnabled">
                Rate Variants
                </mat-slide-toggle>
                <div class="form-instruction">Costs of rate variant attributes will be calculated based on the sum of selected dropdown properties.</div>   
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row">
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" 
            mat-raised-button 
            color="accent" 
            [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>  
</form>
