import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Issue } from '@beaconlite/models';
import { IssueEditorData } from "./issue-editor-data.interface";
import { IssueEditorComponent } from "./issue-editor.component";

@Injectable({
    providedIn: 'root'
})
export class IssueEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: IssueEditorData): Promise<Issue> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            minWidth: 800,
            data,
        }

        return this.dialog.open(IssueEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
  