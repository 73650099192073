<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<mat-card class="medium-card">
    <div class="title-row">
        <span class="mat-headline">{{ this.title }}</span>
        <span class="mat-caption employee-state-label" *ngIf="this.employee.discarded">
            <span>Discarded</span>
        </span>
    </div>

    <form #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>First Name</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.person.first_name"
                    name="firstNameField"
                    #firstNameField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="firstNameField.hasError('required')">First name is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Last Name</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.person.last_name"
                    name="lastNameField"
                    #lastNameField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="lastNameField.hasError('required')">Last name is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Email</mat-label>
                <input type="email"
                    [(ngModel)]="this.employee.person.email"
                    name="emailField"
                    #emailField="ngModel"
                    [disabled]="this.locked"
                    [required]="this.account.exists() || this.account.active"
                    email
                    matInput>
                <mat-error *ngIf="emailField.hasError('required')">Email is required.</mat-error>
                <mat-error *ngIf="emailField.hasError('email')">Invalid email.</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Phone</mat-label>
                <input type="tel"
                    [(ngModel)]="this.employee.person.phone"
                    name="phoneField"
                    [disabled]="this.locked"
                    matInput>
                <mat-error>Implement phone validation</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex *ngIf="this.departments?.length > 1">
                <mat-label>Department</mat-label>
                <mat-select [(ngModel)]="this.employee.department_id"
                    name="departmentField"
                    required
                    [disabled]="this.locked">
                    <mat-option [value]="''">All</mat-option>
                    <mat-option *ngFor="let department of this.departments" [value]="department.id">
                        {{ department.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Position</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.position"
                    name="positionField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Address</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.person.address"
                    name="addressField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>City</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.person.city"
                    name="cityField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Province</mat-label>
                <mat-select [(ngModel)]="this.employee.person.region"
                    name="regionField"
                    #regionField="ngModel"
                    [disabled]="this.locked">
                    <mat-optgroup label="Provinces">
                        <mat-option value="AB">Alberta</mat-option>
                        <mat-option value="BC">British Columbia</mat-option>
                        <mat-option value="MB">Manitoba</mat-option>
                        <mat-option value="NL">Newfoundland and Labrador</mat-option>
                        <mat-option value="NB">New Brunswick</mat-option>
                        <mat-option value="NS">Nova Scotia</mat-option>
                        <mat-option value="ON">Ontario</mat-option>
                        <mat-option value="PE">Prince Edward Island</mat-option>
                        <mat-option value="QC">Quebec</mat-option>
                        <mat-option value="SK">Saskatchewan</mat-option>
                    </mat-optgroup>
                    <mat-optgroup label="Territories">
                        <mat-option value="NT">Northwest Territories</mat-option>
                        <mat-option value="NU">Nunavut</mat-option>
                        <mat-option value="YT">Yukon</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Postal Code</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.person.postal_code"
                    name="postalCodeField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Country</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.person.country"
                    name="countryField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex *ngIf="!!this.dispatchRegions?.length">
                <mat-label>Dispatch Region</mat-label>
                <mat-select [(ngModel)]="this.employee.dispatch_region_id"
                    name="dispatchRegionField"
                    [disabled]="this.locked">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let region of this.dispatchRegions" [value]="region.id">
                        {{ region.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Note</mat-label>
                <input type="text"
                    [(ngModel)]="this.employee.note"
                    name="noteField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>

        <div class="account-enabled-row" fxLayout="row">
            <mat-slide-toggle fxFlexAlign="center"
                [(ngModel)]="this.account.active" 
                name="activeField"
                [disabled]="this.employee.discarded">
                Account Enabled &nbsp;
            </mat-slide-toggle>
        </div>

        <mat-divider [fxShow]="this.account.active"></mat-divider>

        <div class="title-row" fxLayout="row" fxflex *ngIf="this.account.active">
            <div class="mat-title">Account Details</div>
        </div>

        <div fxLayout="row" *ngIf="this.account.active">
            <mat-form-field appearance="fill" fxFlex>
                <mat-label>Email</mat-label>
                <input type="email"
                    [(ngModel)]="this.employee.person.email"
                    name="accountEmailField"
                    readonly
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="this.account.active">
            <mat-form-field fxFlex>
                <mat-label>Account Role</mat-label>
                <mat-select [(ngModel)]="this.account.role"
                    [compareWith]="this.roleCompare"
                    name="roleField"
                    #roleField="ngModel"
                    [disabled]="this.locked"
                    required>
                    <mat-option *ngFor="let role of this.roles" [value]="role">
                        {{ role.name }}
                    </mat-option>   

                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" *ngIf="this.account.active">
            <div fxLayout="column"fxFlex>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input type="password"
                        [(ngModel)]="this.account.credentials.password"
                        name="passwordField"
                        #passwordField="ngModel"
                        [required]="this.isPasswordRequired()"
                        [disabled]="this.locked"
                        matInput>
                    <mat-error *ngIf="passwordField.hasError('required')">Password is required.</mat-error>
                    <mat-error *ngIf="passwordField.hasError('passwordStrength')">Password is not strong enough.</mat-error>
                </mat-form-field>
                <app-password-strength [passwordModel]="passwordField" passwordStrength="12"></app-password-strength>
            </div>

            <mat-form-field fxFlex>
                <mat-label>Confirm password</mat-label>
                <input type="password"
                    [(ngModel)]="this.account.credentials.password_confirm"
                    name="passwordConfirmField"
                    #passwordConfirmField="ngModel"
                    valueMustMatch="passwordField"
                    [required]="this.isPasswordRequired()"
                    [disabled]="this.locked"
                    matInput>
                <mat-error *ngIf="passwordConfirmField.hasError('required')">Passwords match is required.</mat-error>
                <mat-error *ngIf="passwordConfirmField.hasError('valueMustMatch')">Passwords must match.</mat-error>
            </mat-form-field>
        </div>
            
        <mat-divider></mat-divider>

        <div class="action-row" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-icon-button
                type="button"
                *ngIf="this.employee.discarded"
                [disabled]="this.loading"
                (click)="this.restore()"
                color="primary">
                <mat-icon>restore</mat-icon>
            </button>
            <button mat-icon-button
                type="button"
                *ngIf="!this.employee.discarded && this.employee.exists()"
                [disabled]="this.loading || !this.employee.exists()"
                (click)="this.discard()"
                color="primary">
                <mat-icon>delete</mat-icon>
            </button>
            <span fxFlex></span>

            <button mat-raised-button color="accent" type="submit">
                Save
            </button>
        </div>
    </form>
</mat-card>