import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Account } from './Account.model';
import { BaseModel } from './Base.model';
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { HttpResponse } from '../services/network/http-response';
import { LockVaultService } from '../services/lock-vault.service';
import { RouteMap } from '../services/network/route-map.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceLockService {
    constructor(protected routeMap: RouteMap) {}

    /**
     * Get a ResourceLock model from remote.
     *
     * @param {string} type
     * @param {string} resourceId
     * @returns {Promise}
     */
    async get(type: string, resourceId: string): Promise<ResourceLock> {
        const response = await this.routeMap.requestResourceLock(type, resourceId);
        return new ResourceLock( response.data() );
    }
}

// TODO: make sure the refreshing and timing works the same as it used to. 
export class ResourceLock extends BaseModel {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    static TYPE_WORK_ORDER = 'work-order';
    static TYPE_INVOICE    = 'invoice';
    static TYPE_DISPATCH   = 'dispatch';

    @dto() id: string = null;
    @dto() @date expires_at: number = 0;
    @dto() service?: LockVaultService;
    @dto() promise? = null;
    @dto() refreshSubscription?: Subscription;
    @dto() resource? = null;

    @dto(Account) account?: Account;

    async extend(): Promise<ResourceLock> {
        const response = await this.routeMap.extendResourceLock(this.id);
        return this.map(response.data());
    }

    async revoke(): Promise<HttpResponse> {
        this.selfRefresh(false);

        return this.routeMap.revokeResourceLock(this.id);
    }

    // Experimenting with Rxjs observables
    selfRefresh(enabled: boolean) {
        if (!enabled && !!this.refreshSubscription)
        {
            this.refreshSubscription.unsubscribe();
            this.refreshSubscription = null;
        }

        if (enabled && !this.refreshSubscription)
        {
            this.refreshSubscription = interval(60000).subscribe(t => this.refreshLock())
        }
    }

    protected async refreshLock() {
        const expiresIn = this.expires_at - moment().unix();

        // Refresh lock if it will expire within the next 90 seconds 
        if (expiresIn < 90)
        {
            try
            {
                await this.extend();
                console.log('Model lock refreshed');
            }
            catch (error)
            {
                try
                {
                    this.selfRefresh(false);
                    return this.service.requestLock(this.resource);
                }
                catch
                {
                    this.service.revokeLock(this.resource);
                }
            }
        }
    }

}
