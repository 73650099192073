import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { prefs } from "@beaconlite/services/preferences.service";
import { RentalDefinition, RentalOverride, VariantRental } from '@beaconlite/models';
import { VariantRentalCollection } from '@beaconlite/models/collections';
import { RentalOverrideEditorData } from './rental-override-editor-data.interface';

@Component({
  selector: 'app-rental-override-editor',
  templateUrl: './rental-override-editor.component.html',
  styleUrls: ['./rental-override-editor.component.scss']
})
export class RentalOverrideEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('searchTextField') searchTextField: NgModel;

  original: RentalOverride = this.data.original;
  onUpdate = this.data.onRentalUpdate;
  onRemove = this.data.onRentalRemove;

  loading = false;
  rentalOverride = new RentalOverride();
  selectedRental: VariantRental;
  searchText = '';
  promisedRentals: Promise<VariantRental[]>;
  isPercentDiscount = true;
  weeklyDiscount = 0;
  monthlyDiscount = 0;  
  weekThreshold: number;
  monthThreshold: number;

  overrideTypes = {
    rate: RentalOverride.TYPE_RATE,
    percent: RentalOverride.TYPE_PERCENT,
  };
  
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: RentalOverrideEditorData,
    public dialogRef: MatDialogRef<RentalOverrideEditorComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  { 
    if (!! this.original)
    {
      this.rentalOverride = this.original.copy();
      this.selectedRental = this.rentalOverride.variant_rental;
    }

    [this.weekThreshold, this.monthThreshold] = await Promise.all([
      prefs('pricing.week_threshold'), 
      prefs('pricing.month_threshold')
    ]);
  }

  canRemove(): boolean
  {
    return this.original.exists();
  }

  onDailyRateChange(): void
  {
    if ( !(this.rentalOverride.type === this.overrideTypes.rate)
      || this.isPercentDiscount) 
    {
      return;
    }

    // Refresh the values for $/day, $/week, $/month to reflect the updated this.rental.daily_rate.
    this.onWeeklyDiscountPriceChange();
    this.onMonthlyDiscountPriceChange();
  }

  onIsPercentDiscountChange(isPercentDiscount: boolean): void
  {
    // From $ to %.
    if (isPercentDiscount)
    {
      return;
    } 
    // From % to $.
    // Sync this.weeklyDiscountOverride / this.monthlyDiscountOverride values to correspond to this.rental.weekly_discount / this.rental.monthly_discount values
    this.weeklyDiscount = this._getDiscountPriceFromPercent(this.rentalOverride.weekly_discount, this.rentalOverride.daily_rate);
    this.monthlyDiscount = this._getDiscountPriceFromPercent(this.rentalOverride.monthly_discount, this.rentalOverride.daily_rate);
    // Refresh the values for $/day, $/week, $/month to reflect the value in this.weeklyDiscountOverride / this.monthlyDiscountOverride.
    this.onWeeklyDiscountPriceChange();
    this.onMonthlyDiscountPriceChange();
  }

  onWeeklyDiscountPriceChange(): void
  {
    this.rentalOverride.weekly_discount = this._getDiscountPercentFromPrice(this.weeklyDiscount, this.rentalOverride.daily_rate);
  }

  onMonthlyDiscountPriceChange(): void
  {
    this.rentalOverride.monthly_discount = this._getDiscountPercentFromPrice(this.monthlyDiscount, this.rentalOverride.daily_rate);
  }

  calculateRate(dailyRate: number, discount: number, days: number): number
  {
    return RentalDefinition.calculateRateFromDiscount(dailyRate, discount, days);
  }

  onQueryRentals(): void
  {
    const params = {
      name: this.searchText,
      order: 'asc',
    };

     this.promisedRentals = (new VariantRentalCollection()).all(params);
  }

  onRentalSelected(variant: VariantRental)
  {
    this.selectedRental = variant;
    this.rentalOverride.resetVariant();

    if (!! variant)
    {
      this.rentalOverride.inheritVariant(variant);
    }
  }

  displaySelectedRental = (rental?: VariantRental): string => 
  {
    if (!!this.original?.exists() && this.searchTextField.pristine)
    {
      this.searchTextField.control.setErrors(null);
      return this.original.variant_rental.display_name;
    }

    return rental?.display_name || '';
  }

  async save(): Promise<void> 
  {
    if (this.editorForm.invalid) return;

    this.loading = true;
    
    try
    {
      await this.onUpdate(this.original, this.rentalOverride);
    }
    finally
    { 
      this.loading = false;
      this.dialogRef.close();
    }
  }

  async remove(): Promise<void> 
  {
    this.loading = true;
    
    try
    {
      if (await this.dialogNotification.removeConfirm())
      {
        await this.onRemove(this.original);
      }
    }
    finally
    {
      this.loading = false;
      this.dialogRef.close();
    }
  }

  protected _getDiscountPercentFromPrice(discount: number, dailyRate: number): number
  {
    if (dailyRate == 0)
    {
      return 0;
    }

    const decimalPlaces = 4;
    const discountPercentage = this._roundDecimals( ( 1 - ( discount / dailyRate ) ), decimalPlaces);
    return discountPercentage;
  }

  protected _getDiscountPriceFromPercent(discount: number, dailyRate: number): number {
    return ((1 - discount) * dailyRate);
  }

  protected _roundDecimals (number: number, digits: number): number
  {
    const multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        roundedNum = Math.round(adjustedNum);
    return roundedNum / multiplier;
  }
}
