import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Charge, Note, Service, WorkOrder } from '@beaconlite/models';
import { ChargeEditorService } from '../../../../../../components/editors/charge-editor/charge-editor.service';
import { NoteEditorService } from '../../../../../../components/editors/note-editor/note-editor.service';
import { ServiceEditorService } from '../service-editor/service-editor.service';
import { DateFilterPipe } from '../../../../../../pipes/date-filter.pipe';
import { DialogNotificationService } from '../../../../../../services/notification/dialog-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'service-component',
  templateUrl: './service.component.html',
})
export class ServiceComponent implements OnInit, DoCheck {

  @Input() service: Service;
  @Input() workOrder: WorkOrder;
  @Input() shouldShowDepartment: boolean;
  @Input() onUpdate: (service: Service) => Promise<any>;
  @Input() onRemove: (service: Service) => Promise<any>; 

  constructor(
    protected dateFilter: DateFilterPipe,
    protected serviceEditor: ServiceEditorService,
    protected chargeEditor: ChargeEditorService,
    protected noteEditor: NoteEditorService,
    protected dialogNotifications: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  displayService(): string
  {
    return this.service.name || 'Service';
  }

  async onEdit(): Promise<Service|void>
  {
    return this.serviceEditor.open({
      workOrder: this.workOrder,
      original: this.service,
      onServiceUpdate: this.onServiceUpdate,
      onServiceRemove: this.onServiceRemove,
    });
  }

  onServiceUpdate = async (updatedService: Service): Promise<void> => 
  { 
    this.service.map(updatedService.flush());
    await this.onUpdate(this.service);
  }

  onServiceRemove = async (): Promise<void> => 
  {
    if (! await this.dialogNotifications.removeConfirm()) return;
    this.onRemove(this.service);
  }

  async onDuplicate(): Promise<any>
  {
    const onDuplicateService = async (newService: Service) => {
      await this.onUpdate(newService);
    }

    return this.serviceEditor.open({
      workOrder: this.workOrder,
      original: this.service.duplicate(),
      onServiceUpdate: onDuplicateService,
      onServiceRemove: null,
    });        
  }

  async onAddCharge(): Promise<boolean|void>
  {
    return this.onEditCharge();
  }

  async onEditCharge(charge: Charge = null): Promise<boolean|void>
  {
    const onChargeUpdate = async (updatedCharge: Charge) => {
      updatedCharge?.exists()
        ? charge.mapAttributes(updatedCharge.flushAttributes())
        : this.service.addCharge(updatedCharge);
      
      await this.onUpdate(this.service);
    } 

    const onChargeRemove = async () => {
      this.service.removeCharge(charge);
      await this.onUpdate(this.service);
    }

    return this.chargeEditor.open({
      original: charge,
      onChargeUpdate,
      onChargeRemove,
    });
  }

  onAddNote()
  {
    return this.onEditNote();
  }

  onEditNote(note: Note = null)
  {
    const onNoteUpdate = async (updatedNote: Note) => {
      updatedNote?.exists()
        ? note.mapAttributes(updatedNote.flushAttributes())
        : this.service.addNote(updatedNote);
      
      await this.onUpdate(this.service);
    }

    const onNoteRemove = async () => {
      this.service.removeNote(note);
      await this.onUpdate(this.service);
    }

    return this.noteEditor.open({
      original: note,
      onNoteUpdate,
      onNoteRemove,
    });
  }

  async remove(): Promise<void>
  {
    if (! await this.dialogNotifications.removeConfirm()) return;
    await this.onRemove(this.service);
  }

  displayPeriod(): string
  {
    if ( this.service.started_at )
    {
        let periodString = this.dateFilter.transform(this.service.started_at, 'dateTimeMedium');
        
        if (!! this.service.ended_at)
        {

            periodString += ' - ' + this.dateFilter.transform(this.service.ended_at, 'dateTimeMedium');
        }
        else
        {
            periodString += ' — TBD';
        }

        return periodString;
    }

    return 'N/A';
  }

  protected _sortItems()
  {
    this.service.charges?.sort(orderBy('created_at'));
    this.service.notes?.sort(orderBy('noted_at'));
  }
}
