import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { SmsNotificationsData } from "./sms-notifications-data.interface";
import { SmsNotificationsComponent } from "./sms-notifications.component";

@Injectable({
    providedIn: 'root'
})
export class SmsNotificationsService {

    constructor(protected dialog: MatDialog) {}

    async open(data: SmsNotificationsData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            height: '100%',
            maxHeight: 500,
            data,
        }

        return this.dialog.open(SmsNotificationsComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
  