import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountingSetting, Integration } from '@beaconlite/models';
import { AccountingSettingsEditorData } from './accounting-settings-editor-data.interface';

// TODO: Jira task BL-507. This editor only works for quickbooks, not all integrations. Relocate
// into quickbooks directory and rename appropriately.
@Component({
  selector: 'app-accounting-settings-editor',
  templateUrl: './accounting-settings-editor.component.html',
})
export class AccountingSettingsEditorComponent implements OnInit {

  original = this.data.original;
  onUpdate = this.data.onAccountingSettingsUpdate;

  @ViewChild('editorForm') editorForm: NgForm;

  loading = false;

  settings: AccountingSetting;
  isActiveIntegrationQuickbooks: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: AccountingSettingsEditorData,
    public dialogRef: MatDialogRef<AccountingSettingsEditorComponent>,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.settings = this.original.copy();

    const activeIntegration: Integration = await Integration.getActiveAccountingModel();
    this.isActiveIntegrationQuickbooks = activeIntegration.slug === Integration.QUICKBOOKS;
  }

  async save(): Promise<void>
  {
    if (this.editorForm.invalid) return;
    
    this.loading = true;

    try
    {
      await this.onUpdate(this.settings);
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }
}
