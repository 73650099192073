<mat-toolbar fxLayout="row" 
             fxLayoutAlign="space-between center"
             color="warn">
    <span>Warning</span>
    <span><mat-icon>warning</mat-icon></span>
</mat-toolbar>

<mat-dialog-content>
    <div>
        <h2 [innerHtml]="data.title"></h2>
        <p [innerHtml]="data.textContent"></p>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button [mat-dialog-close]="false">{{ data.cancelText || 'Cancel' }}</button>
    <button mat-raised-button 
            [mat-dialog-close]="true"
            color="warn">{{ data.okText || 'Okay' }}</button>
</mat-dialog-actions>