import { BaseModel } from "./Base.model";
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";
import { SingleTaxRate } from "./contracts/SingleTaxRate.interface";

export class TaxRate extends BaseModel {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id: string = null;
    @dto() tag?: string = null;
    @dto() rates?: SingleTaxRate[] = [];

    static async get(tag: string): Promise<TaxRate> 
    {
        const response = await AppInjector.get(RouteMap).getTaxRate(tag);
        return new TaxRate(response.data());
    } 

    static async getAll(): Promise<TaxRate[]> 
    {
        const response = await AppInjector.get(RouteMap).allTaxRates();
        const castCollection = response.data().map( data => new TaxRate(data));

        return castCollection;
    }
}
