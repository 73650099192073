import IRental from "@beaconlite/models/contracts/IRental.interface";
import IRentalRequest from "@beaconlite/models/contracts/IRentalRequest.interface";
import IRentalReturn from "@beaconlite/models/contracts/IRentalReturn.interface";

export class RentalGroupBuilderHelper {

    getUngroupedRentals<T extends IRental>(request: IRentalRequest): Array<T> {
        let ungrouped_rentals: T[] = [];

        for (let i = 0; i < request.rentals.length; i+=1) {

            const groupIdTarget = this.getGroupIdTarget(request.rentals[i]);

            if (groupIdTarget == null) {
                ungrouped_rentals.push(request.rentals[i] as T);
            }
        }

        return ungrouped_rentals;
    }

    setRentalsOnGroups(request: IRentalRequest): void {

        for (let j = 0; j < request.rental_groups.length; j+=1) {
            // Clear rentals on groups. Not doing this would break .copy().
            // TODO Jira-BL-737: Once we make the API consistent, can remove the dto() from rentals in the RentalGroup, meaning .copy will not create a flush of the related rentals.
            request.rental_groups[j].rentals = [];
        }

        for (let i = 0; i < request.rentals.length; i+=1) {

            const groupIdTarget = this.getGroupIdTarget(request.rentals[i]);

            if (groupIdTarget != null) {
                let foundGroup = false;

                for (let j = 0; j < request.rental_groups.length; j+=1) {

                    const curGroupId = this.getCurGroupId(request.rental_groups[j]);

                    if (groupIdTarget == curGroupId) {
                        request.rental_groups[j].rentals.push(request.rentals[i]);
                        foundGroup = true;
                        break;
                    }
                }
                
                if (!foundGroup) {
                    throw new Error(`Rental ${request.rentals[i].name} was not matched with its group.`);
                }
            }
        }
    }

    protected getGroupIdTarget(rental: IRental): string | null {
        // If dispatched, get the source's group_id.
        if (!!rental.source) {
            return rental.source.rental_group_id;
        } 

        // If invoiced, get the invoiced_rental_group's original rental_group_id
        if (!!rental.rental_group) {
            return rental.rental_group.rental_group_id;
        }
        // Otherwise, on request. tmp_id is for duplicated groups.
        return rental.rental_group_id ?? rental.rental_group_tmp_id;
    }

    protected getGroupIdTargetForReturn(aReturn: IRentalReturn): string | null {
        return aReturn.rental.rental_group_id;
    }

    protected getCurGroupId(rentalGroup): string | null {
        // Dispatched groups have the source id directly on them.
        if (rentalGroup?.rental_group_id?.length) {
            return rentalGroup.rental_group_id;
        } 
        // Otherwise, on request. tmp_id is for duplicated groups.
        return rentalGroup.id ?? rentalGroup.tmp_id;
    }

}
