import { BaseModel } from './Base.model';
import { Discardable } from './mixins/Discardable.mixin'
import { dto } from './mixins/Dto.decorators'
import { date } from './mixins/Date.decorators';
import { LineItem } from './LineItem.model';
import { ShortIdUtility } from './utilities/ShortId.utility';
import { Helpers as $helpers } from '@beaconlite/services/helpers.service';

const MixinBase = Discardable( BaseModel );
export class LineItemRequest extends MixinBase {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id: string = null;
    @dto() department_id: string = null;
    @dto() serial_id: string = null;
    @dto() work_order_id: string = null;
    @dto() locked: boolean = false;
    @dto() invoiced: boolean = false;
    @dto() ordered_by: string = null;
    @dto() tags: string = null;
    @dto() @date started_at: number = null;

    @dto(LineItem) line_items: LineItem[] = [];

    protected static readonly _prefix: string = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_LINE_ITEM);

    async save(): Promise<LineItemRequest> {
        const response = (this.exists())
            ? await this.routeMap.updateLineItemRequest(this.id, this.flush())
            : await this.routeMap.createLineItemRequest(this.flush());
        
        return this.map(response.data());
    }

    async delete(): Promise<void> {
        const response = await this.routeMap.deleteLineItemRequest(this.id);
    }

    duplicate() : LineItemRequest
    {
        const duplicate = new LineItemRequest( this.flushAttributes() );

        // Reset computed and unique values back to model defaults
        duplicate.id                = null;
        duplicate.locked            = false;
        duplicate.invoiced          = false;
        duplicate.created_at        = null;
        duplicate.updated_at        = null;

        this.line_items.forEach( item => { 
            duplicate.addLineItem(item.duplicate()) 
        });

        return duplicate;
    }

    hasLineItems(): boolean {
        return this.line_items.length > 0;
    }

    canAddLineItems(): boolean
    {    
        return !this.locked;
    }

    addLineItem(requestItem: LineItem): void
    {
        this.line_items.push(requestItem);
    }

    removeLineItem(removedRequestItem: LineItem): void
    {
        const testFunc = (requestItem: LineItem) => {
            return removedRequestItem.id === requestItem.id;
        }

        const index = this.line_items.findIndex(testFunc);
        
        if (index >= 0)
        {
            this.line_items.splice(index, 1);
        }
    }

    get formatted_serial_id(): string 
    {
        if (! this.serial_id) { return ''; }

        return LineItemRequest.formatSerialId(this.serial_id);
    }

    static formatSerialId(serialId: string): string
    {
        let padded = $helpers.zeroPadLeft(serialId, 6);
        const prefix = LineItemRequest._prefix;
        return `${prefix}-${padded}`;
    }
}
