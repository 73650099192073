import { HttpResponse } from "../../services/network/http-response";
import { BaseCollection } from "./Base.collection";
import { AppInjector } from "../../services/app-injector.service";
import { RouteMap } from "../../services/network/route-map.service";
import { LineItemDefinition } from "../LineItemDefinition.model";

export class LineItemDefinitionCollection extends BaseCollection<LineItemDefinition> {

    constructor(options?: any) {
        super(LineItemDefinition, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchLineItemDefinitions(params);
    }
}
