<form class="mat-dialog-editor branch-override-editor" #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
    <mat-toolbar>
        <h2 class="mat-title">{{ this.branch.name }} - Override</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding" *ngIf="this.original">
        <div fxLayout="column">
            <div class="override-name-label">{{ this.override.value.name }}</div>
            <mat-form-field>
                <mat-label>Account</mat-label>
                <mat-select [(ngModel)]="this.override.value.account" 
                    name="overrideAccountField" 
                    #overrideAccountField="ngModel"
                    required>
                    <mat-option *ngFor="let account of this.accounts" [value]="account.key">
                        {{ getAccountString(account) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-content class="dialog-padding" *ngIf="!this.original">
        <div fxLayout="column">
            <mat-form-field fxflex>
                <mat-label>Item Type</mat-label>
                <mat-select [(ngModel)]="this.override.itemType"
                    name="itemTypeField"
                    (ngModelChange)="this.onItemTypeChanged()"
                    required>
                    <mat-option value="charges">Charge</mat-option>
                    <mat-option value="rentals">Rental</mat-option>
                    <mat-option value="services">Service</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Select Item</mat-label>
                <input type="text"
                    [(ngModel)]="this.searchText"
                    name="searchTextField"
                    #searchTextField="ngModel"
                    [matAutocomplete]="searchTextAuto"
                    (ngModelChange)="this.onQueryItems()"
                    required
                    matInput>
                <mat-autocomplete #searchTextAuto="matAutocomplete"
                    (optionSelected)="this.onItemSelected($event.option.value)"
                    [displayWith]="displaySelectedItem">
                    <mat-option *ngFor="let item of promisedItems | async" [value]="item">
                        {{ item.display_name }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="searchTextField.hasError('alreadyPresent')">Item Override already present.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Account</mat-label>
                <mat-select [(ngModel)]="this.override.value.account" 
                    name="accountField"
                    required>
                    <mat-option *ngFor="let account of this.accounts" [value]="account.key">
                        {{ getAccountString(account) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign=" center">
        <button type="button" mat-icon-button>
            <mat-icon *ngIf="this.original" (click)="this.remove()" color="primary">delete</mat-icon>
        </button>

        <span fxFlex></span>

        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.saving">
            Save
        </button>
    </mat-dialog-actions>
</form>