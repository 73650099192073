import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Department, Dispatch, WorkOrder } from '@beaconlite/models';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { LockVaultService } from '@beaconlite/services/lock-vault.service';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-dispatch-single',
  templateUrl: './dispatch-single.component.html',
  styleUrls: ['./dispatch-single.component.scss']
})
export class DispatchSingleComponent implements OnInit, OnDestroy {
  
  @Input() modelId: string;
  @Input() stateOnClose: string;

  loading = false;
  dispatch = new Dispatch();
  workOrder = new WorkOrder();
  departments: Department[] = [];
  isAdmin: boolean = false;
  canEdit: boolean = false;

  constructor(
    private lockVault: LockVaultService,
    private stateService: StateService,
    protected appData: AppData,
    protected authService: AuthenticationService,
  ) { }
 
  async ngOnInit(): Promise<void> 
  {
    this.loading = true;

    try
    {
      this.dispatch = await Dispatch.get(this.modelId);
      this.departments = await this.appData.departments.getAll();
      this.workOrder = new WorkOrder(this.dispatch.work_order);

      await this._initPermissions();

      if (this.canEdit)
      {
        await this.lockVault.requestLock(this.workOrder);
      }
    }
    finally
    {
      this.loading = false;
    }
  }

  ngOnDestroy(): void 
  {
    if (!this.canEdit) { return; }

    this.lockVault.revokeLock(this.workOrder);
  }

  onDispatchUpdate = async (): Promise<void> =>
  {
    if (!this.canEdit) { return; }

    this.dispatch = await Dispatch.get(this.modelId);
  }

  onDispatchRemove = (): void =>
  {
    if (!this.canEdit) { return; }

    this.stateService.go(this.stateOnClose);
  }

  onClose(): void
  {
    this.stateService.go(this.stateOnClose);
  }

  protected async _initPermissions(): Promise<void>
  {
    const account = await this.authService.getCurrentUser();
    this.isAdmin = account.isAdmin();
    const isAssignedToDispatch = this.dispatch.employees.find(employee => employee.id === account.employee_id);
    this.canEdit = (this.isAdmin || isAssignedToDispatch) ? true : false;
  }
}
