import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';
import { filter, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordStrengthComponent implements OnInit {
  @Input() passwordModel: NgModel;
  @Input() passwordStrength?: number = 8; 

  passwordControl: AbstractControl;
  passwordStrengthScore: number;
  strengthBarValue: number;
  strengthBarColor: string = 'warn';

  // Heavily modified from http://jsfiddle.net/dimitar/n8Dza/
  checks = [
    /* alphaLower */ {
        type: 'alphaLower',
        re: /[a-z]/,
        score: 1
    },
    /* alphaUpper */ {
        type: 'alphaUpper',
        re: /[A-Z]/,
        score: 1
    },
    /* digit */ {
        type: 'digit',
        re: /([0-9])/,
        score: 1
    },
    /* special chars */ {
        type: 'specialChar',
        re: /.[!@#$%^&*?_~]/,
        score: 1
    },
    /* mixture of upper and lowercase */ {
        type: 'caseMixture',
        re: /([a-z].*[A-Z])|([A-Z].*[a-z])/,
        score: 1
    },
    /* threeNumbers */ {
        type: 'threeNumbers',
        re: /(.*[0-9].*[0-9].*[0-9])/,
        score: 1
    },
    /* multiple special chars */ {
        type: 'multiSpecial',
        re: /(.*[!@#$%^&*?_~].*[!@#$%^&*?_~])/,
        score: 1
    },
    /* all together now, does it look nice? */ {
        type: 'combination',
        re: /([a-zA-Z0-9].*[!@#$%^&*?_~])|([!@#$%^&*?_~].*[a-zA-Z0-9])/,
        score: 1
    }
  ];

  ngOnInit(): void 
  {
    this.passwordControl = this.passwordModel.control;

    this.passwordControl.valueChanges
        .pipe(filter(pass => !!pass))
        .subscribe(value => this.onPasswordChange(value));
  }

  onPasswordChange(value: string) 
  {
    const password = value;
    const minChar = this.passwordStrength;
    const length = password.length;
    let score = 25 - minChar;
    let multiplier = 0
    let diff = length - minChar;
    let missingCriteria = false;

    if (! password.length)
    {
        this.strengthBarValue = 0;
        return;
    }

    // Less than minimum gets handicapped
    if (diff < 0)
    {
        score -= 100;
        missingCriteria = true;
    }
    // Scaling multiplier based on checks
    else
    {
        multiplier += diff / (minChar / 2)
    }

    // Increase multiplier based on checks
    this.checks.forEach(check => {
        if (password.match(check.re))
        {
            multiplier += check.score;
        }
        else if (check.type == 'alphaUpper' || 
                 check.type == 'alphaLower' ||
                 check.type == 'digit')
        {
            score -= 100;
            missingCriteria = true;
        }
    });

    // bonus for length per char
    score += multiplier * length;

    score = (score < 25) ? 25 : score;
    score = (score > 125) ? 125 : score;
    this.strengthBarValue = (score / 125) * 100;

    if (diff < 0 || missingCriteria)
    {
        this.passwordControl.setErrors({ passwordStrength: true });
        this.strengthBarColor = 'warn';
    }
    else if (score <=32)
    {
        this.strengthBarColor = 'accent';
    }
    else
    {
        this.strengthBarColor = 'success';
    }
  }

}
