import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-task-timer',
  templateUrl: './task-timer.component.html',
  styleUrls: ['./task-timer.component.scss']
})
export class TaskTimerComponent implements OnInit, OnDestroy, OnChanges {

  @Input() task: string; 
  @Input() startTime: any; 
  @Input() punchtime: any;

  _internalTimer: Subscription = null;
  isStarted = false;
  shifttimer = null;
  punchtimer = null;

  constructor() { }

  ngOnInit(): void 
  {
    this.isStarted = !!this.punchtime;

    this.resetTimer();
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.punchtime.currentValue)
    {
      this.resetTimer();
    }
    else
    {
      this.stopTimer();
    }

    if (changes.punchtime?.currentValue != changes.punchtime?.previousValue ||
        changes.startTime?.currentValue != changes.startTime?.previousValue)
    {
      this.resetTimer();
    }
  }

  ngOnDestroy(): void
  {
    this.stopTimer();
  }

  startTimer(): void
  {
    this._setTimerValues();

    this._internalTimer = interval(1000).subscribe(() => { 
      this._setTimerValues(); 
    });
  }

  stopTimer(): void
  {
    if (!! this._internalTimer)
    {
      this._internalTimer.unsubscribe();
    }
  }

  resetTimer(): void
  {
    this.stopTimer();
    this._setTimerValues(true);

    if (this.isStarted)
    {
      this.startTimer();
    }
  }

  // Utils
  _setTimerValues(zerofill?: boolean): void
  {
      const shifttime = zerofill ? 0 : this.startTime;
      const punchtime = zerofill ? 0 : this.punchtime;
      
      this.shifttimer = this._getTimerValues(shifttime);
      this.punchtimer = this._getTimerValues(punchtime);
  }


  _getTimerValues(timestamp: number): {hours: string, minutes: string, seconds: string}
  {
    const current_time = moment().unix();
    const duration = timestamp ? current_time - timestamp : 0;

    const hours   = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - (hours * 3600)) / 60);
    const seconds = Math.floor(duration - (hours * 3600) - (minutes * 60));

    return {
        hours:      this._normalizeToDoubleDigit(hours),
        minutes:    this._normalizeToDoubleDigit(minutes),
        seconds:    this._normalizeToDoubleDigit(seconds)
    };
  }

  _normalizeToDoubleDigit(n: number): string
  {
    return n > 9 ? "" + n : "0" + n;
  }
}
