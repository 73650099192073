import { Injector } from '@angular/core';
import type { Constructor } from '@beaconlite/types';

export class AppInjector {
  private static _injector: Injector;

  static initialize(injector: Injector) {
    if (! this._injector) 
    {
      this._injector = injector;
    }
  }

  static get<T>(service: Constructor<T>): T {
    if (! this._injector) 
    {
      return;
    }

    return this._injector.get<T>(service);
  }
}
