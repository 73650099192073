import { Component, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';

export interface Sage50AdditionalSettingsData {
  original: number,
  onUpdate: (invoiceNumber: number) => Promise<void>,
}

@Injectable({
  providedIn: 'root'
})
export class Sage50AdditionalSettingsService {

  constructor(protected dialog: MatDialog) {}

  async open(data: Sage50AdditionalSettingsData): Promise<boolean> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data
    }

    return this.dialog.open(Sage50AdditionalSettingsComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-sage50-additional-settings-editor',
  templateUrl: './sage50-additional-settings-editor.component.html',
  styleUrls: ['./sage50-additional-settings-editor.component.scss']
})
export class Sage50AdditionalSettingsComponent implements OnInit {
  @ViewChild('editorForm') editorForm: NgForm;

  original = this.data.original;
  onUpdate = this.data.onUpdate;

  isSaving = false;
  invoiceNumber = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: Sage50AdditionalSettingsData,
    public dialogRef: MatDialogRef<Sage50AdditionalSettingsComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  ngOnInit(): void {
    if (this.original) {
      this.invoiceNumber = 'INV-' + this.original.toString();
    }
  }

  onInvoiceNumberChange() {
    this.editorForm.controls.invoiceNumberField.setErrors(null);

    if (this.invoiceNumber.length < 4) { return; }

    // Regex checking if all characters are digits.
    const isNumberOnly = /^\d+$/.test(this.invoiceNumber);
    if (isNumberOnly) { return; }
 
    const invoiceNumPrefix = this.invoiceNumber.slice(0,4);
    const invoiceNumNumber =  this.invoiceNumber.slice(4, this.invoiceNumber.length);
    const isInvoiceNumNumberValid = /^\d+$/.test(invoiceNumNumber);

    if (invoiceNumPrefix !== 'INV-') {
      this.editorForm.controls.invoiceNumberField.setErrors({ incorrectFormat: true });
    } else if (!(invoiceNumNumber.length > 0)) {
      this.editorForm.controls.invoiceNumberField.setErrors({ incompleteNumber: true });
    } else if (!isInvoiceNumNumberValid) {
      this.editorForm.controls.invoiceNumberField.setErrors({ invalidCharacters: true });
    }
  }

  async onSave(): Promise<void>
  {
    if (this.editorForm.invalid) { return; }

    this.isSaving = true; 

    await this.onUpdate(this.getNum());
    this.dialogRef.close();

    this.isSaving = false;
  }
  
  private getNum(): number | null {
    const isNumberOnly = /^\d+$/.test(this.invoiceNumber); 

    if (isNumberOnly) {
      return parseInt(this.invoiceNumber);
    }

    if (this.invoiceNumber.length < 4) {
      return null;
    } else {
      return parseInt(this.invoiceNumber.slice(4));
    }
  }
}
