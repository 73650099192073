import { Ng2StateDeclaration } from '@uirouter/angular';
import { LineItemIndexComponent } from './line-item-index.component';

export const LineItemIndexState: Ng2StateDeclaration = {
  name: 'protected.line-items',
  url: '/line-item',
  component: LineItemIndexComponent,
  data: { 
    pageTitle: 'Line Item Index', 
    requiresAuth: true,
  },
}
