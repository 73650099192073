<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" >
        <h2>Dispatch Editor</h2>
        <mat-icon *ngIf="this.dispatch.locked">lock_outline</mat-icon>       
    </mat-toolbar> 

    <mat-dialog-content>
        <div class="dialog-padding">
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex="20">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="this.dispatch.type"
                        name="typeField"
                        #typeField="ngModel"
                        [disabled]="this.isNotModifiable() || !!this.dispatch.created_at"
                        (ngModelChange)="this.onTypeChange()"
                        cdkFocusInitial
                        required>
                        <mat-option [value]="TYPE_DELIVERY">Delivery</mat-option>
                        <mat-option [value]="TYPE_PICKUP">Retrieval</mat-option>
                    </mat-select>
                    <mat-error *ngIf="typeField.hasError('required')">Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex>
                    <mat-label>Location</mat-label>
                    <input type="text"
                        [(ngModel)]="this.dispatch.location"
                        name="locationField"
                        #locationField="ngModel"
                        [disabled]="this.isNotModifiable()"
                        required
                        maxlength="255"
                        matInput>
                    <mat-hint align="end">{{ this.dispatch.location?.length || 0 }} / 255</mat-hint>
                    <mat-error *ngIf="locationField.hasError('required')">Location is required.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="25" *ngIf="!!this.dispatchRegions?.length">
                    <mat-label>Dispatch Region</mat-label>
                    <mat-select [(ngModel)]="this.dispatch.dispatch_region_id"
                        name="dispatchRegionField"
                        [disabled]="this.isNotModifiable()">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let region of this.dispatchRegions" [value]="region.id">
                            {{ region.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Site Contact</mat-label>
                    <input type="text"
                        [(ngModel)]="this.dispatch.site_contact"
                        name="siteContactField"
                        #siteContactField="ngModel"
                        maxlength="255"
                        [disabled]="this.dispatch.dispatched_at"
                        matInput>
                    <mat-hint align="end">{{ this.dispatch.site_contact?.length || 0 }} / 255</mat-hint>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex="25">
                    <mat-label>Delivery Date</mat-label>
                    <input type="text"
                        [(ngModel)]="this.dispatch._dates.scheduled_at.date"
                        name="deliveryDateField"
                        #deliveryDateField="ngModel"
                        [matDatepicker]="deliveryDatePicker"
                        [max]="this.dispatch._dates.scheduled_end_at.date"
                        [disabled]="this.isNotModifiable()"
                        (focus)="deliveryDatePicker.open()"
                        required
                        matInput>
                    <mat-datepicker-toggle matSuffix [for]="deliveryDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #deliveryDatePicker></mat-datepicker>
                    <mat-error *ngIf="deliveryDateField.hasError('required')">Delivery date is required.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="25">
                    <mat-label>Time</mat-label>
                    <input type="time"
                        [(ngModel)]="this.dispatch._dates.scheduled_at.time"
                        name="deliveryTimeField"
                        #deliveryTimeField="ngModel"
                        required
                        [disabled]="this.isNotModifiable()"
                        matInput>
                    <mat-error *ngIf="deliveryTimeField.hasError('required')">Delivery time is required.</mat-error>
                </mat-form-field>   
                
                <mat-checkbox [(ngModel)]="this.dispatch.time_specified"
                    name="timeSpecifiedField"
                    fxFlexAlign="center"
                    [disabled]="this.isNotModifiable()">
                    Time Critical
                </mat-checkbox>
            </div>
            
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex="25">
                    <mat-label>End Date</mat-label>
                    <input type="text"
                        [(ngModel)]="this.dispatch._dates.scheduled_end_at.date"
                        name="endDateField"
                        #endDateField="ngModel"
                        [matDatepicker]="endDatePicker"
                        [min]="this.dispatch._dates.scheduled_at.date"
                        [disabled]="this.dispatch.completed_at"
                        (focus)="endDatePicker.open()"
                        matInput>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                    <mat-error *ngIf="endDateField.hasError('matDatepickerMin')">End date is too early.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="25">
                    <mat-label>Time</mat-label>
                    <input type="time"
                        [(ngModel)]="this.dispatch._dates.scheduled_end_at.time"
                        name="endTimeField"
                        #endTimeField="ngModel"
                        [disabled]="this.isNotModifiable()"
                        matInput>
                </mat-form-field>   
                
                <mat-form-field fxFlex="25">
                    <mat-label>Travel Time Required</mat-label>
                    <input type="text"
                        [(ngModel)]="this.dispatch.travel_time"
                        name="travelTimeField"
                        #travelTimeField="ngModel"
                        [disabled]="this.dispatch.completed_at"
                        timeInput
                        matInput>
                    <mat-error *ngIf="travelTimeField.hasError('required')">Travel time is required.</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="column" *ngIf="!!this.defaultChargeDefinitions?.length">
                <span class="mat-subheading-1">Default Charges</span>

                <mat-checkbox *ngFor="let charge of this.defaultChargeDefinitions; let i = index"
                    [(ngModel)]="charge.checked"
                    name="defaultChargeField{{i}}">
                    {{ charge.definition.label}} ({{charge.definition.flat_value | currencyFilter }})
                </mat-checkbox>
            </div>
        </div>

        <!-- Rental Requests -->
        <mat-divider></mat-divider>

        <div class="dialog-padding request-title" fxLayout="row">
            <h2 fxFlex>Rental Requests</h2>
        </div>

        <div class="dialog-padding" fxLayout="row">
            <mat-table [dataSource]="this.rental_requests" 
                [fxShow]="!!this.rental_requests.length" 
                fxFlex>
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                    <mat-cell *matCellDef="let request">
                        <mat-checkbox 
                            (change)="this.toggleRequest(this.RENTAL_REQUESTS, request)"
                            [checked]="this.hasRequest(this.RENTAL_REQUESTS, request)"
                            [disabled]="this.isNotModifiable(request)">
                        </mat-checkbox>
                        {{ request.source.formatted_serial_id }}
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="item-count">
                    <mat-header-cell *matHeaderCellDef>Item Count</mat-header-cell>
                    <mat-cell *matCellDef="let request">
                        &nbsp;{{ request.num_unique_rentals }} items<br>({{ this.dispatch.getUnitCount(request) }} units)
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="start-end">
                    <mat-header-cell *matHeaderCellDef>Start - End</mat-header-cell>
                    <mat-cell *matCellDef="let request">
                        {{ request.dispatched_started_at | dateFilter:'dateMedium' }}<br>{{ request.dispatched_ended_at | dateFilter:'dateMedium':'TBD' }}
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                    <mat-cell *matCellDef="let request">{{ request.source.location }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="this.rentalRequestTableColumns"></mat-header-row>
                <mat-row *matRowDef="let request; columns: this.rentalRequestTableColumns"></mat-row>
            </mat-table>
            <div fxFlex [fxShow]="!this.rental_requests.length">No available rental requests.</div> 
        </div>

        <!-- Services -->
        <mat-divider></mat-divider>

        <div class="dialog-padding request-title" fxLayout="row">
            <h2 fxFlex>Services</h2>
        </div>

        <div class="dialog-padding" fxLayout="row">
            <mat-table [dataSource]="this.services" 
                [fxShow]="!!this.services.length"
                fxFlex>
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                    <mat-cell *matCellDef="let service">
                        <mat-checkbox 
                            (change)="this.toggleRequest(this.SERVICE_REQUESTS, service)"
                            [checked]="this.hasRequest(this.SERVICE_REQUESTS, service)"
                            [disabled]="this.isNotModifiable(service)">
                        </mat-checkbox>
                        {{ service.source.formatted_serial_id }}
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                    <mat-cell *matCellDef="let service">{{ service.source.name }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="start-end">
                    <mat-header-cell *matHeaderCellDef>Start - End</mat-header-cell>
                    <mat-cell *matCellDef="let service">
                        {{ service.dispatched_started_at | dateFilter:'dateTimeMedium' }}<br>{{ service.dispatched_ended_at | dateFilter:'dateTimeMedium':'TBD' }}
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                    <mat-cell *matCellDef="let service">{{ service.source.location }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="serviceTableColumns"></mat-header-row>
                <mat-row *matRowDef="let service; columns: serviceTableColumns"></mat-row>
            </mat-table>

            <div fxFlex [fxShow]="!this.services.length">No available services.</div>
        </div>

        <mat-divider></mat-divider>

        <!-- Notes -->

        <div class="dialog-padding">        
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Vehicle</mat-label>
                    <input type="text"
                        [(ngModel)]="this.dispatch.truck_identifier"
                        name="truckIdentifierField"
                        maxlength="255"
                        [disabled]="this.dispatch.completed_at"
                        matInput>
                    <mat-hint align="end">{{ this.dispatch.truck_identifier?.length || 0 }} / 255</mat-hint>
                </mat-form-field>
            </div>

            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Employees</mat-label>
                    <mat-chip-list #employeeChipList>
                        <mat-chip *ngFor="let employee of this.dispatch.employees"
                            removable
                            (removed)="this.onRemoveEmployeeChip(employee)">
                            <app-dispatch-employee-label 
                                [employee]="employee">
                            </app-dispatch-employee-label>
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input name="employeeSearchTextField"
                            #employeeSearchTextField="ngModel"
                            [(ngModel)]="this.employeeSearchText"
                            (ngModelChange)="this.onQueryEmployees()"
                            [matAutocomplete]="employeeAuto"
                            [matChipInputFor]="employeeChipList"
                            (matChipInputTokenEnd)="onAddEmployeeChip($event)"
                            matChipInputAddOnBlur>
                    </mat-chip-list>
                    <mat-autocomplete #employeeAuto="matAutocomplete"
                        (optionSelected)="this.onEmployeeSelected($event.option.value)">
                        <mat-option *ngFor="let employee of promisedEmployees | async" [value]="employee">
                            <app-dispatch-employee-label 
                                [employee]="employee">
                            </app-dispatch-employee-label>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Dispatch Note</mat-label>
                    <textarea [(ngModel)]="this.firstDispatchNote.content"
                        name="firstDispatchNoteField"
                        (focus)="$event.target.select()"
                        matInput>
                    </textarea>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.loading" diameter="36" mode="indeterminate"></mat-progress-spinner>
        <button mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>

