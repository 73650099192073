import { Ng2StateDeclaration } from '@uirouter/angular';
import { DispatchedItemComponent } from './dispatched-item.component';

export const DispatchedItemReportState: Ng2StateDeclaration = {
  name: 'protected.reports.dispatched-item',
  url: '/dispatched-item',
  component: DispatchedItemComponent,
  data: { 
    pageTitle: 'Dispatched Item Report', 
    requiresAuth: true,
  },
}
