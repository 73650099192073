<div class="mat-dialog-editor">
    <mat-toolbar>
        <h2 class="mat-title">QuickBooks Onboarding Help</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="column">
            <div fxLayout="row">
                <span *ngIf="this.isAccountMappingsMissing">
                    The QuickBooks accounting integration assumes your billable accounts will be created in QuickBooks 
                    as products/services, and assigned to a category named <strong>Account Mappings</strong>.  We could
                    not detect this category in QuickBooks.
                    <br><br>
                    <strong>Would you like us to create this category for you now?</strong>
                </span>
                <span *ngIf="this.isAccountMappingsEmpty">
                    We have detected that the <strong>Account Mappings</strong> category exists in QuickBooks, but no accounts
                    have been assigned to it yet.  To do this, from the QuickBooks dashboard click on <strong>Sales > Products 
                    and Services</strong>, select the accounts you wish to use, click on <strong>Assign category</strong> and select 
                    <strong>Account Mappings</strong>.
                </span>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.actioning" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button mat-dialog-close>{{ this.getCancelButtonLabel() }}</button>
        <button mat-raised-button color="accent" *ngIf="this.actionExists" (click)="this.createQuickbooksAccountMappings()" [disabled]="this.actioning">Yes</button>
    </mat-dialog-actions>
</div>