<div #punchButton 
    class="punch-button" 
    [class.punched-in]="this.isActive"
    (click)="this.onButtonClick($event)">
    <a href="#">
        <div #outer class="outer">
            <span #loader class="loader" [fxShow]="this.isPunching"></span>
            <div #inner class="inner"></div>
        </div>
    </a>
</div>

<div #punchButtonListWrapper id="punch-button-list-wrapper" class="punch-button-list-wrapper" [fxShow]="this.selectorShown">
    <div fxLayout="column" [fxHide]="this.selection.project || this.skipProjectSelection" >
        <mat-toolbar color="primary">
            <!-- <div class="md-toolbar-tools> -->
                <span fxFlex></span>
                <button mat-icon-button (click)="this.showSelector(false)">
                    <mat-icon>close</mat-icon>
                </button>
            <!-- </div> -->
        </mat-toolbar>
        <div fxFlex>
            <div class="list-item search-item">
                <input class="form-control input-sm input-search"
                    type="text"
                    placeholder="Search"
                    [(ngModel)]="this.projectSearchText"
                    (ngModelChange) = "this.onProjectSearch()"/>
            </div>
            <mat-list class="list" dense>
                <mat-divider></mat-divider>
                <mat-list-item class="list-item clickable"
                    *ngFor="let project of this.projects"
                    (click)="this.selectProject(project)">
                    <p [class.live]="this.isLiveProject(project)">{{ project.name }}</p>
                    <mat-icon color="secondary" aria-label="project">chevron_right</mat-icon>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
        <div fxFlex="nogrow" class="end-item">
            <button mat-raised-button color="warn"
                *ngIf="this.isActive"
                (click)="this.selectTask(this.endTask)">
                {{ this.endTask.name }}
            </button>
        </div>
    </div>
    <div fxLayout="column" fxFlexFill [fxShow]="this.selection.project || this.skipProjectSelection">
        <mat-toolbar color="primary">
                <button mat-icon-button (click)="this.selectProject()">
                    <mat-icon [fxHide]="this.skipProjectSelection">arrow_back</mat-icon>
                </button>
                <h2>
                    {{this.selection.project?.name}}
                </h2>
                <span fxFlex></span>                
                <button mat-icon-button (click)="this.showSelector(false)">
                    <mat-icon>close</mat-icon>
                </button>
        </mat-toolbar>
        <div fxFlex class="list-item-container">
            <div class="list-item search-item"
                *ngIf="this.tasks.length > 10" >
                <input class="form-control input-sm input-search"
                        type="text"
                        placeholder="Search"
                        [(ngModel)]="this.taskSearch" />                
            </div>
            
            <mat-list>
                <mat-divider></mat-divider>
                <!-- *ngFor="task in this.tasks | filter:{'name': this.taskSearch} | orderBy:task.name" -->
                <mat-list-item class="list-item clickable"
                    *ngFor="let task of this.tasks"
                    (click)="this.selectTask(task)">
                    <p [class.live]="this.isLiveTask(task)">{{task.name}}</p>
                    <span fxFlex></span>
                    <mat-icon class="md-secondary" aria-label="project">chevron_right</mat-icon>
                    <mat-divider></mat-divider>
                </mat-list-item>

            </mat-list>
        </div>
        <div fxFlex="nogrow" class="end-item">
            <button mat-raised-button
                color="warn"
                *ngIf="this.isActive"
                (click)="this.selectTask(this.endTask)">
                {{ this.endTask.name }}
            </button>
        </div>
    </div>
</div>
