import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, Inject, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VariantPropertyDefinition } from '@beaconlite/models';
import { VariantDefinition } from '@beaconlite/models/contracts/VariantDefinition.interface';
import { VariantOption } from '@beaconlite/models/VariantOption.model';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { orderBy } from "@beaconlite/utilities/Sort.utility";

interface PropEditorData {
    original: VariantPropertyDefinition,
    itemDefinition: VariantDefinition,
    onPropUpdate: (prop: VariantPropertyDefinition) => Promise<void>;
    onPropRemove: (prop: VariantPropertyDefinition) => Promise<void>;
}

@Injectable({
    providedIn: 'root'
})
export class VariantPropertyDefinitionEditorService {
    constructor(protected dialog: MatDialog) {}

    async open(data: PropEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            data
          }

        return this.dialog.open(VariantPropertyDefinitionEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}

@Component({
    selector: 'app-variant-prop-editor',
    templateUrl: './variant-prop-editor.component.html',
    styleUrls: ['./variant-prop-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VariantPropertyDefinitionEditorComponent implements OnInit, DoCheck {

    @ViewChild('editorForm') editorForm: NgForm;

    original = this.data.original;
    itemDefinition = this.data.itemDefinition;
    onUpdate = this.data.onPropUpdate;
    onRemove = this.data.onPropRemove;

    loading = false;
    prop: VariantPropertyDefinition;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: PropEditorData,
        public dialogRef: MatDialogRef<VariantPropertyDefinitionEditorComponent>,
        protected snackbarNotification: SnackbarNotificationService,
        protected dialogNotification: DialogNotificationService,
    ){}

    async ngOnInit(): Promise<void>
    {
        this.prop = this.original.copy();
        this._sortItems();
    }

    ngDoCheck(): void
    {
        this._sortItems();
    }

    onAddOption()
    {
        this.prop.addOption();
        this._setOptionOrdinals();
    }

    async onRemoveOption(option: VariantOption): Promise<void>
    {
        if (! await this.dialogNotification.removeConfirm()) return;
        
        this.prop.removeOption(option);
        this._setOptionOrdinals();
    }

    formatField(field: string): string
    {
        return field.replace('_', ' ');
    }

    onOptionDropped(event: CdkDragDrop<VariantOption[]>)
    {
        moveItemInArray(this.prop.options, event.previousIndex, event.currentIndex);
        this._setOptionOrdinals();
    }

    async remove(): Promise<void>
    {
        if (! await this.dialogNotification.removeConfirm()) return;

        this.loading = true;
        
        try
        {
            await this.onRemove(this.prop);
            this.snackbarNotification.saved();
            this.dialogRef.close();
        }
        finally
        {
            this.loading = false;
        }
    }

    async save(): Promise<void>
    {
        if (this.editorForm.invalid) return;

        this.loading = true;

        try
        {
            await this.onUpdate(this.prop);
            this.snackbarNotification.saved();
            this.dialogRef.close();
        }
        finally
        {
            this.loading = false;
        }
    }

    protected _sortItems(): void
    {
        this.prop.options?.sort( orderBy( 'ordinal' ) );
    }

    protected _setOptionOrdinals(): void
    {
        this.prop.options.forEach(
            (option, index) => option.ordinal = index
        );
    }
}
