import { Account } from "./Account.model";
import { BaseModel } from "./Base.model";
import { Person } from "./contracts/Person.interface";
import { DispatchDetails } from "./contracts/DispatchDetails.interface";
import { Discardable } from "./mixins/Discardable.mixin";
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";
import { DispatchRegion } from "./DispatchRegion.model";

const MixinBase = Discardable( BaseModel )

export class Employee extends MixinBase {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() department_id?: string = null;
    @dto() dispatch_region_id?: string = null;
    @dto() position?: string = null;
    @dto() note?: string = null;
    @dto() is_booked?: boolean = false;
    @dto() time_booked?: number = 0;

    @dto(DispatchRegion) dispatch_region?: DispatchRegion = null;

    @dto() dispatch_details: DispatchDetails =  {
        last_viewed_at: null
    }
    
    @dto() person: Person =  {
        first_name: null,
        last_name: null,
        title: null,
        email: null,
        phone: null,
    }

    @dto(Account) account: Account =  null;

    get fullname(): string 
    {
        const person = this.person;

        return person.last_name
            ? `${person.first_name} ${person.last_name}`
            : person.first_name;
    }

    get formattedAddress(): string 
    {
        const person = this.person;
        const addressFields = [person.address, person.city, person.region, person.postal_code, person.country];
        return addressFields.filter(field => field).join(', ');
    }

    static async get(id: string): Promise<Employee> 
    {
        const response = await AppInjector.get(RouteMap).getEmployee(id);
        return new Employee(response.data());
    }

    async save(): Promise<Employee> 
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateEmployee(this.id, this.flush())
            : await this.routeMap.createEmployee(this.flush())
        
        return this.map(response.data());
    }

    async discard(): Promise<void> 
    {
        const response = await this.routeMap.discardEmployee(this.id);
        // return this.map(response.data());
    }

    async recover(): Promise<Employee> 
    {
        const response = await this.routeMap.recoverEmployee(this.id);
        return this.map(response.data());
    }
}
