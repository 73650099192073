<mat-card class="large-card quickbooks-settings settings">
    <mat-card-title>
        <span>{{ this.integration.name }} Settings</span>
        <span fxFlex></span>
        <mat-slide-toggle class="settings__title-switch"
            [(ngModel)]="this.integration.enabled"
            (ngModelChange)="this.onSave()"
            [disabled]="!this.integration.linked_at">
        </mat-slide-toggle>
    </mat-card-title>

    <mat-divider *ngIf="!this.integration.linked_at"></mat-divider>

    <div class="settings__body">
        <div fxLayout="row" layoutPadding>
            <span class="mat-caption" *ngIf="!this.integration.linked_at" layoutPadding>QuickBooks is not authorized</span>
            <span class="mat-caption" *ngIf="this.integration.linked_at" layoutPadding>QuickBooks authorized on {{ this.integration.linked_at | dateFilter:'dateMedium' }}</span>
        </div>
        <div fxLayout="row" *ngIf="!this.integration.linked_at" layoutPadding>
            <button mat-raised-button (click)="this.authorizeQuickbooks()">
                Authorize QuickBooks
            </button>
        </div>
    </div>

    <mat-divider *ngIf="this.integration.linked_at"></mat-divider>

    <div class="settings__body" *ngIf="this.integration.linked_at"  layoutPadding cdkScrollable>
        <div fxLayout="row" layoutPadding>
            <button mat-raised-button
                (click)="this.syncClientsWithQuickbooks()" 
                [disabled]="!this.integration.enabled">
                Sync Clients
            </button>
            <mat-progress-spinner [fxShow]="this.syncingClients" mode="indeterminate" diameter="36"></mat-progress-spinner>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="settings__body" *ngIf="this.integration.linked_at" layoutPadding cdkScrollable>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <span class="mat-title" layoutPadding>Accounts</span>
            <button mat-icon-button 
                *ngIf="!this.loadingQuickbooksAccounts && (this.isAccountMappingsMissing || this.isAccountMappingsEmpty)"
                (click)="this.onOpenOnboardingHelper()">
                <mat-icon color="primary">help_outline</mat-icon>
            </button>
        </div>
        <div fxLayout="row" [fxShow]="this.loadingQuickbooksAccounts">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div fxLayout="row" [fxShow]="!this.loadingQuickbooksAccounts && !this.isAccountMappingsMissing && !this.isAccountMappingsEmpty">
            <mat-chip-list class="readonly" 
                fxFlex 
                [fxShow]="this.accounts.length"
                [removable]="false">
                <mat-chip class="account-chip" *ngFor="let account of this.accounts"  disableRipple>
                    {{ account.value.name }}
                </mat-chip>
            </mat-chip-list>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" [fxHide]="this.accounts.length">No accounts have been specified</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="settings__body" 
        fxLayout="column" 
        layoutPadding 
        cdkScrollable
        *ngIf="this.integration.linked_at">
        <span class="mat-title" layoutPadding>Taxes</span>
        <div fxLayout="row" [fxShow]="this.loadingQuickbooksTaxes">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <mat-table class="mat-selectable-table"
            fxFlex="100"
            [dataSource]="this.taxes"
            [fxShow]="!this.loadingQuickbooksTaxes">
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>Group</mat-header-cell>
                <mat-cell *matCellDef="let tax">{{ tax.tag }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="external-name">
                <mat-header-cell *matHeaderCellDef>External Name</mat-header-cell>
                <mat-cell *matCellDef="let tax">{{ this.settings.taxes[tax.tag]?.name }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="taxTableColumns"></mat-header-row>
            <mat-row *matRowDef="let tax; columns: taxTableColumns" (click)="this.onEditTax(tax)"></mat-row>
        </mat-table>
    </div>

    <ng-container *ngFor="let branch of this.branches">
        <mat-toolbar class="settings__lead--top" *ngIf="this.integration.linked_at">
            <mat-toolbar-row fxLayoutAlign="space-between center">
                <span class="mat-headline">{{ branch.name }}</span>
                <button mat-icon-button 
                    [disabled]="this.loadingQuickbooksAccounts || this.isAccountMappingsMissing || this.isAccountMappingsEmpty"
                    (click)="this.onEditBranchDefaults(branch)">
                    <mat-icon color="primary">edit</mat-icon>
                </button>
            </mat-toolbar-row>
        
            <mat-toolbar-row *ngIf="this.integration.linked_at" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex>
                    <span class="mat-subheading-1">Default Account</span>
                    <p class="mat-body-1">{{ this.getBranchAccountName(branch, 'default') }}</p>
                </div>
                <div fxLayout="column" fxFlex>
                    <span class="mat-subheading-1">Rentals Account</span>
                    <p class="mat-body-1">{{ this.getBranchAccountName(branch, 'rentals') }}</p>
                </div>
                <div fxLayout="column" fxFlex>
                    <span class="mat-subheading-1">Services Account</span>
                    <p class="mat-body-1">{{ this.getBranchAccountName(branch, 'services') }}</p>
                </div>
                <div fxLayout="column" fxFlex>
                    <span class="mat-subheading-1">Charges Account</span>
                    <p class="mat-body-1">{{ this.getBranchAccountName(branch, 'charges') }}</p>
                </div>
            </mat-toolbar-row>
    </mat-toolbar>

        <div class="settings__body"
            fxLayout="column" 
            *ngIf="this.integration.linked_at"
            layoutPadding
            cdkScrollable>
            <span class="mat-title" layoutPadding>Per Item Overrides</span>

            <mat-table class="mat-selectable-table"
                fxFlex="100"
                [fxShow]="this.getBranchOverrides(branch)?.length"
                [dataSource]="this.getBranchOverrides(branch)">
                <ng-container matColumnDef="item">
                    <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                    <mat-cell *matCellDef="let override">{{ override.value.name }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account">
                    <mat-header-cell *matHeaderCellDef>Account</mat-header-cell>
                    <mat-cell *matCellDef="let override">
                        {{ this.settings.accounts[override.value.account]?.name }}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="overridesTableColumns"></mat-header-row>
                <mat-row *matRowDef="let override; columns: overridesTableColumns" (click)="this.onEditBranchOverride(branch, override)"></mat-row>
            </mat-table>
            <div fxLayout="row" fxLayoutAlign="end end" layoutPadding>
                <button mat-mini-fab
                    (click)="this.onEditBranchOverride(branch)"
                    [disabled]="this.loadingQuickbooksAccounts ||
                                this.isAccountMappingsMissing ||
                                this.isAccountMappingsEmpty ||
                                !this.settings.branches[branch.id]?.default_account">
                    <mat-icon>add</mat-icon>
                </button>
            </div>

        </div>
    </ng-container>

</mat-card>
<div class="mat-caption settings__version">v{{ this.appVersion }}</div>