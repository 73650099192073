import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BranchSettings } from '../interfaces/branch-settings.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntegrationBranchDefaultsEditorData } from './integration-branch-defaults-editor-data.interface';
import { Helpers as $helpers } from '@beaconlite/services/helpers.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { Sage50BranchSettings, Sage50MappedAccount } from '../sage50-settings/sage50-inferaces/sage50-interfaces';
import { MappedAccount } from '../interfaces/mapped-account.interface';

@Component({
  selector: 'app-integration-branch-defaults-editor',
  templateUrl: './integration-branch-defaults-editor.component.html',
})
export class IntegrationBranchDefaultsEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  branch = this.data.branch;
  accounts = this.data.accounts;
  original = this.data.original;
  onUpdate = this.data.onUpdate;

  saving = false;
  settings: BranchSettings | Sage50BranchSettings = {
    'default_account': null, 
    'rentals': {
      'default_account': null
    }, 
    'services': {
      'default_account': null
    }, 
    'charges': {
      'default_account': null
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: IntegrationBranchDefaultsEditorData,
    public dialogRef: MatDialogRef<IntegrationBranchDefaultsEditorComponent>,
  ) { }

  ngOnInit(): void 
  {
    if (!!this.original)
    {
      this.settings = $helpers.deepCopy(this.original);
    }

    this._sortItems();
  }

  applyDefaultAccounts(): void
  {
    if ( !this.settings.rentals.default_account )
    {
      this.settings.rentals.default_account = this.settings.default_account;
    }

    if ( !this.settings.services.default_account )
    {
      this.settings.services.default_account = this.settings.default_account;
    }

    if ( !this.settings.charges.default_account )
    {
      this.settings.charges.default_account = this.settings.default_account;
    }
  }

  getAccountString(account: MappedAccount | Sage50MappedAccount): string
  {
    if (this._isSage50MappedAccount(account)) {
      account as Sage50MappedAccount;
      return account.value.description 
        ? account.value.id + ' - ' + account.value.description 
        : account.value.id;
    } else {
      account as MappedAccount;
      return account.value;
    }
  }

   protected _isSage50MappedAccount(account: MappedAccount | Sage50MappedAccount): account is Sage50MappedAccount {
    return (account as Sage50MappedAccount).value.id !== undefined;
  }

  async onSave(): Promise<void|boolean>
  { 
    if (this.editorForm.invalid) return false;

    this.applyDefaultAccounts();

    this.saving = true;

    try
    {
      await this.onUpdate(this.settings);
      this.dialogRef.close();
    }
    finally
    {
      this.saving = false;
    }
  }

  protected _sortItems(): void
  {
    this.accounts.sort(orderBy('value.id'));
  }
}
