<mat-card class="large-card {{ this.headerClass }} dispatch" fxLayout="column">
    <mat-card-title fxLayout="row" fxLayoutAlign=" center">
        <div fxLayout="column">
            <span class="mat-headline">{{ workOrder.client?.name }}</span>
            <span class="mat-caption">{{ displayType }} | {{ this.dispatch.formatted_serial_id }}</span>
        </div>

        <span fxFlex></span>

        <div class="toolbar-status" fxLayout="column">
            <span class="toolbar-status__current capitalize">{{ this.dispatch.state_name }}</span>
        </div>
        <mat-icon *ngIf="this.dispatch.locked">lock_outline</mat-icon>

        <button mat-icon-button [matMenuTriggerFor]="dispatchMenu" *ngIf="this.canEdit">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #dispatchMenu="matMenu">
            <button mat-menu-item (click)="this.onEdit()">
                <mat-icon>edit</mat-icon>
                Edit
            </button>

            <button mat-menu-item (click)="this.onSend()" *ngIf="this.dispatch.dispatched_at">
                <mat-icon>send</mat-icon>
                {{ this.dispatch.sent_at ? 'Resend' : 'Send' }}
            </button>

            <button mat-menu-item 
                *ngIf="this.dispatch.canMarkSent() && this.dispatch.dispatched_at"
                (click)="this.onMarkSent()">
                <mat-icon>arrow_circle_right</mat-icon>
                Mark Sent
            </button>

            <button mat-menu-item 
                *ngIf="this.dispatch.sent_at"
                (click)="this.onShowEmailHistory()">
                <mat-icon>dynamic_feed</mat-icon>
                Email History
            </button>

            <button mat-menu-item (click)="this.onPrint()" *ngIf="this.dispatch.dispatched_at" [disabled]="sending">
                <mat-icon>print</mat-icon>
                Print
            </button>

            <button mat-menu-item (click)="this.onDownload()" *ngIf="this.dispatch.dispatched_at">
                <mat-icon>cloud_download</mat-icon>
                Download as PDF
            </button>

            <button mat-menu-item (click)="this.onNotify()" *ngIf="this.dispatch.dispatched_at && !this.dispatch.completed_at && smsEnabled">
                <mat-icon>phonelink_ring</mat-icon>
                SMS Notifications
            </button>

            <button mat-menu-item (click)="this.onViewSlip()" *ngIf="this.dispatch.dispatched_at">
                <mat-icon>confirmation_number</mat-icon>
                View Slip
            </button>

            <button mat-menu-item (click)="this.onDoDispatch()" *ngIf="!this.dispatch.dispatched_at && !this.hasIssueRestraints()" [disabled]="!this.dispatch.services?.length && !this.dispatch.rental_requests?.length">
                <mat-icon>assignment</mat-icon>
                Dispatch
            </button>

            <button mat-menu-item (click)="this.onCancel()" *ngIf="this.dispatch.dispatched_at && !this.dispatch.completed_at">
                <mat-icon>assignment_return</mat-icon>
                Un-dispatch
            </button>

            <button mat-menu-item (click)="this.onComplete()" *ngIf="this.dispatch.dispatched_at && !this.dispatch.completed_at">
                <mat-icon>assignment_turned_in</mat-icon>
                Complete
            </button>

            <button mat-menu-item (click)="this.onUncomplete()" *ngIf="this.dispatch.completed_at && !this.dispatch.invoiced">
                <mat-icon>assignment_return</mat-icon>
                Un-complete
            </button>

            <button mat-menu-item (click)="this.onAddCharge()" [disabled]="!this.dispatch.canAddCharges()">
                <mat-icon>attach_money</mat-icon>
                Additional Charge
            </button>

            <button mat-menu-item (click)="this.onAddNote()" [disabled]="!this.dispatch.canAddNotes()">
                <mat-icon>note_add</mat-icon>
                Add Note
            </button>

            <button mat-menu-item (click)="this.onAddDocument()" [disabled]="!this.dispatch.canAddNotes()">
                <mat-icon>attach_file</mat-icon>
                Attach Document
            </button>

            <button mat-menu-item (click)="this.remove()" *ngIf="!this.dispatch.locked">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
        </mat-menu>
    </mat-card-title>

    <!-- Dispatch properties -->

    <div class="large-card__lead">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Delivery Time</span>
                <p class="mat-subhead">{{ this.dispatch.scheduled_at | dateFilter:'dateTimeMedium' }}</p>
            </div>

            <div fxLayout="column" fxFlex="25" *ngIf="!this.dispatch.scheduled_end_at" layoutPadding></div>
            <div fxLayout="column" fxFlex="25" *ngIf="this.dispatch.scheduled_end_at" layoutPadding>
                <span class="mat-caption">End Time</span>
                <p class="mat-subhead">{{ this.dispatch.scheduled_end_at | dateFilter:'dateTimeMedium' }}</p>
            </div>    
            
            <div fxLayout="column" fxFlex="25" *ngIf="!this.dispatch.travel_time" layoutPadding></div>
            <div fxLayout="column" fxFlex="25" *ngIf="this.dispatch.travel_time" layoutPadding>
                <span class="mat-caption">Travel Time Required</span>
                <p class="mat-subhead">{{ this.dispatch.travel_time | timeFilter:'minutes' }}</p>
            </div>
       
            <div fxLayout="column" fxFlex="25" [fxShow]="this.dispatch.truck_identifier" layoutPadding>
                <span class="mat-caption">Vehicle</span>
                <p class="mat-body-1">{{ this.dispatch.truck_identifier }}</p>
            </div>
        </div>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex layoutPadding>
                <span class="mat-caption">Location</span>
                <p class="mat-subhead">{{ this.dispatch.location }}</p>
            </div>
            <div fxLayout="column" fxFlex="50" *ngIf="this.dispatch.dispatch_region" layoutPadding>
                <span class="mat-caption">Dispatch Region</span>
                <p class="mat-subhead">{{ this.dispatch.dispatch_region.name }}</p>
            </div>
        </div>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50" *ngIf="this.dispatch.site_contact" layoutPadding>
                <span class="mat-caption">Site Contact</span>
                <p class="mat-subhead">{{ this.dispatch.site_contact }}</p>
            </div>
            <div fxLayout="column" fxFlex="50" *ngIf="this.shouldShowSentInfo()" layoutPadding>
                <span class="mat-caption">{{this.getSentInfoLabel()}}</span>
                <p class="mat-subhead">{{ this.getSentName() }} {{ this.getSentOrMarkedSentAt() | dateFilter:'dateTimeMedium':'--' }}</p>
            </div>
        </div>

        <mat-divider [fxShow]="shouldShowDepartment"></mat-divider>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50" *ngIf="shouldShowDepartment" layoutPadding>
                <span class="mat-caption">Departments</span>
                <p class="mat-body-1">{{ displayDepartments() }}</p>
            </div>
            <div fxLayout="column" fxFlex="50" *ngIf="!isWorkOrderView()" layoutPadding>
                <span class="mat-caption">Work Order</span>
                <p class="mat-body-1">
                    <a uiSref="protected.work-orders.single" [uiParams]="{modelId: this.workOrder.id}" target="_blank" *ngIf="this.isAdmin">
                        {{ workOrder.formatted_serial_id }}
                    </a>
                    <span *ngIf="!this.isAdmin">
                        {{ workOrder.formatted_serial_id }}
                    </span>
                </p>
            </div>
        </div>

        <mat-divider [fxShow]="this.dispatch.dispatched_at"></mat-divider>

        <div fxLayout="row" *ngIf="this.dispatch.dispatched_at || this.dispatch.completed_at">
            <div fxLayout="column" [fxFlex]="50" [fxShow]="this.dispatch.dispatched_at" layoutPadding>
                <span class="mat-caption">Dispatched by</span>
                <p class="mat-body-1">{{ displayDispatcher() }} {{ this.dispatch.dispatched_at | dateFilter:'dateTimeMedium' }}</p>
            </div>
            <div fxLayout="column" [fxFlex]="50" [fxShow]="this.dispatch.completed_at" layoutPadding>
                <span class="mat-caption">Completed by</span>
                <p class="mat-body-1">{{ displayCompleter() }} {{ this.dispatch.completed_at | dateFilter:'dateTimeMedium' }}</p>
            </div>
        </div>

        <div class="employees-section" fxLayout="row" *ngIf="this.dispatch.hasEmployees()">
            <div fxLayout="column" fxFlex layoutPadding>
                <span class="mat-caption">Assigned to</span>
                <p class="mat-body-1" fxLayout="row">
                    <span *ngFor="let employee of this.dispatch.employees; last as isLast" fxLayout="row" fxLayoutAlign=" center">
                        <span class="employee-notification__status employee-notification__status--{{ employee.temps.notification_status.status_name }}"
                            *ngIf="!employee.dispatch_details.last_viewed_at && !!employee.temps.notification_status"
                            [matTooltip]="employee.temps.notification_status.status_name"
                            matTooltipPosition="above"
                            matTooltipClass="employee-notification-status-tooltip">
                            {{ employee.temps.notification_status.status_letter }}
                        </span>
                        <mat-icon *ngIf="employee.dispatch_details.last_viewed_at" 
                            color="primary"
                            matTooltip="Accessed"
                            matTooltipPosition="above">
                            visibility
                        </mat-icon>
                        {{ employee.fullname }}
                        <span *ngIf="!isLast">,&nbsp;&nbsp;</span>
                    </span>
                </p>     
            </div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <mat-card-content class="large-card__content" fxLayout="column">

        <!-- Rental Requests -->
        <div fxLayout="row" *ngIf="this.dispatch.hasRentalRequests()">
            <span class="mat-subheading-2">Rental Requests</span>
        </div>

        <mat-table class="mat-selectable-table"
            [dataSource]="this.dispatch.rental_requests" 
            [fxShow]="this.dispatch.hasRentalRequests()">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                <mat-cell *matCellDef="let request">{{ request.source.formatted_serial_id }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="item-count">
                <mat-header-cell *matHeaderCellDef>Item Count</mat-header-cell>
                <mat-cell *matCellDef="let request">
                    &nbsp;{{ request.num_unique_rentals }} items<br>({{ this.dispatch.getUnitCount(request) }} units)
                </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="start-end">
                <mat-header-cell *matHeaderCellDef>Start - End</mat-header-cell>
                <mat-cell *matCellDef="let request">
                    {{ request.dispatched_started_at | dateFilter:'dateMedium' }}<br>{{ request.dispatched_ended_at | dateFilter:'dateMedium':'TBD' }}
                </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="location">
                <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                <mat-cell *matCellDef="let request">{{ request.source.location }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reviewed">
                <mat-header-cell *matHeaderCellDef fxFlex="8" [fxShow]="this.dispatch.actioned_at">Reviewed</mat-header-cell>
                <mat-cell *matCellDef="let request" fxFlex="8" [fxShow]="this.dispatch.actioned_at">
                    <mat-icon [fxShow]="request.reviewed_at" color="primary">check_circle_outline</mat-icon>
                    <mat-icon [fxHide]="request.reviewed_at" color="primary">radio_button_unchecked</mat-icon>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="requestTableColumns"></mat-header-row>
            <mat-row *matRowDef="let request; columns: requestTableColumns" (click)="onReviewRequestValues(request)"></mat-row>
        </mat-table>

        <!-- Services -->
        <div fxLayout="row" *ngIf="this.dispatch.hasServices()">
            <span class="mat-subheading-2">Services</span>
        </div>

        <mat-table class="mat-selectable-table"
            [dataSource]="this.dispatch.services" 
            [fxShow]="this.dispatch.hasServices()">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                <mat-cell *matCellDef="let service">{{ service.source.formatted_serial_id }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                <mat-cell *matCellDef="let service">{{ service.source.name }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="start-end">
                <mat-header-cell *matHeaderCellDef>Start - End</mat-header-cell>
                <mat-cell *matCellDef="let service">
                    {{ service.dispatched_started_at | dateFilter:'dateTimeMedium' }}<br>{{ service.dispatched_ended_at | dateFilter:'dateTimeMedium':'TBD' }}
                </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="location">
                <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                <mat-cell *matCellDef="let service">{{ service.source.location }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reviewed">
                <mat-header-cell *matHeaderCellDef fxFlex="8" [fxShow]="this.dispatch.actioned_at">Reviewed</mat-header-cell>
                <mat-cell *matCellDef="let service" fxFlex="8" [fxShow]="this.dispatch.actioned_at">
                    <mat-icon [fxShow]="service.reviewed_at" color="primary">check_circle_outline</mat-icon>
                    <mat-icon [fxHide]="service.reviewed_at" color="primary">radio_button_unchecked</mat-icon>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="serviceTableColumns"></mat-header-row>
            <mat-row *matRowDef="let service; columns: serviceTableColumns" (click)="onReviewServiceValues(service)"></mat-row>
        </mat-table>

        <!-- Charges -->

        <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="this.dispatch.hasCharges()">
            <span class="mat-subheading-2">Charges</span>
        </div>
        <app-charges-table [fxShow]="this.dispatch.hasCharges()"
            [charges]="this.dispatch.charges"
            (chargeSelected)="onEditCharge($event)">
        </app-charges-table>

        <!-- Notes -->

        <div fxLayout="row" fxLayoutAlign="space-between start" [fxShow]="this.dispatch.hasNotes()">
            <span class="mat-subheading-2">Notes</span>
        </div>
        <app-notes-table [fxShow]="this.dispatch.hasNotes()"
            [notes]="this.dispatch.notes"
            (noteSelected)="onEditNote($event)">
        </app-notes-table>
        
        <!-- Documents -->

        <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="this.dispatch.hasDocuments()">
            <span class="mat-subheading-2">Documents</span>
        </div>
        
        <div class="doc-list-item" 
            fxFlex 
            fxLayout="row" 
            fxLayoutAlign=" center"
            fxLayoutGap="20px"
            *ngFor="let doc of this.dispatch.documents"
            (click)="this.onPreviewDocument(doc)">
            <div class="doc-list-item__avatar">
                <mat-icon>image</mat-icon>
            </div>
            <div fxLayout="column">
                <span>{{ doc.attachment?.name }}</span>
                <span>{{ doc.created_at | dateFilter:'dateMedium' }}</span>
                <span class="mat-caption">{{ doc.attachment?.description }}</span>
            </div>
            <span fxFlex></span>
            <div>
                <button mat-icon-button [matMenuTriggerFor]="documentMenu" (click)="$event.stopPropagation();" *ngIf="this.canEdit">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #documentMenu="matMenu">
                    <button mat-menu-item (click)="onEditDocument(doc)">
                        <mat-icon>edit</mat-icon>
                        Edit
                    </button>
                    <button mat-menu-item (click)="onUnlinkDocument(doc)">
                        <mat-icon>link_off</mat-icon>
                        Unlink
                    </button>
                    <button mat-menu-item (click)="onRemoveDocument(doc)">
                        <mat-icon>delete</mat-icon>
                        Delete
                    </button>
                </mat-menu>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="end center" [fxShow]="this.dispatch.hasDocuments()">
            <button mat-mini-fab (click)="onAddDocument()" color="secondary" *ngIf="this.canEdit">
                <mat-icon>attach_file</mat-icon>
            </button>
        </div>

        <div fxLayout="row">
            <mat-divider [fxShow]="this.dispatch.hasDocuments()"></mat-divider>
        </div>

        <!-- Signatures -->
        <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="this.dispatch.hasSignatures()">
            <span class="mat-subheading-2">Signatures</span>
        </div>

        <div class="signature-row" 
            fxLayout="row"
            *ngFor="let signature of dispatch.signatures"
            (click)="onCaptureSignature(signature)"
            matRipple>
            <div fxLayout="column" fxFlex="33">
                <img class="displayed-signature" [src]="signature.attachment.url">
            </div>
            <div fxFlex="33">
                <span class="mat-caption">Customer Name</span>
                <p class="mat-body-1">{{ signature.name }}</p>
            </div>
            <div fxFlex="33">
                <span class="mat-caption">Date Signed</span>
                <p class="mat-body-1">{{ signature.captured_at | dateFilter:'dateMedium' }}</p>
            </div>
        </div>

    </mat-card-content>
</mat-card>