import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChargeDefinition, Variant, VariantRental, VariantService } from '@beaconlite/models';
import { ChargeDefinitionCollection, VariantRentalCollection, VariantServiceCollection} from '@beaconlite/models/collections';
import { IntegrationBranchOverrideEditorData } from './integration-branch-override-editor-data.interface';
import { BranchOverride } from '../interfaces/branch-override.interface';
import { Helpers as $helpers } from '../../../../../services/helpers.service';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

type Item = ChargeDefinition | Variant;

// TODO BL-548: Relocate this to a quickbooks specific file
@Component({
  selector: 'app-integration-branch-override-editor',
  templateUrl: './integration-branch-override-editor.component.html',
  styleUrls: ['./integration-branch-override-editor.component.scss']
})
export class IntegrationBranchOverrideEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  original = this.data.original;
  branch = this.data.branch;
  accounts = this.data.accounts;
  existingOverrides = this.data.existingOverrides;
  onUpdate = this.data.onOverrideUpdate;
  onRemove = this.data.onOverrideRemove;

  saving = false;
  override: BranchOverride = {
    key: null, 
    value: { 
      name:null, 
      account:null
    }, 
    itemType: 'rentals'
  };

  searchText: string = '';
  selectedItem: Item;
  promisedItems: Promise<Item[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: IntegrationBranchOverrideEditorData,
    public dialogRef: MatDialogRef<IntegrationBranchOverrideEditorComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    if (!! this.original)
    {
      this.override = $helpers.deepCopy(this.original);
    }

    this._sortItems();
  }

  onItemTypeChanged(): void
  {
    this.promisedItems = null;
    this.selectedItem = null;
    this.searchText = null;
  }

  onQueryItems(): void
  {
    switch(this.override.itemType)
    {
        case 'charges':
          this.promisedItems = this._queryCharges()
          break;
        case 'rentals':
          this.promisedItems = this._queryRentals()
          break;
        case 'services':
          this.promisedItems = this._queryServices()
          break;
    }
  }

  protected async _queryCharges(): Promise<ChargeDefinition[]>
  {
    return (new ChargeDefinitionCollection()).all({
      order:  'asc', 
      label:  this.searchText, 
    });
  }

  protected async _queryRentals(): Promise<VariantRental[]>
  {
    return (new VariantRentalCollection()).all({
      order:  'asc', 
      name:   this.searchText, 
    })
  }

  protected async _queryServices(): Promise<VariantService[]>
  {
    return (new VariantServiceCollection()).all({
      order:  'asc', 
      name:   this.searchText, 
    })
  }

  getAccountString(account): string
  {
    return account.value.description 
      ? account.value.id + ' - ' + account.value.description 
      : account.value.id;
  }

  onItemSelected(item: Item)
  {
    this.selectedItem = item;
    // TODO: Jira BL-539. Only pass in overrides for current branch.
    const overridesToSearch =  this.existingOverrides[this.branch.id][this.override.itemType].values;
    if (Object.keys(overridesToSearch).length)
    {
      for (const override in overridesToSearch)
      {
        if (overridesToSearch[override].key === this.selectedItem.id)
        {
          this.editorForm.controls.searchTextField.setErrors({ alreadyPresent: true });
          break;
        }
      };
    }

    this.override.value.name = item.getDisplayName();
  }

  displaySelectedItem(item: Item): string
  { 
    return item.getDisplayName();
  }

  async save(): Promise<void|boolean>
  {
      if (this.editorForm.invalid) return false;
      
      this.saving = true;

      if (! this.original)
      {
        this.override.key = this.selectedItem.id
      }
      try
      {
        await this.onUpdate(this.override);
        this.dialogRef.close();
      }
      finally
      {
        this.saving = false;
      }
  }

  async remove(): Promise<void>
  {
    this.saving = true;

    try
    {
      if (await this.dialogNotification.removeConfirm())
      {
        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.saving = false;
    }
  }

  protected _sortItems(): void
  {
    this.accounts.sort(orderBy('value.name'));
  }
}
