import * as moment from 'moment';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RentalReturn, Rental, RentalGroup } from '@beaconlite/models';
import { ReturnSheetEditorData } from './return-sheet-editor-data.interface';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';

interface SheetReturn extends SheetItem {
  rental: Rental;
  return: RentalReturn;
  lost: RentalReturn;
  invalid: boolean;
  references: {  
    rental: Rental;
    return: RentalReturn;
    lost: RentalReturn;
  };
}

interface SheetGroup extends SheetItem {
  rentalGroup: RentalGroup;
  sheetReturns: SheetReturn[];
}

interface SheetItem {
  sheetReturns?: SheetReturn[];
}

type VariantRentalId = string;

export type FlattenedReturns = Map<VariantRentalId, FlattenedReturnValue>;

interface FlattenedReturnValue {
    sheetReturn: SheetReturn[];
    quantity_rented: number;
    quantity_remaining: number;
    quantity_returned: number;
    quantity_lost: number;
    invalid: boolean;
}

@Component({
  selector: 'app-return-sheet-editor',
  templateUrl: './return-sheet-editor.component.html',
})
export class ReturnSheetEditorComponent implements OnInit {

  original = this.data.original;
  request = this.data.originalRequest;
  workOrder = this.data.workOrder;
  onUpdate = this.data.onSheetUpdate;
  onRemove = this.data.onSheetRemove;

  loading = false;

  return_date: Date;
  minimum_date: Date;
  maximum_date: Date
  private _sheetReturns: SheetReturn[] = [];
  sheetItems: SheetItem[] = [];
  isFlattenedView = false;
  flattenedReturns: FlattenedReturns = null;

  returnTableColumns = ['item', 'error', 'detail', 'rented', 'remaining', 'returned', 'lost'];
  groupReturnTableColumns = ['item', 'error', 'detail', 'rented', 'remaining', 'returned', 'lost'];

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ReturnSheetEditorData,
    public dialogRef: MatDialogRef<ReturnSheetEditorComponent>,
    protected dialogNotifications: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    let minimum_date = this.workOrder.minimum_allowable_date || this.request.started_at;
    this.minimum_date = moment.unix(minimum_date).toDate();

    if (!! this.request.ended_at)
    {
      this.maximum_date = this.request._dates.ended_at.date;
    }

    if (!! this.original)
    {

      // Init date selected
      if (!! this.original.returned_at)
      {
        this.return_date = moment.unix(this.original.returned_at).startOf('day').toDate();
      }
    }

    this.setSheet();
  }

  setSheet(): void {
    this.setSheetItems();
    this.setSheetReturns();
    this.setSheetFlattenedReturns();
  }

  setSheetReturns(): void {
    const sheetReturnsFromGroups = this.sheetItems.filter(returnItem => returnItem?.sheetReturns?.length)
      .reduce((sheetReturns: SheetReturn[], returnItem) => 
        sheetReturns = sheetReturns.concat(returnItem.sheetReturns), []);
    const ungroupedSheetReturns = this.sheetItems.filter(returnItem => !returnItem?.sheetReturns?.length);

    this._sheetReturns = [].concat(ungroupedSheetReturns).concat(sheetReturnsFromGroups);
  }

  setSheetItems(): void {
    const timestamp = this.getReturnDateTimestamp();
    const sheetReturns = this.request.ungrouped_rentals.map((rental) => this.buildSheetReturn(rental, timestamp));
    const sheetGroups = this.request.rental_groups.map(group => this.buildSheetReturnGroup(group, timestamp));

    this.sheetItems = [].concat(sheetReturns).concat(sheetGroups);
  }

  setSheetFlattenedReturns(): void {
    this.flattenedReturns = new Map();

    for (let i = 0; i < this._sheetReturns.length; i+=1) {

      const curReturn = this._sheetReturns[i];

      let definitionId = curReturn.rental.variant_rental_id;
      let flattenedReturn = this.flattenedReturns.get(definitionId);

      if (flattenedReturn === undefined) {
          flattenedReturn = {
            sheetReturn: [],
              quantity_rented: 0,
              quantity_remaining: 0,
              quantity_returned: 0,
              quantity_lost: 0,
              invalid: false,
          };

          this.flattenedReturns.set(definitionId, flattenedReturn);
      }

      flattenedReturn.quantity_rented += curReturn.rental.quantity;
      flattenedReturn.quantity_remaining += curReturn.rental.quantity_remaining;
      flattenedReturn.quantity_returned += curReturn.return.quantity;
      flattenedReturn.quantity_lost += curReturn.lost.quantity;
      flattenedReturn.invalid = flattenedReturn.invalid  && curReturn.invalid
      
      flattenedReturn.sheetReturn.push(curReturn);
    }

    for (let key of this.flattenedReturns.keys()) {
      this.validateFlatSheetItem(this.flattenedReturns.get(key));
    }
  }

  getReturnDateTimestamp(): number | null
  {
      if (! this.return_date) return null;

      return Math.floor( this.return_date.getTime() / 1000 );
  }

  buildSheetReturnGroup(rentalGroup: RentalGroup, timestamp: number): SheetGroup {
    const sheetGroup: SheetGroup = {
      rentalGroup: rentalGroup,
      sheetReturns: []
    }

    for (let j = 0; j <rentalGroup.rentals.length; j += 1 ) {
      const relatedRental = rentalGroup.rentals[j];
      sheetGroup.sheetReturns.push(this.buildSheetReturn(relatedRental, timestamp));
    }

    return sheetGroup;
  }

  buildSheetReturn(rental: Rental, timestamp: number): SheetReturn
  {
      const references = {
          rental: rental,
          return: null,
          lost: null,
      };

      const aRental = rental.copy();
      const aReturn = new RentalReturn({ returned_at: timestamp });
      const lost = new RentalReturn({ returned_at: timestamp, lost: true });

      let sheetItem: SheetReturn = {
          rental: aRental,
          return: aReturn,
          lost: lost,
          references: references,
          invalid: false
      };

      // Verify if there are existing RentalReturns for the specified timestamp and track/copy them
      for (let i = 0; i < rental.returns.length; i++)
      {
          let rental_return = rental.returns[i];

          // If RentalReturn exists for the specified time, make a working copy and track the source object
          if (rental_return.returned_at == timestamp)
          {
              if (rental_return.lost === true)
              {
                  sheetItem.lost = rental_return.copy();
                  sheetItem.references.lost = rental_return;
              }
              else
              {
                  sheetItem.return = rental_return.copy();
                  sheetItem.references.return = rental_return;
              }
          }
      }

      // Calculate the Rental's adjusted quantity remaining for the return date
      sheetItem.rental.quantity_remaining = this.calculateAdjustedRemainingQuantity(sheetItem.rental, timestamp);

      // Track tuple validity
      this.validateSheetItem(sheetItem);

      return sheetItem;
  }

  calculateAdjustedRemainingQuantity(rental: Rental, timestamp: number): number
  {
      const remaining = rental.returns
        .filter((returnItem: RentalReturn) => !timestamp || returnItem.returned_at < timestamp)
        .reduce((acc, returnItem: RentalReturn) => acc + returnItem.quantity, 0);

      return Math.max(0, rental.quantity - remaining);

  }

  validateSheetItem(sheetItem: SheetReturn): void
  {
      const row_total = sheetItem.return.quantity + sheetItem.lost.quantity;
      sheetItem.invalid = row_total > sheetItem.rental.quantity_remaining || sheetItem.return.quantity < 0 || sheetItem.lost.quantity < 0;
  }

  onFlattenedViewToggledHandler($event: boolean): void {
    this.isFlattenedView = $event;
    if (this.isFlattenedView == false) { 
      this.distributeFlattenedQuantities();
    } else {
      this.setSheetFlattenedReturns();
    }
  }

  protected distributeFlattenedQuantities(): void {
    for (let key of this.flattenedReturns.keys()) {
    
      let flatReturn = this.flattenedReturns.get(key);

      let availableQuantReturned = flatReturn.quantity_returned;
      let availableQuantLost = flatReturn.quantity_lost;

      // Go through each sheetReturn and apply values. Does not apply in any particular order.
      for (let i=0; i<flatReturn.sheetReturn.length; i+=1) {
        let sheetReturn = flatReturn.sheetReturn[i];
        let rentalQuantityRemaining =  sheetReturn.rental.quantity_remaining;

        let returnQuantReturned = Math.min(availableQuantReturned, rentalQuantityRemaining);
        sheetReturn.return.quantity = returnQuantReturned;
        rentalQuantityRemaining -= returnQuantReturned;
        availableQuantReturned -= returnQuantReturned;

        let returnQuantLost = Math.min(availableQuantLost, rentalQuantityRemaining);
        sheetReturn.lost.quantity = returnQuantLost;
        availableQuantLost -= returnQuantLost;

        this.validateSheetItem(sheetReturn);
      }
    }
  }

  validateFlatSheetItem(flattenedReturnValue: FlattenedReturnValue) {
    const total = flattenedReturnValue.quantity_returned + flattenedReturnValue.quantity_lost;
    flattenedReturnValue.invalid = total > flattenedReturnValue.quantity_remaining || flattenedReturnValue.quantity_returned < 0 || flattenedReturnValue.quantity_lost < 0;
  }

  isSheetValid(): boolean
  {
    return this._sheetReturns.some(tuple => tuple.invalid);
  }

  canSave(): boolean
  {
    return !this.loading || (!! this.return_date && this.isSheetValid());
  }

  canRemove(): boolean
  {
    return !!this.data.original;
  }

  async remove(): Promise<void>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotifications.removeConfirm())
      {
        this.removeRentalReturns();
        this.request.adjustEndDate();

        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.loading = false;
    }
  }

  async save(): Promise<void>
  {
    this.loading = true;

    if (this.isFlattenedView) {
      this.distributeFlattenedQuantities();
    }

    // // TODO: Should not process request changes in editor component
    this.saveRentalReturns();
    this.request.adjustEndDate();

    try
    {
      await this.onUpdate();
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }

  saveRentalReturns(): void
  {
    for (let i = 0; i < this._sheetReturns.length; i++)
    {
        let tuple = this._sheetReturns[i];

        tuple.return.returned_at = this.getReturnDateTimestamp();
        tuple.lost.returned_at = this.getReturnDateTimestamp();

        // RETURNED
        // Remove existing RentalReturn object if quantity is 0
        if (tuple.references.return && tuple.return.quantity == 0)
        {
            tuple.references.rental.removeRentalReturn(tuple.references.return);
        }
        // Update existing RentalReturn object is quantity is > 0
        else if (tuple.references.return && tuple.return.quantity > 0)
        {
            tuple.references.return.quantity = tuple.return.quantity;
        }
        // Add new RentalReturn object if quantity is > 0
        else if (!tuple.references.return && tuple.return.quantity > 0)
        {
            tuple.references.rental.addRentalReturn(tuple.return);
        }

        // LOST
        // Remove existing RentalReturn object if quantity is 0
        if (tuple.references.lost && tuple.lost.quantity == 0)
        {
            tuple.references.rental.removeRentalReturn(tuple.references.lost);
        }
        // Update existing RentalReturn object is quantity is > 0
        else if (tuple.references.lost && tuple.lost.quantity > 0)
        {
            tuple.references.lost.quantity = tuple.lost.quantity;
        }
        // Add new RentalReturn object if quantity is > 0
        else if (!tuple.references.lost && tuple.lost.quantity > 0)
        {
            tuple.references.rental.addRentalReturn(tuple.lost);
        }

        // Update Rental remaining quantity attribute
        tuple.references.rental.validateQuantities();
    }
  }

  removeRentalReturns(): void
  {
      // Remove existing RentalReturns from their Rental
      for (let i = 0; i < this._sheetReturns.length; i++)
      {
          let tuple = this._sheetReturns[i];

          // Remove RentalReturn
          if (tuple.references.return)
          {
              tuple.references.rental.removeRentalReturn(tuple.references.return);
          }

          // Remove lost RentalReturn
          if (tuple.references.lost)
          {
              tuple.references.rental.removeRentalReturn(tuple.references.lost);
          }

          // Update Rental remaining quantity attribute
          tuple.references.rental.validateQuantities();
      }
  }

  returnAll(): void
  {
    if (this.isFlattenedView) {
      for (let key of this.flattenedReturns.keys()) {
    
        let flatReturn = this.flattenedReturns.get(key);
        flatReturn.quantity_returned = flatReturn.quantity_remaining - flatReturn.quantity_lost;
        this.validateFlatSheetItem(flatReturn);
      }
    } else {
      for (let i = 0; i < this._sheetReturns.length; i++){
        let sheetItem = this._sheetReturns[i];

        if (sheetItem.rental.quantity_remaining > 0){
          sheetItem.return.quantity = sheetItem.rental.quantity_remaining - sheetItem.lost.quantity;
        } else {
          sheetItem.return.quantity = 0;
        }

        this.validateSheetItem(sheetItem);
      }
    }
  }

  getArrayFromMapIterable<K,V>(map: Map<K,V>): [K, V][]{
    return Array.from(map);
  }
}
