<div class="index-sidebar" fxLayout="column">
    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="onSearch()" novalidate>
        <div fxLayout="column" fxLayoutGap="10px">
            <mat-form-field>
                <mat-label class="mat-icon-label">
                    <mat-icon>search</mat-icon>
                </mat-label>
                <input type="text" 
                        name="keyword"
                        [(ngModel)]="keyword"
                        matInput>
            </mat-form-field>

            <div class="tab" fxLayout="row wrap">
                <mat-checkbox class="search-all" 
                    [(ngModel)]="searchAll"
                    #searchAllField="ngModel"
                    name="searchAllField">
                    <span class="mat-caption">Search all fields</span>
                </mat-checkbox>
                <input id="tab-one" type="checkbox" name="tabs">
                <label class="tab-one-label" for="tab-one" fxFlex>More Options</label>

                <div class="tab-content" fxLayout="column" fxFlex="100">
                    <mat-form-field fxFlex>
                        <mat-label>Branch</mat-label>
                        <input type="text"
                            [(ngModel)]="branchSearchText"
                            #searchBranchField="ngModel"
                            name="searchBranchField"
                            (ngModelChange)="onQueryBranches(branchSearchText)"
                            [matAutocomplete]="branchAuto"
                            matInput>
                        <button type="button"
                            mat-icon-button
                            matSuffix
                            *ngIf="branchSearchText"
                            (click)="clearBranchSelection()">
                            <mat-icon color="primary">close</mat-icon>
                        </button>
                        <mat-autocomplete #branchAuto="matAutocomplete"
                            (optionSelected)="onBranchOptionSelected($event.option.value)"
                            [displayWith]="displaySelectedBranch">
                            <mat-option *ngFor="let branch of promisedBranches | async" [value]="branch">
                                {{ branch.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Assigned To</mat-label>
                        <input type="text"
                            [(ngModel)]="employeeSearchText"
                            #searchEmployeeField="ngModel"
                            name="searchEmployeeField"
                            (ngModelChange)="onQueryEmployees(employeeSearchText)"
                            [matAutocomplete]="employeeAuto"
                            matInput>
                        <button type="button"
                            mat-icon-button
                            matSuffix
                            *ngIf="employeeSearchText"
                            (click)="clearEmployeeSelection()">
                            <mat-icon color="primary">close</mat-icon>
                        </button>
                        <mat-autocomplete #employeeAuto="matAutocomplete"
                            (optionSelected)="onEmployeeOptionSelected($event.option.value)"
                            [displayWith]="displaySelectedEmployee">
                            <mat-option *ngFor="let employee of promisedEmployees | async" [value]="employee">
                                {{ employee.fullname }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Client</mat-label>
                        <input type="text"
                            [(ngModel)]="clientSearchText"
                            #searchClientField="ngModel"
                            name="searchClientField"
                            (ngModelChange)="onQueryClients(clientSearchText)"
                            [matAutocomplete]="clientAuto"
                            matInput>
                        <button type="button"
                            mat-icon-button
                            matSuffix
                            *ngIf="clientSearchText"
                            (click)="clearClientSelection()">
                            <mat-icon color="primary">close</mat-icon>
                        </button>
                        <mat-autocomplete #clientAuto="matAutocomplete"
                            (optionSelected)="onClientOptionSelected($event.option.value)"
                            [displayWith]="displaySelectedClient">
                            <mat-option *ngFor="let client of promisedClients | async" [value]="client">
                                {{ client.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <mat-form-field fxFlex="48">
                            <mat-label>Start Date</mat-label>
                            <input type="text"
                                [matDatepicker]="startDatePicker"
                                [(ngModel)]="selectedStartDate"
                                name="selectedStartDateField"
                                (focus)="startDatePicker.open()"
                                matInput>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="48">
                            <mat-label>End Date</mat-label>
                            <input type="text"
                                [matDatepicker]="endDatePicker"
                                [(ngModel)]="selectedEndDate"
                                name="selectedEndDateField"
                                (focus)="endDatePicker.open()"
                                matInput>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <mat-form-field fxFlex>
                        <mat-label>Filter by Dispatch</mat-label>
                        <mat-select [(ngModel)]="selectedDispatch" name="selectedDispatchField">
                            <mat-option value="''">All</mat-option>
                            <mat-option value="partial">Missing</mat-option>
                            <mat-option value="draft">Draft</mat-option>
                            <mat-option value="open">Open</mat-option>
                            <mat-option value="active">In Progress</mat-option>
                            <mat-option value="dispatched">Dispatched</mat-option>
                            <mat-option value="actioned">Actioned</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Filter by State</mat-label>
                        <mat-select [(ngModel)]="selectedState" name="selectedStateField">
                            <mat-option value="''">All</mat-option>
                            <mat-option value="active">Active</mat-option>
                            <mat-option value="discarded">Discarded</mat-option>
                            <mat-option value="estimate">Estimate</mat-option>
                            <mat-option value="invoiced">Invoiced</mat-option>
                            <mat-option value="ongoing">Ongoing</mat-option>
                            <mat-option value="pending">Pending</mat-option>
                            <mat-option value="suspended">Quarantined</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Filter by Issue</mat-label>
                        <mat-select [(ngModel)]="selectedIssueLevel" name="selectedIssueLevelField">
                            <mat-option value="''">All</mat-option>
                            <mat-option value="major">Flagged</mat-option>
                            <mat-option value="quarantine">Quarantined</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex *ngIf="departments?.length > 1">
                        <mat-label>Filter by Department</mat-label>
                        <mat-select [(ngModel)]="selectedDepartment" name="selectedDepartmentField">
                            <mat-option value="''">All</mat-option>
                            <mat-option *ngFor="let department of this.departments" [value]="department.id">
                                {{ department.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Tag</mat-label>
                        <input type="text"
                            [(ngModel)]="tag"
                            name="tagField"
                            matInput>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="primary">Search</button>
            </div>
        </div>
    </form>

    <button class="new-work-order" 
        (click)="onNewWorkOrder()"
        mat-mini-fab 
        color="secondary">
        <mat-icon>add</mat-icon>
    </button>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [fxShow]="this.workOrders.loading"></mat-progress-bar>
    
    <cdk-virtual-scroll-viewport itemSize="70">
        <mat-list dense>
            <mat-list-item class="index-list" 
                [class.index-list__item--selected]="selectedModelId === workOrder.id"
                *cdkVirtualFor="let workOrder of workOrders" 
                (click)="viewWorkOrder(workOrder)" 
                matRipple>
                <div matListAvatar class="work-order-list-state {{ workOrder.state_name }}">{{ workOrder.state_letter }}</div>
                <mat-icon class="index-list__issue-icon" *ngIf="workOrder.issue_severity == 'major'" color="primary">flag</mat-icon>
                <div class="mat-list-text" fxLayout="column">
                    <div fxLayout="row">
                        <h3 fxFlex>{{ workOrder.client.name }}</h3> 
                        <p>{{ workOrder.formatted_serial_id }}</p>
                    </div>
                    <h4>{{ workOrder.started_at | dateFilter:'dateShort' }} - {{ workOrder.ended_at | dateFilter:'dateShort':'TBD' }}</h4>
                    <p>{{ workOrder.location }}</p>
                </div>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>
</div>
<mat-divider vertical></mat-divider>

<!-- Work Order Single -->
<ui-view fxFlex fxLayout="column"></ui-view>
