<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<mat-card class="medium-card">
    <form #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
        <div class="title-row" fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="column">
                <span class="mat-headline">{{ this.title }}</span>
                <span class="mat-caption" *ngIf="this.branch.default">
                    Default Branch
                </span>
                <span class="mat-caption branch-state-label" *ngIf="this.branch.discarded">
                    Discarded
                </span>
            </div>
            <mat-slide-toggle *ngIf="!this.original?.default"
                fxFlexAlign="center"
                [(ngModel)]="this.branch.default"
                name="defaultBranchField">
                Default
            </mat-slide-toggle>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Name</mat-label>
                <input type="text"
                    [(ngModel)]="this.branch.name"
                    name="nameField"
                    #nameField="ngModel"
                    required
                    maxlength="255"
                    [disabled]=this.locked
                    matInput>
                <mat-hint align="end">{{ this.branch.name?.length || 0 }} / 255</mat-hint>
                <mat-error *ngIf="nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Address 1</mat-label>
                <input type="text"
                    [(ngModel)]="this.branch.address_1"
                    name="address1Field"
                    #address1Field="ngModel"
                    required
                    [disabled]=this.locked
                    matInput>
                <mat-error *ngIf="address1Field.hasError('required')">Address 1 is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Address 2</mat-label>
                <input type="text"
                    [(ngModel)]="this.branch.address_2"
                    name="address2Field"
                    #address1Field="ngModel"
                    [disabled]=this.locked
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>City</mat-label>
                <input type="text"
                    [(ngModel)]="this.branch.city"
                    name="cityField"
                    #cityField="ngModel"
                    required
                    [disabled]=this.locked
                    matInput>
                <mat-error *ngIf="cityField.hasError('required')">City is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Province</mat-label>
                <mat-select [(ngModel)]="this.branch.region"
                    name="regionField"
                    #regionField="ngModel"
                    [disabled]="this.locked"
                    required>
                    <mat-optgroup label="Provinces">
                        <mat-option value="AB">Alberta</mat-option>
                        <mat-option value="BC">British Columbia</mat-option>
                        <mat-option value="MB">Manitoba</mat-option>
                        <mat-option value="NL">Newfoundland and Labrador</mat-option>
                        <mat-option value="NB">New Brunswick</mat-option>
                        <mat-option value="NS">Nova Scotia</mat-option>
                        <mat-option value="ON">Ontario</mat-option>
                        <mat-option value="PE">Prince Edward Island</mat-option>
                        <mat-option value="QC">Quebec</mat-option>
                        <mat-option value="SK">Saskatchewan</mat-option>
                    </mat-optgroup>
                    <mat-optgroup label="Territories">
                        <mat-option value="NT">Northwest Territories</mat-option>
                        <mat-option value="NU">Nunavut</mat-option>
                        <mat-option value="YT">Yukon</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Country</mat-label>
                <input type="text"
                    [(ngModel)]="this.branch.country"
                    name="countryField"
                    #countryField="ngModel"
                    disabled
                    required
                    matInput>
                <mat-error *ngIf="countryField.hasError('required')">Country is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Postal Code</mat-label>
                <input type="text"
                    [(ngModel)]="this.branch.postal_code"
                    name="postalCodeField"
                    #postalCodeField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="postalCodeField.hasError('required')">Postal code is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Email</mat-label>
                <input type="email"
                    [(ngModel)]="this.branch.email"
                    name="emailField"
                    #emailField="ngModel"
                    [disabled]="this.locked"
                    email
                    matInput>
                <mat-error *ngIf="emailField.hasError('email')">Invalid email.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Phone</mat-label>
                <input type="tel"
                    [(ngModel)]="this.branch.phone"
                    name="phoneField"
                    #phoneField="ngModel"
                    [disabled]="this.locked"
                    matInput>
                <mat-error *ngIf="phoneField.hasError('required')">Phone is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Toll Free</mat-label>
                <input type="tel"
                    [(ngModel)]="this.branch.toll_free"
                    name="tollFreeField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Fax</mat-label>
                <input type="text"
                    [(ngModel)]="this.branch.fax"
                    name="faxField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <div class="action-row" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-icon-button
                type="button"
                *ngIf="this.branch.discarded"
                [disabled]="this.loading"
                (click)="this.onRestore()"
                color="primary">
                <mat-icon>restore</mat-icon>
            </button>
            <button mat-icon-button
                type="button"
                *ngIf="!this.branch.discarded && this.branch.exists() && !this.original.default"
                [disabled]="this.loading || !this.branch.exists()"
                (click)="this.discard()"
                color="primary">
                <mat-icon>delete</mat-icon>
            </button>
            <span fxFlex></span>

            <button mat-raised-button color="accent" type="submit">
                Save
            </button>
        </div>
    </form>
</mat-card>

<app-rental-overrides modelLabel="Branch"
    [overridableModel]="this.branch" 
    [onRentalUpdate]="this.onRentalUpdate"
    [onRentalRemove]="this.onRentalRemove">
</app-rental-overrides>

<app-service-overrides modelLabel="Branch"
    [overridableModel]="this.branch"
    [onServiceUpdate]="this.onServiceUpdate"
    [onServiceRemove]="this.onServiceRemove">
</app-service-overrides>