<div class="mat-dialog-editor" fxLayout="column">
    <mat-toolbar fxLayout="row">
        <h2 fxFlex class="mat-title">{{ this.document.attachment.name }}</h2>
        <button mat-icon-button color="primary" (click)="this.download()">
            <mat-icon>cloud_download</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="this.editDocument()" *ngIf="this.account.isAdmin()">
            <mat-icon>info</mat-icon>
        </button>
        <button mat-icon-button color="primary" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>

    <mat-dialog-content>
        <img *ngIf="this.docIsImageType()" [src]="this.document.attachment.url">
        <iframe fxFlexFill *ngIf="this.docIsApplicationType()" [src]="this.trustedUrl"></iframe>
    </mat-dialog-content>
</div>
