<div class="mat-dialog-editor">
    <mat-toolbar fxLayout="row">
        <h2 class="mat-title">{{ this.header }}</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="column">
            <mat-form-field>
                <mat-label>{{ this.label }}</mat-label>
                <input type="text"
                    name="selectedDateField"
                    [(ngModel)]="this.selectedDate"
                    [matDatepicker]="selectedDatepicker"
                    (focus)="selectedDatepicker.open()"
                    required
                    matInput>
                <mat-datepicker-toggle matSuffix [for]="selectedDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #selectedDatepicker></mat-datepicker>
            </mat-form-field>
    
            <mat-slide-toggle name="resetTimesField" [(ngModel)]="this.resetTimes">
                Reset request times
            </mat-slide-toggle>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="accent" (click)="this.ok()">Ok</button>
    </mat-dialog-actions>
</div>