import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LoginState } from 'src/app/views/public/login/login.state';
import { Account } from '@beaconlite/models';
import { HttpResponse } from '../network/http-response';
import { RouteMap } from '../network/route-map.service';
import { AccessToken, TokenManagerService } from './token-manager.service';
import { PreferencesService } from '@beaconlite/services/preferences.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  protected readonly DEFAULT_ADMIN_STATE = 'protected.work-orders';
  protected readonly DEFAULT_USER_STATE = 'protected.user-dispatches';
  
  initializing: Promise<AccessToken> = null;
  protected _loadingAccount: Promise<Account> = null;
  protected _account: Account = null;

  constructor(
    protected routeMap: RouteMap,
    protected stateService: StateService,
    protected tokenManager: TokenManagerService,
    protected prefs: PreferencesService,
  ) { 
    this.initialize();
  }

  /**
   * Initialize the service
   * 
   * @returns {Promise}
   */
  async initialize(): Promise<void> 
  {
    console.debug('[Service:Authentication] initializing...');

    this.initializing = this.tokenManager.initialize();

    await this.initializing;

    // Don't try loading authed account if token is invalid
    if (! this.isAuthenticated()) return;

    await this.loadAuthenticatedAccount();
    await this.prefs.load();
  }

  /**
   * Get the access token for the authenticated account.
   * 
   * @returns {Promise<AccessToken>}
   */
  async getAuthToken(): Promise<AccessToken|void> 
  {
    return this.tokenManager.getToken()
    .catch( this.handleAuthErrors.bind(this) ) 
  }

  /**
   * Handle user login
   * 
   * @param {string} username
   * @param {string} password
   * @returns {Promise}
   */
  async login(username: string, password: string): Promise<any> 
  {
    await this.tokenManager.requestToken(username, password);
    await this.loadAuthenticatedAccount();
    this.redirectToDefault();
  }

  /**
   * Handle user logout
   * 
   * @returns {Promise<boolean>}
   */
  async logout(): Promise<boolean> 
  {
    this.clearAuthenticatedAccount();

    return this.tokenManager.revokeToken()
    .finally( () => this.redirectToLogin() )
  }

  /**
   * Handle reset password request for a forgotten password
   * 
   * @param {string} email 
   */
  requestPasswordReset(email: string): Promise<HttpResponse> 
  {
    return this.routeMap.forgotPassword(email);
  }

  /**
   * Handle reset password request
   * 
   * @param params 
   */
  resetPassword(params: {email: string, token: string, password: string, password_confirmation: string}): Promise<HttpResponse> 
  {
    return this.routeMap.resetPassword(params);
  }

  /**
   * Gets the currently authenticated user
   * 
   */
  async getCurrentUser(): Promise<Account>
  {
    await this.initializing;
    await this._loadingAccount;

    return this._account.copy();
  }

  /**
   * Checks if the supplied user matches the currently authenticated one
   * 
   * @returns {boolean}
   */
  isCurrentUser(id: string): boolean 
  {
    if (! this._account) { return false; }
    return this._account.id == id;
  }

 /**
  * Load the authenticated account details from the API
  * 
  * @returns {Promise}
  */
  protected async loadAuthenticatedAccount(): Promise<Account> 
  {
    this._loadingAccount = Account.auth();
    this._account = await this._loadingAccount;
    
    return this._account;
  }

  /**
   * Gets default application state based on account type
   * 
   * @returns {string}
   */
  async getDefaultState(): Promise<string> 
  {
    await this._loadingAccount;

    return this._account.isAdmin() 
      ? this.DEFAULT_ADMIN_STATE 
      : this.DEFAULT_USER_STATE;
  }

  /**
   * Clear the authenticated account from the service.
   *
   */
  protected clearAuthenticatedAccount() 
  {
    this._account = null;
  }

  /**
   * Verify if there is an authenticated account.
   * 
   * @returns {boolean}
   */
  isAuthenticated(): boolean 
  {
    // TODO: I think this can be improved.
    // Check and trigger refresh attempt if we have a refresh token
    // Consolidate to make sure that when isAuthed is false the TokenManager has no token and the AuthService has no account
    return this.tokenManager.hasValidToken();
  }

  /**
   * Handle Http response errors pertaining to authentication.
   * 
   * @param {HttpResponse} response 
   */
  protected handleAuthErrors(response: HttpResponse): void 
  {
    if ( !response.isClientError()) { return; }

    // Backend errors
    if (response.error() !== null &&
        response.error().type == 'authentication')
    {
      this.logout();
      return;
    }

    // API errors
    if (!! response.data() &&
        ( response.data().code == 'exception.passport.authentication' ||
          response.data().code == 'exception.passport.unexpected' ))
    {
      this.logout();
      return;
    }
  }

  /**
   * Redirect to the LOGIN_STATE
   */
  protected redirectToLogin(): void 
  {
    this.stateService.go( LoginState );
  }
  
  /**
   * Redirect to the DEFAULT_STATE
   */
  protected async redirectToDefault(): Promise<void> 
  {
    const defaultState = await this.getDefaultState();
    this.stateService.go(defaultState);
  }
}
