<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Service Editor</h2>
    </mat-toolbar>
    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            <app-thumbnail-control *ngIf="!this.loading && !this.serviceDefinition.variants_enabled"
                name="thumbnailField"
                [original]="this.serviceDefinition.thumbnail"
                (thumbnailSelected)="this.onThumbnailSelected($event)"
                (thumbnailRemoved)="this.onThumbnailRemoved($event)"
                (thumbnailUpdated)="this.onThumbnailUpdated($event)">
            </app-thumbnail-control>
            
            <mat-form-field fxFlex> 
                <mat-label>Name</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceDefinition.name"
                    name="nameField"
                    #nameField="ngModel"
                    required
                    [disabled]="this.locked"
                    maxlength="255"
                    matInput>
                <mat-hint align="end">{{ this.serviceDefinition.name?.length || 0 }} / 255</mat-hint>
                <mat-error *ngIf="nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex fxFlex="80">
                <mat-label>Description</mat-label>
                <textarea [(ngModel)]="this.serviceDefinition.description"
                        name="descriptionField" 
                        rows="4"
                        [disabled]="this.locked"
                        matInput>
                </textarea>
            </mat-form-field>
            <div fxFlex="20" fxLayout="column" fxLayoutGap="10px">
                <mat-slide-toggle name="disableAddingToWorkOrdersField"
                    [(ngModel)]="this.serviceDefinition.disabled"
                    [disabled]="this.locked">
                    Disabled
                </mat-slide-toggle>
                <div class="form-instruction">Disabled items cannot be added to Work Orders</div>   
            </div>

        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            
            <mat-form-field fxFlex>
                <mat-label>Code</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceDefinition.code"
                    name="codeField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.serviceDefinition.code?.length || 0 }} / 255</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Tags</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceDefinition.tags"
                    name="tagField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.serviceDefinition.tags?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row"  fxLayoutGap="20px" *ngIf="this.departments?.length > 1">
            <mat-form-field fxFlex>
                <mat-label>Default Department</mat-label>
                <mat-select [(ngModel)]="this.serviceDefinition.department_id"
                    name="departmentField"
                    [disabled]="this.locked"
                    required>
                    <mat-option *ngFor="let department of this.departments" [value]="department.id">
                        {{ department.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Default Pricing</mat-label>
                <mat-select [(ngModel)]="this.serviceDefinition.default_pricing_type"
                    name="defaultPricingField"
                    [disabled]="this.locked"
                    required>
                    <mat-option *ngFor="let item of this.pricingTypes" [value]="item">
                        {{ this.getPriceTypeLabel(item) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex fxLayoutGap="20px">
                <mat-label>Regular Rate</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceDefinition.regular_rate"
                    name="regularRateField"
                    #regularRateField="ngModel"
                    [disabled]="this.locked || this.serviceDefinition.variant_rates_enabled"
                    currencyInput
                    minNum="0"
                    required
                    matInput>
                <mat-error *ngIf="regularRateField.hasError('required')">Regular rate is required.</mat-error>
                <mat-error *ngIf="regularRateField.hasError('minNum')">Regular rate must be at least $ 0.00.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex fxLayoutGap="20px" *ngIf="this.serviceDefinition.default_pricing_type === this.PRICING_HOUR ">
                <mat-label>Overtime Rate</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceDefinition.overtime_rate"
                    name="overtimeRateField"
                    #overtimeRateField="ngModel"
                    [disabled]="this.locked || this.serviceDefinition.variant_rates_enabled"
                    currencyInput
                    minNum="0"
                    required
                    matInput>
                <mat-error *ngIf="overtimeRateField.hasError('required')">Overtime rate is required.</mat-error>
                <mat-error *ngIf="overtimeRateField.hasError('minNum')">Overtime rate must be at least $ 0.00.</mat-error>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Unit &mdash; Long Form</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceDefinition.unit_long"
                    name="unitLongField"
                    maxlength="32"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.serviceDefinition.unit_long?.length || 0 }} / 32</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Unit &mdash; Short Form</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceDefinition.unit_short"
                    name="unitShortField"
                    maxlength="32"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.serviceDefinition.unit_short?.length || 0 }} / 32</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="!this.serviceDefinition.exists()">
            <div  fxLayout="column">
                <h3 class="mat-subeading-2">Variants can be enabled after Service Definition is saved.</h3>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button (click)="this.onClose()">
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
