<div fxLayout="column" class="header">
    <span class="mat-headline" fxFlex>Upcoming Dispatches</span>
    <p class="mat-subhead">List of recent and upcoming dispatches</p>
</div>
<mat-divider></mat-divider>
<div fxLayout="column" fxFlex>
    <cdk-virtual-scroll-viewport class="summary-view-viewport" 
        [itemSize]="this.summaryItemSize"
        #summaryContainer> 
        <dispatch-summary class="large-card"
            *cdkVirtualFor="let dispatch of this.dispatchSource"
            [shouldShowDepartment]="this.hasMultipleDepartments"
            [dispatch]="dispatch"
            [stateOnView]="'protected.upcoming-dispatches-single'">
        </dispatch-summary>
    </cdk-virtual-scroll-viewport> 
</div>
