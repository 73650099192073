<div class="index-sidebar" fxLayout="column">
    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="onSearch()" novalidate>
        <div fxLayout="column" fxLayoutGap=10px>
            <button mat-raised-button color="primary" (click)="this.goToSchedule()">Invoice Schedule</button>
            <mat-form-field>
                <mat-label class="mat-icon-label">
                    <mat-icon>search</mat-icon>
                </mat-label>
                <input type="text" 
                    name="keywordField"
                    [(ngModel)]="this.keyword"
                    matInput>
            </mat-form-field>

            <div class="tab" fxLayout="row wrap">
                <mat-checkbox class="search-all" 
                    [(ngModel)]="searchAll"
                    #searchAllField="ngModel"
                    name="searchAllField">
                    <span class="mat-caption">Search all fields</span>
                </mat-checkbox>
                <input id="tab-one" type="checkbox" name="tabs">
                <label class="tab-one-label" for="tab-one" fxFlex>More Options</label>

                <div class="tab-content" fxLayout="column" fxFlex="100">
                    <mat-form-field fxFlex>
                        <mat-label>Branch</mat-label>
                        <input type="text"
                            [(ngModel)]="branchSearchText"
                            name="branchSearchTextField"
                            #branchSearchTextField="ngModel"
                            (ngModelChange)="onQueryBranches()"
                            (blur)="clearSearchText(selectedBranch, 'branch')"
                            [matAutocomplete]="branchAuto"
                            matInput>
                        <button type="button" 
                            mat-icon-button 
                            matSuffix
                            *ngIf="branchSearchText"
                            (click)="clearBranchSelection()"> 
                            <mat-icon color="primary">close</mat-icon>
                        </button>
                        <mat-autocomplete #branchAuto="matAutocomplete"
                            (optionSelected)="onBranchOptionSelected($event.option.value)"
                            [displayWith]="displaySelectedBranch">
                            <mat-option *ngFor="let branch of promisedBranches | async" [value]="branch">
                                {{ branch.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Client</mat-label>
                        <input type="text"
                            [(ngModel)]="clientSearchText"
                            #searchClientField="ngModel"
                            name="searchClientField"
                            (ngModelChange)="onQueryClients()"
                            (blur)="clearSearchText(selectedClient, 'client')"
                            [matAutocomplete]="clientAuto"
                            matInput>
                        <button type="button" 
                            mat-icon-button 
                            matSuffix
                            *ngIf="clientSearchText"
                            (click)="clearClientSelection()"> 
                            <mat-icon color="primary">close</mat-icon>
                        </button>
                        <mat-autocomplete #clientAuto="matAutocomplete"
                            (optionSelected)="onClientOptionSelected($event.option.value)"
                            [displayWith]="displaySelectedClient">
                            <mat-option *ngFor="let client of promisedClients | async" [value]="client">
                                {{ client.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <mat-form-field fxFlex="48">
                            <mat-label>Start Date</mat-label>
                            <input type="text"
                                [matDatepicker]="startDatePicker"
                                [(ngModel)]="this.selectedStartDate"
                                name="selectedStartDateField"
                                (focus)="startDatePicker.open()"
                                matInput>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="48">
                            <mat-label>End Date</mat-label>
                            <input type="text"
                                [matDatepicker]="endDatePicker"
                                [(ngModel)]="this.selectedEndDate"
                                name="selectedEndDateField"
                                (focus)="endDatePicker.open()"
                                matInput>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <mat-form-field fxFlex="48">
                            <mat-label>Filter by State</mat-label>
                            <mat-select [(ngModel)]="this.selectedState" name="selectedStateField">
                                <mat-option value="''">All</mat-option>
                                <mat-option value="discarded">Discarded</mat-option>
                                <mat-option value="invoiced">Invoiced</mat-option>
                                <mat-option value="sent">Sent</mat-option>
                                <mat-option value="voided">Voided</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="48">
                            <mat-label>Order By</mat-label>
                            <mat-select [(ngModel)]="this.orderBy" name="orderByField">
                                <mat-option value="id">ID</mat-option>
                                <mat-option value="client">Client</mat-option>
                                <mat-option value="date">Date</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" color="accent">
                <button type="submit" mat-raised-button color="primary">Search</button>
            </div>
        </div>
    </form>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [fxShow]="this.invoices.loading"></mat-progress-bar>

    <cdk-virtual-scroll-viewport itemSize="70">
        <mat-list dense>
            <mat-list-item class="index-list" 
                *cdkVirtualFor="let invoice of this.invoices" 
                [class.index-list__item--selected]="selectedModelId === invoice.id"
                (click)="this.onEdit(invoice)" 
                matRipple>
                <div matListAvatar class="invoice-list-state {{ invoice.state_name }}">{{ invoice.state_letter }}</div>
                <div class="mat-list-text" fxLayout="column">
                    <div fxLayout="row">
                        <h3 fxFlex>{{ invoice.client.name }}</h3> 
                        <p>{{ invoice.formatted_serial_id }}</p>
                    </div>
                    <div fxLayout="row">
                        <h4 fxFlex>{{ invoice.started_at | dateFilter:'dateShort' }} - {{ invoice.ended_at | dateFilter:'dateShort':'TBD' }}</h4>
                        <p>{{ this.getWOSerial(invoice) }}</p>
                    </div>
                    <p>{{ invoice.work_order.location }}</p>
                </div>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>
</div>
<mat-divider vertical></mat-divider>

<!-- Invoice Single -->
<ui-view fxFlex fxLayout="column"></ui-view>
