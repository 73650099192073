import { Ng2StateDeclaration } from '@uirouter/angular';
import { WorkOrderSingleComponent } from './work-order-single.component';
import { Transition } from '@uirouter/core';

export const WorkOrderSingleState: Ng2StateDeclaration = {
  name: 'protected.work-orders.single',
  url: '/:modelId',
  component: WorkOrderSingleComponent,
  data: {
    pageTitle: 'Work Order',
    requiresAuth: true,
  },
  resolve: [
    { 
      token: 'modelId', 
      deps: [Transition],
      resolveFn: (transition: Transition) => transition.params().modelId,
    },
  ],
}
