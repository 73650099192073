import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { UpcomingDispatchesSingleComponent } from "./upcoming-dispatches-single.component";


export const UpcomingDispatchesSingleState: Ng2StateDeclaration = {
    name: 'protected.upcoming-dispatches-single',
    url: '/upcoming-dispatches/:modelId',
    component: UpcomingDispatchesSingleComponent,
    data: {
        pageTitle: 'Upcoming Dispatches',
        requiresAuth: true,
    },
    params: {
        modelId: 'new'
    },
    resolve: [
        { 
            token: 'modelId',
            deps: [Transition],
            resolveFn: (transition: Transition) => transition.params().modelId,
        },
    ],
}
