import { BaseModel } from "./Base.model";
import { Rental } from "./Rental.model";
import IRental from "./contracts/IRental.interface";
import { dto } from './mixins/Dto.decorators'

export class DispatchedRental extends BaseModel implements IRental {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }
    
    @dto() id?: string = null;
    @dto() dispatched_rental_request_id?: string = null;
    @dto() dispatched_rental_group_id?: string = null;
    @dto() rental_id?: string = null;
    @dto() dispatched_quantity?: number = 0;
    @dto() actioned_quantity?: number = null;
    @dto() reviewed_quantity?: number = null;

    @dto(Rental) source: Rental = null;

    static fromRental(source: Rental | DispatchedRental): DispatchedRental 
    {
        // Already the right type
        if ( source instanceof DispatchedRental ) { return source; }
    
        let rental = new DispatchedRental();
        rental.source = source;
        rental.rental_id = source.id;
        rental.dispatched_quantity = source.quantity_remaining;
        return rental;
    }
}
