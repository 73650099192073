import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { WorkOrder } from '@beaconlite/models';
import { WorkOrderEditorData } from "./work-order-editor-data.interface";
import { WorkOrderEditorComponent } from "./work-order-editor.component";

@Injectable({
    providedIn: 'root'
})
export class WorkOrderEditorService {
  
    constructor(protected dialog: MatDialog) {}
  
    async open(data?: WorkOrderEditorData): Promise<WorkOrder> 
    {
      const dialogConfig: MatDialogConfig = {
        disableClose: true,
        hasBackdrop: true,
        autoFocus: true,
        width: '100%',
        maxWidth: 800,
        data,
      }
  
      return this.dialog.open(WorkOrderEditorComponent, dialogConfig)
        .afterClosed()
        .toPromise();
    }
}
