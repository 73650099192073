<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Variant Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            
            <app-thumbnail-control
                *ngIf="!this.loading"
                name="thumbnailField"
                [original]="this.variant.thumbnail"
                (thumbnailSelected)="this.onThumbnailSelected($event)"
                (thumbnailRemoved)="this.onThumbnailRemoved($event)"
                (thumbnailUpdated)="this.onThumbnailUpdated($event)">
            </app-thumbnail-control>

            <mat-form-field fxFlex>
                <mat-label>Name</mat-label>
                <input type="text"
                    name="nameField"
                    #nameField="ngModel"
                    [(ngModel)]="this.variant.name"
                    matInput
                    required>
                <mat-error *ngIf="this.nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Description</mat-label>
                <textarea name="descriptionField"
                    [(ngModel)]="this.variant.description"
                    matInput>
                </textarea>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">      
            <mat-form-field fxFlex *ngIf="this.shouldDisplayQuantity()">
                <mat-label>Quantity</mat-label>
                <input type="number"
                    name="quantityField"
                    [(ngModel)]="this.variant.quantity"
                    matInput
                    required>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Code</mat-label>
                <input type="text"
                    name="codeField"
                    [(ngModel)]="this.variant.code"
                    matInput>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Tags</mat-label>
                <input type="text"
                    name="tagsField"
                    [(ngModel)]="this.variant.tags"
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex *ngFor="let field of this.itemDefinition.variant_fields">
                <mat-label class="capitalize">{{ this.formatFieldLabel(field) }}</mat-label>
                <ng-container *ngIf="this.field !== 'default_pricing_type'">
                    <input
                    type="text"
                    name="field{{field}}"
                    [(ngModel)]="this.variant[field]"
                    [disabled]="this.itemDefinition.variant_rates_enabled"
                    currencyInput
                    matInput
                    required>
                </ng-container>
                <ng-container *ngIf="this.field === 'default_pricing_type'">
                    <mat-select
                        name="defaultPricingTypeField"
                        [(ngModel)]="this.variant.default_pricing_type"
                        #defaultPricingTypeField="ngModel"
                        required>
                        <mat-option 
                            *ngFor="let item of this.pricingTypes" 
                            [value]="item">
                            {{ this.getPriceTypeLabel(item) }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="defaultPricingTypeField.hasError('required')">Default Pricing Type is required.</mat-error>
                </ng-container>
            </mat-form-field>
        </div>

        <div fxLayout="row wrap">
            <!-- TODO: Find a better way to lay this out. 
                Currently hard coded to 3 columns. 
                Also 32.88% is kinda gross -->
            <mat-form-field  fxFlex="0 1 calc(32.88%-10px)" 
                *ngFor="let def of this.itemDefinition.variant_property_definitions; let i = index"
                [style.margin-right]="(i+1)%3 ? '20px': '0px'">
                <mat-label class="capitalize">{{ def.name }}</mat-label>
                <mat-select name="propDef{{i}}" 
                    [(ngModel)]="this.getPropFromDefinition(def).value"
                    (ngModelChange)="this.calculateRateVariantFields()"
                    *ngIf="def.isDropdown()">
                    <mat-option *ngFor="let option of def.options" [value]="option.id">
                        {{ option.name }}
                    </mat-option>
                </mat-select>

                <input type="text"
                    name="propDef{{i}}"
                    *ngIf="def.isText()"
                    [(ngModel)]="this.getPropFromDefinition(def).value"
                    matInput>
            </mat-form-field>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions fxLayout="row">
        <button mat-icon-button
            type="button" 
            *ngIf="this.original.exists()"
            (click)="this.remove()"
            color="primary">
            <mat-icon>delete</mat-icon>
        </button>
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
