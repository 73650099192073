import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DateDialogData } from "./date-dialog-data.interface";
import { DateDialogComponent } from './date-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DateDialogService {

    constructor(protected dialog: MatDialog) {}

    async open(data: DateDialogData): Promise<any> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            data,
        }

        return this.dialog.open(DateDialogComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
  