import { BaseModel } from "./Base.model";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { DateFilterPipe } from "../pipes/date-filter.pipe";
import { AppInjector } from '../services/app-injector.service';
import { Helpers as $helpers } from '../services/helpers.service'
import { orderBy } from "@beaconlite/utilities/Sort.utility";

export class Note extends BaseModel {
    
    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    static TYPE_PRIVATE = 'private';
    static TYPE_INVOICE = 'invoice';
    static TYPE_DISPATCH = 'dispatch';
    static TYPE_FIELD = 'field';

    @dto() id?: string = null;
    @dto() notable_id?: string = null;
    @dto() notable_type?: BaseModel = null;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() type?: any = null;
    @dto() content?: string = null;
    @dto() @date noted_at?: number = null;
    @dto() recurring?: boolean = false;
    @dto() @date recurring_until?: number = null;

    duplicate(): Note 
    {
        const duplicate = this.copy();
        // Reset Service properties used for existing models
        duplicate.id                = null;
        duplicate.notable_id        = null;
        duplicate.notable_type      = null;
        duplicate.locked            = false;
        duplicate.invoiced          = false;
        duplicate.created_at        = null;
        duplicate.updated_at        = null;

        return duplicate;
    }

    displayDate(): string 
    {
        if (! this.exists()) return '';

        const dateFilter = AppInjector.get(DateFilterPipe);
        return dateFilter.transform(this.noted_at, 'dateTimeMedium');
    }

    displayType(): string
    {
        return Note.getDisplayType(this.type);
    }

    static getDisplayType(type: any): string
    {
        switch (type)
        {
            case Note.TYPE_INVOICE:
                return 'Invoice';

            case Note.TYPE_PRIVATE:
                return 'Private';

            case Note.TYPE_DISPATCH:
                return 'Dispatch';

            case Note.TYPE_FIELD:
                return 'Field Note';

            default:
                return 'Invalid';
        }
    }

    static getFirstNote(notes: Note[], type: string): Note 
    {
        let filteredNotes = notes.filter( note => note.type === type);
        filteredNotes.sort( orderBy('created_at') );

        let note = new Note({type});

        if (filteredNotes.length > 0)
        {
            note = filteredNotes[0];
        }

        return note;
    }

    /**
     * Stop the charge recurrence.
     *
     * @returns {Promise}
     */
    //  async stopRecurrence(): Promise<Note> {
     async stopRecurrence(){
        // Charge must be recurring and have at least one invoice to be stopped.
        if ( this.invoiced || !this.locked || !this.recurring)
        {
            return this;
        }
        
        const response = await this.routeMap.stopNote(this.id);
        return this.map(response.data());
    }
}
