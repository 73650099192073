import { BaseModel } from "./Base.model";
import { VariantOption } from "./VariantOption.model";
import { dto } from "./mixins/Dto.decorators";
import { VariantDefinition } from "./contracts/VariantDefinition.interface";

export abstract class VariantPropertyDefinition extends BaseModel {

    constructor(attributes: object = {}) 
    {
        super();
        this.init(attributes);
    }

    static TYPE_TEXT = 'text';
    static TYPE_DROPDOWN = 'dropdown';

    @dto() id?: string = null;
    @dto() variant_configuration_id?: string = null;
    @dto() type?: string = VariantPropertyDefinition.TYPE_TEXT;
    @dto() name?: string = null;
    @dto() is_displayed: boolean = true;
    @dto() ordinal?: number = 0;

   abstract options?: VariantOption[];

    isText(): boolean
    {
        return this.type === VariantPropertyDefinition.TYPE_TEXT;
    }

    isDropdown(): boolean
    {
        return this.type === VariantPropertyDefinition.TYPE_DROPDOWN;
    }

    async save(): Promise<VariantPropertyDefinition>
    {
        const response = this.exists()
            ? await this.routeMap.updateVariantProp(this.id, this.flush())
            : await this.routeMap.createVariantProp(this.flush());

        return this.map( response.data() );
    }

    async discard(): Promise<void>
    {
        await this.routeMap.discardVariantProp(this.id);
    }

    abstract addOption(): void; 

    removeOption(model: VariantOption): void
    {
        const index = this.options.indexOf(model);

        if (index >= 0)
        {
            this.options.splice(index, 1);
        }
    }
}
