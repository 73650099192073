import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Sage50TaxEditorComponent } from "./sage50-tax-editor.component";
import { Sage50TaxEditorData } from "./sage50-tax-editor.interface";

@Injectable({
    providedIn: 'root'
})
export class Sage50TaxEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: Sage50TaxEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            data
          }

        return this.dialog.open(Sage50TaxEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
