import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { DispatchScheduleSingleComponent } from "./dispatch-schedule-single.component";

export const DispatchScheduleSingleState: Ng2StateDeclaration = {
    name: 'protected.dispatch-schedule.single',
    url: '/:modelId',
    component: DispatchScheduleSingleComponent,
    data: {
        pageTitle: 'Dispatches',
        requiresAuth: true,
    },
    params: {
        modelId: 'new',
    },
    resolve: [
        { 
          token: 'modelId', 
          deps: [Transition],
          resolveFn: (transition: Transition) => transition.params().modelId,
        },
      ],
}
