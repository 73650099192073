
export function orderBy(...props: string[]): ( a: object, b: object ) => number
{
    return ( a: object, b: object ) => {

        for( const rawProp of props )
        {
            const invertFactor = rawProp.slice(0, 1) === '-' ? -1 : 1;
            // Remove desc prefix
            const dotProps = invertFactor < 0 ? rawProp.substring(1) : rawProp;
            // Split dot notation
            const splitProps = dotProps.split('.')

            // Descend to the comparison level
            let aPrime = a;
            let bPrime = b;

            for( const prop of splitProps )
            {
                aPrime = aPrime[prop]
                bPrime = bPrime[prop]

                if ( aPrime === undefined && bPrime === undefined ) { return 0; }
                if ( bPrime === undefined ) { return 1 * invertFactor; }
                if ( aPrime === undefined ) { return -1 * invertFactor; }
            }

            if ( aPrime > bPrime ) { return 1 * invertFactor }
            if ( aPrime < bPrime ) { return -1 * invertFactor }
        }

        return 0;
    }
}

