import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeFilter'
})
export class TimeFilterPipe implements PipeTransform {
    readonly HOUR_SYMBOL = 'h';
    readonly MINUTE_SYMBOL = 'min';

    constructor(protected decimalPipe: DecimalPipe) {}

    transform(value: number = 0, units: 'minutes' | 'hours' | 'human' = 'hours'): string {
        let symbol = this.HOUR_SYMBOL;
        let divisor = 3600;
        let precision = '1.2-2';

        if (units == 'minutes')
        {
            symbol = this.MINUTE_SYMBOL;
            divisor = 60;
            precision = '1.0-0';
        }

        return `${this.decimalPipe.transform(value / divisor, precision)} ${symbol}`
    }
}
