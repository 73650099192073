import { BaseModel } from "./Base.model";
import { InvoicedRentalGroup } from "./InvoicedRentalGroup.model";
import IRental from "./contracts/IRental.interface";
import { InvoicedRequestItem } from "./contracts/InvoicedRequestItem.interface";
import { InvoicedAdjustment } from "./contracts/invoiced/InvoicedAdjustment.interface";
import { date } from "./mixins/Date.decorators";
import { Discardable } from "./mixins/Discardable.mixin";
import { dto } from "./mixins/Dto.decorators";

const MixinBase = Discardable( BaseModel );

export class InvoicedRental extends MixinBase implements InvoicedRequestItem, IRental {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() invoiced_rental_request_id?: string = null;
    @dto() invoiced_rental_group_id?: string = null;
    @dto() rental_id?: string = null;
    @dto() name?: string = null;
    @dto() detail?: string = null;
    @dto() code?: string = null;
    @dto() quantity?: number = null;
    @dto() quantity_remaining?: number = null;
    @dto() quantity_invoiced?: number = null;
    @dto() pricing_type?: string = null;
    @dto() daily_rate_override?: number = null;
    @dto() daily_rate?: number = null;
    @dto() weekly_discount?: number = null;
    @dto() monthly_discount?: number = null;
    @dto() applied_rate?: number = null;
    @dto() retail_cost?: number = null;
    @dto() replacement_discount?: number = null;
    @dto() replacement_rental_offset?: number = null;
    @dto() replacement_charge?: number = null;
    @dto() minimum_charge?: number = null;
    @dto() position?: number = null;
    @dto() @date period_started_at?: number = null;
    @dto() @date period_ended_at?: number = null;
    @dto() period_days?: number = null;
    @dto() rental_days?: number = null;
    @dto() rental_total?: number = null;
    @dto() total?: number = null;

    @dto() rate_adjustment?: InvoicedAdjustment  = null;
    // Constructor not set in dto() to avoid circular dependency.
    @dto() rental_group: InvoicedRentalGroup = null;
}
