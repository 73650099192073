import { Ng2StateDeclaration } from '@uirouter/angular';
import { Sage50ExportsComponent } from './sage50-exports.component';

export const Sage50ExportsState: Ng2StateDeclaration = {
  name: 'protected.exports-sage50',
  url: '/export/sage50',
  component: Sage50ExportsComponent,
  data: { 
    pageTitle: 'Sage50 Exports', 
    requiresAuth: true,
  },
}
