import { BaseModel } from './Base.model'
import { Account } from './Account.model'
import { Discardable } from './mixins/Discardable.mixin'
import { HasItemOverrides } from './mixins/HasItemOverrides.mixin'
import { Suspendable } from './mixins/Suspendable.mixin'
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { RouteMap } from '../services/network/route-map.service'
import { AppInjector } from '../services/app-injector.service'

const MixinBase = HasItemOverrides(
                  Suspendable(
                  Discardable( BaseModel ) ) );
export class Client extends MixinBase {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null
    @dto() name?: string = null
    @dto() address_1?: string = null
    @dto() address_2?: string = null
    @dto() city?: string = null
    @dto() region?: string = null
    @dto() postal_code?: string = null
    @dto() country: string = 'Canada'
    @dto() phone?: string = null
    @dto() fax?: string = null
    @dto() email?: string = null
    @dto() notice?: string = null
    @dto() @date overtime_start?: number = null
    @dto() @date overtime_end?: number = null
    @dto() default_tax?: string = null
    @dto() prefix?: string = null

    @dto(Account) accounts: Account[] = []
    @dto() accounting_exportable = []

    static async get(id: string): Promise<Client> 
    {
        const response = await AppInjector.get(RouteMap).getClient(id)
        return new Client( response.data() )
    }

    /**
     * Save the current Client instance.
     *
     * @returns {Promise}
     */
    async save(): Promise<Client>
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateClient( this.id, this.flush() )
            : await this.routeMap.createClient( this.flush() )

        return this.map( response.data() )
    }

    /**
     * Discard the current Client instance.
     *
     * @returns {Promise}
     */
    async discard(): Promise<Client>
    {
        const response = await this.routeMap.discardClient( this.id )
        return this.map( response.data() )
    }

    /**
     * Recover the current Client instance.
     *
     * @returns {Promise}
     */
    async recover(): Promise<Client>
    {
        const response = await this.routeMap.recoverClient( this.id )
        return this.map( response.data() )
    }

     /**
     * Reload the current Client instance from remote.
     *
     * @returns {Promise}
     */
    async reload(): Promise<Client>
    {
        const response = await this.routeMap.getClient( this.id );
        return this.map( response.data() )
    }

    hasAccounts(): boolean
    {
        return this.accounts.length > 0;
    }

    removeAccount(model: Account): void
    {
        var index = this.accounts.indexOf(model);

        if ( index >= 0 )
        {
            this.accounts.splice(index, 1);
        }
    }

    hasAccountingExportable(): boolean
    {
        return this.accounting_exportable.length > 0
    }

    getAccountingExportable()
    {
        return this.accounting_exportable[0]
    }

}
