<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.onSave()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Company Settings Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div class="fieldset">
            <div fxLayout="row">
                <h3 class="mat-subheading-2">Business Hours</h3>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex>
                    <mat-label>Start Time</mat-label>
                    <input type="time"
                        [(ngModel)]="this.preferences.company.business_hours.start"
                        name="startTimeField"
                        #startTimeField="ngModel"
                        matInput
                        timeOfDayInput
                        required>
                    <mat-error *ngIf="this.startTimeField.hasError('required')">Start time is required.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-label>End Time</mat-label>
                    <input type="time"
                        [(ngModel)]="this.preferences.company.business_hours.end"
                        #endTimeField="ngModel"
                        name="endTimeField"
                        matInput
                        timeOfDayInput
                        required>
                    <mat-error *ngIf="this.endTimeField.hasError('required')">End time is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Rental Request Minimum</mat-label>
                <input type="text"
                    [(ngModel)]="this.preferences.company.rental_request_minimum"
                    #requestMinField="ngModel"
                    name="requestMinField"
                    matInput
                    currencyInput
                    minNum="0"
                    required>
                <mat-error *ngIf="this.requestMinField.hasError('required')">Rental request minimum is required.</mat-error>
                <mat-error *ngIf="this.requestMinField.hasError('minNum')">Rental request minimum must be at least $ 0.00.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-slide-toggle [(ngModel)]="this.preferences.company.sms_enabled" 
                name="smsEnabledField"
                fxFlexAlign="center">
                    SMS
            </mat-slide-toggle>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner mode="indeterminate" *ngIf="this.loading" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>
