import { BaseModel } from './Base.model';
import { Discardable } from './mixins/Discardable.mixin'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from '../services/app-injector.service';
import { RouteMap } from '../services/network/route-map.service';

const MixinBase = Discardable( BaseModel );
export class LineItemDefinition extends MixinBase {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id: string = null;
    @dto() department_id: string = null;
    @dto() name: string = null;
    @dto() code: string = null;
    @dto() tags: string = null;
    @dto() description: string = null;
    @dto() quantity: number = null;
    @dto() rate: number = null;
    @dto() disabled: boolean = false;


    static async get(id: string): Promise<LineItemDefinition> 
    {
        const response = await AppInjector.get(RouteMap).getLineItemDefinition(id);
        return new LineItemDefinition( response.data() );
    }

    async save(): Promise<LineItemDefinition> 
    {
        const response = this.exists()
            ? await this.routeMap.updateLineItemDefinition( this.id, this.flush() )
            : await this.routeMap.createLineItemDefinition( this.flush() );

        return this.map( response.data() );
    }

    async discard(): Promise<LineItemDefinition> 
    {
        const response = await this.routeMap.discardLineItemDefinition( this.id );
        return this.map( response.data() );
    }

    async reload(): Promise<LineItemDefinition>
    {
        const response = await this.routeMap.getLineItemDefinition(this.id);

        return this.map( response.data() );
    }
}
