import { ApplicationRef, ComponentFactoryResolver, ElementRef, Injectable, Injector } from '@angular/core';
import { interval } from 'rxjs';
import { RouteMap } from './network/route-map.service';
import { env } from '../services/env.service';
import { VersionUpdateBarComponent } from '@beaconlite/components/version-update-bar/version-update-bar.component';
import { EmbeddedViewRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(
    protected routeMap: RouteMap,
    protected injector: Injector,
    protected resolver: ComponentFactoryResolver,
    protected appRef: ApplicationRef,
  ) { }
  
  async startPolling()
  {
    const versionInterval = interval(60000).subscribe(async () => 
    {
      const response = await this.routeMap.getVersion();

      const version = env('APP_VERSION');
      const requiredVersion = response.data();

      const ok = this._versionOk(version, requiredVersion);

      if (! ok)
      {
        this._showUpdateBar();
        versionInterval.unsubscribe();
      }
    });
  }

  protected _versionOk(version: string, requiredVersion: string): boolean
  {
    const versionLayers = version.split('.');
    const requiredLayers = requiredVersion.split('.');

    for (let i=0; i< versionLayers.length; i++)
    {
      if ( parseInt(versionLayers[i]) < parseInt(requiredLayers[i]) )
      {
          return false;
      }
    }

    return true;
  }

  protected _showUpdateBar()
  {
    // Check if bar is already shown
    const updateBar = document.querySelector('app-version-update-bar');

    if (updateBar) return;

    const componentRef = this.resolver
        .resolveComponentFactory(VersionUpdateBarComponent)
        .create(this.injector);
    
    this.appRef.attachView(componentRef.hostView);
    
    const domElem = (componentRef.hostView as EmbeddedViewRef<any> )
      .rootNodes[0] as HTMLElement;

      document.body.prepend(domElem)
  }
}
