<div class="shift-timers" [class.punched-in]="this.isStarted">
    <div class="timers">
        <div class="timer shift-timer" *ngIf="this.startTime">
            <div class="timer-label">Shift</div>
            <span class="timer-text">
                <span class="timer-digits">{{ this.shifttimer.hours }}</span>:<span class="timer-digits">{{ this.shifttimer.minutes }}</span>:<span class="timer-digits">{{ this.shifttimer.seconds }}</span>
            </span>
        </div>
        <div class="timer task-timer">
            <div class="timer-label capitalize" *ngIf="this.task">{{ this.task }}</div>
            <div class="timer-label" *ngIf="!this.task">Task</div>
            <span class="timer-text">
                <span class="timer-digits">{{ this.punchtimer.hours }}</span>:<span class="timer-digits">{{ this.punchtimer.minutes }}</span>:<span class="timer-digits">{{ this.punchtimer.seconds }}</span>
            </span>
        </div>
    </div>
</div>
