import { BaseModel } from "./Base.model";
import { RequestItem } from "./contracts/RequestItem.interface";
import { LineItemDefinition } from "./LineItemDefinition.model";
import { dto } from './mixins/Dto.decorators'

export class LineItem extends BaseModel implements RequestItem {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() line_item_request_id?: string = null;
    @dto() line_item_definition_id?: string = null;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() name?: string = null;
    @dto() detail?: string = null;
    @dto() code?: string = null;
    @dto() quantity?: number = 0;
    @dto() rate?: number = 0;
    @dto() rate_override?: number = 0;
    @dto() position?: number = 0;

    duplicate(): LineItem
    {
        let duplicate = new LineItem(this.flushAttributes());

        duplicate.id                    = null;
        duplicate.locked                = false;
        duplicate.invoiced              = false;
        duplicate.created_at            = null;
        duplicate.updated_at            = null;

        return duplicate;
    }

    resetValues(): void 
    {
        this.map(new LineItem({ id: this.id, quantity: this.quantity }));
    }

    fillFromDefinition(definition: LineItemDefinition): void 
    {
        this.line_item_definition_id = definition.id;
        this.name = definition.name;
        this.detail = definition.description;
        this.code = definition.code;
        this.rate = definition.rate;
        this.rate_override = definition.rate;
    }
}
