import { Component, Inject, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentUploadData } from './document-upload-data.interface';
import { DocumentUploadService } from './document-upload.service';
import { Document, Attachment } from '@beaconlite/models';
import { FileItem, FileUploader } from 'ng2-file-upload';
@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentUploadComponent implements OnInit {

  associables = this.data.associables;
  documentableModel = this.data.documentableModel;
  onUpdate = this.data.onDocumentUpdate;
  
  uploadedFiles = [];
  documents = [];
  associates = [];
  availableItems = [];
  uploader: FileUploader;

  selectedTab = 0;

  sending = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DocumentUploadData,
    public dialogRef: MatDialogRef<DocumentUploadService>,
  ) { }

  ngOnInit(): void 
  {
    if (!!this.associables && !!this.associables.length)
    {
      this.availableItems = this._filterAssociated();
    }

    this.onInitFileUploader();
  }

  onInitFileUploader()
  {
    this.uploader = new FileUploader({
      maxFileSize: 1024 * 1024 * 256, // 256MB
      queueLimit: 10,
      method: 'PUT',
      disableMultipart: true,
    });

    // Don't add duplicate files to uploader
    this.uploader.onAfterAddingFile = item => 
    {
      item.remove();

      if (this.uploader.queue.filter(f => f.file.name === item.file.name && f.file.size === item.file.size).length == 0) 
      {
        item.withCredentials = false;
        this.uploader.queue.push(item);
      }
    }
  }

  protected _filterAssociated()
  {
    return this.associables.filter(doc => 
      this.documentableModel.documents.findIndex(existingDoc => existingDoc.id == doc.id) < 0
    )
    .map(doc => ({ document: doc, selected: false }))
  }

  onAssociationChange()
  {
    this.associates = this.availableItems.filter(item => item.selected == true)
      .map(item => item.document);
  }

  // TODO: this is currently uploading docs synchronously, should be async
  onFileAdded()
  {
    this.uploader.getNotUploadedItems().forEach(async item => {
      let doc = new Document();
      doc.attachment = new Attachment();

      const urlData = await Document.getUploadUrl();

      doc.attachment.temps.file = item.file;
      doc.attachment.name = item.file.name;
      doc.attachment.filename = item.file.name;
      doc.attachment.key = urlData.key;
      
      item.url = urlData.url;
      item.headers = [{ name: 'Content-Type', value: item.file.type }]
      item.upload();
      
      this.documents.push(doc);
    });    

  }

  async save(): Promise<void>
  {
    this.sending = true;

    let deferrals = [];

    if (!! this.documents.length)
    {
      deferrals.push( this.documentableModel.addDocuments( this.documents ) )
    }

    if (!! this.associates.length)
    {
      deferrals.push( this.documentableModel.linkDocuments( this.associates ) )
    }

    try
    {
      await Promise.all(deferrals)
      await this.onUpdate();
      this.dialogRef.close();
    }
    finally
    {
      this.sending = false;
    }
  }

  onFileRemoved(item: FileItem)
  {
    const index = this.uploader.queue.indexOf(item);
    this.documents.splice(index, 1);
    
    item.cancel();
    item.remove();
    
  }
}
  

