import { Component, Input, OnInit } from '@angular/core';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { StateService } from '@uirouter/core';
import { Department, ServiceDefinition, VariantService } from '@beaconlite/models';
import { AppData } from '../../../../services/data/shared-data.departments';
import { orderBy } from '../../../../utilities/Sort.utility';
import { VariantServicePropertyDefinition } from '@beaconlite/models/VariantServicePropertyDefinition.model';
import { ServiceDefinitionEditorService } from '../service-definition-editor/service-definition-editor.component';
import { VariantConfigurationData } from '@beaconlite/components/variants/variant-configuration/variant-configuration.component';

@Component({
  selector: 'app-service-single',
  templateUrl: './service-single.component.html',
  styleUrls: ['./service-single.component.scss']
})
export class ServiceSingleComponent implements OnInit {

  @Input() modelId: string;

  loading = false;
  locked = false;
  original = new ServiceDefinition();
  departments: Department[];
  departmentName: string;

  readonly PRICING_HOUR = ServiceDefinition.PRICING_HOUR;
  readonly PRICING_UNIT = ServiceDefinition.PRICING_UNIT;
  readonly PRICING_MANUAL = ServiceDefinition.PRICING_MANUAL;

  constructor(
    protected appData: AppData,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
    protected $state: StateService,
    protected serviceDefinitionEditorService: ServiceDefinitionEditorService,
  ) { }

  async ngOnInit(): Promise<void> 
  {    
    this.lockAndLoad();

    try
    {
      this.original = await ServiceDefinition.get(this.modelId);
      await this.original.loadVariants();

      if (this._shouldSetThumbnail()) 
      {
        await this._setThumbnailUrl();
      }
      
      // Get all departments for the service definition editor.
      this.departments = await this.appData.departments.getAll();
      this.departments?.sort(orderBy('name'));
      this.departmentName = this._getDepartmentName();
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.original.discarded;
  }
  
  private _shouldSetThumbnail(): boolean
  {
    return !this.original.discarded && !this.original.variants_enabled;
  }
z
  protected async _setThumbnailUrl(): Promise<void>
  {
    if (this.original.thumbnail === null) { return; }

    await this.original.thumbnail.small.getUrl();                
  }

  protected _getDepartmentName(): string 
  {
    return this.departments.find(({ id }) => id === this.original.department_id)['name'];
  }

  async edit(): Promise<void>
  {
    await this.serviceDefinitionEditorService.open({
      original: this.original,
      departments: this.departments,
      onUpdate: this.updateServiceDefinition
    });
  }

  updateServiceDefinition = async (service): Promise<void> =>
  {
    await service.save();
    this.original = service.copy();
    if (this. _shouldSetThumbnail())
    {
      await this._setThumbnailUrl();
    }
  }

  async discard(): Promise<void>
  {
    if (! await this.dialogNotification.discardConfirm()) { return; }

    this.lockAndLoad();

    try
    {
      await this.original.discard();
      this.$state.go('protected.services');
    }
    finally
    {
      this.unlockAndUnload();
    }
  }
  
  onVariantConfigUpdate = async (variantConfiguration: VariantConfigurationData): Promise<void> =>
  {
    this.lockAndLoad();

    try
    {
      this.original.variants_enabled = variantConfiguration.variantsEnabled;
      this.original.variant_rates_enabled = variantConfiguration.rateVariantsEnabled;
      await this.original.save();
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  onPropUpdate = async (updatedProp: VariantServicePropertyDefinition) =>  
  {
    await updatedProp.save();
    await this._reloadOriginal();
  }

  onPropRemove = async (prop: VariantServicePropertyDefinition) =>  
  {
    await prop.discard();
    await this._reloadOriginal();
  }

  onVariantUpdate = async (updatedVariant: VariantService) =>
  {
    await updatedVariant.save();
    await this._reloadOriginal();
  }

  onVariantRemove = async (variant: VariantService) =>
  {
    await variant.discard();
    await this._reloadOriginal();
  }

  protected async _reloadOriginal(): Promise<void>
  {
    await this.original.reload();
    await this.original.loadVariants();
  }
}
