import { Ng2StateDeclaration } from '@uirouter/angular';
import { DispatchedRequestComponent } from './dispatched-request.component';

export const DispatchedRequestReportState: Ng2StateDeclaration = {
  name: 'protected.reports.dispatched-request',
  url: '/dispatched-request',
  component: DispatchedRequestComponent,
  data: { 
    pageTitle: 'Dispatched Item Report', 
    requiresAuth: true,
  },
}
