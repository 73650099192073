import { Injector, NgModule } from '@angular/core';
import { ResolvableLiteral, Transition, UIRouter, UIRouterModule } from '@uirouter/angular';
import { APP_STATES } from './app.states';
import { registerAuthHook, registerLoginHook, registerTitleHook } from './services/auth/auth.hooks';
import { LoginState } from './views/public/login/login.state';
import { NotFoundState } from './views/public/not-found/not-found.state';

function uiRouterConfigFn( router: UIRouter, injector: Injector )
{
    registerTitleHook( router.transitionService );
    registerAuthHook( router.transitionService );
    registerLoginHook( router.transitionService );

    router.urlService.rules.initial( { state: LoginState } );
    router.urlService.rules.otherwise( { state: NotFoundState } );
}

@NgModule( {
    imports: [
        UIRouterModule.forRoot( {
            states: APP_STATES,
            useHash: false,
            config: uiRouterConfigFn
        } ),
    ],
    declarations: [
    ],
    exports: [
        UIRouterModule
    ]
} )
export class RoutingModule { }


export class ResolvableModelId implements ResolvableLiteral
{
    token = 'modelId';
    deps = [ Transition ];
    resolveFn = ( transition: Transition ) => transition.params().modelId
}
