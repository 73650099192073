import { VariantProperty } from "./VariantProperty.model";
import { dto } from "./mixins/Dto.decorators";
import { VariantRentalPropertyDefinition } from "./VariantRentalPropertyDefinition.model";

export class VariantRentalProperty extends VariantProperty {

    constructor(attributes: object = {}) 
    {
        super();
        this.init(attributes);
    }

    @dto(VariantRentalPropertyDefinition) definition: VariantRentalPropertyDefinition = null;
}
