<div fxLayout="column">
<input class="file-selector" 
    type="file"
    #fileSelector 
    ng2FileSelect 
    multiple
    (onFileSelected)="this.onFileSelected()"
    [uploader]="uploader">
<div class="file-dropzone file-dropzone--dragover" fxLayout="column" fxLayoutAlign="center center"
    ng2FileDrop
    (fileOver)="this.isOverDropzone = $event"
    [class.file-dropzone--dragover]="this.isOverDropzone"
    (onFileDrop)="this.onFileSelected()"
    (click)="this.fileSelector.click()"
    [uploader]="this.uploader">
    <div>Drop files here or click to upload</div>
    <span class="mat-caption">{{ this.uploader.queue.length }} / 10 files selected <br>Maximum file size: 256MB </span>
</div>

<mat-list fxFlex fxLayout="column">
    <mat-list-item class="mat-list-item--divided" fxFlex *ngFor="let item of this.uploader.queue" fxLayoutAlign="space-between center">
        <span>{{ item.file.name }}</span>
        <span fxFlex></span>
        <div fxLayout="row" fxLayoutAlign="end" [fxShow]="item.isUploading">
            <span>{{ item.progress }}%&nbsp;</span>
            <mat-progress-spinner color="accent" mode="determinate" diameter="24" [value]="item.progress" [fxShow]="item.progress > 0"></mat-progress-spinner>
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="24" [fxHide]="item.progress > 0"></mat-progress-spinner>
        </div>
        <button mat-icon-button [fxShow]="item.isUploaded" color="primary" (click)="this.onFileRemoved(item)">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-list-item>
</mat-list>
</div>