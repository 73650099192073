import { Component, Input } from '@angular/core';
import { RentalDefinition, ServiceDefinition, Variant, VariantProperty, VariantPropertyDefinition } from '@beaconlite/models';
import { VariantDefinition } from '@beaconlite/models/contracts/VariantDefinition.interface';
import { VariantEditorService } from '../variant-editor/variant-editor.component';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-variant-list',
  templateUrl: './variant-list.component.html',
  styleUrls: ['./variant-list.component.scss'],
})
export class VariantListComponent {

  @Input() itemDefinition: VariantDefinition;
  @Input() onVariantUpdate: (variant: Variant) => Promise<void>;
  @Input() onVariantRemove: (variant: Variant) => Promise<void>;

  keywordFilter = '';

  constructor(
    protected editor: VariantEditorService
  ){}

  formatAttributeLabel(field: string): string
  {
    return field.replace(/_/g, ' ');
  }

  getPropFromDefinition(def: VariantPropertyDefinition, variant: Variant): VariantProperty
  {
    return variant.properties.find(
      (prop: VariantProperty) => prop.variant_property_definition_id === def.id
    );
  }

  getOptionFromDefinition(def: VariantPropertyDefinition, variant: Variant)
  {
    const id: string = this.getPropFromDefinition(def, variant)?.value;
    if ( !id ) { return; }
    return def.options.find(option => option.id === id);
  }

  shouldDisplayQuantity(): boolean
  {
    return this.itemDefinition instanceof RentalDefinition;
  }

  filteredVariants(): Variant[]
  {
    return this.itemDefinition.variants.filter(variant => {
      const keyword = this.keywordFilter.toLowerCase();

      return variant.name?.toLowerCase().includes(keyword) ||  
        variant.description?.toLowerCase().includes(keyword) ||
        variant.code?.toLowerCase().includes(keyword) ||
        variant.tags?.toLowerCase().includes(keyword)
    }).sort(orderBy('name'));
  }
  
  async onAdd(): Promise<void>
  { 
    await this.onEdit( this.itemDefinition.newVariant() );
  }

  async onEdit(variant: Variant): Promise<void>
  {
    await this.editor.open({
      original: variant, 
      itemDefinition: this.itemDefinition,
      onVariantUpdate: this.onVariantUpdate,
      onVariantRemove: this.onVariantRemove,
    });
  }

  getPriceTypeLabel(priceType): string
  {
    return this.itemDefinition.getPriceTypeLabel(priceType);
  }
}
