import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['../public.scss'],
})
export class ForgotComponent implements OnInit {
	pending: boolean = false;
	resetWasSent: boolean = false;
	company: any;
	user: any = {};
	@ViewChild('requestResetForm') requestResetForm: NgForm;


	constructor(
		protected authService: AuthenticationService,
		protected $settings: SettingsService,
	) {}

	ngOnInit(): void {
		console.debug('[View:RequestReset] controller loaded.'); 

		this.company = this.$settings.get('company_config');

		// this.requestResetForm = this.fb.group({
		// 	username: ['', [Validators.required, Validators.email]]
		// });
	}

	get form() {
		return this.requestResetForm.controls;
	}

	async onRequestReset(): Promise<any> {
		if (this.requestResetForm.invalid) return;

		this.pending = true;

		try
		{
			await this.authService.requestPasswordReset(this.form.username.value);
			this.resetWasSent = true;
		}
		catch (response)
		{
			this.form.username.setErrors({failed: true});
			
			console.error('[View:ForgotPassword] login failed');
		}
		finally
		{
			this.pending = false;
		}
	}
}
