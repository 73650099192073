import { Component, Input, OnInit } from '@angular/core';
import { InvoiceItemsReport } from '@beaconlite/models/reports/InvoiceItems.model';

@Component({
  selector: 'invoiced-items-invoice-row',
  templateUrl: './invoiced-items-invoice-row.component.html',
})
export class InvoicedItemsInvoiceRowComponent implements OnInit {

  @Input() report: InvoiceItemsReport;
  @Input() items: InvoiceItemsReport[];
  @Input() layer: string;

  nestedLayer = '';
  tableColumns = ['serial', 'issued', 'branch', 'voided', 'total'];

  constructor() { }
  ngOnInit(): void {}

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: InvoiceItemsReport): InvoiceItemsReport[]|null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }

}
