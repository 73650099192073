<!-- INVOICE PREVIEW PICKER  -->
<form class="mat-dialog-editor" #editorForm (ngSubmit)="onPreview()" novalidate *ngIf="!this.invoice && !this.loading">
    <mat-dialog-content class="dialog-padding">
        <div fxLayout="column" *ngIf="this.isInvoiceType">
            <h2 class="mat-title">New Invoice</h2>
            <p>Invoice period starts {{ this.minimumCustomEndDate / 1000 | dateFilter:'dateMedium' }}</p>
        </div>

        <div fxLayout="column" *ngIf="!this.isInvoiceType">
            <h2 class="mat-title">New Estimate/Quote</h2>
            <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="this.type"
                    name="typeField"
                    #typeField="ngModel"
                    required>
                    <mat-option value="estimate">Estimate</mat-option>
                    <mat-option value="quote">Quote</mat-option>
                </mat-select>
                <mat-error *ngIf="typeField.hasError('required')">Type is required.</mat-error>
            </mat-form-field>
            <p>Period starts {{ this.minimumCustomEndDate / 1000 | dateFilter:'dateMedium' }}</p>
        </div>

        <div class="date-picker-row" fxLayout="row" fxLayoutGap="20px">                
            <mat-form-field fxFlex="50">
                <mat-label>End Date</mat-label>
                <input [matDatepicker]="endDatePicker" 
                    [(ngModel)]="this.customEndDate" 
                    name="endDateField"
                    #endDateField="ngModel"
                    [min]="this.minimumCustomEndDate"
                    matInput>
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                <mat-error *ngIf="endDateField.hasError('matDatepickerMin')">End date is too early.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>Issue Date</mat-label>
                <input [matDatepicker]="issueDatePicker" 
                    [(ngModel)]="this.issueDate" 
                    name="issueDateField"
                    matInput>
                <mat-datepicker-toggle matSuffix [for]="issueDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #issueDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.processing">
            Preview
        </button>
    </mat-dialog-actions>
</form>


<!-- PREVIEW INVOICE -->
<form class="mat-dialog-editor" #editorForm (ngSubmit)="this.create()" *ngIf="!this.invoice?.exists() && (this.invoice || this.loading)" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!this.invoice?.exists() && !this.loading">
        <h2 class="mat-title">Preview</h2>
    </mat-toolbar>

    <mat-dialog-content *ngIf="this.invoice || this.loading">
        <app-invoice [invoice]="this.invoice" [loaded]="this.loaded"></app-invoice>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" *ngIf="!this.invoice?.exists() && (this.invoice || this.loading)">
        <button type="button" 
            mat-raised-button 
            mat-dialog-close>
            Cancel
        </button>
        <button type="submit"
            mat-raised-button 
            *ngIf="this.canCreate()"
            [disabled]="this.workOrder.suspended_at"
            color="accent">
            Create
        </button>
    </mat-dialog-actions>
</form>

<!-- EXISTING INVOICE -->
<form class="mat-dialog-editor" #editorForm *ngIf="this.invoice?.exists()" novalidate>
    <mat-toolbar>
        <h2 fxFlex>{{ this.invoice.formatted_serial_id }}</h2>

        <button mat-icon-button (click)="this.onPrint()">
            <mat-icon color="primary">print</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="invoiceMenu">
            <mat-icon color="primary">more_vert</mat-icon>
        </button>
        <mat-menu #invoiceMenu="matMenu">
            <button mat-menu-item 
                *ngIf="this.isInvoiceType"
                [disabled]="!this.invoice.canSend() || this.sending"
                (click)="this.onSend()">
                <mat-icon>send</mat-icon>
                {{ !!this.invoice.sent_at ? "Resend" : "Send" }} 
            </button>
            <button mat-menu-item 
                *ngIf="this.isInvoiceType && this.invoice.canMarkSent()"
                [disabled]="this.sending"
                (click)="this.onMarkSent()">
                <mat-icon>arrow_circle_right</mat-icon>
                Mark Sent
            </button>
            <button mat-menu-item 
                *ngIf="this.invoice.sent_at"
                (click)="this.onShowEmailHistory()">
                <mat-icon>dynamic_feed</mat-icon>
                Email History
            </button>
            <button mat-menu-item (click)="this.onPrint()">
                <mat-icon>print</mat-icon>
                Print
            </button>
            <button mat-menu-item 
                *ngIf="this.isInvoiceType"
                (click)="this.onDownload()">
                <mat-icon>cloud_download</mat-icon>
                Download as PDF
            </button>
            <button mat-menu-item 
                *ngIf="this.isInvoiceType"
                [disabled]="!this.invoice.canVoid()"
                (click)="this.onVoid()">
                <mat-icon>highlight_off</mat-icon>
                Void
            </button>
            <button mat-menu-item 
                [disabled]="!this.invoice.canDiscard()"
                (click)="this.onDiscard()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
            <button mat-menu-item [mat-dialog-close]>
                <mat-icon>close</mat-icon>
                Close
            </button>
        </mat-menu>
    </mat-toolbar>

    <mat-dialog-content *ngIf="this.invoice || this.loading">
        <app-invoice [invoice]="this.invoice" [loaded]="this.loaded"></app-invoice>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" *ngIf="this.invoice?.exists()">
        <mat-progress-spinner *ngIf="sending" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button [mat-dialog-close] [disabled]="this.sending">Ok</button>
    </mat-dialog-actions>
</form>
