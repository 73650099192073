import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { AuthRouteMapService } from './auth-route-map.service';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {
  protected _promise: Promise<AccessToken>;
  protected _token: AccessToken;

  constructor(protected authRouteMap: AuthRouteMapService) {}

  async initialize(): Promise<AccessToken>
  {
    try
    {
      return await this.retrieveToken();
    }
    catch
    {
      try
      {
        return await this.refreshToken();
      }
      catch
      {
        // Lazy null object pattern
        this._token = new AccessToken();
        return this._token;
      }
    }
  }

  /**
   * Verify if the service has a valid access token present.
   *
   * @returns {boolean}
   */
  hasValidToken(): boolean
  {
    return !!this._token && !this._token.expired();
  }

  /**
   * Request a new access token. Authentication.
   *
   * @param {string} username
   * @param {string} password
   * @returns {Promise<AccessToken>}
   */
  async requestToken(username: string, password: string): Promise<AccessToken> 
  {
    this._promise = this.authRouteMap.requestAccessToken(username, password)
      .then((response: any) => 
      {
       this._token = new AccessToken(response.data());
       return this._token;
      })
      .finally(() => this._promise = null );

    return this._promise;
  }

  /**
   * Refresh the current access token. Re-authentication.
   *
   * @returns {Promise}
   */
  async refreshToken(): Promise<AccessToken> 
  {
    this._promise = this.authRouteMap.refreshAccessToken()
      .then((response: any) => 
      {
        this._token = new AccessToken( response.data());
        return this._token;
      })
      .finally(() => this._promise = null );

    return this._promise;
  }

  /**
   * Revoke the current access token. De-authentication.
   *
   * @returns {Promise}
   */
  async revokeToken(): Promise<any> 
  {
    return this.authRouteMap.revokeAccessToken()
      .then( () => 
      {
        this._promise = null
        this._token = null;
        return true;
      })
  }

  /**
   * Get the access token through a promise.
   * Promise will resolve immediately if there is already a valid access token or it will attempt to refresh it.
   *
   * @returns {Promise}
   */
  async getToken(): Promise<AccessToken> {
    // Return pending token request's promise if there is one
    if ( !!this._promise )
    {
      return this._promise
    }  

    // If token appears to be invalid, almost expired, or expired, then attempt to refresh it
    if (this._token === null || 
        this._token?.stale() )
    {
      return this.refreshToken();
    }

    return Promise.resolve( this._token );
  }

  /**
   * Retrieve the access token from the backend session.
   * NOTE: Should only be used when initializing the TokenManager.
   *
   * @returns {Promise}
   */  
  protected async retrieveToken(): Promise<AccessToken> 
  {
    this._promise = this.authRouteMap.retrieveAccessToken()
      .then((response: any) =>
      { 
        this._token = new AccessToken(response.data());
        return this._token;
      })
      .finally(() => { this._promise = null });

    return this._promise;
  }
}

/**
 * AccessToken utility class for interacting with access token data.
 *
 * @class AccessToken
 */
export class AccessToken 
{
  _token: string;
  _expiresAt: number;

/**
 *Creates an instance of AccessToken.
 * @param {{access_token: string, expires_at: number}} data
 */
constructor(data?: {access_token: string, expires_at: number})
  {
    if (!! data)
    {
      this._token = data.access_token;
      this._expiresAt = data.expires_at;
    }
    else
    {
      this._token = '';
      this._expiresAt = 0;
    }
  }

  /**
   * Verify if the current access token object appears to have a valid value.
   *
   * @returns {boolean}
   */
  exists(): boolean
  {
    return this._token.length > 0;
  }

  /**
   * Verify if the current access token object is close to expiring.
   *
   * @returns {boolean}
   */
  stale(): boolean
  {
    if ( !this.exists() ) { return true; }

    const expires = moment.unix(this._expiresAt).subtract(5, 'minutes');
    const now = moment();

    return expires.isValid() && expires.isSameOrBefore(now);
  }

  /**
   * Verify if the current access token object is expired.
   *
   * @returns {boolean}
   */
  expired(): boolean
  {
    if ( !this.exists() )
    {
      return true;
    }

    const expires = moment.unix( this._expiresAt );
    const now = moment();

    return expires.isValid() && expires.isSameOrBefore(now);
  }

  /**
   * Verify if the current access token object is valid.
   *
   * @returns {boolean}
   */ 
  valid(): boolean
  {
    return this.exists() && !this.expired();
  }

  /**
   * Get the current access token's value.
   *
   * @returns {String}
   */
  value(): string
  {
    return this._token;
  }

  /**
   * Return the string representation of the token
   *
   * @returns {String}
   */
  toString(): string 
  {
    return this.value();
  }
}
