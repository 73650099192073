import { AppComponent } from './app.component'
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NotificationModule } from './notification.module';
import { AppInjector } from './services/app-injector.service';
import { ProtectedViewComponent } from './views/protected/protected-view.component';
import { SharedModule } from './shared.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { WorkOrderEditorComponent } from './views/protected/work-orders/work-order-single/tab-main/work-order-editor/work-order-editor.component';
import { ServiceEditorComponent } from './views/protected/work-orders/work-order-single/tab-services/service-editor/service-editor.component';
import { RentalRequestEditorComponent } from './views/protected/work-orders/work-order-single/tab-rentals/rental-request-editor/rental-request-editor.component';
import { TabMainComponent } from './views/protected/work-orders/work-order-single/tab-main/tab-main.component';
import { WorkOrderSingleComponent } from './views/protected/work-orders/work-order-single/work-order-single.component';
import { ChargeEditorComponent } from './components/editors/charge-editor/charge-editor.component';
import { RentalRequestComponent } from './views/protected/work-orders/work-order-single/tab-rentals/rental-request/rental-request.component';
import { ServiceComponent } from './views/protected/work-orders/work-order-single/tab-services/service/service.component';
import { NoteEditorComponent } from './components/editors/note-editor/note-editor.component';
import { ReturnSheetEditorComponent } from './views/protected/work-orders/work-order-single/tab-rentals/return-sheet-editor/return-sheet-editor.component';
import { DispatchEditorComponent } from './views/protected/work-orders/work-order-single/tab-dispatches/dispatch-editor/dispatch-editor.component';
import { EmailEditorComponent } from './components/editors/email-editor/email-editor.component';
import { RentalQuantitiesEditorComponent } from './views/protected/work-orders/work-order-single/tab-dispatches/rental-quantities-editor/rental-quantities-editor.component';
import { ServiceQuantitiesEditorComponent } from './views/protected/work-orders/work-order-single/tab-dispatches/service-quantities-editor/service-quantities-editor.component';
import { NotesTableComponent } from './components/shared/notes-table/notes-table.component';
import { ChargesTableComponent } from './components/shared/charges-table/charges-table.component';
import { InvoiceEditorComponent } from './components/editors/invoice-editor/invoice-editor.component';
import { ReasonEditorComponent } from './components/editors/reason-editor/reason-editor.component';
import { IssueEditorComponent } from './views/protected/work-orders/work-order-single/tab-main/issue-editor/issue-editor.component';
import { IssueComponent } from './views/protected/work-orders/work-order-single/tab-main/issue/issue.component';
import { TabRentalsComponent } from './views/protected/work-orders/work-order-single/tab-rentals/tab-rentals.component';
import { TabServicesComponent } from './views/protected/work-orders/work-order-single/tab-services/tab-services.component';
import { TabDispatchesComponent } from './views/protected/work-orders/work-order-single/tab-dispatches/tab-dispatches.component';
import { TabInvoicesComponent } from './views/protected/work-orders/work-order-single/tab-invoices/tab-invoices.component';
import { TabQuotesComponent } from './views/protected/work-orders/work-order-single/tab-quotes/tab-quotes.component';
import { WorkOrderIndexComponent } from './views/protected/work-orders/work-order-index/work-order-index.component';
import { ClientIndexComponent } from './views/protected/clients/client-index/client-index.component';
import { ClientSingleComponent } from './views/protected/clients/client-single/client-single.component';
import { ClientAccountEditorComponent } from './views/protected/clients/client-account-editor/client-account-editor.component';
import { RentalOverrideEditorComponent } from './components/rental-overrides/rental-override-editor/rental-override-editor.component';
import { ServiceOverrideEditorComponent } from './components/service-overrides/service-override-editor/service-override-editor.component';
import { RentalIndexComponent } from './views/protected/rentals/rental-index/rental-index.component';
import { RentalSingleComponent } from './views/protected/rentals/rental-single/rental-single.component';
import { ServiceSingleComponent } from './views/protected/services/service-single/service-single.component';
import { ServiceIndexComponent } from './views/protected/services/service-index/service-index.component';
import { ChargeIndexComponent } from './views/protected/charges/charge-index/charge-index.component';
import { ChargeSingleComponent } from './views/protected/charges/charge-single/charge-single.component';
import { EmployeeSingleComponent } from './views/protected/employees/employee-single/employee-single.component';
import { EmployeeIndexComponent } from './views/protected/employees/employee-index/employee-index.component';
import { SignatureCaptureEditorComponent } from './components/editors/signature-capture-editor/signature-capture-editor.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SmsNotificationsComponent } from './views/protected/work-orders/work-order-single/tab-dispatches/sms-notifications/sms-notifications.component';
import { GeneralSettingsComponent } from './views/protected/settings/general-settings/general-settings.component';
import { AccountingSettingsEditorComponent } from './views/protected/settings/general-settings/accounting-settings-editor/accounting-settings-editor.component';
import { BranchSettingsIndexComponent } from './views/protected/settings/branch-settings/branch-settings-index/branch-settings-index.component';
import { BranchSettingsSingleComponent } from './views/protected/settings/branch-settings/branch-settings-single/branch-settings-single.component';
import { RentalOverridesComponent } from './components/rental-overrides/rental-overrides.component';
import { ServiceOverridesComponent } from './components/service-overrides/service-overrides.component';
import { UserDispatchesIndexComponent } from './views/protected/user-dispatches/user-dispatches-index/user-dispatches-index.component';
import { DispatchSlipSingleComponent } from './components/dispatch-slip-single/dispatch-slip-single.component';
import { DispatchSlipComponent } from './components/dispatch-slip/dispatch-slip.component';
import { DispatchSlipRequestEditorComponent } from './components/dispatch-slip/dispatch-slip-request-editor/dispatch-slip-request-editor.component';
import { DispatchSlipServiceEditorComponent } from './components/dispatch-slip/dispatch-slip-service-editor/dispatch-slip-service-editor.component';
import { PunchButtonComponent } from './components/dispatch-slip/punch-button/punch-button.component';
import { TaskTimerComponent } from './components/dispatch-slip/task-timer/task-timer.component';
import { UpcomingDispatchesComponent } from './views/protected/upcoming-dispatches/upcoming-dispatches/upcoming-dispatches.component';
import { InvoiceIndexComponent } from './views/protected/invoices/invoice-index/invoice-index.component';
import { InvoiceSingleComponent } from './views/protected/invoices/invoice-single/invoice-single.component';
import { InvoiceScheduleComponent } from './views/protected/invoices/invoice-schedule/invoice-schedule.component';
import { QuickbooksSettingsComponent } from './views/protected/settings/accounting-settings/quickbooks-settings/quickbooks-settings.component';
import { IntegrationTaxGroupEditorComponent } from './views/protected/settings/accounting-settings/integration-tax-group-editor/integration-tax-group-editor.component';
import { IntegrationBranchDefaultsEditorComponent } from './views/protected/settings/accounting-settings/integration-branch-defaults-editor/integration-branch-defaults-editor.component';
import { IntegrationBranchOverrideEditorComponent } from './views/protected/settings/accounting-settings/integration-branch-override-editor/integration-branch-override-editor.component';
import { OnboardingHelperComponent } from './views/protected/settings/accounting-settings/quickbooks-settings/onboarding-helper/onboarding-helper.component';
import { ReportsViewComponent } from './views/protected/reports/reports-view/reports-view.component';
import { ActiveItemComponent } from './views/protected/reports/active-item/active-item.component';
import { ActiveItemRentalRowComponent } from './views/protected/reports/active-item/active-item-rental-row/active-item-rental-row.component';
import { ActiveItemClientRowComponent } from './views/protected/reports/active-item/active-item-client-row/active-item-client-row.component';
import { ActiveItemWorkOrderRowComponent } from './views/protected/reports/active-item/active-item-work-order-row/active-item-work-order-row.component';
import { LostItemComponent } from './views/protected/reports/lost-item/lost-item.component';
import { LostItemWorkOrderRowComponent } from './views/protected/reports/lost-item/lost-item-work-order-row/lost-item-work-order-row.component';
import { LostItemClientRowComponent } from './views/protected/reports/lost-item/lost-item-client-row/lost-item-client-row.component';
import { LostItemRentalRowComponent } from './views/protected/reports/lost-item/lost-item-rental-row/lost-item-rental-row.component';
import { ServiceHoursComponent } from './views/protected/reports/service-hours/service-hours.component';
import { ServiceHoursWorkOrderRowComponent } from './views/protected/reports/service-hours/service-hours-work-order-row/service-hours-work-order-row.component';
import { ServiceHoursClientRowComponent } from './views/protected/reports/service-hours/service-hours-client-row/service-hours-client-row.component';
import { ServiceHoursServiceRowComponent } from './views/protected/reports/service-hours/service-hours-service-row/service-hours-service-row.component';
import { InvoicedItemsComponent } from './views/protected/reports/invoiced-items/invoiced-items.component';
import { InvoicedItemsClientRowComponent } from './views/protected/reports/invoiced-items/invoiced-items-client-row/invoiced-items-client-row.component';
import { InvoicedItemsInvoiceRowComponent } from './views/protected/reports/invoiced-items/invoiced-items-invoice-row/invoiced-items-invoice-row.component';
import { InvoicedItemsItemRowComponent } from './views/protected/reports/invoiced-items/invoiced-items-item-row/invoiced-items-item-row.component';
import { InvoicedItemsWorkOrderRowComponent } from './views/protected/reports/invoiced-items/invoiced-items-work-order-row/invoiced-items-work-order-row.component';
import { RentalItemComponent } from './views/protected/reports/rental-item/rental-item.component';
import { ClientRowComponent } from './views/protected/reports/rental-item/client-row/client-row.component';
import { WorkOrderRowComponent } from './views/protected/reports/rental-item/work-order-row/work-order-row.component';
import { RequestRowComponent } from './views/protected/reports/rental-item/request-row/request-row.component';
import { RentalRowComponent } from './views/protected/reports/rental-item/rental-row/rental-row.component';
import { ReportFilterEditorComponent } from './views/protected/reports/report-filter-editor/report-filter-editor.component';
import { DispatchedItemComponent } from './views/protected/reports/dispatched-item/dispatched-item.component';
import { DispatchedItemRentalRowComponent } from './views/protected/reports/dispatched-item/dispatched-item-rental-row/dispatched-item-rental-row.component';
import { DispatchedItemClientRowComponent } from './views/protected/reports/dispatched-item/dispatched-item-client-row/dispatched-item-client-row.component';
import { DispatchedItemWorkOrderRowComponent } from './views/protected/reports/dispatched-item/dispatched-item-work-order-row/dispatched-item-work-order-row.component';
import { DispatchedRequestComponent } from './views/protected/reports/dispatched-request/dispatched-request.component';
import { DispatchedRequestRequestRowComponent } from './views/protected/reports/dispatched-request/dispatched-request-request-row/dispatched-request-request-row.component';
import { DispatchedRequestClientRowComponent } from './views/protected/reports/dispatched-request/dispatched-request-client-row/dispatched-request-client-row.component';
import { DispatchedRequestWorkOrderRowComponent } from './views/protected/reports/dispatched-request/dispatched-request-work-order-row/dispatched-request-work-order-row.component';
import { DateDialogComponent } from './components/editors/date-dialog/date-dialog.component';
import { PublicModule } from './views/public/public.module';
import { PrintModule } from './views/print/print.module';
import { RoutingModule } from './routing.module';
import { DocumentUploadComponent } from './components/document/document-upload/document-upload.component';
import { TabUploadComponent } from './components/document/document-upload/tab-upload/tab-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DocumentEditorComponent } from './components/document/document-editor/document-editor.component';
import { DocumentPreviewComponent } from './components/document/document-preview/document-preview.component';
import { VersionService } from './services/version.service';
import { env } from './services/env.service';
import { VersionUpdateBarComponent } from './components/version-update-bar/version-update-bar.component';
import { TabAssociateComponent } from './components/document/document-upload/tab-associate/tab-associate.component';
import { RentalItemGraphComponent } from './views/protected/reports/rental-item/components/graph/graph.component';
import { DispatchScheduleComponent } from './views/protected/schedules/dispatches/dispatch-schedule.component';
import { GanttDispatchComponent } from './views/protected/schedules/dispatches/components/dispatch-gantt/dispatch-gantt.component';
import { EmployeeScheduleComponent } from './views/protected/schedules/employees/employee-schedule.component';
import { GanttEmployeeComponent } from './views/protected/schedules/employees/components/employee-gantt/employee-gantt.component';
import { DispatchSingleComponent } from './components/dispatch-single/dispatch-single.component';
import { DispatchComponent } from './components/dispatch/dispatch.component';
import { RentalEditorComponent } from './views/protected/work-orders/work-order-single/tab-rentals/rental-editor/rental-editor.component';
import { EmployeeScheduleSingleComponent } from './views/protected/schedules/employees/employee-single/employee-single.component';
import { EmployeeComponent } from './views/protected/schedules/employees/components/employee/employee.component';
import { DispatchEmployeeLabelComponent } from './views/protected/work-orders/work-order-single/tab-dispatches/dispatch-editor/employee-search-option/dispatch-employee-label.component';
import { VariantPropertyDefinitionEditorComponent } from './components/variants/variant-prop-editor/variant-prop-editor.component';
import { VariantEditorComponent } from './components/variants/variant-editor/variant-editor.component';
import { VariantPropListComponent } from './components/variants/variant-prop-list/variant-prop-list.component';
import { VariantListComponent } from './components/variants/variant-list/variant-list.component';
import { VariantConfigurationComponent } from './components/variants/variant-configuration/variant-configuration.component';
import { ThumbnailControlCompnent } from './components/thumnail-control/thumbnail-control.component';
import { RequestSearchOptionComponent } from './views/protected/work-orders/work-order-single/request-search-option/request-search-option.component';
import { DispatchSettingsEditorComponent } from './views/protected/settings/general-settings/dispatch-settings-editor/dispatch-settings-editor.component';
import { InvoiceSettingsEditorComponent } from './views/protected/settings/general-settings/invoice-settings-editor/invoice-settings-editor.component';
import { DiscountSettingsEditorComponent } from './views/protected/settings/general-settings/discount-settings-editor/discount-settings-editor.component';
import { DispatchSummaryComponent } from './components/dispatch-summary/dispatch-summary.component';
import { DispatchRegionEditorComponent } from './views/protected/settings/general-settings/dispatch-region-editor/dispatch-region-editor.component';
import { CompanySettingsEditorComponent } from './views/protected/settings/general-settings/company-settings-editor/company-settings-editor.component';
import { EmailHistoryComponent } from './components/email-history/email-history.component';
import { VariantConfigurationModalComponent } from './components/variants/variant-configuration-modal/variant-configuration-modal.component';
import { RentalDefinitionEditorComponent } from './views/protected/rentals/rental-definition-editor/rental-definition-editor.component';
import { ServiceDefinitionEditorComponent } from './views/protected/services/service-definition-editor/service-definition-editor.component';
import { UserDispatchesSingleComponent } from './views/protected/user-dispatches/user-dispatches-single/user-dispatches-single.component';
import { UpcomingDispatchesSingleComponent } from './views/protected/upcoming-dispatches/upcoming-dispatches-single/upcoming-dispatches-single.component';
import { DispatchScheduleSingleComponent } from './views/protected/schedules/dispatches/dispatch-schedule-single/dispatch-schedule-single.component';
import { Sage50SettingsComponent } from './views/protected/settings/accounting-settings/sage50-settings/sage50-settings.component';
import { Sage50AccountEditorComponent } from './views/protected/settings/accounting-settings/sage50-settings/sage50-account-editor/sage50-account-editor.component';
import { Sage50TaxEditorComponent } from './views/protected/settings/accounting-settings/sage50-settings/sage50-tax-editor/sage50-tax-editor.component';
import { Sage50ExportsComponent } from './views/protected/settings/accounting-settings/sage50-settings/sage50-exports/sage50-exports.component';
import { Sage50ExcludedClientEditorComponent } from './views/protected/settings/accounting-settings/sage50-settings/sage50-excluded-client-editor/sage50-excluded-client-editor.component';
import { Sage50AdditionalSettingsComponent } from './views/protected/settings/accounting-settings/sage50-settings/sage50-additional-settings-editor/sage50-additional-settings-editor.component';
import { Sage50OverrideEditorComponent } from './views/protected/settings/accounting-settings/sage50-settings/sage50-override-editor/sage50-override-editor.component';
import { RentalGroupSingleComponent } from './views/protected/rentals/rental-group-single/rental-group-single.component';
import { GroupSingleComponent } from './components/group/group-single.component';
import { GroupDefinitionEditorComponent } from './components/group/group-definition-editor/group-definition-editor.component';
import { GroupEditorItem } from './components/group/group-definition-editor/components/group-editor-item.component';
import { RentalEditorGroupItem } from './views/protected/work-orders/work-order-single/tab-rentals/rental-editor/components/rental-editor-group-item.component';
import { FlattenedViewToggleComponent } from './components/flattened-view-toggle/flattened-view-toggle.component';
import { LineItemSingleComponent } from './views/protected/line-items/line-item-single/line-item-single.component';
import { LineItemIndexComponent } from './views/protected/line-items/line-item-index/line-item-index.component';
import { LineItemDefinitionEditorComponent } from './views/protected/line-items/line-item-definition-editor/line-item-definition-editor.component';
import { TabLineItemsComponent } from './views/protected/work-orders/work-order-single/tab-line-items/tab-line-items.component';
import { LineItemRequestComponent } from './views/protected/work-orders/work-order-single/tab-line-items/line-item-request/line-item-request.component';
import { LineItemRequestEditorComponent } from './views/protected/work-orders/work-order-single/tab-line-items/line-item-request-editor/line-item-request-editor.component';
import { LineItemEditorComponent } from './views/protected/work-orders/work-order-single/tab-line-items/line-item-editor/line-item-editor.component';

@NgModule( {
  imports: [
    BrowserModule,
    HotkeyModule.forRoot(),
    HttpClientModule,
    NotificationModule,
    PrintModule,
    PublicModule,
    RoutingModule,
    SharedModule,
    SignaturePadModule,
    FileUploadModule,
  ],
  declarations: [
    AppComponent,
    ProtectedViewComponent,
    WorkOrderIndexComponent,
    WorkOrderEditorComponent,
    RentalRequestEditorComponent,
    RentalEditorComponent,
    ServiceEditorComponent,
    WorkOrderEditorComponent,
    TabMainComponent,
    WorkOrderSingleComponent,
    ChargeEditorComponent,
    TabRentalsComponent,
    RentalRequestComponent,
    TabServicesComponent,
    ServiceComponent,
    TabDispatchesComponent,
    DispatchComponent,
    TabInvoicesComponent,
    TabQuotesComponent,
    NoteEditorComponent,
    ReturnSheetEditorComponent,
    DispatchEditorComponent,
    EmailEditorComponent,
    RentalQuantitiesEditorComponent,
    ServiceQuantitiesEditorComponent,
    NotesTableComponent,
    ChargesTableComponent,
    InvoiceEditorComponent,
    ReasonEditorComponent,
    IssueEditorComponent,
    IssueComponent,
    ClientIndexComponent,
    ClientSingleComponent,
    ClientAccountEditorComponent,
    RentalOverrideEditorComponent,
    ServiceOverrideEditorComponent,
    RentalIndexComponent,
    RentalSingleComponent,
    ServiceSingleComponent,
    ServiceIndexComponent,
    ChargeIndexComponent,
    ChargeSingleComponent,
    EmployeeSingleComponent,
    EmployeeIndexComponent,
    SignatureCaptureEditorComponent,
    SmsNotificationsComponent,
    GeneralSettingsComponent,
    AccountingSettingsEditorComponent,
    InvoiceSettingsEditorComponent,
    DiscountSettingsEditorComponent,
    DispatchSettingsEditorComponent,
    BranchSettingsIndexComponent,
    BranchSettingsSingleComponent,
    RentalOverridesComponent,
    ServiceOverridesComponent,
    UserDispatchesIndexComponent,
    DispatchSlipSingleComponent,
    DispatchSlipComponent,
    DispatchSlipRequestEditorComponent,
    DispatchSlipServiceEditorComponent,
    PunchButtonComponent,
    TaskTimerComponent,
    UpcomingDispatchesComponent,
    InvoiceIndexComponent,
    InvoiceSingleComponent,
    InvoiceScheduleComponent,
    QuickbooksSettingsComponent,
    IntegrationTaxGroupEditorComponent,
    IntegrationBranchDefaultsEditorComponent,
    IntegrationBranchOverrideEditorComponent,
    OnboardingHelperComponent,
    ReportsViewComponent,
    ActiveItemComponent,
    ActiveItemRentalRowComponent,
    ActiveItemClientRowComponent,
    ActiveItemWorkOrderRowComponent,
    LostItemComponent,
    LostItemWorkOrderRowComponent,
    LostItemClientRowComponent,
    LostItemRentalRowComponent,
    ServiceHoursComponent,
    ServiceHoursWorkOrderRowComponent,
    ServiceHoursClientRowComponent,
    ServiceHoursServiceRowComponent,
    InvoicedItemsComponent,
    InvoicedItemsClientRowComponent,
    InvoicedItemsInvoiceRowComponent,
    InvoicedItemsItemRowComponent,
    InvoicedItemsWorkOrderRowComponent,
    RentalItemComponent,
    ClientRowComponent,
    WorkOrderRowComponent,
    RequestRowComponent,
    RentalRowComponent,
    ReportFilterEditorComponent,
    DispatchedItemComponent,
    DispatchedItemRentalRowComponent,
    DispatchedItemClientRowComponent,
    DispatchedItemWorkOrderRowComponent,
    DispatchedRequestComponent,
    DispatchedRequestRequestRowComponent,
    DispatchedRequestClientRowComponent,
    DispatchedRequestWorkOrderRowComponent,
    DateDialogComponent,
    DocumentUploadComponent,
    TabUploadComponent,
    DocumentEditorComponent,
    DocumentPreviewComponent,
    VersionUpdateBarComponent,
    TabAssociateComponent,
    RentalItemGraphComponent,
    DispatchScheduleComponent,
    GanttDispatchComponent,
    EmployeeScheduleComponent,
    EmployeeScheduleSingleComponent,
    EmployeeComponent,
    GanttEmployeeComponent,
    DispatchSingleComponent,
    DispatchEmployeeLabelComponent,
    VariantPropertyDefinitionEditorComponent,
    VariantEditorComponent,
    VariantPropListComponent,
    VariantListComponent,
    VariantConfigurationComponent,
    ThumbnailControlCompnent,
    RequestSearchOptionComponent,
    DispatchSummaryComponent,
    DispatchRegionEditorComponent,
    CompanySettingsEditorComponent,
    EmailHistoryComponent,
    RentalDefinitionEditorComponent,
    VariantConfigurationModalComponent,
    ServiceDefinitionEditorComponent,
    UserDispatchesSingleComponent,
    UpcomingDispatchesSingleComponent,
    DispatchScheduleSingleComponent,
    Sage50SettingsComponent,
    Sage50AccountEditorComponent,
    Sage50TaxEditorComponent,
    Sage50ExportsComponent,
    Sage50ExcludedClientEditorComponent,
    Sage50AdditionalSettingsComponent,
    Sage50OverrideEditorComponent,
    RentalGroupSingleComponent,
    GroupSingleComponent,
    GroupDefinitionEditorComponent,
    GroupEditorItem,
    RentalEditorGroupItem,
    FlattenedViewToggleComponent,
    LineItemSingleComponent,
    LineItemIndexComponent,
    LineItemDefinitionEditorComponent,
    LineItemRequestEditorComponent,
    TabLineItemsComponent,
    LineItemEditorComponent,
    LineItemRequestComponent,
  ],
  providers: [
    Title,
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule
{

  constructor(
    protected injector: Injector,
    protected version: VersionService,
  )
  {
    AppInjector.initialize( this.injector );
    this.version.startPolling();
    console.info( `${env( 'APP_NAME' )} ${env( 'APP_VERSION' )} booted.` )

  }
}
