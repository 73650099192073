import { BaseModel } from "./Base.model";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";

export class AccountingSetting extends BaseModel {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() period_lock_enabled?: boolean = null;
    @dto() @date period_lock_date?: boolean = null;

    static async get(): Promise<AccountingSetting> 
    {
        const response = await AppInjector.get(RouteMap).getAccountingSettings();
        return new AccountingSetting(response.data().value);
    }

    async save(): Promise<AccountingSetting> 
    {
        const response = await this.routeMap.updateAccountingSettings(this.flush());
        return this.map(response.data().value);
    }
}
