import { Ng2StateDeclaration } from '@uirouter/angular';
import { ServiceHoursComponent } from './service-hours.component';

export const ServiceHoursReportState: Ng2StateDeclaration = {
  name: 'protected.reports.service-hours',
  url: '/service-hours',
  component: ServiceHoursComponent,
  data: { 
    pageTitle: 'Service Hours Report', 
    requiresAuth: true,
  },
}
