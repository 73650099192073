import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { RentalGroupSingleComponent } from "./rental-group-single.component";

export const RentalGroupSingleState: Ng2StateDeclaration = {
  name: 'protected.rentals.group-single',
  url: '/group/:modelId',
  component: RentalGroupSingleComponent,
  data: {
      pageTitle: 'Rental Groups',
      requiresAuth: true,
  },
  resolve: [
    { 
      token: 'modelId',
      deps: [Transition],
      resolveFn: (transition: Transition) => transition.params().modelId,
    },
  ],
}
