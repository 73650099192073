import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupItemConstants } from '@beaconlite/models/contracts/GroupItem.interface';

@Component({
  selector: 'app-flattened-view-toggle',
  templateUrl: './flattened-view-toggle.component.html',
  styleUrls: ['./flattened-view-toggle.component.scss']
})
export class FlattenedViewToggleComponent implements OnInit {
  @Input() isFlattenedView: boolean;
  @Output() onToggled = new EventEmitter<boolean>();
  groupedViewIcon = GroupItemConstants.GROUPED_VIEW_ICON;
  flattenedViewIcon = GroupItemConstants.FLATTENED_VIEW_ICON;

  toggle() {
    this.onToggled.emit(this.isFlattenedView);
  }

  constructor() { }

  ngOnInit(): void { }
}
