import { Thumbnail } from "../Thumbnail.model";
import { ResourceItemCollection } from "./ResourceItem.interface";

export interface GroupItem {
    id: string;
    name: string;
    thumbnail: Thumbnail;
    quantity: number;
    ordinal: number;
    item_level: string;
    get icon(): string;
    getCollection(): ResourceItemCollection;
}

export class GroupItemConstants {
    static readonly VARIANT_ITEM_ICON = 'lan';
    static readonly ITEM_DEF_ICON = 'api';
    static readonly GROUPED_VIEW_ICON = 'segment';
    static readonly FLATTENED_VIEW_ICON = 'reorder'
}
