 <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
    <div class="login-form">
        <img src="/assets/img/{{ company.logo_colour }}" alt="Company Logo" class="login-form-logo">
        <mat-card fxLayout="column">
            <mat-progress-bar mode="indeterminate" [fxShow]="pending"></mat-progress-bar>
            <div fxLayout="column">
                <mat-card-title class="text-center">
                    <span class="md-headline">Admin Portal</span>
                </mat-card-title>
                <mat-card-content>
                    <form #loginForm="ngForm" (ngSubmit)="onLogin()">
                        <mat-form-field class="md-block" appearance="outline" hideRequiredMarker>
                            <mat-label>Email</mat-label>
                            <input [(ngModel)]="user.username" 
                                name="username" 
                                #username="ngModel" 
                                type="email"
                                matInput 
                                email
                                required>
                            <mat-error *ngIf="username.hasError('email')">Invalid email format.</mat-error>
                            <mat-error *ngIf="username.hasError('required')">Email is required.</mat-error>
                            <mat-error *ngIf="username.hasError('failed')">Incorrect email or password.</mat-error>
                        </mat-form-field>

                        <mat-form-field class="md-block" appearance="outline" hideRequiredMarker>
                            <mat-label>Password</mat-label>
                            <input [(ngModel)]="user.password" 
                                name="password" 
                                #password="ngModel" 
                                type="password" 
                                matInput 
                                required>
                            <mat-error *ngIf="password.hasError('required')">Password is required.</mat-error>
                            <mat-error *ngIf="password.hasError('failed')">Incorrect email or password.</mat-error>
                        </mat-form-field>

                        <div fxLayout="row" fxLayoutAlign="end">
                            <a class="forgot-password__link" uiSref="forgotPassword">Forgot Password?</a>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button mat-raised-button type="submit" color="accent" [disabled]="pending">
                                Sign in
                            </button>
                        </div>
                    </form>
                </mat-card-content>
            </div>
        </mat-card>
    </div>
    <!-- Padding to offset vertical centering -->
    <span fxFlex="25"></span>
</div>
