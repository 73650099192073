import { VariantRentalOption } from "./VariantRentalOption.model";
import { dto } from "./mixins/Dto.decorators";
import { VariantPropertyDefinition } from "./VariantPropertyDefinition.model";

export class VariantRentalPropertyDefinition extends VariantPropertyDefinition {

    constructor(attributes: object = {})
    {
        super(attributes);
        this.init(attributes);
    }

    @dto(VariantRentalOption) options?: VariantRentalOption[] = [];
    
    addOption(): void
    {
        this.options.push(
            new VariantRentalOption({ ordinal: this.options.length })
        );
    }
}
