import { Injectable } from '@angular/core';
import { env } from '../env.service';
import { HttpAdapterService } from './http-adapter.service';
import { HttpRequest } from './http-request';
import { HttpResponse } from './http-response';
import { DialogNotificationService } from '../notification/dialog-notification.service';
import { TokenManagerService } from '../auth/token-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ApiClient {
  private readonly _url: string;

  constructor(
    private tokenManager: TokenManagerService,
    private httpAdapter: HttpAdapterService,
    private notificationService: DialogNotificationService ) {

      this._url = env('API_URL');
    }

  /**
   * Create a new instance of HttpRequest pre-configured with configuration for the API.
   * 
   * @returns {HttpRequest}
   */
  request(): HttpRequest {
    return this.httpAdapter.request().base(this._url);
  }

  /**
   * Create a new instance of HttpRequest pre-configured with configuration and auth headers for the API.
   * 
   * @param {Object} options 
   * @returns {HttpRequest}
   */
  withAuth(options?: {[key: string]: any}): HttpRequest {
    const defaults = { handleFailure: true };
    options = {...defaults, ...options};

    const request = this.request();

    if (options.handleFailure === true)
    {
      request.addFailureHandler(this.displayApiErrors)
    }

    const promise = this.tokenManager.getToken()
      .then( token => request.header('Authorization', `Bearer ${token}`) )
    
    request.promise(promise);

    return request;
  }

  protected displayApiErrors = (response: HttpResponse): void => 
  {
    // If the api responds with a status code of 0 (an invalid
    // HTTP response code), this means the server is unreachable.  
    // Usually indicates a type of timeout error, which will occur 
    // when the app is left to idle for an extended period of time.  
    // Do not show error dialog under these circumstances.
    if (response._status === 0) { return; }

    this.notificationService.apiError(response)
  }
}
