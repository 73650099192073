import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition } from '@uirouter/core';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['../public.scss'],
})
export class PasswordResetComponent implements OnInit {
  email: string;
  token: string;
  pending: boolean = false;
  company: any;
  user: any = {};
  @ViewChild('passwordResetForm') passwordResetForm: NgForm;

  constructor(
    protected $settings: SettingsService,
    protected transition: Transition,
    protected authService: AuthenticationService,
  ) {}

  ngOnInit(): void { 
    console.debug('[View:ResetPassword] controller loaded.'); 

    this.company = this.$settings.get('company_config');

    const queryParams = this.transition.params();
    this.email = queryParams.email;
    this.token = queryParams.token;
  }

  async onResetPassword(): Promise<any> {
    if (this.passwordResetForm.invalid) return;

    this.pending = true

    const params = {
      email: this.email,
      token: this.token,
      password: this.passwordResetForm.controls.password.value,
      password_confirmation: this.passwordResetForm.controls.passwordConfirm.value,
    };

    try
    {
      await this.authService.resetPassword(params);
      await this.authService.login(this.email, this.passwordResetForm.controls.password.value);
    }
    catch (response)
    {
      this.passwordResetForm.controls.password.setErrors({failed: false});
      this.passwordResetForm.controls.passwordConfirm.setErrors({failed: false});

      console.error('[View:ResetPassword] Reset failed.')
    }
    finally
    {
      this.pending = false;
    }

  }
}
