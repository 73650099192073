<mat-table fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="serial">
        <mat-header-cell *matHeaderCellDef>Invoice</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.serial_id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="issued">
        <mat-header-cell *matHeaderCellDef>Issue Date</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.issued_at }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="branch">
        <mat-header-cell *matHeaderCellDef>Branch</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.branch }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="voided">
        <mat-header-cell *matHeaderCellDef>Voided</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.voided ? 'Yes' : 'No' }}</mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.total | currencyFilter }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let item">
            <invoiced-items-client-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </invoiced-items-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="workOrderDetail" *ngIf="this.nestedLayer == 'work_orders'">
        <mat-cell *matCellDef="let item">
            <invoiced-items-workorder-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </invoiced-items-workorder-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="itemDetail" *ngIf="this.nestedLayer == 'items'">
        <mat-cell *matCellDef="let item">
            <invoiced-items-item-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </invoiced-items-item-row>         
        </mat-cell>
    </ng-container>

  
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'work_orders'">
        <mat-row *matRowDef="let nested; columns: ['workOrderDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'items'">
        <mat-row *matRowDef="let nested; columns: ['itemDetail']" ></mat-row>
    </ng-container>
</mat-table>
