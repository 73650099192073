<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar>
        <h2>Rental Request Editor</h2>
        <span fxFlex></span>
        <mat-icon *ngIf="request.locked" color="primary">lock_outline</mat-icon>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" *ngIf="departments.length > 1">
            <mat-form-field fxFlex="33">
                <mat-label>Department</mat-label>
                <mat-select [(ngModel)]="request.department_id"
                    name="departmentField"
                    #departmentField="ngModel">
                    <mat-option *ngFor="let department of departments" [value]="department.id">
                        {{ department.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Location</mat-label>
                <input type="text"
                    [(ngModel)]="request.location"
                    name="locationField"
                    #locationField="ngModel"
                    maxlength="255"
                    required
                    cdkFocusInitial
                    [disabled]="request.locked || request.lock_level >= 1"
                    matInput>
                <mat-hint align="end">{{ request.location?.length || 0 }} / 255</mat-hint>
                <mat-error *ngIf="locationField.hasError('required')">Location is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Ordered By</mat-label>
                <input type="text"
                    [(ngModel)]="request.ordered_by"
                    name="orderedByField"
                    #orderedByField="ngModel"
                    maxlength="255"
                    [disabled]="request.locked || request.lock_level >= 1"
                    matInput>
                    <mat-hint align="end">{{ request.ordered_by?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input [(ngModel)]="request._dates.started_at.date"
                    name="startedAtField"
                    #startedAtField="ngModel"
                    [matDatepicker]="startedAtPicker"
                    [min]="minDate"
                    required
                    (focus)="startedAtPicker.open()"
                    [disabled]="request.locked || request.lock_level >= 2"
                    matInput>
                <mat-datepicker-toggle matSuffix [for]="startedAtPicker"></mat-datepicker-toggle>
                <mat-datepicker #startedAtPicker></mat-datepicker>
                <mat-error *ngIf="startedAtField.hasError('required')">Start date is required.</mat-error>
                <mat-error *ngIf="startedAtField.hasError('matDatepickerMin')">Start date is too early.</mat-error>
            </mat-form-field>

            <mat-slide-toggle fxFlexAlign="center"
                [(ngModel)]="request.waive_request_minimum"
                name="waiveRequestMinimumField"
                [disabled]="request.locked">
                Waive request minimum
            </mat-slide-toggle>
        </div>

       
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Initial Dispatch Note</mat-label>
                <textarea [(ngModel)]="firstNote.content" 
                    name="firstNoteField"
                    (focus)="$event.target.select()" 
                    matInput>
                </textarea>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>
