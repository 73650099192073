<form class="mat-dialog-editor" #editorForm (ngSubmit)="onSave()" novalidate>
  <mat-toolbar>
    <h2>Edit Tax Rate</h2>
</mat-toolbar>
  <mat-dialog-content class="dialog-padding">
    <span class="mat-subheading-2">What is the name of "{{ this.taxRate.tag }} - {{ this.taxRate.name }}" in Sage 50?</span>
      <div fxLayout="column">
          <mat-form-field>
              <mat-label>Tax Name</mat-label>
              <input type="text"
              [(ngModel)]="this.taxNameInSage50"
              name="nameInSage50"
              #nameInSage50="ngModel"
              required
              matInput>
          </mat-form-field>
      </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
      <button type="button" mat-raised-button mat-dialog-close>
          Cancel
      </button>
      <button type="submit" mat-raised-button color="accent" [disabled]="this.saving">
          Save
      </button>
  </mat-dialog-actions>
</form>
