export class InvoiceConstants {

  static TYPE_INVOICE     = 'invoice';
  static TYPE_QUOTE       = 'quote';
  static TYPE_ESTIMATE    = 'estimate';

  static STATE_DISCARDED  = 'discarded';
  static STATE_VOIDED     = 'voided';
  static STATE_SENT       = 'sent';
  static STATE_INVOICED   = 'invoiced'; 

  static CONSTANT_PREVIEW = 'preview';
}
