import * as moment from 'moment';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client, Department, Note, RentalRequest} from '@beaconlite/models';
import { RentalRequestEditorData } from './rental-request-editor-data.interface';
import { AppData } from '../../../../../../services/data/shared-data.departments';
import { DialogNotificationService } from '../../../../../../services/notification/dialog-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-rental-request-editor',
  templateUrl: './rental-request-editor.component.html',
})
export class RentalRequestEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  workOrder = this.data.workOrder;
  original = this.data.original;
  onUpdate = this.data.onRequestUpdate;
  onRemove = this.data.onRequestRemove;

  loading = false;
  request = new RentalRequest({});
  departments: Department[] = [];

  client: Client = null;
  minDate: Date = null;

  firstNote = new Note({type: Note.TYPE_DISPATCH});

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: RentalRequestEditorData,
    public dialogRef: MatDialogRef<RentalRequestEditorComponent>,
    protected dialogNotifications: DialogNotificationService,
    protected appData: AppData,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.client = this.workOrder.client;
    this.minDate = moment.unix(this.workOrder.minimum_allowable_date).toDate();

    // Get departments
    const departments = await this.appData.departments.getAll();
    this.departments = departments;

    if (!! this.data.original)
    {
      this.request = this.original.copy();
      this.firstNote = Note.getFirstNote(this.request.notes, Note.TYPE_DISPATCH);
    }

    if (! this.request.department_id)
    {
      const defaultDepartment = departments.find(department => department.default)
      this.request.department_id = defaultDepartment.id;
    }

    this._sortItems();
  }

  canRemove(): boolean
  {
    return !!this.data.original && this.data.original.exists() && !this.data.original.locked;
  }

  async remove(): Promise<void>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotifications.removeConfirm())
      {
        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.loading = false;
    }

  }

  async save(): Promise<void>
  {
    if (this.editorForm.invalid) return;

    // Add new note to the request
    if( this.firstNote.content && !this.request.hasNote(this.firstNote) )
    {
      this.request.addNote(this.firstNote);
    }
    // Remove the note from the request
    else if (!this.firstNote.content)
    {
      this.request.removeNote(this.firstNote);
    }

    this.loading = true;

    try
    {
      await this.onUpdate(this.request);
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }

  protected _sortItems(): void
  {
    this.departments?.sort(orderBy('name'));
  }
}
