import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { DispatchedRequestReport } from '@beaconlite/models/reports/DispatchedRequestReport.model';
import { DispatchedReportRequest } from '@beaconlite/models/reports/contracts/DispatchedReportRequest.interface';

@Component({
  selector: 'dispatched-request-workorder-row',
  templateUrl: './dispatched-request-work-order-row.component.html',
})
export class DispatchedRequestWorkOrderRowComponent implements OnInit, DoCheck {

  @Input() report: DispatchedRequestReport;
  @Input() requests: DispatchedReportRequest[];
  @Input() layer: string;

  nestedLayer = '';
  tableColumns = ['serial_id'];

  constructor() { }
  ngOnInit(): void { }

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedRequests(request: DispatchedReportRequest): DispatchedReportRequest[] | null
  {    
    if (! this.nestedLayer) return;

    return Object.values(request[this.nestedLayer]);
  }
}
