 <mat-toolbar fxLayout="row"
              fxLayoutAlign="space-between center"
              color="warn">
    <span>Error</span>
    <span><mat-icon>error_outline</mat-icon></span>            
</mat-toolbar>

<mat-dialog-content>
    <div>
        <h2>An error has occured</h2> 
        <p>{{ data?.error?.message }}</p>
        <p>{{ data?.error?.debugMessage }}</p>
        <p>Code: {{ data?.error?.code }} ({{ data?.error?.status }})</p>
        <mat-checkbox [(ngModel)]="viewDetails" aria-label="view details">
            Details
        </mat-checkbox>
        <pre *ngIf=viewDetails>{{ data?.formattedError }}</pre>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button
            [mat-dialog-close]="true"
            color="warn">
        Okay
    </button>
</mat-dialog-actions>
