<div class="index-sidebar" fxLayout="column">
    
    <mat-progress-bar mode="indeterminate" [fxShow]="this.dispatches.loading"></mat-progress-bar>

    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="this.onSearch()" fxLayout="column" novalidate>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="false">
            <mat-form-field>
                <mat-label>
                    <mat-icon>search</mat-icon>
                </mat-label>
                <input type="text" matInput>
            </mat-form-field>

            <div class="tab" fxLayout="row wrap">
                <input id="tab-one" type="checkbox" name="tabs">
                <label class="tab-one-label" for="tab-one" fxFlex>More Options</label>

                <div class="tab-content" fxLayout="column" fxFlex="100">
                    <mat-form-field fxFlex>
                        <mat-label>Branch (IMPLEMENT AUTOCOMPLETE)</mat-label>
                        <input type="text" matInput>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>Client (IMPLEMENT AUTOCOMPLETE)</mat-label>
                        <input type="text" matInput>
                    </mat-form-field>

                    <div fxFlex fxLayout="row">
                    <mat-form-field fxFlex="50">
                        <mat-label>Start Date</mat-label>
                        <input type="text"
                            [matDatepicker]="startDatepicker"
                            [(ngModel)]="this.selectedStartDate"
                            name="selectedStartDateField"
                            (focus)="startDatepicker.open()"
                            matInput>
                        <mat-datepicker #startDatepicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <mat-label>End Date</mat-label>
                        <input type="text"
                            [matDatepicker]="endDatepicker"
                            [(ngModel)]="this.selectedEndDate"
                            name="selectedEndDateField"
                            (focus)="endDatepicker.open()"
                            matInput>
                        <mat-datepicker #endDatepicker></mat-datepicker>
                    </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <mat-divider *ngIf="false"></mat-divider>

    <cdk-virtual-scroll-viewport itemSize="70">
        <mat-list dense>
            <!-- Do not copy this to other places. We change the height of mat-list-item with css, which is supposed to be set by itemSize of cdk-virtual-scroll-viewport.
            This allows us to optionally show the date-separator. While it was tested thoroughly here, there is a good chance it will break if copied and used
            elsewhere. The height affects how the list is rendered. -->
            <mat-list-item class="index-list"
                *cdkVirtualFor="let dispatch of this.dispatches"
                [class.index-list__item--selected]="selectedModelId === dispatch.id"
                (click)="this.view(dispatch)"
                matRipple>
                <div class="list-item__container">
                    <div class="date-separator" *ngIf="shouldShowDateSeparator(dispatch)">
                        <span class="mat-caption">{{ dispatch.scheduled_at | dateFilter: 'dateMedium' }}</span>
                    </div>
                    <div class="dispatch-wrapper">
                        <div matListAvatar class="dispatch-list-state {{ dispatch.state_name }}">{{ dispatch.state_letter }}</div>
                        <div class="mat-list-text" fxLayout="column">
                            <div fxLayout="row">
                                <h3 fxFlex>{{ dispatch.client.name }}</h3>
                                <p>#{{ dispatch.serial_id }}</p>
                            </div>
                            <h4>{{ dispatch.scheduled_at | dateFilter: 'dateMedium' }}</h4>
                            <p>{{ dispatch.location }}</p>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>

</div>

<mat-divider vertical></mat-divider>

<!-- Dispatch Slip Single -->
<ui-view [class.single-view--displayed]="!this.isOnIndex" fxFlex fxLayout="column"></ui-view>