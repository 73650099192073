import { Injectable } from '@angular/core';
import { env } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  protected _settings: Map<string,any> = new Map();

  constructor() { this.initialize(); }

  /**
   * Initialize the service
   * 
   */
  initialize(): void {
    // TODO: pulling settings from backend rather than env
    this.set('company_config', env('companySettings', {}));

    console.debug('[Service:$settings] initialized.');
  }

  /**
   * Verify that a setting with a particular key exists
   * 
   * @param {string} key 
   * @returns {boolean}
   */
  has(key: string): boolean {
    return this._settings.has(key);
  }

  /**
   * Get a setting by key with optional default value
   * 
   * @param {string} key 
   * @param {*} fallback 
   * @returns {*}
   */
  get(key: string, fallback?: any): any {
    if (this._settings.has(key))
    {
      return this._settings.get(key);
    }

    return fallback || null;
  }

  /**
   * Set a setting with a key and value
   * 
   * @param {string} key
   * @param {*} value
   */
  set(key: string, value: any): void {
    this._settings.set(key, value);
  }

  /**
   * Clear a setting with a particular key.
   * 
   * @param {string} key
   * @returns {boolean}
   */
  clear(key: string): boolean {
    return !!this._settings.delete(key);
  }
}
