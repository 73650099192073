import { Injectable } from '@angular/core';
import * as moment from 'moment';

type DateTimeUtilityModel = object;
@Injectable({
  providedIn: 'root',
})
export class DateTimeUtility 
{
    private _modelReference: DateTimeUtilityModel;
    private _modelAttribute: string;
    private _internalDate: Date;
    private _internalTime: Date;
    private _internalTimestamp: number;

    /**
     * DateTimeUtility class that facilitates manipulation of data models' Unix timestamp attributes through date
     * and time javascript Dates with bidirectional, cascading updates.
     *
     * @class
     * @param {BaseModel} model
     * @param {String} attribute
     */
    constructor(model: DateTimeUtilityModel, attribute: string)
    {

        if ( !model.hasOwnProperty(attribute) )
        {
            console.error('[Model:DateTimeUtility] invalid timestamp attribute for model');
            return;
        }

        // Default property values
        this._modelReference = model;
        this._modelAttribute = attribute;

        // Initial property synchronization
        this.updateInternalDateTime.call(this);
    }

    get date(): Date { return this.getDate(); }
    set date(date: Date) { this.setDate(date); }

    get time(): string { return this.getTime(); }
    set time(time: string) { this.setTime(time); }

    /**
     * Get the internal Date object representing the date portion of the model's Unix timestamp attribute.
     *
     * @returns {Date|null}
     */
    getDate(): Date 
    {
        this.updateInternalDateTime.call(this);
        return this._internalDate;
    }

    /**
     * Set the internal Date object representing the date portion of the model's Unix timestamp attribute.
     *
     * @param {Date|null} date
     */
    setDate(date: Date): void 
    {
        this._internalDate = date;

        // Initialize the internal time if it is uninitialized when the internal date is updated with a valid date
        if ( moment.isDate(this._internalDate) && !moment.isDate(this._internalTime) )
        {
            // Today at 00:00
            this._internalTime = moment().startOf('day').toDate();
        }
        // Reset the internal time when then internal date is reset
        else if ( !moment.isDate(this._internalDate) )
        {
            this._internalTime = null;
        }

        this.updateModelTimestamp.call(this);
    }

    /**
     * Get a HH:mm string representing the time of day portion of the model's Unix timestamp attribute.
     *
     * @returns {Date|null}
     */
    getTime(): string 
    {
        this.updateInternalDateTime.call(this);

        return moment(this._internalTime).format('HH:mm');
    }

    /**
     * Set the internal Date object representing the time of day portion of the model's Unix timestamp attribute.
     *
     * @param {string|null} time
     */
    setTime(time: string): void 
    {
        const hours = time.split(':')[0];
        const minutes = time.split(':')[1];

        this._internalTime = this._internalDate
            ? moment(this._internalDate).add(hours, 'hours').add(minutes, 'minutes').toDate()
            : moment().add(hours, 'hours').add(minutes, 'minutes').toDate()

        // Initialize the internal date if it is uninitialized when the internal time is updated with a valid date
        if (! moment.isDate(this._internalDate) )
        {
            // Today at 00:00
            this._internalDate = moment().startOf('day').toDate();
        }

        this.updateModelTimestamp.call(this);
    }

    /**
     * Update the corresponding model's Unix timestamp attribute to reflect the value of the internal date and time.
     *
     */
    private updateModelTimestamp()
    {
        var timestamp = null;

        if ( moment.isDate(this._internalDate) && moment.isDate(this._internalTime) )
        {
            timestamp = moment({
                year:           this._internalDate.getFullYear(),
                month:          this._internalDate.getMonth(),
                date:           this._internalDate.getDate(),
                hours:          this._internalTime.getHours(),
                minutes:        this._internalTime.getMinutes(),
                seconds:        this._internalTime.getSeconds(),
                milliseconds:   0
            }).unix();
        }

        this._internalTimestamp = timestamp;
        this._modelReference[this._modelAttribute] = timestamp;
    }

    /**
     * Update the internal date and time to reflect the value of the corresponding model's Unix timestamp attribute.
     *
     */
    private updateInternalDateTime()
    {
        var timestamp = parseInt(this._modelReference[this._modelAttribute]) || null;

        // Skip update if the internal/external timestamps are still synchronized
        if ( timestamp === this._internalTimestamp )
        {
            return;
        }

        // Update the internal properties if the timestamp is valid
        if ( timestamp > 0 || timestamp === 0 )
        {
            // Update internal date
            this._internalDate = moment.unix(timestamp).startOf('day').toDate();

            // Update internal time
            this._internalTime = moment.unix(timestamp).toDate();

            // Update internal timestamp
            this._internalTimestamp = timestamp;

            return;
        }

        // Otherwise reset the internal properties
        this._internalDate = null;
        this._internalTime = null;
        this._internalTimestamp = null;
    }

}
