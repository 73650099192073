import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Note } from '@beaconlite/models';
import { DialogNotificationService } from '../../../services/notification/dialog-notification.service';
import { NoteEditorData } from './note-editor-data.interface';

@Component({
  selector: 'app-note-editor',
  templateUrl: './note-editor.component.html',
  styleUrls: ['./note-editor.component.scss']
})
export class NoteEditorComponent implements OnInit {

  minimumDate = this.data.minimumDate;
  original = this.data.original;
  allowRecurrence = this.data.allowRecurrence;
  options = this.data.options || {};
  onUpdate = this.data.onNoteUpdate;
  onRemove = this.data.onNoteRemove;

  @ViewChild('editorForm') editorForm: NgForm;

  loading = false;
  note = new Note({});
  noteTypes = [];
  recurringWithEnd: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: NoteEditorData,
    public dialogRef: MatDialogRef<NoteEditorComponent>,
    private dialogNotifications: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    this._buildNoteTypes();

    // Default type
    this.note.type = this._getDefaultType();

    if (!! this.original)
    {
      this.note = this.original.copy();
      this.recurringWithEnd = !!this.note.recurring_until;
    }
  }

  isRecurringUntilDisabled(): boolean
  {
    return this.note.invoiced || !this.note.recurring || !this.recurringWithEnd;
  }

  async stopRecurrence()
  {
    this.loading = true;

    try
    {
      if (! await this.dialogNotifications.confirm()) { return false };
      await this.note.stopRecurrence();
      this.onUpdate(this.note);
    }
    finally
    {
      this.loading = false
    }
  }

  canRemove(): boolean 
  {
    return !!this.original && !this.original.locked;
  }

  async remove(): Promise<void>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotifications.removeConfirm())
      {
        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.loading = false;
    }
  }

  async save(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) { return false };

    this.loading = true;
    try
    {
      await this.onUpdate(this.note);
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }

  canRecur(): boolean 
  {
    if (this.allowRecurrence && this.note.type === 'invoice') {
      return true;
    }
    return false;
  }

  _buildNoteTypes()
  {
    const types = [
      Note.TYPE_PRIVATE,
      Note.TYPE_DISPATCH,
      Note.TYPE_INVOICE,
      Note.TYPE_FIELD,
    ]

    types.forEach(type => {
      const noteType = { name:  Note.getDisplayType(type), value: type };

      if (! this.data.options?.types || // Allow types not specified
            this.data.options?.types.includes(type)) // Or allow type found
      {
        return this.noteTypes.push(noteType);
      }
    });
  }

  _getDefaultType(): string
  {
    // Default provided return it
    if (!! this.options.defaultType)
    {
      return this.options.defaultType
    }

    if (! this.options.types || // Types not specified
          this.options.types.includes(Note.TYPE_PRIVATE))
    {
      return Note.TYPE_PRIVATE;
    }
    else
    {
      // Grab the first one
      return this.options.types[0];
    }
  }
}
