import { Ng2StateDeclaration } from '@uirouter/angular';
import { ChargeIndexComponent } from './charge-index.component';


export const ChargeIndexState: Ng2StateDeclaration = {
  name: 'protected.charges',
  url: '/charges',
  abstract: true,
  component: ChargeIndexComponent,
  data: { 
    pageTitle: 'Charge Index', 
    requiresAuth: true,
  },
}