import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { ServiceDefinitionCollection } from '@beaconlite/models/collections';
import { ServiceDefinition , Department, Thumbnail } from '@beaconlite/models';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';
import { ServiceDefinitionEditorService } from '../service-definition-editor/service-definition-editor.component';
import { AppData } from "@beaconlite/services/data/shared-data.departments";
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

@Component({
  selector: 'app-service-index',
  templateUrl: './service-index.component.html',
  styleUrls: ['./service-index.component.scss']
})
export class ServiceIndexComponent implements OnInit {

  collection = new ServiceDefinitionCollection();
  services = new CollectionDataSource(this.collection);
  selectedModelId: string = this.$stateGlobals.params.modelId;
  keyword: string;
  disabledState: boolean;

  constructor(
    protected $stateGlobals: UIRouterGlobals,
    protected $state: StateService,
    protected serviceDefinitionEditor: ServiceDefinitionEditorService,
    protected appData: AppData,
    protected snackbarNotification: SnackbarNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.onSearch();
  }

  onSearch()
  {
    const params: any = {
      order:      'asc', 
      order_by:   'name',
    }

    if (this.disabledState != null)
    {
      params.disabled_state = this.disabledState;
    }

    if (this.keyword)
    {
      params.keyword = this.keyword;
    }

    this.collection.search(params);
  }

  async onAdd(): Promise<void>
  {
    const departments = await this.appData.departments.getAll();
    let serviceDefinition = new ServiceDefinition();

    departments?.sort(orderBy('name'));
    serviceDefinition.department_id = departments.find(dep => dep.default).id;
    
    await this.serviceDefinitionEditor.open({
      original: serviceDefinition,
      departments: departments,
      onUpdate: this.createServiceDefinition
    });
  }

  createServiceDefinition = async (serviceDefinition: ServiceDefinition): Promise<void> =>
  {
    await serviceDefinition.save();
    this.selectDefinition(serviceDefinition);
  }

  selectDefinition(serviceDefinition: ServiceDefinition): void 
  {
    this.selectedModelId = serviceDefinition.id;
    this.$state.go('protected.services.single', { modelId: serviceDefinition.id });
  }
}
