import { Component, OnInit } from '@angular/core';
import { Branch, Integration, TaxRate } from '@beaconlite/models';
import { BranchCollection } from '@beaconlite/models/collections';
import { env } from '../../../../../services/env.service';
import { HttpResponse } from '../../../../../services/network/http-response';
import { RouteMap } from '../../../../../services/network/route-map.service';
import { DialogNotificationService } from '../../../../../services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '../../../../../services/notification/snackbar-notification.service';
import { orderBy } from '../../../../../utilities/Sort.utility';
import { BranchSettings } from '../interfaces/branch-settings.interface';
import { IntegrationBranchDefaultsEditorService } from '../integration-branch-defaults-editor/integration-branch-defaults-editor.service';
import { IntegrationTaxGroupEditorService } from '../integration-tax-group-editor/integration-tax-group-editor.service';
import { IntegrationBranchOverrideEditorService } from '../integration-branch-override-editor/integration-branch-override-editor.service';
import { TaxSettings } from '../interfaces/tax-settings.interface';
import { IntegrationAccount } from '../interfaces/integration-account.interface';
import { BranchOverride } from '../interfaces/branch-override.interface';
import { IntegrationSettings } from '../interfaces/integration-settings.interface';
import { OnboardingHelperService } from './onboarding-helper/onboarding-helper.service';
import { MappedAccount } from '../interfaces/mapped-account.interface';
 
@Component({
  selector: 'app-quickbooks-settings',
  templateUrl: './quickbooks-settings.component.html',
  styleUrls: ['./quickbooks-settings.component.scss']
})
export class QuickbooksSettingsComponent implements OnInit {

  loadingQuickbooksTaxes = false;
  loadingQuickbooksAccounts = false;
  isAccountMappingsMissing = false;
  isAccountMappingsEmpty = false;
  syncingClients = false;

  accounts: MappedAccount[] = [];
  taxes: TaxRate[] = [];
  branches: Branch[] = [];
  integration = new Integration({
      slug: Integration.QUICKBOOKS,
  });

  quickbooksTaxes: TaxSettings[];
  quickbooksAccounts: IntegrationAccount[];

  appVersion: string = env('APP_VERSION');
  settings: IntegrationSettings = {taxes:{}, accounts: {}, branches:{}};
  storedOverrides: any = {};

  // Tables
  taxTableColumns = ['group', 'external-name'];
  overridesTableColumns = ['item', 'account'];

  constructor(
    protected routeMap: RouteMap,
    protected dialogNotifications: DialogNotificationService,
    protected snackbarNotifications: SnackbarNotificationService,
    protected taxGroupEditor: IntegrationTaxGroupEditorService,
    protected branchDefaultsEditor: IntegrationBranchDefaultsEditorService,
    protected branchOverrideEditor: IntegrationBranchOverrideEditorService,
    protected onboardingHelper: OnboardingHelperService,
  ) { }

  async ngOnInit(): Promise<void|null> 
  {

    this.taxes = await TaxRate.getAll();
    this.branches = await (new BranchCollection()).all();
    
    this._sortItems();

    await this._fetchIntegration();

    if (! this.integration.linked_at) return;

    this._fetchQuickbooksTaxes();
    this._fetchQuickbooksAccounts();

  }

  protected async _fetchIntegration(): Promise<boolean>
  {
    this.integration = await Integration.getBySlug(Integration.QUICKBOOKS);
    this.settings = this.integration.settings[0].value;

    // This is here because if branches, taxes and accounts are empty objects in db, they return 
    //        as empty arrays for some reason and then mess everything up when going to save
    this.settings.taxes = Array.isArray(this.settings.taxes) ? {} : this.settings.taxes
    this.settings.accounts = Array.isArray(this.settings.accounts) ? {} : this.settings.accounts
    this.settings.branches = Array.isArray(this.settings.branches) ? {} : this.settings.branches

    return true;
  }

  protected async _fetchQuickbooksTaxes(): Promise<void>
  {
    this.loadingQuickbooksTaxes = true;

    try
    {
      const response: HttpResponse = await this.routeMap.loadTaxesFromQuickBooks();
      this.quickbooksTaxes = response.data();
    }
    finally
    {
      this.loadingQuickbooksTaxes = false;
    }
  }

  _fetchQuickbooksAccounts = async (): Promise<void> =>
  {
    this.loadingQuickbooksAccounts = true;
    this.isAccountMappingsMissing = false;
    this.isAccountMappingsEmpty = false;

    try
    {
      const response: HttpResponse = await this.routeMap.loadAccountsFromQuickBooks();
      this.quickbooksAccounts = response.data();

      this._applyQuickbooksAccounts();
      this._remapAccountsForDefaultsAndOverrides();
    }
    catch (response)
    {
      switch(response.error().error.code)
      {
        case 'account-mappings-missing':
          this.isAccountMappingsMissing = true
          break;
        case 'account-mappings-empty':
          this.isAccountMappingsEmpty = true
          break;
        default: 
          this.dialogNotifications.apiError(response)
      }
    }
    finally
    {
      this.loadingQuickbooksAccounts = false;
    }
  }

  _applyQuickbooksAccounts(): void
  {
    this.settings.accounts = Object.fromEntries(this.quickbooksAccounts.map(key => [key.id, {"name": key.name} ]))
  }

  _remapAccountsForDefaultsAndOverrides(): void
  {   
    this.accounts = Object.entries(this.settings.accounts)
      .map( ([key, value]) => ({ 'key':key, 'value':value.name}) );
  }

  async onEditTax(taxRate: TaxRate): Promise<void>
  {
    const onUpdate = async (tax: TaxSettings): Promise<void> => {
      this.settings.taxes[taxRate.tag] = tax;
      await this.onSave();
    }

    await this.taxGroupEditor.open({
      taxRate: taxRate,
      taxList: this.quickbooksTaxes,
      settings: this.settings,
      onUpdate: onUpdate,
    });
  }

  async onEditBranchDefaults(branch: Branch): Promise<void>
  {
    const onUpdate = async (branchSettings: BranchSettings): Promise<void> => 
    {
      this.settings.branches[branch.id] = branchSettings;
      await this.onSave();
    }

    await this.branchDefaultsEditor.open({
      branch: branch,
      accounts: this.accounts,
      original: this.settings.branches[branch.id],
      onUpdate: onUpdate,
    });
  }

  async onEditBranchOverride(branch: Branch, override?: BranchOverride): Promise<void>
  {
    const onOverrideUpdate = async (override: BranchOverride): Promise<void> => 
    {
      if (! this.settings.branches[branch.id][override.itemType]?.overrides)
      {
        this.settings.branches[branch.id][override.itemType].overrides = {};
      }

      this.settings.branches[branch.id][override.itemType].overrides[override.key]= override.value;

      await this.onSave();
    }

    const onOverrideRemove = async (): Promise<void> => 
    {  
      delete this.settings.branches[branch.id][override.itemType].overrides[override.key]

      if (Object.keys(this.settings.branches[branch.id][override.itemType].overrides).length === 0)
      {
        delete this.settings.branches[branch.id][override.itemType].overrides
      }

      await this.onSave();
    }

    await this.branchOverrideEditor.open({
      original: override,
      branch: branch,
      accounts: this.accounts,
      // TODO: Jira BL-537 implement existingOverrides to prevent duplicate overrides from being added.
      existingOverrides: [],
      onOverrideUpdate, 
      onOverrideRemove,
    });
  }

  getBranchAccountName(branch: Branch, type: string): string|null
  {
    if (!this.settings.branches[branch.id]){ return }
    
    let accountId = this.settings.branches[branch.id].default_account

    if (!this.settings.accounts[accountId]){ return }

    if (this.settings.branches[branch.id][type] && 
        this.settings.branches[branch.id][type].default_account)
    {
        accountId = this.settings.branches[branch.id][type].default_account
    }

    return this.settings.accounts[accountId].name
  }

  getBranchOverrides(branch: Branch)
  {
      // Check if branch settings are set
      if (! (this.settings.branches &&
          this.settings.branches[branch.id])) { return; }

      let branchSettings = this.settings.branches[branch.id]

      // Check the stored mapped overrides. Init if required
      if (! this.storedOverrides[branch.id])
      {
          this.storedOverrides[branch.id] = {
              shouldReset: true,
              values: []
          };
      }
      
      // Check for remmap
      if ( !this.storedOverrides[branch.id].shouldReset ) { return this.storedOverrides[branch.id].values }

      let branchOverrides = []

      Array.prototype.push.apply(branchOverrides, this._mapOverrideForRepeat(branchSettings, 'charges'));
      Array.prototype.push.apply(branchOverrides, this._mapOverrideForRepeat(branchSettings, 'rentals'));
      Array.prototype.push.apply(branchOverrides, this._mapOverrideForRepeat(branchSettings, 'services'));
  
      // branchOverrides[]
      this.storedOverrides[branch.id].shouldReset = false;
      this.storedOverrides[branch.id].values = branchOverrides;

      return branchOverrides
  }

  _mapOverrideForRepeat(branchSetting, itemType: string)
  {
      let itemTypeOverrides = []

      if ( branchSetting[itemType] &&
           branchSetting[itemType]['overrides'] ) 
      {
          let overrides = branchSetting[itemType]['overrides'];
          itemTypeOverrides = Object.getOwnPropertyNames(overrides).map(k => ({key:k, itemType: itemType, value:overrides[k]}));
      }

      return itemTypeOverrides
  }

  _resetOverrides()
  {
      Object.getOwnPropertyNames(this.storedOverrides).forEach( (key) => this.storedOverrides[key].shouldReset = true );
  }

  async onSave(): Promise<void>
  {
      this.integration.settings[0].value = this.settings;

      try
      {
        await this.integration.save();
        this.snackbarNotifications.saved();
      }
      catch
      {
        await this._fetchIntegration();
        this._applyQuickbooksAccounts();
      }
      finally
      {
        this._resetOverrides();
      }
  }

  async authorizeQuickbooks(): Promise<void>
  {
    const response: HttpResponse = await this.routeMap.authorizeQuickBooks();
    window.open(response.data(), '_self');
  }

  async onOpenOnboardingHelper(): Promise<void>
  {
    this.onboardingHelper.open({
      isAccountMappingsMissing: this.isAccountMappingsMissing, 
      isAccountMappingsEmpty: this.isAccountMappingsEmpty,
      onRefresh: this._fetchQuickbooksAccounts
    });
  }

  async syncClientsWithQuickbooks(): Promise<void>
  {
    this.syncingClients = true;

    try
    {
      await this.routeMap.syncClientsWithQuickbooks();
    }
    finally
    {
      this.syncingClients = false;
    }
  }

  protected _sortItems(): void
  {
    this.branches.sort(orderBy('-default', 'name'));
  }
}
