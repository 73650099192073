import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EnvService, Config } from '@beaconlite/services/env.service';
import { AppModule } from './app/app.module';

function bootstrapFailed( val )
{
    console.error( 'Angular bootstrap has failed.', val );
}

const envService = new EnvService();

// Load in env information
envService.load()
    .then( ( config: Config ) =>
    {
        if ( !config || !config.APP_VERSION )
        {
            bootstrapFailed( config );
            return;
        }

        if ( config.isProduction )
        {
            enableProdMode();
        }

        platformBrowserDynamic( [ { provide: EnvService, useValue: envService } ] )
            .bootstrapModule( AppModule )
            .catch( bootstrapFailed );
    } )
    .catch( bootstrapFailed );
