import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LineItemRequestEditorData } from './line-item-request-editor-data.interface';
import { LineItemRequestEditorComponent } from './line-item-request-editor.component';

@Injectable({
  providedIn: 'root'
})
export class LineItemRequestEditorService {

  constructor(protected dialog: MatDialog) {}

  async open(data: LineItemRequestEditorData): Promise<boolean>
  {
      const dialogConfig: MatDialogConfig = {
          disableClose: true,
          hasBackdrop: true,
          autoFocus: true,
          width: '100%',
          maxWidth: 800,
          data
        }

      return this.dialog.open(LineItemRequestEditorComponent, dialogConfig)
          .afterClosed()
          .toPromise();
  }
}
