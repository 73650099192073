import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { ClientSingleComponent } from "./client-single.component";

export const ClientSingleState: Ng2StateDeclaration = {
    name: 'protected.clients.single',
    url: '/:modelId',
    component: ClientSingleComponent,
    data: {
        pageTitle: 'Clients',
        requiresAuth: true,
    },
    params: {
        modelId: 'new'
    },
    resolve: [
        { 
          token: 'modelId', 
          deps: [Transition],
          resolveFn: (transition: Transition) => transition.params().modelId,
        },
      ],
}
