import * as moment from 'moment';
import type { Constructor } from '@beaconlite/types';
import { BaseModel } from '../Base.model'
import { dto } from './Dto.decorators';
import { date } from './Date.decorators';

export function Discardable<T extends Constructor<BaseModel>>( Base: T ) {
    class DiscardableMixin extends Base {
        @dto() @date discarded_at?: number = null
    
        get discarded(): boolean {
            return this.isDiscarded();
        }
    
        set discarded(value: boolean) {
            if( !!this.discarded_at === value ) { return; }
            
            // If the new value is true, update the timestamp
            if (value) {
                this.discarded_at = moment().unix();
            } else {
                // Clear the timestamp
                this.discarded_at = null;
            }
        }
        
        /**
         * Verify if the current model seems to be discarded.
         *
         * @returns {boolean}
         */
        isDiscarded(): boolean {
            return !!this.discarded_at;
        }
    }

    // Decorators not allowed within TS class expressions (return class DiscardableMixin)
    // https://github.com/microsoft/TypeScript/issues/7342
    return DiscardableMixin;
}