<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" >
        <h2>Return Sheet</h2>
        <span fxFlex></span>
        <mat-icon *ngIf="true">lock_outline</mat-icon>
        <button mat-button [disabled]=" !return_date" (click)="returnAll()" type="button">Return all</button>

    </mat-toolbar> 

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
            <mat-form-field fxFlex="25">
                <mat-label>Return Date</mat-label>
                <input matInput
                    [matDatepicker]="returnDatePicker"
                    [(ngModel)]="return_date"
                    [min]="minimum_date"
                    [max]="maximum_date"
                    name="returnDateField"
                    #returnDateField="ngModel"
                    (change)="setSheet()">
                <mat-datepicker-toggle matSuffix [for]="returnDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #returnDatePicker></mat-datepicker>
            </mat-form-field>
            <app-flattened-view-toggle
                [isFlattenedView]="isFlattenedView"
                (onToggled)="onFlattenedViewToggledHandler($event)">
            </app-flattened-view-toggle>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <p [fxHide]="return_date">Please choose a return date.</p>
        </div>

        <ng-container *ngIf="this.isFlattenedView; then flattenedTable; else groupedTable">
        </ng-container>

        <ng-template #flattenedTable>
            <mat-table 
                [fxShow]="return_date" 
                [dataSource]="getArrayFromMapIterable(flattenedReturns.values())">
                <ng-container matColumnDef="item">
                    <mat-header-cell fxFlex="40" *matHeaderCellDef>Item</mat-header-cell>
                    <mat-cell fxFlex="40" *matCellDef="let item">
                        {{ item.sheetReturn[0].rental.name || 'No item selected'}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="error">
                    <mat-header-cell fxFlex="6" *matHeaderCellDef></mat-header-cell>
                    <mat-cell fxFlex="6" *matCellDef="let item">
                        <mat-icon color="warn" [fxShow]="item.invalid">error_outline</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <mat-header-cell *matHeaderCellDef>Detail</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{ item.sheetReturn[0].rental.detail }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="rented">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Rented</mat-header-cell>
                    <mat-cell class="mat-cell--numeric" *matCellDef="let item">{{ item.quantity_rented }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="remaining">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Remaining</mat-header-cell>
                    <mat-cell class="mat-cell--numeric" *matCellDef="let item">{{ item.quantity_remaining}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="returned">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Returned</mat-header-cell>
                    <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let item; let i = index;">
                        <mat-form-field>
                            <input type="number" 
                                [(ngModel)]="item.quantity_returned"
                                name="returnQuantity{{i}}"
                                (ngModelChange)="this.validateFlatSheetItem(item)"
                                matInput>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lost">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Lost</mat-header-cell>
                    <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let item; let i = index;">
                        <mat-form-field>
                            <input type="number" 
                                [(ngModel)]="item.quantity_lost"
                                name="lostQuantity{{i}}"
                                (ngModelChange)="this.validateFlatSheetItem(item)"
                                matInput>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="returnTableColumns"></mat-header-row>
                <mat-row *matRowDef="let item; columns: returnTableColumns"></mat-row>
            </mat-table>
        </ng-template>

        <ng-template #groupedTable>
            <mat-table 
                [fxShow]="return_date" 
                [dataSource]="sheetItems" 
                multiTemplateDataRows>
                <ng-container matColumnDef="item">
                    <mat-header-cell fxFlex="40" *matHeaderCellDef>Item</mat-header-cell>
                    <mat-cell fxFlex="40" *matCellDef="let returnItem">
                        <span *ngIf="!returnItem?.sheetReturns">{{ returnItem.rental.name || 'No item selected'}}</span>
                        <span *ngIf="returnItem.sheetReturns">{{ returnItem.rentalGroup.name || 'No item selected'}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="error">
                    <mat-header-cell fxFlex="6" *matHeaderCellDef></mat-header-cell>
                    <mat-cell fxFlex="6" *matCellDef="let returnItem">
                        <mat-icon color="warn" *ngIf="!returnItem?.sheetReturns" [fxShow]="returnItem.invalid">error_outline</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <mat-header-cell *matHeaderCellDef>Detail</mat-header-cell>
                    <mat-cell *matCellDef="let returnItem">
                        <span *ngIf="!returnItem?.sheetReturns">{{ returnItem.rental.detail }}</span>
                        <span *ngIf="returnItem.sheetReturns">{{ returnItem.rentalGroup.detail }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="rented">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Rented</mat-header-cell>
                    <mat-cell class="mat-cell--numeric" *matCellDef="let returnItem">
                        <span *ngIf="!returnItem?.sheetReturns">
                            {{ returnItem.rental.quantity }}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="remaining">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Remaining</mat-header-cell>
                    <mat-cell class="mat-cell--numeric" *matCellDef="let returnItem">
                        <span *ngIf="!returnItem?.sheetReturns">
                            {{ returnItem.rental.quantity_remaining }}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="returned">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Returned</mat-header-cell>
                    <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let returnItem">
                        <mat-form-field *ngIf="!returnItem?.sheetReturns">
                            <input type="number" 
                                [(ngModel)]="returnItem.return.quantity"
                                name="returnQuantity-{{returnItem.references.rental.id}}"
                                (ngModelChange)="this.validateSheetItem(returnItem)"
                                matInput>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lost">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Lost</mat-header-cell>
                    <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let returnItem">
                        <mat-form-field *ngIf="!returnItem?.sheetReturns">
                            <input type="number" 
                                [(ngModel)]="returnItem.lost.quantity"
                                name="lostQuantity-{{returnItem.references.rental.id}}"
                                (ngModelChange)="this.validateSheetItem(returnItem)"
                                matInput>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="groupItem">
                    <mat-cell *matCellDef="let returnItem" [fxShow]="returnItem?.sheetReturns?.length">
                        <mat-table class="inner-table"
                            [dataSource]="returnItem.sheetReturns">
                            <ng-container matColumnDef="item">
                                <mat-header-cell fxFlex="40" *matHeaderCellDef>Item</mat-header-cell>
                                <mat-cell fxFlex="40" *matCellDef="let groupItem">
                                    {{ groupItem.rental.name || 'No item selected'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="error">
                                <mat-header-cell fxFlex="6" *matHeaderCellDef></mat-header-cell>
                                <mat-cell fxFlex="6" *matCellDef="let groupItem">
                                    <mat-icon color="warn" [fxShow]="groupItem.invalid">error_outline</mat-icon>
                                </mat-cell>
                            </ng-container>
            
                            <ng-container matColumnDef="detail">
                                <mat-header-cell *matHeaderCellDef>Detail</mat-header-cell>
                                <mat-cell *matCellDef="let groupItem">{{ groupItem.rental.detail }}</mat-cell>
                            </ng-container>
            
                            <ng-container matColumnDef="rented">
                                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Rented</mat-header-cell>
                                <mat-cell class="mat-cell--numeric" *matCellDef="let groupItem">
                                    {{ groupItem.rental.quantity }}
                                </mat-cell>
                            </ng-container>
            
                            <ng-container matColumnDef="remaining">
                                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Remaining</mat-header-cell>
                                <mat-cell class="mat-cell--numeric" *matCellDef="let groupItem">
                                    {{ groupItem.rental.quantity_remaining }}
                                </mat-cell>
                            </ng-container>
            
                            <ng-container matColumnDef="returned">
                                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Returned</mat-header-cell>
                                <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let groupItem">
                                    <mat-form-field>
                                        <input type="number" 
                                            [(ngModel)]="groupItem.return.quantity"
                                            name="returnQuantity-{{groupItem.references.rental.id}}"
                                            (ngModelChange)="this.validateSheetItem(groupItem)"
                                            matInput>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
            
                            <ng-container matColumnDef="lost">
                                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Lost</mat-header-cell>
                                <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let groupItem">
                                    <mat-form-field>
                                        <input type="number" 
                                            [(ngModel)]="groupItem.lost.quantity"
                                            name="lostQuantity-{{groupItem.references.rental.id}}"
                                            (ngModelChange)="this.validateSheetItem(groupItem)"
                                            matInput>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <mat-row *matRowDef="let groupItem; columns: groupReturnTableColumns"></mat-row>
                        </mat-table>
                    </mat-cell>

                </ng-container>

                <mat-header-row *matHeaderRowDef="returnTableColumns"></mat-header-row>
                <mat-row *matRowDef="let returnItem; columns: returnTableColumns"></mat-row>
                <mat-row *matRowDef="let groupItem; columns: ['groupItem']" class="inner-row"></mat-row>

            </mat-table>
        </ng-template>


    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button type="button" mat-icon-button *ngIf="canRemove()" (click)="remove()" [disabled]="loading">
            <mat-icon color="primary">delete</mat-icon>
        </button>
        
        <span fxFlex></span>

        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="workOrder.invoiced || loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
