import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { EmployeeCollection } from '@beaconlite/models/collections';
import { Employee } from '@beaconlite/models';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';

@Component({
  selector: 'app-employee-index',
  templateUrl: './employee-index.component.html',
  styles: [':host{height:100%}']
})
export class EmployeeIndexComponent implements OnInit {

  collection = new EmployeeCollection();
  employees = new CollectionDataSource(this.collection);

  selectedModelId: string = this.$stateGlobals.params.modelId;
  keyword: string;


  constructor(
    protected $stateGlobals: UIRouterGlobals,
    protected $state: StateService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this.onSearch();
  }

  onSearch()
  {
    const params: any = {
      // with:       'employee',
      order:      'asc', 
      order_by:   'name',
      type:       'admin',
    }

    if (this.keyword)
    {
        params.keyword = this.keyword;
    }

    this.collection.search(params);
  }

  onAdd(): void
  {
    this.$state.go('protected.employees.single', { modelId: 'new' });
  }

  onEdit(employee: Employee): void
  {
    this.selectedModelId = employee.id
    this.$state.go('protected.employees.single', { modelId: employee.id });
  }
}
