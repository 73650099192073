import * as moment from 'moment';
import type { Constructor } from '@beaconlite/types';
import { BaseModel } from '../Base.model'
import { dto } from './Dto.decorators';
import { date } from './Date.decorators';

export function Suspendable<T extends Constructor<BaseModel>>( Base: T ) {
    class SuspendableMixin extends Base {
        @dto() @date suspended_at?: number = null
    
        get suspended(): boolean {
            return !!this.suspended_at;
        }
    
        set suspended(value: boolean) {
            if( !!this.suspended_at === value ) { return; }
            
            // If the new value is true, update the timestamp
            if (value) {
                this.suspended_at = moment().unix();
            } else {
                // Clear the timestamp
                this.suspended_at = null;
            }

        }
    }

    // Decorators not allowed within TS class expressions (return class SuspendableMixin)
    // https://github.com/microsoft/TypeScript/issues/7342
    return SuspendableMixin;
}