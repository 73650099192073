<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign=" center">
        <h2 class="mat-title">Rental Override Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Select Item</mat-label>
                <input type="text"
                    [(ngModel)]="this.searchText"
                    name="searchTextField"
                    #searchTextField="ngModel"
                    [matAutocomplete]="rentalAuto"
                    (ngModelChange)="this.onQueryRentals()"
                    matInput
                    required
                    [disabled]="this.rentalOverride.exists()">
                <mat-autocomplete #rentalAuto="matAutocomplete"
                    (optionSelected)="this.onRentalSelected($event.option.value)"
                    [displayWith]="this.displaySelectedRental">
                    <mat-option *ngFor="let rental of promisedRentals | async" [value]="rental">
                        {{ rental.display_name }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="searchTextField.hasError('required')">Rental is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field fxFlex="50">
                <mat-label>Override Type</mat-label>
                <mat-select [(ngModel)]="this.rentalOverride.type"
                    name="overrideTypeField"
                    #overrideTypeField="ngModel"
                    [disabled]="!this.selectedRental"
                    required>
                    <mat-option selected [value]="this.overrideTypes.rate">Rate</mat-option>
                    <mat-option [value]="this.overrideTypes.percent">Percentage</mat-option>
                </mat-select>
                <mat-error *ngIf="overrideTypeField.hasError('required')">Override type is required.</mat-error>
            </mat-form-field>
            <div fxFlex="50"></div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px"
            *ngIf="this.rentalOverride.type === this.overrideTypes.percent" >
            <mat-form-field fxFlex="50">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="rentalOverride.discount"
                    name="typeField"
                    #typeField="ngModel"
                    [disabled]="!this.selectedRental"
                    required>
                    <mat-option [value]="true">Discount</mat-option>
                    <mat-option [value]="false">Charge</mat-option>
                </mat-select>
                <mat-error *ngIf="typeField.hasError('required')">Type is required.</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <mat-label>Value</mat-label>
                <input type="text"
                    [(ngModel)]="rentalOverride.percent_value"
                    name="percentValueField"
                    #percentValueField="ngModel"
                    percentInput
                    [minNum]="0"
                    [maxNum]="1"
                    [disabled]="!this.selectedRental"
                    required
                    matInput>
                <mat-error *ngIf="percentValueField.hasError('required')">Value is required.</mat-error>
                <mat-error *ngIf="percentValueField.hasError('minNum')">Value must be at least 0.00 %.</mat-error>
                <mat-error *ngIf="percentValueField.hasError('maxNum')">Value cannot exceed 100.00 %.</mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="this.rentalOverride.type === this.overrideTypes.rate">
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Rate</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalOverride.daily_rate"
                        (change)="this.onDailyRateChange()"
                        name="dailyRateField"
                        #dailyRateField="ngModel"
                        currencyInput
                        [minNum]="0"
                        required
                        [disabled]="!this.selectedRental"
                        matInput>
                    <mat-error *ngIf="dailyRateField.hasError('required')">Rate is required.</mat-error>
                    <mat-error *ngIf="dailyRateField.hasError('minNum')">Rate must be at least $ 0.00.</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field fxFlex="30">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="this.isPercentDiscount"
                        (selectionChange)="this.onIsPercentDiscountChange($event.value)"
                        name="chargeTypeField"
                        #isPercentDiscountField="ngModel"
                        [disabled]="!this.selectedRental"
                        required>
                        <mat-option [value]="true">Percent Discount</mat-option>
                        <mat-option [value]="false">Discounted Dollar Rate</mat-option>
                    </mat-select>
                    <mat-error *ngIf="isPercentDiscountField.hasError('required')">Type is required.</mat-error>
                </mat-form-field>
                <div fxFlex fxFlexAlign="center" fxFlex="70">
                    <p class="form-instruction">
                        Dollar values displayed approximate.
                    </p>
                </div>
            </div>
            
            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field fxFlex="20" *ngIf="this.isPercentDiscount">
                    <mat-label>Weekly Discount</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalOverride.weekly_discount"
                        name="weeklyDiscountField"
                        #weeklyDiscountField="ngModel"
                        [disabled]="!this.selectedRental"
                        percentInput
                        [minNum]="0"
                        [maxNum]="1"
                        required
                        matInput>
                    <mat-error *ngIf="weeklyDiscountField.hasError('required')">Weekly discount is required.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('minNum')">Weekly discount must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('maxNum')">Weekly discount cannot exceed 100.00 %.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="20" *ngIf="!this.isPercentDiscount">
                    <mat-label>Weekly Discount Dollar</mat-label>
                    <input type="text"
                        [(ngModel)]="this.weeklyDiscount"
                        (change)="this.onWeeklyDiscountPriceChange()"
                        name="weeklyDiscountField"
                        #weeklyDiscountField="ngModel"
                        [disabled]="!this.selectedRental"
                        currencyInput
                        [minNum]="0"
                        [maxNum]="this.rentalOverride.daily_rate"
                        required
                        matInput>
                    <mat-error *ngIf="weeklyDiscountField.hasError('required')">Weekly discount is required.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('minNum')">Weekly discount must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('maxNum')">Weekly discount cannot exceed price of rental.</mat-error>
                </mat-form-field>

                <div fxFlex fxFlexAlign="center">
                    <p class="form-instruction">
                        {{ this.calculateRate(this.rentalOverride.daily_rate, this.rentalOverride.weekly_discount, 1) | currencyFilter }} /day<br>
                        {{ this.calculateRate(this.rentalOverride.daily_rate, this.rentalOverride.weekly_discount, this.weekThreshold) | currencyFilter }} /week ({{ this.weekThreshold }} days)
                    </p>
                </div>

                <mat-form-field fxFlex="20" *ngIf="this.isPercentDiscount">
                    <mat-label>Monthly Discount</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalOverride.monthly_discount"
                        name="monthlyDiscountField"
                        #monthlyDiscountField="ngModel"
                        [disabled]="!this.selectedRental"
                        percentInput
                        [minNum]="0"
                        [maxNum]="1"
                        required
                        matInput>
                    <mat-error *ngIf="monthlyDiscountField.hasError('required')">Monthly discount is required.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('minNum')">Monthly discount must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('maxNum')">Monthly discount cannot exceed 100.00 %.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="20" *ngIf="!this.isPercentDiscount">
                    <mat-label>Monthly Discount Dollar</mat-label>
                    <input type="text"
                        [(ngModel)]="this.monthlyDiscount"
                        (change)="this.onMonthlyDiscountPriceChange()"
                        name="monthlyDiscountField"
                        #monthlyDiscountField="ngModel"
                        [disabled]="!this.selectedRental"
                        currencyInput
                        [minNum]="0"
                        [maxNum]="this.rentalOverride.daily_rate"
                        required
                        matInput>
                    <mat-error *ngIf="monthlyDiscountField.hasError('required')">Monthly discount is required.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('minNum')">Monthly discount must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('maxNum')">Monthly discount cannot exceed price of rental.</mat-error>
                </mat-form-field>

                <div fxFlex fxFlexAlign="center">
                    <p class="form-instruction">
                        {{ this.calculateRate(this.rentalOverride.daily_rate, this.rentalOverride.monthly_discount, 1) | currencyFilter }} /day<br>
                        {{ this.calculateRate(this.rentalOverride.daily_rate, this.rentalOverride.monthly_discount, this.monthThreshold) | currencyFilter }} /month ({{ this.monthThreshold }} days)
                    </p>
                </div>
            </div>
        </div>

        <div fxLayout="row">
            <h3 class="mat-subheading-2">Replacement Cost</h3>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="33">
                <mat-label>Retail Cost</mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalOverride.retail_cost"
                    name="retailCostField"
                    #retailCostField="ngModel"
                    currencyInput
                    [minNum]="0"
                    required
                    [disabled]="!this.selectedRental"
                    matInput>
                <mat-error *ngIf="retailCostField.hasError('required')">Retail cost is required.</mat-error>
                <mat-error *ngIf="retailCostField.hasError('minNum')">Retail cost must be at least $ 0.00.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="33">
                <mat-label>Discount</mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalOverride.replacement_discount"
                    name="replacementDiscountField"
                    #replacementDiscountField="ngModel"
                    percentInput
                    [minNum]="0"
                    [maxNum]="1"
                    [disabled]="!this.selectedRental"
                    matInput>
                <mat-error *ngIf="replacementDiscountField.hasError('minNum')">Replacement discount must be at least 0.00 %.</mat-error>
                <mat-error *ngIf="replacementDiscountField.hasError('maxNum')">Replacement discount cannot exceed 100.00 %.</mat-error>
            </mat-form-field>

            <div class="form-static-txt" fxFlex="33">
                <span class="form-static-txt__label">Total Replacement Cost</span>
                <span class="form-static-txt__content">{{ this.rentalOverride.retail_cost * (1 - this.rentalOverride.replacement_discount) | currencyFilter}}</span>
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="33">
                <mat-label>Rental Offset</mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalOverride.replacement_rental_offset"
                    name="replacementRentalOffsetField"
                    #replacementRentalOffsetField="ngModel"
                    percentInput
                    [minNum]="0"
                    [maxNum]="1"
                    matInput>
                <mat-error *ngIf="replacementRentalOffsetField.hasError('minNum')">Rental offset must be at least 0.00 %.</mat-error>
                <mat-error *ngIf="replacementRentalOffsetField.hasError('maxNum')">Rental offset cannot exceed 100.00 %.</mat-error>
            </mat-form-field>

            <div class="form-instruction" fxFlex="33">The rental offset uses a percentage of the total rental cost and subtracts it from the&nbsp;replacement&nbsp;cost.</div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <button mat-icon-button 
            type="button"
            *ngIf="this.canRemove()" 
            (click)="this.remove()" 
            [disabled]="this.loading" 
            color="primary">
            <mat-icon>delete</mat-icon>
        </button>

        <span fxFlex></span>

        <button mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" 
            mat-raised-button 
            color="accent" 
            [disabled]="this.loading">
            Save
        </button>
    </mat-dialog-actions>
</form>