import { VariantService } from './VariantService.model';
import { dto } from './mixins/Dto.decorators'
import { ItemOverride } from './ItemOverride.model';
export class ServiceOverride extends ItemOverride {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() variant_service_id: string = null;
    @dto() regular_rate: number = null;
    @dto() overtime_rate: number = null;

    @dto(VariantService) variant_service: VariantService = null;

    inheritVariant(variant: VariantService) 
    {
        this.variant_service_id   = variant.id;
        this.regular_rate         = variant.regular_rate;
        this.overtime_rate        = variant.overtime_rate;
        this.variant_service      = variant;
    }

    resetVariant() 
    {
        this.inheritVariant(new VariantService())
    }

    // TODO: is this used? Seems we never had a getServiceOverride() method on route map.
    // static async get(id: string): Promise<ServiceOverride> 
    // {
    //     const response = await AppInjector.get(RouteMap).getServiceOverride(id);
    //     return new ServiceOverride(response.data());
    // }
}

    /* TODO: The following methods don't exist on routeMap
    async save(): Promise<ServiceOverride> {
        const repsonse = this.exists()
            ? await this.routeMap.updateServiceOverride( this.id, this.flush() )
            : await this.routeMap.createServiceOverride( this.id, this.flush() );
        
        return this.map( repsonse.data() );
    }

    async discard(): Promise<ServiceOverride> {
        const reponse = await this.routeMap.discardServiceOverride( this.id );
        return this.map( response.data() );
    }
    */

