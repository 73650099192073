<app-dispatch-slip-single 
    *ngIf="!this.loading && this.canEdit"
    [modelId]="this.modelId"
    [stateOnClose]="'protected.upcoming-dispatches'">
</app-dispatch-slip-single>

<app-dispatch-single
    *ngIf="!this.loading && !this.canEdit"
    [modelId]="this.modelId"
    [stateOnClose]="'protected.upcoming-dispatches'">
</app-dispatch-single>
