import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Document } from '@beaconlite/models';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'document-tab-associate',
  templateUrl: './tab-associate.component.html',
})
export class TabAssociateComponent implements OnInit {

  @Input() items: {document: Document, selected: boolean}[];
  @Output() associationChanged = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  onOptionSelected(event: MatSelectionListChange)
  {
    const document: Document = event.options[0].value;
    const selected: boolean = event.options[0].selected;

    this.items.find(item => item.document === document).selected = selected;

    this.associationChanged.emit();
  }

  protected _sortItems()
  {
    this.items.sort(orderBy('document.created_at'));
  }
}
