import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { InvoicePrintComponent } from "./invoice-print.component";

export const InvoicePrintState: Ng2StateDeclaration = {
    name: 'print.invoice',
    url: '/print/invoice/:modelId',
    component: InvoicePrintComponent, 
    data: {
        pageTitle: 'Invoice',
        requiresAuth: true,
    },
    resolve: [
        { 
          token: 'modelId', 
          deps: [Transition],
          resolveFn: (transition: Transition) => transition.params().modelId,
        },
      ],
}
