import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Branch, Client, Department } from '@beaconlite/models';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { LineItem } from '@beaconlite/models/LineItem.model';
import { LineItemEditorData } from './line-item-editor-data.interface';
import { LineItemCollection } from '@beaconlite/models/collections/LineItem.collection';
import { LineItemDefinition } from '@beaconlite/models/LineItemDefinition.model';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

@Component({
  selector: 'app-line-item-editor',
  templateUrl: './line-item-editor.component.html',
  styleUrls: ['./line-item-editor.component.scss']
})
export class LineItemEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('searchTextField') searchTextField: NgModel;

  workOrder = this.data.workOrder;
  request = this.data.originalRequest;
  original = this.data.original;
  onUpdate = this.data.onRequestItemUpdate;
  onRemove = this.data.onRequestItemRemove;

  updating = false;
  loading = false;
  
  searchText = '';
  defaultDepartment: Department = null;
  promisedItems: Promise<LineItem[]>;
  lineItem = new LineItem();
  client: Client = null;
  branch: Branch = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: LineItemEditorData,
    public dialogRef: MatDialogRef<LineItemEditorComponent>,
    protected appData: AppData,
    protected dialogNotifications: DialogNotificationService,
    protected snackbarNotification: SnackbarNotificationService,
  ) { }
  
  async ngOnInit(): Promise<void> 
  {
    this.loading = true;

    if (!! this.original)
    {

      this.lineItem = this.original.copy();
      this.searchText = this.lineItem.name;
    }
    else
    {
      this.lineItem.position = this.request.line_items.length;
    }

    let departments;
    [departments, this.branch] = await Promise.all([
      this.appData.departments.getAll(),
      Branch.get(this.workOrder.branch.id)
    ]);
    this.defaultDepartment = departments.find(department => department.id);
    this.client = this.workOrder.client;
    this.loading = false;
  }

  onQueryLineItems(): void
  {
    if (!this.searchText) { 
      this.promisedItems = null;
      return;
    }

    this.promisedItems = (new LineItemCollection()).all({
      name: this.searchText,
      order: 'asc',
      exclude_disabled: true,
      client_id: this.client.id,
    });
  }
  
  displaySelectedItem = (item: LineItem): string =>
  {
    if (!!this.original && this.searchTextField.pristine)
    {
      this.searchTextField.control.setErrors(null);
      return this.original.name;
    }

    if (!item?.name) {
      return '';
    }

    return item.name;
  }

  async onItemSelected(itemDef: LineItemDefinition): Promise<void>
  {
    this.loading = true;

    this.lineItem.resetValues();

    if (itemDef)
    {
      this.lineItem.fillFromDefinition(itemDef);
    }

    this.loading = false;
  }

  canRemove(): boolean
  {
    return this.data.original && !this.data.original.locked;
  }

  async remove(): Promise<void> 
  {
    this.updating = true;

    if (!await this.dialogNotifications.removeConfirm()) { return };
    
    try
    {
      await this.onRemove(this.lineItem);
      this.dialogRef.close();
    }
    finally 
    {
      this.updating = false;
    }
  }

  async save(): Promise<void|boolean> 
  {
    if (this.editorForm.invalid) { return false; }

    if (!this.lineItem.line_item_definition_id)
    {
      this.lineItem.name = this.searchText;
    }

    this.updating = true;

    try
    {
      await this.onUpdate(this.lineItem);
      this.dialogRef.close();
    }
    finally 
    {
      this.updating = false;
    }
  }
}
