<div class="scroll__container" fxFlex>
    <div class="scroll__section">
        <div fxLayout="row" fxFlexFill>
            <div #graphContainer fxflex class="schedule-graph" (scroll)="onGraphScroll($event)">
                <svg class="graph__header"></svg>
                <svg class="graph__content"></svg>
                <div class="scale-controls">
                    <button type="button" class="split-button" mat-icon-button (click)="this.scaleDown()" [disabled]="!this.canScaleDown()">
                        <mat-icon>remove</mat-icon>
                    </button>
                    <button type="button" class="split-button" mat-icon-button (click)="this.scaleUp()" [disabled]="!this.canScaleUp()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>