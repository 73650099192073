import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateDialogData } from './date-dialog-data.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-date-dialog',
  templateUrl: './date-dialog.component.html',
})
export class DateDialogComponent implements OnInit {

  header = this.data.header;
  label = this.data.label;
  defaultDate = this.data.defaultDate;

  selectedDate = moment().startOf('day').toDate();
  resetTimes = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DateDialogData,
    public dialogRef: MatDialogRef<DateDialogComponent>,
  ) {  }

  ngOnInit(): void {}

  ok()
  {
    const values = {
      'date': moment(this.selectedDate).unix(),
      'resetTimes': this.resetTimes,
    }

    return this.dialogRef.close(values)
  }

}
