import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Branch } from '@beaconlite/models';
import { NgModel } from '@angular/forms';
import { RentalItemReport } from '@beaconlite/models/reports/RentalItemReport.model';
import { StateService } from '@uirouter/core';
import { BranchCollection } from '@beaconlite/models/collections';
import * as moment from 'moment';
import { D3Service } from '../../../../services/d3.service';
import { ReportFilter } from '../report-filter.interface';
import { ReportFilterEditorService } from '../report-filter-editor/report-filter-editor.service';

@Component({
  selector: 'app-rental-item',
  templateUrl: './rental-item.component.html',
})
export class RentalItemComponent implements OnInit {

  @ViewChild('branchSearchTextField') branchSearchTextField: NgModel;

  readonly REPORT_TYPE = 'rental_item';
  reportType = 'rental-item';

  selectedBranch: Branch = null;
  branchSearchText = '';
  selectedStartDate: Date = null;
  selectedEndDate: Date = null;

  promisedBranches: Promise<Branch[]>;
  
  filters: ReportFilter[] = [];

  report: RentalItemReport = null;

  listLevel = 0;
  listLevels: string[] = ['items', null, null, null];

  selectedGraphItem = null;

  loading = false;

  constructor(
    protected $state: StateService,
    protected D3: D3Service,
    protected renderer: Renderer2,
    protected filterEditor: ReportFilterEditorService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    
  }

  onReportTypeChanged(): void
  {
    this.$state.go(`protected.reports.${this.reportType}`)
  }

  onQueryBranches(): void
  {
    this.promisedBranches = (new BranchCollection()).all({name:this.branchSearchText});
  }

  clearSearchText(selection: Branch, field: string): null|void
  {
    if (!!selection) return; 
    
    switch(field)
    {
        case 'branch':
          this.branchSearchText = '';
        break;
    }
  }

  async addFilter(): Promise<void>
  {
    await this.filterEditor.open({
      filterTypes: ['rentals', 'clients'],
      onAddition: this._onAddition,
    });
  }

  protected _onAddition = (filter: ReportFilter) => 
  {
    this.filters.push(filter);
  }

  isListOption(slug: string, nestLevel: number): boolean
  {
    if (slug === 'work_orders')
    {
        return (this.listLevels.indexOf(slug) < 0 && // Not found AND
        (this.listLevels.indexOf('rental_requests') < 0 || this.listLevels.indexOf('rental_requests') >= nestLevel) ) || // rental_requests not found
        this.listLevels.indexOf(slug) >= nestLevel // Already last level of nesting
    }

    if (slug === 'clients')
    {
        return (this.listLevels.indexOf(slug) < 0 && // Not found AND
        (this.listLevels.indexOf('work_orders') < 0 || this.listLevels.indexOf('work_orders') >= nestLevel) &&
        (this.listLevels.indexOf('rental_requests') < 0 || this.listLevels.indexOf('rental_requests') >= nestLevel) ) || // work_orders AND rental_requests not found
        this.listLevels.indexOf(slug) >= nestLevel // Already last level of nesting
    }

    return this.listLevels.indexOf(slug) < 0 ||    // Not found OR
           this.listLevels.indexOf(slug) >= nestLevel // Already last level of nesting
  }

  addListLevel(): void|null
  {
    if ( this.listLevel == 3) { return; }

    this.listLevel++;
  }

  resetListLevels(level: number): void|null
  {
    let resetting = this.listLevels[level] == null
    if(resetting)
    {
        this.listLevel = level;
    }

    this.listLevels.forEach((value, index, array) => {
        if( index < level ) { return } // Don't reset upper levels
        if( array[index] == null ) { return } // Already null

        if( resetting ) {
            array[index] = null
        }
        else if( !this.isListOption(array[index], index) ) {
            // Reset everything after the first discrepancy
            if( !resetting ) {
                resetting = true
                this.listLevel = index
            }
            array[index] = null
        }
    })
  }


  onListChange(level: number): void
  {
    if( this.listLevels[level] == 'null' ) 
    {
      this.listLevels[level] = null
    }

    this.resetListLevels(level);
  }

  onExport(type: string = 'nested')
  {
    return this.onGenerate(type);
  }

  async onGenerate(exportType?: string): Promise<void>
  {
    let exporting = !!exportType;

    // Default params
    var params = {
        flat_export: exportType == 'flat',
        report_type: this.REPORT_TYPE,
        branch_id: this.selectedBranch ? this.selectedBranch.id : null,
        start_date: this.selectedStartDate ? moment(this.selectedStartDate).unix() : null,
        end_date: this.selectedEndDate ? moment(this.selectedEndDate).endOf('day').unix() : null,
        list_by: this.listLevels.filter((element) => !!element ),
    } as any

    // Grab the IDs from the chips array
    let client_filter = [];
    let rental_filter = [];
    this.filters.forEach(element => {
        switch(element.type)
        {
            case 'client':
                client_filter.push(element.id);
                break
            case 'rental':
                rental_filter.push(element.id);
                break
        }
    });

    // Add to params
    if(client_filter.length)
    {
      params.client_filter = client_filter
    }

    // Add to params
    if(rental_filter.length)
    {
      params.rental_filter = rental_filter
    }

    if(exporting)
    {
      RentalItemReport.export(params);
    }
    else
    {
      this.loading = true;

      this.report = await RentalItemReport.generate(params);

      if (! (this.report?.items)) { return; }

      let index = 0;

      if (!! this.selectedGraphItem)
      {
        index = this.report.items.findIndex(item => item.id == this.selectedGraphItem.id);
      }

      this.onItemClicked(this.report.items[index]);

      this.loading = false;
    }      
  }

  onItemClicked(item)
  {
    this.selectedGraphItem = item;
    // this._drawGraph();
  }

  onBranchSelected(branch: Branch)
  {
    this.selectedBranch = branch;
  }

  displaySelectedBranch(branch: Branch): string|null
  {
    return branch?.name || null;
  }

  onClearBranchSelection()
  {
    this.selectedBranch = null;
    this.branchSearchTextField.reset();
  }

  onRemoveFilterChip(filter: ReportFilter): null|void
  {
    if (! this.filters.length) return

    this.filters.splice(this.filters.indexOf(filter), 1);
  }

}
