import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { RentalDefinition } from '@beaconlite/models';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';
import { RentalDefinitionEditorService } from '../rental-definition-editor/rental-definition-editor.component';
import { prefs } from "@beaconlite/services/preferences.service";
import { AppData } from "@beaconlite/services/data/shared-data.departments";
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { GroupDefinitionEditorService } from '@beaconlite/components/group/group-definition-editor/group-definition-editor.component';
import { RentalGroupDefinition } from '@beaconlite/models/RentalGroupDefinition.model';
import { RentalGroupItem } from '@beaconlite/models/RentalGroupItem.model';
import { GroupItemConstants } from '@beaconlite/models/contracts/GroupItem.interface';
import { GroupDefinition } from '@beaconlite/models/contracts/GroupDefinition';
import { HigherOrderRentalResourceCollection } from '@beaconlite/models/collections/rental-resource-collections/HigherOrderRentalResource.collection';
import { ResourceItem } from '@beaconlite/models/contracts/ResourceItem.interface';
import { InventoryConstants } from '@beaconlite/models/constants/InventoryConstants';

@Component({
  selector: 'app-rental-index',
  templateUrl: './rental-index.component.html',
  styleUrls: ['./rental-index.component.scss']
})
export class RentalIndexComponent implements OnInit {

  collection = new HigherOrderRentalResourceCollection();
  rentals = new CollectionDataSource(this.collection);
  selectedModelId: string = this.$stateGlobals.params.modelId;
  keyword: string;
  disabledState: boolean;

  constructor(
    protected $stateGlobals: UIRouterGlobals,
    protected $state: StateService,
    protected rentalDefinitionEditor: RentalDefinitionEditorService,
    protected rentalGroupDefinitionEditor: GroupDefinitionEditorService,
    protected appData: AppData,
    protected snackbarNotification: SnackbarNotificationService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this.onSearch();
  }

  onSearch() 
  {
    const params: any = {
      order: 'asc',
      order_by: 'name',
    }

    if (this.disabledState != null)
    {
      params.disabled_state = this.disabledState;
    }

    if (this.keyword)
    {
      params.keyword = this.keyword;
    }

    this.collection.search(params);
  }

  selectDefinition(rentalResource: ResourceItem): void 
  {
    this.selectedModelId = rentalResource.id;
    if (rentalResource.item_level == InventoryConstants.INV_ITEM_DEF)
    {
      this.$state.go('protected.rentals.single', { modelId: rentalResource.id });
    }
    else
    {
      this.$state.go('protected.rentals.group-single', { modelId: rentalResource.id });
    }
  }

  async addRentalDefinition(): Promise<void>
  {
    const [departments, weekThreshold, monthThreshold] = await Promise.all([
      this.appData.departments.getAll(),
      prefs('pricing.week_threshold'), 
      prefs('pricing.month_threshold')
    ]);
    let rentalDefinition = new RentalDefinition();

    departments?.sort(orderBy('name'));
    rentalDefinition.department_id = departments.find(dep => dep.default).id;
    
    await this.rentalDefinitionEditor.open({
      original: rentalDefinition,
      departments: departments,
      weekThreshold: weekThreshold,
      monthThreshold: monthThreshold,
      onUpdate: this.createRentalDefinition
    });
  }

  createRentalDefinition = async (rentalDefinition: RentalDefinition): Promise<void> =>
  {
    await rentalDefinition.save();
    this.selectedModelId = rentalDefinition.id;
    this.$state.go('protected.rentals.single', { modelId: rentalDefinition.id });
  }

  async addRentalGroupDefinition(): Promise<void>
  {
    let rentalGroupDefinition = new RentalGroupDefinition();
    let departments = await this.appData.departments.getAll();
    departments?.sort(orderBy('name'));
    rentalGroupDefinition.department_id = departments.find(dep => dep.default).id;

    for (let i = 0; i < GroupDefinition.MIN_GROUP_COUNT ; i++)
    {
      rentalGroupDefinition.group_items.push(new RentalGroupItem({quantity: 1, ordinal: i}));
    }

    await this.rentalGroupDefinitionEditor.open({
      original: rentalGroupDefinition,
      departments: departments,
      onUpdate: this.createRentalGroupDefinition
    });
  }

  createRentalGroupDefinition = async (rentalGroupDefinition: RentalGroupDefinition): Promise<void> =>
  {
    await rentalGroupDefinition.save();
    this.selectedModelId = rentalGroupDefinition.id;
    this.$state.go('protected.rentals.group-single', { modelId: rentalGroupDefinition.id });
  }

  getRentalGroupIcon(): string
  {
    return GroupItemConstants.ITEM_DEF_ICON;
  }

  getRentalGroupDefIcon(): string
  {
    return GroupDefinition.GROUP_DEFINITION_ICON;
  }
}
