<form class="mat-dialog-editor rental-quantities-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign=" center">
        <h2>{{ this.request.source.formatted_serial_id }} | Item {{ this.displayType }}</h2>
    </mat-toolbar>
    <mat-dialog-content class="dialog-padding" fxLayout="column">
        <div fxLayout="row" fxFlex="25" *ngIf="!this.isDelivery">
            <mat-form-field>
                <mat-label>Return Date</mat-label>
                <input matInput 
                    [matDatepicker]="returnDatePicker" 
                    [(ngModel)]="this.returnDate" 
                    name="startedAtField"
                    #startedAtField="ngModel"
                    [min]="this.minimumReturnDate"
                    required>
                <mat-datepicker-toggle matSuffix [for]="returnDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #returnDatePicker></mat-datepicker>
                <mat-error *ngIf="startedAtField.hasError('required')">Date is required.</mat-error>
                <mat-error *ngIf="startedAtField.hasError('matDatepickerMin')">Date is too early.</mat-error>
            </mat-form-field>
        </div>

        <mat-table [dataSource]="this.request.rentals">
            <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef fxFlex="50">Item</mat-header-cell>
                <mat-cell *matCellDef="let rental" fxFlex="50">
                    <div fxLayout="column">
                        <span>{{ rental.source.name }}</span>
                        <span class="mat-caption">{{ rental.source.detail }}</span>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="planned">
                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Planned</mat-header-cell>
                <mat-cell class="mat-cell--numeric" *matCellDef="let rental">{{ rental.dispatched_quantity }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="action-label">
                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>{{ this.actionLabel }}</mat-header-cell>
                <mat-cell class="mat-cell--numeric" *matCellDef="let rental">{{ rental.actioned_quantity || 'N/A' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="confirmed">
                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Confirmed</mat-header-cell>
                <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let rental; let i = index;">
                    <mat-form-field>
                        <input type="number"
                            [(ngModel)]="rental.temps._confirmed_quantity"
                            name="confirmedQuantityField{{ i }}"
                            step="1"
                            min="0"
                            required
                            matInput>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="remaining">
                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Remaining</mat-header-cell>
                <mat-cell class="mat-cell--numeric" *matCellDef="let rental">{{ rental.source.quantity_remaining }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="rentalTableColumns"></mat-header-row>
            <mat-row *matRowDef="let rental; columns: rentalTableColumns"></mat-row>
        </mat-table>

        <div fxLayout="row" fxLayoutAlign="end">
            <button type="button" class="action-all-button" mat-raised-button (click)="this.actionAll()">
                {{ this.getAllButtonLabel }} All
            </button>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button [mat-dialog-close]>
            Cancel
        </button>   
        <button type="submit" 
            mat-raised-button 
            [disabled]="this.saving || this.hasIssueRestraints()"
            color="accent">
            Save
        </button>   
    </mat-dialog-actions>
</form>

