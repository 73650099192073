import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sage50TaxEditorData } from './sage50-tax-editor.interface';

@Component({
  selector: 'app-sage50-tax-editor',
  templateUrl: './sage50-tax-editor.component.html',
  styleUrls: ['./sage50-tax-editor.component.scss']
})
export class Sage50TaxEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  taxRate = this.data.taxRate;
  settings = this.data.settings;
  onUpdate = this.data.onUpdate;

  saving = false;
  taxNameInSage50 = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: Sage50TaxEditorData,
    public dialogRef: MatDialogRef<Sage50TaxEditorComponent>,
  ) { }

  ngOnInit(): void 
  {
    this.taxNameInSage50 = this.settings.taxes[this.taxRate.id];
  }

  async onSave(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) { return false; }  

    this.saving = true;
    try
    {
      await this.onUpdate(this.taxNameInSage50);
      this.dialogRef.close();
    }
    finally
    {
      this.saving = false;
    }
  }
}

