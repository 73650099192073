<mat-card class="large-card" fxLayout="column">
    <mat-card-title class="issue__header--{{ this.issue.severity }}" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex>
            <span class="mat-headline">Issue</span>
        </div>

        <div class="toolbar-status" fxLayout="column">
            <span class="toolbar-status__current">
                {{ !!this.issue.resolved_at ? 'Resolved' : 'Open' }}
            </span>
        </div> 
        <mat-icon *ngIf="this.workOrder.invoiced">lock_outline</mat-icon> 

        <button mat-icon-button [matMenuTriggerFor]="issueMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #issueMenu="matMenu">
            <button mat-menu-item 
                (click)="this.onEditIssue()">
                <mat-icon>edit</mat-icon>
                Edit
            </button>

            <button mat-menu-item 
                (click)="this.onAddNote()">
                <mat-icon>note_add</mat-icon>
                Add Note
            </button>
            
            <button mat-menu-item 
                *ngIf="!this.issue.resolved_at && !this.workOrder.invoiced"
                (click)="this.onResolve()">
                <mat-icon>assignment_turned_in</mat-icon>
                Resolve
            </button>

            <button mat-menu-item 
                *ngIf="!!this.issue.resolved_at && !this.workOrder.invoiced"
                (click)="this.onUnresolve()">
                <mat-icon>assignment_return</mat-icon>
                Un-resolve
            </button>

            <button mat-menu-item 
                *ngIf="!this.workOrder.invoiced"
                (click)="this.onDiscard()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
        </mat-menu>
    </mat-card-title>

    <!-- Issue properties -->
    <div class="large-card__lead">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="30" layoutPadding>
                <span class="mat-caption">Created by</span>
                <p class="mat-body-1">
                    {{ this.issue.issuer?.fullname }} {{ this.issue.created_at | dateFilter:'dateMedium' }}
                </p>
            </div>
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Severity</span>
                <p class="mat-body-1 capitalize">{{ this.issue.severity_display_name }}</p>
            </div>
            <div fxLayout="column" fxFlex *ngIf="!!this.issue.resolver && !!this.issue.resolved_at" layoutPadding>
                <span class="mat-caption">Resolved by</span>
                <p class="mat-body-1 capitalize">
                    {{ this.issue.resolver.fullname }} {{ this.issue.resolved_at | dateFilter:'dateMedium' }}
                </p>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex layoutPadding>
                <span class="mat-caption">Description</span>
                <p class="mat-body-1">{{ this.issue.description }}</p>
            </div>
        </div>
    </div>

    <mat-card-content class="large-card__content" fxLayout="column" [fxShow]="this.issue.hasNotes()">
        <mat-divider></mat-divider>
        <div fxLayout="row">
            <span class="mat-subheading-2">Notes</span>
        </div>
    
        <app-notes-table
            [notes]="this.issue.notes"
            (noteSelected)="this.onEditNote($event)">
        </app-notes-table>
    </mat-card-content>
</mat-card>
