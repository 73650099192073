import * as moment from "moment";
import 'moment-duration-format';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Account, Client, RentalOverride, ServiceOverride, TaxRate } from '@beaconlite/models';
import { ClientAccountEditorService } from '../client-account-editor/client-account-editor.service';
import { NgForm } from "@angular/forms";
import { SnackbarNotificationService } from "@beaconlite/services/notification/snackbar-notification.service";
import { StateService } from "@uirouter/core";
import { DialogNotificationService } from "@beaconlite/services/notification/dialog-notification.service";
import { UnsavedChangesService } from "@beaconlite/services/unsaved-changes.service";
import { prefs } from "@beaconlite/services/preferences.service";

@Component({
  selector: 'app-client-single',
  templateUrl: './client-single.component.html',
  styleUrls: ['./client-single.component.scss']
})
export class ClientSingleComponent implements OnInit {

  @Input() modelId: string;
  @ViewChild('editorForm') editorForm: NgForm;

  loading = false;
  locked = false;
  title = 'New Client';
  businessStart: string;
  businessEnd: string;

  client = new Client({});
  taxes: TaxRate[];

  clientAccountsTableColumns = ['name', 'email'];

  constructor(
    protected clientAccountEditor: ClientAccountEditorService,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
    protected unsavedChanges: UnsavedChangesService,
    protected $state: StateService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this.taxes = await TaxRate.getAll();
    this.businessStart = await prefs('company.business_hours.start');
    this.businessEnd = await prefs('company.business_hours.end');

    if (!! this.modelId && this.modelId !== 'new')
    {

      this.lockAndLoad();

      try
      {
        this.client = await Client.get(this.modelId);
        this.title = this.client.name;
      }
      finally
      {
        this.unlockAndUnload();
      }
    }

    // Register unsaved changes guard
    this.unsavedChanges.setDiscardCheck(() => !this.editorForm.pristine && !this.editorForm.submitted)
  }

  hasAccounts(): boolean
  {
    return this.client.accounts.length > 0;
  }

  canAddAccounts(): boolean
  {
    return this.client.exists() && !this.client.suspended && !this.client.discarded;
  }

  async save(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) return false;

    const modelExists: boolean = this.client.exists();

    this.lockAndLoad();

    try
    {
      await this.client.save();
      this.snackbarNotification.saved();

      if (! modelExists)
      {
        this.$state.go('protected.clients.single', { modelId: this.client.id });
      }
    }
    finally
    {
      this.unlockAndUnload();
      this.client.reload();
    }
  }

  async restore(): Promise<void|boolean>
  {
    if (! await this.dialogNotification.recoverConfirm()) return;

    this.lockAndLoad();

    try
    {
      await this.client.recover();
    }
    finally
    {
      this.unlockAndUnload();
    }

  }

  async discard(): Promise<void|boolean>
  {
    if (! await this.dialogNotification.discardConfirm()) return;

    this.lockAndLoad();

    try
    {
      await this.client.discard();
    }
    finally
    {
      this.unlockAndUnload();
      this.$state.go('protected.clients.single', { modelId: 'new' });
    }
  }

  onRentalUpdate = async (override: RentalOverride, updatedOverride: RentalOverride): Promise<void> =>
  {
    updatedOverride?.exists()
      ? override.mapAttributes(updatedOverride.flushAttributes())
      : this.client.rental_overrides.push(updatedOverride);

    await this.save();
  }

  onRentalRemove = async (override: RentalOverride): Promise<void> =>
  {
    this.client.removeRentalOverride(override);
    await this.save();
  }

  onServiceUpdate = async (override: ServiceOverride, updatedOverride: ServiceOverride): Promise<void> =>
  {
    updatedOverride?.exists()
      ? override.mapAttributes(updatedOverride.flushAttributes())
      : this.client.service_overrides.push(updatedOverride);
  
    await this.save();
  }

  onServiceRemove = async (override: ServiceOverride): Promise<void> =>
  {
    this.client.removeServiceOverride(override);
    await this.save();
  }

  onEditAccount(account: Account = null) 
  {
    const onAccountUpdate = async (updatedAccount: Account): Promise<void> =>
    {
      updatedAccount?.exists()
      ? account.mapAttributes(updatedAccount.flushAttributes())
      : this.client.accounts.push(updatedAccount);

      await this.save();
    }

    const onAccountRemove = async (): Promise<void> => 
    {
      this.client.removeAccount(account);
      await this.save();
    }

    if (! account)
    {
      account = new Account({
        active: true,
        type: 'client',
        client_id: this.client.id,
      });
    }

    this.clientAccountEditor.open({
      client: this.client,
      original: account,
      onAccountUpdate,
      onAccountRemove,
    });
  }

  lockAndLoad()
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload()
  {
    this.loading = false;
    this.locked = this.client.discarded;
  }
}
