import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { IntegrationBranchDefaultsEditorData } from "./integration-branch-defaults-editor-data.interface";
import { IntegrationBranchDefaultsEditorComponent } from "./integration-branch-defaults-editor.component";

@Injectable({
    providedIn: 'root'
})
export class IntegrationBranchDefaultsEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: IntegrationBranchDefaultsEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            data
          }

        return this.dialog.open(IntegrationBranchDefaultsEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
