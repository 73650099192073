import { CurrencyPipe, getLocaleCurrencyCode } from '@angular/common';
import { 
    Inject, 
    LOCALE_ID, 
    Pipe, 
    PipeTransform 
} from '@angular/core';

@Pipe({
    name: 'currencyFilter'
})
export class CurrencyFilterPipe implements PipeTransform {
    readonly CURRENCY_CODE: string;

    constructor(
        @Inject(LOCALE_ID) private locale: string, 
        private currencyPipe: CurrencyPipe
    ) 
    {
        this.CURRENCY_CODE = getLocaleCurrencyCode(this.locale);
    }

    /**
     * Formatting function for currency (dollar) values.
     * Converts an interger (cents) value into a decimal (dollar) value.
     * 
     * @param {string|number} value 
     * @param {string} [negativesStyle] 
     */
    transform(value: string | number, negativesStyle?: 'parentheses' | 'suffix'): string 
    {
        const parsedValue: number = Number(value)
        let currencyString: string = this.currencyPipe.transform(parsedValue / 100, this.CURRENCY_CODE);

        if (value >= 0 || !negativesStyle)
        {
            return currencyString;
        }

        switch (negativesStyle)
        {
            case 'parentheses':
                currencyString = `(${ currencyString.slice(1, currencyString.length) })`
                break;
            case 'suffix':
                currencyString = `${ currencyString.slice(1, currencyString.length) } CR`
                break;
        }

        return currencyString;
    }
}
