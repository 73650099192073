import { BaseModel } from './Base.model' 
import { Attachment } from './Attachment.model'
import { Dispatch } from './Dispatch.model'
import { Email } from './Email.model'
import { InvoiceConstants } from './InvoiceConstants'
import { TaxRate } from './TaxRate.model'
import { ResourceLock } from './ResourceLock.model'
import { InvoiceParams } from "./contracts/InvoiceParams.interface";
import { LockableModel } from "./contracts/LockableModel.interface";
import { Discardable } from "./mixins/Discardable.mixin";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";
import { ShortIdUtility } from "./utilities/ShortId.utility";
import { InvoicedClient } from "./contracts/invoiced/InvoicedClient.interface";
import { InvoicedBranch } from "./contracts/invoiced/InvoicedBranch.interface";
import { InvoicedCharge } from "./contracts/invoiced/InvoicedCharge.interface";
import { InvoicedService } from "./contracts/invoiced/InvoicedService.interface";
import { InvoicedNote } from "./contracts/invoiced/InvoicedNote.interface";
import { InvoicedDispatch } from "./contracts/invoiced/InvoicedDispatch.interface";
import { InvoicedExternalId } from "./contracts/invoiced/InvoicedExternalId.interface";
import { InvoicedRentalRequest } from './InvoicedRentalRequest.model'
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { InvoicedLineItemRequest } from './InvoicedLineItemRequest.model'

// Not possible to use constants to declare types.
export type InvoiceType = 'invoice' | 'quote' | 'estimate';

const MixinBase = Discardable( BaseModel );
export class Invoice extends MixinBase implements LockableModel {

    constructor(attributes: any = {}) {
        super();
        this.init(attributes);
        this.setPrefix();
    }

    static TYPE_INVOICE     = InvoiceConstants.TYPE_INVOICE;
    static TYPE_QUOTE       = InvoiceConstants.TYPE_QUOTE;
    static TYPE_ESTIMATE    = InvoiceConstants.TYPE_ESTIMATE;

    static STATE_DISCARDED  = InvoiceConstants.STATE_DISCARDED;
    static STATE_VOIDED     = InvoiceConstants.STATE_VOIDED;
    static STATE_SENT       = InvoiceConstants.STATE_SENT;
    static STATE_INVOICED   = InvoiceConstants.STATE_INVOICED;

    // Not read-only because of quote and estimate types.
    protected static _prefix: string = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_INVOICE);
    protected _prefix: string = Invoice._prefix;
 
    @dto() id?: string = null;
    @dto() serial_id?: string = null;
    @dto() client_id?: string = null;
    @dto() work_order_id?: string = null;
    @dto() type?: InvoiceType = null;
    @dto() locked?: boolean = false;
    @dto() voided?: boolean = false;
    @dto() @date started_at?: number = null;
    @dto() @date ended_at?: number = null;
    @dto() tax_rates?: TaxRate = null;
    @dto() subtotal?: number = null;
    @dto() taxes?: number = null;
    @dto() total?: number = null;
    @dto() reason_voided?: string = null;
    @dto() reason_discarded?: string = null;
    @dto() @date issued_at?: number = null;
    @dto() @date due_at?: number = null;
    @dto() @date sent_at?: number = null;
    @dto() @date last_sent_at?: number = null;
    @dto() @date marked_as_sent_at?: number = null;

    @dto() work_order? = null;
    @dto() accounting_exportable? = [];
    @dto(Attachment) pdf?: Attachment = null;
    @dto(Email) emails?: Email[] = [];
    @dto(InvoicedRentalRequest) rental_requests: InvoicedRentalRequest[] = [];

    // Invoiced relation interfaces.
    @dto() client?: InvoicedClient = null;
    @dto() branch?: InvoicedBranch = null;
    @dto() charges?: InvoicedCharge[] = [];
    @dto() services?: InvoicedService[] = [];
    @dto() line_item_requests?: InvoicedLineItemRequest[] = [];
    @dto() notes?: InvoicedNote[] = [];
    @dto() dispatches?: InvoicedDispatch[] = [];
    @dto() external_ids?: InvoicedExternalId[] = [];
    
    lock: ResourceLock;
    lockType = ResourceLock.TYPE_INVOICE;

    get formatted_serial_id(): string 
    {
        if (! this.serial_id )
        {
            // Intentionally a string. In the future this will be made translatable.
            return 'PREVIEW';
        }

        const prefix = this._prefix || Invoice._prefix;
        return `${prefix}-${this.serial_id}`;
    }

    get state_name(): string 
    {
        if (!! this.discarded_at)
        {
            return Invoice.STATE_DISCARDED;
        }
        else if (this.voided)
        {
            return Invoice.STATE_VOIDED;
        }
        else if (!! this.sent_at || !! this.marked_as_sent_at)
        {
            return Invoice.STATE_SENT;
        }
        else
        {
            return Invoice.STATE_INVOICED;
        }
    }

    get state_letter(): string 
    {
        return this.state_name[0];
        
    }

    static async get(id: string): Promise<Invoice> 
    {
        const response = await AppInjector.get(RouteMap).getInvoice(id);
        return new Invoice(response.data());
    }

    static async preview(params: InvoiceParams): Promise<Invoice> 
    {
        const response = await AppInjector.get(RouteMap).previewInvoice(params);
        return new Invoice(response.data());
    }

    static async create(params: InvoiceParams): Promise<Invoice> 
    {
        const response = await AppInjector.get(RouteMap).createInvoice(params);
        return new Invoice(response.data());
    }

    async void(reason: string): Promise<Invoice> 
    {
        const response = await this.routeMap.voidInvoice(this.id, { reason_voided: reason });
        return this.map(response.data());
    }

    canVoid(): boolean 
    {
        return this.voided === false && !this.discarded_at;
    }

    async discard(reason: string): Promise<Invoice> 
    {
        const response = await this.routeMap.discardInvoice(this.id, { reason_discarded: reason });
        return this.map(response.data());
    }

    canDiscard(): boolean 
    {
        return !this.discarded_at;
    }

    async getPdf(): Promise<Invoice> 
    {
        const response = await this.routeMap.getInvoicePdf(this.id);
        return this.map(response.data());
    }

    async send(data: any): Promise<Invoice> 
    {
        const response = await this.routeMap.sendInvoice(this.id, data);
        return this.map(response.data());
    }

    canSend(): boolean 
    {
        return !this.discarded_at;
    }

    canMarkSent(): boolean 
    {
        return !this.last_sent_at && !this.marked_as_sent_at;
    }

    async markSent(): Promise<Invoice> 
    {
        const response = await this.routeMap.markSentInvoice(this.id);
        return this.map(response.data());
    }

    get last_sent(): Email | null {
        if (! this.emails.length) { return; }
        return this.emails.sort( orderBy('-sent_at') )[0];
    }

    hasAccountingExportable(): boolean 
    {
        return !!this.accounting_exportable.length;
    }

    getAccountingExportable() 
    {
        return this.accounting_exportable[0];
    }

    hasNotes(): boolean
    {
        return !!this.notes.length;
    }    

    hasRentalRequests(): boolean
    {
        return !!this.rental_requests.length;
    }    

    hasServices(): boolean
    {
        return !!this.services.length;
    }    
    hasCharges(): boolean
    {
        return !!this.charges.length;
    }    

    displayType(): string 
    {
        let type: string;

        switch( this.type )
        {   
            // Labels are intentionally strings. In the future they will be translatable values.
            case Invoice.TYPE_INVOICE:
                type = 'Invoice';
                break;
            case Invoice.TYPE_QUOTE:
                type = 'Quote';
                break;
            default:
                type = 'Estimate';
        }

        return type;
    }

    flattenDispatchCharges(): Array<Dispatch> 
    {
        return this.dispatches.reduce( (charges, dispatch) => charges.concat(dispatch.charges), [])
    }

    protected setPrefix(): void 
    {
        if (this.type == Invoice.TYPE_QUOTE)
        {
            this._prefix = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_QUOTE);
        }
        else if (this.type == Invoice.TYPE_ESTIMATE)
        {
            this._prefix = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_ESTIMATE);
        }
    }
}
