import { Account } from "./Account.model";
import { Attachment } from "./Attachment.model";
import {BaseModel } from './Base.model';
import { Discardable } from "./mixins/Discardable.mixin";
import { date } from './mixins/Date.decorators';
import { dto } from './mixins/Dto.decorators';
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";

const MixinBase = Discardable( BaseModel );

export class Signature extends MixinBase {
    
    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id?: string =  null;
    @dto() name?: string =  null;
    @dto() captured_by?: string =  null;
    @dto() @date captured_at?: number =  null;

    @dto() captured?: string = null;

    @dto(Account) captor?: Account =  null;
    @dto(Attachment) attachment?: Attachment = null;

    static async get(id: string): Promise<Signature> 
    {
        const response = await AppInjector.get(RouteMap).getSignature(id);
        return new Signature(response.data()); 
    }
    
    async save(): Promise<Signature> {
        if (! this.exists()) throw "Invalid signature creation method";

        const response = await this.routeMap.updateSignature(this.id, this.flush());
        return this.map(response.data());
    }

    async discard(): Promise<Signature> 
    {
        const response = await this.routeMap.discardSignature(this.id);
        return this.map(response.data());
    }
}