<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="onSend()" fxLayout="column" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">SMS Notifications</h2>
    </mat-toolbar>

    <mat-dialog-content>
        <!-- INDEX -->
        <div class="index-panel" fxLayout="column" fxFlex="25">
            <div class="index-wrapper" fxLayout="column">
                <mat-progress-bar mode="indeterminate" [fxShow]="this.sending"></mat-progress-bar>
                <mat-list dense fxFlex *ngIf="this.sendableModel.hasNotifications()">
                    <mat-list-item
                        *ngFor="let notification of this.sendableModel.notifications"
                        [class.index-list__item--selected]="this.selectedNotification?.id == notification.id"
                        (click)="this.selectNotification(notification)">
                        <div class="mat-list-text">
                            {{ notification.sent_at | dateFilter:'dateTimeMedium' }}
                        </div>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </mat-list>
                <div *ngIf="!this.sendableModel.hasNotifications()" layoutPadding>
                    <span>No notifications sent!</span>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end">
                <button class="send-new-fab"
                    mat-mini-fab 
                    color="secondary"
                    (click)="this.newNotification()"
                    [disabled]="this.sending || !this.selectedNotification">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>

        <mat-divider vertical></mat-divider>

        <!-- INFO PANEL -->
        <div class="info-panel" fxLayout="column" fxFlex fxLayoutGap="16px">
            <span class="mat-subheading-1" *ngIf="this.selectedNotification">
                {{ this.selectedNotification.sent_at | dateFilter:'dateTimeMedium' }}
            </span>
            <span class="md-subhead" *ngIf="!this.selectedNotification">
                New Notification 
            </span>

            <mat-table [dataSource]="this.selectedNotification.recipients" *ngIf="this.selectedNotification">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                    <mat-cell *matCellDef="let recipient">{{ this.getEmployeeName(recipient) }}</mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef>Number</mat-header-cell>
                    <mat-cell *matCellDef="let recipient">{{ recipient.phone }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="received">
                    <mat-header-cell fxFlex="15" *matHeaderCellDef>Received</mat-header-cell>
                    <mat-cell fxFlex="15" *matCellDef="let recipient">
                        <mat-icon color="primary" [fxShow]="recipient.message_status == 'delivered' || recipient.message_status == 'confirmed'">
                            check_circle_outline
                        </mat-icon>
                        <mat-icon [fxShow]="recipient.error_code"
                            color="primary"
                            [matTooltip]="this.getErrorCodeLabel(recipient)"
                            matTooltipPosition="right">
                            error_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="confirmed">
                    <mat-header-cell fxFlex="15" *matHeaderCellDef>Confirmed</mat-header-cell>
                    <mat-cell fxFlex="15" *matCellDef="let recipient">
                        <mat-icon [fxShow]="recipient.confirmed_at" color="primary">check_circle_outline</mat-icon>
                    </mat-cell>
                </ng-container>
            
                <mat-header-row *matHeaderRowDef="recipientsTableColumns"></mat-header-row>
                <mat-row *matRowDef="let recipient; columns: recipientsTableColumns"></mat-row>
            </mat-table>              

            <mat-form-field fxFlex>
                <mat-chip-list #toChips>
                    <mat-chip *ngFor="let phone of this.to"
                        [removable]="true"
                        (removed)="this.removeChip(this.to, phone)">
                        {{ phone }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Tel:"
                        [matChipInputAddOnBlur]="true"
                        [matChipInputFor]="toChips"
                        (matChipInputTokenEnd)="addChip(this.to, $event)">
                </mat-chip-list>
            </mat-form-field>

            <mat-form-field fxFlex>
                <textarea name="messageField"
                    #messageField="ngModel"
                    [(ngModel)]="this.message"
                    [disabled]="this.selectedNotification"
                    required
                    maxlength="1400"
                    rows="8"
                    matInput>
                </textarea>
                <mat-hint align="start">Standard message rates may apply</mat-hint>
                <mat-hint align="end">{{ this.messageCharacterCounter() }}</mat-hint>
                <mat-error *ngIf="messageField.hasError('required')">Message is required.</mat-error>
            </mat-form-field>
    
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="this.sending" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit"
            mat-raised-button  
            color="accent" 
            *ngIf="!this.selectedNotification"
            [disabled]="this.sending">
            Send
        </button>
        <button type="button"
            mat-raised-button  
            color="accent" 
            *ngIf="this.selectedNotification"
            (click)="this.duplicateNotification()">
            Duplicate
        </button>
    </mat-dialog-actions>
</form>
