<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Line Item Definition Editor</h2>
    </mat-toolbar>
    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            
            <mat-form-field fxFlex> 
                <mat-label>Name</mat-label>
                <input type="text"
                    [(ngModel)]="this.lineItemDefinition.name"
                    name="nameField"
                    #nameField="ngModel"
                    required
                    [disabled]="this.locked"
                    maxlength="255"
                    matInput>
                <mat-hint align="end">{{ this.lineItemDefinition.name?.length || 0 }} / 255</mat-hint>
                <mat-error *ngIf="nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex fxFlex="80">
                <mat-label>Description</mat-label>
                <textarea [(ngModel)]="this.lineItemDefinition.description"
                        name="descriptionField" 
                        rows="4"
                        [disabled]="this.locked"
                        matInput>
                </textarea>
            </mat-form-field>
            <div fxFlex="20" fxLayout="column" fxLayoutGap="10px">
                <mat-slide-toggle name="disableAddingToWorkOrdersField"
                    [(ngModel)]="this.lineItemDefinition.disabled"
                    [disabled]="this.locked">
                    Disabled
                </mat-slide-toggle>
                <div class="form-instruction">Disabled items cannot be added to Work Orders</div>   
            </div>

        </div>

        <div fxLayout="row" fxLayoutGap="20px">

            <mat-form-field fxFlex>
                <mat-label>Quantity</mat-label>
                <input type="number"
                    [(ngModel)]="this.lineItemDefinition.quantity"
                    name="quantityField"
                    #quantityField="ngModel"
                    required
                    [minNum]="0"
                    [disabled]="this.locked"
                    matInput>
                <mat-error *ngIf="quantityField.hasError('required')">Quantity is required.</mat-error>
                <mat-error *ngIf="quantityField.hasError('minNum')">Quantity must be at least 0.</mat-error>
            </mat-form-field>
            
            <mat-form-field fxFlex>
                <mat-label>Code</mat-label>
                <input type="text"
                    [(ngModel)]="this.lineItemDefinition.code"
                    name="codeField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.lineItemDefinition.code?.length || 0 }} / 255</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Tags</mat-label>
                <input type="text"
                    [(ngModel)]="this.lineItemDefinition.tags"
                    name="tagField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.lineItemDefinition.tags?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row"  fxLayoutGap="20px" *ngIf="this.departments?.length > 1">
            <mat-form-field fxFlex>
                <mat-label>Default Department</mat-label>
                <mat-select [(ngModel)]="this.lineItemDefinition.department_id"
                    name="departmentField"
                    [disabled]="this.locked"
                    required>
                    <mat-option *ngFor="let department of this.departments" [value]="department.id">
                        {{ department.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayoutGap="20px">

            <mat-form-field fxFlex fxLayoutGap="20px">
                <mat-label>Rate</mat-label>
                <input type="text"
                    [(ngModel)]="this.lineItemDefinition.rate"
                    name="regularRateField"
                    #regularRateField="ngModel"
                    [disabled]="this.locked"
                    currencyInput
                    minNum="0"
                    required
                    matInput>
                <mat-error *ngIf="regularRateField.hasError('required')">Regular rate is required.</mat-error>
                <mat-error *ngIf="regularRateField.hasError('minNum')">Regular rate must be at least $ 0.00.</mat-error>
            </mat-form-field>


        </div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button (click)="this.onClose()">
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
