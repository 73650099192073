import { BaseModel } from './Base.model';
import { Discardable } from './mixins/Discardable.mixin';
import { dto } from './mixins/Dto.decorators'

const MixinBase = Discardable( BaseModel )

export type OverrideType = 'rate'|'percent';

export type OverrideItemType = 'rental'|'service';
export class ItemOverride extends MixinBase {

    static ITEM_RENTAL: OverrideItemType ='rental';
    static ITEM_SERVICE: OverrideItemType = 'service';
    
    static TYPE_RATE: OverrideType ='rate';
    static TYPE_PERCENT: OverrideType = 'percent';
    
    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() type: OverrideType = ItemOverride.TYPE_RATE;
    @dto() discount: boolean = true;
    @dto() percent_value: number = 0;

    displayType(): 'Charge' | 'Discount' {
        return !!this.discount
            ? 'Discount'
            : 'Charge';
    }

}
