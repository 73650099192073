<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2>Line Item Editor</h2>
        <span *ngIf="this.lineItem.locked">
            <mat-icon color="primary">lock_outline</mat-icon>
        </span>
    </mat-toolbar> 
    <mat-progress-bar mode="indeterminate" [fxShow]="loading"></mat-progress-bar> 

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap=20px>
            <mat-form-field fxFlex="75">
                <mat-label>Select Item</mat-label>
                <input type="text"
                    [(ngModel)]="searchText"
                    name="searchTextField"
                    #searchTextField="ngModel"
                    [matAutocomplete]="searchTextAuto"
                    (ngModelChange)="onQueryLineItems()"
                    required
                    matInput
                    [disabled]="request.locked || loading || updating">
                <mat-autocomplete #searchTextAuto="matAutocomplete"
                    (optionSelected)="onItemSelected($event.option.value)"
                    [displayWith]="displaySelectedItem">
                    <mat-option *ngFor="let item of promisedItems | async" [value]="item">
                        <app-request-search-option 
                            [text]="item.name"
                            [thumbnail]=null
                            [icon]="item.icon">
                    </app-request-search-option>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="searchTextField.hasError('required')">Line item is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Quantity</mat-label>
                <input type="number"
                    step="1"
                    [(ngModel)]="this.lineItem.quantity"
                    name="quantityField"
                    #quantityField="ngModel"
                    required
                    [minNum]="1"
                    matInput
                    [disabled]="request.locked || loading || updating">
                    <mat-error *ngIf="quantityField.hasError('required')">Quantity is required.</mat-error>
                    <mat-error *ngIf="quantityField.hasError('minNum')">Quantity must be at least 1.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Detail</mat-label>
                <input type="text"
                    [(ngModel)]="this.lineItem.detail"
                    name="detailField"
                    #detailField="ngModel"
                    maxlength="255"
                    matInput
                    [disabled]="this.lineItem.locked || loading || updating">
                <mat-hint align="end">{{ this.lineItem.detail?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <div fxLayout="column" fxFlex>
                <p class="form-instruction">
                    Rate<br>
                    {{ this.lineItem.rate | currencyFilter }}
                </p>
                <mat-form-field fxFlex="20">
                    <mat-label>Override</mat-label>
                    <input 
                        [(ngModel)]="this.lineItem.rate_override"
                        name="dailyRateOverrideField"
                        #dailyRateOverrideField="ngModel"
                        required
                        currencyInput
                        [minNum]="0"
                        [disabled]="this.lineItem.locked || loading || updating"
                        matInput>
                    <mat-error *ngIf="dailyRateOverrideField.hasError('required')">Rate is required.</mat-error>
                    <mat-error *ngIf="dailyRateOverrideField.hasError('minNum')">Rate must be at least $ 0.00.</mat-error>
                </mat-form-field>    
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <button type="button" mat-icon-button (click)="remove()" *ngIf="canRemove()" [disabled]="loading || updating">
            <mat-icon color="primary">delete</mat-icon>
        </button>
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="updating" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading || updating">
            Save
        </button>
    </mat-dialog-actions>
</form>
