import { HttpResponse } from "../../services/network/http-response";
import { Account } from "../Account.model";
import { BaseCollection } from "./Base.collection";

export class AccountCollection extends BaseCollection<Account> {
    constructor(options?: any) {
        super(Account, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return this._routeMap.searchWorkOrders(params);
    }
}
