<mat-card class="medium-card item-definition__card" fxLayout="column" *ngIf="!this.loading">
    <mat-card-title fxFlex fxLayoutAlign="space-between center">

        <div class="thumbnail-container" *ngIf="!this.original">
            <img *ngIf="this.original.thumbnail !== null" 
                [src]="this.original.thumbnail.small.url">
            <mat-icon *ngIf="this.original.thumbnail === null" color="primary">image</mat-icon>
        </div>

        <span class="mat-headline">{{ this.original.name }}</span>
        <span class="mat-caption rental-state-label" *ngIf="!!this.original.deleted_at">
            <span>Discarded</span>
        </span>

        <div fxLayoutAlign="center center">
            <button mat-icon-button [matMenuTriggerFor]="mainMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #mainMenu="matMenu">
                <button mat-menu-item
                    *ngIf="!this.original.deleted_at && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.edit()">
                    <mat-icon>edit</mat-icon>
                    Edit
                </button>
                <button mat-menu-item
                    *ngIf="!this.original.deleted_at && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.delete()">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>
            </mat-menu>
        </div>
    </mat-card-title>

    <div class="item-definition__card-inner">
        <mat-divider></mat-divider>
        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="100" layoutPadding>
                <span class="mat-caption">Description</span>
                <p class="mat-body-1">{{ this.original.description }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Code</span>
                <p class="mat-body-1">{{ this.original.code }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Tags</span>
                <p class="mat-body-1">{{ this.original.tags }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" *ngIf="this.departments?.length > 1">
            <div fxLayout="column" fxFlex="32" layoutPadding>
                <span class="mat-caption">Default Department</span>
                <p class="mat-body-1">{{ this.departmentName }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="100" layoutPadding>
                <span class="mat-subheading-2">Items</span>
                <mat-table class="mat-selectable-table"
                    #rentalTable
                    [dataSource]="this.original.group_items">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef fxFlex="35">Item</mat-header-cell>
                        <mat-cell *matCellDef="let item" fxFlex="35"><mat-icon>{{ item.icon }}</mat-icon>{{ item.name }}</mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                        <mat-cell *matCellDef="let item">{{ item.quantity }}</mat-cell>
                    </ng-container>
        
                    <mat-header-row *matHeaderRowDef="groupItemTableColumns"></mat-header-row>
                    <mat-row *matRowDef="let item; columns: groupItemTableColumns"></mat-row>
                </mat-table>
            </div>
        </div>

    </div>
</mat-card>
