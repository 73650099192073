import { Injectable } from "@angular/core";
import { DispatchedRentalRequest, InvoicedRentalRequest, RentalRequest } from "@beaconlite/models";
import { DispatchedFlattenedRentals } from "@beaconlite/models/contracts/DispatchedFlattenedRentals.interface";
import FlattenedRentals from "@beaconlite/models/contracts/FlattenedRentals.interface";
import { InvoicedFlattenedMinimumAdjustments } from "@beaconlite/models/contracts/InvoicedFlattenedMinimumAdjustments.interface";
import { InvoicedFlattenedRateAdjustments } from "@beaconlite/models/contracts/InvoicedFlattenedRateAdjustments.interface";
import { InvoicedFlattenedRentals } from "@beaconlite/models/contracts/InvoicedFlattenedRentals.interface";
import { InvoicedFlattenedReturns } from "@beaconlite/models/contracts/InvoicedFlattenedReturns.interface";

@Injectable({
    providedIn: 'root'
})
export class RentalFlattenBuilder {

    computeFlattenedRentals(request: RentalRequest): FlattenedRentals {
        const flattenedRentals: FlattenedRentals = new Map();
    
        for (let i = 0; i < request.rentals.length; i+=1) {
            const rental = request.rentals[i];
    
            let key = rental.variant_rental_id + '_' + rental.name + '_' + rental.pricing_type + '_' + rental.daily_rate_override;
            let requestRental = flattenedRentals.get(key);
    
            if (requestRental === undefined) {
                requestRental = {
                    rentals: [],
                    quantity: 0,
                    quantity_remaining: 0,
                    pricing_type: rental.pricing_type,
                    daily_rate_override: rental.daily_rate_override,
                };
    
                flattenedRentals.set(key, requestRental);
            }
    
            requestRental.quantity += rental.quantity;
            requestRental.quantity_remaining += rental.quantity_remaining;
            requestRental.rentals.push(rental);
        }
        
        return flattenedRentals;
    }

    computeDispatchedFlattenedRentals(request: DispatchedRentalRequest): DispatchedFlattenedRentals {
        const flattenedRentals: DispatchedFlattenedRentals = new Map();
    
        for (let i = 0; i < request.rentals.length; i+=1) {
    
            const definitionId = request.rentals[i].source.variant_rental_id;
            let requestRental = flattenedRentals.get(definitionId);
    
            if (requestRental === undefined) {
                requestRental = {
                    dispatchedRentals: [],
                    dispatched_quantity: 0,
                    actioned_quantity: 0
                };
    
                flattenedRentals.set(definitionId, requestRental);
            }
    
            requestRental.dispatched_quantity += request.rentals[i].dispatched_quantity;
            requestRental.actioned_quantity += request.rentals[i].actioned_quantity;
            requestRental.dispatchedRentals.push(request.rentals[i]);
        }
        return flattenedRentals;
    }

    computeInvoicedFlattenedRentals(request: InvoicedRentalRequest): InvoicedFlattenedRentals {
        const flattenedRentals: InvoicedFlattenedRentals = new Map();
    
        const propList = [
            'name',
            'detail',
            'period_days',
            'applied_rate',
            'pricing_type',
        ]
    
        for (let i = 0; i < request.rentals.length; i+=1) {
            const rental = request.rentals[i];

            const key = propList.reduce((acc, curVal) => { return acc + (rental[curVal] ?? '')}, '');
    
            let requestRental = flattenedRentals.get(key);
    
            if (requestRental === undefined) {
                requestRental = {
                    rentals: [],
                    name: rental.name,
                    detail: rental.detail,
                    period_days: rental.period_days,
                    quantity_invoiced: 0,
                    applied_rate: rental.applied_rate,
                    pricing_type: rental.pricing_type,
                    rental_total: 0,
                };
    
                flattenedRentals.set(key, requestRental);
            }
    
            requestRental.quantity_invoiced += rental.quantity_invoiced;
            requestRental.rental_total += rental.rental_total;
            requestRental.rentals.push(rental);
        }
        
        return flattenedRentals;
    }
    
    computeInvoicedFlattenedReturns(request: InvoicedRentalRequest): InvoicedFlattenedReturns {
        const flattenedReturns: InvoicedFlattenedReturns = new Map();
    
        const propList = [
            // Not including variant_rental_id because on an invoice it doesn't help to have the difference.
            'name',
            'detail',
            'period_days',
            'returned_at',
            'applied_rate',
            'pricing_type',
            'replacement_charge',
            'lost'
        ]
    
        for (let i = 0; i < request.returns.length; i+=1) {
            const aReturn = request.returns[i];
    
            const key = propList.reduce((acc, curVal) => { return acc + (aReturn[curVal] ?? '')}, '');
    
            let requestReturn = flattenedReturns.get(key);
    
            if (requestReturn === undefined) {
                requestReturn = {
                    returns: [],
                    name: aReturn.name,
                    detail: aReturn.detail,
                    period_days: aReturn.period_days,
                    period_ended_at: aReturn.period_ended_at,
                    quantity_returned: 0,
                    applied_rate: aReturn.applied_rate,
                    pricing_type: aReturn.pricing_type,
                    rental_total: 0,
                    replacement_charge: aReturn.replacement_charge,
                    total: 0,
                    lost: aReturn.lost
                };
    
                flattenedReturns.set(key, requestReturn);
            }
    
            requestReturn.quantity_returned += aReturn.quantity_returned;
            requestReturn.rental_total += aReturn.rental_total;
            requestReturn.total += aReturn.total;
            requestReturn.returns.push(aReturn);
        }
        
        return flattenedReturns;
    }

    computeInvoicedFlattenedRateAdjustments(request: InvoicedRentalRequest): InvoicedFlattenedRateAdjustments {
        const flattenedAdjustments: InvoicedFlattenedRateAdjustments = new Map();
    
        // Flatten all rate adjustments to just name and description. Rate adjustments can be flattened across returns and rentals.
        for (let i = 0; i < request.rentals.length; i+=1) {
            const rental = request.rentals[i];

            if (rental.rate_adjustment === null) {
                continue;
            }

            const key = rental.name + '_' + rental.rate_adjustment.description
    
            let adjustment = flattenedAdjustments.get(key);
    
            if (adjustment === undefined) {
                adjustment = {
                    name: rental.name,
                    description: rental.rate_adjustment.description,
                    expected_amount: 0,
                    previous_amount: 0,
                    rental_total: 0,
                    amount: 0
                };
    
                flattenedAdjustments.set(key, adjustment);
            }

            adjustment.expected_amount += rental.rate_adjustment.expected_amount;
            adjustment.previous_amount += rental.rate_adjustment.previous_amount;
            adjustment.rental_total += rental.rental_total;
            adjustment.amount += rental.rate_adjustment.amount
        }

        for (let i = 0; i < request.returns.length; i+=1) {
            const aReturn = request.returns[i];

            if (aReturn.rate_adjustment === null) {
                continue;
            }

            const key = aReturn.name + '_' + aReturn.rate_adjustment.description
    
            let adjustment = flattenedAdjustments.get(key);
    
            if (adjustment === undefined) {
                adjustment = {
                    name: aReturn.name,
                    description: aReturn.rate_adjustment.description,
                    expected_amount: 0,
                    previous_amount: 0,
                    rental_total: 0,
                    amount: 0
                };
    
                flattenedAdjustments.set(key, adjustment);
            }

            adjustment.expected_amount += aReturn.rate_adjustment.expected_amount;
            adjustment.previous_amount += aReturn.rate_adjustment.previous_amount;
            adjustment.rental_total += aReturn.rental_total;
            adjustment.amount += aReturn.rate_adjustment.amount
        }

        return flattenedAdjustments
    }

    computeInvoicedFlattenedMinimumAdjustments(request: InvoicedRentalRequest): InvoicedFlattenedMinimumAdjustments {
        const flattenedAdjustments: InvoicedFlattenedMinimumAdjustments = new Map();
    
        for (let i = 0; i < request.returns.length; i+=1) {
            const aReturn = request.returns[i];

            if (aReturn.minimum_adjustment === null) {
                continue;
            }

            const key = aReturn.name + '_' + aReturn.minimum_charge;
            let adjustment = flattenedAdjustments.get(key);
    
            if (adjustment === undefined) {
                adjustment = {
                    name: aReturn.name,
                    minimum_charge: aReturn.minimum_charge,
                    quantity_returned: 0,
                    previous_amount: 0,
                    rental_total: 0,
                    amount: 0
                };
    
                flattenedAdjustments.set(key, adjustment);
            }

            adjustment.quantity_returned += aReturn.quantity_returned;
            adjustment.previous_amount += aReturn.minimum_adjustment.previous_amount;
            adjustment.rental_total += aReturn.rental_total;
            adjustment.amount += aReturn.minimum_adjustment.amount;
        }

        return flattenedAdjustments
    }
}
