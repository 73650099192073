import { BaseModel } from "./Base.model";
import { Discardable } from "./mixins/Discardable.mixin";
import { HasItemOverrides } from './mixins/HasItemOverrides.mixin'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";

const MixinBase = HasItemOverrides (
                  Discardable( BaseModel ) );

export class Branch extends MixinBase {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() id?: string =  null;
    @dto() default?: boolean =  false;
    @dto() name?: string =  null;
    @dto() address_1?: string =  null;
    @dto() address_2?: string =  null;
    @dto() city?: string =  null;
    @dto() region?: string =  null;
    @dto() postal_code?: string =  null;
    @dto() country?: string =  'Canada';
    @dto() phone?: string =  null;
    @dto() toll_free?: string =  null;
    @dto() fax?: string =  null;
    @dto() email?: string =  null;
    @dto() website?: string =  null;

    async save(): Promise<Branch> {
        const response = ( this.exists() )
            ? await this.routeMap.updateBranch(this.id, this.flush())
            : await this.routeMap.createBranch(this.flush());
        
        return this.map(response.data());
    }

    async discard(): Promise<Branch> {
        const response = await this.routeMap.discardBranch(this.id);
        return this.map(response.data());
    }

    async recover(): Promise<Branch> {
        const response = await this.routeMap.recoverBranch(this.id);
        return this.map(response.data());
    }

    static async get(id: string): Promise<Branch>
    {
        const response = await AppInjector.get(RouteMap).getBranch(id);
        return new Branch(response.data())
    }
}
 