import { BaseModel } from "./Base.model";
import { DispatchedRental } from "./DispatchedRental.model";
import { DispatchedRentalGroup } from "./DispatchedRentalGroup.model";
import { RentalRequest } from "./RentalRequest.model";
import { DispatchedRequest } from "./contracts/DispatchedRequest.interface";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import IRentalRequest from "./contracts/IRentalRequest.interface";
import { RequestItem } from "./contracts/RequestItem.interface";
import { RentalGroupBuilderHelper } from "@beaconlite/services/RentalGroupBuilder.helper";

export class DispatchedRentalRequest extends BaseModel implements DispatchedRequest, IRentalRequest {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
        this.runRentalsSetup();
    }

    @dto() id?: string = null;
    @dto() dispatch_id?: string = null;
    @dto() rental_request_id?: string = null;
    @dto() @date dispatched_started_at?: number = null;
    @dto() @date dispatched_ended_at?: number = null;
    @dto() @date reviewed_at?: number = null;
    @dto() @date returned_at?: number = null;

    @dto(RentalRequest) source?: RentalRequest = null;
    @dto(DispatchedRental) rentals: DispatchedRental[] = [];    
    @dto(DispatchedRentalGroup) rental_groups: DispatchedRentalGroup[] = [];

    protected _ungrouped_rentals: DispatchedRental[] = [];
    protected _request_items: RequestItem[] = [];
    protected _num_unique_rentals: number = 0;
    
    protected rentalGroupBuilder: RentalGroupBuilderHelper = new RentalGroupBuilderHelper();

    get request_items(): RequestItem[] {
        return this._request_items;
    }

    protected setRequestItems(): void {
        this._request_items = [].concat(this.rental_groups).concat(this._ungrouped_rentals);
    }

    protected runRentalsSetup(): void {
        this.setUngroupedRentals();
        this.setRentalsOnGroup();
        this.setRequestItems();
        this.setNumUniqueRentals();
    }

    protected setUngroupedRentals(): void {
        this._ungrouped_rentals = this.rentalGroupBuilder.getUngroupedRentals(this);
    }

    protected setRentalsOnGroup(): void {
        this.rentalGroupBuilder.setRentalsOnGroups(this);
    }

    get num_unique_rentals(): number {
        return this._num_unique_rentals;
    }

    setNumUniqueRentals(): void {

        const unqiueRentals: Set<string> = new Set();

        for (let i = 0; i < this.rentals.length; i+=1) {

            const definitionId = this.rentals[i].source.variant_rental_id;

            if (!unqiueRentals.has(definitionId)) {
                unqiueRentals.add(definitionId)
            }
        }

        this._num_unique_rentals = unqiueRentals.size;
    }

    static fromRequest(source: RentalRequest | DispatchedRentalRequest): DispatchedRentalRequest 
    {
        // Already the right type
        if ( source instanceof DispatchedRentalRequest ) { return source; }

        let request = new DispatchedRentalRequest();
        request.source = source;
        request.rental_request_id = source.id;
        request.dispatched_started_at = source.started_at;
        request.dispatched_ended_at = source.ended_at;
        request.rentals = source.rentals.filter( rental => rental.quantity_remaining > 0)
            .map( rental => DispatchedRental.fromRental(rental) );
        request.rental_groups = source.rental_groups.filter( rentalGroup => rentalGroup.rentals.some(rental => rental.quantity_remaining > 0))
            .map(rentalGroup => DispatchedRentalGroup.fromRentalGroup(rentalGroup));
    
        request.runRentalsSetup();
        return request;    
    }

    async review(): Promise<DispatchedRentalRequest> 
    {
        const response = await this.routeMap.reviewDispatchedRequest(this.id, this.flush());
        return this.map(response.data());
    }
}
