<mat-card class="large-card" fxLayout="column">
    <mat-card-title fxLayout="row" fxLayoutAlign=" center">
        <div fxLayout="column">
            <span class="mat-headline">{{ displayService() }}</span>
            <span class="mat-caption">{{ service.formatted_serial_id }}</span>
        </div>

        <span fxFlex></span>

        <div class="toolbar-status" fxLayout="column">
            <span class="toolbar-status__current">{{ service.state_name }}</span>
        </div>
        <mat-icon *ngIf="service.locked">lock_outline</mat-icon>

        <button mat-icon-button [matMenuTriggerFor]="serviceMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #serviceMenu="matMenu">
            <button mat-menu-item (click)="onEdit()">
                <mat-icon>edit</mat-icon>
                Edit
            </button>

            <button mat-menu-item (click)="onDuplicate()">
                <mat-icon>content_copy</mat-icon>
                Duplicate
            </button>

            <button mat-menu-item [disabled]="!service.canAddCharges()" (click)="onAddCharge()">
                <mat-icon>attach_money</mat-icon>
                Additional Charge
            </button>

            <button mat-menu-item [disabled]="!service.canAddNotes()" (click)="onAddNote()">
                <mat-icon>note_add</mat-icon>
                Add Note
            </button>

            <button mat-menu-item *ngIf="!service.locked" (click)="remove()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
        </mat-menu>
    </mat-card-title>

    <!-- Service properties -->

    <div class="large-card__lead">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="40" layoutPadding>
                <span class="mat-caption">Period</span>
                <p class="mat-body-1">{{ displayPeriod() }}</p>
            </div>
            <div fxLayout="column" fxFlex="60" layoutPadding>
                <span class="mat-caption">Location</span>
                <p class="mat-body-1">{{ service.location }}</p>
            </div>
        </div>

        <div fxLayout="row" *ngIf="service.ordered_by">
            <div fxLayout="column" fxFlex="50" layoutPadding>
                <span class="mat-caption">Ordered By</span>
                <p class="mat-body-1">{{ service.ordered_by }}</p>
            </div>
        </div>

        <div fxLayout="row" *ngIf="shouldShowDepartment">
            <div fxLayout="column" fxFlex="50" layoutPadding>
                <span class="mat-caption">Department</span>
                <p class="mat-body-1">{{ service.department?.name }}</p>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Quantity</span>
                <p class="mat-body-1">{{ service.quantity }}</p>
            </div>

            <div fxLayout="column" fxFlex="20" [fxHide]="service.pricing_type == 'unit'" layoutPadding>
                <span class="mat-caption">Time</span>
                <p class="mat-body-1">
                    <span>{{ service.regular_time | timeFilter }}</span>
                    <span>&nbsp;@&nbsp;</span>
                    <span>{{ service.regular_rate_override | currencyFilter }}</span>
                </p>
            </div>

            <div fxLayout="column" fxFlex="20" [fxHide]="service.pricing_type == 'unit'" layoutPadding>
                <span class="mat-caption">Overtime</span>
                <p class="mat-body-1">
                    <span>{{ service.over_time | timeFilter }}</span>
                    <span>&nbsp;@&nbsp;</span>
                    <span>{{ service.overtime_rate_override | currencyFilter }}</span>
                </p>
            </div>

            <div fxLayout="column" fxFlex="20" [fxHide]="service.pricing_type == 'unit'" layoutPadding>
                <span class="mat-caption">Travel Time</span>
                <p class="mat-body-1">{{ service.travel_time | timeFilter }}</p>
            </div>

            <div fxLayout="column" fxFlex="60" [fxShow]="service.pricing_type == 'unit'" layoutPadding>
                <span class="mat-caption">Unit Rate</span>
                <p class="mat-body-1">
                    <span>{{ service.regular_rate_override | currencyFilter }}</span>
                </p>
            </div>

            <div fxLayout="row" class="large-card__total" fxLayoutAlign="end center" fxFlex layoutPadding>
                <span class="large-card__total--label">Total</span>
                <p class="large-card__total--amount">{{ service.estimated_total | currencyFilter }}</p>
            </div>
        </div>
    </div>

    <mat-card-content class="large-card__content" fxLayout="column">

        <!-- Charges -->
        
        <mat-divider *ngIf="service.hasCharges()"></mat-divider>

        <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="service.hasCharges()">
            <span class="mat-subheading-2">Charges</span>
        </div>
        <app-charges-table [fxShow]="service.hasCharges()"
            [charges]="service.charges"
            (chargeSelected)="onEditCharge($event)">
        </app-charges-table>

         <!-- Notes -->

        <mat-divider *ngIf="service.hasNotes()"></mat-divider>

        <div fxLayout="row" fxLayoutAlign="space-between start" [fxShow]="service.hasNotes()">
            <span class="mat-subheading-2">Notes</span>
        </div>
        <app-notes-table [fxShow]="service.hasNotes()"
            [notes]="service.notes"
            (noteSelected)="onEditNote($event)">
        </app-notes-table>

    </mat-card-content>
</mat-card>
