
import { Ng2StateDeclaration } from '@uirouter/angular';
import { ForgotComponent } from './forgot.component';

/**
 * This state allows the user to set their application preferences
 */
export const ForgotState: Ng2StateDeclaration = {
  
  name: 'forgotPassword',
  url: '/password/forgot',
  component: ForgotComponent,
  // Mark this state as requiring authentication.  See ../global/requiresAuth.hook.js.
  // Marty: This comes from the UI router hybrid sample app
  data: { 
    requiresAuth: false,
    pageTitle: 'Forgot Password',
   }
}
