import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ReportFilterEditorData } from './report-filter-editor-data.interface';
import { ReportFilterEditorComponent } from './report-filter-editor.component';

@Injectable({
    providedIn: 'root'
})
export class ReportFilterEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: ReportFilterEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            data
          }

        return this.dialog.open(ReportFilterEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
