import { HttpResponse } from "../../services/network/http-response";
import { Department } from "../Department.model";
import { BaseCollection } from "./Base.collection";

export class DepartmentCollection extends BaseCollection<Department> {

    constructor(options?: any) {
        super(Department, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return this._routeMap.searchDepartments(params);
    }
}
