import { BaseModel } from "./Base.model";
import { ChargeDefinition } from "./ChargeDefinition.model";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { Helpers as $helpers } from '../services/helpers.service'

export class Charge extends BaseModel {
    
    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() charge_definition_id?: string = null;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() invoice_id?: string = null;
    @dto() label?: string = '';
    @dto() description?: string = null;
    @dto() recurring?: boolean = false;
    @dto() @date recurring_until?: number = null;
    @dto() discount?: boolean = false;
    @dto() percent?: boolean = false;
    @dto() flat_value?: number = 0;
    @dto() percent_value?: number = 0.0;

    get name(): string {
        return this.label
    }

    inheritDefinition(definition: ChargeDefinition): void {
        this.charge_definition_id   = definition.id;
        this.label                  = definition.label;
        this.description            = definition.description;
        this.recurring              = definition.recurring;
        this.recurring_until        = definition.recurring_until;
        this.discount               = definition.discount;
        this.percent                = definition.percent;
        this.flat_value             = definition.flat_value;
        this.percent_value          = definition.percent_value;
    }

    // TODO: find a better way of doing this
    resetDefinition(): void {
        this.charge_definition_id   = null;
        this.label                  = '';
        this.description            = null;
        this.recurring              = false;
        this.recurring_until        = null;
        this.discount               = false;
        this.percent                = false;
        this.flat_value             = 0;
        this.percent_value          = 0.0;
    }

    duplicate(): Charge {
        const duplicate = this.copy();

        // Reset Service properties used for existing models
        duplicate.id                = null;
        duplicate.locked            = false;
        duplicate.invoiced          = false;
        duplicate.invoice_id        = null;
        duplicate.created_at        = null;
        duplicate.updated_at        = null;

        return duplicate;
    }

    /**
     * Stop the charge recurrence.
     *
     * @returns {Promise}
     */
    async stopRecurrence(): Promise<Charge> {
        // Charge must be recurring and have at least one invoice to be stopped.
        if ( this.invoiced || !this.locked || !this.recurring)
        {
            return this;
        }

        const response = await this.routeMap.stopCharge(this.id);
        return this.map(response.data());
    }

    displayType(): 'Charge' | 'Discount' {
        return !!this.discount
            ? 'Discount'
            : 'Charge';
    }
}