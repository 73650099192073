import { BaseModel } from "./Base.model";
import { Charge } from "./Charge.model";
import { Department } from "./Department.model";
import { DispatchConstants } from "./DispatchConstants";
import { Note } from "./Note.model";
import { ServiceOverride } from "./ServiceOverride.model";
import { Request } from "./contracts/Request.interface";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { Helpers as $helpers } from "../services/helpers.service"
import { ShortIdUtility } from "./utilities/ShortId.utility";
import { orderBy } from "@beaconlite/utilities/Sort.utility";
import { VariantService } from ".";
import { ItemOverride } from "./ItemOverride.model";

export class Service extends BaseModel implements Request {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    protected _prefix: string = Service._prefix;
    protected static readonly _prefix: string = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_SERVICE);

    static STATE_NONE = 'none';
    static STATE_INVOICED = 'invoiced';

    static LOCK_LEVEL = {
        [Service.STATE_NONE] : 0,
        [DispatchConstants.STATE_DRAFT] : 0,
        [DispatchConstants.STATE_REQUESTED] : 0,
        [DispatchConstants.STATE_DISPATCHED] : 1,
        [DispatchConstants.STATE_ACTIONED] : 1,
        [DispatchConstants.STATE_COMPLETED] : 2,
        [Service.STATE_INVOICED] : 3,
    }

    static QTY_INPUT_STEP: string            = '1'
    static QTY_INPUT_STEP_UNIT: string       = '0.01'
    static QTY_STEP_ERROR: string            = 'Must be a whole number.'
    static QTY_STEP_ERROR_UNIT: string       = 'Must be 2 decimal places or less.'

    @dto() id?: string = null;
    @dto() serial_id?: string = null;
    @dto() department_id?: string = null;
    @dto() work_order_id?: string = null;
    @dto() variant_service_id?: string = null;
    @dto() state?: string = Service.STATE_NONE;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() invoice_id?: string = null;
    @dto() name?: string = null;
    @dto() detail?: string = null;
    @dto() code?: string = null;
    @dto() location?: string = null;
    @dto() ordered_by?: string = null;
    @dto() quantity?: number = 1;
    @dto() unit_short?: string = 'unit';
    @dto() unit_long?: string = 'unit';
    @dto() pricing_type?: string = 'auto';
    @dto() overtime_rate_applied?: boolean = true;
    @dto() time_override?: boolean = false;
    @dto() regular_rate_override?: number = 0;
    @dto() regular_rate?: number = 0;
    @dto() overtime_rate_override?: number = 0;
    @dto() overtime_rate?: number = 0;
    @dto() @date started_at?: number = null;
    @dto() @date ended_at?: number = null;
    @dto() regular_time?: number = 0;
    @dto() over_time?: number = 0;
    @dto() travel_time?: number = 0;
    @dto() planned_quantity?: number = null;
    @dto() @date planned_started_at?: number = null;
    @dto() @date planned_ended_at?: number = null;
    @dto() planned_regular_time?: number = null;
    @dto() planned_over_time?: number = null;
    @dto() planned_travel_time?: number = null;
    @dto() estimated_total?: number = 0;

    @dto(Department) department: Department =  null;
    @dto(Charge) charges: Array<Charge> = [];
    @dto(Note) notes: Array<Note> = [];

    get dispatch_notes(): Array<Note> {
        return this.notes.filter( note => note.type == Note.TYPE_DISPATCH);
    }

    get state_name(): string {
        let state = ''

        switch(this.state)
        {
            case DispatchConstants.STATE_DRAFT:
                state = 'Scheduled';
                break;
            case DispatchConstants.STATE_REQUESTED:
                state = 'Requested';
                break;
            case DispatchConstants.STATE_DISPATCHED:
                state = 'Dispatched';
                break;
            case DispatchConstants.STATE_ACTIONED:    
                state = 'Actioned';
                break;
            case DispatchConstants.STATE_COMPLETED:
                state = 'Completed';
                break;
            case Service.STATE_INVOICED:         
                state = 'Invoiced';
                break;
            default:
                state = '';
        }

        return state;
    }

    get lock_level(): number {
        return Service.LOCK_LEVEL[this.state] || 0;
    }

    get formatted_serial_id(): string {
        if (! this.serial_id) return '';
        return Service.formatSerialId(this.serial_id);
    }

    static formatSerialId(serialId: string)
    {
        let padded = $helpers.zeroPadLeft(serialId, 6);
        const prefix = this._prefix || Service._prefix;
        return `${prefix}-${padded}`;
    }

    inheritVariant(variant: VariantService): void {
        this.department_id          = variant.service_definition.department_id;
        this.variant_service_id     = variant.id;
        this.name                   = variant.display_name;
        this.code                   = variant.code;
        this.pricing_type           = variant.service_definition.default_pricing_type;
        this.regular_rate_override  = variant.regular_rate;
        this.regular_rate           = variant.regular_rate;
        this.overtime_rate_override = variant.overtime_rate;
        this.overtime_rate          = variant.overtime_rate;
        this.unit_short             = variant.service_definition.unit_short;
        this.unit_long              = variant.service_definition.unit_long;
    }

    resetDefinition(): void {
        this.department_id          = null;
        this.variant_service_id     = null;
        this.name                   = null;
        this.code                   = null;
        this.pricing_type           = 'auto';
        this.regular_rate_override  = 0;
        this.regular_rate           = 0;
        this.overtime_rate_override = 0;
        this.overtime_rate          = 0;
        this.unit_short             = 'unit';
        this.unit_long              = 'unit';
    }

    applyOverride(override: ServiceOverride): void {

        if ( override.type === ItemOverride.TYPE_RATE )
        {
            this.regular_rate_override  = override.regular_rate;
            this.overtime_rate_override = override.overtime_rate;
        }
        else if ( override.type === ItemOverride.TYPE_PERCENT )
        {
            const percentMultiplier = 1 + override.percent_value * ( override.discount ? -1 : 1 );

            this.regular_rate_override = Math.round( this.regular_rate * percentMultiplier );
            this.overtime_rate_override = Math.round( this.overtime_rate * percentMultiplier );
        }
    }

    duplicate(overrides: any = {}): Service 
    {
        let duplicate = new Service( this.flushAttributes() );

        duplicate.started_at = overrides.started_at || duplicate.started_at;
        // If the start was overriden, grab the end from the override as well
        duplicate.ended_at = overrides.started_at ? overrides.ended_at : duplicate.ended_at;

        // Reset computed and unique values back to model defaults
        duplicate.id                = null;
        duplicate.work_order_id     = null;
        duplicate.locked            = false;
        duplicate.invoiced          = false;
        duplicate.state             = Service.STATE_NONE;
        duplicate.invoice_id        = null;
        duplicate.created_at        = null;
        duplicate.updated_at        = null;

        this.charges.forEach( charge => {
            duplicate.addCharge( charge.duplicate() );
        });

        this.notes.forEach( note => {
            duplicate.addNote( note.duplicate() );
        });

        return duplicate;
    }

    canAddCharges(): boolean {
        return !this.invoiced;
    }

    hasCharges(): boolean {
        return !!this.charges.length;
    }

    addCharge(charge: Charge = new Charge()): Charge 
    {
        this.charges.push(charge);

        return charge;
    }

    removeCharge(model: Charge): void {
        const index = this.charges.indexOf(model);

        if (index >= 0)
        {
            this.charges.splice(index, 1);
        }
    }

    canAddNotes(): boolean {
        return !this.invoiced;
    }

    hasNotes(): boolean {
        return !!this.notes.length;
    }

    hasNote(model: Note): boolean {
        return this.notes.indexOf(model) >= 0;
    }

    addNote(note: Note = new Note()): Note 
    {
        this.notes.unshift(note);

        this.notes.sort( orderBy('created_at') );

        return note;
    }

    removeNote(model: Note): void {
        const index = this.notes.indexOf(model);

        if (index >= 0)
        {
            this.notes.splice(index, 1);
        }
    }
}
