import { BaseModel } from "../Base.model";
import { AppInjector } from "../../services/app-injector.service";
import { FileDownload } from "../../services/file-download.service";
import { HttpResponse } from "../../services/network/http-response";
import { RouteMap } from "../../services/network/route-map.service";
import { dto } from "../mixins/Dto.decorators";
import { ReportData } from "./contracts/ReportData.interface";
import { LostReportItem } from "./contracts/LostReportItem.interface";

export class LostItemReport extends BaseModel {

    constructor(attributes: object)
    {
        super();
        this.init(attributes);
    }

    @dto() params: ReportData = {};
    @dto() data: LostReportItem[] = [];
    @dto() items: LostReportItem[] = [];

    static async generate(data: ReportData): Promise<LostItemReport>
    {
        const response: HttpResponse = await AppInjector.get(RouteMap).generateReport(data);
        return new LostItemReport({
            params: data,
            data: response.data(),
            items: response.data(),
        });
    }

    static async export(data: ReportData): Promise<void>
    {   
        const response: HttpResponse = await AppInjector.get(RouteMap).exportReport(data);
        AppInjector.get(FileDownload).asBase64(response);
    }

    nextLayer(currentLayer: string): string|null
    {
        const layers = this.params.list_by
        const index = layers.indexOf(currentLayer)

        if (index < 0 || index+1 >= layers.length ) { return null }

        return layers[index+1]
    }

    _processData(report)
    {
        return
    }
}