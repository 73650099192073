import { BaseModel } from "./Base.model";
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";
export class Department extends BaseModel {
    
    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() default?: boolean =false;
    @dto() name?: string = null;

    static async get(id: string): Promise<Department> 
    {
        const response = await AppInjector.get(RouteMap).getDepartment(id);
        return new Department(response.data());
    }

    async save(): Promise<Department> 
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateDepartment(this.id, this.flush())
            : await this.routeMap.createDepartment(this.flush());

        return this.map(response.data());
    }
}
