import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { InvoiceEditorService } from '../../../../../components/editors/invoice-editor/invoice-editor.service';
import { Invoice, WorkOrder } from '@beaconlite/models';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'work-order-tab-quotes',
  templateUrl: './tab-quotes.component.html',
})
export class TabQuotesComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;

  quoteTableColumns = ['date-issued', 'number', 'period', 'grand-total'];

  constructor(protected invoiceEditor: InvoiceEditorService) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  async onAddQuote(): Promise<boolean>
  {
    if (this.workOrder.invoiced) return false;

    return this.invoiceEditor.open({
      workOrder: this.workOrder,
      original: null,
      isInvoiceType: false,
      onInvoiceUpdate: this.onQuoteUpdate, 
    });
  }

  async onEditQuote(quote: Invoice): Promise<boolean>
  {
    return this.invoiceEditor.open({
      workOrder: this.workOrder,
      original: quote,
      isInvoiceType: false,
      onInvoiceUpdate: this.onQuoteUpdate,
      onInvoiceRemove: this.onQuoteRemove,
    });
  }

  onQuoteUpdate = async (): Promise<void> => 
  {
    await this.workOrder.reload();
  }

  onQuoteRemove = async (): Promise<void> => 
  {
    await this.workOrder.reload();
  }

  protected _sortItems(): void
  {
    this.workOrder.quotes?.sort(orderBy('-issued_at', '-started_at'));
  }
}
