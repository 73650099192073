import { ItemOverride } from './ItemOverride.model';
import { dto } from './mixins/Dto.decorators'
import { VariantRental } from './VariantRental.model';

export class RentalOverride extends ItemOverride {
    
    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() variant_rental_id?: string = null;
    @dto() daily_rate: number = 0;
    @dto() weekly_discount: number = 0.0;
    @dto() monthly_discount: number = 0.0;
    @dto() retail_cost: number = 0;
    @dto() replacement_discount: number = 0;
    @dto() replacement_rental_offset: number = 0;
    @dto() minimum_charge: number = 0;

    @dto(VariantRental) variant_rental: VariantRental =  null;

    inheritVariant(variant: VariantRental) 
    {
        this.variant_rental            = variant;
        this.variant_rental_id         = variant.id;
        this.daily_rate                = variant.daily_rate;
        this.weekly_discount           = variant.rental_definition?.weekly_discount;
        this.monthly_discount          = variant.rental_definition?.monthly_discount;
        this.minimum_charge            = variant.rental_definition?.minimum_charge;
        this.retail_cost               = variant.retail_cost;
        this.replacement_discount      = variant.rental_definition?.replacement_discount;
        this.replacement_rental_offset = variant.rental_definition?.replacement_rental_offset;        
    }

    resetVariant() 
    {
        this.inheritVariant(new VariantRental())
    }

    // TODO: is this used? Seems we never had a getRentalOverride() method on route map.
    // static async get(id: string): Promise<RentalOverride> 
    // {
    //     const response = await AppInjector.get(RouteMap).getRentalOverride(id);
    //     return new RentalOverride(response.data());
    // }

    /* TODO: The following methods don't exist on routeMap
    async save(): Promise<RentalOverride> {
        const repsonse = this.exists()
            ? await this.routeMap.updateRentalOverride( this.id, this.flush() )
            : await this.routeMap.createRentalOverride( this.id, this.flush() );
        
        return this.map( repsonse.data() );
    }

    async discard(): Promise<RentalOverride> {
        const reponse = await this.routeMap.discardRentalOverride( this.id );
        return this.map( response.data() );
    }

    async recover(): Promise<RentalOverride> {
        const response = await this.routeMap.recoverRentalOverride( this.id );
        return this.map( response.data() );
    }
    */
}
