import { BaseModel } from "./Base.model";
import { dto } from './mixins/Dto.decorators'

export class ExternalId extends BaseModel {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() work_order_id?: string = null;
    @dto() locked?: boolean = false;
    @dto() value?: string = null;

    duplicate(): ExternalId {
        let duplicate = new ExternalId(this.flushAttributes());

        // Reset computed and unique values back to model defaults
        duplicate.id                = null;
        duplicate.work_order_id     = null;
        duplicate.locked            = false;
        duplicate.created_at        = null;
        duplicate.updated_at        = null;

        return duplicate;
    }
}
