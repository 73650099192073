<div class="thumbnail-control__container">
    <div class="thumbnail-control__thumbnail" (click)="this.fileSelector.click()">
        <img *ngIf="this.thumbnailUrl" 
            [src]="this.thumbnailUrl"
            (error)="this.onImgError($event)">
        <mat-icon *ngIf="!this.thumbnailUrl" color="primary">image</mat-icon>
        <input type="file"
            #fileSelector
            ng2FileSelect 
            (onFileSelected)="this.onFileSelectedControl($event)"
            [uploader]="uploader">
    </div>
    <button *ngIf="this.canRemoveThumbnail()"
        type="button" 
        mat-raised-button
        [disabled]="this.loading"
        (click)="this.onThumbnailRemoved()">
        Remove
    </button>
</div>
