<mat-card class="medium-card">
<div class="override-card-inner" fxLayout="column">
    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-title">Service Overrides</div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center"
        *ngIf="!this.model.exists()">
        {{ this.label }} must first be created before adding service overrides.
    </div>

    <div fxLayout="column" *ngIf="this.hasRateOverrides()">
        <div class="mat-subheading-2">Rate Overrides</div>
        <mat-table class="mat-selectable-table"
        fxFlex="100"
        [dataSource]="this.model.service_rate_overrides">
            <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                <mat-cell *matCellDef="let override">{{ override.variant_service.display_name }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="regular-rate">
                <mat-header-cell fxFlex="15" *matHeaderCellDef>Regular Rate</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let override">{{ override.regular_rate | currencyFilter }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="overtime-rate">
                <mat-header-cell fxFlex="15" *matHeaderCellDef>Overtime Rate</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let override">{{ override.overtime_rate | currencyFilter }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="rateTableColumns"></mat-header-row>
            <mat-row *matRowDef="let override; columns: rateTableColumns" 
                (click)="this.onEdit(override)">
            </mat-row>
        </mat-table>
    </div>

    <div fxLayout="column" *ngIf="this.hasPercentOverrides()">
        <div class="mat-subheading-2">Percentage Overrides</div>
        <mat-table class="mat-selectable-table"
            fxFlex="100"
            [dataSource]="this.model.service_percent_overrides">
            <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                <mat-cell *matCellDef="let override">{{ override.variant_service.display_name }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="type">
                <mat-header-cell fxFlex="15" *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let override">{{ override.displayType() }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="percent">
                <mat-header-cell fxFlex="15" *matHeaderCellDef>Percent</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let override">
                    {{ override.percent_value | percentFilter }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="percentTableColumns"></mat-header-row>
            <mat-row class="rental-override-row"
                *matRowDef="let override; columns: percentTableColumns" 
                (click)="onEdit(override)">
            </mat-row>
        </mat-table>  
    </div>

    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end end">
        <button mat-mini-fab
            (click)="this.onEdit()"
            [disabled]="!this.model.exists()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
</mat-card>
