import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Signature } from '@beaconlite/models';
import { SignatureCaptureEditorData } from "./signature-capture-editor-data.interface";
import { SignatureCaptureEditorComponent } from "./signature-capture-editor.component";

@Injectable({
    providedIn: 'root'
})
export class SignatureCaptureEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: SignatureCaptureEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            width: '700px',
            // maxWidth: '700',
            panelClass: 'signature-capture',
            data,
        }

        return this.dialog.open(SignatureCaptureEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
  