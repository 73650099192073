import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Invoice, RentalRequest, Service, WorkOrder, InvoicedRentalRequest } from '@beaconlite/models';
import { SettingsService } from '../../services/settings.service';
import { orderBy } from '../../utilities/Sort.utility';
import { InvoicedRentalGroup } from '@beaconlite/models/InvoicedRentalGroup.model';
import { InvoicedFlattenedRentals, InvoicedFlattenedRentalsValue } from '@beaconlite/models/contracts/InvoicedFlattenedRentals.interface';
import { InvoicedFlattenedReturns, InvoicedFlattenedReturnsValue } from '@beaconlite/models/contracts/InvoicedFlattenedReturns.interface';
import { RentalFlattenBuilder } from '@beaconlite/services/RentalFlattenBuilder.service';
import { InvoicedFlattenedRateAdjustments, InvoicedFlattenedRateAdjustmentsValue } from '@beaconlite/models/contracts/InvoicedFlattenedRateAdjustments.interface';
import { InvoicedFlattenedMinimumAdjustments, InvoicedFlattenedMinimumAdjustmentsValue } from '@beaconlite/models/contracts/InvoicedFlattenedMinimumAdjustments.interface';
import { LineItemRequest } from '@beaconlite/models/LineItemRequest.model';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
})
export class InvoiceComponent implements OnInit, OnChanges {

  @Input() invoice: Invoice;
  @Input() loaded: boolean;

  // TODO Jira BL-752: This has been left in as a hardcoded setting while we get feedback from feature. Potentially move to config if we decide there is a time we would want both views.
  isFlattened = true;
  requestFlattenedRentals: Map<string, InvoicedFlattenedRentals> = new Map();
  requestFlattenedRateAdjustments: Map<string, InvoicedFlattenedRateAdjustments> = new Map();
  requestFlattenedMinimumAdjustments: Map<string, InvoicedFlattenedMinimumAdjustments> = new Map();
  requestFlattenedReturns: Map<string, InvoicedFlattenedReturns> = new Map(); 
  company = this.settingsService.get('company_config');

  constructor(
    protected settingsService: SettingsService,
    protected rentalFlattenBuilder: RentalFlattenBuilder,
  ) { }

  ngOnInit(): void 
  {
    // When invoice-editor is first opened, there is no invoice. It then reloads with invoice data.
    // Jira BL-755: Update invoice-editor to not show the component unless the invoice is loaded?
    if (this.invoice) {
      this.setRequestFlattenedRentals();
      this.setRequestFlattenedReturns();
      this.setFlattenedRateAdjustments();
      this.setFlattenedMinimumAdjustments();

      if (this.loaded)
      {
        this._sortItems();
      }
    }
  }

  setRequestFlattenedRentals(): void {
    for (let i = 0; i < this.invoice.rental_requests.length; i+=1) {
      const rentalRequest = this.invoice.rental_requests[i];
      this.requestFlattenedRentals.set(rentalRequest.rental_request_id, this.rentalFlattenBuilder.computeInvoicedFlattenedRentals(rentalRequest));
    }
  }

  getFlattenedRentalsForRequest(request: InvoicedRentalRequest): InvoicedFlattenedRentalsValue[] {
    return Array.from((this.requestFlattenedRentals.get(request.rental_request_id)).values())
  }

  setRequestFlattenedReturns(): void {
    for (let i = 0; i < this.invoice.rental_requests.length; i+=1) {
      const rentalRequest = this.invoice.rental_requests[i];
      this.requestFlattenedReturns.set(rentalRequest.rental_request_id, this.rentalFlattenBuilder.computeInvoicedFlattenedReturns(rentalRequest));
    }
  }

  getFlattenedReturnsForRequest(request: InvoicedRentalRequest): InvoicedFlattenedReturnsValue[] {
    return Array.from((this.requestFlattenedReturns.get(request.rental_request_id)).values())
  }

  getFlattenedMinimumAdjustments(request: InvoicedRentalRequest): InvoicedFlattenedMinimumAdjustmentsValue[] {
    return Array.from((this.requestFlattenedMinimumAdjustments.get(request.rental_request_id)).values())
  }

  setFlattenedMinimumAdjustments(): void {
    for (let i = 0; i < this.invoice.rental_requests.length; i+=1) {
      const rentalRequest = this.invoice.rental_requests[i];
      this.requestFlattenedMinimumAdjustments.set(rentalRequest.rental_request_id, this.rentalFlattenBuilder.computeInvoicedFlattenedMinimumAdjustments(rentalRequest));
    }
  }

  getFlattenedRateAdjustments(request: InvoicedRentalRequest): InvoicedFlattenedRateAdjustmentsValue[] {
    return Array.from((this.requestFlattenedRateAdjustments.get(request.rental_request_id)).values())
  }

  setFlattenedRateAdjustments(): void {
    for (let i = 0; i < this.invoice.rental_requests.length; i+=1) {
      const rentalRequest = this.invoice.rental_requests[i];
      this.requestFlattenedRateAdjustments.set(rentalRequest.rental_request_id, this.rentalFlattenBuilder.computeInvoicedFlattenedRateAdjustments(rentalRequest));
    }
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    if( changes.loaded?.currentValue )
    {
      this._sortItems();
    }
  }

  getWorkOrderSerial(): string
  {
	  return WorkOrder.formatSerialId(this.invoice.work_order.serial_id);
  }

  formatRentalSerialId(serialId: string): string
  {
    return RentalRequest.formatSerialId(serialId);
  }

  formatServiceSerialId(serialId: string): string
  {
    return Service.formatSerialId(serialId);
  }

  formatLineItemRequestSerialId(serialId: string): string
  {
    return LineItemRequest.formatSerialId(serialId);
  }

  requestHasNormalReturns(request: InvoicedRentalRequest): boolean 
  {
    return (request.return_items.length > request.lost_items.length);
  }

  checkIfGroupHasReturnItems(rentalGroup: InvoicedRentalGroup): boolean {
    return rentalGroup.returns.some( rentalReturn => !rentalReturn.lost);
  }

  checkIfGroupHasLostItems(rentalGroup: InvoicedRentalGroup): boolean {
    return rentalGroup.returns.some( rentalReturn => rentalReturn.lost);
  }

  protected _sortItems(): void
  {
    this.invoice.notes?.sort(orderBy('noted_at'));
    this.invoice.tax_rates?.rates.sort(orderBy('name'));
    this.invoice.charges?.sort(orderBy('created_at'));

    // TODO: Jira BL-723: Implement sort on InvoiceRentalRequest fields
    this.invoice.rental_requests
      ?.sort(orderBy('started_at'))
      .forEach((request: InvoicedRentalRequest) => {
        
        request.rentals?.sort(orderBy('position'));
        request.charges?.sort(orderBy('created_at'));
        request.notes?.sort(orderBy('noted_at'));

        request.returns?.sort( orderBy('returned_at', 'position') );
    });

    this.invoice.services
      ?.sort(orderBy('started_at'))
      .forEach(service => {
        service.charges?.sort(orderBy('created_at'))
        service.notes?.sort(orderBy('noted_at'))
      });
  }
}
