import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Branch } from '@beaconlite/models';
import { BranchCollection } from '@beaconlite/models/collections';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';

@Component({
  selector: 'app-branch-settings-index',
  templateUrl: './branch-settings-index.component.html',
  styles: [':host{height:100%}']
})
export class BranchSettingsIndexComponent implements OnInit {

  collection = new BranchCollection();
  branches = new CollectionDataSource(this.collection);
  
  selectedModelId: string = this.$stateGlobals.params.modelId;
  keyword: string;

  constructor(
    protected $stateGlobals: UIRouterGlobals,
    protected $state: StateService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.onSearch();
  }

  onSearch()
  {
    const params: any = {
      order:      'asc', 
      order_by:   'name',
    } 

    if (this.keyword)
    {
      params.keyword = this.keyword;
    }

    this.collection.search(params);
  }

  onAdd(): void
  {
    this.$state.go('protected.branches.single', { modelId: 'new' });
  }

  onEdit(branch: Branch): void
  {
    this.selectedModelId = branch.id
    this.$state.go('protected.branches.single', { modelId: branch.id });
  }
}
