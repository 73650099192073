<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Group Definition Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">

            <mat-form-field fxFlex> 
                <mat-label>Name</mat-label>
                <input type="text"
                    [(ngModel)]="this.groupDef.name"
                    name="nameField"
                    #nameField="ngModel"
                    required
                    [disabled]="this.locked"
                    maxlength="255"
                    matInput>
                <mat-hint align="end">{{ this.groupDef.name?.length || 0 }} / 255</mat-hint>
                <mat-error *ngIf="nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Description</mat-label>
                <textarea [(ngModel)]="this.groupDef.description"
                        name="descriptionField" 
                        rows="4"
                        [disabled]="this.locked"
                        matInput>
                </textarea>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">            
            <mat-form-field fxFlex>
                <mat-label>Code</mat-label>
                <input type="text"
                    [(ngModel)]="this.groupDef.code"
                    name="codeField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.groupDef.code?.length || 0 }} / 255</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Tags</mat-label>
                <input type="text"
                    [(ngModel)]="this.groupDef.tags"
                    name="tagField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.groupDef.tags?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="this.departments?.length > 1">
            <mat-form-field fxFlex>
                <mat-label>Default Department</mat-label>
                <mat-select [(ngModel)]="this.groupDef.department_id"
                    name="departmentField"
                    [disabled]="this.locked"
                    required>
                    <mat-option *ngFor="let department of this.departments" [value]="department.id">
                        {{ department.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="100">
                <span class="mat-subheading-2">Items</span>
            </div>
        </div>
        <div cdkDropList (cdkDropListDropped)="onDropped($event)">
            <div *ngFor="let item of this.groupDef.group_items; let idx = index" cdkDrag>
                <div fxFlex="5" fxFlex fxLayoutAlign="center" fxLayout="column">
                    <mat-icon class="drag-indicator" color="primary">drag_indicator</mat-icon>
                </div>
                <div fxFlex="95"> 
                    <app-group-editor-item 
                        [item]="item"
                        [quantity]="quantity"
                        [canRemoveItem]="canRemoveItem"
                        (onGroupItemChange)="this.setItem($event)"
                        (onGroupItemRemoved)="this.removeItem($event)">
                    </app-group-editor-item>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-mini-fab
                type="button"
                (click)="this.addItem()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button (click)="this.onClose()">
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
