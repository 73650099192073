import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { UserDispatchesSingleComponent } from "./user-dispatches-single.component";


export const UserDispatchesSingleState: Ng2StateDeclaration = {
    name: 'protected.user-dispatches.single',
    url: '/:modelId',
    component: UserDispatchesSingleComponent,
    data: {
        pageTitle: 'My Dispatches',
        requiresAuth: true,
    },
    params: {
        modelId: 'new'
    },
    resolve: [
        { 
            token: 'modelId',
            deps: [Transition],
            resolveFn: (transition: Transition) => transition.params().modelId,
        },
    ],
}
