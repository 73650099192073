import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Account, Client } from '@beaconlite/models';
import { DialogNotificationService } from '../../../../services/notification/dialog-notification.service';
import { ClientAccountEditorData } from './client-account-editor-data.interface';

@Component({
  selector: 'app-client-account-editor',
  templateUrl: './client-account-editor.component.html',
  styleUrls: ['./client-account-editor.component.scss']
})
export class ClientAccountEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  original: Account = this.data.original;
  client: Client = this.data.client;
  onUpdate = this.data.onAccountUpdate;
  onRemove = this.data.onAccountRemove;

  loading = false;
  locked = false;
  account = new Account({type: 'client'});

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ClientAccountEditorData,
    public dialogRef: MatDialogRef<ClientAccountEditorComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    if (!! this.original)
    {
      this.account = this.original.copy();
    }
    else
    {
      this.account.client_id = this.client.id;
      this.account.active = true;
    }
  }

  canRemove(): boolean
  {
      return !!this.original;
  }

  async save(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) return false;

    this.loading = true;

    try
    {
      await this.account.save();
      await this.onUpdate(this.account);
    }
    finally
    {
      this.loading = false;
      this.dialogRef.close();
    }
  }

  async remove(): Promise<void|boolean>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotification.removeConfirm())
      {
        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.loading = false;
    }
  }

}
