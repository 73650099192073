<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.onSave()" novalidate>
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="mat-title">Additional Settings</h2>
  </mat-toolbar>

  <mat-dialog-content class="dialog-padding">
      <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex>
              <mat-label>Invoice Number Start</mat-label>
              <input type="text"
              [(ngModel)]="this.invoiceNumber"
              (ngModelChange)="this.onInvoiceNumberChange()"
              name="invoiceNumberField"
              #invoiceNumberField="ngModel"
              matInput>
              <mat-error *ngIf="this.invoiceNumberField.hasError('incorrectFormat')">Incorrect format. Invoice numbers begin with "INV-".</mat-error>
              <mat-error *ngIf="this.invoiceNumberField.hasError('incompleteNumber')">Incorrect format. Invoice number is incomplete.</mat-error>
              <mat-error *ngIf="this.invoiceNumberField.hasError('invalidCharacters')">Incorrect format. Invoice number can only contain digits after "INV-".</mat-error>
          </mat-form-field>

      </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <mat-progress-spinner mode="indeterminate" *ngIf="this.isSaving" diameter="36"></mat-progress-spinner>
      <button type="button" mat-raised-button [mat-dialog-close]>
          Cancel
      </button>
      <button type="submit" mat-raised-button color="accent">
          Save
      </button>
  </mat-dialog-actions>
</form>
