import { ResolvableModelId } from "@beaconlite/routing.module";
import { Ng2StateDeclaration } from "@uirouter/angular";
import { DispatchPrintComponent } from "./dispatch-print.component";

export const DispatchPrintState: Ng2StateDeclaration = {
    name: 'print.dispatch',
    url: '/print/dispatch/:modelId',
    component: DispatchPrintComponent,
    data: {
        pageTitle: 'Dispatch',
        requiresAuth: true,
    },
    resolve: [ new ResolvableModelId ],
}
