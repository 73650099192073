import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpRequest} from './http-request';

@Injectable({
  providedIn: 'root'
})
export class HttpAdapterService {

  constructor(private http: HttpClient) { }

  request(options?: {}): HttpRequest
  {
    return new HttpRequest(this.http, options);
  }
}
