<mat-toolbar fxLayout="row" 
    fxLayoutAlign="space-between center"
    color="warn">
    <span>Notice</span>
</mat-toolbar>

<mat-dialog-content>
    <div>
        <h2 [innerHtml]="data.title"></h2>
        <p [innerHtml]="data.textContent"></p>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button 
        type="button"
        [mat-dialog-close]="true">
        {{ data.okText }}
    </button>
</mat-dialog-actions>