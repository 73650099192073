import { Ng2StateDeclaration } from '@uirouter/angular';
import { ClientIndexComponent } from './client-index.component';


export const ClientIndexState: Ng2StateDeclaration = {
  name: 'protected.clients',
  url: '/clients',
  component: ClientIndexComponent,
  data: { 
    pageTitle: 'Client Index', 
    requiresAuth: true,
  },
}