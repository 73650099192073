import { BaseModel } from "./Base.model";
import { Thumbnail } from "./Thumbnail.model";
import { RentalGroupDefinitionResourceCollection } from "./collections/rental-resource-collections/RentalGroupDefinitionResource.collection";
import { GroupItem } from "./contracts/GroupItem.interface";
import { ResourceItemCollection } from "./contracts/ResourceItem.interface";
import { dto } from "./mixins/Dto.decorators";
import { HasIcon } from "./mixins/HasIcon.mixin";
import { HasItemLevel } from "./mixins/HasItemLevel.mixin";

const MixinBase = HasIcon(
    HasItemLevel( BaseModel )
);

export class RentalGroupItem extends MixinBase implements GroupItem {
    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }
    @dto() id: string = null;
    @dto() name: string = null;
    @dto() quantity: number = null;
    @dto() ordinal: number = null;
    @dto(Thumbnail) thumbnail: Thumbnail = null;

    getCollection(): ResourceItemCollection {
        return new RentalGroupDefinitionResourceCollection();
    }
}
