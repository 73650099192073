<mat-table fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="serial">
        <mat-header-cell fxFlex="35" *matHeaderCellDef>Work Order</mat-header-cell>
        <mat-cell fxFlex="35" *matCellDef="let item">{{ item.serial_id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="regular">
        <mat-header-cell *matHeaderCellDef>Regular</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.regular_time | timeFilter:'human' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="overtime">
        <mat-header-cell *matHeaderCellDef>Overtime</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.over_time | timeFilter:'human' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="travel">
        <mat-header-cell *matHeaderCellDef>Travel</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.travel_time | timeFilter:'human' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.total | timeFilter:'human' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let item">
            <service-hours-client-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </service-hours-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="servicesDetail" *ngIf="this.nestedLayer == 'services'">
        <mat-cell *matCellDef="let item">
            <service-hours-service-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </service-hours-service-row>         
        </mat-cell>
    </ng-container>

  
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'services'">
        <mat-row *matRowDef="let nested; columns: ['servicesDetail']" ></mat-row>
    </ng-container>
</mat-table>
