import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { IssueEditorService } from '../issue-editor/issue-editor.service';
import { NoteEditorService } from '../../../../../../components/editors/note-editor/note-editor.service';
import { Issue, Note, WorkOrder } from '@beaconlite/models';
import { DialogNotificationService } from '../../../../../../services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '../../../../../../services/notification/snackbar-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss']
})
export class IssueComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;
  @Input() issue: Issue;
  @Input() onUpdate: () => Promise<void>;

  constructor(
    protected issueEditor: IssueEditorService,
    protected noteEditor: NoteEditorService,
    protected dialogNotifications: DialogNotificationService,
    protected snackbarNotifications: SnackbarNotificationService,
  ) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void
  {
    this._sortItems();
  }

  async onEditIssue(): Promise<void>
  {
    await this.issueEditor.open({
      workOrder: this.workOrder,
      original: this.issue,
    });

    this.workOrder.reload();
  }

  async onAddNote(): Promise<void>
  {
    this.onEditNote();
  }

  async onEditNote(note: Note = null): Promise<any> 
  {
    const onNoteUpdate = async (updatedNote: Note) => {
      updatedNote?.exists()
        ? note.mapAttributes(updatedNote.flushAttributes())
        : this.issue.addNote(updatedNote);

      await this.issue.save();
      await this.onUpdate();
    }

    const onNoteRemove = async () => {
      this.issue.removeNote(note);

      await this.issue.save();
      await this.onUpdate();
    }

    return this.noteEditor.open({
      options: {types: [Note.TYPE_PRIVATE]},
      original: note,
      onNoteUpdate,
      onNoteRemove,
    });
  }

  async onResolve(): Promise<any>
  {
    await this.issue.resolve();
    await this.onUpdate();
  }

  async onUnresolve(): Promise<any>
  {
    const shouldUnresolve = await this.dialogNotifications.confirm({
      title: 'Confirm Unresolve Issue?',
      textContent: 'Are you sure you would like to unresolve this issue?',
    });

    if (! shouldUnresolve) return; 

    await this.issue.unresolve();
    await this.onUpdate()
  }

  async onDiscard(): Promise<any>
  {
    if (! await this.dialogNotifications.removeConfirm()) return;

    await this.issue.discard();
    await this.onUpdate()
  }

  protected _sortItems()
  {
    this.issue.notes?.sort(orderBy('noted_at'))
  }

}
