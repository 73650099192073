import { Component, Input, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { Dispatch, WorkOrder } from '@beaconlite/models';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';

@Component({
  selector: 'app-dispatch-slip-single',
  templateUrl: './dispatch-slip-single.component.html',
  styleUrls: ['./dispatch-slip-single.component.scss']
})
export class DispatchSlipSingleComponent implements OnInit {

  @Input() modelId: string;
  @Input() stateOnClose: string;

  loading = false;
  title: string;
  dispatch: Dispatch;
  workOrder: WorkOrder
  isAdmin: boolean;

  constructor(
    protected $state: StateService,
    protected authService: AuthenticationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.loading = true;

    try
    {
      this.dispatch = await Dispatch.get(this.modelId);
      this.title = this.dispatch.formatted_serial_id;
      this.workOrder = new WorkOrder(this.dispatch.work_order);
      this.isAdmin = (await this.authService.getCurrentUser()).isAdmin();
    }
    finally
    {
      this.loading = false;
    }
  }

  onDispatchUpdate = async (): Promise<void> =>
  {
    this.dispatch = await Dispatch.get(this.modelId);
  }

  // TODO Jira BL-564: Find a better way to do navigation for admins.
  // Component is called by using _target=blank for admins. Component is closed by closing the tab, since close icon doesn't appear.
  onClose(): void
  {  
    this.$state.go(this.stateOnClose);
  }
}
