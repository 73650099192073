import { BaseModel } from "./Base.model";
import { Rental } from "./Rental.model";
import { RentalGroupDefinition } from "./RentalGroupDefinition.model";
import { RentalReturn } from "./RentalReturn.model";
import { VariantRentalCollection } from "./collections/VariantRental.collection";
import IRentalGroup from "./contracts/IRentalGroup.interface";
import { RequestItem } from "./contracts/RequestItem.interface";
import { ReturnItem } from "./contracts/ReturnItem.interface";
import { dto } from "./mixins/Dto.decorators";

export class RentalGroup extends BaseModel implements RequestItem, ReturnItem, IRentalGroup {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() rental_group_definition_id: string = null;
    @dto() rental_request_id?: string = null;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() name?: string = null;
    @dto() quantity?: number = 1;
    @dto() code?: string = null;
    @dto() tags?: string = null;
    @dto() position?: number = 0;

    // TODO: Jira BL-737: Not yet implemented for create/update requests. Want to shift API to use tmp_ids instead of nesting rentals in groups in json payload.
    // However, tmp_id is used when duplicating RentalRequests.
    @dto() tmp_id?: string = null;

    @dto(Rental) rentals: Rental[] = [];

    reset(): void
    {
        this.map(new RentalGroup({ id: this.id}));
    }

    async copyDefinitionValues(definition: RentalGroupDefinition): Promise<void>
    {
        this.name = definition.name;
        this.code = definition.code;
        this.tags = definition.tags;
        this.quantity = 1;
        this.rental_group_definition_id = definition.id;

        const params = {
            ids: definition.group_items.map(item => item.id)
        }

        const variantRentalDefinitions = await (new VariantRentalCollection()).all(params);

        let count = 0;

        this.rentals = variantRentalDefinitions.map((variantRentalDef) => {
            let rental = new Rental();
            rental.inheritVariant(variantRentalDef)
            rental.quantity *= this.quantity;
            rental.position = count;
            count += 1;
            return rental;
        });

    }

    duplicate(): RentalGroup 
    {
        let duplicate = new RentalGroup(this.flushAttributes());

        duplicate.tmp_id = duplicate.id;

        // Reset computed and unique values back to model defaults
        duplicate.id                    = null;
        duplicate.rental_request_id     = null;
        duplicate.locked                = false;
        duplicate.invoiced              = false;
        duplicate.created_at            = null;
        duplicate.updated_at            = null;
        duplicate.rentals               = [];

        return duplicate;
    }
}
