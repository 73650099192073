import { Injectable } from "@angular/core";
import { Account } from "./Account.model";
import { Attachment } from "./Attachment.model";
import { BaseModel } from "./Base.model";
import { Discardable } from "./mixins/Discardable.mixin";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "@beaconlite/services/app-injector.service";
import { RouteMap } from "@beaconlite/services/network/route-map.service";

Injectable({
    providedIn: 'root'
})

const MixinBase = Discardable( BaseModel )

export class Export extends MixinBase {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() type?: string = null;
    @dto() variant?: string = null;
    @dto() quantity?: number = null;
    @dto() exported_by?: string = null;
    @dto() is_manual?: boolean = true;
    @dto() @date from_date?: number = null;
    @dto() @date to_date?: number = null;

    @dto(Account) exporter?: Account = null;
    @dto(Attachment) attachments?: Array<Attachment> = null;

    static async create(params): Promise<Export | boolean>
    { 
        const response = await AppInjector.get(RouteMap).createExport(params);

        if (response.data() === null)
        {
            return false;
        }
        else
        {
            return new Export(response.data());
        }
    }

    // TODO: This isn't set up in the API. Jira task BL-512.
    async discard(id: string): Promise<Export>
    {
        const response = await this.routeMap.discardExport(this.id);
        return this.map(response.data());
    }
}