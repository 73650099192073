<div fxLayout="column" 
     fxLayoutAlign="center center" 
     fxFlexFill>

    <h1>Oops! Looks like the page you were looking for doesn't exist.</h1>

    <!-- Padding to offset vertical centering -->
    <span fxFlex="25"></span>

</div>
