import { Component, Inject, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dispatch } from '@beaconlite/models';
import { EmailEditorData } from './email-editor-data.interface';

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
})
export class EmailEditorComponent implements OnInit {

  defaults = this.data.defaults;
  sendableModel = this.data.sendableModel;
  onUpdate = this.data.onSendableUpdate;

  sending = false;

  to: string[] = [];
  cc: string[] = [];
  bcc: string[] = [];
  appendableModels = [];
  shouldAppendModel = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: EmailEditorData,
    public dialogRef: MatDialogRef<EmailEditorComponent>,
  ) { }

  ngOnInit(): void 
  {
    if (!! this.defaults)
    {
      this.to = this._mapEmails(this.defaults.to) || this.to;
      this.cc = this._mapEmails(this.defaults.cc) || this.cc;
      this.bcc = this._mapEmails(this.defaults.bcc) || this.bcc;

      this.appendableModels = this.defaults.appendableModels || this.appendableModels;
    }
  }

  // TODO: Can probably move this and removeChip into global mat-chip helpers.
  addChip(addressArray: string[], event: MatChipInputEvent): void
  {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim())
    {
      addressArray.push(value);
    }

    if (input)
    {
      input.value = ''
    }
  }

  removeChip(addressArray: string[], address: string)
  {
    const index = addressArray.indexOf(address)

    if (index < 0) return;

    addressArray.splice(index, 1);
  }

  formatDispatchSerialId(serialId: string): string
  {
    return Dispatch.formatSerialId(serialId);
  }

  async send()
  {
    this.sending = true;

    const data = {
        to:     this.to.map( value => ({email:value}) ),
        cc:     this.cc.map( value => ({email:value}) ),
        bcc:    this.bcc.map( value => ({email:value}) ),
        shouldAppendModel: this.shouldAppendModel,
    }

    try
    {
      await this.sendableModel.send(data);

      if (!! this.onUpdate)
      {
        await this.onUpdate();
      }
      
      this.dialogRef.close();
    }
    finally
    {
      this.sending = false;
    }
  }

  protected _mapEmails(emails: string[]): string[]
  {
    if (! emails) return null;

    return emails.filter(email => email != '' && !!email);
  }
}
