<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Property Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start">
            <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select name="typeField" [(ngModel)]="this.prop.type" [disabled]="this.original.exists()">
                    <mat-option value="text">Text</mat-option>
                    <mat-option value="dropdown">Dropdown</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Name</mat-label>
                <input type="text"
                    name="nameField"
                    #nameField="ngModel"
                    [(ngModel)]="this.prop.name"
                    matInput
                    required>
                <mat-error *ngIf="this.nameField.hasError('required')">Name is required</mat-error>
            </mat-form-field>

            <mat-slide-toggle [(ngModel)]="this.prop.is_displayed"
                class="display-toggle"
                name="isDisplayedField">
                Append to displayed name
            </mat-slide-toggle>
        </div>

        <h3 class="mat-title" *ngIf="this.itemDefinition.variant_property_fields && this.prop.isDropdown()">Options</h3>

        <div class="prop-option-list" fxLayout="column" 
             *ngIf="this.prop.isDropdown()"
             cdkDropList
             (cdkDropListDropped)="this.onOptionDropped($event)">
            <div class="prop-option-list__row" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign=" center"
                *ngFor="let option of this.prop.options; let i = index"
                cdkDrag>
                <mat-icon class="drag-indicator" color="primary">drag_indicator</mat-icon>
                <mat-form-field>
                    <mat-label>Option</mat-label>
                    <input type="text"
                        name="optionField{{i}}"
                        [(ngModel)]="option.name"
                        matInput
                        required >
                </mat-form-field>
                <ng-container *ngIf="this.itemDefinition.variant_rates_enabled">
                    <ng-container *ngFor="let field of this.itemDefinition.variant_property_fields">
                        <mat-form-field>
                            <mat-label class="capitalize">{{ this.formatField(field) }}</mat-label>
                            <input type="text"
                                name="{{field}}{{i}}Field"
                                [(ngModel)]="option[field]"
                                currencyInput
                                minNum="0"
                                matInput
                                required >
                        </mat-form-field>
                    </ng-container>
                </ng-container>
                <button type="button" mat-icon-button color="primary" (click)="this.onRemoveOption(option)">
                    <mat-icon class="remove-option-icon">remove_circle_outline</mat-icon>
                </button>
            </div>

            <button type="button" mat-icon-button color="primary" (click)="this.onAddOption()">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions fxLayout="row">
        <button mat-icon-button
            type="button" 
            *ngIf="this.original.exists()"
            (click)="this.remove()"
            color="primary">
            <mat-icon>delete</mat-icon>
        </button>
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
