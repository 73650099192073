import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { VariantPropertyDefinition } from '@beaconlite/models';
import { VariantDefinition } from '@beaconlite/models/contracts/VariantDefinition.interface';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { VariantPropertyDefinitionEditorService } from '../variant-prop-editor/variant-prop-editor.component';

@Component({
  selector: 'app-variant-prop-list',
  templateUrl: './variant-prop-list.component.html',
  styleUrls: ['./variant-prop-list.component.scss']
})
export class VariantPropListComponent implements OnInit, DoCheck {

  @Input() itemDefinition: VariantDefinition;
  @Input() onPropUpdate: (prop: VariantPropertyDefinition) => Promise<any>;
  @Input() onPropRemove: (prop: VariantPropertyDefinition) => Promise<any>;
  
  constructor(
    protected editor: VariantPropertyDefinitionEditorService,
    protected snackbarNotification: SnackbarNotificationService,
  ) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck()
  {
    this._sortItems();
  }

  async onEdit(prop: VariantPropertyDefinition): Promise<any>
  { 
    await this.editor.open({
      original: prop,
      itemDefinition: this.itemDefinition,
      onPropUpdate: this.onPropUpdate,
      onPropRemove: this.onPropRemove,
    })
  }

  async onAdd(): Promise<any>
  { 
    await this.onEdit( this.itemDefinition.newProp() );
  }

  formatAttribute(field: string): string
  {
      return field.replace('_', ' ');
  }

  onPropDropped(event: CdkDragDrop<VariantPropertyDefinition[]>)
  {
    moveItemInArray(this.itemDefinition.variant_property_definitions, event.previousIndex, event.currentIndex);

    this.itemDefinition.variant_property_definitions.forEach(
      (prop, index) => prop.ordinal = index
    );

    this.itemDefinition.updateVariantPropsOrdinals();
    this.snackbarNotification.saved();
  }

  private _sortItems(): void
  {
    this.itemDefinition.variant_property_definitions.sort( orderBy('ordinal') );
  }
}

