import { BaseModel } from "./Base.model";
import { DispatchedRental } from "./DispatchedRental.model";
import { RentalGroup } from "./RentalGroup.model";
import IRentalGroup from "./contracts/IRentalGroup.interface";
import { dto } from './mixins/Dto.decorators'

export class DispatchedRentalGroup extends BaseModel implements IRentalGroup {
    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }
    
    @dto() id?: string = null;
    @dto() dispatched_rental_request_id?: string = null;
    @dto() rental_group_id?: string = null;
    @dto(DispatchedRental) rentals: DispatchedRental[] = [];
    @dto(RentalGroup) source: RentalGroup = null;

    static fromRentalGroup(source: RentalGroup | DispatchedRentalGroup): DispatchedRentalGroup
    {
        // Already the right type
        if ( source instanceof DispatchedRentalGroup ) { return source; }
    
        let rentalGroup = new DispatchedRentalGroup();
        rentalGroup.source = source;
        rentalGroup.rental_group_id = source.id;

        return rentalGroup;
    }
}
