<mat-card class="large-card" fxLayout="column">
    <mat-card-title fxLayout="row" fxLayoutAlign=" center">
        <div fxLayout="column">
            <span class="mat-headline">{{ this.employee.fullname }}</span>
        </div>

        <span fxFlex></span>

        <button mat-icon-button [matMenuTriggerFor]="dispatchMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #dispatchMenu="matMenu">
            <button mat-menu-item (click)="this.onEdit()">
                <mat-icon>edit</mat-icon>
                Edit
            </button>
        </mat-menu>
    </mat-card-title>

    <mat-card-content class="large-card__content" fxLayout="column">
        <div fxLayout="row wrap">
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Email</span>
                <p class="mat-subhead">{{ this.employee.person.email|| "--" }}</p>
            </div>

            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Phone</span>
                <p class="mat-subhead">{{ this.employee.person.phone || "--" }}</p>
            </div>
      
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Department</span>
                <p class="mat-subhead">{{ (this.department | async)?.name }}</p>
            </div>
       
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Position</span>
                <p class="mat-body-1">{{ this.employee.position || "--"  }}</p>
            </div>

            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Account</span>
                <p class="mat-body-1">{{ this.employee.account ? 'Enabled' : 'Disabled' }}</p>
            </div>
        </div>

        <div fxLayout="row wrap">
            <div fxLayout="column" fxFlex="40" layoutPadding>
                <span class="mat-caption">Address</span>
                <p class="mat-subhead">{{ this.employee.person.address || "--"  }}</p>
            </div>
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">City</span>
                <p class="mat-subhead">{{ this.employee.person.city || "--"  }}</p>
            </div>
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Province</span>
                <p class="mat-subhead">{{ this.employee.person.region || "--"  }}</p>
            </div>
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Postal Code</span>
                <p class="mat-subhead">{{ this.employee.person.postal_code || "--"  }}</p>
            </div>
            <div fxLayout="column" fxFlex="20" layoutPadding>
                <span class="mat-caption">Country</span>
                <p class="mat-subhead">{{ this.employee.person.country || "--"  }}</p>
            </div>
            <div fxLayout="column" fxFlex="100" layoutPadding>
                <span class="mat-caption">Note</span>
                <p class="mat-subhead">{{ this.employee.note || "--"  }}</p>
            </div>
        </div>       

    </mat-card-content>
</mat-card>