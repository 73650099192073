import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WorkOrder } from '@beaconlite/models';
import { AppData } from '../../../../services/data/shared-data.departments';
import { LockVaultService } from '../../../../services/lock-vault.service';
import { StateService } from '@uirouter/core';
import * as moment from "moment";
import { SnackbarNotificationService } from '../../../../services/notification/snackbar-notification.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ReasonEditorService } from '../../../../components/editors/reason-editor/reason-editor.service';
import { IssueEditorService } from './tab-main/issue-editor/issue-editor.service';
import { TabRentalsComponent } from './tab-rentals/tab-rentals.component';
import { TabServicesComponent } from './tab-services/tab-services.component';
import { TabDispatchesComponent } from './tab-dispatches/tab-dispatches.component';
import { DateDialogService } from '../../../../components/editors/date-dialog/date-dialog.service';
import { DialogNotificationService } from '../../../../services/notification/dialog-notification.service';
import { TabLineItemsComponent } from './tab-line-items/tab-line-items.component';

@Component({
  selector: 'app-work-order-single',
  templateUrl: './work-order-single.component.html',
  styleUrls: ['./work-order-single.component.scss']
})
export class WorkOrderSingleComponent implements OnInit, OnDestroy {

  @Input() modelId: string;

  @ViewChild(TabRentalsComponent) tabRentals: TabRentalsComponent;
  @ViewChild(TabServicesComponent) tabServices: TabServicesComponent;
  @ViewChild(TabLineItemsComponent) tabLineItems: TabLineItemsComponent;
  @ViewChild(TabDispatchesComponent) tabDispatches: TabDispatchesComponent;

  workOrder = new WorkOrder({});
  title = 'New Work Order';
  selectedTab = 0;
  shouldShowDepartment = false;
  loading = false; 

  constructor(
    public lockVault: LockVaultService,
    protected appData: AppData,
    protected $state: StateService,
    protected snackbarNotifications: SnackbarNotificationService,
    protected dialogNotifications: DialogNotificationService,
    protected hotkeysService: HotkeysService,
    protected reasonEditor: ReasonEditorService,
    protected issueEditor: IssueEditorService,
    protected dateDialog: DateDialogService,
  ) {}

  async ngOnInit(): Promise<void> 
  {
    const departments = await this.appData.departments.getAll();
    this.shouldShowDepartment = departments.length > 1;

    // Load WO if it appears to exist
    if (!! this.modelId && this.modelId != 'new')
    {
      this.loading = true;

      try
      {
        this.workOrder = await WorkOrder.get(this.modelId);
        this.title = `Work Order #${this.workOrder.serial_id}`

        await this.lockVault.requestLock(this.workOrder);
      }
      finally
      {
        this.loading = false;
      }
    }

    this._setupHotKeys()
  }

  ngOnDestroy(): void 
  {
    this.lockVault.revokeLocks();
  }

  // Work Order Menu

  onCancel() 
  {
    this.$state.go('protected.work-orders');
  }

  async onDuplicateWorkOrder(): Promise<void> 
  {

    const dialogValues: any = await this.dateDialog.open({
      header: 'Duplicate Work Order',
      label: 'Start Date',
      defaultDate: moment().toDate(),
    });

    if (! dialogValues) return;

    let duplicate = this.workOrder.duplicate(dialogValues.date, dialogValues.resetTimes);

    this.loading = true;

    try
    {
      await duplicate.save();
      this.$state.go('protected.work-orders.single', { modelId:  duplicate.id });
    }
    finally
    {
      this.loading = false;
    }
  }

  async onTransformEstimate(): Promise<void> 
  {
    this.workOrder.estimate = false;
    
    await this.onSave()
    this.workOrder.reload();
  }

  async onAddIssue(): Promise<void>
  {
    const issue = await this.issueEditor.open({
      workOrder: this.workOrder
    });
    
    if (! issue) return;
    
    this.workOrder.reload();
  }

  async onClose(): Promise<void> 
  {
    try
    {
      await this.workOrder.close();
      this.snackbarNotifications.saved('Closed');
    }
    catch (response: any)
    { 
      this.snackbarNotifications.warning('Close Unsuccessful<br>Some items are incomplete');
    }

  }

  async onRecover(): Promise<void>
  {
    if (! this.dialogNotifications.recoverConfirm()) return;

    this.loading = true;

    try
    {
      await this.workOrder.recover();
    }
    finally
    {
      this.loading = false;
    }
  }

  async onDiscard(): Promise<void>
  {
    
    const reason: string = await this.reasonEditor.open()

    if (! reason) return;

    this.loading = true;

    try
    {
      await this.workOrder.discard(reason);
      this.$state.go('protected.work-orders');
    }
    finally
    {
      this.loading = false;
    }
  }

  onSave = async (): Promise<void> =>
  {
    this.loading = true;

    try
    {
      await this.workOrder.save()
      this.snackbarNotifications.saved();
    }
    finally
    {
      this.loading = false;
    }
  }

  // For things that don't save through the work order but still need to reflect that the work order info is being updated.
  onReload = async (): Promise<void> =>
    {
      this.loading = true;
  
      try
      {
        await this.workOrder.reload();
        // Handle saved notification here.
        this.snackbarNotifications.saved();
      }
      finally
      {
        this.loading = false;
      }
    }

  // View helpers

  getHeaderClass(): string
  {
    return this.workOrder.issue_severity || this.workOrder.state_name;
  }

  rentalCount(): number
  {
    return this.workOrder.rental_requests.length;
  }  
  
  serviceCount(): number
  {
    return this.workOrder.services.length;
  }  

  lineItemCount(): number
  {
    return this.workOrder.line_item_requests.length;
  }

  dispatchCount(): number
  {
    return this.workOrder.dispatches.length;
  }  

  invoiceCount(): number
  {
    return this.workOrder.invoices.length;
  }  

  quoteCount(): number
  {
    return this.workOrder.quotes.length;
  }  

  _setupHotKeys() 
  {
    // New rental request
    this.hotkeysService.add(new Hotkey('option+r', (): boolean => { 
      if (this.workOrder.canAddRentalRequests())
      {
        this.tabRentals.onAddRentalRequest();
        this.selectedTab = 1;
      }
      
      return false;
    }, undefined, 'Add a Rental Request'));

    // New Service
    this.hotkeysService.add(new Hotkey('option+s', (): boolean => { 
      if (this.workOrder.canAddServices())
      {
        this.tabServices.onAddService();
        this.selectedTab = 2;
      }  
      
      return false;
    }, undefined, 'Add a Service'));

    this.hotkeysService.add(new Hotkey('option+l', (): boolean => { 
      if (this.workOrder.canAddLineItemRequests())
      {
        this.tabLineItems.onAddLineItemRequest();
        this.selectedTab = 3;
      }  
      
      return false;
    }, undefined, 'Add a Line Item Request'));

    // New Dispatch
    this.hotkeysService.add(new Hotkey('option+d', (): boolean => { 
      if (this.workOrder.canAddDispatches())
      {
        this.tabDispatches.onAddDispatch();
        this.selectedTab = 4;
      }  

      return false;
    }, undefined, 'Add a Dispatch'));

  }

}
