import { BaseModel } from './Base.model';
import { Credentials } from './contracts/Credentials.interface';
import { Person } from './contracts/Person.interface';
import { Role } from './contracts/Role.interface';
import { Discardable } from './mixins/Discardable.mixin'
import { dto } from './mixins/Dto.decorators'
import { RouteMap } from '../services/network/route-map.service';
import { AppInjector } from '../services/app-injector.service';

type AccountType = "admin" | "client"

const MixinBase = Discardable( BaseModel )
export class Account extends MixinBase {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null
    @dto() client_id?: string = null
    @dto() employee_id?: string = null
    @dto() role_id?: string = null
    @dto() type: AccountType = "admin"
    @dto() active: boolean = true

    @dto() credentials: Credentials = {
        verified: false,
        email: null,
        password: null,
        password_confirm: null
    }
    
    @dto() person: Person = {
        first_name: null,
        last_name: null,
        title: null,
        email: null,
        phone: null
    }

    @dto() role: Role = {
        id: null,
        name: null,
        slug: null,
        description: null
    }

    get fullname(): string 
    {
        const person = this.person
        return ( person.last_name )
            ? person.first_name + ' ' + person.last_name 
            : person.first_name
    }


    static async auth(): Promise<Account> 
    { 
        const response = await AppInjector.get(RouteMap).getAuthenticatedAccount();
        return new Account( response.data() );
    }

    static async get(id: string): Promise<Account> 
    {
        const response = await AppInjector.get(RouteMap).getAccount(id)
        return new Account( response.data() );
    }

    /**
     * Save the current Account instance.
     *
     * @returns {Promise}
     */
    async save(): Promise<Account>
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateAccount( this.id, this.flush() )
            : await this.routeMap.createAccount( this.flush() )
        
        return this.map( response.data() );
    }

    /**
     * Discard the current Account instance.
     *
     * @returns {Promise}
     */
    async discard(): Promise<Account>
    {
        const response = await this.routeMap.discardAccount( this.id )
        return this.map( response.data() );
    }

    /**
     * Recover the current Account instance.
     *
     * @returns {Promise}
     */
    async recover(): Promise<Account>
    {
        const response = await this.routeMap.recoverAccount( this.id )
        return this.map( response.data() );
    }

    // MISC ROLE STUFF

    // TODO: Does this belong here.
    // TODO: Fix typing
    static async getAllRoles(): Promise<any[]>
    {
        const response = await AppInjector.get(RouteMap).allAccountRoles()
        return response.data();
    }

    // TODO: Does this belong here. Will probably be fixed when we improve permissions
    isAdmin(): boolean
    {
        return this.role.slug == "administrator"
    }
}
