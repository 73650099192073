import { Ng2StateDeclaration } from '@uirouter/angular';
import { WorkOrderIndexComponent } from './work-order-index.component';


export const WorkOrderIndexState: Ng2StateDeclaration = {
  name: 'protected.work-orders',
  url: '/work-orders',
  component: WorkOrderIndexComponent,
  // TODO: make sure this is requiring auth properly
  data: { 
    pageTitle: 'Work Order Index', 
    requiresAuth: true,
  },
}
