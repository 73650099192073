import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ReasonEditorData } from "./reason-editor-data.interface";
import { ReasonEditorComponent } from "./reason-editor.component";

@Injectable({
    providedIn: 'root'
})
export class ReasonEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data?: ReasonEditorData): Promise<string> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            minWidth: 450,
            data
          }

        return this.dialog.open(ReasonEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
