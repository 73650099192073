import { Component, Input, OnInit } from '@angular/core';
import { ActiveReportItem } from '@beaconlite/models/reports/contracts/ActiveReportItem.interface';
import { LostItemReport } from '@beaconlite/models/reports/LostItemReport.model';

@Component({
  selector: 'lost-item-workorder-row',
  templateUrl: './lost-item-work-order-row.component.html',
})
export class LostItemWorkOrderRowComponent implements OnInit {

  @Input() report: LostItemReport;
  @Input() items: ActiveReportItem[];
  @Input() layer: string;

  nestedLayer = '';
  tableColumns = ['name', 'ongoing', 'returned', 'lost', 'ratio'];

  constructor() { }
  ngOnInit(): void {}

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: ActiveReportItem): ActiveReportItem[]|null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }
}
