import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { DispatchedRequestReport } from '@beaconlite/models/reports/DispatchedRequestReport.model';
import { DispatchedReportRequest } from '@beaconlite/models/reports/contracts/DispatchedReportRequest.interface';

@Component({
  selector: 'dispatched-request-request-row',
  templateUrl: './dispatched-request-request-row.component.html',
})
export class DispatchedRequestRequestRowComponent implements OnInit, DoCheck {

  @Input() report: DispatchedRequestReport;
  @Input() requests: DispatchedReportRequest[];
  @Input() layer: string;

  nestedLayer: string = '';

  tableColumns = [ 'scheduled', 'name', 'department', 'location' ];

  constructor() { }
  ngOnInit(): void { }

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedRequests(item: DispatchedReportRequest): DispatchedReportRequest[] | null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }
}
