<div class="request-tab" fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="16px">

    <mat-card class="large-card" fxLayout="column">
        <mat-table class="mat-selectable-table"
            [dataSource]="workOrder.quotes"
            [fxShow]="workOrder.hasQuotes()">
            <ng-container matColumnDef="date-issued">
                <mat-header-cell *matHeaderCellDef>Date Issued</mat-header-cell>
                <mat-cell *matCellDef="let quote">{{ quote.issued_at | dateFilter:'dateMedium':'TBD' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef>Number</mat-header-cell>
                <mat-cell *matCellDef="let quote">{{ quote.formatted_serial_id }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="period">
                <mat-header-cell fxFlex="40" *matHeaderCellDef>Period</mat-header-cell>
                <mat-cell fxFlex="40" *matCellDef="let quote">{{ quote.started_at | dateFilter:'dateMedium' }}&nbsp;&mdash;&nbsp;{{ quote.ended_at | dateFilter:'dateMedium' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="grand-total">
                <mat-header-cell *matHeaderCellDef class="mat-col--small">Grand Total</mat-header-cell>
                <mat-cell *matCellDef="let quote">{{ quote.total | currencyFilter:'parentheses' }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="quoteTableColumns"></mat-header-row>
            <mat-row *matRowDef="let quote; columns: quoteTableColumns" (click)="onEditQuote(quote)"></mat-row>
        </mat-table>
    </mat-card>

    <div fxLayout="row" 
        fxLayoutAlign="end end" 
        class="request-tab__add-button-wrapper">
        <button mat-fab (click)="onAddQuote()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

</div>

