import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { AfterViewInit, ElementRef, Renderer2 } from "@angular/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DispatchEditorService } from "@beaconlite/views/protected/work-orders/work-order-single/tab-dispatches/dispatch-editor/dispatch-editor.service";
import { Department, Dispatch, Employee, WorkOrder } from "@beaconlite/models";
import { EmployeeCollection } from "@beaconlite/models/collections";
import { CollectionDataSource } from "@beaconlite/models/utilities/CollectionDataSource.utility";
import { orderBy } from "@beaconlite/utilities/Sort.utility";
import { StateService, TransitionService } from "@uirouter/angular";
import { AppData } from '../../../../services/data/shared-data.departments';
import { Datum, GanttEmployeeComponent } from "./components/employee-gantt/employee-gantt.component";

@Component( {
    selector: 'app-employee-schedule',
    templateUrl: './employee-schedule.component.html',
    styleUrls: ['./employee-schedule.component.scss']
} )
export class EmployeeScheduleComponent implements OnInit, AfterViewInit
{
    @ViewChild(CdkVirtualScrollViewport) indexContainer: CdkVirtualScrollViewport
    @ViewChild(GanttEmployeeComponent) graphComponent: GanttEmployeeComponent;

    // Render Flags
    loading = false;
    isOnIndex = true;

    // Template Data
    collection = new EmployeeCollection();
    employeeSource = new CollectionDataSource( this.collection );

    // Search Params
    keyword: string = null;
    searchAll = false;
    selectedDepartment: Department;
    orderBy = 'id';
    order = 'asc';

    departments: Department[] = null;

    constructor(
        protected appData: AppData,
        protected renderer: Renderer2,
        protected $state: StateService,
        protected transitionService: TransitionService,
        private dispatchEditorService: DispatchEditorService,
    ) { }

    async ngOnInit(): Promise<void>
    {
        this.departments = await this.appData.departments.getAll();
        this._sortItems();

        this.onSearch();

        if ( !this.$state.is( 'protected.employee-schedule' ) )
        {
            this.isOnIndex = false;
        }

        this.transitionService.onSuccess( { to: 'protected.employee-schedule' }, () => 
        {
            this.isOnIndex = true;
        } );

        this.transitionService.onSuccess( { from: 'protected.employee-schedule' }, () =>
        {
            this.isOnIndex = false;
        } );
    }

    ngAfterViewInit() 
    {
        this._synchronizeScrolling();
    }

    _synchronizeScrolling()
    {
        this.renderer.listen( this.indexContainer.elementRef.nativeElement, 'scroll', (event) =>
        {
            const graphContainer = this.graphComponent.graphContainer.nativeElement;

            if ( graphContainer.scrollTop == event.target.scrollTop ) { return }

            graphContainer.scrollTop = event.target.scrollTop
        } );
    }

    onScheduleScroll = (event: any) =>
    {
        const indexContainer = this.indexContainer.elementRef.nativeElement;
        
        if ( indexContainer.scrollTop == event.target.scrollTop ) { return }

        indexContainer.scrollTop = event.target.scrollTop
    }

    onSearch()
    {
        var params: any = {
            search_all: this.searchAll,
            order: this.order,
            order_by: this.orderBy,
        }

        if ( this.keyword )
        {
            params.keyword = this.keyword;
        }

        if ( this.selectedDepartment )
        {
            params.department_ids = [ this.selectedDepartment ]
        }

        this.collection.search( params )
    }

    onView( employee: Employee )
    {
        this.$state.go( 'protected.employee-schedule.single', { modelId: employee.id } );
    }

    onScheduleItemClick = async ( event: any, datum: Datum ) =>
    {
        const dispatchPromise = Dispatch.get( datum.id )
        const workOrderPromise = WorkOrder.get( datum.work_order_id )

        return Promise.all( [ dispatchPromise, workOrderPromise ] )
            .then( async ( [ dispatch, workOrder ] ) =>
            {

                var locals = {
                    workOrder: workOrder,
                    original: dispatch,
                    onDispatchRemove: async () => this.onSearch(),
                };
                // TODO: on update, it does not update the display on the Gantt chart when time is modified.
                const updatedDispatch = await this.dispatchEditorService.open( locals )
                //Update the local
                dispatch.map( updatedDispatch.flush() )
                // TODO: figure out if this client copy was required
                // dispatch.client = updatedDispatch.client
            } );
    }  

    protected _sortItems(): void
    {
        this.departments.sort( orderBy( 'name' ) );
    }

}
