<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>

    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Service Editor</h2>
        <mat-icon *ngIf="service.locked">lock_outline</mat-icon>
    </mat-toolbar> 

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" *ngIf="departments.length > 1">
            <mat-form-field fxFlex="33">
                <mat-label>Department</mat-label>
                <mat-select [(ngModel)]="service.department_id"
                        name="departmentIdField"
                        #departmentIdField="ngModel">
                    <mat-option *ngFor="let department of departments" [value]="department.id">
                        {{ department.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="75">
                <mat-label>Select Service</mat-label>
                <input type="text"
                    [(ngModel)]="serviceSearchText"
                    name="serviceSearchTextField"
                    #serviceSearchTextField="ngModel"
                    [matAutocomplete]="serviceAuto"
                    (ngModelChange)="onQueryServices()"
                    [blAutofocus]="this.autoFocus"
                    required
                    matInput
                    [disabled]="service.locked || service.lock_level >= 1">
                <mat-autocomplete #serviceAuto="matAutocomplete"
                    (optionSelected)="onServiceSelected($event.option.value)"
                    [displayWith]="displaySelectedService">
                    <mat-option *ngFor="let service of promisedServices | async" [value]="service">
                        <app-request-search-option 
                            [text]="service.display_name" 
                            [thumbnail]="service.thumbnail">
                        </app-request-search-option>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="serviceSearchTextField.hasError('required')">Service is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="25">
                <mat-label>Quantity</mat-label>
                <input type="number"
                    [(ngModel)]="service.quantity"
                    name="quantityField"
                    #quantityField="ngModel"
                    (ngModelChange)="calculateTotal()"
                    required
                    [minNum]="qtyInputStep"
                    matInput
                    [blStep]="qtyInputStep"
                    [errorStateMatcher]="syncErrorStateMatcher"
                    [disabled]="isQuantityDisabled()">
                <mat-hint *ngIf="service.planned_quantity">Planned: {{ service.planned_quantity }}</mat-hint>
                <mat-error *ngIf="quantityField.hasError('required')">Quantity is required.</mat-error>
                <mat-error *ngIf="quantityField.hasError('minNum')">Quantity must be at least {{ qtyInputStep }}.</mat-error>
                <mat-error *ngIf="quantityField.hasError('blStep')">{{ qtyStepError }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Location</mat-label>
                <input type="text"
                    [(ngModel)]="service.location"
                    name="locationField"
                    #locationField="ngModel"
                    required
                    maxlength="255"
                    matInput
                    [disabled]="service.locked || service.lock_level >= 1">
                <mat-hint align="end">{{ service.location?.length || '0' }} / 255</mat-hint>
                <mat-error *ngIf="locationField.hasError('required')">Location is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex="35">
                <mat-label>Ordered By</mat-label>
                <input type="text"
                    [(ngModel)]="service.ordered_by"
                    name="orderedByField"
                    #orderedByField="ngModel"
                    maxlength="255"
                    [disabled]="service.locked || service.lock_level >= 1"
                    matInput>
                    <mat-hint align="end">{{ service.ordered_by?.length || '0' }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="35">
                <mat-label>Pricing</mat-label> 
                <mat-select [(ngModel)]="service.pricing_type"
                    name="pricingField"
                    #pricingField="ngModel"
                    required
                    (ngModelChange)="onTypeChanged()"
                    [disabled]="service.locked">
                    <mat-option value="auto">Per Hour</mat-option>
                    <mat-option value="unit">Per Unit</mat-option>
                    <mat-option value="manual">Manual</mat-option>
                </mat-select>
                <mat-error *ngIf="pricingField.hasError('required')">Pricing type is required.</mat-error>
            </mat-form-field>

            <mat-slide-toggle fxFlexAlign="center" 
                [(ngModel)]="service.overtime_rate_applied"
                name="overtimeRateAppliedField"
                #overtimeRateAppliedField="ngModel"
                (change)="calculateTimes()"
                aria-label="Apply Overtime"
                [fxShow]="service.pricing_type == 'auto'"
                [disabled]="service.locked">
                Apply Overtime
            </mat-slide-toggle>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start">
            <mat-form-field fxFlex="22">
                <mat-label>Start Date</mat-label>
                <input matInput 
                    [matDatepicker]="startDatePicker" 
                    [(ngModel)]="service._dates.started_at.date" 
                    name="startedAtField"
                    #startedAtField="ngModel"
                    required
                    [min]="minDate"
                    [max]="service._dates.ended_at.date"
                    (ngModelChange)="calculateTimes()"
                    (focus)="startDatePicker.open()"
                    [disabled]="service.locked || service.lock_level >= 2">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-hint *ngIf="service.planned_started_at">
                    Planned: {{ service.planned_started_at | dateFilter:'dateShort' }}
                </mat-hint>
                <mat-error *ngIf="startedAtField.hasError('required')">Start date is required.</mat-error>
                <mat-error *ngIf="startedAtField.hasError('matDatepickerMin')">Start date is too early.</mat-error>
                <mat-error *ngIf="startedAtField.hasError('matDatepickerMax')">Start date is too late.</mat-error>
            </mat-form-field>


            <mat-form-field fxFlex="22">
                <mat-label>Start Time</mat-label>
                <input type="time"
                    [(ngModel)]="service._dates.started_at.time"
                    name="startTimeField"
                    #startTimeField="ngModel"
                    required
                    (ngModelChange)="calculateTimes()"
                    matInput
                    [disabled]="service.locked || service.lock_level >= 2">
                <mat-hint *ngIf="service.planned_started_at">Planned: {{ service.planned_started_at | dateFilter:'time' }}</mat-hint>
                <mat-error *ngIf="startTimeField.hasError('required')">Start time is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="22">
                <mat-label>End Date</mat-label>
                <input matInput 
                    [matDatepicker]="endDatePicker" 
                    [(ngModel)]="service._dates.ended_at.date" 
                    name="endedAtField"
                    #endedAtField="ngModel"
                    [min]="service._dates.started_at.date"
                    (ngModelChange)="calculateTimes()"
                    (focus)="endDatePicker.open()"
                    [disabled]="service.locked || service.lock_level >= 2">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                <mat-hint *ngIf="service.planned_ended_at">Planned: {{ service.planned_ended_at | dateFilter:'dateShort' }}</mat-hint>
                <mat-error *ngIf="endedAtField.hasError('matDatepickerMin') || endedAtField.hasError('minDate')">
                    End date is too early.
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="22">
                <mat-label>End Time</mat-label>
                <input type="time"
                    [(ngModel)]="service._dates.ended_at.time"
                    name="endTimeField"
                    #endTimeField="ngModel"
                    (ngModelChange)="calculateTimes()"
                    matInput
                    [disabled]="service.locked || service.lock_level >= 2">
                <mat-hint *ngIf="service.planned_ended_at">
                        Planned: {{ service.planned_ended_at | dateFilter:'time' }}
                    </mat-hint>
                    <mat-error *ngIf="endTimeField.hasError('required')">End time is required.</mat-error>
                    <mat-error *ngIf="endTimeField.hasError('minDate')">End time is too early.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" class="time-override-row">
            <mat-slide-toggle fxFlexAlign="end"
                [(ngModel)]="service.time_override"
                name="timeOverrideField"
                #timeOverrideField="ngModel"
                (ngModelChange)="calculateTimes()"
                [fxShow]="service.pricing_type == 'auto'"
                [disabled]="service.locked">
                Time Override
            </mat-slide-toggle>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" *ngIf="service.pricing_type != 'unit'">
            <mat-form-field fxFlex="33">
                <mat-label>Time</mat-label>
                <input type="text"
                    [(ngModel)]="service.regular_time"
                    name="regularTimeField"
                    (ngModelchange)="calculateTotal()"
                    timeInput
                    matInput
                    [disabled]="areTimesDisabled()">
                    <mat-hint *ngIf="service.planned_regular_time">
                        Planned: {{ service.planned_regular_time | timeFilter:'human' }}
                    </mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="33">
                <mat-label>Overtime</mat-label>
                <input type="text"
                    [(ngModel)]="service.over_time"
                    name="overTimeField"
                    (ngModelChange)="calculateTotal()"
                    timeInput
                    matInput
                    [disabled]="areTimesDisabled()">
                    <mat-hint *ngIf="service.planned_over_time">
                        Planned: {{ service.planned_over_time | timeFilter:'human' }}
                    </mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="33">
                <mat-label>Travel Time</mat-label>
                <input type="text"
                    [(ngModel)]="service.travel_time"
                    name="travelTimeField"
                    (ngModelChange)="calculateTotal()"
                    timeInput
                    matInput
                    [disabled]="service.locked">
                    <mat-hint *ngIf="service.planned_travel_time">
                        Planned: {{ service.planned_travel_time | timeFilter:'human' }}
                    </mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="33" *ngIf="service.pricing_type != 'manual'">
                <mat-label>Regular Rate</mat-label>
                <input type="text"
                    [(ngModel)]="service.regular_rate_override"
                    name="regularRateOverrideField"
                    #regularRateOverrideField="ngModel"
                    (ngModelChange)="calculateTotal()"
                    required
                    currencyInput
                    minNum="0"
                    matInput
                    [disabled]="service.locked">
                <mat-error *ngIf="regularRateOverrideField.hasError('required')">Regular rate is required.</mat-error>
                <mat-error *ngIf="regularRateOverrideField.hasError('minNum')">Must be at least $ 0.00.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="33" *ngIf="service.pricing_type =='auto'">
                <mat-label>Overtime Rate</mat-label>
                <input type="text"
                    [(ngModel)]="service.overtime_rate_override"
                    name="overtimeRateOverrideField"
                    #overtimeRateOverrideField="ngModel"
                    (ngModelChange)="calculateTotal()"
                    required
                    currencyInput
                    minNum
                    matInput
                    [disabled]="service.locked || !service.overtime_rate_applied">
                <mat-error *ngIf="overtimeRateOverrideField.hasError('required')">Overtime rate is required.</mat-error>
                <mat-error *ngIf="overtimeRateOverrideField.hasError('minNum')">Must be at least $ 0.00.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="33">
                <mat-label>Total</mat-label>
                <input type="text"
                    [(ngModel)]="service.estimated_total"
                    name="estimatedTotalField"
                    #estimatedTotalField="ngModel"
                    currencyInput
                    minNum="0"
                    matInput
                    [disabled]="service.locked || service.pricing_type != 'manual'">
                <mat-error *ngIf="estimatedTotalField.hasError('minNum')">Must be at least $ 0.00.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Initial Dispatch Note</mat-label>
                <textarea [(ngModel)]="firstNote.content" 
                    name="firstNoteField"
                    (focus)="$event.target.select()" 
                    matInput>
                </textarea>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="service.invoiced || loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
