<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="start center">
        <h2 class="mat-title">
            {{ !!this.original ? 'Edit' : 'New' }} Issue    
        </h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" *ngIf="!!this.original">
            <div fxLayout="column" fxFlex="40">
                <span class="mat-caption">Created by</span>
                <span class="mat-body-1">{{ this.issue.issuer.fullname }}</span>
            </div>
            <div fxLayout="column" fxFlex>
                <span class="mat-caption">Date</span>
                <span class="mat-body-1">{{ this.issue.created_at | dateFilter:'dateMedium' }}</span>
            </div>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Severity</mat-label>
                <mat-select [(ngModel)]="this.issue.severity"
                    name="severityField"
                    #severityField="ngModel"
                    [disabled]="this.workOrder.invoiced || !!this.issue.resolved_at"
                    required>
                    <mat-option [value]="this.SEVERITY_MAJOR">Flagged</mat-option>
                    <mat-option [value]="this.SEVERITY_QUARANTINE">Quarantined</mat-option>
                </mat-select>
                <mat-hint>{{ this.getSeverityDescription() }}</mat-hint>
                <mat-error *ngIf="severityField.hasError('required')">Severity is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Description</mat-label>
                <textarea [(ngModel)]="this.issue.description" 
                    name="descriptionField"
                    #descriptionField="ngModel" 
                    rows="5"
                    [disabled]="this.workOrder.invoiced || this.issue.resolved_at"
                    maxlength
                    required
                    matInput>
                </textarea>
                <mat-error *ngIf="descriptionField.hasError('required')">Description is required.</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button mat-raised-button 
            type="submit" 
            color="accent" 
            [disabled]="this.saving || this.workOrder.invoiced || !!this.issue.resolved_at">
            Save
        </button>
    </mat-dialog-actions>
</form>
