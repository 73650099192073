import * as moment from "moment";
import { Account } from "./Account.model";
import { Attachment } from "./Attachment.model";
import { BaseModel } from "./Base.model";
import { Charge } from "./Charge.model";
import { Client } from "./Client.model";
import { Department } from "./Department.model";
import { DispatchConstants as DC } from "./DispatchConstants";
import { DispatchedRentalRequest } from "./DispatchedRentalRequest.model";
import { DispatchedService } from "./DispatchedService.model";
import { Document } from "./Document.model";
import { Email } from "./Email.model";
import { Employee } from "./Employee.model";
import { Note } from "./Note.model";
import { Notification } from "./Notification.model";
import { ResourceLock } from "./ResourceLock.model";
import { Signature } from "./Signature.model";
import { DispatchedRequest } from "./contracts/DispatchedRequest.interface";
import { Discardable } from "./mixins/Discardable.mixin";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { env } from "../services/env.service";
import { Helpers as $helpers } from "../services/helpers.service";
import { RouteMap } from "../services/network/route-map.service";
import { ShortIdUtility } from "./utilities/ShortId.utility";
import { orderBy } from "@beaconlite/utilities/Sort.utility";
import { DispatchNotificationSummary } from "./contracts/DispatchNotificationSummary.interface";
import { DispatchRegion } from "./DispatchRegion.model";

const MixinBase = Discardable( BaseModel )

export class Dispatch extends MixinBase {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    static TYPE_DELIVERY          = DC.TYPE_DELIVERY;
    static TYPE_PICKUP            = DC.TYPE_PICKUP;

    static STATE_DRAFT            = DC.STATE_DRAFT;
    static STATE_REQUESTED        = DC.STATE_REQUESTED;
    static STATE_DISPATCHED       = DC.STATE_DISPATCHED;
    static STATE_ACTIONED         = DC.STATE_ACTIONED;
    static STATE_COMPLETED        = DC.STATE_COMPLETED;

    static TASK_WORKING           = DC.TASK_WORKING;
    static TASK_TRAVEL            = DC.TASK_TRAVEL;
    static TASK_END               = DC.TASK_END;

    static PRICING_UNIT          = DC.PRICING_UNIT;

    protected readonly _prefix: string = Dispatch._prefix;
    protected static readonly _prefix: string = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_DISPATCH);

    @dto() id?: string = null;
    @dto() serial_id?: string = null;
    @dto() work_order_id?: string = null;
    @dto() dispatch_region_id?: string = null;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() type?: string = Dispatch.TYPE_DELIVERY;
    @dto() location?: string = null;
    @dto() time_specified?: boolean = false;
    @dto() travel_time?: number = 1800;
    @dto() truck_identifier?: string = null;
    @dto() @date started_at?: number = null;
    @dto() @date ended_at?: number = null;
    @dto() @date scheduled_at?: number = null;
    @dto() @date scheduled_end_at?: number = null;
    @dto() requested_by?: string = null;
    @dto() @date requested_at?: number = null;
    @dto() dispatched_by?: string = null;
    @dto() @date dispatched_at?: number = null;
    @dto() completed_by?: string = null;
    @dto() @date completed_at?: number = null;
    @dto() @date sent_at?: number = null;
    @dto() @date last_sent_at?: number = null;
    @dto() @date marked_as_sent_at?: number = null;
    @dto() @date actioned_at?: number = null;
    @dto() @date discarded_at?: number = null;
    @dto() site_contact?: string = null;
    @dto() state?: string = null;

    @dto(DispatchedRentalRequest) rental_requests?: DispatchedRentalRequest[] = [];
    @dto(DispatchedService) services?: DispatchedService[] = [];
    @dto(Employee) employees?: Employee[] = [];
    @dto() notification_summary?: DispatchNotificationSummary[] = [];
    @dto(Charge) charges?: Charge[] = [];
    @dto(Note) notes?: Note[] = [];
    @dto(Notification) notifications?: Notification[] = [];
    @dto(Signature) signatures?: Signature[] = [];
    @dto(Attachment) attachments?: Attachment[] = [];
    @dto(Email) emails?: Email[] = [];
    @dto(Document) documents?: Document[] = [];
    @dto(Client) client?: Client = null;
    @dto(Account) dispatcher?: Account = null;
    @dto(Account) requester?: Account = null;
    @dto(Account) completer?: Account = null;
    @dto() work_order? = null;
    @dto(Attachment) pdf?: Attachment = null;
    @dto(DispatchRegion) dispatch_region?: DispatchRegion = null;

    lock: ResourceLock;
    lockType = ResourceLock.TYPE_DISPATCH;

    static async get(id: string): Promise<Dispatch> 
    {
        const response = await AppInjector.get(RouteMap).getDispatch(id);
        return new Dispatch(response.data());
    }

    get state_name(): string
    {
        return this.state;
    }

    get state_letter(): string 
    {
        return this.state_name[0];
    }

    get dispatch_notes(): Note[] 
    {
        return this.notes.filter( note => note.type == Note.TYPE_DISPATCH );
    }

    get field_notes(): Note[] 
    {
        return this.notes.filter( note => note.type == Note.TYPE_FIELD );
    }

    get formatted_serial_id(): string 
    {
        return Dispatch.formatSerialId(this.serial_id);
    }

    static formatSerialId(serial_id: string)
    {
        if (! serial_id ) return '';

        let padded = $helpers.zeroPadLeft(serial_id, 6);
        const prefix = this._prefix || Dispatch._prefix;
        return `${prefix}-${padded}`;
    }

    get last_sent(): Email | null {
        if (! this.emails.length) { return; }
        return this.emails.sort( orderBy('-sent_at') )[0];
    }

    get departments(): Array<Department> {
        // TODO: why was this copy added? 
        // If we want to make things immutable we should probably just copy the departments prior to returning them
        // const services = $helpers.deepCopy(this.services);
        const requests = (<DispatchedRequest[]>this.services).concat(this.rental_requests);
        const departments = [];

        requests.forEach( request => {
            if ( departments.findIndex( department => department.id == request.source.department.id ) == -1 )
            {
                departments.push(request.source.department);
            }
        });

        return departments;
    }

    get per_unit_services(): DispatchedService[]
    {
        return this.services.filter( service => service.source.pricing_type === Dispatch.PRICING_UNIT)
    } 
    
    get per_hour_services(): DispatchedService[] 
    {
        return this.services.filter( service => service.source.pricing_type !== Dispatch.PRICING_UNIT)
    }

    get latest_notification(): Notification
    {
        if ( !this.notifications.length) { return; }
        return this.notifications.sort( orderBy('-sent_at') )[0];
    }
    
    async getNotifications(id: string)
    {
        const response = await this.routeMap.getDispatchNotifications(id);
        this.notifications = response.data();
    }

    getUnitCount(request: DispatchedRentalRequest): number 
    {
        return request.rentals.reduce( (qty, rental) => { return qty + rental.dispatched_quantity }, 0);
    }

    getItemNames(request: DispatchedRentalRequest): string 
    {
        let display = request.rentals.reduce( (displayValue, rental) => { return displayValue + rental.source.name + ', ' }, '')
        return display.slice(0, -2); 
    }

    async save(): Promise<Dispatch> 
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateDispatch(this.id, this.flush())
            : await this.routeMap.createDispatch(this.flush());

        return this.map(response.data());
    }

    async discard(): Promise<Dispatch> 
    {
        const response = await this.routeMap.discardDispatch(this.id);
        return this.map(response.data());
    }

    async recover(): Promise<Dispatch> 
    {
        const response = await this.routeMap.recoverDispatch(this.id);
        return this.map(response.data());
    }

    async dispatch(): Promise<Dispatch> 
    {
        const response = await this.routeMap.dispatchDispatch(this.id);
        return this.map(response.data());
    }

    async cancel(): Promise<Dispatch> 
    {
        const response = await this.routeMap.cancelDispatch(this.id);
        return this.map(response.data());
    }

    async action(): Promise<Dispatch> 
    {
        const response = await this.routeMap.actionDispatch(this.id);
        return this.map(response.data());
    }

    async complete(): Promise<Dispatch> 
    {
        const response = await this.routeMap.completeDispatch(this.id);
        return this.map(response.data());
    }

    async uncomplete(): Promise<Dispatch> 
    {
        const response = await this.routeMap.uncompleteDispatch(this.id);
        return this.map(response.data());
    }

    async send(data: any): Promise<Dispatch> 
    {
        const response = await this.routeMap.sendDispatch(this.id, data);
        return this.map(response.data());
    }

    canMarkSent(): boolean 
    {
        return !this.last_sent_at && !this.marked_as_sent_at;
    }

    async markSent(): Promise<Dispatch> 
    {
        const response = await this.routeMap.markSentDispatch(this.id);
        return this.map(response.data());
    }

    async notify(data: any): Promise<Dispatch> 
    {
        const response = await this.routeMap.notifyDispatch(this.id, data);
        return this.map(response.data());
    }

    async getPdf(): Promise<Dispatch> 
    {
        const response = await this.routeMap.getDispatchPdf(this.id);
        return this.map(response.data());
    }

    async sign(signature: Signature): Promise<Signature|null> 
    {
        if (! this.exists()) return;

        const response = await this.routeMap.signDispatch(this.id, signature.flush());
        return signature.map(response.data());
    }

    async reloadModel(): Promise<void>
    {
        const response = await this.routeMap.getDispatch(this.id);
        this.map(response.data());
    }

    hasRentalRequests(): boolean 
    {
        return !!this.rental_requests.length;
    }

    hasServices(): boolean 
    {
        return !!this.services.length;
    }

    hasPerHourServices(): boolean 
    {
        return this.services.some( service => service.source.pricing_type !== Dispatch.PRICING_UNIT )
    }

    hasPerUnitServices(): boolean 
    {
        return this.services.some( service => service.source.pricing_type === Dispatch.PRICING_UNIT )
    }

    canAddCharges(): boolean 
    {
        return !this.invoiced;
    }

    hasCharges(): boolean 
    {
        return !!this.charges.length;
    }

    addCharge(charge: Charge = new Charge()): Charge 
    {
        this.charges.push(charge);

        return charge;
    }

    removeCharge(model: Charge): void 
    {
        const index = this.charges.indexOf(model);

        if (index >= 0)
        {
            this.charges.splice(index, 1);
        }
    }

    canAddNotes(): boolean 
    {
        return !this.locked;
    }

    hasNotes(): boolean 
    {
        return !!this.notes.length;
    }

    hasEmployees(): boolean 
    {
        return !!this.employees.length;
    }

    hasNotifications(): boolean 
    {
        return !!this.notifications.length;
    }

    addNote(note: Note = new Note()): Note 
    {
        this.notes.unshift(note);

        this.notes.sort( orderBy('created_at') );

        return note;
    }

    removeNote(model: Note): void 
    {
        const index = this.notes.indexOf(model);

        if (index >= 0)
        {
            this.notes.splice(index, 1);
        }
    }

    canAddSignatures(): boolean 
    {
        return !this.locked;
    }

    hasSignatures(): boolean 
    {
        return !!this.signatures.length;
    }

    hasDocuments(): boolean 
    {
        return !!this.documents.length;
    }

    async addDocuments(documents: Document[]) 
    {
        const data = documents.map( doc => doc.flush());
        const response = await this.routeMap.createWorkOrderDocument(this.work_order_id, data);
        const docs = response.data().map( data => new Document(data));

        return this.linkDocuments(docs);
    }

    // TODO: need to double check this works correctly
    async linkDocuments(documents: Document[]) 
    {
        const ids = documents.map( doc => doc.id);
        const response = await this.routeMap.linkDispatchDocument(this.id, ids);
        
        return this.documents.splice(this.documents.length, 0, ...documents);
    }

    async linkDocument(doc: Document): Promise<Document> 
    {
        const response = await this.routeMap.linkDispatchDocument(this.id, [doc.id]);
        doc.map(response.data());
        this.documents.push(doc);

        return doc;
    }

    async unlinkDocuments(documents: Array<Document>) 
    {
        documents.forEach( doc => this.unlinkDocument(doc));
        const ids = documents.map( doc => doc.id);

        const response = await this.routeMap.unlinkDispatchDocument(this.id, ids);
        return documents.forEach( doc => this.removeDocument(doc));
    }

    async unlinkDocument(doc: Document) 
    {
        const response = await this.routeMap.unlinkDispatchDocument(this.id, [doc.id])
        this.removeDocument(doc);

        return doc;
    }

    protected removeDocument(doc: Document): void 
    {
        const index = this.documents.indexOf(doc);

        if (index >= 0)
        {
            this.documents.splice(index, 1);
        }
    }

    async captureTime(request: DispatchedService, task, companyBusinessHours: {start: number, end: number}): Promise<void>
    {
        const previousTask = request.timed_task;
        const previousTime = request.timed_at;

        request.timed_at = moment().unix();   
        request.timed_task = task;

        const newEntry = {
            'timed_at': request.timed_at,
            'task': task,
        }

        request.timesheet = request.timesheet || [];
        request.timesheet.push(newEntry);

        // First work capture (allow travel beforehand)
        if ( task == Dispatch.TASK_WORKING && !request.actioned_started_at )
        {
            request.actioned_started_at = request.timed_at;
        }

        // Fragmented range
        if ( previousTask != Dispatch.TASK_WORKING && 
             task == Dispatch.TASK_WORKING && 
             request.actioned_ended_at )
        {
            request.time_override = true;
            // TODO: Update the service settings or do we use the service values and just apply the start and end even if fragmented
        }
        // TODO: validate timed_at > previous?

        const elapsedTime = request.timed_at - previousTime;

        // Switching off working updates the end
        if ( previousTask == Dispatch.TASK_WORKING)
        {
            // TODO: use existing client configuration if set

            let OVERTIME_START  = companyBusinessHours.end;   
            let OVERTIME_END    = companyBusinessHours.start;

            if ( this.client.overtime_start )
            {
                OVERTIME_START = $helpers.secondsFromMidnight(this.client.overtime_start);
                OVERTIME_END = $helpers.secondsFromMidnight(this.client.overtime_end);
            }

            const overtime = $helpers.calculateOvertime(OVERTIME_START, OVERTIME_END, moment.unix(previousTime), moment.unix(request.timed_at) );
            request.regular_time += elapsedTime - overtime;
            request.over_time += overtime;
            request.actioned_ended_at = request.timed_at;
            request.actioned_quantity = request.dispatched_quantity;
        }

        if(previousTask == Dispatch.TASK_TRAVEL)
        {
            request.travel_time += elapsedTime;
        }

        if( request.timed_task == Dispatch.TASK_END )
        {
            request.timed_at = null;
            request.timed_task = null;
        }       
    }

    getActionLabel(): string {
        return this.type === Dispatch.TYPE_DELIVERY ? DC.ACTION_DELIVERED : DC.ACTION_RETRIEVED;
    }

    getDisplayType(): string {
        return this.type === Dispatch.TYPE_DELIVERY ? DC.ACTION_DELIVERY : DC.ACTION_RETRIEVAL;
    }

    getAllButtonLabel(): string {
        return this.type === Dispatch.TYPE_DELIVERY ? DC.ACTION_DELIVER : DC.ACTION_RETRIEVE;
    }

    // TODO: don't like coupling component class names with the model.
    getHeaderClass(): string {
        return this.type === Dispatch.TYPE_DELIVERY ? 'dispatch__header--delivery-' + (this.hasServices() ? 'service' : 'rental') : 'dispatch__header--pickup';
    }
}
