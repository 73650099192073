import * as moment from 'moment';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Charge, ChargeDefinition, Dispatch, DispatchConstants, DispatchedRentalRequest, DispatchedRequest, DispatchedService, DispatchRegion, Employee, Note, RentalRequest } from '@beaconlite/models';
import { EmployeeCollection } from '@beaconlite/models/collections';
import { DispatchEditorData } from './dispatch-editor-data.interface';
import { DateFilterPipe } from '../../../../../../pipes/date-filter.pipe';
import { env } from '../../../../../../services/env.service';
import { LockVaultService } from '../../../../../../services/lock-vault.service';
import { SnackbarNotificationService } from '../../../../../../services/notification/snackbar-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { prefs } from '@beaconlite/services/preferences.service';
import { Request } from '@beaconlite/models/contracts/Request.interface';

type RequestType = typeof DispatchConstants.RENTAL_REQUESTS | typeof DispatchConstants.SERVICE_REQUESTS;

@Component({
  selector: 'app-dispatch-editor',
  templateUrl: './dispatch-editor.component.html',
  styleUrls: ['./dispatch-editor.component.scss']
})
export class DispatchEditorComponent implements OnInit, OnDestroy {

  workOrder = this.data.workOrder;
  original = this.data.original;
  onUpdate = this.data.onDispatchUpdate;
  onRemove = this.data.onDispatchRemove;

  readonly TYPE_DELIVERY = Dispatch.TYPE_DELIVERY;
  readonly TYPE_PICKUP = Dispatch.TYPE_PICKUP;
  readonly RENTAL_REQUESTS = DispatchConstants.RENTAL_REQUESTS;
  readonly SERVICE_REQUESTS = DispatchConstants.SERVICE_REQUESTS;

  @ViewChild('employeeSearchTextField') employeeSearchTextField: NgModel;
  @ViewChild('editorForm') editorForm: NgForm;

  loading = false;
  dispatchSearchText = '';
  selectedDispatch: Dispatch = null;
  rental_requests = [];
  services = [];
  firstDispatchNote = new Note({type: Note.TYPE_DISPATCH});
  suggestedDate = new Date();
  defaultChargeDefinitions: {checked: boolean, definition: ChargeDefinition}[];
  dispatchRegions: DispatchRegion[];

  dispatch: Dispatch;
  promisedEmployees: Promise<Employee[]>;
  employeeSearchText = '';

  // Tables
  rentalRequestTableColumns = ['id', 'item-count', 'start-end', 'location'];
  serviceTableColumns = ['id', 'name', 'start-end', 'location'];

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: DispatchEditorData,
    public dialogRef: MatDialogRef<DispatchEditorComponent>,
    public lockVault: LockVaultService,
    protected dateFilter: DateFilterPipe,
    protected snackbarNotifications: SnackbarNotificationService,
  ) {  }

  async ngOnInit(): Promise<void>
  {
    if (!! this.original)
    {
      this.dispatch = this.original.copy();
      this.firstDispatchNote = Note.getFirstNote(this.dispatch.notes, Note.TYPE_DISPATCH);

      await this.lockVault.requestLock(this.dispatch);
    }
    else
    {
      this.dispatch = new Dispatch({
        location: this.workOrder.location,
        work_order_id: this.workOrder.id,
      });

      this.defaultChargeDefinitions = await Promise.all(
        env('defaultDispatchCharges').map(async (chargeId: string) => {
          return {
            checked: true, 
            definition: await ChargeDefinition.get(chargeId),
          }
        })
      );
    }

    this.dispatchRegions = await DispatchRegion.getAll();
    
    // Pre-load preferences on init to avoid waiting on employee search.
    prefs();

    this._updateEmployeesTimeBooked();
    this._sortItems();
    this._updateSelectableRequests();

    // If this is a new delivery dispatch with no selectable requests,
    // then we can assume it should be a pickup
    if (! this.dispatch.exists() 
        && this.dispatch.type == this.TYPE_DELIVERY
        && this.services.length == 0
        && this.rental_requests.length == 0)
    {
        this.dispatch.type = this.TYPE_PICKUP;
        this._updateSelectableRequests();
    }

    // Preselect for new dispatches
    this._preSelectRequests();
  }

  ngOnDestroy(): void
  {
    this.lockVault.revokeLock(this.dispatch);
  }

  _preSelectRequests(): void
  {
    if ( this.dispatch.exists() ) { return }

    if (this.rental_requests.length)
    {
        this.dispatch.rental_requests = this._getEarliestRequest(this.rental_requests) as DispatchedRentalRequest[];
    }

    if (this.services.length)
    {   
        this.dispatch.services = this._getEarliestRequest(this.services) as DispatchedService[];
    }

    // Assumption is that delivery should always default to time sensitive
    this.dispatch.time_specified = true;
    this.dispatch.scheduled_at = this._getEarliestRequestDate((<DispatchedRequest[]>this.dispatch.rental_requests).concat(this.dispatch.services));

    if ( this.dispatch.type == this.TYPE_PICKUP )
    {
        // Default time not specified for pickups
        this.dispatch.time_specified = false;
        
        // Preselect all selectable requests for pickups
        this.dispatch.rental_requests = this.rental_requests.map(request => request.copy());

        // If the current date is after the earliest request date, use the current datetime
        if ( moment().startOf('hour').isAfter(moment.unix(this.dispatch.scheduled_at)) )
        {
            this.dispatch.scheduled_at = moment().startOf('hour').unix()
        }
    }
    else 
    {
        // Default time specified for delivery
        this.dispatch.time_specified = true;
    }
  }

  _getEarliestRequest(requests: DispatchedRequest[]): DispatchedRequest[]
  {
    return requests.reduce( (accumulator: DispatchedRequest[], request: DispatchedRequest) => {
            
      // New or earlier start date, then reset accumulator
      if ( accumulator.length == 0 || request.dispatched_started_at < accumulator[0].dispatched_started_at ) 
      {
          accumulator = [request];
      }
      // The same date?
      else if (request.dispatched_started_at == accumulator[0].dispatched_started_at)
      {
          accumulator.push(request)
      }

      return accumulator
    }, []);
  }

  _getEarliestRequestDate(requests: DispatchedRequest[]): number
  {
    return requests.reduce( (startedAt, request) => {
        if ( request.dispatched_started_at < startedAt || startedAt == 0 ) 
        {
          startedAt = request.dispatched_started_at
        }
        return startedAt
    }, 0);
  }

  onTypeChange(): void
  {
    // Clear existing selections
    this.dispatch.rental_requests = [];
    this.dispatch.services = [];

    this._updateSelectableRequests();
    this._preSelectRequests();
  }

  _updateSelectableRequests(): void
  {
    this.rental_requests= this._getSelectableRequests(this.dispatch.type, this.RENTAL_REQUESTS).map( DispatchedRentalRequest.fromRequest );
    this.services = this._getSelectableRequests(this.dispatch.type, this.SERVICE_REQUESTS).map( DispatchedService.fromService );
  }

  _getSelectableRequests(dispatchType: string, requestType: RequestType): DispatchedRequest[]
  {
      let selectableRequests; 

      if (dispatchType == this.TYPE_PICKUP)
      {
          selectableRequests = this._getPickableRequests(requestType);
      }
      else
      {
          selectableRequests = this._getDeliverableRequests(requestType);
      }

      // Grab requests already on the dispatch that are no longer selectable (on a pickup etc)
      let diff = (this.dispatch[requestType] as DispatchedRequest[]).filter((needleRequest ) => 
          !selectableRequests.some( haystackRequest => 
              needleRequest.source.id == haystackRequest.id
          ) 
      );
      diff.forEach((request) => request.temps._lockedForDispatchEdit = true )

      return selectableRequests.concat(diff);
  }

  _getPickableRequests(requestType: RequestType): Request[]
  {
      // Rental requests can be picked up multiple times
      if ( requestType == this.RENTAL_REQUESTS)
      {
          const deliveredRequests = this.workOrder.dispatches.reduce((accumulator: RentalRequest[], dispatch: Dispatch) =>
          {
              // Do not process current dispatch
              if (this.dispatch.id === dispatch.id){ return accumulator; }

              // Skip non delivery
              if (dispatch.type !== this.TYPE_DELIVERY ){ return accumulator; }

              // Skip non-dispatched
              // if ( dispatch.dispatched_at == null ){ return accumulator; }

              return accumulator.concat( dispatch[requestType].map( request => request.source ) );

          }, []);

          return deliveredRequests.filter((request) =>
          {
              if ( request.ongoing_rentals.length > 0 || // Items remaining OR
                  (request.ongoing_rentals.length == 0 && // No items AND
                  this.dispatch[requestType].some( (existingRequest) => existingRequest.source.id == request.id) ) ) // In current dispatch
              { return true; }
          })
      } 
      else 
      {
          return this.workOrder[requestType].filter((request) =>
          {
              return !this.workOrder.dispatches.some((dispatch: Dispatch) =>
              {
                  // Do not process for current dispatch
                  if (this.dispatch.id === dispatch.id){ return false; }

                  // Already on another dispatch?
                  return dispatch[requestType].some((dispatchRequest) =>
                  {
                      return dispatchRequest.source.id === request.id;
                  });
              });
          });
      }

  }

  _getDeliverableRequests(requestType: RequestType): Request[]
  {
      return (this.workOrder[requestType] as Request[]).filter((request) => 
      {
          // Already returned
          if ( requestType == this.RENTAL_REQUESTS && // Rental
               (request as RentalRequest).ongoing_rentals.length == 0 && // Fully returned
               !this.dispatch[requestType].some( (existingRequest) => existingRequest.source.id == request.id) ) // Not already in the current dispatch
          { return false; }

          return !this.workOrder.dispatches.some((dispatch)  => 
          {
              // Do not process for current dispatch
              if (this.dispatch.id === dispatch.id){ return false; }

              // Already on another dispatch?
              return dispatch[requestType].some((dispatchRequest) => 
              {
                  return dispatchRequest.source.id === request.id;
              });
          });
      });
  }

  toggleRequest(requestType: RequestType, targetRequest: DispatchedRequest): void
  {
    if (! targetRequest) return; 

    const index = this.dispatch[requestType].findIndex(request => request.source.id === targetRequest.source.id);

    if (index > -1)
    {
      this.dispatch[requestType].splice(index, 1);
    }
    else
    {
      if (requestType == this.RENTAL_REQUESTS) {
        this.dispatch[requestType].push(targetRequest as DispatchedRentalRequest);
      } else {
        this.dispatch[requestType].push(targetRequest as DispatchedService);
      }
    }
  }

  hasRequest(requestType: RequestType, targetRequest: DispatchedRequest): boolean
  {
    const index = this.dispatch[requestType].findIndex((request) =>
    {
        return request.source.id === targetRequest.source.id;
    });

    return index > -1;
  }

  async save(): Promise<void|boolean>
  {
    // NG1
    if (this.editorForm.invalid) return false;

    this.loading = true;

    // Apply the selected default charges
    this.defaultChargeDefinitions?.forEach( (defaultCharge) => {
        if ( !defaultCharge.checked ) return;

        const charge = new Charge();
        charge.inheritDefinition(defaultCharge.definition);

        let formatString = this.dispatch.time_specified ? 'dateTimeMedium' : 'dateMedium' 
        // Capital case Dispatch type
        charge.description = this.dispatch.type.charAt(0).toUpperCase() + this.dispatch.type.slice(1)
        // Add scheduled time
        charge.description += " requested for " + this.dateFilter.transform(this.dispatch.scheduled_at, formatString) + "\n" 
        // Add rental request ids
        this.dispatch.rental_requests.forEach((request)=> charge.description += request.source.formatted_serial_id + ', ' )
        // Add service request ids
        this.dispatch.services.forEach((request)=> charge.description += request.source.formatted_serial_id + ', ' )
        // Remove the last comma and space
        charge.description = charge.description.replace(/,\s*$/, "")
        
        this.dispatch.addCharge(charge);
    })

    // // Add new note to the dispatch
    if( !this.firstDispatchNote.exists() && this.firstDispatchNote.content )
    {
        this.dispatch.addNote(this.firstDispatchNote);
    }
    // Remove the note from the dispatch
    else if (this.firstDispatchNote.exists() && !this.firstDispatchNote.content)
    {
        this.dispatch.removeNote(this.firstDispatchNote);
    }

    try
    {
      await this.dispatch.save();
      await this.onUpdate?.();

      this.snackbarNotifications.saved();
      this.dialogRef.close(this.dispatch);
    }
    finally
    {
      this.loading = false;
    }
  }

  canRemove(): boolean
  {
    return !!this.data.original && 
           this.data.original.exists() && 
           !this.data.original.locked;
  }

  async remove(): Promise<void>
  {  
    this.loading = true;

    try
    {
      await this.onRemove?.();
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }

  isNotModifiable(request?): boolean
  {
    return !!this.dispatch.dispatched_at || request?.temps?._lockedForDispatchEdit;
  }

  async onQueryEmployees(): Promise<void>
  {
    const {start, end} = await this.getDispatchCollisionRange();

    this.promisedEmployees = (new EmployeeCollection()).all({
      keyword: this.employeeSearchText,
      booked_start: start,
      booked_end: end,
      date: this.dispatch.scheduled_at,
      scope: 'week',
      order:  'asc',
    });
  }

  private async getDispatchCollisionRange(): Promise<{start: number, end: number}>
  {
    const start = this.dispatch.scheduled_at - this.dispatch.travel_time;
    let end = this.dispatch.scheduled_end_at || this.dispatch.scheduled_at + await prefs('dispatch.projected_duration');
    end += this.dispatch.travel_time;

    return {start, end};
  }

  onRemoveEmployeeChip(employee: Employee): void
  {
    const index = this.dispatch.employees.indexOf(employee);

    if (index < 0) return;

    this.dispatch.employees.splice(index, 1);
  }

  onEmployeeSelected(employee: Employee): void 
  {
    this.dispatch.employees.push(employee);
  }

  onAddEmployeeChip(event: MatChipInputEvent): void 
  {
    event.input.value = '';
  }

  protected async _updateEmployeesTimeBooked(): Promise<void>
  {
    if ( !this.dispatch.employees.length )
    {
      return;
    }

    const {start, end} = await this.getDispatchCollisionRange();

    const response = await (new EmployeeCollection()).all({
      employee_ids: this.dispatch.employees.map(employee => employee.id),
      date: this.dispatch.scheduled_at,
      scope: 'week',
      booked_threshold: 1,
      booked_start: start,
      booked_end: end
    });

    this.dispatch.employees.forEach(employee => {
      const empl = response.find(element => element.id === employee.id);
      employee.is_booked = empl.is_booked;
      employee.time_booked = empl.time_booked;
    });
  }

  protected _sortItems(): void
  {
    this.dispatch.rental_requests?.sort(orderBy('dispatched_started_at', 'source.formatted_serial_id'));
    this.dispatch.services?.sort(orderBy('dispatched_started_at', 'source.formatted_serial_id'));
  }
}
