<mat-table fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="name">
        <mat-header-cell fxFlex="35" *matHeaderCellDef>Customer</mat-header-cell>
        <mat-cell fxFlex="35" *matCellDef="let item">{{ item.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ongoing">
        <mat-header-cell *matHeaderCellDef>Ongoing</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.ongoing }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="returned">
        <mat-header-cell *matHeaderCellDef>Returned</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.returned }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lost">
        <mat-header-cell *matHeaderCellDef>Lost</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.lost }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ratio">
        <mat-header-cell *matHeaderCellDef>Lost Ratio</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.lost / item.returned | percentFilter }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="workOrderDetail" *ngIf="this.nestedLayer == 'work_orders'">
        <mat-cell *matCellDef="let item">
            <lost-item-workorder-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </lost-item-workorder-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rentalItemDetail" *ngIf="this.nestedLayer == 'items'">
        <mat-cell *matCellDef="let item">
            <lost-item-rental-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </lost-item-rental-row>         
        </mat-cell>
    </ng-container>

  
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'work_orders'">
        <mat-row *matRowDef="let nested; columns: ['workOrderDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'items'">
        <mat-row *matRowDef="let nested; columns: ['rentalItemDetail']" ></mat-row>
    </ng-container>
</mat-table>