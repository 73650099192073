import { HttpResponse } from "../../../services/network/http-response";
import { BaseCollection } from "../Base.collection";
import { AppInjector } from "../../../services/app-injector.service";
import { RouteMap } from "../../../services/network/route-map.service";
import { ResourceItemCollection } from "../../contracts/ResourceItem.interface";
import { RentalResource } from "../../RentalResource.model";

export class RentalRequestResourceCollection extends BaseCollection<RentalResource> implements ResourceItemCollection {
    
    constructor(options?: any) {
        super(RentalResource, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchRentalRequestResources(params);
    }
}
