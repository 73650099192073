import { HttpResponse } from "../../services/network/http-response";
import { VariantService } from "../VariantService.model";
import { BaseCollection } from "./Base.collection";
import { AppInjector } from "../../services/app-injector.service";
import { RouteMap } from "../../services/network/route-map.service";

export class VariantServiceCollection extends BaseCollection<VariantService> {
    
    constructor(options?: any) {
        super(VariantService, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchVariantServices(params);
    }
}
