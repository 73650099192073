import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { Branch, RentalOverride, ServiceOverride, TaxRate } from '@beaconlite/models';
import { StateService } from '@uirouter/core';
import { UnsavedChangesService } from '@beaconlite/services/unsaved-changes.service';

@Component({
  selector: 'app-branch-settings-single',
  templateUrl: './branch-settings-single.component.html',
  styleUrls: ['./branch-settings-single.component.scss']
})
export class BranchSettingsSingleComponent implements OnInit {

  @Input() modelId: string;
  @ViewChild('editorForm') editorForm: NgForm;

  loading = false;
  locked = false;
  title = 'New Branch';

  original = new Branch();
  branch = new Branch();
  taxes: TaxRate[];

  constructor(
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
    protected unsavedChanges: UnsavedChangesService,
    protected $state: StateService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.taxes = await TaxRate.getAll();

    if (!! this.modelId && this.modelId != 'new')
    {
      this.lockAndLoad();

      try
      {        
        const branch: Branch = await Branch.get(this.modelId);
        this.original = branch;
        this.branch = branch.copy();
        this.title = this.branch.name; 
      }
      finally
      {
        this.unlockAndUnload();
      }
    }

    // Register unsaved changes guard
    this.unsavedChanges.setDiscardCheck(() => !this.editorForm.pristine && !this.editorForm.submitted)
  }

  onRentalUpdate = async (override: RentalOverride, updatedOverride: RentalOverride): Promise<void> =>
  {
    updatedOverride?.exists()
      ? override.mapAttributes(updatedOverride.flushAttributes())
      : this.branch.rental_overrides.push(updatedOverride);

    await this.save();
  }

  onRentalRemove = async (override: RentalOverride): Promise<void> =>
  {
    this.branch.removeRentalOverride(override);
    await this.save();
  }

  onServiceUpdate = async (override: ServiceOverride, updatedOverride: ServiceOverride): Promise<void> =>
  {
    updatedOverride?.exists()
      ? override.mapAttributes(updatedOverride.flushAttributes())
      : this.branch.service_overrides.push(updatedOverride);
    
    await this.save();
  }

  onServiceRemove = async (override: ServiceOverride): Promise<void> =>
  { 
    this.branch.removeServiceOverride(override);
    await this.save();
  }

  async save(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) return false;

    const modelExists: boolean = this.branch.exists();

    this.lockAndLoad();

    try
    {
      await this.branch.save();
      this.snackbarNotification.saved();

      if (! modelExists)
      {
        this.$state.go('protected.branches.single', { modelId: this.branch.id })
      }

      this.original.map(this.branch.flush());
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  async discard(): Promise<void|boolean>
  { 
    if (! await this.dialogNotification.discardConfirm()) return;

    this.lockAndLoad();

    try
    {
      await this.branch.discard();
    }
    finally
    {
      this.unlockAndUnload();
      this.$state.go('protected.branches.single', { modelId: 'new' });
    }
  }

  async restore(): Promise<void|boolean>
  {
    if (! await this.dialogNotification.recoverConfirm()) return;

    this.lockAndLoad();

    try
    {
      await this.branch.recover();
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.branch.discarded;
  }

}
