import type { Constructor } from '@beaconlite/types';
import { BaseModel } from '../Base.model'
import { dto } from './Dto.decorators';
import { ItemOverride, OverrideItemType } from '../ItemOverride.model';
import { RentalOverride } from '../RentalOverride.model';
import { ServiceOverride } from '../ServiceOverride.model';

export function HasItemOverrides<T extends Constructor<BaseModel>>( Base: T ) {
    class HasItemOverridesMixin extends Base {
        
        @dto(RentalOverride) rental_overrides: RentalOverride[] = []
        @dto(ServiceOverride) service_overrides: ServiceOverride[] = []
        
        get rental_rate_overrides(): RentalOverride[]
        {
            return this.rental_overrides.filter( override => override.type === ItemOverride.TYPE_RATE);
        } 

        get rental_percent_overrides(): RentalOverride[]
        {
            return this.rental_overrides.filter( override => override.type === ItemOverride.TYPE_PERCENT);
        } 

        get service_rate_overrides(): ServiceOverride[]
        {
            return this.service_overrides.filter( override => override.type === ItemOverride.TYPE_RATE);
        } 

        get service_percent_overrides(): ServiceOverride[]
        {
            return this.service_overrides.filter( override => override.type === ItemOverride.TYPE_PERCENT);
        }
        
        hasRentalOverrides(): boolean
        {
            return this.rental_overrides.length > 0;
        }

        getRentalOverride(variant_rental_id: string): RentalOverride
        {
            return this.rental_overrides.find( override => override.variant_rental_id == variant_rental_id );
        }

        removeRentalOverride(model: RentalOverride): void
        {
            let index = this.rental_overrides.indexOf(model);

            if ( index >= 0 )
            {
                this.rental_overrides.splice(index, 1);
            }
        }

        hasServiceOverrides(): boolean
        {
            return this.service_overrides.length > 0;
        }

        getServiceOverride(variant_service_id: string): ServiceOverride
        {
            return this.service_overrides.find( override => override.variant_service_id == variant_service_id );
        }

        removeServiceOverride(model: ServiceOverride): void
        {
            let index = this.service_overrides.indexOf(model);

            if ( index >= 0 )
            {
                this.service_overrides.splice(index, 1);
            }
        }


        /**
         * 
         * Experimental unification of override manipulation.
         * 
         */

        
        hasOverrides(type: OverrideItemType): boolean
        {
            const overrides = this.getOverrides(type);
            return overrides.length > 0;
        }

        getOverride(type: OverrideItemType, variantItemId: string): ItemOverride
        {
            const overrides = this.getOverrides(type);
            return overrides.find( override => override[`variant_${type}_id`] == variantItemId );
        }

        removeOverride(type: OverrideItemType, model: ItemOverride): void
        {
            const overrides = this.getOverrides(type);

            let index = overrides.indexOf(model);

            // Edit in place with splice
            if ( index >= 0 )
            {
                overrides.splice(index, 1);
            }
        }

        private getOverrides(type: OverrideItemType): ItemOverride[]
        {
            switch(type) 
            {
                case ItemOverride.ITEM_RENTAL:
                    return this.rental_overrides;
                case ItemOverride.ITEM_SERVICE:
                    return this.service_overrides;
            }
        }
    }

    // Decorators not allowed within TS class expressions (return class HasItemOverridesMixin)
    // https://github.com/microsoft/TypeScript/issues/7342
    return HasItemOverridesMixin;
}
