import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export class SyncErrorStateMatcher implements ErrorStateMatcher {

    /**
     * Sets error state on an input when the error happens.  IE, error will be set before blur,
     * touched, !pristine, etc if the error exists.
     * 
     * @param {FormControl} control 
     * @param {FormGroupDirective|NgForm} form 
     * @returns {boolean}
     */
    isErrorState(control: FormControl, form: FormGroupDirective|NgForm): boolean 
    {
        return !!control.errors;
    }
}
