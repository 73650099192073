import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShortIdUtility 
{
    static TYPE_WORK_ORDER  = 'work-order';
    static TYPE_DISPATCH    = 'dispatch';
    static TYPE_RENTAL      = 'rental';
    static TYPE_SERVICE     = 'service';
    static TYPE_LINE_ITEM   = 'line-item';
    static TYPE_INVOICE     = 'invoice';
    static TYPE_ESTIMATE    = 'estimate';
    static TYPE_QUOTE       = 'quote';

    static readonly separator = '-';

    // These must be strings. Cannot use class's constants as keys.
    static readonly prefixMap = {
        'work-order': 'WO',
        'rental'    : 'RTL',
        'service'   : 'SVC',
        'line-item' : 'ORD',
        'dispatch'  : 'DSP',
        'invoice'   : 'INV',
        'estimate'  : 'EST',
        'quote'     : 'QT',
    };


    /**
     *
     * @param {string} key
     * @returns {string}
     */
    static getPrefix(key: string): string
    {
        return ShortIdUtility.prefixMap[key] || '';
    }


    /**
     * Formatting function for long string identifier such as uuid's.
     * Returns a truncated, formatted and prefixed version of the identifier .
     *
     * @param {string} prefix
     * @param {string} id
     * @returns {string}
     */
    static format(prefix: string, id: string): string
    {
        prefix = prefix || '';
        let short_id = id;

        if ( id.length > 8) 
        {
            short_id = id.substring(0,8).toUpperCase();
        }

        return prefix + ShortIdUtility.separator + short_id;
    }

    /**
     *
     * @param {string} key
     * @param {string} id
     * @returns {string}
     */
    static formatFromKey(key: string, id: string): string
    {
        return ShortIdUtility.format(ShortIdUtility.getPrefix(key), id);
    }

}
