import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteMap } from '../../../../../../services/network/route-map.service';
import { SnackbarNotificationService } from '../../../../../../services/notification/snackbar-notification.service';
import { OnboardingHelperData } from './onboarding-helper-data.interface';

@Component({
  selector: 'app-onboarding-helper',
  templateUrl: './onboarding-helper.component.html',
})
export class OnboardingHelperComponent implements OnInit {

  isAccountMappingsMissing = this.data.isAccountMappingsMissing;
  isAccountMappingsEmpty = this.data.isAccountMappingsEmpty;
  onRefresh = this.data.onRefresh;

  saving = false;
  actionExists = false;
  actioning = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: OnboardingHelperData,
    public dialogRef: MatDialogRef<OnboardingHelperComponent>,
    protected routeMap: RouteMap,
    protected snackbarNotifications: SnackbarNotificationService,
  ) { }

  ngOnInit(): void 
  {
    if (this.isAccountMappingsMissing)
    {
      this.actionExists = true;
    }
  }

  getCancelButtonLabel(): string
  {
    if (this.isAccountMappingsMissing)
    {
        return 'No'
    }
    else if (this.isAccountMappingsEmpty)
    {
        return 'Ok'
    }
  }

  async createQuickbooksAccountMappings(): Promise<void>
  {
    this.actioning = true;

    try
    {
      await this.routeMap.createQuickBooksAccountMappings();
      this.dialogRef.close();
      this.snackbarNotifications.saved();
      this.onRefresh();
    }
    finally
    {
      this.actioning = false;
    }
  }
}
