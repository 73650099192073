<div class="index-sidebar" fxLayout="column">
    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="this.onSearch()" fxLayout="column" novalidate>
        <div fxLayout="column" fxLayoutGap="10px">
            <mat-form-field fxFlex>
                <mat-label class="mat-icon-label">
                    <mat-icon>search</mat-icon>
                </mat-label>
                <input type="text"
                    name="keywordField"
                    [(ngModel)]="this.keyword" 
                    matInput>
            </mat-form-field>

            <div class="tab" fxLayout="row wrap">
                <input id="tab-one" type="checkbox" name="tabs">
                <label class="tab-one-label" for="tab-one" fxFlex>More Options</label>

                <div class="tab-content" fxLayout="column" fxFlex="100">
                    <mat-form-field>
                        <mat-label>Filter by State</mat-label>
                        <mat-select [(ngModel)]="this.disabledState" name="disabledStateField">
                            <mat-option value="">All</mat-option>
                            <mat-option [value]="false">Enabled</mat-option>
                            <mat-option [value]="true">Disabled</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="primary">Search</button>
            </div>
        </div>
    </form>

    <button class="new-item-button" 
        (click)="this.onAdd()"
        mat-mini-fab 
        color="secondary">
        <mat-icon>add</mat-icon>
    </button>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [fxShow]="this.lineItems.loading"></mat-progress-bar>

    <cdk-virtual-scroll-viewport itemSize="70">
        <mat-list dense>
            <mat-list-item class="index-list"
                [class.index-list__item--selected]="selectedModelId === lineItem.id"
                *cdkVirtualFor="let lineItem of this.lineItems"
                (click)="this.selectDefinition(lineItem)"
                matRipple>

                <div class="mat-list-text" fxLayout="column">
                    <h3>{{ lineItem.name }}</h3>
                    <h4>{{ lineItem.code || 'No code'}}</h4>
                    <p>{{ lineItem.description || 'No detail' }}</p>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>

</div>

<mat-divider vertical></mat-divider>

<!-- Definition Single -->
<ui-view fxFlex fxLayout="column"></ui-view>
