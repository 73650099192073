<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Accounting Settings Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div class="row-padding" fxLayout="row" *ngIf="this.isActiveIntegrationQuickbooks">
            <span>The closing date is being enforced by the current active accounting integration, and can only be altered from its dashboard directly.</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between ">
            <mat-form-field fxFlex="25">
                <mat-label>Closing Date</mat-label>
                    <input [(ngModel)]="this.settings._dates.period_lock_date.date"
                        name="closingDateField"
                        [matDatepicker]="closingDatepicker"
                        (focus)="closingDatepicker.open()"
                        [disabled]="!this.settings.period_lock_enabled || this.isActiveIntegrationQuickbooks"
                        matInput>
                    <mat-datepicker-toggle matSuffix [for]="closingDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #closingDatepicker></mat-datepicker>
            </mat-form-field>

            <mat-slide-toggle fxFlexAlign="center"
                [(ngModel)]="this.settings.period_lock_enabled"
                name="periodLockActiveField"
                aria-label="Enable ClosingDate"
                [disabled]="this.isActiveIntegrationQuickbooks">
            </mat-slide-toggle>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner mode="indeterminate" *ngIf="this.loading" diameter="36"></mat-progress-spinner>
        <span fxFlex></span>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>