import { PercentPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'percentFilter'
})
export class PercentFilterPipe implements PipeTransform {

    constructor(protected percentPipe: PercentPipe) {}

    transform(value: any): string 
    {
        return this.percentPipe.transform(value, '1.2-2')
    }
}
