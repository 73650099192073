<mat-card class="large-card settings">
  <mat-card-title>
    <span>Sage 50 Settings</span>
    <span fxFlex></span>
    <mat-slide-toggle class="settings__title-switch"
      [(ngModel)]="this.integration.enabled"
      (ngModelChange)="this.integrationEnabledChanged()">
    </mat-slide-toggle>
  </mat-card-title>

  <mat-divider></mat-divider>

  <div class="settings__body" 
    fxLayout="column"
    layoutPadding
    cdkScrollable
    *ngIf="this.integration.enabled">
    <div layoutPadding>
      <span class="mat-title">Accounts</span><br>
      <span class="mat-caption">Accounts link to Service Record accounts in Sage 50. Account numbers must match Service Record numbers.</span>
    </div>
    <div fxLayout="row" [fxShow]="this.loadingIntegration">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <mat-table class="mat-selectable-table"
      fxFlex="100"
      [dataSource]="this.mappedAccounts"
      [fxShow]="!this.loadingIntegration">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>Account ID</mat-header-cell>
          <mat-cell *matCellDef="let account">{{ account.value.id }}</mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Account Description</mat-header-cell>
          <mat-cell *matCellDef="let account">{{ account.value.description }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="accountTableColumns"></mat-header-row>
        <mat-row *matRowDef="let account; columns: accountTableColumns" (click)="this.editAccount(account)"></mat-row>
    </mat-table>

    <div fxLayout="row" fxLayoutAlign="end end" layoutPadding>
      <button mat-mini-fab
        (click)="this.editAccount()"
        [disabled]="this.loadingIntegration">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="settings__body" 
    fxLayout="column" 
    layoutPadding 
    cdkScrollable
    *ngIf="this.integration.enabled">
    <span class="mat-title" layoutPadding>Taxes</span>
    <div fxLayout="row" [fxShow]="this.loadingIntegration">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <mat-table class="mat-selectable-table"
      fxFlex="100"
      [dataSource]="this.taxes"
      [fxShow]="!this.loadingIntegration">
      <ng-container matColumnDef="group">
        <mat-header-cell *matHeaderCellDef>Group</mat-header-cell>
        <mat-cell *matCellDef="let tax">{{ tax.tag }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let tax">{{ tax.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="external-name">
        <mat-header-cell *matHeaderCellDef>Sage 50 Tax Name</mat-header-cell>
        <mat-cell *matCellDef="let tax">{{ this.settings.taxes[tax.id]}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="taxTableColumns"></mat-header-row>
      <mat-row *matRowDef="let tax; columns: taxTableColumns" (click)="this.onEditTax(tax)"></mat-row>
    </mat-table>
  </div>

  <ng-container *ngFor="let branch of this.branches">
    <mat-toolbar class="settings__lead--top" *ngIf="this.integration.enabled">
      <mat-toolbar-row fxLayoutAlign="space-between center">
        <span class="mat-headline">{{ branch.name }}</span>
        <button mat-icon-button 
          [disabled]="this.loadingIntegration || !this.mappedAccounts.length"
          (click)="this.onEditBranchDefaults(branch)">
          <mat-icon color="primary">edit</mat-icon>
        </button>
      </mat-toolbar-row>
    
      <mat-toolbar-row *ngIf="this.integration.enabled" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxFlex>
          <span class="mat-subheading-1">Default Account</span>
          <p class="mat-body-1">{{ this.getBranchAccountName(branch, 'default') }}</p>
        </div>
        <div fxLayout="column" fxFlex>
          <span class="mat-subheading-1">Rentals Account</span>
          <p class="mat-body-1">{{ this.getBranchAccountName(branch, this.RENTALS) }}</p>
        </div>
        <div fxLayout="column" fxFlex>
          <span class="mat-subheading-1">Services Account</span>
          <p class="mat-body-1">{{ this.getBranchAccountName(branch, this.SERVICES) }}</p>
        </div>
        <div fxLayout="column" fxFlex>
          <span class="mat-subheading-1">Charges Account</span>
          <p class="mat-body-1">{{ this.getBranchAccountName(branch, this.CHARGES) }}</p>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="settings__body"
      fxLayout="column" 
      *ngIf="this.integration.enabled"
      layoutPadding
      cdkScrollable>
      <ng-container  *ngIf="this.showBranchOverrides(branch); then hasOverrides; else noOverrides;">
      </ng-container>

      <ng-template #noOverrides>
        <span class="mat-caption" layoutPadding>No overrides set.</span>
      </ng-template>

      <ng-template #hasOverrides>
        <ng-container *ngFor="let itemType of this.ITEM_TYPES">
          <ng-container *ngIf="this.showOverrides(branch, itemType)">
            <div class="table-header--row">
              <span class="mat-subheading-1 capitalize" layoutPadding>{{ itemType }}</span>
            </div>

            <mat-table class="mat-selectable-table"
              fxFlex="100"
              [dataSource]="this.getOverrides(branch, itemType)"
              multiTemplateDataRows>

              <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                <mat-cell *matCellDef="let override" (click)="this.onEditOverride(branch, itemType, override)">{{ override.name }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="account">
                <mat-header-cell *matHeaderCellDef>Account</mat-header-cell>
                <mat-cell *matCellDef="let override"  (click)="this.onEditOverride(branch, itemType, override)">
                  <span *ngIf="!!override.account">
                    {{ this.settings.accounts[override.account]?.description 
                      ? this.settings.accounts[override.account].id + ' - ' + this.settings.accounts[override.account].description 
                      : this.settings.accounts[override.account]?.id 
                    }}
                  </span>
                  <span *ngIf="!override.account">
                    Not set
                  </span>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="variantOverrides">
                <mat-cell *matCellDef="let override" [fxShow]="this.showOverrides(branch, itemType)">
                  <div *ngIf="override.variant_overrides.length > 0" fxFlex="100">
                    <mat-table class="mat-selectable-table"
                      fxFlex="100"
                      [dataSource]="override.variant_overrides">
                      <ng-container matColumnDef="item">
                        <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                        <mat-cell *matCellDef="let variantOverride" (click)="this.onEditOverride(branch, itemType, override, variantOverride)">{{ variantOverride.name }}</mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="account">
                        <mat-header-cell *matHeaderCellDef>Account</mat-header-cell>
                        <mat-cell *matCellDef="let variantOverride" (click)="this.onEditOverride(branch, itemType, override, variantOverride)">
                          {{ this.settings.accounts[variantOverride.account].description 
                            ? this.settings.accounts[variantOverride.account].id + ' - ' + this.settings.accounts[variantOverride.account].description 
                            : this.settings.accounts[variantOverride.account].id 
                          }}
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="variantOverrideTableColumns"></mat-header-row>
                      <mat-row *matRowDef="let variantOverride; columns: variantOverrideTableColumns"></mat-row>
                    </mat-table>
                  </div>
                </mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="itemDefOverrideTableColumns"></mat-header-row>
              <mat-row *matRowDef="let override; columns: itemDefOverrideTableColumns"></mat-row>

              <ng-container *ngIf="this.showOverrides(branch, itemType, override)">
                <mat-row *matRowDef="let variantOverride columns: ['variantOverrides']" class="override-row"></mat-row>
              </ng-container>

            </mat-table>
          </ng-container>
        </ng-container>
      </ng-template>

      <div fxLayout="row" fxLayoutAlign="end end" layoutPadding>
        <button mat-mini-fab
            (click)="this.onEditOverride(branch)"
            [disabled]="this.loadingIntegration ||
              !this.mappedAccounts.length ||
              !this.settings.branches[branch.id]?.default_account">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <mat-divider></mat-divider>

  <div class="settings__body" 
    fxLayout="column" 
    layoutPadding 
    cdkScrollable
    *ngIf="this.integration.enabled">
    <span class="mat-title" layoutPadding>Excluded Clients</span>
    <div fxLayout="row" [fxShow]="this.loadingIntegration">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div [fxShow]="!this.loadingIntegration && !this.excludedClients.length" fxLayout="row" layoutPadding>
      <span class="mat-caption">No Excluded Clients added.</span>
    </div>

    <mat-table class="mat-selectable-table"
      fxFlex="100"
      [dataSource]="this.excludedClients"
      [fxShow]="!this.loadingIntegration && this.excludedClients.length">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Client Name</mat-header-cell>
        <mat-cell *matCellDef="let client">{{ client.name }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="excludedClientsTableColumn"></mat-header-row>
      <mat-row *matRowDef="let client; columns: excludedClientsTableColumn" (click)="this.onEditExcludedClient(client)"></mat-row>
    </mat-table>

    <div fxLayout="row" fxLayoutAlign="end end" layoutPadding>
      <button mat-mini-fab
          (click)="this.onEditExcludedClient()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="settings__body" 
    fxLayout="column" 
    layoutPadding 
    cdkScrollable
    *ngIf="this.integration.enabled">
    
    <div fxLayout="row" fxLayoutAlign="space-between center" layoutPadding [fxShow]="!this.loadingIntegration">
      <span class="mat-title">Additional Settings</span>
      <button mat-icon-button color="primary" (click)="this.onEditStartInvoiceNumber()">
          <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div fxLayout="row" [fxShow]="this.loadingIntegration">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div fxLayout="row" layoutPadding [fxShow]="!this.loadingIntegration">
      <div fxLayout="column" fxFlex="25">
        <span class="mat-subheading-1">Invoice Number Start</span>
        <span class="mat-caption">
          <ng-container *ngIf="this.settings.additional_settings?.invoice_number_start; then hasInvoiceNumberStart; else noInvoiceNumberStart">
          </ng-container>
          <ng-template #hasInvoiceNumberStart>
            INV-{{ this.settings.additional_settings.invoice_number_start }}
          </ng-template>
          <ng-template #noInvoiceNumberStart>
            Invoice Number Start not set.
          </ng-template>
        </span>    
      </div>
    </div>
  </div>

</mat-card>
