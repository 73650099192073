import { Component, OnInit } from '@angular/core';
import { LineItemDefinitionCollection } from '@beaconlite/models/collections/LineItemDefinition.collection';
import { LineItemDefinition } from '@beaconlite/models/LineItemDefinition.model';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { LineItemDefinitionEditorService } from '../line-item-definition-editor/line-item-definition-editor.component';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
    selector: 'app-line-item-index',
    templateUrl: './line-item-index.component.html',
    styleUrls: ['./line-item-index.component.scss']
})
export class LineItemIndexComponent implements OnInit {

    collection = new LineItemDefinitionCollection();
    lineItems = new CollectionDataSource(this.collection);
    selectedModelId: string = this.$stateGlobals.params.modelId;
    keyword: string;
    disabledState: boolean;

    constructor(
        protected $stateGlobals: UIRouterGlobals,
        protected $state: StateService,
        protected lineItemDefinitionEditor: LineItemDefinitionEditorService,
        protected appData: AppData,
        protected snackbarNotification: SnackbarNotificationService,
    ) { }

    async ngOnInit(): Promise<void> 
    {
        this.onSearch();
    }

    onSearch()
    {
        const params: any = {
            order:      'asc', 
            order_by:   'name',
        }

        if (this.disabledState != null)
        {
            params.disabled_state = this.disabledState;
        }

        if (this.keyword)
        {
            params.keyword = this.keyword;
        }

        this.collection.search(params);
    }

    async onAdd(): Promise<void>
    {
        const departments = await this.appData.departments.getAll();
        let definition = new LineItemDefinition();

        departments?.sort(orderBy('name'));
        definition.department_id = departments.find(dep => dep.default).id;

        await this.lineItemDefinitionEditor.open({
            original: definition,
            departments: departments,
            onUpdate: this.createDefinition
        });
    }

    createDefinition = async (definition: LineItemDefinition): Promise<void> =>
    {
        await definition.save();
        this.selectDefinition(definition);
    }

    selectDefinition(definition: LineItemDefinition): void 
    {
        this.selectedModelId = definition.id;
        this.$state.go('protected.line-items.single', { modelId: definition.id });
    }
}
