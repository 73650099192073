<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">

    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Note Editor</h2>
        <mat-icon *ngIf="note.locked">lock_outline</mat-icon>        
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between start">
            <mat-form-field *ngIf="noteTypes.length > 1">
                <b matPrefix class="note__type-indicator note__type-indicator--{{ note.type }}"></b>
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="note.type"
                    name="noteTypeField"
                    #noteTypeField="ngModel"
                    [disabled]="note.locked"
                    required>
                    <mat-option *ngFor="let type of noteTypes" [value]="type.value">
                        <b class="note__type-indicator note__type-indicator--{{type.value}}"></b>
                        {{ type.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="column" *ngIf="noteTypes.length == 1">
                <span class="mat-caption">Type</span>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span class="note__type-indicator note__type-indicator--{{ noteTypes[0].value }}"></span>
                    <span>{{ noteTypes[0].name }}</span>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="start end">
                <div *ngIf="note.created_at">
                    <span class="mat-caption">Created: </span>
                    <span class="mat-body-1">{{ note.created_at | dateFilter:'dateTimeMedium' }}</span>
                </div>
                <div *ngIf="note.updated_at !== note.created_at">
                    <span class="mat-caption">Updated: </span>
                    <span class="mat-body-1">{{ note.updated_at | dateFilter: 'dateTimeMedium' }}</span>
                </div>
            </div>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Description</mat-label>
                <textarea rows="5"
                    [(ngModel)]="note.content"
                    name="contentField"
                    #contentField="ngModel"
                    matInput
                    cdkFocusInitial
                    [disabled]="note.locked">
                </textarea>
            </mat-form-field>
        </div>

        <div class="fieldset recurrance-fields" fxLayout="column" *ngIf="canRecur()">
            <div fxLayout="row" fxLayoutGap="20px">
                <h2 class="mat-subheading-2">Recurrence Settings</h2>
            </div>
            <div fxLayout="row">
                <mat-slide-toggle [(ngModel)]="note.recurring"  
                    name="recurringField"
                    [disabled]="note.locked"
                    class="recurrance-fields__apply-toggle">
                    Apply to every Invoice
                </mat-slide-toggle>
            </div>
            <div fxLayout="row">
                <mat-slide-toggle fxFlexAlign="center"
                    [(ngModel)]="recurringWithEnd" 
                    name="recurringwithEndField"
                    [disabled]="note.invoiced || !note.recurring">
                    Only apply to Invoices starting before: &nbsp;
                </mat-slide-toggle>

                <mat-form-field fxFlex="25" class="recurrance-fields__date-picker">
                    <mat-label>Date</mat-label>
                    <input matInput 
                        [matDatepicker]="recurringUntilPicker" 
                        [(ngModel)]="note._dates.recurring_until.date" 
                        (focus)="recurringUntilPicker.open()"
                        name="recurringUntilField"
                        #recurringUntilField="ngModel"
                        [disabled]="isRecurringUntilDisabled()"
                        [min]="minimumDate"
                        [required]="recurringWithEnd && !isRecurringUntilDisabled()">
                    <mat-datepicker-toggle matSuffix [for]="recurringUntilPicker"></mat-datepicker-toggle>
                    <mat-datepicker #recurringUntilPicker></mat-datepicker>
                    <mat-error *ngIf="recurringUntilField.hasError('required')">This is required.</mat-error>
                    <mat-error *ngIf="recurringUntilField.hasError('matDatepickerMin')">Date is too early.</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" *ngIf="note.locked && !note.invoiced">
                <button mat-raised-button
                    type="button" 
                    (click)="stopRecurrence()"
                    [disabled]="loading">
                    Stop Recurring
                </button>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <button mat-icon-button
            type="button" 
            *ngIf="canRemove()"
            (click)="remove()"
            [disabled]="loading"
            color="primary">
            <mat-icon>delete</mat-icon>
        </button>
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="note.invoiced || loading">
            Save
        </button>
    </mat-dialog-actions>
</form>