import * as d3 from 'd3';
import * as moment from 'moment';
import { Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Series, SeriesPoint } from 'd3';

interface ItemDatum {
    date: Date
    value: number
    rented: number
    returned: number
    lost: number
    max: number
}

@Component( {
    selector: 'rental-item-chart',
    templateUrl: './graph.component.html',
} )
export class RentalItemGraphComponent implements OnChanges, OnDestroy, OnInit
{
    @Input() item?;
    @Input() startDate?: Date;
    @Input() endDate?: Date;

    loading = false;

    private _max = 0;
    private _windowListeners: Array<() => void> = []

    constructor(
        protected renderer: Renderer2
    ) { }

    ngOnChanges( changes: SimpleChanges ): void
    {
        if(changes['item']){
            this._drawGraph()
        }
    }

    async ngOnInit(): Promise<void>
    {
        const svg = d3.select( '.chart' )
        const defs = svg.append( "defs" );
        
        defs.append( "linearGradient" )
            .attr( "id", "svgGradient" )
            .attr( "x1", "0%" )
            .attr( "x2", "0%" )
            .attr( "y1", "0%" )
            .attr( "y2", "100%" )
            .append( "stop" )
            .attr( 'class', 'start' )
            .attr( "offset", "0%" )
            .attr( "stop-color", "#6BCD7D" )
            .attr( "stop-opacity", 0.9 )
            .append( "stop" )
            .attr( 'class', 'end' )
            .attr( "offset", "100%" )
            .attr( "stop-color", "#6BCD7D" )
            .attr( "stop-opacity", 0.6 );

        defs.append( "linearGradient" )
            .attr( "id", "svgGradient2" )
            .attr( "x1", "0%" )
            .attr( "x2", "0%" )
            .attr( "y1", "0%" )
            .attr( "y2", "100%" )
            .append( "stop" )
            .attr( 'class', 'start' )
            .attr( "offset", "0%" )
            .attr( "stop-color", "#6D40DD" )
            .attr( "stop-opacity", 0.9 )
            .append( "stop" )
            .attr( 'class', 'end' )
            .attr( "offset", "100%" )
            .attr( "stop-color", "#6D40DD" )
            .attr( "stop-opacity", 0.6 );

        defs.append( "linearGradient" )
            .attr( "id", "svgGradient3" )
            .attr( "x1", "0%" )
            .attr( "x2", "0%" )
            .attr( "y1", "0%" )
            .attr( "y2", "100%" )
            .append( "stop" )
            .attr( 'class', 'start' )
            .attr( "offset", "0%" )
            .attr( "stop-color", "#E23838" )
            .attr( "stop-opacity", 0.7 )
            .append( "stop" )
            .attr( 'class', 'end' )
            .attr( "offset", "100%" )
            .attr( "stop-color", "#E23838" )
            .attr( "stop-opacity", 0.4 );

        this._windowListeners.push(
            this.renderer.listen(window, 'resize', () => {
                this._drawGraph();
            })
        );
    }

    ngOnDestroy(): void 
    {
        this._windowListeners.forEach( remove => { if ( !!remove ) { remove(); } });
    }

    protected async _drawGraph(): Promise<void>
    {
        if ( !this.item ) return;

        const data = this._processGraphData();
        this._stackedAreaChart( data );
    }

    protected _processGraphData()
    {
        // const item = this.selectedGraphItem;
        let itemData = { ...this.item.dates };
        // let itemData = angular.copy(item.dates);
        let values = Object.values( itemData ) as any;

        // Set starting date to the earliest given value or 1 year ago. Which ever is earlier
        let current = moment.unix( values[ 0 ].timestamp );
        const lastYear = moment().subtract( 1, 'year' ).startOf( 'day' );
        if ( current.isAfter( lastYear ) )
        {
            current = lastYear;
        }
        // If a start date was specified, use that
        current = this.startDate ? moment( this.startDate ) : current;

        // Set latest to last given value or now. Which ever is later
        let latest = moment.unix( values[ values.length - 1 ].timestamp );
        const now = moment().startOf( 'day' );
        if ( latest.isBefore( now ) )
        {
            latest = now;
        }
        // If an end date was specified use that.
        latest = this.endDate ? moment( this.endDate ) : latest;

        // Sanity Check
        if ( !current.isBefore( latest ) ) { return; }

        let data = [];
        let runningTotal = 0;
        this._max = this.item.max;

        // Fill in the holes for the date range. Calculate the running total.
        while ( current.isSameOrBefore( latest ) )
        {
            let value = itemData[ current.unix() ] ? itemData[ current.unix() ].value : runningTotal;
            const rented = itemData[ current.unix() ] ? itemData[ current.unix() ].rented : 0;
            const returned = itemData[ current.unix() ] ? itemData[ current.unix() ].returned : 0;
            const lost = itemData[ current.unix() ] ? itemData[ current.unix() ].lost : 0;

            // Update the runningTotal
            runningTotal = value;
            // Assemble
            data.push( {
                date: current.toDate(),
                value: runningTotal,
                rented: rented,
                returned: returned,
                lost: lost,
            } )
            // Increment day
            current.add( 1, 'days' );
        }
        // Compose the data
        data = Object.values( data ).map( ( { date, value, rented, returned, lost } ) => ( {
            date: moment( date, 'DD/MM/YYYY' ).toDate(),
            value: value,
            rented,
            returned,
            lost
        } ) );
        // ( data as any ).max = max;

        return data;
    }

    _stackedAreaChart( data: ItemDatum[] )
    {
        // Area Chart
        const height = 600;
        const width = d3.select<Element, ItemDatum>( ".chart" ).node().parentElement.getBoundingClientRect().width;
        const margin = { top: 75, right: 40, bottom: 30, left: 40 };

        let columns = [ 'value', 'lost', 'returned' ];
        const series: Series<ItemDatum, string>[] = d3.stack<ItemDatum>().keys( columns )( data);

        let x = d3.scaleUtc()
            .domain( d3.extent( data, d => d.date ) )
            .range( [ margin.left, width - margin.right ] );

        const minMaxUnits = 10;
        const yScaleMax = Math.max( minMaxUnits, d3.max( series, d => d3.max( d, d => d[ 1 ] ) * 1.1 ) );
        let y = d3.scaleLinear()
            .domain( [ 0, yScaleMax ] ).nice()
            .range( [ height - margin.bottom, margin.top ] )

        let xAxis = (g: d3.Selection<SVGGElement, ItemDatum, HTMLElement, ItemDatum>) => g
            .attr( "transform", `translate(0,${height - margin.bottom})` )
            .call( d3.axisBottom( x ).ticks( width / 120 ).tickSizeOuter( 0 ) )

        let yAxis = (g: d3.Selection<SVGGElement, ItemDatum, HTMLElement, ItemDatum>) => g
            .attr( "transform", `translate(${margin.left},0)` )
            .call( d3.axisLeft( y ) )
            .call( g => g.select( ".domain" ).remove() )
            .call( g => g.select( ".tick:last-of-type text" ).clone()
                .attr( "x", 3 )
                .attr( "text-anchor", "start" )
                .attr( "font-weight", "bold" )
                .text( "units" ) )

        const color = d3.scaleOrdinal<string>()
            .domain( columns )
            .range( [ 'url(#svgGradient)', 'url(#svgGradient3)', 'url(#svgGradient2)' ] )

        let area = d3.area<SeriesPoint<ItemDatum>>()
            .x( d => x( d.data.date ) )
            .y0( d => y( d[ 0 ] ) )
            .y1( d => y( d[ 1 ] ) )

        // Clear
        d3.select( ".chart" ).selectAll(['g','path','line'] as any).remove();

        const svg = d3.select<HTMLElement, ItemDatum>( ".chart" )
            .attr( "viewBox", `0 0 ${width} ${height}` )
            .attr( "width", width )
            .attr( "height", height );

        svg.append( "g" )
            .call( xAxis );

        svg.append( "g" )
            .call( yAxis );

        svg.append( "g" )
            .selectAll( "path" )
            .data( series )
            .join( "path" )
            .attr( "fill", ( {key} ) => color( key ) )
            .attr( "d", area )
            .append( "title" )
            .text( ( { key } ) => key )

        svg.append( "line" )
            .attr( 'stroke', '#cccccc' )
            .attr( 'x1', x( data[ 0 ].date ) )
            .attr( 'x2', x( data[ data.length - 1 ].date ) )
            .attr( 'y1', y( this._max ) - 1 )
            .attr( 'y2', y( this._max ) - 1 );


        const tooltip = svg.append( "g" );
        const bisect = _getBisectFunc();
        const callout = _calloutFunc;

        svg.on( "touchmove mousemove", function (event) 
        {
            const { date, value, rented, returned, lost } = bisect( d3.pointer( event, this )[ 0 ] );

            tooltip.attr( "transform", `translate(${x( date )},${y( value )})` )
                .call( callout,
                    `${value} items
${rented} rented
${returned} returned
${lost} lost
${date.toLocaleString( undefined, { month: "short", day: "numeric", year: "numeric" } )}`
                );
        } );

        svg.on( "touchend mouseleave", () => tooltip.call( callout, null ) );

        function _getBisectFunc() 
        {
            const bisect = d3.bisector<ItemDatum, Date>( d => d.date ).left;
            return (mx: number) =>
            {
                const date = x.invert( mx );
                const index = bisect( data, date, 1 );
                if ( index >= data.length )
                {
                    // TODO: Solve this?
                    throw "Index out of bounds";
                }
                const a = data[ index - 1 ];
                const b = data[ index ];
                return date.valueOf() - a.date.valueOf() > b.date.valueOf() - date.valueOf() 
                    ? b 
                    : a;
            };
        }

        function _calloutFunc( g, value ) 
        {
            if ( !value ) { return g.style( "display", "none" ) };

            g.style( "display", null )
                .style( "pointer-events", "none" )
                .style( "font", "10px sans-serif" );

            const circle = g.selectAll( "circle" )
                .data( [ null ] )
                .join( "circle" )
                .attr( "fill", "white" )
                .attr( "stroke", "#666666" );

            const path = g.selectAll( "path" )
                .data( [ null ] )
                .join( "path" )
                .attr( "fill", "white" )
                .attr( "stroke", "#666666" );

            const text = g.selectAll( "text" )
                .data( [ null ] )
                .join( "text" )
                .call( text => text
                    .selectAll( "tspan" )
                    .data( ( value + "" ).split( /\n/ ) )
                    .join( "tspan" )
                    .attr( "x", 0 )
                    .attr( "y", ( d, i ) => `${i * 1.1}em` )
                    .style( "font-weight", ( _, i ) => i ? null : "bold" )
                    .text( d => d ) );

            const { x, y, width: w, height: h } = text.node().getBBox();

            text.attr( "transform", `translate(${-w / 2},${-115 + 10})` );
            path.attr( "d", `M${-w / 2 - 10},-50H-5l5,5l5,-5H${w / 2 + 10}v${-h - 20}h-${w + 20}z` );
            circle.attr( "cx", x )
                .attr( "cy", y + 10 )
                .attr( "r", 3 )
        }
    }

}
