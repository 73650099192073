import { BaseModel } from './Base.model';
import { Discardable } from './mixins/Discardable.mixin'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from '../services/app-injector.service';
import { RouteMap } from '../services/network/route-map.service';
import { VariantDefinition } from './contracts/VariantDefinition.interface';
import { VariantService } from './VariantService.model';
import { VariantServicePropertyDefinition } from './VariantServicePropertyDefinition.model';
import { Thumbnail } from './Thumbnail.model';
import { VariantServiceCollection } from './collections/VariantService.collection';
import { OverrideableItem } from './contracts/OverrideableItem.interface';

const MixinBase = Discardable( BaseModel );
export class ServiceDefinition extends MixinBase implements VariantDefinition, OverrideableItem {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    static readonly PRICING_HOUR = 'auto';
    static readonly PRICING_UNIT = 'unit';
    static readonly PRICING_MANUAL = 'manual';

    pricingTypes = [
        ServiceDefinition.PRICING_HOUR,
        ServiceDefinition.PRICING_UNIT,
        ServiceDefinition.PRICING_MANUAL
    ];

    @dto() id: string = null;
    @dto() variant_configuration_id: string = null;
    @dto() variants_enabled: boolean = false;
    @dto() variant_rates_enabled: boolean = false;
    @dto() department_id: string = null;
    @dto() name: string = null;
    @dto() is_variant: boolean = false;
    @dto() is_rate_variant: boolean = false;
    @dto() code: string = null;
    @dto() tags: string = null;
    @dto() description: string = null;
    @dto() default_pricing_type: string = ServiceDefinition.PRICING_HOUR;
    @dto() regular_rate: number = null;
    @dto() overtime_rate: number = null;
    @dto() unit_short: string = ServiceDefinition.PRICING_UNIT;
    @dto() unit_long: string = ServiceDefinition.PRICING_UNIT;
    @dto() disabled: boolean = false;

    @dto(VariantService) variants: VariantService[] = [];
    @dto(VariantServicePropertyDefinition) variant_property_definitions: VariantServicePropertyDefinition[] = [];
    @dto(Thumbnail) thumbnail: Thumbnail = null;

    get variant_fields(): string[] 
    {
        return ['default_pricing_type', 'regular_rate', 'overtime_rate'];
    }

    get variant_property_fields(): string[] 
    {
        return ['regular_rate', 'overtime_rate'];
    }

    static async get(id: string): Promise<ServiceDefinition> 
    {
        const response = await AppInjector.get(RouteMap).getServiceDefinition(id);
        return new ServiceDefinition( response.data() );
    }

    async save(): Promise<ServiceDefinition> 
    {
        const response = this.exists()
            ? await this.routeMap.updateServiceDefinition( this.id, this.flush() )
            : await this.routeMap.createServiceDefinition( this.flush() );

        return this.map( response.data() );
    }

    async discard(): Promise<ServiceDefinition> 
    {
        const response = await this.routeMap.discardServiceDefinition( this.id );
        return this.map( response.data() );
    }

    async reload(): Promise<ServiceDefinition>
    {
        const response = await this.routeMap.getServiceDefinition(this.id);

        return this.map( response.data() );
    }

    async loadVariants(): Promise<void>
    {
        this.variants = await (new VariantServiceCollection()).all({ 
            variant_configuration_id: this.variant_configuration_id 
        });
    }

    newProp(): VariantServicePropertyDefinition
    {
        return new VariantServicePropertyDefinition({
            variant_configuration_id: this.variant_configuration_id,
            ordinal: this.variant_property_definitions.length,
        });
    }

    newVariant(): VariantService
    {
        return new VariantService({ 
            variant_configuration_id: this.variant_configuration_id
        });
    }

    async updateVariantPropsOrdinals(): Promise<VariantServicePropertyDefinition[]>
    {
        const data = this.variant_property_definitions.map(definition => {
            return {
                id: definition.id,
                ordinal: definition.ordinal,
            }
        });

        const response = await this.routeMap.updateVariantProps(data);

        this.variant_property_definitions.forEach(definition => {
            const updatedOrdinal = response.data().find(data => data.id === definition.id).ordinal;
            definition.ordinal = updatedOrdinal;
        });

        return this.variant_property_definitions;
    }

    applyPricingDefaults(): void 
    {
        if (this.default_pricing_type !== ServiceDefinition.PRICING_HOUR)
        {
            this.overtime_rate = 0;
        }
    }

    getPriceTypeLabel(priceType: string): string
    {
        switch (priceType) {
            case ServiceDefinition.PRICING_HOUR:
                return 'Hour';
            case ServiceDefinition.PRICING_UNIT:
                return 'Unit';
            case ServiceDefinition.PRICING_MANUAL:
                return 'Flat';
        }
    }

    getDisplayName(): string
    {
        return this.name;
    }
}
