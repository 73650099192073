import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NoteEditorData } from "./note-editor-data.interface";
import { NoteEditorComponent } from "./note-editor.component";

@Injectable({
    providedIn: 'root'
})
export class NoteEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: NoteEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            panelClass: 'bl-dialog',
            data
          }

        return this.dialog.open(NoteEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
