import { Ng2StateDeclaration } from '@uirouter/angular';
import { DispatchScheduleComponent } from './dispatch-schedule.component';

export const DispatchScheduleState: Ng2StateDeclaration = {
  name: 'protected.dispatch-schedule',
  url: '/dispatch-schedule',
  component: DispatchScheduleComponent,
  data: { 
    pageTitle: 'Dispatches', 
    requiresAuth: true,
  },
}
