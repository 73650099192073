import { Injectable } from '@angular/core';
import { Uuid } from '@beaconlite/types';
import { PreferencesData } from '../preferences.service';
import { ApiClient } from './api-client.service';
import { HttpResponse } from './http-response';

@Injectable({
  providedIn: 'root'
})
export class RouteMap {
  constructor(private apiClient: ApiClient) {}

  // TODO: still need to type hint all the function parameters

  // Accounts

  async getAuthenticatedAccount(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/auth/account')
        .get();
  }

  async searchAccounts(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounts')
        .params(params)
        .get();
  }

  async createAccount(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounts')
        .data(data)
        .post();
  }

  async getAccount(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounts/:id')
        .param('id', id)
        .get();
  }

  async updateAccount(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounts/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardAccount(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounts/:id/discard')
        .param('id', id)
        .post();
  }

  async recoverAccount(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounts/:id/recover')
        .param('id', id)
        .post();
  }
   
  async forgotPassword(email): Promise<HttpResponse> 
  {
    return this.apiClient.request()
        .path('/password/forgot')
        .param('email', email)
        .post();
  }

  async resetPassword(params): Promise<HttpResponse> 
  {
    return this.apiClient.request()
        .path('/password/reset')
        .data(params)
        .post();
  }

  async allAccountRoles(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/account-roles')
        .get();
  }

  // Employees

  async searchEmployees(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/employees')
        .params(params)
        .get();
  }

  async createEmployee(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/employees')
        .data(data)
        .post();
  }

  async getEmployee(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/employees/:id')
        .param('id', id)
        .get();
  }

  async updateEmployee(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/employees/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardEmployee(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/employees/:id/discard')
        .param('id', id)
        .post();
  }

  async recoverEmployee(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/employees/:id/recover')
        .param('id', id)
        .post();
  }

    // Branches

  async searchBranches(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/branches')
        .params(params)
        .get();
  }

  async createBranch(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/branches')
        .data(data)
        .post();
  }

  async getBranch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/branches/:id')
        .param('id', id)
        .get();
  }

  async updateBranch(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/branches/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardBranch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/branches/:id/discard')
        .param('id', id)
        .post();
  }

  async recoverBranch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/branches/:id/recover')
        .param('id', id)
        .post();
  }


  // Clients

  async searchClients(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/clients')
        .params(params)
        .get();
  }

  async createClient(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/clients')
        .data(data)
        .post();
  }

  async getClient(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/clients/:id')
        .param('id', id)
        .get();
  }

  async updateClient(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/clients/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardClient(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/clients/:id/discard')
        .param('id', id)
        .post();
  }

  async recoverClient(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/clients/:id/recover')
        .param('id', id)
        .post();
  }

  // Departments

  async searchDepartments(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/departments')
        .params(params)
        .get();
  }

  async createDepartment(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/departments')
        .data(data)
        .post();
  }

  async getDepartment(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/departments/:id')
        .param('id', id)
        .get();
  }

  async updateDepartment(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/departments/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  // RentalDefinitions

  async searchRentalDefinitions(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-definitions')
        .params(params)
        .get();
  }

  async createRentalDefinition(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-definitions')
        .data(data)
        .post();
  }

  async getRentalDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-definitions/:id')
        .param('id', id)
        .get();
  }

  async updateRentalDefinition(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-definitions/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardRentalDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-definitions/:id/discard')
        .param('id', id)
        .post();
  }

  // Rental Group Definitions
  async createRentalGroupDefinition(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-group-definitions')
        .data(data)
        .post();
  }

  async getRentalGroupDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-group-definitions/:id')
        .param('id', id)
        .get();
  }

  async updateRentalGroupDefinition(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-group-definitions/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async deleteRentalGroupDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-group-definitions/:id')
        .param('id', id)
        .delete();
  }

  async searchRentalGroupDefinitionResources(params): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
        .path('/rental-group-definition-resources')
        .params(params)
        .get();
  }

  async searchHigherOrderRentalResources(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/higher-order-rental-resources')
        .params(params)
        .get();
  }

  async searchRentalRequestResources(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/rental-request-resources')
        .params(params)
        .get();
  }

  // ServiceDefinitions

  async searchServiceDefinitions(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/service-definitions')
        .params(params)
        .get();
  }

  async createServiceDefinition(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/service-definitions')
        .data(data)
        .post();
  }

  async getServiceDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/service-definitions/:id')
        .param('id', id)
        .get();
  }

  async updateServiceDefinition(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/service-definitions/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardServiceDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/service-definitions/:id/discard')
        .param('id', id)
        .post();
  }

  // LineItemDefinitions

  async searchLineItemDefinitions(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/line-item-definitions')
        .params(params)
        .get();
  }

  async createLineItemDefinition(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/line-item-definitions')
        .data(data)
        .post();
  }

  async getLineItemDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/line-item-definitions/:id')
        .param('id', id)
        .get();
  }

  async updateLineItemDefinition(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/line-item-definitions/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardLineItemDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/line-item-definitions/:id/discard')
        .param('id', id)
        .post();
  }

  // ChargeDefinitions

  async searchChargeDefinitions(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/charge-definitions')
        .params(params)
        .get();
  }

  async createChargeDefinition(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/charge-definitions')
        .data(data)
        .post();
  }

  async getChargeDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/charge-definitions/:id')
        .param('id', id)
        .get();
  }

  async updateChargeDefinition(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/charge-definitions/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardChargeDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/charge-definitions/:id/discard')
        .param('id', id)
        .post();
  }

  async recoverChargeDefinition(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/charge-definitions/:id/recover')
        .param('id', id)
        .post();
  }

  // WorkOrders

  async searchWorkOrders(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/work-orders')
        .params(params)
        .get();
  }

  async createWorkOrder(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/work-orders')
        .data(data)
        .post();
  }

  async getWorkOrder(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/work-orders/:id')
        .param('id', id)
        .get();
  }

  async updateWorkOrder(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/work-orders/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardWorkOrder(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/work-orders/:id/discard')
        .param('id', id)
        .data(data)
        .post();
  }

  async recoverWorkOrder(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/work-orders/:id/recover')
        .param('id', id)
        .post();
  }

  async closeWorkOrder(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth({handleFailure: false})
        .path('/work-orders/:id/close')
        .param('id', id)
        .post();
  }

  async stopCharge(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/charges/:id/stop')
        .param('id', id)
        .post();
  }
  
  async stopNote(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/notes/:id/stop')
        .param('id', id)
        .post();
  }

  // Issues

  async createIssue(data: object): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
        .path('/issues')
        .data(data)
        .post();
  }

  async updateIssue(id: Uuid, data: object): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
        .path('/issues/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async resolveIssue(id: Uuid): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
        .path('/issues/:id/resolve')
        .param('id', id)
        .patch();
  }

  async unresolveIssue(id: Uuid): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
        .path('/issues/:id/unresolve')
        .param('id', id)
        .patch();
  }

  async discardIssue(id: Uuid): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
        .path('/issues/:id/discard')
        .param('id', id)
        .post();
  }

  // Line Item Requests
  async createLineItemRequest(data): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/line-item-requests')
      .data(data)
      .post();
  }

  async updateLineItemRequest(id: Uuid, data): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/line-item-requests/:id')
      .param('id', id)
      .data(data)
      .post();
  }

  async deleteLineItemRequest(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/line-item-requests/:id')
        .param('id', id)
        .delete();
  }

  // Dispatches

  async searchDispatches(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches')
        .params(params)
        .get();
  }

  async createDispatch(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches')
        .data(data)
        .post();
  }

  async getDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id')
        .param('id', id)
        .get();
  }

  async getDispatchNotifications(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/notifications')
        .param('id', id)
        .get();
  }

  async updateDispatch(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async dispatchDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/dispatch')
        .param('id', id)
        .post();
  }

  async cancelDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/cancel')
        .param('id', id)
        .post();
  }

  async actionDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/action')
        .param('id', id)
        .post();
  }

  async completeDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/complete')
        .param('id', id)
        .post();
  }

  async uncompleteDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/uncomplete')
        .param('id', id)
        .post();
  }

  async discardDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/discard')
        .param('id', id)
        .post();
  }

  async recoverDispatch(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/recover')
        .param('id', id)
        .post();
  }

  async reviewDispatchedRequest(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatched-rental-requests/:id/review')
        .param('id', id)
        .data(data)
        .post();
  }

  async reviewDispatchedService(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatched-services/:id/review')
        .param('id', id)
        .data(data)
        .post();
  }

  // Invoices

  async searchInvoices(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices')
        .params(params)
        .get();
  }

  async previewInvoice(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices/preview')
        .data(data)
        .post();
  }

  async createInvoice(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices')
        .data(data)
        .post();
  }

  async getInvoice(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices/:id')
        .param('id', id)
        .get();
  }

  async voidInvoice(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices/:id/void')
        .param('id', id)
        .data(data)
        .post();
  }

  async discardInvoice(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices/:id/discard')
        .param('id', id)
        .data(data)
        .post();
  }

  async sendInvoice(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices/:id/send')
        .param('id', id)
        .data(data)
        .post();
  }

  async getInvoicePdf(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/invoices/:id/pdf')
        .param('id', id)
        .get();
  }

  async markSentInvoice(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('invoices/:id/mark-sent')
        .param('id', id)
        .post();
  }

  // Exports

  async searchExports(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounting/exports')
        .params(params)
        .get();
  }

  async createExport(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounting/exports')
        .data(data)
        .post();
  }

  // TODO: This isn't set up in the API. Jira task BL-512.
  async discardExport(id: Uuid, data?): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/accounting/exports/:id/discard')
        .param('id', id)
        .data(data)
        .post();
  }

  // Resource Locks

  async listResourceLock(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/locks')
        .get();
  }

  async requestResourceLock(resource, id): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/locks/:resource/:id')
        .param('resource', resource)
        .param('id', id)
        .get();
  }

  async extendResourceLock(id): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth({handleFailure: false})
        .path('/locks/:id/extend')
        .param('id', id)
        .post();
  }

  async revokeResourceLock(id): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/locks/:id')
        .param('id', id)
        .delete();
  }

  // Taxes

  async allTaxRates(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/tax-rates')
        .get();
  }

  async getTaxRate(tag): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/tax-rates/:tag')
        .param('tag', tag)
        .get();
  }

  // Signatures

  async getSignature(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/signatures/:id')
        .param('id', id)
        .get();
  }

  async updateSignature(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/signatures/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardSignature(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/signatures/:id/discard')
        .param('id', id)
        .post();
  }

  async signDispatch(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/sign')
        .param('id', id)
        .data(data)
        .post();
  }

  async sendDispatch(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/send')
        .param('id', id)
        .data(data)
        .post();
  }
  
  async markSentDispatch(id: Uuid): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/mark-sent')
        .param('id', id)
        .post();
  }

  async notifyDispatch(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/notify')
        .param('id', id)
        .data(data)
        .post();
  }

  async getDispatchPdf(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/pdf')
        .param('id', id)
        .get();
  }

  // Accounting Settings

  async getAccountingSettings(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/settings/accounting')
        .get();
  }

  async updateAccountingSettings(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/settings/accounting')
        .data(data)
        .put();
  }

  // Export Settings

  async getIntegration(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/settings/integrations/:id')
        .param('id', id)
        .get();
  }

  async getIntegrationBySlug(slug: string): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/settings/integrations')
        .param('slug', slug)
        .get();
  }

  async getActiveAccountingIntegration(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/settings/integrations/active-integration')
        .get();
  }

  async updateIntegration(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/settings/integrations/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  // Core settings
  
  async getCoreSettings(): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/settings/core')
      .get();
  }

  async updateCoreSettings(data: PreferencesData): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/settings/core')
      .data(data)
      .put();
  }

  // Dispatch Regions

  async getDispatchRegion(id: Uuid): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/settings/dispatch-regions/:id')
      .param('id', id)
      .get();
  }

  async allDispatchRegions(): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/settings/dispatch-regions')
      .get();
  }

  async updateDispatchRegion(id: Uuid, data: any): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/settings/dispatch-regions/:id')
      .param('id', id)
      .data(data)
      .put();
  }

  async createDispatchRegion(data: any): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/settings/dispatch-regions')
      .data(data)
      .post();
  }

  async deleteDispatchRegion(id: Uuid): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/settings/dispatch-regions/:id')
      .param('id', id)
      .delete();
  }

  // Attachments

  async presignAttachmentUrl(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/attachments/:id/presign')
        .param('id', id)
        .get();
  }

  // Documents

  async getDocument(id: Uuid)  
  {
    return this.apiClient.withAuth()
        .path('/documents/:id')
        .param('id', id)
        .get();
  }

  async updateDocument(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/documents/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async deleteDocument(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/documents/:id')
        .param('id', id)
        .delete();
  }

  async getDocumentUploadUrl(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/documents/upload-url')
        .get();
  }

  async createWorkOrderDocument(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/work-orders/:id/documents')
        .param('id', id)
        .data(data)
        .post();
  }

  async linkDispatchDocument(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/documents')
        .param('id', id)
        .data(data)
        .post();
  }

  async unlinkDispatchDocument(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/dispatches/:id/documents/unlink')
        .param('id', id)
        .data(data)
        .post();
  }

  // Reports

  async generateReport(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/reports')
        .data(data)
        .post();
  }

  async exportReport(data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/reports/export')
        .data(data)
        .post();
  }

  async getVersion(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/versions/web')
        .get();
  }

  async authorizeQuickBooks(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/quickbooks/url')
        .get();
  }

  async loadTaxesFromQuickBooks(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/quickbooks/taxes')
        .get();
  }

  async loadAccountsFromQuickBooks(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth({handleFailure: false})
        .path('/quickbooks/accounts')
        .get();
  }

  async createQuickBooksAccountMappings(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/quickbooks/account-mappings')
        .post();
  }

  async syncClientsWithQuickbooks(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/quickbooks/clients')
        .put();
  }

  async getSettings(slug): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth(slug)
        .path('/settings')
        .param('slug', slug)
        .get();
  }

  // Variant Rentals
  async getVariantRental(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-rentals/:id')
        .param('id', id)
        .get();
  }

  async searchVariantRentals(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-rentals')
        .params(params)
        .get();
  }
  
  async createVariantRental(data: any): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-rentals')
        .data(data)
        .post();
  }

  async updateVariantRental(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-rentals/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardVariantRental(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-rentals/:id')
        .param('id', id)
        .delete();
  }

  // Variant Services

  async searchVariantServices(params): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-services')
        .params(params)
        .get();
  }

  async createVariantService(data: any): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-services')
        .data(data)
        .post();
  }

  async updateVariantService(id: Uuid, data): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-services/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async discardVariantService(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-services/:id')
        .param('id', id)
        .delete();
  }

  async getThumbnailUploadUrl(): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/thumbnails/upload-url')
        .get();
  }

  async deleteThumbnail(id: Uuid): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/thumbnails/:id')
        .param('id', id)
        .delete();
  }

  // Variant Property Definitions

  async createVariantProp(data: any): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/variant-property-definitions')
      .data(data)
      .post();
  }

  async updateVariantProp(id: Uuid, data: any): Promise<HttpResponse> 
  {
    return this.apiClient.withAuth()
        .path('/variant-property-definitions/:id')
        .param('id', id)
        .data(data)
        .put();
  }

  async updateVariantProps(data: any): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/variant-property-definitions')
      .data(data)
      .patch();
  }

  async discardVariantProp(id: Uuid): Promise<HttpResponse>
  {
    return this.apiClient.withAuth()
      .path('/variant-property-definitions/:id')
      .param('id', id)
      .delete();
  }
}
