import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { Component, Input, Inject, OnInit } from '@angular/core';
import { Dispatch, Note, TaxRate, DispatchedRental, DispatchedRentalRequest, DispatchedService } from '@beaconlite/models';
import { SettingsService } from '../../../services/settings.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-dispatch-print',
  templateUrl: './dispatch-print.component.html',
})
export class DispatchPrintComponent implements OnInit {

  @Input() modelId: string;

  loaded = false;
  company = this.settings.get('company_config');
  taxRate: TaxRate = null;
  dispatchNotes: Note[] = [];
  dispatch: Dispatch;

  // Sorted dispatch values
  perHourServices: DispatchedService[];
  perUnitServices: DispatchedService[];
  

  constructor(
    protected settings: SettingsService,
    @Inject(DOCUMENT) protected document: Document,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    if (!! this.modelId)
    {
      try
      {
        this.dispatch = await Dispatch.get(this.modelId);
        this.document.title = `${this.dispatch.work_order.client.name} - ${this.dispatch.formatted_serial_id}`;
  
        let workOrderNotes = this.dispatch.work_order.notes.filter((note: Note) => note.type == Note.TYPE_DISPATCH )
        this.dispatchNotes = this.dispatch.dispatch_notes.concat(workOrderNotes);

        this._loadTaxInfo(this.dispatch);

        this.dispatch.signatures?.forEach(signature => { signature.attachment.getUrl() })

        this._sortItems();
      }
      finally
      {
        this.loaded = true;
      }
    }
  }

  protected async _loadTaxInfo(dispatch: Dispatch): Promise<void>
  {
    this.taxRate = await TaxRate.get(dispatch.work_order.taxes);
  }

  getDisplayType(): string
  {
      let type = this.dispatch.type;

      return this.dispatch.hasServices() ? 'Service' : type;
  }

  getHeaderClass(): string
  {
      if ( this.dispatch.type == Dispatch.TYPE_PICKUP )
      {
          return 'dispatch__header--pickup';
      }

      return 'dispatch__header--delivery-' + (this.dispatch.hasServices() ? 'service' : 'rental'); 
  }

  getTravelTime(service: DispatchedService): number|void
  {
      return this.dispatch.actioned_at ? service.travel_time : service.source.travel_time
  }

  getServiceStartedAt(service: DispatchedService): number|void
  {
      return service.reviewed_started_at || service.actioned_started_at         
  }

  getServiceEndedAt(service: DispatchedService): number|void
  {
      return service.reviewed_ended_at || service.actioned_ended_at
  }

  getCapturedRentalQuantity(request: DispatchedRentalRequest, rental: DispatchedRental): number|void
  {
      return this.getCapturedQuantity(request.reviewed_at, rental)
  }

  getCapturedServiceQuantity(service: DispatchedService): number|void
  {
      return this.getCapturedQuantity(service.reviewed_at, service)
  }

  getCapturedQuantity(hasBeenReviewed: number, requestItem: DispatchedService|DispatchedRental): number|void
  {
      if (!! hasBeenReviewed )
      {
          return requestItem.reviewed_quantity
      }
      
      if ( this.dispatch.actioned_at )
      {
          return requestItem.actioned_quantity
      }
  }

  getLineCount(): number
  {
    return Math.max(0,5-this.dispatch.field_notes.length);
  }

  now(): number
  {
    return moment().unix();       
  }

  protected _sortItems(): void
  {
    this.dispatchNotes?.sort(orderBy('noted_at'));

    this.dispatch.rental_requests 
      ?.sort(orderBy('dispatched_started_at', 'source.formatted_serial_id'))
      .forEach(request => {
        request.source.dispatch_notes?.sort(orderBy('noted_at'));
        request.rentals?.sort(orderBy('source.position'));
      });
    
    this.perHourServices = this.dispatch.per_hour_services
      .sort(orderBy('dispatched_started_at', 'source.formatted_serial_id'));
    this.perHourServices.forEach(service => {
        service.source.dispatch_notes.sort(orderBy('noted_at'));
      });

    this.perUnitServices = this.dispatch.per_unit_services
      .sort(orderBy('dispatched_started_at', 'source.formatted_serial_id'));
    this.perUnitServices.forEach(service => {
        service.source.dispatch_notes.sort(orderBy('noted_at'));
      });

    this.dispatch.employees?.sort(orderBy('first_name'));
    this.dispatch.field_notes?.sort(orderBy('noted_at'));
    this.dispatch.signatures?.sort(orderBy('captured_at'));
  }
}
