import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { RentalEditorData } from "./rental-editor-data.interface";
import { RentalEditorComponent } from "./rental-editor.component";

@Injectable({
    providedIn: 'root'
})
export class RentalEditorService {
  
    constructor(protected dialog: MatDialog) {}
    
    async open(data: RentalEditorData): Promise<boolean>
    {
      const dialogConfig: MatDialogConfig = {
        disableClose: true,
        hasBackdrop: true,
        autoFocus: true,
        width: '100%',
        maxWidth: 800,
        data
      }
  
      return this.dialog.open(RentalEditorComponent, dialogConfig)
        .afterClosed()
        .toPromise();
    }
}