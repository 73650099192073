import { Component, Inject, Injectable, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DispatchRegion } from "@beaconlite/models";
import { DialogNotificationService } from "@beaconlite/services/notification/dialog-notification.service";

interface EditorData {
    original: DispatchRegion,
    onUpdate: (updatedRegion: DispatchRegion) => Promise<void>,
    onRemove: () => Promise<void>,
}

@Component({
    selector: 'app-dispatch-region-editor',
    templateUrl: './dispatch-region-editor.component.html',
})
export class DispatchRegionEditorComponent implements OnInit {
  
    @ViewChild('editorForm') editorForm: NgForm;

    original = this.data.original;
    onUpdate = this.data.onUpdate;
    onRemove = this.data.onRemove;
    region = new DispatchRegion();
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: EditorData,
        public dialogRef: MatDialogRef<DispatchRegionEditorComponent>,
        protected dialogNotifications: DialogNotificationService,
    ) { }

    ngOnInit(): void 
    {
        this.region = this.original.copy();
    }

    async onSave(): Promise<void>
    {
        if (this.editorForm.invalid) { return }

        this.loading = true;
        
        try
        {
            await this.onUpdate(this.region);
            this.dialogRef.close();
        }
        finally
        {
            this.loading = false;
        }
    }

    async onDelete(): Promise<void>
    {
      this.loading = true;
  
      try
      {
        if (await this.dialogNotifications.removeConfirm())
        {
          await this.onRemove();
          this.dialogRef.close();
        }
      }
      finally
      {
        this.loading = false;
      }
    }
}

@Injectable({
    providedIn: 'root'
})
export class DispatchRegionEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: EditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            data,
        }

        return this.dialog.open(DispatchRegionEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
