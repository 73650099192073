import { AppInjector } from "../../services/app-injector.service";
import { FileDownload } from "../../services/file-download.service";
import { HttpResponse } from "../../services/network/http-response";
import { RouteMap } from "../../services/network/route-map.service";
import { BaseModel } from "../Base.model";
import { dto } from "../mixins/Dto.decorators";
import { ActiveReportItem } from "./contracts/ActiveReportItem.interface";
import { ReportData } from "./contracts/ReportData.interface";

export class ActiveItemReport extends BaseModel {

    constructor(attributes: object) 
    {
        super();
        this.init(attributes)
    }

    @dto() params: ReportData = {};
    @dto() data: ActiveReportItem[] = [];
    @dto() items: ActiveReportItem[] = [];

    static async generate(data: ReportData): Promise<ActiveItemReport>
    {
        const response: HttpResponse = await AppInjector.get(RouteMap).generateReport(data);
        return new ActiveItemReport({
            params: data,
            data: response.data(),
            items: response.data(),
        });
    }

    static async export(data: ReportData): Promise<void>
    {   
        const response: HttpResponse = await AppInjector.get(RouteMap).exportReport(data);
        AppInjector.get(FileDownload).asBase64(response);
    }

    nextLayer(currentLayer: string): string|null
    {
        const layers = this.params.list_by
        const index = layers.indexOf(currentLayer)

        if (index < 0 || index+1 >= layers.length ) { return null }

        return layers[index+1]
    }
    
    _processData(report)
    {
        return
    }
}
