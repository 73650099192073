import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[minNum][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinNumValidatorDirective, multi: true }],
})
export class MinNumValidatorDirective implements Validator {
    @Input('minNum') minNum: number;

    validate(control: AbstractControl) {
        if (control.value == null || control.value == undefined ) return null;

        const isValid = control.value >= this.minNum;

        return isValid
            ? null
            : { minNum: true };
    }
}
