import { VariantOption } from "./VariantOption.model";
import { dto } from "./mixins/Dto.decorators";

export class VariantRentalOption extends VariantOption {

    constructor(attributes: object = {})
    {
        super();
        this.map(attributes);
    }

    @dto() daily_rate?: number = 0;
    @dto() retail_cost?: number = 0;
}
