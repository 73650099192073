import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Department, Employee } from '@beaconlite/models';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { EmployeeSingleState } from '@beaconlite/views/protected/employees/employee-single/employee-single.state';

@Component( {
    selector: 'employee-component',
    templateUrl: './employee.component.html',
} )
export class EmployeeComponent implements OnInit
{
    @Input() employee: Employee;

    loading = false;

    department: Promise<Department>;


    constructor(
        protected appData: AppData,
        private stateService: StateService,
    ) { }

    async ngOnInit(): Promise<void>
    {
        const departmentsPromise = this.appData.departments.getAll();

        this.department = departmentsPromise.then( departments => {
            return departments.find( department => department.id === this.employee.department_id );
        });
    }

    onEdit(): void
    {
        this.stateService.go( EmployeeSingleState, { modelId: this.employee.id } );
    }

}
