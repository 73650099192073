<form class="mat-dialog-editor" #editorForm (ngSubmit)="this.onSave()" novalidate fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row">
        <h2 class="mat-title">{{ this.request.source.formatted_serial_id }} | Item {{ this.displayType }}</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding" fxLayout="column">

        <ng-container *ngIf="this.isFlattenedView; then flattenedTable; else groupedTable">
        </ng-container>

        <ng-template #flattenedTable>
            <mat-table class="mat-mobile-table" fxFlex="100" [dataSource]="getArrayFromMapIterable(flattenedRentals.values())">
                <ng-container matColumnDef="item">
                    <mat-header-cell fxFlex="60" fxFlex.gt-xs="70" *matHeaderCellDef>Item</mat-header-cell>
                    <mat-cell fxFlex="60" fxFlex.gt-xs="70" *matCellDef="let rental">
                        <div fxLayout="column">
                            <span>{{ rental.dispatchedRentals[0].source.name }}</span>
                            <span class="mat-caption">{{ rental.dispatchedRentals[0].source.detail }}</span>
                        </div>
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="requested">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Requested</mat-header-cell>
                    <mat-cell class="mat-cell--numeric" *matCellDef="let rental">{{ rental.dispatched_quantity }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="dispatched-quantity">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>{{ this.actionLabel }}</mat-header-cell>
                    <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let rental; let i = index;">
                        <mat-form-field>
                            <input type="number"
                                [(ngModel)]="rental.actioned_quantity"
                                name="actionedQuantityField{{ i }}"
                                min="0"
                                matInput>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
    
                <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                <mat-row *matRowDef="let rental; columns: tableColumns"></mat-row>
            </mat-table>
        </ng-template>

        <ng-template #groupedTable>
            <mat-table class="mat-mobile-table" fxFlex="100" 
            [dataSource]="request.request_items"
            multiTemplateDataRows>
                <ng-container matColumnDef="item">
                    <mat-header-cell fxFlex="60" fxFlex.gt-xs="70" *matHeaderCellDef>Item</mat-header-cell>
                    <mat-cell fxFlex="60" fxFlex.gt-xs="70" *matCellDef="let requestItem">
                        <div fxLayout="column">
                            <span>{{ requestItem.source.name }}</span>
                            <span class="mat-caption">{{ requestItem.source.detail }}</span>
                        </div>
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="requested">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Requested</mat-header-cell>
                    <mat-cell class="mat-cell--numeric" *matCellDef="let requestItem">{{ requestItem.dispatched_quantity }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="dispatched-quantity">
                    <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>{{ this.actionLabel }}</mat-header-cell>
                    <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let requestItem; let i = dataIndex;">
                        <mat-form-field *ngIf="!requestItem?.rentals">
                            <input type="number"
                                [(ngModel)]="requestItem.actioned_quantity"
                                name="actionedQuantityField-outer-{{ i }}"
                                min="0"
                                matInput>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="groupItem">
                    <mat-cell *matCellDef="let requestItem" [fxShow]="requestItem?.rentals?.length">
                        <mat-table class="mat-mobile-table inner-table"
                            fxFlex="100"
                            [dataSource]="requestItem.rentals">
                            <ng-container matColumnDef="item">
                                <mat-cell fxFlex="60" fxFlex.gt-xs="70" *matCellDef="let groupItem">
                                    <span>{{ groupItem.source.name }}</span><br>
                                    <span>{{ groupItem.source.detail }}</span>
                                </mat-cell>
                            </ng-container>
                                            
                            <ng-container matColumnDef="requested">
                                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>Requested</mat-header-cell>
                                <mat-cell class="mat-cell--numeric" *matCellDef="let groupItem">{{ groupItem.dispatched_quantity }}</mat-cell>
                            </ng-container>
                            
                            <ng-container matColumnDef="dispatched-quantity">
                                <mat-header-cell class="mat-header-cell--numeric" *matHeaderCellDef>{{ this.actionLabel }}</mat-header-cell>
                                <mat-cell class="mat-cell--numeric mat-cell--form-field" *matCellDef="let groupItem; let j = index;">
                                    <mat-form-field>
                                        <input type="number"
                                            [(ngModel)]="groupItem.actioned_quantity"
                                            name="actionedQuantityField-inner-{{ j }}"
                                            min="0"
                                            matInput>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <mat-row *matRowDef="let groupItem; columns: tableColumns"></mat-row>
                        </mat-table>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                <mat-row *matRowDef="let requestItem; columns: tableColumns"></mat-row>
                <mat-row *matRowDef="let groupItem; columns: ['groupItem']" class="inner-row"></mat-row>

            </mat-table>
        </ng-template>

        <div fxLayout="row" fxLayoutAlign="end" layoutPadding *ngIf="this.canActionAll()">
            <button type="button" 
                mat-raised-button
                (click)="this.actionAll()">
                {{ this.getAllButtonLabel }} All
            </button>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>Cancel</button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.saving">Save</button>
    </mat-dialog-actions>
</form>
