import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dispatch, Invoice, Email, Attachment } from '@beaconlite/models';
import { EmailHistoryData } from './email-history-data.interface';
import { Contact } from '@beaconlite/models/contracts/Contact.interface';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-email-history',
  templateUrl: './email-history.component.html',
  styleUrls: ['./email-history.component.scss']
})

export class EmailHistoryComponent implements OnInit {

  sendableModel: Dispatch | Invoice = this.data.sendableModel;
  selectedEmail: Email = null;
  emailSubject: string;
  emailMessage: string;
  downloading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: EmailHistoryData,
    public dialogRef: MatDialogRef<EmailHistoryComponent>,
  ) { }

  async ngOnInit(): Promise<void> 
  {    
    this._sortItems();
    this.selectEmail(this.sendableModel.last_sent);
  }

  selectEmail(email: Email) 
  {
    this.selectedEmail = email;
    this.emailSubject = email.subject ||  'No Email Subject';
    this.emailMessage = email.content_html || '<p>No Email Message</p>';
    this._sortAttachments();
  }

  protected _sortItems(): void
  {
    this.sendableModel.emails.sort(orderBy('sent_at'));
  }

  protected _sortAttachments(): void {
    if (this.selectedEmail.attachments.length) {
      this.selectedEmail.attachments.sort(orderBy('name'));
    }
  }
}
