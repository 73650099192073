import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ClientAccountEditorData } from "./client-account-editor-data.interface";
import { ClientAccountEditorComponent } from "./client-account-editor.component";

@Injectable({
    providedIn: 'root'
})
export class ClientAccountEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: ClientAccountEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            minWidth: 800,
            data
          }

        return this.dialog.open(ClientAccountEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
