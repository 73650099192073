<form class="mat-dialog-editor client-account-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>

    <mat-toolbar fxLayout="row" fxLayoutAlign=" center">
        <h2 class="mat-title">Client Account Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div class="activate-account-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="mat-warn" fxLayout="column">
                <span class="mat-caption account-state-label" *ngIf="!this.account.active">
                    Inactive
                </span>
                <span class="mat-caption account-state-label" *ngIf="this.account.discarded">
                    Discarded
                </span>
            </div>
            <mat-slide-toggle [(ngModel)]="this.account.active"
                name="accountActiveField"
                [disabled]="this.account.discarded">
                Active
            </mat-slide-toggle>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
                <mat-label>First name</mat-label>
                <input type="text"
                    [(ngModel)]="this.account.person.first_name"
                    name="firstNameField"
                    #firstNameField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="firstNameField.hasError('required')">First name is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>Last name</mat-label>
                <input type="text"
                    [(ngModel)]="this.account.person.last_name"
                    name="lastNameField"
                    #lastNameField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="lastNameField.hasError('required')">Last name is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Email</mat-label>
                <input type="email"
                    [(ngModel)]="this.account.person.email"
                    name="emailField"
                    #emailField="ngModel"
                    [disabled]="this.locked"
                    email
                    matInput>
                <mat-error *ngIf="emailField.hasError('email')">Email is invalid.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <div fxLayout="column"fxFlex="50">
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input type="password"
                        [(ngModel)]="this.account.credentials.password"
                        name="passwordField"
                        #passwordField="ngModel"
                        [required]="!this.account.exists()"
                        matInput>
                    <mat-error *ngIf="passwordField.hasError('required')">Password is required.</mat-error>
                    <mat-error *ngIf="passwordField.hasError('passwordStrength')">Password is not strong enough.</mat-error>
                </mat-form-field>
                <app-password-strength [passwordModel]="passwordField" passwordStrength="12"></app-password-strength>
            </div>

            <mat-form-field fxFlex="50">
                <mat-label>Confirm password</mat-label>
                <input type="password"
                    [(ngModel)]="this.account.credentials.password_confirm"
                    name="passwordConfirmField"
                    #passwordConfirmField="ngModel"
                    valueMustMatch="passwordField"
                    [required]="!this.account.exists()"
                    matInput>
                <mat-error *ngIf="passwordConfirmField.hasError('required')">Password match is required.</mat-error>
                <mat-error *ngIf="passwordConfirmField.hasError('valueMustMatch')">Passwords must match.</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <button mat-icon-button 
            type="button"
            color="primary"
            *ngIf="this.canRemove()" 
            (click)="remove()" 
            [disabled]="this.loading"> 
            <mat-icon>delete</mat-icon>
        </button>

        <span fxFlex></span>

        <button mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.loading">
            Save
        </button>
    </mat-dialog-actions>
</form>