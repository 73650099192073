<mat-card class="medium-card">
    <div class="card-inner" fxLayout="column wrap">
        <div class="card__header" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">Variant Configuration</span>
            <button mat-icon-button color="primary" (click)="this.onEditVariantConfig()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="status__wrapper">
            <span class="mat-subheading-2">Variants: <strong>{{ variantsActiveString }}</strong></span>
            <div class="mat-caption">Create new items from a starting template while inheriting common attributes.</div>
        </div>
        <div class="status__wrapper">
            <span class="mat-subheading-2">Rate Variants: <strong>{{ rateVariantsActiveString }}</strong></span>
            <div class="mat-caption">Costs of rate variant attributes will be calculated based on the sum of selected dropdown properties.</div>
        </div>
    </div>
</mat-card>
