import { Helpers as $helpers } from './helpers.service';
import { Injectable } from '@angular/core';


export interface Config
{
    isProduction: boolean
    DEBUG: boolean
    APP_NAME: string
    APP_VERSION: string
    BACKEND_URL: string
    API_URL: string
    locale: string
    // set to 24hr or 12hr
    timeFormat: string
    taxes: string
    dateTimeFormat: {}
    companySettings: {}
    defaultDispatchCharges: String[]
}

let envService: EnvService;

@Injectable( {
    providedIn: 'root'
} )
export class EnvService
{
    private readonly _configUrl = 'assets/config.json'

    private _config: Config;


    constructor() { 
        envService = this;
    }

    async load(): Promise<Config> 
    {
        const response = await fetch( this._configUrl );
        const config = await response.json();
        return this._config = config;
    }

    get( path: string, fallback?: any ): any
    {
        return $helpers.dotNotationLookup( this._config, path ) || fallback;
    }
}

export const env = ( path: string, fallback?: any ) =>
{
    return envService.get(path, fallback);
}
