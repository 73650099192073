<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Rental Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            <app-thumbnail-control *ngIf="!this.loading && !this.rentalDefinition.variants_enabled"
                name="thumbnailField"
                [original]="this.rentalDefinition.thumbnail"
                (thumbnailSelected)="this.onThumbnailSelected($event)"
                (thumbnailRemoved)="this.onThumbnailRemoved($event)"
                (thumbnailUpdated)="this.onThumbnailUpdated($event)">
            </app-thumbnail-control>

            <mat-form-field fxFlex> 
                <mat-label>Name</mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalDefinition.name"
                    name="nameField"
                    #nameField="ngModel"
                    required
                    [disabled]="this.locked"
                    maxlength="255"
                    matInput>
                <mat-hint align="end">{{ this.rentalDefinition.name?.length || 0 }} / 255</mat-hint>
                <mat-error *ngIf="nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex fxFlex="80">
                <mat-label>Description</mat-label>
                <textarea [(ngModel)]="this.rentalDefinition.description"
                        name="descriptionField" 
                        rows="4"
                        [disabled]="this.locked"
                        matInput>
                </textarea>
            </mat-form-field>
            <div fxFlex="20" fxLayout="column" fxLayoutGap="10px">
                <mat-slide-toggle name="disableAddingToWorkOrdersField"
                    [(ngModel)]="this.rentalDefinition.disabled"
                    [disabled]="this.locked">
                    Disabled
                </mat-slide-toggle>
                <div class="form-instruction">Disabled items cannot be added to Work Orders</div>   
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Quantity</mat-label>
                <input type="number"
                    [(ngModel)]="this.rentalDefinition.quantity"
                    name="quantityField"
                    #quantityField="ngModel"
                    required
                    [minNum]="0"
                    [disabled]="this.locked || this.rentalDefinition.variants_enabled"
                    matInput>
                <mat-error *ngIf="quantityField.hasError('required')">Quantity is required.</mat-error>
                <mat-error *ngIf="quantityField.hasError('minNum')">Quantity must be at least 0.</mat-error>
            </mat-form-field>
            
            <mat-form-field fxFlex>
                <mat-label>Code</mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalDefinition.code"
                    name="codeField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.rentalDefinition.code?.length || 0 }} / 255</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Tags</mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalDefinition.tags"
                    name="tagField"
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
                <mat-hint align="end">{{ this.rentalDefinition.tags?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="this.departments?.length > 1">
            <mat-form-field fxFlex>
                <mat-label>Default Department</mat-label>
                <mat-select [(ngModel)]="this.rentalDefinition.department_id"
                    name="departmentField"
                    [disabled]="this.locked"
                    required>
                    <mat-option *ngFor="let department of this.departments" [value]="department.id">
                        {{ department.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Default Pricing</mat-label>
                <mat-select [(ngModel)]="this.rentalDefinition.default_pricing_type"
                    name="defaultPricingField"
                    [disabled]="this.locked"
                    required>
                    <mat-option *ngFor="let item of this.pricingTypes" [value]="item">
                        {{ this.getPriceTypeLabel(item) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>
                    {{ this.rentalDefinition.default_pricing_type === this.PRICING_FLAT ? '' : 'Daily ' }}Rate
                </mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalDefinition.daily_rate"
                    (change)="this.onDailyRateChange()"
                    name="dailyRateField"
                    #dailyRateField="ngModel"
                    currencyInput
                    [minNum]="0"
                    [disabled]="this.locked || this.rentalDefinition.variant_rates_enabled"
                    required
                    matInput>
                <mat-error *ngIf="dailyRateField.hasError('required')">Rate is required.</mat-error>
                <mat-error *ngIf="dailyRateField.hasError('minNum')">Rate must be at least $ 0.00.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex [fxShow]="this.rentalDefinition.default_pricing_type !== this.PRICING_FLAT">
                <mat-label>Minimum Charge</mat-label>
                <input type="text"
                    [(ngModel)]="this.rentalDefinition.minimum_charge"
                    name="minimumChargeField"
                    #minimumChargeField="ngModel"
                    currencyInput
                    [minNum]="0"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="minimumChargeField.hasError('required')">Minimum charge is required.</mat-error>
                <mat-error *ngIf="minimumChargeField.hasError('minNum')">Minimum charge must be at least $ 0.00.</mat-error>
            </mat-form-field>
        </div>

        <div class="fieldset" fxLayout="column" [fxShow]="this.rentalDefinition.default_pricing_type === this.PRICING_RATED">
            <div fxLayout="row">
                <h3 class="mat-subheading-2">Discounts</h3>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field fxFlex="20">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="this.isPercentDiscount"
                        (selectionChange)="this.onIsPercentDiscountChange($event.value)"
                        name="chargeTypeField"
                        #isPercentDiscountField="ngModel"
                        [disabled]="this.locked"
                        required>
                        <mat-option [value]="true">Percent Discount</mat-option>
                        <mat-option [value]="false">Discounted Dollar Rate</mat-option>
                    </mat-select>
                    <mat-error *ngIf="isPercentDiscountField.hasError('required')">Type is required.</mat-error>
                </mat-form-field>
                <div fxFlex fxFlexAlign="center">
                    <p class="form-instruction">
                        Dollar values displayed approximate.
                    </p>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field fxFlex="20" *ngIf="this.isPercentDiscount">
                    <mat-label>Weekly </mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalDefinition.weekly_discount"
                        name="weeklyDiscountField"
                        #weeklyDiscountField="ngModel"
                        [disabled]="this.locked"
                        percentInput
                        [minNum]="0"
                        [maxNum]="1"
                        required
                        matInput>
                    <mat-error *ngIf="weeklyDiscountField.hasError('required')">Weekly discount is required.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('minNum')">Weekly discount must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('maxNum')">Weekly discount cannot exceed 100.00 %.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="20" *ngIf="!this.isPercentDiscount">
                    <mat-label>Weekly Dollar</mat-label>
                    <input type="text"
                        [(ngModel)]="this.weeklyDiscount"
                        (change)="this.onWeeklyDiscountPriceChange()"
                        name="weeklyDiscountField"
                        #weeklyDiscountField="ngModel"
                        [disabled]="this.locked"
                        currencyInput
                        [minNum]="0"
                        [maxNum]="this.rentalDefinition.daily_rate"
                        required
                        matInput>
                    <mat-error *ngIf="weeklyDiscountField.hasError('required')">Weekly discount is required.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('minNum')">Weekly discount must be at least $0.00.</mat-error>
                    <mat-error *ngIf="weeklyDiscountField.hasError('maxNum')">Weekly discount cannot exceed price of rental.</mat-error>
                </mat-form-field>

                <div fxFlex fxFlexAlign="center">
                    <p class="form-instruction">
                        {{ this.calculateRate(this.rentalDefinition.weekly_discount, 1) | currencyFilter }} /day<br>
                        {{ this.calculateRate(this.rentalDefinition.weekly_discount, this.weekThreshold) | currencyFilter }} /week ({{ this.weekThreshold }} days)
                    </p>
                </div>

                <mat-form-field fxFlex="20" *ngIf="this.isPercentDiscount">
                    <mat-label>Monthly</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalDefinition.monthly_discount"
                        name="monthlyDiscountField"
                        #monthlyDiscountField="ngModel"
                        [disabled]="this.locked"
                        percentInput
                        [minNum]="0"
                        [maxNum]="1"
                        required
                        matInput>
                    <mat-error *ngIf="monthlyDiscountField.hasError('required')">Monthly discount is required.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('minNum')">Monthly discount must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('maxNum')">Monthly discount cannot exceed 100.00 %.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="20" *ngIf="!this.isPercentDiscount">
                    <mat-label>Monthly Dollar</mat-label>
                    <input type="text"
                        [(ngModel)]="this.monthlyDiscount"
                        (change)="this.onMonthlyDiscountPriceChange()"
                        name="monthlyDiscountField"
                        #monthlyDiscountField="ngModel"
                        [disabled]="this.locked"
                        currencyInput
                        [minNum]="0"
                        [maxNum]="this.rentalDefinition.daily_rate"
                        required
                        matInput>
                    <mat-error *ngIf="monthlyDiscountField.hasError('required')">Monthly discount is required.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('minNum')">Monthly discount must be at least $0.00.</mat-error>
                    <mat-error *ngIf="monthlyDiscountField.hasError('maxNum')">Monthly discount cannot exceed price of rental.</mat-error>
                </mat-form-field>
                <div fxFlex fxFlexAlign="center">
                    <p class="form-instruction">
                        {{ this.calculateRate(this.rentalDefinition.monthly_discount, 1) | currencyFilter }} /day<br>
                        {{ this.calculateRate(this.rentalDefinition.monthly_discount, this.monthThreshold) | currencyFilter }} /month ({{ this.monthThreshold }} days)
                    </p>
                </div>
            </div>
        </div>

        <div class="fieldset" fxLayout="column">
            <div fxLayout="row">
                <h3 class="mat-subeading-2">Replacement Cost</h3>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex="33">
                    <mat-label>Retail Cost</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalDefinition.retail_cost"
                        name="replacementChargeField"
                        #replacementChargeField="ngModel"
                        currencyInput
                        [minNum]="0"
                        [disabled]="this.locked || this.rentalDefinition.variant_rates_enabled"
                        required
                        matInput>
                    <mat-error *ngIf="replacementChargeField.hasError('required')">Retail cost is required.</mat-error>
                    <mat-error *ngIf="replacementChargeField.hasError('minNum')">Retail cost must be at least $ 0.00.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="33">
                    <mat-label>Discount</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalDefinition.replacement_discount"
                        name="replacementDiscountField"
                        #replacementDiscountField="ngModel"
                        percentInput
                        [minNum]="0"
                        [maxNum]="1"
                        [disabled]="this.locked"
                        matInput>
                    <mat-error *ngIf="replacementDiscountField.hasError('minNum')">Discount must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="replacementDiscountField.hasError('maxNum')">Discount cannot exceed 100.00 %.</mat-error>
                </mat-form-field>

                <div class="form-static-txt" fxFlex="33">
                    <span class="form-static-txt__label">Total Replacement Cost</span>
                    <span class="form-static-txt__content">
                        {{ this.rentalDefinition.retail_cost * (1 - this.rentalDefinition.replacement_discount) | currencyFilter}}
                    </span>
                </div>
            </div>
            <div fxLayout="row" fxFlex>
                <mat-form-field fxFlex="33">
                    <mat-label>Rental Offset</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rentalDefinition.replacement_rental_offset"
                        name="replacementRentalOffsetField"
                        #replacementRentalOffsetField="ngModel"
                        [disabled]="this.locked"
                        percentInput
                        [minNum]="0"
                        [maxNum]="1"
                        matInput>
                    <mat-error *ngIf="replacementRentalOffsetField.hasError('minNum')">Rental offset must be at least 0.00 %.</mat-error>
                    <mat-error *ngIf="replacementRentalOffsetField.hasError('maxNum')">Rental offset cannot exceed 100.00 %.</mat-error>
                </mat-form-field>
                <div class="form-instruction" fxFlex="33">The rental offset uses a percentage of the total rental cost and subtracts it from the replacement cost.</div>
            </div>
        </div>

        <div fxLayout="row" *ngIf="!this.rentalDefinition.exists()">
            <div  fxLayout="column">
                <h3 class="mat-subeading-2">Variants can be enabled after Rental Definition is saved.</h3>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button (click)="this.onClose()">
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
