import { Component, Inject, Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpResponse } from "../network/http-response";

interface DialogWarningData {
  title?: string,
  textContent?: string,
  okText?: string,
  cancelText?: string
}
  
interface DialogErrorData {
  error: {
    message: string,
    status: number,
    code: string,
    debugMessage?: string,
    formattedError?: string
  }
}

interface DialogInfoData {
  title?: string,
  textContent?: string,
  okText?: string,
}

@Injectable({
    providedIn: 'root'
})
export class DialogNotificationService {
  constructor(private dialog: MatDialog) {}
  
  async confirm( data?: DialogWarningData ): Promise<boolean>
  {
    const defaults: DialogWarningData = {
      title: 'Confirm Action?',
      textContent: 'Are you sure you want to do this?',
    }
    
    return await this.warning( {...defaults, ...data} );
  }

  async recoverConfirm(): Promise<boolean>
  {
    return await this.warning({
      title: 'Recovering Record',
      textContent: 'You are about to recover an item, are you sure you wish to continue?'
    });
  }

  async unsavedChanges(): Promise<boolean>
  {
    return await this.warning({
      title: 'You have unsaved changes',
      textContent: 'If you proceed you will lose all unsaved changes, do you wish to continue?'
    });
  }

  async removeConfirm(): Promise<boolean>
  {
    return this.warning({
      title: 'Removing Item',
      textContent: 'You are about to remove an item, are you sure you wish to continue?'
    });
  }

  async discardConfirm(): Promise<boolean>
  {
    return await this.warning({
      title: 'Discarding Record',
      textContent: 'You are about to remove a record, are you sure you wish to continue?'
    });
  }

  async deleteConfirm(): Promise<boolean>
  {
    return await this.warning({
      title: 'Deleting Record',
      textContent: 'You are about to remove a record, are you sure you wish to continue?'
    });
  }

  async customWarning(title: string, textContent: string): Promise<boolean>
  {
    const data: DialogWarningData = {
      title: title,
      textContent: textContent,
    }

    return await this.warning(data);
  }

  async showNotice(title: string, textContent: string): Promise<boolean>
  {
    const data: DialogInfoData = {
      title: title,
      textContent: textContent,
    }

    return await this.notice(data);
  }

  async apiError(response: HttpResponse): Promise<boolean>
  {
    return await this.notification(
      DialogErrorNotificationComponent,
      {...response.error(), formattedError: JSON.stringify(response.error(), null, 2)}
    )
  }

  protected async warning(data: DialogWarningData): Promise<boolean>
  {
    const defaults: DialogWarningData = {
      okText: 'Yes',
      cancelText: 'Cancel'
    }

    return this.notification( 
      DialogWarningNotificationComponent, 
      {...defaults, ...data} 
    );
  }

  protected async notice(data: DialogInfoData): Promise<boolean>
  {
    const defaults: DialogInfoData = {
      okText: 'Ok',
    }

    return this.notification( 
      DialogNoticeNotificationComponent, 
      {...defaults, ...data} 
    );
  }

  protected async notification(DialogComponent, data: DialogWarningData | DialogErrorData ): Promise<boolean>
  {
    return this.dialog.open(DialogComponent, {...this.matDialogConfig(), data} )
      .afterClosed()
      .toPromise();
  }

  protected matDialogConfig(): MatDialogConfig
  {
    return {
      hasBackdrop: true,
      width: '100%',
      maxWidth: 800,
      disableClose: true
    } 
  }
}

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './dialog-warning-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss']
})
export class DialogWarningNotificationComponent {
  constructor( @Inject( MAT_DIALOG_DATA ) public data: DialogWarningData ) {}
}
  
@Component({
  selector: 'app-error-dialog',
  templateUrl: './dialog-error-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss']
})
export class DialogErrorNotificationComponent {
  viewDetails: boolean = false;  
  constructor( @Inject( MAT_DIALOG_DATA ) public data: DialogErrorData ) {}
}

@Component({
  selector: 'app-notice-dialog',
  templateUrl: './dialog-notice-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss']
})
export class DialogNoticeNotificationComponent {
  constructor( @Inject( MAT_DIALOG_DATA ) public data: DialogInfoData ) {}
}
