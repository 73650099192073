import { Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { DialogNotificationService } from './notification/dialog-notification.service';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesService {
  _unsavedChanges: () => any;

  constructor(
    private dialogNotifications: DialogNotificationService,
    private transitions: TransitionService,
    ) 
  {
    this.listen();
  }
  
  /**
   * Checks for unsaved changes on the current state
   */
  async unsavedChangesGuard(): Promise<boolean> 
  {
    if ( !!this._unsavedChanges && this._unsavedChanges() === true )
    {
      const shouldContinue: boolean = await this.dialogNotifications.unsavedChanges()

      if (! shouldContinue)
      {
        return false;
      }
    }

    this.clearDiscardCheck();
    return true;
  }

  /**
   * Call this function where the UIRouter singleton is created.  
   * Registers UIRouter transition hooks to listen in on the specified transitions.
   *  
   * @param {TransitionService} transitions 
   */
  listen(): void 
  { 
    this.transitions.onBefore({}, () => this.unsavedChangesGuard())
  }

  /**
   * Set the internal function reference used for checking if the current state has unsaved changes.
   * 
   * @param {Function} callback 
   */
  setDiscardCheck(callback: () => any): void 
  {
    this._unsavedChanges = callback;
  }

  /**
   * Reset the internal function reference used for checking if the current state has unsaved changes.
   */
  clearDiscardCheck(): boolean 
  {
    this._unsavedChanges = null;
    return true;
  }
}
