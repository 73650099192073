import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { DispatchCollection } from '@beaconlite/models/collections';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';
import * as moment from 'moment';
import { DispatchConstants } from '@beaconlite/models';

@Component({
  selector: 'app-upcoming-dispatches',
  templateUrl: './upcoming-dispatches.component.html',
  styleUrls: ['./upcoming-dispatches.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpcomingDispatchesComponent implements OnInit {
  @ViewChild('summaryContainer') summaryContainer: CdkVirtualScrollViewport;

  collection = new DispatchCollection();
  dispatchSource = new CollectionDataSource(this.collection);
  loading = false;
  hasMultipleDepartments = false;

  indexItemSize = 70;
  summaryItemSize = 236;

  start_time = moment().subtract(4, 'hour').unix();
  end_time =  moment().add(8, 'hour').unix();

  constructor(
    protected $state: StateService,
    protected appData: AppData,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.hasMultipleDepartments = (await this.appData.departments.getAll()).length > 1 ? true : false;
    await this.onSearch();
  }

  async onSearch(): Promise<void>
  {
    const params = {
      search_all: true,
      order: 'desc', 
      order_by: 'date',
      state: DispatchConstants.STATE_DISPATCHED,
      // TODO: Jira BL-566. Update this when API is updated. 
      with: ['employees', 'client', 'work_order', 'services', 'rental_requests'],
      date_start: this.start_time,
      date_end: this.end_time,
    }

    await this.collection.search(params);
  }
}
