import * as moment from 'moment';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {  NgForm, NgModel } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Branch, Client, Employee, ExternalId, TaxRate, WorkOrder } from '@beaconlite/models';
import { BranchCollection, ClientCollection, EmployeeCollection } from '@beaconlite/models/collections';
import { WorkOrderEditorData } from './work-order-editor-data.interface';

@Component({
  selector: 'app-work-order-editor',
  templateUrl: './work-order-editor.component.html',
})
export class WorkOrderEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('clientSearchTextField') clientSearchTextField: NgModel;
  @ViewChild('employeeSearchTextField') employeeSearchTextField: NgModel;

  onUpdate = this.data.onUpdate;

  original = this.data?.original; 
  saving = false;
  workOrder = new WorkOrder({ started_at: moment().startOf('day').unix() });
  taxes: TaxRate[] = [];
  branches: Branch[] = [];

  clientSearchText: string;
  promisedClients: Promise<Client[]>;
  employeeSearchText: string;
  promisedEmployees: Promise<Employee[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: WorkOrderEditorData,
    public dialogRef: MatDialogRef<WorkOrderEditorComponent>,
  ) {}

  async ngOnInit(): Promise<void>
  {
    [this.taxes, this.branches] = await Promise.all([
      await TaxRate.getAll(),
      await (new BranchCollection()).all()
    ]);

    if (!! this.original)
    {
      this.workOrder = this.original.copy();
      this.employeeSearchText = this.workOrder.employee?.fullname;
      this.clientSearchText = this.workOrder.client.name;
    }
    else
    {
      this.workOrder.branch_id = this.branches.find(branch => branch.default).id;
    }
  }

  async onClientSearchTextChange(): Promise<void>
  {
    switch (this.clientSearchText.length) {
      case 0: 
        this.workOrder.client = null;
        this.workOrder.client_id = null;
        this.promisedClients = null;
        break;
      case 1:
        this.promisedClients = null;
        break;
      default: 
        await this.queryClients();
    }
  }

  queryClients(): void
  {
    this.promisedClients = (new ClientCollection()).all({
      order: 'asc',
      name: this.clientSearchText,
    });
  }

  onClientSearchTextBlur(): void
  {
    if (this.clientSearchText !== this.workOrder.client?.name) {
      this.clientSearchText = this.workOrder.client?.name;
    }
  }

  onClientSelected(client: Client): void
  {
    this.workOrder.client_id = null;
    this.workOrder.client = null;

    if (!! client)
    {
      this.workOrder.client_id = client.id;
      this.workOrder.client = client;

      if (!! client.default_tax)
      {
        this.workOrder.taxes = client.default_tax;
      }
    }

    this._validateClientField();
  }

  private _validateClientField()
  {
    if (! this.workOrder.client) return;

    if (this.workOrder.client.suspended == true)
    {
      this.editorForm.controls.clientSearchTextField.setErrors({ suspended: true });
    }

    if (this.workOrder.client.discarded == true) 
    { 
      this.editorForm.controls.clientSearchTextField.setErrors({ discarded: true });
    }
  }

  displaySelectedClient = (client?: Client): string =>
  {
    // Before anything from the auto complete is selected, client is always null.
    // Use clientSearchText to populate input on load.
    return client?.name ?? this.clientSearchText;
  }

  async onEmployeeSearchTextChange(): Promise<void>
  {
    switch (this.employeeSearchText.length) {
      case 0: 
        this.workOrder.employee = null;
        this.workOrder.employee_id = null;
        this.promisedEmployees = null;
        break;
      case 1:
        this.promisedEmployees = null;
        break;
      default: 
        await this.queryEmployees();
    }
  }
  
  async queryEmployees(): Promise<void>
  {
    this.promisedEmployees = (new EmployeeCollection()).all({
      keyword: this.employeeSearchText,
      roles: ['administrator'],
      order:  'asc',
    });
  }

  onEmployeeSearchTextBlur(): void
  {
    if (this.employeeSearchText !== this.workOrder.employee?.fullname) {
      this.employeeSearchText = this.workOrder.employee?.fullname;
    }
  }

  displaySelectedEmployee = (employee?: Employee): string =>
  {
    // Before anything from the auto complete is selected, employee is always null.
    // Use employeeSearchText to populate input on load.
    return employee?.fullname ?? this.employeeSearchText;
  }

  onEmployeeSelected(employee: Employee): void
  {
    this.workOrder.employee = null;
    this.workOrder.employee_id = null;

    if (!! employee) {
      this.workOrder.employee = employee;
      this.workOrder.employee_id = employee.id;
    }
  }

  addExternalIdChip(event: MatChipInputEvent): void
  {
    const input = event.input;
 
    if ((event.value || '').trim())
    {
      const externalId = this.workOrder.addExternalId();
      externalId.value = event.value;
    }

    if (input)
    {
      input.value = ''
    }
  }

  removeExternalIdChip(externalId: ExternalId): void 
  {
    this.workOrder.removeExternalId(externalId);
  }

  async save(): Promise<void>
  {
    if (this.editorForm.invalid) { return; }

    this.saving = true;
    
    try
    {
      await this.onUpdate(this.workOrder);
      this.dialogRef.close();
    }
    finally
    {
      this.saving = false;
    }
  }
}
