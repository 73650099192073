<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
  <mat-toolbar>
    <h2 class="mat-title">{{ this.selectedClient ? 'Remove ' + this.selectedClient.name : 'Add Client' }}</h2>
  </mat-toolbar>
  <mat-dialog-content class="dialog-padding">
    <div fxLayout="column">
      <mat-form-field fxflex>
        <mat-label>Select Client</mat-label>
        <input type="text"
          [(ngModel)]="clientSearchText"
          name="clientSearchTextField"
          #clientSearchTextField="ngModel"
          [matAutocomplete]="clientAuto"
          (ngModelChange)="onQueryClients()"
          cdkFocusInitial
          minlength="2"
          required
          matInput
          [disabled]="this.original">
    
        <mat-autocomplete #clientAuto="matAutocomplete"
          (optionSelected)="onClientSelected($event.option.value)"
          [displayWith]="displaySelectedClient">
          <mat-option *ngFor="let client of promisedClients | async" [value]="client">
            {{ client.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="clientSearchTextField.hasError('required')">Client is required.</mat-error>
        <mat-error *ngIf="clientSearchTextField.hasError('suspended')">Client is suspended.</mat-error>
        <mat-error *ngIf="clientSearchTextField.hasError('discarded')">Client is discarded.</mat-error>
        <mat-error *ngIf="clientSearchTextField.hasError('alreadyPresent')">Client is already present in list of excluded clients.</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button type="button" mat-icon-button *ngIf="this.original">
      <mat-icon (click)="this.delete()" color="primary">delete</mat-icon>
  </button>

  <span fxFlex></span>
    <mat-progress-spinner *ngIf="this.isSaving" mode="indeterminate" diameter="36"></mat-progress-spinner>
    <button type="button" mat-raised-button mat-dialog-close>
        Cancel
    </button>
    <button type="submit" mat-raised-button color="accent" [disabled]="this.isSaving"  [fxHide]="!!this.original">
        Save
    </button>
</mat-dialog-actions>
</form>
