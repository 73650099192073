<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill *ngIf="!this.loaded">
    <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
</div>

<div class="print-wrapper" *ngIf="this.loaded" cdkScrollable>

<!-- Invoice -->
<div class="document">

    <!-- Preview -->
    <div class="document__warning" *ngIf="!this.invoice.exists()">
        <span class="document__warning-headline">PREVIEW</span>
    </div>
    <div class="document__warning" *ngIf="this.invoice.discarded_at">
        <span class="document__warning-headline">CANCELLED</span>
        <p>Date: {{ this.invoice.discarded_at | dateFilter:'dateMedium' }}</p>
        <p>User: {{ this.invoice.reason_discarded.account.name }}</p>
        <p>Reason: {{ this.invoice.reason_discarded.reason }}</p>
    </div>
    <div class="document__warning" *ngIf="this.invoice.voided && !this.invoice.discarded_at">
        <span class="document__warning-headline">VOIDED</span>
        <p>User: {{ this.invoice.reason_voided.account.name }}</p>
        <p>Reason: {{ this.invoice.reason_voided.reason }}</p>
    </div>

    <!-- Invoice Header -->
    <div class="mdc-layout-grid mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8">
            <div class="document__company-info mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell--span-6">
                    <div><strong>{{ this.invoice.branch.name }}</strong></div>
                    <div>{{ this.invoice.branch.address_1 }}</div>
                    <div *ngIf="this.invoice.branch.address_2">{{ this.invoice.branch.address_2 }}</div>
                    <div>{{ this.invoice.branch.city }}, {{ this.invoice.branch.region }}, {{ this.invoice.branch.postal_code }}</div>
                    <div *ngIf="this.invoice.branch.phone"><strong>Telephone:</strong> {{ this.invoice.branch.phone }}</div>
                    <div *ngIf="this.invoice.branch.toll_free"><strong>Toll Free:</strong> {{ this.invoice.branch.toll_free }}</div>
                    <div *ngIf="this.invoice.branch.fax"><strong>Fax:</strong> {{ this.invoice.branch.fax }}</div>
                    <div *ngIf="this.invoice.branch.email"><strong>Email:</strong> {{ this.invoice.branch.email }}</div>
                    <div *ngIf="this.invoice.branch.website"><strong>Website:</strong> {{ this.invoice.branch.website }}</div>
                </div>
                <div class="mdc-layout-grid__cell--span-6">
                    <img class="document__logo" src="/assets/img/{{ this.company.logo }}" alt="Logo">
                </div>
            </div>
            <div class="document__client-info document__client-info--mail-window mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                    <div class="document__subhead">{{ this.invoice.client.name }}</div>
                    <div>{{ this.invoice.client.address_1 }}</div>
                    <div>{{ this.invoice.client.address_2 }}</div>
                    <div>{{ this.invoice.client.city }}, {{ this.invoice.client.region }}, {{ this.invoice.client.country }}</div>
                    <div>{{ this.invoice.client.postal_code }}</div>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                    <div class="document-info__section" *ngIf="this.invoice.work_order.main_contact">
                        <div class="document__subhead">Main Contact</div>
                        <div>{{this.invoice.work_order.main_contact}}</div>
                    </div>
                    <div class="document-info__section" *ngIf="this.invoice.work_order.ordered_by">
                        <div class="document__subhead">Ordered By</div>
                        <div>{{this.invoice.work_order.ordered_by}}</div>
                    </div>
                    <!-- External IDs -->
                    <div class="document-info__section" *ngIf="this.invoice.external_ids.length">
                        <div class="document__subhead">Customer Reference</div>
                        <div *ngFor="let external_id of this.invoice.external_ids">{{ external_id.value }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="document__info mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
            <div class="document-info__section document-info__invoice-number">
                <div class="document-info__label">{{ this.invoice.displayType() }} #</div>
                <div class="document-info__value">{{ this.invoice.formatted_serial_id }}</div>
            </div>
            <div class="document-info__section document-info__wo-number">
                <div class="document-info__label">Work Order #</div>
                <div class="document-info__value">{{ this.getWorkOrderSerial() }}</div>
            </div>
            
            <div class="document-info__section invoice-info__dates">
                <div>Issued</div>
                <div>{{ this.invoice.issued_at | dateFilter:'dateMedium':'TBD' }}</div>
            </div>
            <div class="document-info__section invoice-info__period">
                <div>{{ this.invoice.displayType() }} Period</div>
                <div>{{ this.invoice.started_at | dateFilter:'dateMedium':'TBD' }}&nbsp;&mdash;&nbsp;{{ this.invoice.ended_at | dateFilter:'dateMedium':'TBD' }}</div>
            </div>
            <div class="document-info__section document-info__grand-total">
                <div class="document-info__label">Grand Total</div>
                <div class="document-info__value">{{ this.invoice.total | currencyFilter: 'parentheses' }}</div>
            </div>
            <!-- Tax Rates IDs -->
            <div *ngFor="let taxRate of this.invoice.tax_rates.rates">
            <strong>{{ taxRate.name }}</strong>&nbsp;<strong>{{ taxRate.identifier }}</strong>
            </div>
        </div>
    </div>

    <!-- Invoice Notes -->
    <div class="document__section" *ngIf="this.invoice.notes.length">
        <div class="document-section__header">
            <h2 class="document-section__title">Notes</h2>
        </div>
        <table class="bl-table">
            <tbody>
                <tr *ngFor="let note of this.invoice.notes">
                    <td class="bl-datetime">{{ note.noted_at | dateFilter:'dateTimeMedium' }}</td>
                    <td class="multiline">{{ note.content }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Invoiced Rental Requests -->
    <div class="document__section" *ngFor="let request of this.invoice.rental_requests">
        <div class="document-section__header">
            <div class="document-flex--20">
                <h2 class="document-section__title">Rental</h2>
                <div>{{ this.formatRentalSerialId(request.rental_request_serial_id) }}</div>
            </div>
            <div class="document-flex--20">
                <div class="document-section__detail-title">Rental Period</div>
                <div>{{ request.started_at | dateFilter:'dateShort' }}&nbsp;&mdash;&nbsp;{{ request.ended_at | dateFilter:'dateShort':'TBD' }}</div>
            </div>
            <div class="document-flex--20">
                <div class="document-section__detail-title">{{ this.invoice.displayType() }}d Period</div>
                <div>{{ request.period_started_at | dateFilter:'dateShort' }}&nbsp;&mdash;&nbsp;{{ request.period_ended_at | dateFilter:'dateShort' }}</div>
            </div>
            <div class="document-flex">
                <div>
                    <div class="document-section__detail-title">Delivery Location</div>
                    <div>{{ request.location }}</div>
                </div>
                <div *ngIf="request.ordered_by">
                    <div class="document-section__detail-title">Ordered By</div>
                    <div>{{ request.ordered_by }}</div>
                </div>
            </div>
        </div>

        <!-- Ongoing rentals -->
        <div *ngIf="request.rentals.length">
            <h3 class="document-section__subhead">Ongoing Rentals</h3>
            <table class="bl-table">
                <thead>
                <tr>
                    <th class="bl-item">Item</th>
                    <th class="bl-date">Period</th>
                    <th class="bl-numeric bl-numeric--small">Q</th>
                    <th class="bl-numeric">Rate</th>
                    <th class="bl-numeric">Total</th>
                </tr>
                </thead>
                <tbody>

                    <ng-container *ngIf="this.isFlattened; then flatRows; else groupedRows">
                    </ng-container>

                    <ng-template #flatRows>
                        <ng-container *ngFor="let requestItem of this.getFlattenedRentalsForRequest(request)">
                            <tr>
                                <td class="bl-item"><span class="uppercase">{{ requestItem.name }}</span><br>{{ requestItem.detail }}</td>
                                <td class="bl-date">{{ requestItem.period_days }} days</td>
                                <td class="bl-numeric bl-numeric--small">{{ requestItem.quantity_invoiced }}</td>
                                <td class="bl-numeric">{{ requestItem.applied_rate | currencyFilter }}<span *ngIf="!(requestItem.pricing_type == 'flat' && requestItem.applied_rate == 0)">/unit</span></td>
                                <td class="bl-numeric">{{ requestItem.rental_total | currencyFilter }}</td>
                            </tr>
                        </ng-container>
                    </ng-template>

                    <ng-template #groupedRows>
                        <ng-container *ngFor="let requestItem of request.request_items">

                            <ng-container *ngIf="requestItem.rentals?.length; then rentalGroupRow; else rentalRow">
                            </ng-container>
    
                            <ng-template #rentalGroupRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ requestItem.name }}</span></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                </tr>
    
                                <tr *ngFor="let rental of requestItem.rentals" class="grouped_rental">
                                    <td class="bl-item"><span class="uppercase">{{ rental.name }}</span><br>{{ rental.detail }}</td>
                                    <td class="bl-date">{{ rental.period_days }} days</td>
                                    <td class="bl-numeric bl-numeric--small">{{ rental.quantity_invoiced }}</td>
                                    <td class="bl-numeric">{{ rental.applied_rate | currencyFilter }}<span *ngIf="!(rental.pricing_type == 'flat' && rental.applied_rate == 0)">/unit</span></td>
                                    <td class="bl-numeric">{{ rental.rental_total | currencyFilter }}</td>
                                </tr>
                            </ng-template>
    
                            <ng-template #rentalRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ requestItem.name }}</span><br>{{ requestItem.detail }}</td>
                                    <td class="bl-date">{{ requestItem.period_days }} days</td>
                                    <td class="bl-numeric bl-numeric--small">{{ requestItem.quantity_invoiced }}</td>
                                    <td class="bl-numeric">{{ requestItem.applied_rate | currencyFilter }}<span *ngIf="!(requestItem.pricing_type == 'flat' && requestItem.applied_rate == 0)">/unit</span></td>
                                    <td class="bl-numeric">{{ requestItem.rental_total | currencyFilter }}</td>
                                </tr>
                            </ng-template>
    
                        </ng-container>
                    </ng-template>

                </tbody>
            </table>
        </div>  

        <!-- Normal returns -->
        <div *ngIf="this.requestHasNormalReturns(request)">
            <h3 class="document-section__subhead">Returns</h3>
            <table class="bl-table">
                <thead>
                <tr>
                    <th class="bl-item">Item</th>
                    <th class="bl-date--returns">Period</th>
                    <th class="bl-numeric bl-numeric--small">Q</th>
                    <th class="bl-numeric">Rate</th>
                    <th class="bl-numeric">Total</th>
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="this.isFlattened; then flatRows; else groupedRows">
                    </ng-container>

                    <ng-template #flatRows>
                        <ng-container *ngFor="let returnItem of this.getFlattenedReturnsForRequest(request)">
                            <tr *ngIf="!returnItem.lost"> 
                                <td class="bl-item"><span class="uppercase">{{ returnItem.name }}</span><br>{{ returnItem.detail }}</td>
                                <td class="bl-date--returns">{{ returnItem.period_days }} days ({{ returnItem.period_ended_at | dateFilter:'dateMedium' }})</td>
                                <td class="bl-numeric bl-numeric--small">{{ returnItem.quantity_returned }}</td>
                                <td class="bl-numeric">{{ returnItem.applied_rate | currencyFilter }}<span *ngIf="!(returnItem.pricing_type == 'flat' && returnItem.applied_rate == 0)">/unit</span></td>
                                <td class="bl-numeric">{{ returnItem.rental_total | currencyFilter }}</td>
                            </tr>
                        </ng-container>
                    </ng-template>

                    <ng-template #groupedRows>
                        <ng-container *ngFor="let returnItem of request.return_items">
                            <ng-container *ngIf="returnItem.returns?.length > 0; then rentalGroupRow; else rentalRow">
                            </ng-container>
                                
                            <ng-template #rentalGroupRow>
                                <ng-container *ngIf="this.checkIfGroupHasReturnItems(returnItem)">
                                    <tr>
                                        <td class="bl-item"><span class="uppercase">{{ returnItem.name }}</span></td>
                                        <td class="bl-numeric"></td>
                                        <td class="bl-numeric"></td>
                                        <td class="bl-numeric"></td>
                                        <td class="bl-numeric"></td>
                                    </tr>
        
                                    <tr *ngFor="let return of returnItem.returns" class="grouped_rental">
                                        <ng-container *ngIf="!return.lost">
                                            <td class="bl-item"><span class="uppercase">{{ return.name }}</span><br>{{ return.detail }}</td>
                                            <td class="bl-date--returns">{{ return.period_days }} days ({{ return.period_ended_at | dateFilter:'dateMedium' }})</td>
                                            <td class="bl-numeric bl-numeric--small">{{ return.quantity_returned }}</td>
                                            <td class="bl-numeric">{{ return.applied_rate | currencyFilter }}<span *ngIf="!(return.pricing_type == 'flat' && return.applied_rate == 0)">/unit</span></td>
                                            <td class="bl-numeric">{{ return.rental_total | currencyFilter }}</td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </ng-template>
    
                            <ng-template #rentalRow>
                                <tr *ngIf="!returnItem.lost"> 
                                    <td class="bl-item"><span class="uppercase">{{ returnItem.name }}</span><br>{{ returnItem.detail }}</td>
                                    <td class="bl-date--returns">{{ returnItem.period_days }} days ({{ returnItem.period_ended_at | dateFilter:'dateMedium' }})</td>
                                    <td class="bl-numeric bl-numeric--small">{{ returnItem.quantity_returned }}</td>
                                    <td class="bl-numeric">{{ returnItem.applied_rate | currencyFilter }}<span *ngIf="!(returnItem.pricing_type == 'flat' && returnItem.applied_rate == 0)">/unit</span></td>
                                    <td class="bl-numeric">{{ returnItem.rental_total | currencyFilter }}</td>
                                </tr>
                            </ng-template>
    
                        </ng-container>
                    </ng-template>
                    
                </tbody>
            </table>
        </div>

        <!-- Lost Items -->
        <div *ngIf="request.lost_items.length">
            <h3 class="document-section__subhead">Lost Items</h3>
            <table class="bl-table">
                <thead>
                <tr>
                    <th class="bl-item">Item</th>
                    <th class="bl-date--returns">Period</th>
                    <th class="bl-numeric bl-numeric--small">Q</th>
                    <th class="bl-numeric">Rate</th>
                    <th class="bl-numeric">Subtotal</th>
                    <th class="bl-numeric">Replacement Rate</th>
                    <th class="bl-numeric">Total</th>
                </tr>
                </thead>
                <tbody>

                    <ng-container *ngIf="this.isFlattened; then flatRows; else groupedRows">
                    </ng-container>

                    <ng-template #flatRows>
                        <ng-container *ngFor="let lostItem of this.getFlattenedReturnsForRequest(request)">
                            <tr *ngIf="lostItem.lost">
                                <td class="bl-item"><span class="uppercase">{{ lostItem.name }}</span><br>{{ lostItem.detail }}</td>
                                <td class="bl-date--returns">{{ lostItem.period_days }} days ({{ lostItem.period_ended_at | dateFilter:'dateMedium' }})</td>
                                <td class="bl-numeric bl-numeric--small">{{ lostItem.quantity_returned }}</td>
                                <td class="bl-numeric">{{ lostItem.applied_rate | currencyFilter }}<span *ngIf="!(lostItem.pricing_type == 'flat' && lostItem.applied_rate == 0)">/unit</span></td>
                                <td class="bl-numeric">{{ lostItem.rental_total | currencyFilter }}</td>
                                <td class="bl-numeric">{{ lostItem.replacement_charge | currencyFilter }}/unit</td>
                                <td class="bl-numeric">{{ lostItem.total | currencyFilter }}</td>
                            </tr>
                        </ng-container>
                    </ng-template>

                    <ng-template #groupedRows>
                        <ng-container *ngFor="let lostItem of request.lost_items">
                            <ng-container *ngIf="lostItem.lost_returns?.length > 0; then rentalGroupRow; else rentalRow">
                            </ng-container>
                                
                            <ng-template #rentalGroupRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ lostItem.name }}</span></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                </tr>
    
                                <tr *ngFor="let return of lostItem.lost_returns" class="grouped_rental">
                                    <td class="bl-item"><span class="uppercase">{{ return.name }}</span><br>{{ return.detail }}</td>
                                    <td class="bl-date--returns">{{ return.period_days }} days ({{ return.period_ended_at | dateFilter:'dateMedium' }})</td>
                                    <td class="bl-numeric bl-numeric--small">{{ return.quantity_returned }}</td>
                                    <td class="bl-numeric">{{ return.applied_rate | currencyFilter }}<span *ngIf="!(return.pricing_type == 'flat' && return.applied_rate == 0)">/unit</span></td>
                                    <td class="bl-numeric">{{ return.rental_total | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ return.replacement_charge | currencyFilter }}/unit</td>
                                    <td class="bl-numeric">{{ return.total | currencyFilter }}</td>
                                </tr>
                            </ng-template>
    
                            <ng-template #rentalRow>
                                <tr> 
                                    <td class="bl-item"><span class="uppercase">{{ lostItem.name }}</span><br>{{ lostItem.detail }}</td>
                                    <td class="bl-date--returns">{{ lostItem.period_days }} days ({{ lostItem.period_ended_at | dateFilter:'dateMedium' }})</td>
                                    <td class="bl-numeric bl-numeric--small">{{ lostItem.quantity_returned }}</td>
                                    <td class="bl-numeric">{{ lostItem.applied_rate | currencyFilter }}<span *ngIf="!(lostItem.pricing_type == 'flat' && lostItem.applied_rate == 0)">/unit</span></td>
                                    <td class="bl-numeric">{{ lostItem.rental_total | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ lostItem.replacement_charge | currencyFilter }}/unit</td>
                                    <td class="bl-numeric">{{ lostItem.total | currencyFilter }}</td>
                                </tr>
                            </ng-template>
    
                        </ng-container>
                    </ng-template>
                </tbody>
            </table>
        </div>

        <!-- Rate Adjusments -->
        <div *ngIf="request.rate_adjusted_rentals.length || request.rate_adjusted_returns.length">
            <h3 class="document-section__subhead">Rate Adjustments</h3>
            <table class="bl-table">
                <thead>
                    <tr>
                        <th class="bl-item">Item</th>
                        <th>Description</th>
                        <th class="bl-numeric">Expected To Date</th>
                        <th class="bl-numeric">{{ this.invoice.displayType() }}d to Date</th>
                        <th class="bl-numeric">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="this.isFlattened; then flatRows; else groupedRows">
                    </ng-container>

                    <ng-template #flatRows>
                        <ng-container *ngFor="let flattenedRateAdjustment of this.getFlattenedRateAdjustments(request)">
                            <tr>
                                <td class="bl-item"><span class="uppercase">{{ flattenedRateAdjustment.name }}</span></td>
                                <td class="multiline">{{ flattenedRateAdjustment.description }}</td>
                                <td class="bl-numeric">{{ flattenedRateAdjustment.expected_amount | currencyFilter }}</td>
                                <td class="bl-numeric">{{ flattenedRateAdjustment.previous_amount + flattenedRateAdjustment.rental_total | currencyFilter }}</td>
                                <td class="bl-numeric">{{ flattenedRateAdjustment.amount | currencyFilter: 'parentheses' }}</td>
                            </tr>
                        </ng-container>
                    </ng-template>

                    <ng-template #groupedRows>
                        <ng-container *ngFor="let rate_adjusted_item of request.rate_adjusted_rentals">
                            <ng-container *ngIf="rate_adjusted_item.rate_adjusted_rentals?.length; then rentalGroupRow; else rentalRow">
                            </ng-container>

                            <ng-template #rentalGroupRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ rate_adjusted_item.name }}</span></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                </tr>

                                <tr *ngFor="let rental of rate_adjusted_item.rate_adjusted_rentals" class="grouped_rental">
                                    <td class="bl-item"><span class="uppercase">{{ rental.name }}</span></td>
                                    <td class="multiline">{{ rental.rate_adjustment?.description }}</td>
                                    <td class="bl-numeric">{{ rental.rate_adjustment.expected_amount | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ rental.rate_adjustment.previous_amount + rental.rental_total | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ rental.rate_adjustment.amount | currencyFilter: 'parentheses' }}</td>
                                </tr>
                            </ng-template>

                            <ng-template #rentalRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ rate_adjusted_item.name }}</span></td>
                                    <td class="multiline">{{ rate_adjusted_item.rate_adjustment?.description }}</td>
                                    <td class="bl-numeric">{{ rate_adjusted_item.rate_adjustment.expected_amount | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ rate_adjusted_item.rate_adjustment.previous_amount + rate_adjusted_item.rental_total | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ rate_adjusted_item.rate_adjustment.amount | currencyFilter: 'parentheses' }}</td>
                                </tr>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngFor="let rate_adjusted_item of request.rate_adjusted_returns">

                            <ng-container *ngIf="rate_adjusted_item.rate_adjusted_returns?.length; then rentalGroupRow; else rentalRow">
                            </ng-container>

                            <ng-template #rentalGroupRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ rate_adjusted_item.name }}</span></td>
                                    <td class="multiline"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                </tr>

                                <tr *ngFor="let return of rate_adjusted_item.rate_adjusted_returns" class="grouped_rental">
                                    <td class="bl-item"><span class="uppercase">{{ return.name }}</span></td>
                                    <td class="multiline">{{ return.rate_adjustment?.description }}</td>
                                    <td class="bl-numeric">{{ return.rate_adjustment.expected_amount | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ return.rate_adjustment.previous_amount + return.rental_total | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ return.rate_adjustment.amount | currencyFilter: 'parentheses' }}</td>
                                </tr>
                            </ng-template>

                            <ng-template #rentalRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ rate_adjusted_item.name }}</span></td>
                                    <td class="multiline">{{ rate_adjusted_item.rate_adjustment?.description }}</td>
                                    <td class="bl-numeric">{{ rate_adjusted_item.rate_adjustment.expected_amount | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ rate_adjusted_item.rate_adjustment.previous_amount + rate_adjusted_item.rental_total | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ rate_adjusted_item.rate_adjustment.amount | currencyFilter: 'parentheses' }}</td>
                                </tr>
                            </ng-template>

                        </ng-container>
                    </ng-template>
                </tbody>
            </table>
        </div>

        <!-- Minimum Adjustments -->
        <div *ngIf="request.minimum_adjusted_returns.length || request.minimum_adjustment">
            <h3 class="document-section__subhead">Minimum Adjustments</h3>
            <table class="bl-table">
                <thead>
                <tr>
                    <th class="bl-item">Item</th>
                    <th class="bl-numeric">Minimum</th>
                    <th class="bl-numeric bl-numeric--small">Q</th>
                    <th class="bl-numeric">{{ this.invoice.displayType() }}d to Date</th>
                    <th class="bl-numeric">Total</th>
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="this.isFlattened; then flatRows; else groupedRows">
                    </ng-container>

                    <ng-template #flatRows>
                        <ng-container *ngFor="let flattenedMinimumAdjustment of this.getFlattenedMinimumAdjustments(request)">
                            <tr>
                                <td class="bl-item"><span class="uppercase">{{ flattenedMinimumAdjustment.name }}</span></td>
                                <td class="bl-numeric">{{ flattenedMinimumAdjustment.minimum_charge | currencyFilter }}/unit</td>
                                <td class="bl-numeric bl-numeric--small">{{ flattenedMinimumAdjustment.quantity_returned }}</td>
                                <td class="bl-numeric">{{ flattenedMinimumAdjustment.previous_amount + flattenedMinimumAdjustment.rental_total | currencyFilter }}</td>
                                <td class="bl-numeric">{{ flattenedMinimumAdjustment.amount | currencyFilter }}</td>
                            </tr>
                        </ng-container>
                    </ng-template>

                    <ng-template #groupedRows>
                        <ng-container *ngFor="let minimum_adjusted_return of request.minimum_adjusted_returns">

                            <ng-container *ngIf="minimum_adjusted_return.minimum_adjusted_returns?.length; then rentalGroupRow; else rentalRow">
                            </ng-container>

                            <ng-template #rentalGroupRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ minimum_adjusted_return.name }}</span></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                    <td class="bl-numeric"></td>
                                </tr>

                                <tr *ngFor="let return of minimum_adjusted_return.minimum_adjusted_returns" class="grouped_rental">
                                    <td class="bl-item"><span class="uppercase">{{ return.name }}</span></td>
                                    <td class="bl-numeric">{{ return.minimum_charge | currencyFilter }}</td>
                                    <td class="bl-numeric bl-numeric--small">{{ return.quantity_returned }}</td>
                                    <td class="bl-numeric">{{ return.minimum_adjustment.previous_amount + (return.rental_total / return.quantity_returned) | currencyFilter }}/unit</td>
                                    <td class="bl-numeric">{{ return.minimum_adjustment.amount | currencyFilter }}</td>
                                </tr>
                            </ng-template>

                            <ng-template #rentalRow>
                                <tr>
                                    <td class="bl-item"><span class="uppercase">{{ minimum_adjusted_return.name }}</span></td>
                                    <td class="bl-numeric">{{ minimum_adjusted_return.minimum_charge | currencyFilter }}/unit</td>
                                    <td class="bl-numeric bl-numeric--small">{{ minimum_adjusted_return.quantity_returned }}</td>
                                    <td class="bl-numeric">{{ minimum_adjusted_return.minimum_adjustment.previous_amount + minimum_adjusted_return.rental_total | currencyFilter }}</td>
                                    <td class="bl-numeric">{{ minimum_adjusted_return.minimum_adjustment.amount | currencyFilter }}</td>
                                </tr>
                            </ng-template>

                        </ng-container>
                    </ng-template>

                    <tr *ngIf="request.minimum_adjustment">
                        <td><span class="uppercase">Request Minimum</span></td>
                        <td class="bl-numeric">{{ request.request_minimum | currencyFilter }}</td>
                        <td class="bl-numeric bl-numeric--small">-</td>
                        <td class="bl-numeric">{{ request.minimum_adjustment?.previous_amount | currencyFilter }}</td>
                        <td class="bl-numeric">{{ request.minimum_adjustment?.amount | currencyFilter }}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>

        <!-- Rental Charges -->
        <div *ngIf="request.charges.length">
            <h3 class="document-section__subhead">Charges</h3>
            <table class="bl-table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Label</th>
                        <th>Description</th>
                        <th class="bl-numeric">Value</th>
                        <th class="bl-numeric">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let charge of request.charges">
                        <td><span class="uppercase">{{ charge.discount ? 'Discount' : 'Charge' }}</span></td>
                        <td>{{ charge.label }}</td>
                        <td class="multiline">{{ charge.description }}</td>
                        <td class="bl-numeric">{{ charge.percent ? (charge.percent_value | percentFilter) : (charge.flat_value | currencyFilter: 'parentheses') }}</td>
                        <td class="bl-numeric">{{ charge.total | currencyFilter:'parentheses' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Rental Notes -->
        <div *ngIf="request.notes.length">
            <h3 class="document-section__subhead">Notes</h3>
            <table class="bl-table">
                <tbody>
                    <tr *ngFor="let note of request.notes">
                        <td class="bl-datetime">{{ note.noted_at | dateFilter:'dateTimeMedium' }}</td>
                        <td class="multiline">{{ note.content }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="document-section__totals mdc-layout-grid mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-6"></div>
            <div class="mdc-layout-grid__cell--span-1">
                <span>Subtotal:</span>
            </div>
            <div class="mdc-layout-grid__cell--span-1">
                <span><strong>{{ request.total | currencyFilter: 'parentheses' }}</strong></span>
            </div>
        </div>
    </div>

    <!-- Invoiced Services -->
    <div class="document__section" *ngFor="let service of this.invoice.services">
        <div class="document-section__header">
            <div class="document-flex--60">
                <h2 class="document-section__title uppercase">{{ service.name }}</h2>
                <div>{{ this.formatServiceSerialId(service.service_serial_id) }}</div>
            </div>
            <div class="document-flex">
                <div>
                    <div class="document-section__detail-title">Location</div>
                    <div>{{ service.location }}</div>
                </div>
                <div *ngIf="service.ordered_by">
                    <div class="document-section__detail-title">Ordered By</div>
                    <div>{{ service.ordered_by }}</div>
                </div>
            </div>
        </div>

        <!-- Service -->
        <table class="bl-table">
            <thead>
                <tr>
                    <th class="bl-item">Name</th>
                    <th class="bl-date">Period</th>
                    <th class="bl-numeric bl-numeric--small">Q</th>
                    <th class="bl-numeric" *ngIf="service.pricing_type != 'unit'">Time</th>
                    <th class="bl-numeric" *ngIf="service.pricing_type != 'unit'">Overtime</th>
                    <th class="bl-numeric" *ngIf="service.pricing_type != 'unit'">Travel</th>
                    <th class="bl-numeric">Total</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td><span class="bl-item uppercase">{{ service.name }}</span><br>{{ service.detail }}</td>
                    <td class="bl-date">{{ service.started_at | dateFilter:'dateTimeShort' }}<br> 
                        {{ service.ended_at | dateFilter:'dateTimeShort' }}</td>
                    <td class="bl-numeric bl-numeric--small">{{ service.quantity }}<br>
                        <span *ngIf="service.pricing_type == 'unit'">@ {{ service.regular_rate_override | currencyFilter }}</span></td>
                    <td class="bl-numeric" *ngIf="service.pricing_type != 'unit'">{{ service.regular_time | timeFilter:'human' }}<br>
                        <span *ngIf="service.pricing_type == 'auto'">@ {{ service.regular_rate_override | currencyFilter }}</span></td>

                    <td class="bl-numeric" *ngIf="service.pricing_type != 'unit'">{{ service.over_time | timeFilter:'human' }}<br>
                        <span *ngIf="service.pricing_type == 'auto'">@ {{ service.overtime_rate_applied ? (service.overtime_rate_override | currencyFilter) : (service.regular_rate_override | currencyFilter) }}</span></td>
                        
                    <td class="bl-numeric" *ngIf="service.pricing_type != 'unit'">{{ service.travel_time | timeFilter:'human' }}<br>
                        <span *ngIf="service.pricing_type == 'auto'">@ {{ service.regular_rate_override | currencyFilter }}</span></td>
                    <td class="bl-numeric">{{ service.estimated_total | currencyFilter }}</td>
                </tr>
            </tbody>
        </table>

        <!-- Service Charges -->
        <div *ngIf="service.charges.length">
            <h3 class="document-section__subhead">Charges</h3>
            <table class="bl-table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Label</th>
                        <th>Description</th>
                        <th class="bl-numeric">Value</th>
                        <th class="bl-numeric">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let charge of service.charges">
                        <td><span class="uppercase">{{ charge.discount ? 'Discount' : 'Charge' }}</span></td>
                        <td>{{ charge.label }}</td>
                        <td class="multiline">{{ charge.description }}</td>
                        <td class="bl-numeric">{{ charge.percent ? (charge.percent_value | percentFilter) : (charge.flat_value | currencyFilter: 'parentheses') }}</td>
                        <td class="bl-numeric">{{ charge.total | currencyFilter: 'parentheses' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Service Notes -->
        <div *ngIf="service.notes.length">
            <h3 class="document-section__subhead">Notes</h3>
            <table class="bl-table">
                <tbody>
                    <tr *ngFor="let note of service.notes">
                        <td class="bl-datetime">{{ note.noted_at | dateFilter:'dateTimeMedium' }}</td>
                        <td class="multiline">{{ note.content }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="document-section__totals mdc-layout-grid mdc-layout-grid__inner" *ngIf="service.charges.length">
            <div class="mdc-layout-grid__cell--span-6"></div>
            <div class="mdc-layout-grid__cell--span-1">
                <span>Subtotal:</span>
            </div>
            <div class="mdc-layout-grid__cell--span-1">
                <span><strong>{{ service.total | currencyFilter: 'parentheses' }}</strong></span>
            </div>
        </div>
    </div>

    <!-- Invoiced Line Item Requests -->
    <div class="document__section" *ngFor="let request of this.invoice.line_item_requests">
        <div class="document-section__header">
            <div class="document-flex--20">
                <h2 class="document-section__title">Sale Order</h2>
                <div>{{ this.formatLineItemRequestSerialId(request.line_item_request_serial_id) }}</div>
            </div>
            <div class="document-flex--20">
                <div class="document-section__detail-title">Date Ordered</div>
                <div>{{ request.started_at | dateFilter:'dateShort' }}</div>
            </div>
            <div class="document-flex">
                <div *ngIf="request.ordered_by">
                    <div class="document-section__detail-title">Ordered By</div>
                    <div>{{ request.ordered_by }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="request.line_items.length">
            <h3 class="document-section__subhead">Sale Orders</h3>
            <table class="bl-table">
                <thead>
                <tr>
                    <th class="bl-item">Item</th>
                    <th class="bl-numeric bl-numeric--small">Q</th>
                    <th class="bl-numeric">Rate</th>
                    <th class="bl-numeric">Total</th>
                </tr>
                </thead>
                <tbody>

                <ng-container *ngFor="let requestItem of request.line_items">
                    <tr>
                        <td class="bl-item"><span class="uppercase">{{ requestItem.name }}</span><br>{{ requestItem.detail }}</td>
                        <td class="bl-numeric bl-numeric--small">{{ requestItem.quantity }}</td>
                        <td class="bl-numeric">{{ requestItem.rate_override | currencyFilter }}/unit</td>
                        <td class="bl-numeric">{{ requestItem.total | currencyFilter }}</td>
                    </tr>
                </ng-container>
                    

                </tbody>
            </table>
        </div>  

        <div class="document-section__totals mdc-layout-grid mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-6"></div>
            <div class="mdc-layout-grid__cell--span-1">
                <span>Subtotal:</span>
            </div>
            <div class="mdc-layout-grid__cell--span-1">
                <span><strong>{{ request.total | currencyFilter: 'parentheses' }}</strong></span>
            </div>
        </div>
    </div>

    <!-- Invoiced Charges -->
    <div class="document__section" *ngIf="this.invoice.charges.length || this.invoice.flattenDispatchCharges().length">
        <div class="document-section__header">
            <h2 class="document-section__title">Charges</h2>
        </div>
        <table class="bl-table">
            <thead>
            <tr>
                <th>Type</th>
                <th>Label</th>
                <th>Description</th>
                <th class="bl-numeric">Value</th>
                <th class="bl-numeric">Total</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let charge of this.invoice.flattenDispatchCharges()">
                    <td><span class="uppercase">{{ charge.discount ? 'Discount' : 'Charge' }}</span></td>
                    <td>{{ charge.label }}</td>
                    <td class="multiline">{{ charge.description }}</td>
                    <td class="bl-numeric">{{ charge.percent ? (charge.percent_value | percentFilter) : (charge.flat_value | currencyFilter: 'parentheses') }}</td>
                    <td class="bl-numeric">{{ charge.total | currencyFilter: 'parentheses' }}</td>
                </tr>
                <tr *ngFor="let charge of this.invoice.charges">
                    <td><span class="uppercase">{{ charge.discount ? 'Discount' : 'Charge' }}</span></td>
                    <td>{{ charge.label }}</td>
                    <td class="multiline">{{ charge.description }}</td>
                    <td class="bl-numeric">{{ charge.percent ? (charge.percent_value | percentFilter) : (charge.flat_value | currencyFilter: 'parentheses') }}</td>
                    <td class="bl-numeric">{{ charge.total | currencyFilter: 'parentheses' }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Invoice Grand Total -->
    <div class="document__section document-section__totals mdc-layout-grid mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-6"></div>
        <div class="mdc-layout-grid__cell--span-2">
            <div class="document-section__header"></div>
        </div>
        <div class="mdc-layout-grid__cell--span-6"></div>
        <div class="mdc-layout-grid__cell--span-1">Subtotal:</div>
        <div class="mdc-layout-grid__cell--span-1">
            <strong>{{ this.invoice ? (this.invoice.subtotal | currencyFilter: 'parentheses') : 'TBD' }}</strong>
        </div>
        <ng-container *ngFor="let taxRate of this.invoice.tax_rates.rates">
            <div class="mdc-layout-grid__cell--span-6"></div>
            <div class="mdc-layout-grid__cell--span-1">{{ taxRate.name }}:</div>    
            <div class="mdc-layout-grid__cell--span-1">
                <strong>{{ this.invoice ? ((this.invoice.subtotal * taxRate.value ) | currencyFilter: 'parentheses') : 'TBD' }}</strong>
            </div>
        </ng-container>
        <div class="mdc-layout-grid__cell--span-6"></div>
        <div class="mdc-layout-grid__cell--span-1">Grand Total:</div>
        <div class="mdc-layout-grid__cell--span-1">
            <strong>{{ this.invoice ? (this.invoice.total | currencyFilter: 'parentheses') : 'TBD' }}</strong>
        </div>
    </div>
    <div class="document__section mdc-layout-grid mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-12">
            Net 30 days.<br>Please report any items which do not agree with your records within 30 days of the statement date.
        </div>
    </div>

</div>

</div>