import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Department, Employee } from '@beaconlite/models';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-employee-single',
  templateUrl: './employee-single.component.html',
  styleUrls: ['./employee-single.component.scss']
})
export class EmployeeScheduleSingleComponent implements OnInit, OnDestroy {
  
  @Input() modelId: string;

  loading = false;
  title = 'Employee';
  employee = new Employee();
  departments: Department[] = [];

  constructor(
    private stateService: StateService,
    protected appData: AppData,
  ) { }
 
  async ngOnInit(): Promise<void> 
  {
    this.loading = true;

    try
    {
      this.employee = await Employee.get(this.modelId);
      this.departments = await this.appData.departments.getAll();

      this.title = this.employee.fullname;     
    }
    finally
    {
      this.loading = false;
    }
  }

  ngOnDestroy(): void 
  {
    // this.lockVault.revokeLock(this.workOrder);
  }

  onEmployeeUpdate = async (): Promise<void> =>
  {
    this.employee = await Employee.get(this.modelId);
  }

  onEmployeeRemove = () =>
  {
    this.stateService.go('protected.employee-schedule')
  }

  onCancel(): void
  {
    this.stateService.go('protected.employee-schedule');
  }
}
