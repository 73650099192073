import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ChargeEditorService } from '@beaconlite/components/editors/charge-editor/charge-editor.service';
import { NoteEditorService } from '@beaconlite/components/editors/note-editor/note-editor.service';
import { RentalRequestEditorService } from '../rental-request-editor/rental-request-editor.service';
import { ReturnSheetEditorService } from '../return-sheet-editor/return-sheet-editor.service';
import { Charge, Note, Rental, RentalGroup, RentalRequest, RentalReturn, ReturnRowItem, WorkOrder } from '@beaconlite/models';
import { DateFilterPipe } from '@beaconlite/pipes/date-filter.pipe';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { RentalEditorService } from '../rental-editor/rental-editor.service';
import * as moment from 'moment';
import { RequestItem } from '@beaconlite/models/contracts/RequestItem.interface';
import { ReturnItem } from '@beaconlite/models/contracts/ReturnItem.interface';
import FlattenedRentals from '@beaconlite/models/contracts/FlattenedRentals.interface';
import { RentalFlattenBuilder } from '@beaconlite/services/RentalFlattenBuilder.service';

@Component({
  selector: 'rental-request-component',
  templateUrl: './rental-request.component.html',
  styleUrls: ['./rental-request.component.scss']
})
export class RentalRequestComponent implements OnInit, DoCheck {
  @ViewChild('requestItemTable') requestItemTable: MatTable<RequestItem[]>;

  @Input() workOrder: WorkOrder;
  @Input() request: RentalRequest;
  @Input() shouldShowDepartment: boolean;
  @Input() onUpdate: (request: RentalRequest) => Promise<any>;
  @Input() onRemove: (request: RentalRequest) => Promise<any>; 

  returnLineItems: ReturnRowItem[] = [];
  minimumDate: Date = null;
  isFlattenedView = false;
  flattenedRentals: FlattenedRentals = new Map();

  // Tables
  requestItemTableColumns = ['drag', 'item', 'detail', 'quantity', 'remaining', 'pricing', 'rate'];
  flatRequestItemTableColumns = ['item', 'detail', 'quantity', 'remaining', 'pricing', 'rate'];
  groupItemTableColumns = ['item', 'detail', 'quantity', 'remaining', 'pricing', 'rate'];
  returnTableColumns = ['error', 'date', 'returned', 'lost'];

  constructor(
    protected dateFilter: DateFilterPipe,
    protected chargeEditor: ChargeEditorService,
    protected requestItemEditor: RentalEditorService,
    protected returnSheetEditor: ReturnSheetEditorService,
    protected rentalRequestEditor: RentalRequestEditorService,
    protected noteEditor: NoteEditorService,
    protected dialogNotifications: DialogNotificationService,
    protected rentalFlattenBuilder: RentalFlattenBuilder,
  ) { }

  ngOnInit(): void 
  {
    this._sortItems();
    this.setRequestFlattenedRentals();
    this.generateSheets(this.request.return_items);
  }

  setRequestFlattenedRentals() {
    this.flattenedRentals = this.rentalFlattenBuilder.computeFlattenedRentals(this.request);
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  onFlattenedViewToggledHandler($event: boolean): void {
    this.isFlattenedView = $event;
  }

  generateSheets(items: ReturnItem[], map:Map<number, ReturnRowItem> = null) {
    if (map === null) {
      map = new Map<number, ReturnRowItem>();
    }

    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      let returnedAt = null;
      let invalid = false;

      if (this.isItemGroup(item)) {
        let groupReturns: RentalReturn[] = [];

        for (let j = 0; j < item.rentals.length; j += 1) {
          groupReturns.splice(groupReturns.length, 0, ...item.rentals[j].returns);
        }

        this.generateSheets(groupReturns, map);
        continue;
      } else {
        returnedAt = (item as RentalReturn).returned_at;
        invalid = (item as RentalReturn).invalid;
      }

      if (! map.has(returnedAt)) {
        const sheet = new ReturnRowItem([], returnedAt, false);

        map.set(returnedAt, sheet );
        this.returnLineItems.push(sheet);
      }

      const sheet = map.get(returnedAt);

      sheet.returns.push(item as RentalReturn);
      sheet.invalid = sheet.invalid || invalid;
    }

    this.returnLineItems.sort(orderBy('returned_at'));
  }

  async onEdit(): Promise<boolean>
  {
    return this.rentalRequestEditor.open({
      workOrder: this.workOrder,
      original: this.request,
      onRequestUpdate: this.onRequestUpdate,
      onRequestRemove: this.onRequestRemove,
    });
  }

  onRequestUpdate = async (updatedRequest: RentalRequest): Promise<void> =>
  {
    this.request.map(updatedRequest.flush());
    await this.onUpdate(updatedRequest);
  }

  onRequestRemove = async (): Promise<void> =>
  {
    if (! await this.dialogNotifications.removeConfirm()) return;
    await this.onRemove(this.request);
  }
  
  async onRequestItemDropped(event: CdkDragDrop<RequestItem[]>): Promise<void>
  {
    moveItemInArray(this.request.request_items, event.previousIndex, event.currentIndex);
    this.request.request_items.forEach( (element, index) => {
      element.position = index;
    });       

    this.requestItemTable.renderRows();
    await this.onUpdate(this.request);
  }

  onDuplicate(): Promise<boolean>
  {
    const onDuplicateRequestUpdate = async (newRequest: RentalRequest) => {
      await this.onUpdate(newRequest);
    }

    return this.rentalRequestEditor.open({
      workOrder: this.workOrder,
      original: this.request.duplicate(),
      onRequestUpdate: onDuplicateRequestUpdate,
      onRequestRemove: null,
    });
  }

  canAddRequestItem(request: RentalRequest) {
    return request.canAddRequestItems() && !this.isFlattenedView;
  }

  onAddRequestItem()
  {
    return this.onEditRequestItem();
  }

  async onEditRequestItem(requestItem: RequestItem = null): Promise<boolean>
  {
    if (this.isFlattenedView) {
      return await this.dialogNotifications.showNotice("", "Cannot edit Rentals while in Flattened View");
    }

    const onRequestItemUpdate = async (updatedRequestItem: RequestItem) => {

      if (updatedRequestItem?.exists())
      {
        // RentalRequest maintains a reference to this item, so can just change here.
        requestItem.mapAttributes(updatedRequestItem.flushAttributes());

        if (!!updatedRequestItem?.rentals)
        {
          requestItem.rentals = updatedRequestItem.rentals.map(rental => {
            let updatedRental = new Rental();
            updatedRental.mapAttributes(rental.flushAttributes());
            return updatedRental;
          });
        }
      }
      else
      {
        updatedRequestItem.position = this.request.request_items.length;

        if (this.isItemGroup(updatedRequestItem))
        {
          this.request.addRentalGroup(updatedRequestItem as RentalGroup);
        }
        else
        {
          this.request.addRental(updatedRequestItem as Rental);
        }
      }

      await this.onUpdate(this.request);
    }

    const onRequestItemRemove = async (removedItem: RequestItem) => 
    {
      this.request.removeRequestItem(removedItem);
      await this.onUpdate(this.request);
    }

    return this.requestItemEditor.open({
      workOrder: this.workOrder,
      originalRequest: this.request,
      original: requestItem,
      onRequestItemUpdate,
      onRequestItemRemove,
    });
  }

  protected isItemGroup(requestItem: RequestItem)
  {
    return !!requestItem?.rentals;
  }

  onAddSheet()
  {
    return this.onEditSheet();
  }

  onEditSheet(sheet: ReturnRowItem = null)
  {
    const onSheetUpdate = async () => { 
      this.generateSheets(this.request.return_items);
      await this.onUpdate(this.request);
    } 

    return this.returnSheetEditor.open({
      original: sheet,
      originalRequest: this.request,
      workOrder: this.workOrder,
      onSheetUpdate:  onSheetUpdate,
      onSheetRemove:  onSheetUpdate,
    });
  }

  canAddSheets(): boolean
  {
    return !!this.request.started_at &&
             this.request.hasRequestItems() &&
             this.request.hasOngoingRentals();
  }

  async onAddCharge(): Promise<boolean>
  {
    return this.onEditCharge();
  }

  async onEditCharge(charge: Charge = null): Promise<boolean>
  {
    const onChargeUpdate = async (updatedCharge: Charge): Promise<void> => {
      updatedCharge?.exists()
        ? charge.mapAttributes(updatedCharge.flushAttributes())
        : this.request.addCharge(updatedCharge);
      
      await this.onUpdate(this.request);
    }

    const onChargeRemove = async (): Promise<void> => {
      this.request.removeCharge(charge);
      await this.onUpdate(this.request);
    }

    let minimumDate = this.workOrder.minimum_allowable_date;
    this.minimumDate = moment.unix(minimumDate).toDate();

    return this.chargeEditor.open({
      minimumDate: this.minimumDate,
      original: charge,
      onChargeUpdate: onChargeUpdate,
      onChargeRemove: onChargeRemove,
      allowRecurrence: true,
    });
  }

  async onAddNote(): Promise<boolean>
  {
    return this.onEditNote();
  }

  async onEditNote(note: Note = null): Promise<boolean>
  {
    const onNoteUpdate = async (updatedNote: Note) => {
      updatedNote?.exists()
        ? note.mapAttributes(updatedNote.flushAttributes())
        : this.request.addNote(updatedNote);
      
      await this.onUpdate(this.request);
    }

    const onNoteRemove = async () => {
      this.request.removeNote(note);
      await this.onUpdate(this.request);
    }

    let minimumDate = this.workOrder.minimum_allowable_date;
    this.minimumDate = moment.unix(minimumDate).toDate();

    return this.noteEditor.open({
      original: note,
      allowRecurrence: true,
      minimumDate: this.minimumDate,
      onNoteUpdate,
      onNoteRemove,
    })
  }

  canAddNotes(): boolean
  {
    return true;
  }

  hasReturns(): boolean
  {
    return !!this.returnLineItems.length
  }

  displayEndDate(): string
  {
    return !!this.request.ended_at
      ? this.dateFilter.transform(this.request.ended_at, 'dateMedium')
      : 'Automatic';
  }

  getArrayFromMapIterable(map): any[]{
    return Array.from(map);
  }

  protected _sortItems()
  {
    this.request.request_items?.sort(orderBy('position'));
    this.request.charges?.sort(orderBy('created_at'));
    this.request.notes?.sort(orderBy('noted_at'));
  }
}
