import { dto } from "./mixins/Dto.decorators";
import { VariantServicePropertyDefinition } from "./VariantServicePropertyDefinition.model";
import { VariantProperty } from "./VariantProperty.model";

export class VariantServiceProperty extends VariantProperty {

    constructor(attributes: object = {}) 
    {
        super();
        this.init(attributes);
    }

    @dto(VariantServicePropertyDefinition) definition: VariantServicePropertyDefinition = null;
}
