import { BaseModel } from "./Base.model";
import { Discardable } from "./mixins/Discardable.mixin";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";
import { OverrideableItem } from "./contracts/OverrideableItem.interface";

const MixinBase = Discardable( BaseModel );

export class ChargeDefinition extends MixinBase implements OverrideableItem {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }
    
    @dto() id?: string = null;
    @dto() label?: string = null;
    @dto() description?: string = null;
    @dto() applicable_work_orders?: boolean = true;
    @dto() applicable_rental_requests?: boolean = true;
    @dto() applicable_services?: boolean = true;
    @dto() recurring?: boolean = false;
    @dto() @date recurring_until?: number = null;
    @dto() discount?: boolean = false;
    @dto() percent?: boolean = false;
    @dto() flat_value?: number = 0;
    @dto() percent_value?: number = 0;

    static async get(id: string): Promise<ChargeDefinition> 
    {
        const response = await AppInjector.get(RouteMap).getChargeDefinition(id);
        return new ChargeDefinition(response.data());
    }

    get name(): string {
        return this.label;
    }

    getDisplayName(): string
    {
        return this.name;
    }

    async save(): Promise<ChargeDefinition> {
        const response = ( this.exists() )
            ? await this.routeMap.updateChargeDefinition(this.id, this.flush())
            : await this.routeMap.createChargeDefinition(this.flush());

        return this.map(response.data());
    }

    async discard() {
        const response = await this.routeMap.discardChargeDefinition(this.id);
        return this.map(response.data());
    }

    async recover() {
        const response = await this.routeMap.recoverChargeDefinition(this.id);
        return this.map(response.data());
    }
}
