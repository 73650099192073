import { BaseModel } from './Base.model'
import { Charge } from './Charge.model'
import { RentalReturn } from './RentalReturn.model'
import { Department } from './Department.model'
import { Rental } from './Rental.model';
import { Note } from './Note.model';
import { DispatchConstants } from "./DispatchConstants";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { Helpers as $helpers } from "../services/helpers.service"
import { ShortIdUtility } from "./utilities/ShortId.utility";
import { Request } from "./contracts/Request.interface";
import { orderBy } from "@beaconlite/utilities/Sort.utility";
import { RentalGroup } from './RentalGroup.model';
import { RequestItem } from './contracts/RequestItem.interface';
import { RentalGroupBuilderHelper } from '@beaconlite/services/RentalGroupBuilder.helper';
import IRentalRequest from './contracts/IRentalRequest.interface';
import { ReturnItem } from './contracts/ReturnItem.interface';

export class RentalRequest extends BaseModel implements Request, IRentalRequest {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
        this.runRentalsSetup();
    }

    protected readonly _prefix: string = RentalRequest._prefix;
    protected static readonly _prefix: string = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_RENTAL);

    static STATE_NONE                  = 'none';
    static STATE_DELIVERY_DRAFT        = `${DispatchConstants.TYPE_DELIVERY}_${DispatchConstants.STATE_DRAFT}`;
    static STATE_DELIVERY_REQUESTED    = `${DispatchConstants.TYPE_DELIVERY}_${DispatchConstants.STATE_REQUESTED}`;
    static STATE_DELIVERY_DISPATCHED   = `${DispatchConstants.TYPE_DELIVERY}_${DispatchConstants.STATE_DISPATCHED}`;
    static STATE_DELIVERY_ACTIONED     = `${DispatchConstants.TYPE_DELIVERY}_${DispatchConstants.STATE_ACTIONED}`;
    static STATE_DELIVERY_COMPLETED    = `${DispatchConstants.TYPE_DELIVERY}_${DispatchConstants.STATE_COMPLETED}`;
    static STATE_PICKUP_DRAFT          = `${DispatchConstants.TYPE_PICKUP}_${DispatchConstants.STATE_DRAFT}`;
    static STATE_PICKUP_REQUESTED      = `${DispatchConstants.TYPE_PICKUP}_${DispatchConstants.STATE_REQUESTED}`;
    static STATE_PICKUP_DISPATCHED     = `${DispatchConstants.TYPE_PICKUP}_${DispatchConstants.STATE_DISPATCHED}`;
    static STATE_PICKUP_ACTIONED       = `${DispatchConstants.TYPE_PICKUP}_${DispatchConstants.STATE_ACTIONED}`;
    static STATE_PICKUP_COMPLETED      = `${DispatchConstants.TYPE_PICKUP}_${DispatchConstants.STATE_COMPLETED}`;
    static STATE_INVOICED              = 'invoiced';

    static LOCK_LEVEL = {
        [RentalRequest.STATE_NONE] : 0,
        [RentalRequest.STATE_DELIVERY_REQUESTED] : 0,
        [RentalRequest.STATE_DELIVERY_DRAFT] : 0,
        [RentalRequest.STATE_DELIVERY_ACTIONED] : 1,
        [RentalRequest.STATE_DELIVERY_DISPATCHED] : 1,
        [RentalRequest.STATE_DELIVERY_COMPLETED] : 2,
        [RentalRequest.STATE_PICKUP_DRAFT] : 0,
        [RentalRequest.STATE_PICKUP_REQUESTED] : 0,
        [RentalRequest.STATE_PICKUP_ACTIONED] : 2,
        [RentalRequest.STATE_PICKUP_DISPATCHED] : 2,
        [RentalRequest.STATE_PICKUP_COMPLETED] : 2,
        [RentalRequest.STATE_INVOICED] : 3,
    };  

    @dto() id: string = null;
    @dto() serial_id: string = null;
    @dto() department_id: string = null;
    @dto() work_order_id: string = null;
    @dto() locked: boolean = false;
    @dto() invoiced: boolean = false;
    @dto() waive_request_minimum: boolean = false;
    @dto() state: string = RentalRequest.STATE_NONE;
    @dto() location: string = null;
    @dto() ordered_by: string = null;
    @dto() tags: string = null;
    @dto() @date started_at: number = null;
    @dto() @date ended_at: number = null;
    @dto() minimum_adjustment?: any = null;

    @dto(Department) department: Department = null;
    @dto(Rental) rentals: Rental[] = [];
    @dto(RentalGroup) rental_groups: RentalGroup[] = [];
    @dto(Charge) charges: Charge[] = [];
    @dto(Note) notes: Note[] = [];

    protected _ungrouped_rentals: Rental[] = [];
    protected _request_items: RequestItem[] = [];
    protected _return_items: ReturnItem[] = [];

    protected rentalGroupBuilder: RentalGroupBuilderHelper = new RentalGroupBuilderHelper();

    protected runRentalsSetup(): void {
        this.setUngroupedRentals();
        this.setRentalsOnGroup();
        this.setRequestItems();
        this.setReturnItems();
    }

    protected setUngroupedRentals(): void {
        this._ungrouped_rentals = this.rentalGroupBuilder.getUngroupedRentals(this);
    }

    get ungrouped_rentals(): Rental[] {
        return this._ungrouped_rentals;
    }

    protected setRentalsOnGroup(): void {
        this.rentalGroupBuilder.setRentalsOnGroups(this);
    }

    get request_items(): RequestItem[] {
        return this._request_items;
    }

    protected setRequestItems(): void {
        this._request_items = [].concat(this.rental_groups).concat(this._ungrouped_rentals);
    }

    get return_items(): ReturnItem[] {
        return this._return_items;
    }

    protected setReturnItems(): void {
        const groupsWithReturns = this.rental_groups.filter(group => (group.rentals.some(rental => rental.returns.length > 0)));
        let ungrouped_returns: RentalReturn[] = [];

        for (let i = 0; i < this.ungrouped_rentals.length; i += 1) {
            ungrouped_returns.splice(ungrouped_returns.length, 0, ...this.ungrouped_rentals[i].returns);
        }

        this._return_items = [].concat(ungrouped_returns).concat(groupsWithReturns);
    }

    get lock_level(): number 
    {
        return RentalRequest.LOCK_LEVEL[this.state] || 0;
    }

    get ongoing_rentals(): Rental[] 
    { 
        return this.rentals.filter(rental => rental.quantity_remaining > 0);
    }

    get rate_adjusted_rentals(): Rental[]
    {
        return this.rentals.filter(rental => rental.rate_adjustment != undefined && rental.rate_adjustment != null);
    }

    get state_name(): string 
    {
        let state = ''

        switch(this.state)
        {
            case RentalRequest.STATE_DELIVERY_DRAFT:
                state = 'Delivery';
                break;
            case RentalRequest.STATE_DELIVERY_REQUESTED:
                state = 'Delivery Requested';
                break;
            case RentalRequest.STATE_DELIVERY_DISPATCHED:
                state = 'Delivery Dispatched';
                break;
            case RentalRequest.STATE_DELIVERY_ACTIONED:
                state = 'Delivery Actioned';
                break;
            case RentalRequest.STATE_DELIVERY_COMPLETED:
                state = 'Delivery Completed';
                break;
            case RentalRequest.STATE_PICKUP_DRAFT:
                state = 'Retrieval';
                break;
            case RentalRequest.STATE_PICKUP_REQUESTED:
                state = 'Retrieval Requested';
                break;
            case RentalRequest.STATE_PICKUP_DISPATCHED:    
                state = 'Retrieval Dispatched';
                break;
            case RentalRequest.STATE_PICKUP_ACTIONED:
                state = 'Retrieval Actioned';
                break;
            case RentalRequest.STATE_PICKUP_COMPLETED:     
                state = 'Retrieval Completed';
                break;
            case RentalRequest.STATE_INVOICED:         
                state = 'Invoiced';
                break;
            default:
                state = '';
        }

        return state;
    }

    get dispatch_notes(): Note[] 
    {
        return this.notes.filter( note => note.type === Note.TYPE_DISPATCH);
    }

    // TODO: Move into a common utility. Used in a few models.
    get formatted_serial_id(): string {
        if (! this.serial_id) return '';

        return RentalRequest.formatSerialId(this.serial_id);
    }

    static formatSerialId(serialId: string): string
    {
        let padded = $helpers.zeroPadLeft(serialId, 6);
        // TODO: static _prefix should be accessible using this._prefix inside static functions
        const prefix = this._prefix || RentalRequest._prefix;
        return `${prefix}-${padded}`;
    }

    canAddRequestItems(): boolean {
        return !this.locked && this.lock_level < 1;
    }

    hasRequestItems(): boolean {
        return !!this._request_items.length;
    }

    hasOngoingRentals(): boolean {
        return !!this.ongoing_rentals.length;
    }

    addRental(model?: Rental): Rental {
        const rental = model || new Rental();
        this.rentals.push(rental);
        return rental;
    }

    removeRental(model: Rental): void {
        const index = this.rentals.indexOf(model);
        this.rentals.splice(index, 1);
    }

    addRentalGroup(model: RentalGroup): RentalGroup {
        const rentalGroup = model;

        if (rentalGroup.rentals.length < 2) {
            throw new Error(`Trying to add group ${rentalGroup.name} but did not include rentals`);
        }

        // TODO: Jira BL-737: Not yet implemented. Want to shift API to use tmp_ids instead of nesting rentals in groups in json payload.
        // rental.rental_group_tmp_id is used by API though as a flag for grouped, unsaved rentals however.
        const tmp_id = $helpers.generateDirtyId(10);
        rentalGroup.tmp_id = tmp_id;

        this.rental_groups.push(rentalGroup);

        for (let i = 0; i < rentalGroup.rentals.length; i +=1) {
            rentalGroup.rentals[i].rental_group_tmp_id = tmp_id;
            this.addRental(rentalGroup.rentals[i]);
        }

        return rentalGroup;
    }

    removeRentalGroup(model: RentalGroup): void {
        const index = this.rental_groups.indexOf(model);
        this.rental_groups.splice(index, 1);

        for (let i = 0; i < model.rentals.length; i +=1) {
            this.removeRental(model.rentals[i]);
        }
    }

    removeRequestItem(removedItem: RequestItem): void {
        const idx = this._request_items.findIndex(item => item.position === removedItem.position);
        this._request_items.splice(idx, 1);

        this._request_items.forEach((el) => {
            if (el.position > idx) {
                el.position -= 1;
            }
        });

        if (!!removedItem?.rentals) {
            this.removeRentalGroup(removedItem as RentalGroup);
        } else {
            this.removeRental(removedItem as Rental);
        }
    }

    canAddCharges(): boolean {
        return !this.invoiced;
    }

    hasCharges(): boolean {
        return !!this.charges.length;
    }

    addCharge(model?: Charge): Charge {
        const charge = model || new Charge();

        this.charges.push(charge);

        return charge;
    }

    removeCharge(model: Charge): void {
        const index = this.charges.indexOf(model);

        if (index >=0)
        {
            this.charges.splice(index, 1);
        }
    }

    canAddNotes(): boolean {
        return !this.invoiced;
    }

    hasNotes(): boolean {
        return !!this.notes.length;
    }

    hasNote(model: Note): boolean {
        return this.notes.indexOf(model) >= 0;
    }

    addNote(note: Note = new Note()): Note 
    {
        this.notes.unshift(note);

        this.notes.sort( orderBy('created_at') );

        return note;
    }

    removeNote(model: Note): void {
        const index = this.notes.indexOf(model);

        if (index >= 0)
        {
            this.notes.splice(index, 1);
        }
    }

    adjustEndDate() {
        let timestamp = null;

        // Find the last return date if all rentals have been returned
        if(this.ongoing_rentals.length == 0)
        {
            this.rentals.forEach( rental => {
                rental.returns.forEach( returnItem => {
                    if (returnItem.returned_at > timestamp)
                    {
                        timestamp = returnItem.returned_at;
                    }
                });
            });
        }

        this.ended_at = timestamp;
    }


    protected addDuplicatedRentalGroup(model: RentalGroup): RentalGroup {
        const rentalGroup = model;
        this.rental_groups.push(rentalGroup);

        return rentalGroup;
    }

    duplicate(overrides: any = {}) 
    {
        const duplicate = new RentalRequest( this.flushAttributes() );

        duplicate.started_at = overrides.started_at || duplicate.started_at;

        // Reset computed and unique values back to model defaults
        duplicate.id                = null;
        duplicate.work_order_id     = null;
        duplicate.locked            = false;
        duplicate.invoiced          = false;
        duplicate.state             = RentalRequest.STATE_NONE;
        duplicate.ended_at          = null;
        duplicate.created_at        = null;
        duplicate.updated_at        = null;
        duplicate.rentals           = [];

        this.rentals.forEach( rental => { 
            duplicate.addRental(rental.duplicate()) 
        });

        this.rental_groups.forEach(rentalGroup => {
            duplicate.addDuplicatedRentalGroup(rentalGroup.duplicate());
        });

        this.runRentalsSetup();
        
        this.charges.forEach( charge => { 
            duplicate.addCharge(charge.duplicate()) 
        });

        this.notes.forEach( note => { 
            duplicate.addNote(note.duplicate()) 
        });

        return duplicate;
    }
}
