<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<mat-card class="medium-card">
    <div class="title-row" fxLayout="column">
        <span class="mat-headline">{{ this.title }}</span>
        <span class="mat-caption charge-state-label" *ngIf="this.charge.discarded">
            <span>Discarded</span>
        </span>
    </div>

    <form #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Label</mat-label>
                <input type="text"
                    [(ngModel)]="this.charge.label"
                    name="labelField"
                    #labelField="ngModel"
                    required
                    maxlength="255"
                    [disabled]="this.locked"
                    matInput>
            <mat-hint align="end">{{ this.charge.label?.length || 0 }} / 255</mat-hint>
            <mat-error *ngIf="labelField.hasError('required')">Label is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Description</mat-label>
                <textarea [(ngModel)]="this.charge.description"
                    name="descriptionField"
                    rows="4"
                    [disabled]="this.locked"
                    matInput>
                </textarea>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="this.charge.discount"
                    name="isDiscountField"
                    [disabled]="this.locked"
                    required>
                    <mat-option [value]="true">Discount</mat-option>
                    <mat-option [value]="false">Charge</mat-option>
                </mat-select>
        </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Value Type</mat-label>
                <mat-select [(ngModel)]="this.charge.percent"
                    name="isPercentField"
                    [disabled]="this.locked"
                    required>
                    <mat-option [value]="true">Percent</mat-option>
                    <mat-option [value]="false">Dollar</mat-option>
                </mat-select>
        </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="!this.charge.percent">
            <mat-form-field fxFlex>
                <mat-label>Value</mat-label>
                <input type="text"
                    [(ngModel)]="this.charge.flat_value"
                    name="flatValueField"
                    #flatValueField="ngModel"
                    required
                    currencyInput
                    minNum="0"
                    [disabled]="this.locked"
                    matInput>
            <mat-error *ngIf="flatValueField.hasError('required')">Value is required.</mat-error>
            <mat-error *ngIf="flatValueField.hasError('minNum')">Value must be at least $ 0.00.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="this.charge.percent">
            <mat-form-field fxFlex>
                <mat-label>Value</mat-label>
                <input type="text"
                    [(ngModel)]="this.charge.percent_value"
                    name="percentValueField"
                    #percentValueField="ngModel"
                    required
                    percentInput
                    minNum="0"
                    maxNum="1"
                    [disabled]="this.locked"
                    matInput>
            <mat-error *ngIf="percentValueField.hasError('required')">Value is required.</mat-error>
            <mat-error *ngIf="percentValueField.hasError('minNum')">Value must be at least 0.00 %.</mat-error>
            <mat-error *ngIf="percentValueField.hasError('maxNum')">Value cannot exceed 100.00 %.</mat-error>
            </mat-form-field>
        </div>

        <div class="applicable-row" fxLayout="row" fxLayoutAlign="space-around center">
            <mat-checkbox [(ngModel)]="this.charge.applicable_work_orders"
                name="applyWorkOrdersField"
                [disabled]="this.locked">
                Work Orders
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="this.charge.applicable_rental_requests"
                name="applyRentalRequestsField"
                [disabled]="this.locked">
                Rental Requests
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="this.charge.applicable_services"
                name="applyServicesField"
                [disabled]="this.locked">
                Services
            </mat-checkbox>
        </div>

        <mat-divider></mat-divider>

        <div class="action-row" fxLayout="row" fxLayoutAlign="space-between center">
                <button mat-icon-button
                    *ngIf="this.charge.discarded"
                    [disabled]="this.loading"
                    (click)="this.restore()"
                    color="primary">
                    <mat-icon>restore</mat-icon>
                </button>
                <button mat-icon-button
                type="button"
                    *ngIf="!this.charge.discarded && this.charge.exists()"
                    [disabled]="this.loading || !this.charge.exists()"
                    (click)="this.discard()"
                    color="primary">
                    <mat-icon>delete</mat-icon>
                </button>
                <span fxFlex></span>

                <button mat-raised-button color="accent" type="submit">
                    Save
                </button>
            </div>
    </form>
</mat-card>
