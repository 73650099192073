import { Ng2StateDeclaration } from '@uirouter/angular';
import { BranchSettingsIndexComponent } from './branch-settings-index.component';

export const BranchSettingsIndexState: Ng2StateDeclaration = {
  name: 'protected.branches',
  url: '/resources/branches',
  component: BranchSettingsIndexComponent,
  data: { 
    pageTitle: 'Branches', 
    requiresAuth: true,
  },
}
