<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<mat-card class="medium-card item-definition__card" fxLayout="column" *ngIf="!this.loading">
    <div class="disabled-banner" fxLayout="row" fxLayoutAlign="space-between center" [fxShow]="this.original.disabled">
        This item cannot be added to line item requests.
    </div>
    <mat-card-title fxFlex fxLayoutAlign="space-between center">

        <span class="mat-headline">{{ this.original.name }}</span>
        <span class="mat-caption service-state-label" *ngIf="this.original.discarded">
            <span>Discarded</span>
        </span>

        <div fxLayoutAlign="center center">
            <button mat-icon-button [matMenuTriggerFor]="mainMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #mainMenu="matMenu">
                <button mat-menu-item
                    *ngIf="!this.original.discarded && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.edit()">
                    <mat-icon>edit</mat-icon>
                    Edit
                </button>
                <button mat-icon-button
                    *ngIf="this.original.discarded"
                    [disabled]="this.loading"
                    (click)="this.restore()"
                    color="primary">
                    <mat-icon>restore</mat-icon>
                    Restore
                </button>
                <button mat-menu-item
                    *ngIf="!this.original.discarded && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.discard()">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>
            </mat-menu>
        </div>
    </mat-card-title>

    <div class="item-definition__card-inner">
        <mat-divider></mat-divider>
        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="100" layoutPadding>
                <span class="mat-caption">Description</span>
                <p class="mat-body-1">{{ this.original.description }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Quantity</span>
                <p class="mat-body-1">{{ this.original.quantity }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Code</span>
                <p class="mat-body-1">{{ this.original.code }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Tags</span>
                <p class="mat-body-1">{{ this.original.tags }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" *ngIf="this.departments?.length > 1">
            <div fxLayout="column" fxFlex="32" layoutPadding>
                <span class="mat-caption">Default Department</span>
                <p class="mat-body-1">{{ this.departmentName }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Rate</span>
                <p class="mat-body-1">{{ this.original.rate| currencyFilter}}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

    </div>
</mat-card>
