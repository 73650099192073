import { ClientIndexState } from './views/protected/clients/client-index/client-index.state';
import { ClientSingleState } from './views/protected/clients/client-single/client-single.state';
import { ChargeIndexState } from './views/protected/charges/charge-index/charge-index.state';
import { ChargeSingleState } from './views/protected/charges/charge-single/charge-single.state';
import { EmployeeIndexState } from './views/protected/employees/employee-index/employee-index.state';
import { EmployeeSingleState } from './views/protected/employees/employee-single/employee-single.state';
import { UserDispatchesIndexState } from './views/protected/user-dispatches/user-dispatches-index/user-dispatches-index.state';
import { UserDispatchesSingleState } from './views/protected/user-dispatches/user-dispatches-single/user-dispatches-single.state';
import { UpcomingDispatchesState } from './views/protected/upcoming-dispatches/upcoming-dispatches/upcoming-dispatches.state';
import { UpcomingDispatchesSingleState } from './views/protected/upcoming-dispatches/upcoming-dispatches-single/upcoming-dispatches-single.state';
import { RentalIndexState } from './views/protected/rentals/rental-index/rental-index.state';
import { RentalSingleState } from './views/protected/rentals/rental-single/rental-single.state';
import { ServiceIndexState } from './views/protected/services/service-index/service-index.state';
import { ServiceSingleState } from './views/protected/services/service-single/service-single.state';
import { ProtectedViewState } from './views/protected/protected-view.state';
import { WorkOrderIndexState } from './views/protected/work-orders/work-order-index/work-order-index.state';
import { WorkOrderSingleState } from './views/protected/work-orders/work-order-single/work-order-single.state';
import { GeneralSettingsState } from './views/protected/settings/general-settings/general-settings.state';
import { BranchSettingsIndexState } from './views/protected/settings/branch-settings/branch-settings-index/branch-settings-index.state';
import { BranchSettingsSingleState } from './views/protected/settings/branch-settings/branch-settings-single/branch-settings-single.state';
import { InvoiceIndexState } from './views/protected/invoices/invoice-index/invoice-index.state';
import { InvoiceScheduleState } from './views/protected/invoices/invoice-schedule/invoice-schedule.state';
import { InvoiceSingleState } from './views/protected/invoices/invoice-single/invoice-single.state';
import { QuickbooksSettingsState } from './views/protected/settings/accounting-settings/quickbooks-settings/quickbooks-settings.state';
import { ReportsState } from './views/protected/reports/reports-view/reports-view.state';
import { ActiveItemsReportState } from './views/protected/reports/active-item/active-item.state';
import { LostItemsReportState } from './views/protected/reports/lost-item/lost-item.state';
import { RentalItemsReportState } from './views/protected/reports/rental-item/rental-item.state';
import { ServiceHoursReportState } from './views/protected/reports/service-hours/service-hours.state';
import { InvoicedItemsReportState } from './views/protected/reports/invoiced-items/invoiced-items.state';
import { DispatchedItemReportState } from './views/protected/reports/dispatched-item/dispatched-item.state';
import { DispatchedRequestReportState } from './views/protected/reports/dispatched-request/dispatched-request.state';
import { DispatchScheduleState } from './views/protected/schedules/dispatches/dispatch-schedule.state';
import { EmployeeScheduleState } from './views/protected/schedules/employees/employee-schedule.state';
import { DispatchScheduleSingleState } from './views/protected/schedules/dispatches/dispatch-schedule-single/dispatch-schedule-single.state';
import { EmployeeScheduleSingleState } from './views/protected/schedules/employees/employee-single/employee-single.state';
import { Sage50SettingsState } from './views/protected/settings/accounting-settings/sage50-settings/sage50-settings.state';
import { Sage50ExportsState } from './views/protected/settings/accounting-settings/sage50-settings/sage50-exports/sage50-exports.state';
import { RentalGroupSingleState } from './views/protected/rentals/rental-group-single/rental-group-single.state';
import { LineItemIndexState } from './views/protected/line-items/line-item-index/line-item-index.state';
import { LineItemSingleState } from './views/protected/line-items/line-item-single/line-item-single.state';

export const APP_STATES = [
    ProtectedViewState,
    WorkOrderIndexState,
    WorkOrderSingleState,
    ClientIndexState,
    ClientSingleState,
    ChargeIndexState,
    ChargeSingleState,
    EmployeeIndexState,
    EmployeeSingleState,
    RentalIndexState,
    RentalSingleState,
    ServiceIndexState,
    ServiceSingleState,
    GeneralSettingsState,
    BranchSettingsIndexState,
    BranchSettingsSingleState,
    UserDispatchesIndexState,
    UserDispatchesSingleState,
    UpcomingDispatchesState,
    InvoiceIndexState,
    InvoiceScheduleState,
    InvoiceSingleState,
    QuickbooksSettingsState,
    ReportsState,
    ActiveItemsReportState,
    LostItemsReportState,
    ServiceHoursReportState,
    InvoicedItemsReportState,
    RentalItemsReportState,
    DispatchedItemReportState,
    DispatchedRequestReportState,
    DispatchScheduleState,
    EmployeeScheduleState,
    EmployeeScheduleSingleState,
    DispatchScheduleSingleState,
    UpcomingDispatchesSingleState,
    Sage50SettingsState,
    Sage50ExportsState,
    RentalGroupSingleState,
    LineItemIndexState,
    LineItemSingleState,
]
