import { Injectable } from '@angular/core';
import { env } from '../env.service';
import { HttpAdapterService } from './http-adapter.service';
import { HttpRequest } from './http-request'

@Injectable({
  providedIn: 'root'
})
export class BackendClientService {
  _url: string;
  _options: { withCredentials: boolean };

  constructor(private httpAdapter: HttpAdapterService) 
  {
    this._url = env('BACKEND_URL');    
    this._options = { withCredentials: true };
  }

  request(): HttpRequest
  {
    return this.httpAdapter.request(this._options).base(this._url);
  }
}
