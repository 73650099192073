import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { Helpers as $helpers } from '../services/helpers.service';
import { DecimalPipe } from '@angular/common';
@Directive({
    selector: '[timeInput][ngModel]',
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeInputDirective), multi: true }]
})
export class TimeInputDirective implements ControlValueAccessor {
    onChange = (value: number) => {};
    onTouch = () => {};
    readonly HOUR_SYMBOL: string = 'h';
    readonly INVALID_CHARACTERS: RegExp = /[^\d\.]/;

    constructor( 
        protected elementRef: ElementRef,
        protected renderer: Renderer2,
        protected decimalPipe: DecimalPipe,
    ) {}

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        this.restrictInput(event);
    }

    @HostListener('focus', ['$event'])
    onFocus(event: FocusEvent) {
        this.cleanOnFocus(event);
        this.elementRef.nativeElement.select();
    }

    @HostListener('blur', ['$event'])
    onBlur(event: FocusEvent) {
        this.formatOnBlur(event);
    }

    @HostListener('input', ['$event.target.value'])
    input(value: string) {
        this.onChange(this.parse(value));
    }

    @HostListener('focusout', ['$event.target'])
    onFocusout() {
        this.onTouch();
    }

    // equivalent to ng1 $formatters
    writeValue(value: number): void {
        this.format(value);
    }

    // equivalent to ng1 $parsers
    registerOnChange(fn: (value: number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }
   
    /**
     * Restrict key/character input for currency input fields.
     * Allowed values: 0-9, decimals, and minus symbols.
     *
     * @param {KeyboardEvent} event
     */
    protected restrictInput(event: KeyboardEvent) {
        let key: string = event.key;
        let value: string = (<HTMLInputElement>event.target).value;

        // Prevent input of invalid characters
        if (this.INVALID_CHARACTERS.test(key))
        {
            event.preventDefault();
            return;
        }
        
        const selectionStart: number = (<HTMLInputElement>event.target).selectionStart;
        const selectionEnd: number = (<HTMLInputElement>event.target).selectionEnd;

        // Prevent input of additional decimals
        const selection: string = value.substring(selectionStart, selectionEnd);
        const hasSelection: boolean = !!selection.length;

        if ( key == '.' && 
            (value.includes('.') && hasSelection && !selection.includes('.')  || 
            value.includes('.') && !hasSelection ) )
        {
            event.preventDefault();
            return;
        }
    }

    /**
     * Clean the input value when focused.
     *
     * @param {FocusEvent} event
     */
    protected cleanOnFocus(event: FocusEvent): void {
        const element: HTMLInputElement = this.elementRef.nativeElement;
        const eventValue: string = (<HTMLInputElement>event.target).value;
        const cleanedValue: string = this.cleanViewValue(eventValue);

        this.renderer.setProperty(element, 'value', cleanedValue);
    }

    /**
     * Format the input value when blurring.
     *
     * @param {FocusEvent} event
     */
    protected formatOnBlur(event: FocusEvent): void {
        const eventValue: string = (<HTMLInputElement>event.target).value;

        if (!! eventValue)
        {
            const transformedValue = this.angularDecimalPipe(eventValue);
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', transformedValue);
        }
    }

    /**
     * Parsing function for time (in hours) values.
     * Converts a decimal (hours) value into an integer (seconds) value.
     *
     * @param {string} value
     * @returns {number|null}
     */
    protected parse(value: string): number | null {
        if (! value) return;

        const cleanedValue: string = this.cleanViewValue(value);
        const parsedValue: number = parseFloat(cleanedValue);
        const rounded: number = $helpers.roundFloat(parsedValue, 2);

        // Rounding to ensure integer value
        const roundedInt: number = Math.round(rounded * 3600);

        return isNaN(roundedInt)
            ? null
            : roundedInt;
    }

    /**
     * Formatting function for time (in seconds).
     * Converts an integer (seconds) value into a decimal (hours) value.
     *
     * @param {number} value
     * @returns {string|number}
     */
    protected format(value: number): void {
        if (value == null || value == undefined) return;

        const element: HTMLInputElement = this.elementRef.nativeElement;
        const formattedValue: string = this.angularDecimalPipe(value / 3600);
        this.renderer.setProperty(element, 'value', formattedValue);
    }

    /**
     * Clean a number string by removing all invalid characters.
     * Valid characters: 0-9, decimals.
     *
     * @param {string} value
     * @returns {string}
     */
    protected cleanViewValue(value: string): string {
        const globalRegExp = new RegExp(this.INVALID_CHARACTERS, 'g');

        return value.replace(globalRegExp, '');
    }

    /**
     * 
     * @param {string|number} value 
     */
    protected angularDecimalPipe(value: string | number): string {
        return `${this.decimalPipe.transform(value, '1.2-2')} ${this.HOUR_SYMBOL}`
    }
}
