import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceOverride, VariantService } from '@beaconlite/models';
import { VariantServiceCollection } from '@beaconlite/models/collections';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { ServiceOverrideEditorData } from './service-override-editor-data.interface';

@Component({
  selector: 'app-service-override-editor',
  templateUrl: './service-override-editor.component.html',
  styleUrls: ['./service-override-editor.component.scss']
})
export class ServiceOverrideEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('searchTextField') searchTextField: NgModel;

  original: ServiceOverride = this.data.original;
  onUpdate = this.data.onServiceUpdate;
  onRemove = this.data.onServiceRemove;

  loading = false;
  serviceOverride = new ServiceOverride();
  searchText = '';
  promisedServices: Promise<VariantService[]>;
  selectedService: VariantService;

  overrideTypes = {
    rate: ServiceOverride.TYPE_RATE,
    percent: ServiceOverride.TYPE_PERCENT,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ServiceOverrideEditorData,
    public dialogRef: MatDialogRef<ServiceOverrideEditorComponent>,
    protected dialogNotifications: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    if (!! this.original)
    {
      this.serviceOverride = this.original.copy();
      this.selectedService = this.serviceOverride.variant_service;
    }
  }

  canRemove(): boolean
  {
    return this.original.exists();
  }

  onQueryServices(): void
  {
    const params = {
      name: this.searchText,
      order: 'asc',
    };

    this.promisedServices = (new VariantServiceCollection()).all(params);
  }

  onServiceSelected(variant: VariantService)
  {
    this.selectedService = variant;
    this.serviceOverride.resetVariant();

    if (variant)
    {
      this.serviceOverride.inheritVariant(variant);
    }
  }

  displaySelectedService = (service?: VariantService): string => 
  {
    if (!!this.original?.exists() && this.searchTextField.pristine)
    {
      this.searchTextField.control.setErrors(null);
      return this.original.variant_service.display_name;
    }

    return service?.display_name || '';
  }

  async save(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) return false;

    this.loading = true;

    try
    {
      await this.onUpdate(this.original, this.serviceOverride);
    }
    finally
    {
      this.loading = false;
      this.dialogRef.close();
    }
  }

  async remove(): Promise<void>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotifications.removeConfirm())
      {
        await this.onRemove(this.original);
      }
    }
    finally
    {
      this.loading = false;
      this.dialogRef.close();
    }
  }
}
