import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[maxNum][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxNumValidatorDirective, multi: true }],
})
export class MaxNumValidatorDirective implements Validator {
    @Input('maxNum') maxNum: number;

    validate(control: AbstractControl) {
        if (control.value == null || control.value == undefined ) return null;

        const isValid = control.value <= this.maxNum;

        return isValid
            ? null
            : { maxNum: true };
    }
}
