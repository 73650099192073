import { VariantPropertyDefinition } from ".";
import { BaseModel } from "./Base.model";
import { dto } from "./mixins/Dto.decorators";

export abstract class VariantProperty extends BaseModel {

    constructor(attributes: object = {}) 
    {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() variant_property_definition_id?: string = null;
    @dto() variant_id?: string = null;
    @dto() value?: string = null;

    abstract definition: VariantPropertyDefinition;
}
