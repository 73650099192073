import { BaseModel } from "./Base.model";
import { InvoicedRental } from "./InvoicedRental.model";
import { InvoicedRentalReturn } from "./InvoicedRentalReturn.model";
import IRentalGroup from "./contracts/IRentalGroup.interface";
import { InvoicedRequestItem } from "./contracts/InvoicedRequestItem.interface";
import { InvoicedReturnItem } from "./contracts/InvoicedReturnItem.interface";
import { dto } from "./mixins/Dto.decorators";

export class InvoicedRentalGroup extends BaseModel implements InvoicedRequestItem, InvoicedReturnItem, IRentalGroup {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() invoiced_rental_request_id?: string = null;
    @dto() rental_group_id?: string = null;
    @dto() name?: string = null;
    @dto(InvoicedRental) rentals: InvoicedRental[] = [];

    returns: InvoicedRentalReturn[] = [];

    get lost_returns(): InvoicedRentalReturn[] {
        return this.returns.filter(aReturn => aReturn.lost);
    }

    get rate_adjusted_rentals(): InvoicedRental[] {
        return this.rentals.filter(rental => rental.rate_adjustment);
    }
    
    get rate_adjusted_returns(): InvoicedRentalReturn[] {
        return this.returns.filter(aReturn => aReturn.rate_adjustment && !aReturn.lost);
    }

    get minimum_adjusted_returns(): InvoicedRentalReturn[] {
        return this.returns.filter(aReturn => aReturn.minimum_adjustment && !aReturn.lost);
    }
}
