import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateFilterPipe } from './date-filter.pipe';

@Pipe({
    name: 'timeOfDayFilter'
})
export class TimeOfDayFilterPipe implements PipeTransform {

    constructor(protected datePipe: DateFilterPipe) {}

    /**
     * Format seconds from midnight to time format.
     * 
     * @param {number} value 
     * @returns {string}
     */
    transform(value: number = 0): string 
    {
        const secondsFromMidnight = moment().startOf('day').add(value, 'seconds').unix()

        return this.datePipe.transform(secondsFromMidnight, 'time');
    }
}
