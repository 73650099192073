<div class="index-sidebar" fxLayout="column">
    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="this.onSearch()" fxLayout="column" novalidate>
        <mat-form-field fxFlex>
            <mat-label class="mat-icon-label">
                <mat-icon>search</mat-icon>
            </mat-label>
            <input type="text"
                name="keywordField"
                [(ngModel)]="this.keyword" 
                matInput>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="end center">
            <button type="button" (click)="this.onAdd()" mat-mini-fab color="accent">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [fxShow]="this.clients.loading"></mat-progress-bar>

    <cdk-virtual-scroll-viewport itemSize="70">
        <mat-list dense>
            <mat-list-item class="index-list"
                [class.index-list__item--selected]="selectedModelId === client.id"
                *cdkVirtualFor="let client of this.clients"
                (click)="onEdit(client)"
                matRipple>

                <div class="mat-list-text" fxLayout="column">
                    <h3>{{ client.name }}</h3>
                    <h4>{{ client.city }}, {{ client.region }}</h4>
                    <p>{{ client.discarded ? 'Discarded' : 'Active' }}</p>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>

</div>

<mat-divider vertical></mat-divider>

<!-- Client Single -->
<ui-view fxFlex fxLayout="column"></ui-view>
