import { date, HasModelDates, ModelDates } from './mixins/Date.decorators'
import { dto, DtoMap, HasDto } from './mixins/Dto.decorators'
import { AppInjector } from '../services/app-injector.service';
import { Helpers as $helpers } from '../services/helpers.service'
import { RouteMap } from '../services/network/route-map.service';
import { DateTimeUtility } from './utilities/DateTime.utility'
import { ShortIdUtility } from './utilities/ShortId.utility'

// Note: Removed `abstract` modifier to allow for mixins
// Due to probable bug, see: https://github.com/microsoft/TypeScript/issues/29653
// Update: Shold be resolved in TS 4.2+
// TODO: Jira BL-722: Change how data is mapped to BaseModel private members to be more explicit. We currently are doing stuff behind the scenes in this model, but want to pass a string arg into the dto decorator to be explicit.
// Ex: @dto('all_rentals') private _all_rentals: Rentals[] = null;. 
export class BaseModel implements HasDto, HasModelDates {

    protected readonly _prefix:string = '';
    
    _dates: ModelDates;
    _dto: DtoMap
    
    //  TODO: this kind of property breaks deep copy...
    protected routeMap: RouteMap = AppInjector.get(RouteMap);

    // Common attributes
    @dto() id?: string = null
    @dto() @date created_at?: number = null
    @dto() @date updated_at?: number = null

    // A common place to store temporary properties for all model classes.  Non-persisted.
    temps: any = {};

    // Name of members prefixed with '_' on model that had incoming attributes mapped to it. Ex attributes['prop1'] was mapped to this._prop1, _prop1 is added to set.
    // Since visiblity is not able to be determined at runtime, private/protect members are denoted with a '_' prefix in models.
    protected _privateDtoProps: Set<string> = new Set();

    // TODO: BaseModel class isn't finding extending models attributes unless explicitly calling map from derived model constructor.  Figure out alternatives.
    // https://github.com/microsoft/TypeScript/issues/1617
    // https://stackoverflow.com/questions/43595943/why-are-derived-class-property-values-not-seen-in-the-base-class-constructor
    // Alternative 1: init function called after super() in derived constructor.
    constructor(attributes: object = {}){}

    init(attributes: object) {
        this.map(attributes);
        this.createModelDateAccessors();
    }

    get shortId(){
        return ShortIdUtility.format(this._prefix, this.id);
    }

    map(attributes: object): this {

        this.mapAttributes(attributes);
        this.mapRelations(attributes);
        return this;
    }

    mapAttributes(attributes: object): this {
        for (const key in this._dto.attributes) {
        
            // If the dto key began with '_' and we can't find the dto key in attributes, try to map incoming attribute['member'] to model._member. This is done to allow incoming attributes to map to private members on models.
            const potentialPrivateProp = key.slice(0,1) == '_';
            const publicKey = potentialPrivateProp && attributes.hasOwnProperty(key.slice(1,key.length)) 
                ? key.slice(1,key.length) 
                : key;

            if (!(publicKey in attributes)) 
            {
                continue;
            }

            if (potentialPrivateProp && !(key in attributes))
            {
                this._privateDtoProps.add(key);
            }

                this[key] = $helpers.deepCopy(attributes[publicKey]);
        }

        return this;
    }

    mapRelations(attributes: object): this {

        for (const relationKey in this._dto.relations)
        {
            const constructor = this._dto.relations[relationKey];

            const potentialPrivateRelation = relationKey.slice(0,1) == '_';

            // If the dto key began with '_' and we can't find the dto key in attributes, try to map incoming attribute['member'] to model._member. This is done to allow incoming attributes to map to private members on models.
            const publicRelationKey = potentialPrivateRelation && attributes.hasOwnProperty(relationKey.slice(1,relationKey.length)) 
                ? relationKey.slice(1,relationKey.length) 
                : relationKey;

            if (!(publicRelationKey in attributes)) 
            {
                continue;
            }

            if (potentialPrivateRelation && !(relationKey in attributes))
            {
                this._privateDtoProps.add(relationKey);
            }

            // Map the relation if present on the input data and cast it to its defined relation class
            // Copy the raw relation value
            const rawRelation = $helpers.deepCopy( attributes[publicRelationKey] );

            // Cast single-model relation
            if ( $helpers.isObject(rawRelation) )
            {
                this[relationKey] = new constructor( rawRelation );
            }
            // Cast collection-model relation
            else if ( $helpers.isArray(rawRelation) )
            {
                this[relationKey] = rawRelation.map( (relation: any) => new constructor( relation ) );
            }
        }

        return this;
    }

    /**
     * Create a JSON payload from the model, keeping only its attributes and relations and stripping everything else.
     *
     * @returns {Object}
     */
    flush(): object {

        var attributes = this.flushAttributes();
        var relations = this.flushRelations();

        return Object.assign({}, attributes, relations);
    }

    /**
     * Create a simple Object containing only copies of the model's attributes.
     *
     * @returns {Object}
     */
    flushAttributes(): object {
        var flush = {};
        
        for (const key in this._dto.attributes)
        {
            const shouldRemovePrivatePrefix = this._privateDtoProps.has(key);
            const publicProperty = shouldRemovePrivatePrefix ? key.slice(1,key.length) : key;
            flush[publicProperty] = $helpers.deepCopy(this[publicProperty]);
        }

        return flush;
    }

    /**
     * Create a simple Object containing only copies of the model's relations.
     *
     * @returns {Object}
     */
    flushRelations(): object {
        var flush = {};

        for (const property in this._dto.relations)
        {
            // If an incoming property was cast to private, access that property using a getter. 
            const shouldRemovePrivatePrefix = this._privateDtoProps.has(property);
            const publicProperty = shouldRemovePrivatePrefix ? property.slice(1,property.length) : property;

            // Flush single-model relation
            if ( $helpers.isObject(this[publicProperty]) )
            {
                flush[publicProperty] = this[publicProperty].flush();
            }
            // Flush collection-model relation
            else if ( $helpers.isArray(this[publicProperty]) )
            {
                flush[publicProperty] = this[publicProperty].map( (relation: BaseModel) => relation.flush() )
            }
            // Otherwise just copy the relation value if it isn't an instance of BaseModel (such as null values)
            else 
            {
                flush[publicProperty] = $helpers.deepCopy( this[publicProperty] );
            }
        }

        return flush;
    }

    /**
     * Create a copy of the model using only attributes and relations
     *
     * @returns {Object}
     */
    copy(): this {
        const model = this
        // Force cast-constructor to make it newable (not sure how to feel about this)
        const constructor = this.constructor as { new(attributes: object): typeof model }
        return new constructor(this.flush());
    }

    /**
     * Create DateTimeUtilities interfaces for the model's configured timestamp attributes.
     *
     */
    createModelDateAccessors() {

        // Grab the map from the prototype
        if ( !this.hasOwnProperty("_dates") )
        {
            this._dates = Object.assign({}, this._dates);
        }

        for (const property in this._dates)
        {
            // Skip uninitialized timestamp attributes
            if ( !this.hasOwnProperty(property) ) { continue; }

            // Skip invalid timestamp attributes (only null and integers accepted)
            if ( !$helpers.isNull(this[property]) && !$helpers.isInteger(this[property]) )
            {
                console.error('[Model:BaseModel] cannot create DateTimeUtility for attribute "' + property 
                    + '" with value "' + this[property] + '"');

                continue;
            }

            // Create utility for timestamp
            this._dates[property] = new DateTimeUtility(this, property);
        }
    }

    /**
     * Verify if the current model seems to exist.
     *
     * @returns {boolean}
     */
    exists(): boolean {
        // Model is determined to exist if its ID (type: uuid) is a non-empty string
        return $helpers.isString(this.id) && this.id.length > 0;
    }

}
