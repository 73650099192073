
<mat-button-toggle-group 
    [(ngModel)]="this.isFlattenedView"
    (change)="toggle()">
    <mat-button-toggle [value]="false">
        <mat-icon color="primary">{{ groupedViewIcon }}</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [value]="true">
        <mat-icon color="primary">{{ flattenedViewIcon }}</mat-icon>
    </mat-button-toggle>
</mat-button-toggle-group>