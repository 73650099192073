import { Ng2StateDeclaration } from '@uirouter/angular';
import { ServiceIndexComponent } from './service-index.component';


export const ServiceIndexState: Ng2StateDeclaration = {
  name: 'protected.services',
  url: '/services',
  component: ServiceIndexComponent,
  data: { 
    pageTitle: 'Service Index', 
    requiresAuth: true,
  },
}