<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill *ngIf="!this.loaded">
    <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
</div>

<div class="print-wrapper" *ngIf="this.loaded" cdkScrollable>

<div class="document document--dispatch">

    <!-- Dispatch Type -->
    <div class="dispatch__header {{ this.getHeaderClass() }}">
        <span>{{ this.getDisplayType() }}</span>
    </div>

    <!-- Dispatch Header -->
    <div class="mdc-layout-grid mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
            <div class="document__company-info mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell--span-4">
                    <div><strong>{{ this.dispatch.work_order.branch.name }}</strong></div>
                    <div>{{ this.dispatch.work_order.branch.address_1 }}</div>
                    <div *ngIf="this.dispatch.work_order.branch.address_2">{{ this.dispatch.work_order.branch.address_2 }}</div>
                    <div>{{ this.dispatch.work_order.branch.city }}, {{ this.dispatch.work_order.branch.region }}, {{ this.dispatch.work_order.branch.postal_code }}</div>
                    <div *ngIf="this.dispatch.work_order.branch.phone"><strong>Telephone:</strong> {{ this.dispatch.work_order.branch.phone }}</div>
                    <div *ngIf="this.dispatch.work_order.branch.toll_free"><strong>Toll Free:</strong> {{ this.dispatch.work_order.branch.toll_free }}</div>
                    <div *ngIf="this.dispatch.work_order.branch.fax"><strong>Fax:</strong> {{ this.dispatch.work_order.branch.fax }}</div>
                    <div *ngIf="this.dispatch.work_order.branch.email"><strong>Email:</strong> {{ this.dispatch.work_order.branch.email }}</div>
                    <div *ngIf="this.dispatch.work_order.branch.website"><strong>Website:</strong> {{ this.dispatch.work_order.branch.website }}</div>
                </div>
                <div class="mdc-layout-grid__cell--span-8">
                    <img class="document__logo" src="/assets/img/{{ this.company.logo }}" alt="Logo">
                </div>
            </div>
            <div class=" document__client-info document__client-info--list mdc-layout-grid__inner mdc-layout-grid__inner--condensed">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                    <span class="capitalize">{{ this.getDisplayType() }}</span> date:
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                    <span class="document__subhead" [class.dispatch-details__dispatch-time--time-specified]="this.dispatch.time_specified" >
                        <strong>{{ this.dispatch.scheduled_at | dateFilter:'dateMedium' }}</strong>
                        <span *ngIf="this.dispatch.time_specified"><strong> at {{ this.dispatch.scheduled_at | dateFilter:'time' }}</strong></span>
                    </span>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                    <span class="capitalize">{{ this.getDisplayType() }}</span> location:
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                    <span class="document__subhead"><strong>{{ this.dispatch.location }}</strong></span>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                    Customer:
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                    <span class="document__subhead"><strong>{{ this.dispatch.work_order.client.name }}</strong></span>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                     *ngIf="this.dispatch.work_order.ordered_by" >
                    Ordered by:
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9"
                     *ngIf="this.dispatch.work_order.ordered_by" >
                    <span class="document__subhead"><strong>{{ this.dispatch.work_order.ordered_by }}</strong></span>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                     *ngIf="this.dispatch.site_contact" >
                    Site contact:
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9"
                     *ngIf="this.dispatch.site_contact" >
                    <span class="document__subhead"><strong>{{ this.dispatch.site_contact }}</strong></span>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                     *ngIf="this.dispatch.work_order.external_ids.length" >
                    Customer reference:
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9"
                     *ngIf="this.dispatch.work_order.external_ids.length" >
                    <span class="document__subhead" *ngFor="let external_id of this.dispatch.work_order.external_ids; last as isLast">
                        <strong>{{ external_id.value }}{{ !isLast ? ', ' : '' }}</strong>
                    </span>
                </div>
            </div>
        </div>
        <div class="document__info mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
            <div class="document-info__section document-info__invoice-number">
                    <div class="document-info__label">Work Order #</div>
                    <div class="document-info__value">{{ this.dispatch.work_order.serial_id }}</div>
            </div>
            <div class="document-info__section document-info__wo-number">
                <div class="document-info__label">Dispatch #</div>
                <div class="document-info__value">{{ this.dispatch.formatted_serial_id }}</div>
            </div>
            
            <div class="document-info__section invoice-info__dates">
                <div class="document-info__label">Dispatched By</div>
                <div class="document-info__value">{{ this.dispatch.dispatcher.fullname }}</div>
                <div *ngIf="this.dispatch.requester">
                    <div class="document-info__label">Requested By</div>
                    <div class="document-info__value">{{ this.dispatch.requester.fullname }}</div>
                </div>
                <div class="document-info__label">Dispatched</div>
                <div class="document-info__value">{{ this.dispatch.dispatched_at | dateFilter:'dateTimeMedium' }}</div>
                <div class="document-info__label">Printed</div>
                <div class="document-info__value">{{ this.now() | dateFilter:'dateTimeMedium' }}</div>
            </div>
        </div>
    </div>

    <!-- Dispatch Notes -->
    <div class="document__section" *ngIf="this.dispatchNotes.length">
        <div class="document-section__header">
            <h2 class="document-section__title">Dispatch Notes</h2>
        </div>
        <table class="bl-table">
            <tbody>
                <tr *ngFor="let note of this.dispatchNotes">
                    <td class="bl-datetime">{{ note.noted_at | dateFilter:'dateTimeMedium' }}</td>
                    <td class="multiline">{{ note.content }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-container  *ngFor="let request of this.dispatch.rental_requests">
    <div class="document__section"  *ngIf="request.rentals.length > 0">
        <div class="document-section__header mdc-layout-grid mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-6">
                <h2 class="document-section__title">Rental ({{ request.rentals.length }} Items)</h2>
                <div>{{ request.source.formatted_serial_id }}</div>
            </div>
            <!-- <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-bottom">
                <div class="document-section__detail-title">Location</div>
                <div>{{ request.location }}</div>
            </div> -->
        </div>

        <!-- Rental Notes -->
        <div *ngIf="request.source.dispatch_notes.length">
            <h3 class="document-section__subhead">Notes</h3>
            <table class="bl-table">
                <tbody>
                    <tr *ngFor="note in request.source.dispatch_notes">
                        <td class="bl-datetime">{{ note.noted_at | dateFilter:'dateTimeMedium' }}</td>
                        <td class="multiline">{{ note.content }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Ongoing rentals -->
        <h3 class="document-section__subhead">Rentals</h3>
        <table class="bl-table">
            <thead>
            <tr>
                <th class="bl-item">Equipment</th>
                <th>Details</th>
                <th class="bl-numeric bl-numeric--small">Quantity</th>
                <th class="bl-numeric">{{ this.dispatch.type == 'pickup' ? 'Retrieved' : 'Delivered' }}</th>
            </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let rental of request.rentals">
                    <tr *ngIf="rental.dispatched_quantity > 0">
                        <td class="bl-item">{{ rental.source.name }}<br>{{ rental.source.detail }}</td>
                        <td>{{ rental.source.user_detail }}</td>
                        <td class="bl-numeric bl-numeric--small">{{ rental.dispatched_quantity }}</td>
                        <td class="bl-numeric">{{ this.getCapturedRentalQuantity(request, rental) }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    </ng-container>

    <div class="document__section" *ngIf="this.dispatch.hasServices()" >
        <div class="document-section__header mdc-layout-grid mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-6">
                <h2 class="document-section__title uppercase">Services</h2>
            </div>
            <!-- <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-bottom">
                <div class="document-section__detail-title">Location</div>
                <div>{{ service.source.location }}</div>
            </div> -->
        </div>

        <!-- Per-hour Services -->
        <table class="bl-table" 
               [class.bl-table-mb]="this.dispatch.hasPerHourServices() && this.dispatch.hasPerUnitServices()" 
               *ngIf="this.dispatch.hasPerHourServices()">
            <thead>
                <tr>
                    <th class="bl-item">Name</th>
                    <th class="bl-datetime">Scheduled</th>
                    <th class="bl-numeric">Travel Time</th>
                    <th class="bl-numeric bl-numeric--small">Quantity</th>
                    <th class="bl-datetime">Start</th>
                    <th class="bl-datetime">End</th>
                </tr>
            </thead>

            <tbody *ngFor="let service of this.perHourServices">
                <tr>
                    <td>
                        <span class="bl-item uppercase">{{ service.source.name }}</span><br>
                        <span class="document__caption">{{ service.source.formatted_serial_id }}</span>
                    </td>
                    <td class="bl-date">
                        <span>{{ service.dispatched_started_at | dateFilter:'dateTimeMedium' }}</span><br>
                        <span>{{ service.dispatched_ended_at | dateFilter:'dateTimeMedium' }}</span>
                    </td>
                    <td class="bl-numeric">{{ this.getTravelTime(service) | timeFilter }}</td>
                    <td class="bl-numeric bl-numeric--small">{{ service.dispatched_quantity }}</td>
                    <td class="bl-date">{{ this.getServiceStartedAt(service) | dateFilter:'dateTimeMedium' }}</td>
                    <td class="bl-date">{{ this.getServiceEndedAt(service) | dateFilter:'dateTimeMedium' }}</td>
                </tr>
                <tr *ngFor="let note of service.source.dispatch_notes">
                    <td colspan="7" class="document-table__inline-note">
                        <span class="bl-datetime">{{ note.noted_at | dateFilter:'dateTimeMedium' }}</span>
                        <p class="inline-note__content multiline">{{ note.content }}</p>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Per-unit Services -->
        <table class="bl-table" *ngIf="this.dispatch.hasPerUnitServices()">
            <thead>
                <tr>
                    <th class="bl-item">Name</th>
                    <th class="bl-datetime">Scheduled</th>
                    <!-- <th class="bl-numeric">Travel Time</th> -->
                    <th class="bl-numeric bl-numeric--small">Quantity</th>
                    <th class="bl-numeric bl-numeric--small">Captured Quantity</th>
                </tr>
            </thead>

            <tbody *ngFor="let service of this.perUnitServices">
                <tr>
                    <td>
                        <span class="bl-item uppercase">{{ service.source.name }}</span><br>
                        <span class="document__caption">{{ service.source.formatted_serial_id }}</span>
                    </td>
                    <td class="bl-date">
                        <span>{{ service.source.started_at | dateFilter:'dateTimeMedium' }}</span><br>
                        <span>{{ service.source.ended_at | dateFilter:'dateTimeMedium' }}</span>
                    </td>
                    <!-- <td class="bl-numeric">{{ this.getTravelTime(service) | timeFilter }}</td> -->
                    <td class="bl-numeric bl-numeric--small">{{ service.dispatched_quantity }}</td>
                    <td class="bl-numeric bl-numeric--small">{{ this.getCapturedServiceQuantity(service) }}</td>
                </tr>
                <tr *ngFor="let note of service.source.dispatch_notes">
                    <td colspan="7" class="document-table__inline-note">
                        <span class="bl-datetime">{{ note.noted_at | dateFilter:'dateTimeMedium' }}</span>
                        <p class="inline-note__content multiline">{{ note.content }}</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Dispatch Fine Print -->
    <div class="document__legalese">
        <p>This agreement is subject to the condition that the placing and use of all equipment after delivery to customer is subject to the sole control and direction of the customer, and BeaconLite Ltd. does not assume any responsibility or liability to the public or the customer for operation of said equipment. Customer shall be liable for loss of equipment.</p>
    </div>

    <!-- Dispatch Signature Line -->
    <div class="document__signatures mdc-layout-grid mdc-layout-grid__inner">
        <ng-container *ngFor="let signature of this.dispatch.signatures">
            <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-5">
                <span class="signature__label">Client Signature:</span>
                <span class="signature__line signature__line--signature">
                    <img class="captured-signature" [src]="signature.attachment.url">
                </span>
            </div>
            <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                <span class="signature__label">Client Name:</span><span class="signature__line ">{{ signature.name }}</span>
            </div>
            <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                <span class="signature__label">Date & Time:</span><span class="signature__line ">{{ signature.captured_at | dateFilter: 'dateTimeShort'}}</span>
            </div>
        </ng-container>
        <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-5" *ngIf="!this.dispatch.signatures.length">
            <span class="signature__label">Client Signature:</span><span class="signature__line ">&nbsp;</span>
        </div>
        <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-4" *ngIf="!this.dispatch.signatures.length">
            <span class="signature__label">Client Name:</span><span class="signature__line ">&nbsp;</span>
        </div>
        <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-3" *ngIf="!this.dispatch.signatures.length">
            <span class="signature__label">Date & Time:</span><span class="signature__line ">&nbsp;</span>
        </div>
        <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
            <span class="signature__label">Performed By:</span>
            <span class="signature__line ">
                <span *ngFor="let employee of this.dispatch.employees; last as isLast">{{ employee.fullname }}{{ !isLast ? ', ' : '' }}</span>
                &nbsp;
            </span>
        </div>
        <div class="signature mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
            <span class="signature__label">Truck #:</span>
            <span class="signature__line ">{{ this.dispatch.truck_identifier }}&nbsp;</span>
        </div>
        <div class="signature signature--notes mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
            <div>
                <span class="signature__label">Field Notes:</span><span>&nbsp;</span>
            </div>
            <div class="signature signature__line" *ngFor="let note of this.dispatch.field_notes">
                <div class="signature__line--note-date">{{ note.noted_at | dateFilter: 'dateTimeMedium'}}</div>
                <div class="signature__line--note-content multiline">{{ note.content }}</div>
            </div>
            <div class="signature__line signature__line--fillable" *ngFor="let n of [].constructor(this.getLineCount()); let i = index; trackBy: i">&nbsp;</div>
        </div>
    </div>

</div>

</div>
