<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>

    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Work Order Editor</h2>
        <mat-icon *ngIf="workOrder.locked">lock_outline</mat-icon>        
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="35">
                <mat-label>Branch</mat-label>
                <mat-select [(ngModel)]="workOrder.branch_id"
                    name="branchField"
                    #branchField="ngModel"
                    required
                    [disabled]="workOrder.locked">
                    <mat-option *ngFor="let branch of branches" [value]="branch.id">
                        {{ branch.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="branchField.hasError('required')">Branch is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="35">
                <mat-label>Assigned To</mat-label>
                <input type="text"
                    [(ngModel)]="employeeSearchText"
                    name="employeeSearchTextField"
                    #employeeSearchTextField="ngModel"
                    [matAutocomplete]="employeeAuto"
                    (ngModelChange)="onEmployeeSearchTextChange()"
                    (blur)="onEmployeeSearchTextBlur()"
                    matInput>
                <mat-autocomplete #employeeAuto="matAutocomplete"
                    (optionSelected)="onEmployeeSelected($event.option.value)"
                    [displayWith]="displaySelectedEmployee"
                    [autoActiveFirstOption]="true">
                    <mat-option *ngFor="let employee of promisedEmployees | async" [value]="employee">
                        {{ employee.fullname }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Internal Job Code</mat-label>
                <input type="text"
                    [(ngModel)]="workOrder.job_code"
                    name="jobCodeField"
                    #jobCodeField="ngModel"
                    matInput
                    maxlength="255"
                    [disabled]="workOrder.locked">
                <mat-hint align="end">{{ workOrder.job_code?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="75">
                <mat-label>Select Client</mat-label>
                <input type="text"
                    [(ngModel)]="clientSearchText"
                    name="clientSearchTextField"
                    #clientSearchTextField="ngModel"
                    [matAutocomplete]="clientAuto"
                    (ngModelChange)="onClientSearchTextChange()"
                    (blur)="onClientSearchTextBlur()"
                    cdkFocusInitial
                    minlength="2"
                    required
                    matInput
                    [disabled]="workOrder.locked">

                <mat-autocomplete #clientAuto="matAutocomplete"
                    (optionSelected)="onClientSelected($event.option.value)"
                    [displayWith]="displaySelectedClient"
                    [autoActiveFirstOption]="true">
                    <mat-option *ngFor="let client of promisedClients | async" [value]="client">
                        {{ client.name }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="clientSearchTextField.hasError('required')">Client is required.</mat-error>
                <mat-error *ngIf="clientSearchTextField.hasError('suspended')">Client is suspended.</mat-error>
                <mat-error *ngIf="clientSearchTextField.hasError('discarded')">Client is discarded.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Taxes</mat-label>
                <mat-select [(ngModel)]="workOrder.taxes"
                    name="taxesField"
                    #taxesField="ngModel"
                    required
                    [disabled]="workOrder.locked">
                    <mat-option *ngFor="let tax of taxes" [value]="tax.tag">
                        {{ tax.tag }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="taxesField.hasError('required')">Taxes are required.</mat-error>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="75">
                <mat-label>Location</mat-label>
                <input type="text"
                    matInput
                    [(ngModel)]="workOrder.location"
                    name="locationField"
                    #locationField="ngModel"
                    required
                    [disabled]="workOrder.locked"
                    maxlength="255">
                    <mat-hint align="end">{{ workOrder.location?.length || 0 }} / 255</mat-hint>
                    <mat-error *ngIf="locationField.hasError('required')">Location is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Date</mat-label>
                <input matInput 
                    [matDatepicker]="startedAtPicker" 
                    [(ngModel)]="workOrder._dates.started_at.date" 
                    [disabled]="workOrder.locked"
                    (focus)="startedAtPicker.open()"
                    name="startedAtField"
                    #startedAtField="ngModel">
                <mat-datepicker-toggle matSuffix [for]="startedAtPicker"></mat-datepicker-toggle>
                <mat-datepicker #startedAtPicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
                <mat-label>Main Contact</mat-label>
                <input type="text"
                    matInput
                    [(ngModel)]="workOrder.main_contact"
                    name="mainContactField"
                    #mainContactField="ngModel"
                    maxlength="255"
                    [disabled]="workOrder.locked">
                <mat-hint align="end">{{ workOrder.main_contact?.length || 0 }} / 255</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>Ordered By</mat-label>
                <input type="text"
                    matInput
                    [(ngModel)]="workOrder.ordered_by"
                    name="orderedByField"
                    #orderedByField="ngModel"
                    maxlength="255"
                    [disabled]="workOrder.locked">
                    <mat-hint align="end">{{ workOrder.ordered_by?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>
        
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Client Reference Numbers</mat-label>
                <mat-chip-list #externalIdsChips>
                    <mat-chip *ngFor="let externalId of workOrder.external_ids"
                        [removable]="true"
                        (removed)="removeExternalIdChip(externalId)">
                        {{ externalId.value }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input 
                        [matChipInputFor]="externalIdsChips"
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addExternalIdChip($event)">
                </mat-chip-list>
            </mat-form-field>
        </div>

        <mat-slide-toggle *ngIf="workOrder.canBeEstimate()" 
            [(ngModel)]="workOrder.estimate"
            name="estimateField">
            Estimate
        </mat-slide-toggle>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="saving">
            Save
        </button>
    </mat-dialog-actions>
</form>
