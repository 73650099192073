import { Component, Input } from '@angular/core';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';

@Component({
  selector: 'app-dispatch-schedule-single',
  templateUrl: './dispatch-schedule-single.component.html',
  styleUrls: ['./dispatch-schedule-single.component.scss']
})
export class DispatchScheduleSingleComponent {
  @Input() modelId: string;

  constructor(
    protected authService: AuthenticationService,
  ) { }
}
