import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../public.scss'],
})
export class LoginComponent implements OnInit {
  pending: boolean = false;
  company: any;
  user: any = {};
  @ViewChild('loginForm') loginForm: NgForm;

  constructor(
    protected authService: AuthenticationService,
    protected $state: StateService,
    protected $settings: SettingsService,
  ) {}

  ngOnInit(): void {
    this.company = this.$settings.get('company_config');
  }

  async onLogin(): Promise<any> 
  { 
    this.loginForm.controls.username.setErrors(null);
    this.loginForm.controls.password.setErrors(null);

    if (this.loginForm.invalid) return;

    this.pending = true;
    
    try
    {
      await this.authService.login(this.user.username, this.user.password);
    }
    catch (response)
    {
      this.loginForm.controls.username.setErrors({failed: true});
      this.loginForm.controls.password.setErrors({failed: true});

      console.error('[View:Login] login failed.')
    }
    finally
    {
      this.pending = false;
    }
  }
}
