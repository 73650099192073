import { Component, Inject, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Department } from '@beaconlite/models';
import { LineItemDefinition } from '@beaconlite/models/LineItemDefinition.model';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

export interface LineItemDefinitionEditorServiceData {
  original: LineItemDefinition,
  departments: Department[],
  onUpdate: (lineItemDefinition: LineItemDefinition) => Promise<void>
}

@Injectable({
  providedIn: 'root'
})
export class LineItemDefinitionEditorService {

  constructor(protected dialog: MatDialog) {}

  async open(data?: LineItemDefinitionEditorServiceData): Promise<LineItemDefinition> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data,
    }

    return this.dialog.open(LineItemDefinitionEditorComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-line-item-definition-editor',
  templateUrl: './line-item-definition-editor.component.html',
  styleUrls: ['./line-item-definition-editor.component.scss']
})
export class LineItemDefinitionEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @Input() modelId: string;

  original = this.data.original;
  departments = this.data.departments;
  onUpdate = this.data.onUpdate;

  loading = false;
  locked = false;
  lineItemDefinition: LineItemDefinition;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: LineItemDefinitionEditorServiceData,
    public dialogRef: MatDialogRef<LineItemDefinitionEditorComponent>,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.lineItemDefinition = this.original.copy();
  }

  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.lineItemDefinition.discarded;
  }

  async save(): Promise<LineItemDefinition>
  {
    if (this.editorForm.invalid) { return; }

    try
    {
      this.lockAndLoad();
      await this.onUpdate(this.lineItemDefinition);
      this.snackbarNotification.saved();
      this.dialogRef.close();
    }
    finally
    {
      this.unlockAndUnload();
    }

    return (this.lineItemDefinition);
  }

  async onClose(): Promise<void>
  {
    this.dialogRef.close();
  }
}
