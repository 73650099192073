import { Account } from "./Account.model";
import { Attachment } from "./Attachment.model";
import { BaseModel } from "./Base.model";
import { dto } from './mixins/Dto.decorators'
import { HttpResponse } from "../services/network/http-response";
import { RouteMap } from "../services/network/route-map.service";
import { AppInjector } from "../services/app-injector.service";

export class Document extends BaseModel {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() uploaded_by?: string = null;

    @dto(Account) uploader: Account = null;
    @dto(Attachment) attachment: Attachment = null;

    // TODO: figure out what this is
    @dto() associations = [];

    static async get(id: string): Promise<Document> 
    {
        const response = await AppInjector.get(RouteMap).getDocument(id);
        return new Document(response.data()); 
    }

    static async getUploadUrl(): Promise<any> 
    {
        const response = await AppInjector.get(RouteMap).getDocumentUploadUrl();
        return response.data();
    }

    async save(): Promise<Document> 
    {
        if (! this.exists()) throw "Invalid document creation method";

        const response = await this.routeMap.updateDocument(this.id, this.flush());
        return this.map(response.data());
    }

    async delete(): Promise<HttpResponse> 
    {
        return this.routeMap.deleteDocument(this.id);
    }
}
