import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Department } from '@beaconlite/models';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { GroupDefinition } from '@beaconlite/models/contracts/GroupDefinition';
import { GroupDefinitionEditorService } from './group-definition-editor/group-definition-editor.component';

@Component({
    selector: 'app-group-single',
    templateUrl: './group-single.component.html',
    styleUrls: ['./group-single.component.scss']
})
export class GroupSingleComponent implements OnInit {
    @Input() original: GroupDefinition;
    @Input() stateOnClose: string;
    @Output() updatedGroupDefinition = new EventEmitter<GroupDefinition>();
    @Output() doneLoading = new EventEmitter<boolean>();

    departments: Department[];
    departmentName: string;
    loading = true;
    locked = true;

    groupItemTableColumns = ["name", "quantity"];

    constructor(
        protected appData: AppData,
        protected snackbarNotification: SnackbarNotificationService,
        protected dialogNotification: DialogNotificationService,
        protected $state: StateService,
        protected groupDefinitionEditorService: GroupDefinitionEditorService,
    ) { }

    async ngOnInit(): Promise<void>
    {
        this.lockAndLoad();
        try
        {
            [this.departments] = await Promise.all([
                this.appData.departments.getAll(),
                this._setThumbnailUrl()]);
            this.departments?.sort(orderBy('name'));
            this.departmentName = this._getDepartmentName(this.original.department_id);
        }
        finally
        {
            this.unlockAndUnload();
        }
    }

    lockAndLoad(): void
    {
        this.loading = true;
        this.locked = true;
    }

    unlockAndUnload(): void
    {
        this.loading = false;
        this.locked = !!this.original.deleted_at;
        this.doneLoading.emit(this.locked);
    }
    
    protected async _setThumbnailUrl(): Promise<void>
    {
        if (!!this.original.deleted_at || this.original.thumbnail === null) { return; }

        await this.original.thumbnail.small.getUrl();                
    }

    protected _getDepartmentName(targetId: string): string 
    {
        return this.departments.find(({ id }) => id === targetId)['name'];
    }

    async edit(): Promise<void>
    {
        await this.groupDefinitionEditorService.open({
            original: this.original,
            departments: this.departments,
            onUpdate: this.updateGoupDef
        });
    }

    updateGoupDef = async (group: GroupDefinition): Promise<void> =>
    {
        await group.save();
        this.original = group.copy();
        await this._setThumbnailUrl()
        this.updatedGroupDefinition.emit(this.original);
    }

    async delete(): Promise<void>
    {
        if (! await this.dialogNotification.deleteConfirm()) { return; }

        this.lockAndLoad();

        try
        {
            await this.original.delete();
            this.$state.go(this.stateOnClose);
        }
        finally
        {
            this.unlockAndUnload();
        }
    }

    protected async _reloadOriginal(): Promise<void>
    {
        await this.original.reload();
    }
}
