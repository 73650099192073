import { Component, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Variant, VariantRental, VariantPropertyDefinition, VariantProperty, Thumbnail } from '@beaconlite/models';
import { VariantDefinition } from '@beaconlite/models/contracts/VariantDefinition.interface';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { VariantOption } from '@beaconlite/models/VariantOption.model';

interface VariantEditorData {
    original: Variant,
    itemDefinition: VariantDefinition,
    onVariantUpdate: (variant: Variant) => Promise<void>;
    onVariantRemove: (variant: Variant) => Promise<void>;
}

@Injectable({
    providedIn: 'root'
})
export class VariantEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: VariantEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            width: '100%',
            maxWidth: 800,
            data
          }

        return this.dialog.open(VariantEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}

@Component({
    selector: 'app-variant-editor',
    templateUrl: './variant-editor.component.html',
})
export class VariantEditorComponent implements OnInit {

    @ViewChild('editorForm') editorForm: NgForm;

    itemDefinition = this.data.itemDefinition;
    original = this.data.original;
    onUpdate = this.data.onVariantUpdate;
    onRemove = this.data.onVariantRemove;

    loading = false;
    variant: Variant;
    pricingTypes: Array<string> = [];

    uploadThumbnail: () => Promise<Thumbnail>;
    removeThumbnail: () => Promise<void>;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: VariantEditorData,
        public dialogRef: MatDialogRef<VariantEditorComponent>,
        protected snackbarNotification: SnackbarNotificationService,
        protected dialogNotification: DialogNotificationService,
    ){}

    async ngOnInit(): Promise<void>
    {
        this.variant = this.original.copy();
        this._sortItems();
        this.pricingTypes = this.itemDefinition.pricingTypes;

        if (!this.original.exists())
        {
            this.variant.default_pricing_type = this.pricingTypes[0];
            this.variant.addProps(this.itemDefinition.variant_property_definitions);
        }
    }

    async remove()
    {
        if (! await this.dialogNotification.removeConfirm()) { return; }

        this.loading = true;

        try
        {
            await this.onRemove(this.variant);
            this.snackbarNotification.saved();
            this.dialogRef.close();
        }
        finally
        {
            this.loading = false;
        }
    }

    async save()
    {
        if (this.editorForm.invalid) return;
        
        this.loading = true;

        try
        {
            await this.uploadThumbnail?.();
            await this.removeThumbnail?.();
            await this.onUpdate(this.variant);
            this.snackbarNotification.saved();
            this.dialogRef.close();
        }
        finally
        {
            this.loading = false;
        }
    }

    formatFieldLabel(field: string): string
    {
        return field.replace(/_/g, ' ');
    }

    getPropFromDefinition(def: VariantPropertyDefinition): VariantProperty
    {
        return this.variant.properties.find(
            (prop: VariantProperty) => prop.variant_property_definition_id === def.id
        );
    }

    calculateRateVariantFields(): void
    {
        if (!this.itemDefinition.variant_rates_enabled) { return; }

        this.itemDefinition.variant_property_fields.forEach(field => {
            this.variant[field] = this._getSelectedOptions()
                .reduce((accumulator, opt) => accumulator + opt[field], 0);
        });
    }

    protected _getSelectedOptions(): VariantOption[]
    {
        let propDefs: VariantPropertyDefinition[] = this.itemDefinition.variant_property_definitions;

        let dropdownProps: VariantProperty[] = this.variant.properties
            .filter(prop => {return prop.definition.isDropdown()});

        let selectedOptions = dropdownProps
            .flatMap( prop => {
                let propDef = propDefs.find(def => def.isDropdown() && def.id === prop.definition.id);
                if (!propDef) { return null; }

                let option = propDef.options.find(opt => opt.id === prop.value);
                if (!option) { return null; }

                return option;
            })
            .filter( flatOption => !!flatOption );

        return selectedOptions;
    }

    shouldDisplayQuantity(): boolean
    {
        return this.variant instanceof VariantRental;
    }

    onThumbnailSelected(uploadThumbnail: () => Promise<Thumbnail>)
    {
        this.uploadThumbnail = uploadThumbnail;
        this.removeThumbnail = null;
    }

    onThumbnailRemoved(removeThumbnail: () => Promise<void>)
    {
      this.removeThumbnail = removeThumbnail;
      this.uploadThumbnail = null;
      this.variant.thumbnail = null;
    }

    onThumbnailUpdated(thumbnail: Thumbnail): void
    {
      this.variant.thumbnail = thumbnail;
    }
  
    protected _sortItems(): void
    {
        this.itemDefinition.variant_property_definitions.forEach(
            prop => prop.options.sort(orderBy('ordinal'))            
        ) 
    }

    getPriceTypeLabel(priceType): string
    {
        return this.itemDefinition.getPriceTypeLabel(priceType);
    }
}
