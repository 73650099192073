<mat-toolbar [fxHide]="this.isAdmin || this.loading">
    <div fxFlex>&nbsp;</div>
    <button mat-icon-button aria-label="Close" (click)="this.onClose()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div class="slip-container" fxLayout="column" fxLayoutAlign=" center">
    <app-dispatch-slip class="large-card"
        *ngIf="this.dispatch && this.workOrder"
        [dispatch]="this.dispatch"
        [workOrder]="this.workOrder"
        [onUpdate]="this.onDispatchUpdate">
    </app-dispatch-slip>
</div>
