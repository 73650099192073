<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.onSave()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Invoice Settings</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Invoice Grace Period</mat-label>
                <input type="number"
                [(ngModel)]="this.preferences.invoice.grace_period"
                name="invoiceGracePeriod"
                #invoiceGracePeriod="ngModel"
                [minNum]="1"
                [maxNum]="90"
                matInput
                required>
                <mat-error *ngIf="this.invoiceGracePeriod.hasError('required')">Grace period is required</mat-error>
                <mat-error *ngIf="this.invoiceGracePeriod.hasError('minNum')">Grace period must be at least 1 day.</mat-error>
                <mat-error *ngIf="this.invoiceGracePeriod.hasError('maxNum')">Grace period cannot be more than 90 days.</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Invoice Interval</mat-label>
                <input type="number"
                [(ngModel)]="this.preferences.invoice.invoice_interval"
                name="invoiceInterval"
                #invoiceInterval="ngModel"
                [minNum]="1"
                matInput
                required>
                <mat-error *ngIf="this.invoiceInterval.hasError('required')">Invoice Interval length is required</mat-error>
                <mat-error *ngIf="this.invoiceInterval.hasError('minNum')">MInvoice Interval must be at least 1 day.</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner mode="indeterminate" *ngIf="this.loading" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>
