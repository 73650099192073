import { Account } from "./Account.model";
import { BaseModel } from "./Base.model";
import { Note } from "./Note.model";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { orderBy } from "@beaconlite/utilities/Sort.utility";

export type IssueType = 'quarantine' | 'major';

export class Issue extends BaseModel {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    static readonly SEVERITY_QUARANTINE = 'quarantine';
    static readonly SEVERITY_MAJOR = 'major';

    @dto() id?: string = null;
    @dto() work_order_id?: string = null;
    @dto() severity?: IssueType = null;
    @dto() description?: string = null;
    @dto() created_by?: number = null;
    @dto() @date resolved_at?: number = null;
    @dto() resolved_by?: string = null;

    @dto(Account) issuer?: Account = null;
    @dto(Account) resolver?: Account = null;
    @dto(Note) notes?: Note[] = [];

    get severity_display_name(): string
    {
        if (this.severity === Issue.SEVERITY_MAJOR)
        {
            return 'flagged';
        }

        if (this.severity === Issue.SEVERITY_QUARANTINE)
        {
            return 'quarantined';
        }

        return '';
    }

    async save(): Promise<Issue> 
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateIssue(this.id, this.flush())
            : await this.routeMap.createIssue(this.flush());

        return this.map(response.data());
    }

    async resolve(): Promise<Issue> 
    {
        const response = await this.routeMap.resolveIssue(this.id);
        return this.map(response.data());
    }

    async unresolve(): Promise<Issue> 
    {
        const response = await this.routeMap.unresolveIssue(this.id);
        return this.map(response.data());
    }

    async discard(): Promise<Issue> 
    {
        const response = await this.routeMap.discardIssue(this.id);
        return this.map(response.data());
    }

    isQuarantine(): boolean
    {
        return this.severity === Issue.SEVERITY_QUARANTINE;
    }
    
    isMajor(): boolean
    {
        return this.severity === Issue.SEVERITY_MAJOR;
    }

    addNote(note: Note = new Note({})): Note
    {
        this.notes.unshift(note);

        this.notes.sort( orderBy('created_at') );

        return note;
    }

    removeNote(note: Note): void
    {
        const index = this.notes.indexOf(note);

        if (index >= 0)
        {
            this.notes.splice(index, 1);
        }
    }

    hasNotes(): boolean
    {
        return !!this.notes.length
    }
}