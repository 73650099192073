<mat-table class="mat-selectable-table" [dataSource]="charges">
    <ng-container matColumnDef="type">
        <mat-header-cell fxFlex="15" *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell fxFlex="15" *matCellDef="let charge">{{ charge.displayType() }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="label">
        <mat-header-cell fxFlex="15" *matHeaderCellDef>Label</mat-header-cell>
        <mat-cell fxFlex="15" *matCellDef="let charge">{{ charge.label }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
        <mat-cell *matCellDef="let charge">{{ charge.description }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="value">
        <mat-header-cell fxFlex="15" *matHeaderCellDef>Value</mat-header-cell>
        <mat-cell fxFlex="15" *matCellDef="let charge">
            <span [fxShow]="charge.percent">{{ charge.percent_value | percentFilter }}</span>
            <span [fxHide]="charge.percent">{{ charge.flat_value | currencyFilter }}</span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="chargesTableColumns"></mat-header-row>
    <mat-row *matRowDef="let charge; columns: chargesTableColumns" (click)="onChargeSelected(charge)"></mat-row>
</mat-table>
