import { Attribute, Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[valueMustMatch][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ValueMustMatchValidatorDirective, multi: true }]
})
export class ValueMustMatchValidatorDirective implements Validator {
    constructor(
        @Attribute('valueMustMatch') protected mustMatch: string
    ) {}

    validate(control: AbstractControl) {

        const mustMatch = control.value;
        const mustMatchTo = control.root.get(this.mustMatch).value;

        const isValid = mustMatch === mustMatchTo;

        return isValid
            ? null
            : { valueMustMatch: true };
    }
}
