import { LoginComponent } from './login.component';
import { Ng2StateDeclaration } from '@uirouter/angular';

/**
 * This state allows the user to set their application preferences
 */
export const LoginState: Ng2StateDeclaration = {
  
  name: 'login',
  url: '/login',
  component: LoginComponent,
  // Used by the transitionAuthHook
  data: { requiresAuth: false }
}
