import { Component, Input, OnInit } from '@angular/core';
import { RentalOverrideEditorService } from './rental-override-editor/rental-override-editor.service';
import { Branch, Client, RentalOverride, RentalDefinition } from '@beaconlite/models';
import { prefs } from "@beaconlite/services/preferences.service";

@Component({
  selector: 'app-rental-overrides',
  templateUrl: './rental-overrides.component.html',
  styleUrls: ['./rental-overrides.component.scss']
})
export class RentalOverridesComponent implements OnInit {

  @Input('overridableModel') model: Branch | Client;
  @Input('modelLabel') label: string;
  @Input() onRentalUpdate: (override: RentalOverride, updatedOverride: RentalOverride) => Promise<void>; 
  @Input() onRentalRemove: (override: RentalOverride) => Promise<void>;

  weekThreshold: number;
  monthThreshold: number;

  rateTableColumns = ['item', 'daily-rate', 'weekly', 'monthly', 'replacement-charge'];
  percentTableColumns = ['item', 'type', 'percent', 'replacement-charge'];

  constructor(protected rentalOverrideEditor: RentalOverrideEditorService) { }

  async ngOnInit(): Promise<void> {
    this.weekThreshold = await prefs('pricing.week_threshold');
    this.monthThreshold = await prefs('pricing.month_threshold');
  }

  async onEdit(override: RentalOverride = new RentalOverride({})): Promise<void>
  {
    await this.rentalOverrideEditor.open({
      original: override,
      onRentalUpdate: this.onRentalUpdate,
      onRentalRemove: this.onRentalRemove, 
    })
  }

  calculateRate(dailyRate: number, discount: number, days: number): number
  {
    return RentalDefinition.calculateRateFromDiscount(dailyRate, discount, days);
  }

  hasRateOverrides()
  {
    return !!this.model.rental_rate_overrides.length;
  }

  hasPercentOverrides()
  {
    return !!this.model.rental_percent_overrides.length;
  }
}
