import { Injectable } from '@angular/core';
import { Helpers as $helpers } from './helpers.service'
import { HttpResponse } from './network/http-response';

@Injectable({
  providedIn: 'root'
})
export class FileDownload {

  constructor() { }

  /**
   * Process response as a "Content-Disposition:attachment;" download from the API.
   * Trigger the browser download prompt
   *
   * @param {HttpResponse} response
   * @returns {void}
   */
  asAttachment(response: HttpResponse): void {
    // Create a dataUrl representing the blob 
    const contentType = response.xhrResponse.headers()['content-type'];
    const blob = new Blob([response.data()], {type: contentType});                
    const dataUrl = window.URL.createObjectURL(blob);
    
    // Grab the filename from the headers
    let filename: any;
    const contentDisposition = response.xhrResponse.headers()['content-disposition'];

    if( !contentDisposition )
    {
        console.warn("Missing Content-Disposition header. \n Generating random filename.")
        filename = $helpers.generateDirtyId(10);
    }
    else
    {
        filename = contentDisposition.match(/filename=['"]?([^;'"\n]*)/)[1];
    }

    this.triggerDownload(filename, dataUrl);

    // Release the reference to the file by revoking the Object URL
    window.URL.revokeObjectURL(dataUrl);
  }

  /**
   * Process response as base64 download from the API
   * Trigger the browser download prompt
   *
   * @param {HttpResponse} response
   * @returns {void}
   */
  asBase64(response: HttpResponse): void {
      const data = response.data();
      this.triggerDownload(data.filename, data.data_url);
  }

  protected triggerDownload(filename: any, dataUrl: string): void {
      // Create a link element
      const a = document.createElement("a") as any;
      document.body.appendChild(a);
      // Hide it
      a.style = "display: none";
      // Point it towards the data url
      a.href = dataUrl;
      // Set the filename
      a.download = filename
      // Trigger the download
      a.click();
      // Remove the link
      document.body.removeChild(a);
  }
}
