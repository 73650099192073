import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ChargeEditorService } from '@beaconlite/components/editors/charge-editor/charge-editor.service';
import { NoteEditorService } from '@beaconlite/components/editors/note-editor/note-editor.service';
import { WorkOrder } from '@beaconlite/models';
import { DateFilterPipe } from '@beaconlite/pipes/date-filter.pipe';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { LineItemRequest } from '@beaconlite/models/LineItemRequest.model';
import { LineItemRequestEditorService } from '@beaconlite/views/protected/work-orders/work-order-single/tab-line-items/line-item-request-editor/line-item-request-editor.service';
import { LineItemEditorService } from '../line-item-editor/line-item-editor.service';
import { LineItem } from '@beaconlite/models/LineItem.model';

@Component({
  selector: 'line-item-request-component',
  templateUrl: './line-item-request.component.html',
  styleUrls: ['./line-item-request.component.scss']
})
export class LineItemRequestComponent implements OnInit, DoCheck {
  @ViewChild('requestItemTable') requestItemTable: MatTable<LineItem[]>;

  @Input() workOrder: WorkOrder;
  @Input() request: LineItemRequest;
  @Input() shouldShowDepartment: boolean;
  @Input() onRequestUpdated: () => Promise<void>;

  minimumDate: Date = null;

  // Tables
  requestItemTableColumns = ['drag', 'item', 'quantity', 'rate'];

  constructor(
    protected dateFilter: DateFilterPipe,
    protected chargeEditor: ChargeEditorService,
    protected requestItemEditor: LineItemEditorService,
    protected lineItemRequestEditor: LineItemRequestEditorService,
    protected noteEditor: NoteEditorService,
    protected dialogNotifications: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  async onEdit(): Promise<boolean>
  {
    return this.lineItemRequestEditor.open({
      workOrder: this.workOrder,
      original: this.request,
      onRequestUpdated: this.onRequestUpdated
    });
  }

  deleteRequest = async (): Promise<void> =>
  {
    if (! await this.dialogNotifications.removeConfirm()) { return };
    
    try
    {
      await this.request.delete();
      await this.onRequestUpdated();
    }
    finally
    {
      //
    }
  }

  async onRequestItemDropped(event: CdkDragDrop<LineItem[]>): Promise<void>
  {
    moveItemInArray(this.request.line_items, event.previousIndex, event.currentIndex);
    this.request.line_items.forEach( (element, index) => {
      element.position = index;
    });       

    this.requestItemTable.renderRows();
    try
    {
      await this.request.save();
      await this.onRequestUpdated();
    }
    finally
    {
      //
    }
  }

  onDuplicate(): Promise<boolean>
  {
    return this.lineItemRequestEditor.open({
      workOrder: this.workOrder,
      original: this.request.duplicate(),
      onRequestUpdated: this.onRequestUpdated,
    });
  }

  canAddRequestItem(request: LineItemRequest) {
    return request.canAddLineItems();
  }
  
  onAddRequestItem()
  {
    return this.onEditRequestItem();
  }

  async onEditRequestItem(requestItem: LineItem = null): Promise<boolean>
  {
    const onRequestItemUpdate = async (updatedRequestItem: LineItem): Promise<void> =>
    {
      if (updatedRequestItem?.exists())
      {
        // Request maintains a reference to this item, so can just change here.
        requestItem.mapAttributes(updatedRequestItem.flushAttributes());
      }
      else
      {
        updatedRequestItem.position = this.request.line_items.length;
        this.request.addLineItem(updatedRequestItem);
      }

      try
      {
        await this.request.save();
        await this.onRequestUpdated();
      }
      finally
      {
        //
      }
    }

    const onRequestItemRemove = async (removedItem: LineItem): Promise<void> => 
    {
      this.request.removeLineItem(removedItem);

      try
      {
        await this.request.save();
        await this.onRequestUpdated();
      }
      finally
      {
        //
      }
    }

    return this.requestItemEditor.open({
      workOrder: this.workOrder,
      originalRequest: this.request,
      original: requestItem,
      onRequestItemUpdate,
      onRequestItemRemove,
    });
  }

  protected _sortItems()
  {
    this.request.line_items?.sort(orderBy('position'));
  }
}
