import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Employee } from '@beaconlite/models';
import { env } from '@beaconlite/services/env.service';
import { Helpers as $helpers } from '@beaconlite/services/helpers.service'

@Component({
  selector: 'app-dispatch-employee-label',
  templateUrl: './dispatch-employee-label.component.html',
  styleUrls: ['./dispatch-employee-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DispatchEmployeeLabelComponent implements OnInit {

    @Input() employee: Employee;

    ngOnInit(): void {}

    get bookedTimeInHours()
    {
      return $helpers.roundFloat((this.employee.time_booked / 3600));
    }

    get timeIndicatorBackground(): string
    {
      const employeeHoursRanges: {limit: number|null, color: string}[] = env('employeeHoursRanges');

      const applicableRange = employeeHoursRanges.reduce((previousRange, currentRange) => 
      { 
        if (this.employee.time_booked <= previousRange.limit)
        {
          return previousRange
        }

        return currentRange;
      })

      return applicableRange.color;
    }
}
