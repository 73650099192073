import { Component, Input, OnInit } from '@angular/core';
import { LineItemDefinition } from '@beaconlite/models/LineItemDefinition.model';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { StateService } from '@uirouter/core';
import { LineItemDefinitionEditorService } from '../line-item-definition-editor/line-item-definition-editor.component';
import { Department } from '@beaconlite/models';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-line-item-single',
  templateUrl: './line-item-single.component.html',
  styleUrls: ['./line-item-single.component.scss']
})
export class LineItemSingleComponent implements OnInit {

  @Input() modelId: string;

  loading = false;
  locked = false;
  original = new LineItemDefinition();
  departments: Department[];
  departmentName: string;

  constructor(
    protected appData: AppData,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
    protected $state: StateService,
    protected lineItemDefinitionEditorService: LineItemDefinitionEditorService,
  ) { }

  async ngOnInit(): Promise<void> 
  {    
    this.lockAndLoad();

    try
    {
      [this.original, this.departments] = await Promise.all([
        await LineItemDefinition.get(this.modelId),
        await this.appData.departments.getAll()
      ]);

      this.departments?.sort(orderBy('name'));
      this.departmentName = this._getDepartmentName();
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.original.discarded;
  }

  protected _getDepartmentName(): string 
  {
    return this.departments.find(({ id }) => id === this.original.department_id)['name'];
  }

  async edit(): Promise<void>
  {
    await this.lineItemDefinitionEditorService.open({
      original: this.original,
      departments: this.departments,
      onUpdate: this.updateLineItemDefinition
    });
  }

  updateLineItemDefinition = async (lineItem: LineItemDefinition): Promise<void> =>
  {
    await lineItem.save();
    this.original = lineItem.copy();
  }

  async discard(): Promise<void>
  {
    if (! await this.dialogNotification.discardConfirm()) { return; }

    this.lockAndLoad();

    try
    {
      await this.original.discard();
      this.$state.go('protected.line-items');
    }
    finally
    {
      this.unlockAndUnload();
    }
  }
  
}
