import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { AccountingSetting, DispatchRegion } from '@beaconlite/models';
import { PreferencesData, PreferencesService } from '@beaconlite/services/preferences.service';
import * as moment from 'moment';
import { env } from '../../../../services/env.service';
import { SnackbarNotificationService } from '../../../../services/notification/snackbar-notification.service';
import { AccountingSettingsEditorService } from './accounting-settings-editor/accounting-settings-editor.service';
import { DispatchRegionEditorService } from './dispatch-region-editor/dispatch-region-editor.component';
import { InvoiceSettingsEditorService } from './invoice-settings-editor/invoice-settings-editor.component';
import { DiscountSettingsEditorService } from './discount-settings-editor/discount-settings-editor.component';
import { DispatchSettingsEditorService } from './dispatch-settings-editor/dispatch-settings-editor.component';
import { CompanySettingsEditorService } from './company-settings-editor/company-settings-editor.component';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  @ViewChild('dispatchRegionsTable') dispatchRegionsTable: MatTable<DispatchRegion[]>
  
  appVersion = env('APP_VERSION');
  accounting_settings = new AccountingSetting();
  dispatchRegions: DispatchRegion[] = [];

  dispatchRegionTableColumns = ['name'];

  constructor(
    protected accountingSettingsEditor: AccountingSettingsEditorService,
    protected dispatchSettingsEditor: DispatchSettingsEditorService,
    protected companySettingsEditor: CompanySettingsEditorService,
    protected invoiceSettingsEditor: InvoiceSettingsEditorService,
    protected discountSettingsEditor: DiscountSettingsEditorService,
    protected dispatchRegionEditor: DispatchRegionEditorService,
    protected snackbarNotifications: SnackbarNotificationService,
    public prefs: PreferencesService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.accounting_settings = await AccountingSetting.get();
    this.dispatchRegions = await DispatchRegion.getAll()
  }

  onEditAccountingSettings(): void
  {
    const onAccountingSettingsUpdate = async (updatedSettings: AccountingSetting) => 
    {
      await updatedSettings.save();
      this.accounting_settings.map(updatedSettings.flush());
      this.snackbarNotifications.saved();
    }

    this.accountingSettingsEditor.open({
      original: this.accounting_settings,
      onAccountingSettingsUpdate,
    });
  }

  async onEditDispatchSettings(): Promise<void>
  {
    await this.dispatchSettingsEditor.open({
      original: this.prefs.data,
      onUpdate: this.onUpdateCoreSettings,
    });
  }

  async onEditCompanySettings(): Promise<void>
  {
    await this.companySettingsEditor.open({
      original: this.prefs.data,
      onUpdate: this.onUpdateCoreSettings,
    });
  }

  async onEditInvoiceSettings(): Promise<void>
  {
    await this.invoiceSettingsEditor.open({
      original: this.prefs.data,
      onUpdate: this.onUpdateCoreSettings,
    });
  }

  async onEditDiscountSettings(): Promise<void>
  {
    await this.discountSettingsEditor.open({
      original: this.prefs.data,
      onUpdate: this.onUpdateCoreSettings,
    });
  }

  async onAddDispatchRegion(): Promise<void>
  {
    await this.onEditDispatchRegion();
  }

  async onEditDispatchRegion(region: DispatchRegion = new DispatchRegion()): Promise<boolean>
  {
    const onUpdate = async (updatedRegion: DispatchRegion): Promise<void> =>
    {
      updatedRegion?.exists()
        ? region.mapAttributes(updatedRegion.flushAttributes())
        : this.dispatchRegions.push(updatedRegion);

      await updatedRegion.save();

      this.dispatchRegionsTable.renderRows();

      this.snackbarNotifications.saved();
    }

    const onRemove = async (): Promise<void> =>
    {
      const index = this.dispatchRegions.indexOf(region);

      if (index >= 0)
      {
          this.dispatchRegions.splice(index, 1);
      }

      await region.delete();

      this.dispatchRegionsTable.renderRows();

      this.snackbarNotifications.saved();
    }
    
    return this.dispatchRegionEditor.open({
      original: region,
      onUpdate,
      onRemove,
    });
  }

  formattedDayOfWeek(day: number): string
  {
    if (day === null) { return };
    
    return moment().day(day).format('dddd');
  }

  onUpdateCoreSettings = async (updatedPreferences: PreferencesData): Promise<void> =>
  {
    await this.prefs.save(updatedPreferences);
    this.snackbarNotifications.saved();
  }
}
