import { BaseModel } from "./Base.model";
import { RentalOverride } from "./RentalOverride.model";
import { RentalReturn } from "./RentalReturn.model";
import { dto } from './mixins/Dto.decorators'
import { VariantRental } from ".";
import { ItemOverride } from "./ItemOverride.model";
import { RequestItem } from "./contracts/RequestItem.interface";
import IRental from "./contracts/IRental.interface";

export class Rental extends BaseModel implements RequestItem, IRental {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() rental_request_id?: string = null;
    @dto() variant_rental_id?: string = null;
    @dto() rental_group_id?: string = null;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() name?: string = null;
    @dto() detail?: string = null;
    @dto() code?: string = null;
    @dto() tags?: string = null;
    @dto() quantity?: number = 1;
    @dto() quantity_remaining?: number = 0;
    @dto() pricing_type?: string = 'rated';
    @dto() daily_rate?: number = 0;
    @dto() daily_rate_override?: number = 0;
    @dto() weekly_discount?: number = 0.0;
    @dto() weekly_discount_override?: number = 0.0;
    @dto() monthly_discount?: number = 0.0;
    @dto() monthly_discount_override?: number = 0.0;
    @dto() retail_cost?: number = 0;
    @dto() replacement_discount?: number = 0;
    @dto() replacement_discount_override?: number = 0;
    @dto() replacement_rental_offset?: number = 0;
    @dto() replacement_rental_offset_override?: number = 0;
    @dto() minimum_charge?: number = 0;
    @dto() minimum_charge_override?: number = 0;
    @dto() position?: number = 0;
    @dto() planned_quantity?: number = null;
    @dto() invalid?: boolean = null;
    
    // TODO: Jira BL-737: Not yet implemented for create/update requests. Want to shift API to use tmp_ids instead of nesting rentals in groups in json payload.
    // Currently this is being used as a flag in the API to determine whether a new rental is grouped. Also, this is being used when duplicating RentalRequests.
    @dto() rental_group_tmp_id?: string = null;

    @dto() rate_adjustment?: any = null;
    @dto(RentalReturn) returns: RentalReturn[] = [];

    // TODO: Jira BL-703: Give function better name
    inheritVariant(variant: VariantRental): void 
    {
        this.variant_rental_id                  = variant.id;
        this.name                               = variant.display_name;
        this.code                               = variant.code;
        this.tags                               = variant.tags;
        this.pricing_type                       = variant.rental_definition.default_pricing_type;
        this.daily_rate                         = variant.daily_rate;
        this.daily_rate_override                = variant.daily_rate;
        this.weekly_discount                    = variant.rental_definition.weekly_discount;
        this.weekly_discount_override           = variant.rental_definition.weekly_discount;
        this.monthly_discount                   = variant.rental_definition.monthly_discount;
        this.monthly_discount_override          = variant.rental_definition.monthly_discount;
        this.minimum_charge                     = variant.rental_definition.minimum_charge;
        this.minimum_charge_override            = variant.rental_definition.minimum_charge;
        this.retail_cost                        = variant.retail_cost;
        this.replacement_discount               = variant.rental_definition.replacement_discount;
        this.replacement_discount_override      = variant.rental_definition.replacement_discount;
        this.replacement_rental_offset          = variant.rental_definition.replacement_rental_offset;
        this.replacement_rental_offset_override = variant.rental_definition.replacement_rental_offset;
    }

    // TODO: Jira BL-703: Give function better name
    resetDefinition(): void 
    {
        // Need to maintain id when resetting. 
        this.map(new Rental({ id: this.id }));
    }

    applyOverride(override: RentalOverride): void 
    {
        if ( override.type === ItemOverride.TYPE_RATE )
        {
            this.daily_rate_override                = override.daily_rate;
            this.weekly_discount_override           = override.weekly_discount;
            this.monthly_discount_override          = override.monthly_discount;
        }
        else if ( override.type === ItemOverride.TYPE_PERCENT )
        {
            const percentMultiplier = 1+ override.percent_value * ( override.discount ? -1 : 1 );
            this.daily_rate_override = Math.round( this.daily_rate * percentMultiplier );
        }

        this.minimum_charge_override            = override.minimum_charge;
        this.retail_cost                        = override.retail_cost;
        this.replacement_discount_override      = override.replacement_discount;
        this.replacement_rental_offset_override = override.replacement_rental_offset;
        this.replacement_discount               = override.replacement_discount;
    }

    addRentalReturn(model: RentalReturn = new RentalReturn()): RentalReturn 
    {
        this.returns.push(model);

        return model;
    }

    removeRentalReturn(model: RentalReturn): void 
    {
        const index = this.returns.indexOf(model);

        if ( index >= 0 )
        {
            this.returns.splice(index, 1);
        }
    }

    validateQuantities(): void 
    {
        // Reset until validated;
        this.invalid = false;

        let quantity_returned = 0;

        this.returns.forEach(rentalReturn => {
            // Reset until validated
            rentalReturn.invalid = false;

            // Count quantity
            quantity_returned += rentalReturn.quantity;

            // RentalReturn is invalid if no more items can be returned, Rental is invalid is it has an invalid RentalReturn
            if (quantity_returned > this.quantity)
            {
                this.invalid = true;
                rentalReturn.invalid = true;
            }
        });

        // Update quantity remaining
        this.quantity_remaining = this.quantity - quantity_returned;
    }

    duplicate(): Rental 
    {
        let duplicate = new Rental(this.flushAttributes());

        duplicate.quantity_remaining    = duplicate.quantity;
        duplicate.rental_group_tmp_id   = duplicate.rental_group_id;

        // Reset computed and unique values back to model defaults
        duplicate.id                    = null;
        duplicate.rental_request_id     = null;
        duplicate.rental_group_id       = null;
        duplicate.locked                = false;
        duplicate.invoiced              = false;
        duplicate.created_at            = null;
        duplicate.updated_at            = null;

        // We are not duplicatins the RentalReturns

        return duplicate;
    }

    applyPricingDefaults(): void
    {
        if (this.pricing_type !== 'rated')
        {
            this.weekly_discount_override = 0
            this.monthly_discount_override = 0;
        }

        if (this.pricing_type === 'flat')
        {
            this.minimum_charge_override = 0;
        }
    }
}
