import { Ng2StateDeclaration } from '@uirouter/angular';
import { ReportsViewComponent } from './reports-view.component';

export const ReportsState: Ng2StateDeclaration = {
  name: 'protected.reports',
  url: '/reports',
  component: ReportsViewComponent,
  data: { 
    pageTitle: 'Reports', 
    requiresAuth: true,
  },
}
