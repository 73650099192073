<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.onSave()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Dispatch Region Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row">
            <mat-form-field fxFlex hideRequiredMarker>
                <mat-label>Name</mat-label>
                    <input type="text"
                        name="nameField"
                        #nameField="ngModel"
                        [(ngModel)]="this.region.name"
                        required
                        matInput>
                    <mat-error *ngIf="this.nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-icon-button
            type="button" 
            *ngIf="this.original.exists()"
            (click)="this.onDelete()"
            [disabled]="this.loading"
            color="primary">
            <mat-icon>delete</mat-icon>
        </button>
        <span fxFlex></span>
        <mat-progress-spinner mode="indeterminate" *ngIf="this.loading" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>
