import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { env } from '../services/env.service'

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(unixTimestamp: string | number, dateFormat: string, fallback?: string): string 
  {
    // in millis
    let timestamp: number

    if ( unixTimestamp == 0 || !unixTimestamp )
    {
      return fallback || '';
    }

    // Handle date string
    if ( typeof unixTimestamp === 'string' &&
         !/^\d+$/.test(unixTimestamp) ) // Not a string of digits
    {
      timestamp = moment.utc(unixTimestamp).valueOf();
    }
    // Handle number and numerical string
    else
    {
      timestamp = Number.parseInt(''+unixTimestamp) * 1000;
    }

    // timeFormat string is 12 or 24 based on timeFormat value set in env.js
    const timeFormat = env('timeFormat') === '24hr' 
      ? '24' 
      : '12';
 
    // append timeFormat to formatString if applicable
    const formatString = dateFormat.toLowerCase().includes('time') 
      ? env(`dateTimeFormat.${dateFormat}${timeFormat}`) 
      : env(`dateTimeFormat.${dateFormat}`);

    return this.datePipe.transform(timestamp, formatString);
  }

}
