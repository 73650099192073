<mat-table fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ongoing">
        <mat-header-cell *matHeaderCellDef>Ongoing</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.ongoing }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let item">
            <active-item-client-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </active-item-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="workOrderDetail" *ngIf="this.nestedLayer == 'work_orders'">
        <mat-cell *matCellDef="let item">
            <active-item-workorder-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </active-item-workorder-row>         
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'work_orders'">
        <mat-row *matRowDef="let nested; columns: ['workOrderDetail']" ></mat-row>
    </ng-container>
</mat-table>