import * as d3 from 'd3';
import * as moment from 'moment';
import { AfterViewInit, Component, DoCheck, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { GanttBase } from '../../../components/gantt-base/gantt-base';
import { Dispatch, Employee, WorkOrder } from '@beaconlite/models';
import { DispatchCollection } from '@beaconlite/models/collections';
import { UnixTimestamp } from '@beaconlite/types';

export interface Datum
{
    id: string,
    work_order_id: string,
    employee_id: string,
    travel_to: Date,
    travel_from: Date,
    scheduled_at: Date,
    scheduled_end_at: Date,
    is_projected: boolean,
    state_name: string,
}

type Chunk = Map<string, Datum[]>;

@Component( {
    selector: 'gantt-employee',
    templateUrl: '../../../components/gantt-base/gantt-base.html',
} )
export class GanttEmployeeComponent extends GanttBase<Chunk> implements OnInit, DoCheck, OnDestroy, AfterViewInit
{
    @ViewChild('graphContainer') graphContainer: ElementRef<any>
    @Input() sourceData: Employee[];
    @Input() clickHandler: (event: any, datum: Datum) => void;
    @Input() scrollHandler: (event: any) => void

    private _isFirstRender = true;
    private _sourceLength = 0;
    private _intervalId: ReturnType<typeof setInterval>;

    constructor(
        protected renderer: Renderer2
    ) { super( renderer ) }

    ngAfterViewInit()
    {
        this.renderer.listen(this.graphContainer.nativeElement, 'scroll', this.scrollHandler);

        // TODO: I think there are too many side effect and interdependancies occuring inside these function calls which are not clear.
        // More breakout is probably required.
        this._primeForRender()
        this.onResize();
        this.onRescale(new Date);
    }

    ngDoCheck(): void
    {
        if ( this._isFirstRender ) { return; }
        if ( this._sourceLength === this.sourceData.length ) { return; }
        this._sourceLength = this.sourceData.length
        this.render()        
    }

    ngOnDestroy()
    {
        super.ngOnDestroy();
        clearInterval( this._intervalId );
    }

    onGraphScroll( ...args: any[] )
    {
        if (this._isFirstRender){ return }
        return this.checkAndUpdateLoadedChunks();
    }


    async fetchChunk( start: UnixTimestamp, end: UnixTimestamp ): Promise<Chunk>
    {
        const dispatchCollection = new DispatchCollection()

        // Check cache for existing entry
        const cachedChunk = this._cachedData.get( start );
        if ( cachedChunk !== undefined )
        {
            return cachedChunk;
        }

        const params = {
            date_start: start,
            date_end: end,
            order: 'asc',
            with: 'employees',
        }

        const dispatches = await dispatchCollection.all( params );
        const chunk = this._processChunk( dispatches )
        this._cachedData.set( params.date_start, chunk );
        return chunk;

    }

    private _processChunk( dispatches: Dispatch[] ): Chunk
    {
        const chunk = new Map<string, Datum[]>();

        dispatches.forEach( dispatch =>
        {

            const { id, work_order_id, scheduled_at, scheduled_end_at, travel_time, state_name, employees } = dispatch;
            const startDate = moment.unix( scheduled_at );
            let endDate: moment.Moment;
            let isProjected = false;

            if ( scheduled_end_at )
            {
                endDate = moment.unix( scheduled_end_at );
            }
            else
            {
                // Project 4 hours
                // TODO: make configurable
                endDate = moment.unix( scheduled_at + 3600 * 4 );
                isProjected = true;
            }

            employees.forEach( employee =>
            {
                const dispatchData: Datum = {
                    id,
                    work_order_id,
                    employee_id: employee.id,
                    travel_to: startDate.clone().subtract( travel_time, 's' ).toDate(),
                    travel_from: endDate.clone().add( travel_time, 's' ).toDate(),
                    scheduled_at: startDate.toDate(),
                    scheduled_end_at: endDate.toDate(),
                    is_projected: isProjected,
                    state_name,
                }

                const employeeDispatches = chunk.get( employee.id ) || [];
                employeeDispatches.push( dispatchData );
                chunk.set( employee.id, employeeDispatches );
            } );

        } );

        return chunk;
    }

    _gatherRenderData()
    {
        // Grab the latest employees
        const yValues = this.sourceData;

        // Setup the index map
        const yIndexMap = yValues.reduce( ( map, yValue, index ) =>
        {
            map.set( yValue.id, index );
            return map;
        }, new Map<string, number>() );

        // TODO: Actually limit the scope to the chunks rendered
        // Get the dispatches for the time period to be rendered
        const chunks = Array.from( this._cachedData.values() );

        // Reduce the employee dispatches to a single array
        const renderData = chunks.reduce( ( dispatches, chunk ) =>
        {

            chunk.forEach( ( value, key ) =>
            {
                // Skip employees that have not been pulled in yet
                if ( !yIndexMap.has( key ) ) { return; }

                // Merge the dispatches into a single array
                Array.prototype.push.apply( dispatches, value );
            } )

            return dispatches;
        }, new Array<Datum>() );

        return { yValues, yIndexMap, renderData };
    }

    protected _primeForRender()
    {
        const graphEnd = moment( this._rendered_end ).startOf( 'day' )
        const graphStart = moment( this._rendered_start ).startOf( 'day' )
        const dateDiff = graphEnd.diff( graphStart, 'days' )

        // Sanity check. 
        // 3 Chunks rendered at a time. 
        // Date diff will be one day short since we set the end to be the start of day.
        const days = dateDiff;
        const expectedDays = ( this._daysPerChunk * 3 ) - 1;
        if ( days !== expectedDays )
        {
            throw `Error: There is a discrepency in the expected number of days to be rendered. Expected ${expectedDays}, Actual: ${days}`;
        }

        const yValues = this.sourceData;

        this._contentWidth = days * this.ticksPerDay * this._tickWidth || this._viewWidth;
        this._width = this._contentWidth + this._margin.left + this._margin.right;

        this._contentHeight = yValues.length * this.yHeight
        this._height = Math.max( this._contentHeight + this._margin.top + this._margin.bottom, this._viewHeight - this._xAxisHeight );

        // Prep X axis
        this._xFunction = d3.scaleUtc()
            .domain( [ d3.timeDay( graphStart.toDate() ), d3.timeDay( graphEnd.toDate() ) ] )
            .range( [ this._margin.left, this._margin.left + this._contentWidth ] )

        // Prep Y axis
        const paddingRatio = ( this.yHeight - this._desiredBandWidth ) / this.yHeight;
        const bandRangeStart = this._margin.top + this._indexListOffset;
        this._yFunction = d3.scaleBand()
            .domain( yValues.map( ( { id } ) => id ) )
            .range( [ bandRangeStart, bandRangeStart + this._contentHeight ] )
            .paddingOuter( paddingRatio / 2 )
            .paddingInner( paddingRatio );

    }

    protected render() 
    {
        const { renderData } = this._gatherRenderData();
        this._primeForRender();

        // Clear, prep for re-draw
        // TODO: try and find a better alternative than a cast to any
        d3.select( ".schedule-graph" ).selectAll( [ 'path', 'g', 'line' ] as any ).remove();

        const xAxis = g => g
            .attr( "transform", `translate(0,${this._xAxisHeight})` )
            .attr( 'class', 'x-axis' )
            .call( d3.axisTop( this._xFunction )
                .ticks( this.ticks )
                .tickSize( 0 )
                .tickPadding( 5 )
                .tickFormat( this.hourFormat )
            )
            .call( g => g.select( ".domain" ).remove() )
            .call( g => g.selectAll( '.tick' ).insert( 'rect', ':first-child' )
                .attr( 'width', this._tickWidth )
                .attr( 'height', 17 )
                .attr( 'y', -18 )
            )
            .call( g => g.selectAll( '.tick text' )
                .attr( 'transform', 'translate(5, 0)' )
            )
            .call( g => g.selectAll( `.tick:nth-of-type(${this.ticksPerDay}n+1) text` ).clone()
                .attr( 'y', '-30' )
                .attr( 'transform', '' ) // Removed cloned transform
                .text( ( d: Date ) => this.dateFormat( d ) )
            )
            .call( g => g.selectAll( '.tick text' ).attr( 'text-anchor', 'start' ) );

        // Vertical lines in the graph
        const contentAxis = g => g
            .attr( 'class', 'x-axis' )
            .call( d3.axisTop( this._xFunction )
                .ticks( this.ticks )
                .tickSize( 0 )
                .tickSizeInner( -this._height )
            )
            .call( g => g.select( ".domain" ).remove() )
            .call( g => g.select( ".tick text" ).remove() );

        // Header appended separately to remain vertically fixed
        const header = d3.select( ".schedule-graph .graph__header" )
            .attr( "viewBox", [ 0, 0, this._width, this._xAxisHeight ] as any )
            .attr( "width", this._width )
            .attr( "height", this._xAxisHeight )

        header.append( 'rect' )
            .attr( 'width', '100%' )
            .attr( 'height', this._xAxisHeight )
            .attr( 'fill', '#FAFAFA' )

        header.append( 'g' )
            .call( xAxis );

        const svg = d3.select( ".schedule-graph .graph__content" )
            .attr( "viewBox", [ 0, 0, this._width, this._height ] as any )
            .attr( "width", this._width )
            .attr( "height", this._height )

        svg.append( 'g' )
            .call( contentAxis );

        // Now line(s)
        header.append( "line" )
            .attr( 'class', 'now' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y1', 32 )
            .attr( 'y2', this._xAxisHeight )
        svg.append( "line" )
            .attr( 'class', 'now' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y2', this._height );

        // Keep the now line updated
        if ( this._isFirstRender )
        {
            this._intervalId = setInterval( () =>
            {
                d3.selectAll( ".schedule-graph .now" )
                    .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` );
            }, 1000 * 60 ) // 1 Minute
        }

        // Add the time portion of the mouse move time
        header.select( '.x-axis' ).append( 'g' )
            .attr( 'class', 'mousetime' )
            .attr( 'style', 'display:none' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, -30)` )
            .call( g => g.append( 'rect' )
                .attr( 'width', '100' )
                .attr( 'height', '14' )
                .attr( 'fill', '#FAFAFA' )
                .attr( 'transform', `translate(-50, -11)` )
            )
            .call( g => g.append( 'text' )
                .attr( 'fill', 'currentColor' )
                .text( this.hourFormat( new Date() ) )
                .call( text =>
                {
                    const textWidth = text.node().getComputedTextLength() + 10;
                    g.select( 'rect' )
                        .attr( 'width', textWidth )
                        .attr( 'transform', `translate(-${textWidth / 2}, -11)` )
                } )
            )

        // Add the mouse move time lines
        header.append( "line" )
            .attr( 'class', 'mousetime' )
            .attr( 'style', 'display:none' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y1', 32 )
            .attr( 'y2', this._xAxisHeight )
        svg.append( "line" )
            .attr( 'class', 'mousetime' )
            .attr( 'style', 'display:none' )
            .attr( 'transform', `translate(${this._xFunction( Date.now() )}, 0)` )
            .attr( 'y2', this._height );

        const handleMouseLeaveTime = () =>
        {
            d3.selectAll( "line.mousetime" )
                .attr( 'style', 'display:none' );

            header.select( 'g.mousetime' )
                .attr( 'style', 'display:none' );
        }

        const handleMouseMoveTime = ( event ) =>
        {
            // Offset mx by 2 so the cusror does not visually overlap
            const mx = d3.pointer( event )[ 0 ] - 2;

            // Update the line's position
            d3.selectAll( "line.mousetime" )
                .attr( 'style', '' )
                .attr( 'transform', `translate(${mx}, 0)` )

            // Update the time's position and value
            header.select( 'g.mousetime' )
                .attr( 'style', '' )
                .attr( 'transform', `translate(${mx}, -30)` )
                .select( 'text' )
                // For some reason the mouse-x positon is off by one
                .text( this.hourFormat( this._xFunction.invert( mx - 1 ) ) );
        }

        header.on( 'touchmove mousemove', handleMouseMoveTime );
        header.on( 'touchend mouseleave', handleMouseLeaveTime );
        svg.on( 'touchmove mousemove', handleMouseMoveTime );
        svg.on( 'touchend mouseleave', handleMouseLeaveTime );

        // Gantt Bars
        svg.selectAll<SVGGElement, Datum>( '.dispatch' )
            .data<Datum>( renderData, d => d.scheduled_at.valueOf() )
            .join( 'g' )
            .attr( 'class', d =>
            {
                let clazz = `dispatch ${d.state_name}`
                if ( d.is_projected ) { clazz += ' projected' }
                if ( this._isOverlapping( renderData, d ) ) { clazz += ' overlapping' };
                return clazz;
            } )
            .on( 'click', (event, d) => this.clickHandler( event, d ) )
            // Primary section of the bar
            .call( g => g.append( 'rect' )
                .attr( 'rx', d => d.travel_to.getTime() == d.scheduled_at.getTime() ? this._radius : 0 )
                .attr( 'y', d => this._yFunction( d.employee_id ) )
                .attr( 'x', d => this._xFunction( d.scheduled_at ) )
                .attr( 'height', this._yFunction.bandwidth() )
                .attr( 'width', d =>
                {
                    return this._xFunction( d.scheduled_end_at ) - this._xFunction( d.scheduled_at )
                } )
            )
            // Starting portion (lefthand bookend)
            .call( g =>
            {
                g.insert( 'path', ':first-child' )
                    .attr( 'class', 'bookend' )
                    .attr( 'd', d => `M ${this._xFunction( d.scheduled_at )} ${this._yFunction( d.employee_id )} 
                        H ${this._xFunction( d.travel_to ) + this._radius} 
                        a ${this._radius} ${this._radius} 90 0 0 -${this._radius} ${this._radius} 
                        v ${this._yFunction.bandwidth() - 2 * this._radius} 
                        a ${this._radius} ${this._radius} 90 0 0 ${this._radius} ${this._radius} 
                        H ${this._xFunction( d.scheduled_at )} 
                        v ${-this._yFunction.bandwidth()}` )
            } )
            // Ending portion (righthand bookend)
            .call( g =>
            {
                g.append( 'path' )
                    .attr( 'class', 'bookend' )
                    .attr( 'd', d =>
                    {
                        // No end (point)
                        if ( d.is_projected )
                        {
                            return `M ${this._xFunction( d.scheduled_end_at ) - this._radius} ${this._yFunction( d.employee_id )}
                                    h ${this._radius}
                                    l ${this._yFunction.bandwidth() / 4} ${this._yFunction.bandwidth() / 2}
                                    l -${this._yFunction.bandwidth() / 4} ${this._yFunction.bandwidth() / 2}
                                    h -${this._radius}
                                    v ${-this._yFunction.bandwidth()}`
                        // Has end (rounded)
                        } else {
                            return `M ${this._xFunction( d.scheduled_end_at ) - this._radius} ${this._yFunction( d.employee_id )} 
                                    H ${this._xFunction( d.travel_from ) - this._radius} 
                                    a ${this._radius} ${this._radius} 90 0 1 ${this._radius} ${this._radius} 
                                    v ${this._yFunction.bandwidth() - 2 * this._radius} 
                                    a ${this._radius} ${this._radius} 90 0 1 -${this._radius} ${this._radius} 
                                    H ${this._xFunction( d.scheduled_end_at )} 
                                    v ${-this._yFunction.bandwidth()}`
                        }
                    } )
            } )

            if( this._isFirstRender )
            {
                this._scrollTo(new Date);
            }

            this._isFirstRender = false;
    }

    private _isOverlapping( renderData: Datum[], datum: Datum )
    {
        const employeeDispatches = renderData.filter( d => d.employee_id === datum.employee_id );
        return employeeDispatches.some( d =>
        {
            return datum.travel_to > d.travel_to &&
                datum.travel_to < d.travel_from
        } );
    }

}
