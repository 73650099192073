import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { ServiceHoursReport } from '@beaconlite/models/reports/ServiceHoursReport.model';
import { ServiceHoursReportItem } from '@beaconlite/models/reports/contracts/ServiceHoursReportItem.interace';

@Component({
  selector: 'service-hours-client-row',
  templateUrl: './service-hours-client-row.component.html',
})
export class ServiceHoursClientRowComponent implements OnInit, DoCheck {

  @Input() report: ServiceHoursReport;
  @Input() items: ServiceHoursReportItem[];
  @Input() layer: string;

  nestedLayer = '';
  tableColumns = ['name', 'regular', 'overtime', 'travel', 'total'];

  constructor() { }
  ngOnInit(): void {}

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: ServiceHoursReportItem): ServiceHoursReportItem[]|null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }
}
