<mat-card class="medium-card">
<div class="override-card-inner" fxLayout="column">
    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-title">Rental Overrides</div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center"  
        *ngIf="!this.model.exists()">
        {{ this.label }} must first be created before adding rental overrides.
    </div>

    <div fxLayout="column" *ngIf="this.hasRateOverrides()">
        <div class="mat-subheading-2">Rate Overrides</div>
        <mat-table class="mat-selectable-table"
            fxFlex="100"
            [dataSource]="this.model.rental_rate_overrides" >
            <ng-container matColumnDef="item">
                <mat-header-cell fxFlex="25" *matHeaderCellDef>Item</mat-header-cell>
                <mat-cell fxFlex="25" *matCellDef="let override">{{ override.variant_rental.display_name }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="daily-rate">
                <mat-header-cell fxFlex="10" *matHeaderCellDef>Daily Rate</mat-header-cell>
                <mat-cell fxFlex="10" *matCellDef="let override">{{ override.daily_rate | currencyFilter }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="weekly">
                <mat-header-cell *matHeaderCellDef>Weekly</mat-header-cell>
                <mat-cell *matCellDef="let override">
                    {{ override.weekly_discount | percentFilter }} discount<br>
                    {{ this.calculateRate(override.daily_rate, override.weekly_discount, 1) | currencyFilter }} /day<br>
                    {{ this.calculateRate(override.daily_rate, override.weekly_discount, this.weekThreshold) | currencyFilter }} /week ({{ this.weekThreshold }} days)
                </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="monthly">
                <mat-header-cell *matHeaderCellDef>Monthly</mat-header-cell>
                <mat-cell *matCellDef="let override">
                    {{ override.monthly_discount | percentFilter }} discount<br>
                    {{ this.calculateRate(override.daily_rate, override.monthly_discount, 1) | currencyFilter }} /day<br> 
                    {{ this.calculateRate(override.daily_rate, override.monthly_discount, this.monthThreshold) | currencyFilter }} /month ({{this.monthThreshold}} days)
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="replacement-charge">
                <mat-header-cell *matHeaderCellDef>Replacement Charge</mat-header-cell>
                <mat-cell *matCellDef="let override">
                    {{ override.retail_cost * (1 - override.replacement_discount) | currencyFilter}} 
                    ({{ override.replacement_rental_offset | percentFilter }} offset)
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="rateTableColumns"></mat-header-row>
            <mat-row class="rental-override-row"
                *matRowDef="let override; columns: rateTableColumns" 
                (click)="onEdit(override)">
            </mat-row>
        </mat-table>
    </div>

    <div fxLayout="column" *ngIf="this.hasPercentOverrides()">
        <div class="mat-subheading-2">Percentage Overrides</div>
        <mat-table class="mat-selectable-table"
            fxFlex="100"
            [dataSource]="this.model.rental_percent_overrides">
            <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef>Item</mat-header-cell>
                <mat-cell *matCellDef="let override">{{ override.variant_rental.display_name }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="type">
                <mat-header-cell fxFlex="15" *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let override">{{ override.displayType() }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="percent">
                <mat-header-cell fxFlex="15" *matHeaderCellDef>Percent</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let override">
                    {{ override.percent_value | percentFilter }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="replacement-charge">
                <mat-header-cell fxFlex="25" *matHeaderCellDef>Replacement Charge</mat-header-cell>
                <mat-cell fxFlex="25" *matCellDef="let override">
                    {{ override.retail_cost * (1 - override.replacement_discount) | currencyFilter}} 
                    ({{ override.replacement_rental_offset | percentFilter }} offset)
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="percentTableColumns"></mat-header-row>
            <mat-row class="rental-override-row"
                *matRowDef="let override; columns: percentTableColumns" 
                (click)="onEdit(override)">
            </mat-row>
        </mat-table>  
    </div>

    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end end">
        <button mat-mini-fab
            (click)="this.onEdit()"
            [disabled]="!this.model.exists()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
</mat-card>
