<mat-table class="mat-selectable-table" fxFlex="100" [dataSource]="this.requests" multiTemplateDataRows>

    <ng-container matColumnDef="scheduled">
        <mat-header-cell *matHeaderCellDef>Scheduled</mat-header-cell>
        <mat-cell *matCellDef="let request">{{ request.scheduled_at | dateFilter:'dateTimeMedium' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let request">{{ request.name }}</mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="department">
        <mat-header-cell *matHeaderCellDef>Department</mat-header-cell>
        <mat-cell *matCellDef="let request">{{ request.department }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
        <mat-cell *matCellDef="let request">{{ request.location }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let request">
            <dispatched-request-client-row fxFlex="100"
                [report]="this.report"
                [requests]="this.getNestedRequests(request)"
                [layer]="this.nestedLayer">
            </dispatched-request-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="workOrderDetail" *ngIf="this.nestedLayer == 'work_orders'">
        <mat-cell *matCellDef="let request">
            <dispatched-request-workorder-row fxFlex="100"
                [report]="this.report"
                [requests]="this.getNestedRequests(request)"
                [layer]="this.nestedLayer">
            </dispatched-request-workorder-row>         
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let request; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'work_orders'">
        <mat-row *matRowDef="let nested; columns: ['workOrderDetail']" ></mat-row>
    </ng-container>

</mat-table>
