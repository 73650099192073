<mat-table class="mat-selectable-table" fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="serial">
        <mat-header-cell *matHeaderCellDef>Work Order</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.serial_id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="rented">
        <mat-header-cell *matHeaderCellDef>Rented</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.rented }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="max">
        <mat-header-cell *matHeaderCellDef>Max</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.max }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lost">
        <mat-header-cell *matHeaderCellDef>Lost</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.lost }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let item">
            <rental-item-client-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </rental-item-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rentalItemDetail" *ngIf="this.nestedLayer == 'items'">
        <mat-cell *matCellDef="let item">
            <rental-item-rental-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </rental-item-rental-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestDetail" *ngIf="this.nestedLayer == 'rental_requests'">
        <mat-cell *matCellDef="let item">
            <rental-item-request-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </rental-item-request-row>         
        </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns" (click)="this.onItemClicked(item)"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'items'">
        <mat-row *matRowDef="let nested; columns: ['rentalItemDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'rental_requests'">
        <mat-row *matRowDef="let nested; columns: ['requestDetail']" ></mat-row>
    </ng-container>
</mat-table>
