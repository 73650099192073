import { Ng2StateDeclaration } from '@uirouter/angular';
import { Sage50SettingsComponent } from './sage50-settings.component';

export const Sage50SettingsState: Ng2StateDeclaration = {
  name: 'protected.settings-sage50',
  url: '/settings/sage50',
  component: Sage50SettingsComponent,
  data: { 
    pageTitle: 'Sage50 Settings', 
    requiresAuth: true,
  },
}
