export class HttpResponse {
  xhrResponse: any;
  _meta: any;
  _data: any;
  _error: any;
  _status: number;
  // xhrResponse;
  // _meta = null;
  // _data = null;
  // _error = null;

  constructor(response) 
  {
    if (!! response)
    {
      this._data = response.data;
      this._status = response.status;
      this._error = response.error;
      this._meta = response.meta
    }
  }

  isSuccess(): boolean
  {
    return this._status >= 200 && this._status <= 299;
  }

  isRedirection(): boolean
  {
    return this._status >= 300 && this._status <= 399;
  }

  isClientError(): boolean
  {
    return this._status >= 400 && this._status <= 499;
  }

  isServerError(): boolean
  {
    return this._status >= 500 && this._status <= 599;
  }

  isError(): boolean
  {
    return this.isClientError() || this.isServerError();
  }

  data()
  {
    return this._data;
  }

  error()
  {
    return this._error;
  }
}
