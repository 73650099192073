<mat-toolbar class="print-hide" color="primary">
    <h1>Invoice Schedule</h1>
</mat-toolbar>

<div class="tab-container" fxLayout="column">
    <!-- <ng-template mat-tab-label>Scheduled</ng-template> -->
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
        <span fxFlex class="mat-body-1">Work orders listed include a {{ this.invoiceGracePeriod }}-day grace period after it's next invoiceable date to allow delayed data entry.</span>
        <mat-form-field class="inline-input">
            <input type="text"
                [matDatepicker]="issueDatePicker"
                [(ngModel)]="this.issueDate"
                name="issueDateField"
                matInput>
            <mat-datepicker-toggle matSuffix [for]="issueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #issueDatePicker></mat-datepicker>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="this.onRefreshList()">Refresh</button>
    </div>

    <!-- <mat-progress-bar mode="indeterminate" [fxShow]="this.workOrders.loading"></mat-progress-bar> -->

        <mat-table class="mat-selectable-table" 
            (scroll)="onTableScroll($event)"
            [dataSource]="this.workOrders">
            <ng-container matColumnDef="work-order">
                <mat-header-cell *matHeaderCellDef>Work Order</mat-header-cell>
                <mat-cell *matCellDef="let work_order">{{ work_order.serial_id }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="client">
                <mat-header-cell *matHeaderCellDef fxFlex="35">Client</mat-header-cell>
                <mat-cell *matCellDef="let work_order" fxFlex="35">{{ work_order.client.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="last-invoice">
                <mat-header-cell *matHeaderCellDef>Last Invoice</mat-header-cell>
                <mat-cell *matCellDef="let work_order">{{ work_order.previous_invoice | dateFilter:'dateMedium' : 'None' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="next-invoice">
                <mat-header-cell *matHeaderCellDef>Next Invoice</mat-header-cell>
                <mat-cell *matCellDef="let work_order">{{ work_order.next_invoice | dateFilter:'dateMedium' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="lock">
                <mat-header-cell *matHeaderCellDef fxFlex="10">Lock</mat-header-cell>
                <mat-cell *matCellDef="let work_order" fxFlex="10">
                    <span *ngIf="this.hasResourceLock(work_order)">
                        <mat-icon color="primary" [matTooltip]="this.getLockOwner(work_order)" matTooltipPosition="right">
                            lock
                        </mat-icon>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="scheduleTableColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let work_order; columns: scheduleTableColumns" (click)="this.previewScheduledInvoice(work_order)">
            </mat-row>
        </mat-table>
</div>
