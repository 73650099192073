import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'bytesFilter'
})
export class BytesFilterPipe implements PipeTransform {
    
    transform(bytes: number, precision: number = 1): string
    {
        if (!isFinite(bytes)) return '-';


        const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(bytes) / Math.log(1024));

        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
    }
}