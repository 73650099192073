<div class="request-tab" fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="16px">
    <div class="request-tab__no-requests" 
        *ngIf="!workOrder.hasDispatches()"
        (click)="onAddDispatch()">
        <img class="request-tab__no-requests__jerry-cone" src="assets/img/jerry-cone.png" />
        <p>You have no Dispatches, would you like to create one?</p>
        <img class="request-tab__no-requests__keyboard-shortcut" src="assets/img/keyboard-shortcut__option-d.png" />
    </div>

    <dispatch-component class="large-card"
        *ngFor="let dispatch of workOrder.dispatches"
        [workOrder]="workOrder"
        [dispatch]="dispatch"
        [shouldShowDepartment]="shouldShowDepartment"
        [isAdmin]="true"
        [canEdit]="true"
        [onUpdate]="onDispatchUpdate"
        [onRemove]="onDispatchRemove"> 
    </dispatch-component>

    <div fxLayout="row" 
        fxLayoutAlign="end end" 
        class="request-tab__add-button-wrapper">
        <button mat-fab
            [disabled]="!workOrder.canAddDispatches()" 
            (click)="onAddDispatch()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
