<form class="mat-dialog-editor" #editorForm (ngSubmit)="onAccept()" novalidate>
    <mat-dialog-content class="dialog-padding">
        <mat-form-field fxFlex>
            <mat-label>Reason</mat-label>
            <textarea [(ngModel)]="this.reason"
                name="reasonField" 
                #reasonField="ngModel"
                rows="5"
                maxlength="500"
                minlength="10"
                required
                matInput>
            </textarea>
            <mat-error *ngIf="reasonField.hasError('required')">Reason is required.</mat-error>
            <mat-hint align="start">
                Reason must be at least 10 characters long
            </mat-hint>
            <mat-hint align="end">
                <span>{{ this.reason?.length || 0 }} / 500</span>    
            </mat-hint>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="accent" type="submit" [disabled]="!this.isLongEnough()">Accept</button>
        <button mat-raised-button [mat-dialog-close]>Cancel</button>
    </mat-dialog-actions>
</form>
