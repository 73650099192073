import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared.module';
import { LoginComponent } from './login/login.component';
import { LoginState } from './login/login.state';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotFoundState } from './not-found/not-found.state';
import { ForgotComponent } from './forgot/forgot.component';
import { ForgotState } from './forgot/forgot.state';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordResetState } from './password-reset/password-reset.state';


@NgModule({
  imports: [
    SharedModule,
    UIRouterModule.forChild({ states: [LoginState, ForgotState, NotFoundState, PasswordResetState] })
  ],
  declarations: [ 
    LoginComponent,
    ForgotComponent,
    NotFoundComponent,
    PasswordResetComponent
  ],
})
export class PublicModule {}
