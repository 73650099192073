<mat-table fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.type }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.location }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef>Rate</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.rate | currencyFilter }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="rate-type">
        <mat-header-cell *matHeaderCellDef>Rate Type</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.rate_type }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.quantity }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.total | currencyFilter }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientDetail" *ngIf="this.nestedLayer == 'clients'">
        <mat-cell *matCellDef="let item">
            <invoiced-items-client-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </invoiced-items-client-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="workOrderDetail" *ngIf="this.nestedLayer == 'work_orders'">
        <mat-cell *matCellDef="let item">
            <invoiced-items-workorder-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </invoiced-items-workorder-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="InvoiceDetail" *ngIf="this.nestedLayer == 'invoices'">
        <mat-cell *matCellDef="let item">
            <invoiced-items-invoice-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </invoiced-items-invoice-row>         
        </mat-cell>
    </ng-container>

  
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'clients'">
        <mat-row *matRowDef="let nested; columns: ['clientDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'work_orders'">
        <mat-row *matRowDef="let nested; columns: ['workOrderDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'invoices'">
        <mat-row *matRowDef="let nested; columns: ['InvoiceDetail']" ></mat-row>
    </ng-container>
</mat-table>