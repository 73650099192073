import { BaseModel } from "./Base.model";
import { dto } from "./mixins/Dto.decorators";
import { date } from './mixins/Date.decorators'
import { AppInjector } from "@beaconlite/services/app-injector.service";
import { RouteMap } from "@beaconlite/services/network/route-map.service";
import { Attachment } from "./Attachment.model";
import { HttpResponse } from "@beaconlite/services/network/http-response";

export class Thumbnail extends BaseModel {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() thumbnailed_id?: string = null;
    @dto() thumbnailed_type?: string = null;
    @dto() name?: string = null;
    @dto() key?: string = null;
    @dto() @date processed_at?: number = null;

    @dto(Attachment) small?: Attachment = new Attachment({ description: 'small' });
    @dto(Attachment) medium?: Attachment = new Attachment({ description: 'medium' });
    @dto(Attachment) large?: Attachment = new Attachment({ description: 'large' });

    static async getUploadUrl(): Promise<any>
    {
        const response = await AppInjector.get(RouteMap).getThumbnailUploadUrl();
        
        return response.data();
    }

    static async delete(thumbnail: Thumbnail): Promise<HttpResponse> 
    {
        const response = await AppInjector.get(RouteMap).deleteThumbnail(thumbnail.id);

        return response.data();
    }
}
