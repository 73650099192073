<div class="mat-dialog-editor" fxFlexFill fxLayout="column">
    <mat-toolbar fxLayout="row">
        <h2 class="mat-title">Attach files to {{  this.documentableModel.formatted_serial_id }}</h2>   
    </mat-toolbar>

    <mat-dialog-content [class.dialog-padding]="!this.availableItems.length">
        <document-tab-upload *ngIf="!this.availableItems.length"
            [uploader]="this.uploader"
            (fileAdded)="this.onFileAdded()"
            (fileRemoved)="this.onFileRemoved($event)">
        </document-tab-upload>

        <!-- Upload and associate existing -->
        <mat-tab-group *ngIf="this.availableItems.length"
            dynamicHeight
            mat-stretch-tabs
            [selectedIndex]="this.selectedTab"
            (selectedIndexChange)="this.selectedTab = $event"
            color="accent">
            <mat-tab class="dialog-padding">
                <ng-template mat-tab-label>Choose Existing Document</ng-template>
                <document-tab-associate
                    [items]="this.availableItems"
                    (associationChanged)="this.onAssociationChange()">
                </document-tab-associate>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Add New Document</ng-template>
                <document-tab-upload 
                    [uploader]="this.uploader"
                    (fileAdded)="this.onFileAdded()"
                    (fileRemoved)="this.onFileRemoved($event)">
                </document-tab-upload>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <mat-progress-spinner *ngIf="this.sending" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button mat-raised-button 
            color="accent"
            [disabled]="this.sending || (!this.documents.length && !this.associates.length)"
            (click)="this.save()">
            Save
        </button>
    </mat-dialog-actions>
</div>

