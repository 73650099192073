import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { Helpers as $helpers } from '../services/helpers.service';
import { PercentPipe } from '@angular/common';
@Directive({
    selector: '[percentInput][ngModel]',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PercentInputDirective), multi: true }
    ]
})
export class PercentInputDirective implements ControlValueAccessor {
    onChange = (value: number) => {};
    onTouch = () => {};
    readonly INVALID_CHARACTERS: RegExp = /[^\d\.-]/;

    constructor(
        protected elementRef: ElementRef,
        protected renderer: Renderer2,
        protected percentPipe: PercentPipe,
    ) {}

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        this.restrictInput(event);
    }

    @HostListener('focus', ['$event'])
    onFocus(event: FocusEvent) {
        this.cleanOnFocus(event);
        this.elementRef.nativeElement.select();
    }

    @HostListener('blur', ['$event'])
    onBlur(event: FocusEvent) {
        this.formatOnBlur(event);
    }

    @HostListener('input', ['$event.target.value'])
    input(value: string) {
        this.onChange(this.parse(value));
    }

    // mat-error won't show without this when using a custom input directive which implements ControlValueAccessor.
    @HostListener('focusout', ['$event.target'])
    onFocusout() {
        this.onTouch();
    }

    // equivalent to ng1 $formatters
    writeValue(value: number): void {
        this.format(value);
    }

    // equivalent to ng1 $parsers
    registerOnChange(fn: () => void): void {
        this.onChange = fn
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Restrict key/character input for percent input fields.
     * Allowed values: 0-9, decimals, and minus symbols.
     *
     * @param {KeyboardEvent} event
     */
    protected restrictInput(event: KeyboardEvent) {
        let key: string = event.key;
        let value: string = (<HTMLInputElement>event.target).value;

        // Prevent input of invalid characters
        if (this.INVALID_CHARACTERS.test(key))
        {
            event.preventDefault();
            return;
        }

        // Prevent input of additional decimals
        if (key == '.' && value.includes('.'))
        {
            event.preventDefault();
            return;
        }

        // Prevent input of additional or non-leading minus symbols
        if ( key == '-' && 
             (value.includes('-') || (<HTMLInputElement>event.target).selectionStart > 0) )
        {
            event.preventDefault();
            return;
        }
    }

    /**
     * Clean the input value when focused.
     *
     * @param {FocusEvent} event
     */
    protected cleanOnFocus(event: FocusEvent): void {
        const element: HTMLInputElement = this.elementRef.nativeElement;
        const eventValue: string = (<HTMLInputElement>event.target).value;
        const cleanedValue: string = this.cleanViewValue(eventValue);

        this.renderer.setProperty(element, 'value', cleanedValue);
    }

    /**
     * Format the input value when blurring.
     *
     * @param {FocusEvent} event
     */
    protected formatOnBlur(event: FocusEvent) {
        const eventValue: string = (<HTMLInputElement>event.target).value;

        if (! this.INVALID_CHARACTERS.test(eventValue))
        {
            const transformedValue: string = this.percentPipe.transform(parseFloat(eventValue) / 100, '1.2-2');
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', transformedValue);
        } 
    }

    /**
     * Clean a number string by removing all invalid characters.
     * Valid characters: 0-9, decimals, and minus symbols.
     *
     * @param {string} value
     * @returns {string}
     */
    protected cleanViewValue(value: string): string {
        const globalRegExp = new RegExp(this.INVALID_CHARACTERS, 'g');

        return value.replace(globalRegExp, '');
    }

    /**
     * Parsing function for decimal percent values.
     * Converts a decimal percent value (0%-100%) into a fractional percent value (0-1).
     *
     * @param {string} value
     * @returns {number|null}
     */
    protected parse(value: string): number | null {
        const cleanedValue: string = this.cleanViewValue(value);
        const parsedValue: number = parseFloat(cleanedValue);
        const roundedValue = $helpers.roundFloat(parsedValue / 100, 4);

        return isNaN(roundedValue)
            ? null
            : roundedValue;
    }

    /**
     * Formatting function for fractional percent values.
     * Converts a fractional percent value (0-1) into a decimal percent value (0%-100%).
     *
     * @param {number} value
     */
    protected format(value: number): void {
        const element: HTMLInputElement = this.elementRef.nativeElement;
        const formattedValue: string = this.percentPipe.transform(value, '1.2-2');
        this.renderer.setProperty(element, 'value', formattedValue);
    }
}
