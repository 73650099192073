import { BaseModel } from "./Base.model";
import { InvoicedRental } from "./InvoicedRental.model";
import { InvoicedRentalGroup } from "./InvoicedRentalGroup.model";
import { InvoicedRentalReturn } from "./InvoicedRentalReturn.model";
import { InvoicedRequestItem } from "./contracts/InvoicedRequestItem.interface";
import { InvoicedReturnItem } from "./contracts/InvoicedReturnItem.interface";
import { InvoicedAdjustment } from "./contracts/invoiced/InvoicedAdjustment.interface";
import { InvoicedCharge } from "./contracts/invoiced/InvoicedCharge.interface";
import { InvoicedNote } from "./contracts/invoiced/InvoicedNote.interface";
import { date } from "./mixins/Date.decorators";
import { dto } from "./mixins/Dto.decorators";
import IRentalRequest from "./contracts/IRentalRequest.interface";
import { RentalGroupBuilderHelper } from "@beaconlite/services/RentalGroupBuilder.helper";

export class InvoicedRentalRequest extends BaseModel implements IRentalRequest
{
    constructor(attributes: object = {}) {
        super();
        this.init(attributes);

        this.setUngroupedRentals();
        this.setUngroupedReturns();
        this.setGroupMap();
        this.setRentalsOnGroups();
        this.setReturnsOnGroups();
    }

    @dto() id?: string = null;
    @dto() invoice_id?: string = null;
    @dto() rental_request_id?: string = null;
    @dto() rental_request_serial_id?: string = null;
    @dto() request_minimum?: number = null;
    @dto() waive_request_minimum?: boolean = false;
    @dto() location?: string = null;
    @dto() ordered_by?: string = null;
    @dto() @date started_at?: number = null;
    @dto() @date ended_at?: number = null;
    @dto() @date period_started_at?: number = null;
    @dto() @date period_ended_at?: number = null;
    @dto() period_days?: number = null;
    @dto() rental_days?: number = null;
    @dto() total?: number = null;

    @dto() charges: InvoicedCharge[] = [];
    @dto() notes: InvoicedNote[] = [];
    @dto() minimum_adjustment?: InvoicedAdjustment = null;
    @dto(InvoicedRental) rentals: InvoicedRental[] = [];
    @dto(InvoicedRentalGroup) rental_groups: InvoicedRentalGroup[] = [];
    @dto(InvoicedRentalReturn) returns: InvoicedRentalReturn[] = [];

    protected _ungrouped_rentals: InvoicedRental[] = [];
    protected _ungrouped_returns: InvoicedRentalReturn[] = [];
    protected _group_map: Map<string, InvoicedRentalGroup> = new Map();

    protected setUngroupedRentals(): void {
        this._ungrouped_rentals = (new RentalGroupBuilderHelper()).getUngroupedRentals(this);
    }

    protected setUngroupedReturns(): void {
        for (let i = 0; i < this.returns.length; i += 1) {
            if (this.isUngrouped(this.returns[i])) {
                this._ungrouped_returns.push(this.returns[i]);
            }
        }
    }

    protected setGroupMap(): void {
        for (let i = 0; i < this.rental_groups.length; i += 1) {
            // Since invoice previews are unsaved, we use the RentalGroup ids from the RentalRequest to match Rentals to Groups.
            this._group_map.set(this.rental_groups[i].rental_group_id, this.rental_groups[i]);
        }
    }

    protected isUngrouped (item: (InvoicedRental | InvoicedRentalReturn)): boolean {
        return item?.rental_group === null;
    }

    protected findMatchingGroup(item: (InvoicedRental | InvoicedRentalReturn)): InvoicedRentalGroup {
        if (this.isUngrouped(item)) {
            throw new Error(`Item ${item.name} must have a group`);
        }

        const group = this._group_map.get(item.rental_group.rental_group_id);

        if (group === undefined) {
            throw new Error(`Item ${item.name} has an invalid group`);
        }

        return group;
    }

    protected setRentalsOnGroups(): void {
        for (let i = 0; i < this.rentals.length; i += 1) {
            if (this.isUngrouped(this.rentals[i])) { continue; }
            
            const matchingGroup = this.findMatchingGroup(this.rentals[i]);
            matchingGroup.rentals.push(this.rentals[i]);
        }
    }

    protected setReturnsOnGroups(): void {
        for (let i = 0; i < this.returns.length; i += 1) {
            if (this.isUngrouped(this.returns[i])) { continue; }

            const matchingGroup = this.findMatchingGroup(this.returns[i]);
            matchingGroup.returns.push(this.returns[i]);
        }
    }

    get request_items(): InvoicedRequestItem[] {
        let res: InvoicedRequestItem[] = [];
        const groupsWithRentals = this.rental_groups.filter(group => (group.rentals.length));

        return res.concat(this._ungrouped_rentals).concat(groupsWithRentals);
    }

    get return_items(): InvoicedReturnItem[] {
        let res: InvoicedReturnItem[] = [];
        const groupsWithReturns = this.rental_groups.filter(group => (group.returns.length));

        return res.concat(this._ungrouped_returns).concat(groupsWithReturns);
    }

    get lost_items(): InvoicedReturnItem[] {
        let res: InvoicedReturnItem[] = [];
        const ungroupedReturns = this._ungrouped_returns.filter(aReturn => (aReturn.lost ));
        const rentalGroups = this.rental_groups.filter(group => (group.lost_returns.length));

        return res.concat(ungroupedReturns).concat(rentalGroups);
    }

    get rate_adjusted_rentals(): InvoicedRequestItem[] {
        let res: InvoicedRequestItem[] = [];
        const ungroupedRentals = this._ungrouped_rentals.filter(rental => (rental.rate_adjustment ));
        const rentalGroups = this.rental_groups.filter(group => (group.rate_adjusted_rentals.length));

        return res.concat(ungroupedRentals).concat(rentalGroups);
    }

    get rate_adjusted_returns(): InvoicedReturnItem[] {
        let res: InvoicedReturnItem[] = [];
        // Rate adjustments are not applied if the item was lost.
        const ungroupedReturns = this._ungrouped_returns.filter(aReturn => (aReturn.rate_adjustment && !aReturn.lost));
        const rentalGroups = this.rental_groups.filter(group => (group.rate_adjusted_returns.length));

        return res.concat(ungroupedReturns).concat(rentalGroups);
    }

    get minimum_adjusted_returns(): InvoicedReturnItem[] {
        let res: InvoicedReturnItem[] = [];
        // Minimum adjustments are not applied if the item was lost.
        const ungroupedReturns = this._ungrouped_returns.filter(aReturn => (aReturn.minimum_adjustment && !aReturn.lost));
        const rentalGroups = this.rental_groups.filter(group => (group.minimum_adjusted_returns.length));

        return res.concat(ungroupedReturns).concat(rentalGroups);
    }
}
