import { Injectable, Inject, Component } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogNotificationService } from "@beaconlite/services/notification/dialog-notification.service";
import { VariantDefinition } from "@beaconlite/models/contracts/VariantDefinition.interface";
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { VariantConfigurationData } from "../variant-configuration/variant-configuration.component";

export interface VariantConfigurationModalData {
  itemDefinition: VariantDefinition;
  onVariantConfigUpdate: (variantConfiguration: VariantConfigurationData) => Promise<void>;
}

@Injectable({
  providedIn: 'root'
})
export class VariantConfigurationModalService {

  constructor(protected dialog: MatDialog) {}

  async open(data: VariantConfigurationModalData): Promise<boolean> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      height: 'auto',
      data,
    }

    return this.dialog.open(VariantConfigurationModalComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-variant-configuration-modal',
  templateUrl: './variant-configuration-modal.component.html',
  styleUrls: ['./variant-configuration-modal.component.scss']
})
export class VariantConfigurationModalComponent {

  itemDefinition = this.data.itemDefinition;
  onVariantConfigUpdate = this.data.onVariantConfigUpdate;

  variantConfig: VariantConfigurationData = { 
    variantsEnabled: false, 
    rateVariantsEnabled: false 
  };
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: VariantConfigurationModalData,
    public dialogRef: MatDialogRef<VariantConfigurationModalComponent>,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService
  ){}

  ngOnInit()
  {
    this.variantConfig.variantsEnabled = this.itemDefinition.variants_enabled;
    this.variantConfig.rateVariantsEnabled = this.itemDefinition.variant_rates_enabled;
  }

  async onSave(): Promise<void>
  {
    const shouldContinue: boolean = await this.dialogNotification.confirm({
      textContent: 'Changing Variant Configurations may reset rates and costs of Variants and Variant Properties for this item.'
    });

    if (!shouldContinue) { return; }

    this.loading = true;

    try
    {
      await this.onVariantConfigUpdate(this.variantConfig);
      this.snackbarNotification.saved();
      this.loading = false;
      this.dialogRef.close();
    }
    catch
    {
      this.loading = false;
    }
  }

  onVariantsEnabledChange(): void 
  {
    if (this.variantConfig.variantsEnabled) { return; }

    this.variantConfig.rateVariantsEnabled = false;
  }
}
