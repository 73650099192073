import { BaseModel } from "./Base.model";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'

export class Notification extends BaseModel {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    static STATE_CONFIRMED = 'confirmed';
    static STATE_RECEIVED  = 'received';
    static STATE_SENT      = 'sent';
    static STATE_ERROR     = 'error';


    @dto() id?: string = null;
    @dto() to?: string[] = [];
    @dto() truck_identifier?: string = null;
    @dto() message: string = null;
    @dto() sent_by?: string = null;
    @dto() @date sent_at?: number = null;

    @dto() recipients?: any[] = [];

    get state_name(): string
    {
        let state = Notification.STATE_SENT;

        if ( this.isStateConfirmed() )
        {
            state = Notification.STATE_CONFIRMED
        }
        else if ( this.isStateReceived() )
        {
            state = Notification.STATE_RECEIVED
        }
        else if ( this.isStateError() )
        {
            state = Notification.STATE_ERROR
        }

        return state;
    }

    get get_state_letter(): string
    {
        const state = this.state_name; 
        return state == Notification.STATE_ERROR ? 'x' : state[0]
    }

    isStateConfirmed(): boolean
    {
        return this.recipients.every( recipient => !!recipient.confirmed_at )
    }

    isStateReceived(): boolean
    {
        return this.recipients.every( recipient => recipient.message_status == 'delivered' ||  recipient.message_status == 'confirmed')
    }

    isStateError(): boolean
    {
        return this.recipients.some( recipient => !!recipient.error_code )
    }

}
