import { Discardable } from './mixins/Discardable.mixin'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from '../services/app-injector.service';
import { RouteMap } from '../services/network/route-map.service';
import { VariantRental } from './VariantRental.model';
import { VariantPropertyDefinition } from './VariantPropertyDefinition.model';
import { VariantDefinition } from './contracts/VariantDefinition.interface';
import { VariantRentalPropertyDefinition } from './VariantRentalPropertyDefinition.model';
import { Thumbnail } from './Thumbnail.model';
import { VariantRentalCollection } from './collections/VariantRental.collection';
import { OverrideableItem } from './contracts/OverrideableItem.interface';
import { GroupMeta } from './contracts/GroupMeta.interface';
import { HasIcon } from './mixins/HasIcon.mixin';
import { BaseModel } from './Base.model';
import { HasItemLevel } from './mixins/HasItemLevel.mixin';

const MixinBase = HasIcon(
    HasItemLevel (
        Discardable( BaseModel )
    )
);

export class RentalDefinition extends MixinBase implements VariantDefinition, OverrideableItem {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    static readonly PRICING_RATED = 'rated';
    static readonly PRICING_FIXED = 'fixed';
    static readonly PRICING_FLAT  = 'flat';

    pricingTypes = [
        RentalDefinition.PRICING_RATED,
        RentalDefinition.PRICING_FIXED,
        RentalDefinition.PRICING_FLAT
    ];

    @dto() id?: string = null;
    @dto() variant_configuration_id: string = null;
    @dto() variants_enabled: boolean = false;
    @dto() variant_rates_enabled: boolean = false;
    @dto() department_id: string = null;
    @dto() name: string = null;
    @dto() quantity: number = null;
    @dto() code: string = null;
    @dto() tags: string = null;
    @dto() default_pricing_type = RentalDefinition.PRICING_RATED;
    @dto() description: string = null;
    @dto() daily_rate: number = 0;
    @dto() weekly_discount: number = 0;
    @dto() monthly_discount: number = 0;
    @dto() retail_cost: number = 0;
    @dto() replacement_discount: number = 0;
    @dto() replacement_rental_offset: number = 0;
    @dto() minimum_charge: number = 0;
    @dto() disabled: boolean = false;
    
    @dto(VariantRental) variants: VariantRental[] = [];
    @dto(VariantRentalPropertyDefinition) variant_property_definitions: VariantRentalPropertyDefinition[] = [];
    @dto(Thumbnail) thumbnail: Thumbnail = null;

    // Pivot table properties
    @dto() group_meta: GroupMeta = null;

    async save(): Promise<RentalDefinition> 
    {
        const response = this.exists()
            ? await this.routeMap.updateRentalDefinition( this.id, this.flush() )
            : await this.routeMap.createRentalDefinition( this.flush() );

        return this.map( response.data() );
    }

    async discard(): Promise<RentalDefinition> 
    {
        const response = await this.routeMap.discardRentalDefinition( this.id );
        return this.map( response.data() );
    }

    static async get(id: string): Promise<RentalDefinition> 
    {
        const response = await AppInjector.get(RouteMap).getRentalDefinition(id);
        return new RentalDefinition( response.data() );
    }

    get variant_fields(): string[] 
    {
        return ['default_pricing_type', 'daily_rate', 'retail_cost'];
    }

    get variant_property_fields(): string[] 
    {
        return ['daily_rate', 'retail_cost'];
    }

    // TODO: Find a better home for this functionality.
    // Calculating pricing, creating variants, etc. seems to fall outside this scope.
    static calculateRateFromDiscount(dailyRate: number, discount: number, days: number): number 
    {
        return Math.round( dailyRate * (1 - discount)) * days;
    }

    static calculateDiscountFromTotal(dailyRate: number, desiredTotal: number, days: number): number 
    {
        const desiredDaily = desiredTotal / days;
        return Math.round( ( 1 - (desiredDaily / dailyRate) ) * 10000 ) / 10000;
    }

    applyPricingDefaults(): void
    {
        if (this.default_pricing_type !== RentalDefinition.PRICING_RATED)
        {
            this.weekly_discount = 0;
            this.monthly_discount = 0;
        }

        if (this.default_pricing_type === RentalDefinition.PRICING_FLAT)
        {
            this.minimum_charge = 0; 
        }
    }

    async reload(): Promise<RentalDefinition>
    {
        const response = await this.routeMap.getRentalDefinition(this.id);

        return this.map( response.data() );
    }

    async loadVariants(): Promise<void>
    {
        this.variants = await (new VariantRentalCollection()).all({ 
            variant_configuration_id: this.variant_configuration_id 
        });
    }

    newProp(): VariantPropertyDefinition
    {
        return new VariantRentalPropertyDefinition({
            variant_configuration_id: this.variant_configuration_id,
            ordinal: this.variant_property_definitions.length,
        });
    }

    newVariant(): VariantRental
    {
        return new VariantRental({ 
            variant_configuration_id: this.variant_configuration_id
        });
    }

    async updateVariantPropsOrdinals(): Promise<VariantRentalPropertyDefinition[]>
    {
        const data = this.variant_property_definitions.map(definition => {
            return {
                id: definition.id,
                ordinal: definition.ordinal,
            }
        });

        const response = await this.routeMap.updateVariantProps(data);

        this.variant_property_definitions.forEach(definition => {
            const updatedOrdinal = response.data().find(data => data.id === definition.id).ordinal;
            definition.ordinal = updatedOrdinal;
        });

        return this.variant_property_definitions;
    }

    getPriceTypeLabel(priceType: string): string
    {
        switch (priceType) {
            case RentalDefinition.PRICING_RATED:
                return 'Rated';
            case RentalDefinition.PRICING_FIXED:
                return 'Fixed';
            case RentalDefinition.PRICING_FLAT:
                return 'Flat';
        }
    }

    getDisplayName(): string
    {
        return this.name;
    }
}
