import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Charge, ChargeDefinition  } from '@beaconlite/models';
import { ChargeDefinitionCollection } from '@beaconlite/models/collections';
import { ChargeEditorData } from './charge-editor-data.interface';
import { DialogNotificationService } from '../../../services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '../../../services/notification/snackbar-notification.service';

@Component({
  selector: 'app-charge-editor',
  templateUrl: './charge-editor.component.html',
  styleUrls: ['./charge-editor.component.scss']
})
export class ChargeEditorComponent implements OnInit {
  
  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('chargeSearchTextField') chargeSearchTextField: NgModel;

  minimumDate = this.data.minimumDate;
  original = this.data.original;
  onUpdate = this.data.onChargeUpdate;
  onRemove = this.data.onChargeRemove;
  allowRecurrence = this.data.allowRecurrence;

  loading = false;

  selectedCharge: Charge | ChargeDefinition;
  recurringWithEnd: boolean;

  charge: Charge = new Charge({});
  definition: ChargeDefinition;
  promisedDefinitions: Promise<ChargeDefinition[]>;
  chargeSearchText = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ChargeEditorData,
    public dialogRef: MatDialogRef<ChargeEditorComponent>,
    protected dialogNotifications: DialogNotificationService,
    protected snackbarNotifications: SnackbarNotificationService,
  ) { }

  ngOnInit(): void 
  {
    if (!! this.original)
    {
      this.charge = this.original.copy();
      this.selectedCharge = this.charge;
      this.recurringWithEnd = !!this.charge.recurring_until;
    }
  }

  onQueryDefinitions(): void
  {
    const params = {
      label: this.chargeSearchText,
      order: 'asc',
    }

    this.promisedDefinitions = (new ChargeDefinitionCollection()).all(params)
  }

  onDefinitionSelected(definition: ChargeDefinition)
  {
    this.charge.resetDefinition();
    this.charge.inheritDefinition(definition);
  }

  displaySelectedCharge = (charge?: ChargeDefinition) => 
  {
    if (!!this.original && this.chargeSearchTextField.pristine)
    {
      this.chargeSearchTextField.control.setErrors(null);
      return this.original.label;
    }

    return charge?.label || null;
  }

  isRecurringUntilDisabled(): boolean
  {
    return this.charge.invoiced || !this.charge.recurring || !this.recurringWithEnd;
  }

  canRemove(): boolean
  {
    return this.original && !this.original.locked;
  }

  async remove(): Promise<void|null>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotifications.removeConfirm())
      {
        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.loading = false;
    }
  }

  async stopRecurrence()
  {
    this.loading = true;

    try
    {
      if (! await this.dialogNotifications.confirm()) { return false };
      await this.charge.stopRecurrence();
      this.onUpdate(this.charge);
    }
    finally
    {
      this.loading = false
    }
  }
  
  async onSave(): Promise<void> 
  {
    if (this.editorForm.invalid) return;

    this.loading = true;

    // Create new charge
    if (!this.charge.charge_definition_id)
    {
      this.charge.label = this.chargeSearchText;
    }

    try
    {
      await this.onUpdate(this.charge);
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }
  
}
