<mat-card class="large-card" fxLayout="column">
    <mat-card-title fxLayout="row" fxLayoutAlign=" center">
        <div fxLayout="column">
            <span class="mat-headline">Sale Order</span>
            <span class="mat-caption">{{ request.formatted_serial_id }}</span>
        </div>

        <span fxFlex></span>

        <mat-icon *ngIf="request.locked">lock_outline</mat-icon>

        <button mat-icon-button [matMenuTriggerFor]="requestMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #requestMenu="matMenu">
            <button mat-menu-item (click)="onEdit()">
                <mat-icon>edit</mat-icon>
                Edit
            </button>

            <button mat-menu-item (click)="onDuplicate()">
                <mat-icon>content_copy</mat-icon>
                Duplicate
            </button>

            <button mat-menu-item [disabled]="!request.canAddLineItems()" (click)="onAddRequestItem()">
                <mat-icon>add</mat-icon>
                Add Line Item
            </button>

            <button mat-menu-item *ngIf="!request.locked" (click)="deleteRequest()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
        </mat-menu>
    </mat-card-title>

    <div class="large-card__lead">
        <div fxLayout="row" fxLayoutGap="20px">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Order Date</span>
                <p class="mat-body-1">{{ request.started_at | dateFilter:'dateMedium' }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding *ngIf="request.ordered_by">
                <span class="mat-caption">Ordered By</span>
                <p class="mat-body-1">{{ request.ordered_by }}</p>
            </div>
        </div>

        <mat-divider></mat-divider>
    </div>

    <mat-card-content class="large-card__content">
        <mat-table class="mat-selectable-table mat-draggable-table"
            #requestItemTable
            [dataSource]="request.line_items"
            cdkDropList 
            [cdkDropListData]="request.line_items"
            (cdkDropListDropped)="onRequestItemDropped($event)"
            [fxShow]="request.hasLineItems()">
            <ng-container matColumnDef="drag">
                <mat-header-cell *matHeaderCellDef fxFlex="3"></mat-header-cell>
                <mat-cell *matCellDef="let requestItem" fxFlex="3">
                    <mat-icon class="drag-indicator" *ngIf="!request.locked" cdkDragHandle color="primary">drag_indicator</mat-icon>
                    <mat-icon [fxShow]="requestItem.invalid" color="warn">error_outline</mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="item">
                <mat-header-cell *matHeaderCellDef fxFlex="50">Item</mat-header-cell>
                <mat-cell *matCellDef="let requestItem" fxFlex="50">{{ requestItem.name }}<br>{{ requestItem.detail }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef fxFlex="25">Quantity</mat-header-cell>
                <mat-cell *matCellDef="let requestItem" fxFlex="25"><span *ngIf="!requestItem?.line_items">{{ requestItem.quantity }}</span></mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="rate">
                <mat-header-cell *matHeaderCellDef fxFlex="25">Rate</mat-header-cell>
                <mat-cell *matCellDef="let requestItem" fxFlex="25"><span *ngIf="!requestItem?.line_items">{{ requestItem.rate_override | currencyFilter }} / unit</span></mat-cell>
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="requestItemTableColumns"></mat-header-row>
            <mat-row *matRowDef="let requestItem; columns: requestItemTableColumns" 
                (click)="onEditRequestItem(requestItem)"
                cdkDragLockAxis="y"
                cdkDrag
                [cdkDragData]="requestItem">
            </mat-row>
        </mat-table>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-mini-fab 
                [disabled]="!request.canAddLineItems()"
                (click)="onAddRequestItem()"
                color="secondary">
                <mat-icon>add</mat-icon>
            </button>
        </div>

    </mat-card-content>
</mat-card>
