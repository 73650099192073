import * as moment from 'moment';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dispatch, DispatchedRentalRequest, Issue } from '@beaconlite/models';
import { RentalQuantitiesEditorData } from './rental-quantities-editor-data.interface';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-rental-quantities-editor',
  templateUrl: './rental-quantities-editor.component.html',
  styleUrls: ['./rental-quantities-editor.component.scss']
})
export class RentalQuantitiesEditorComponent implements OnInit {


  original = this.data.original;
  dispatch = this.data.dispatch;
  workOrder = this.data.workOrder;
  onUpdate = this.data.onDispatchUpdate;

  request: DispatchedRentalRequest;
  requestPayload: any;

  saving = false;
  isDelivery = true;
  returnDate: Date = null;
  minimumReturnDate: Date = null;

  actionLabel: string;
  displayType: string;
  getAllButtonLabel: string;


  rentalTableColumns = ['item', 'planned', 'action-label', 'confirmed', 'remaining'];

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: RentalQuantitiesEditorData,
    public dialogRef: MatDialogRef<RentalQuantitiesEditorComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  ngOnInit(): void 
  {
    this.request = this.original.copy();
    this.isDelivery = this.dispatch.type == Dispatch.TYPE_DELIVERY;

    this.actionLabel = this.dispatch.getActionLabel();
    this.displayType = this.dispatch.getDisplayType();
    this.getAllButtonLabel = this.dispatch.getAllButtonLabel();

    this.returnDate = this.dispatch._dates.scheduled_at.date < this.minimumReturnDate
      ? this.minimumReturnDate
      : this.dispatch._dates.scheduled_at.date;

    // Preset confirmed values to actioned
    this.request.rentals.forEach( rental => rental.temps._confirmed_quantity = rental.actioned_quantity || 0);

    this._sortItems();
  }

  actionAll()
  {
      this.request.rentals.forEach( rental => {
        rental.temps._confirmed_quantity = rental.dispatched_quantity
      });
  }

  hasIssueRestraints(): boolean
  {
    return this.workOrder.hasUnresolvedIssues(Issue.SEVERITY_QUARANTINE);
  }

  hasZeroQuantityRentals(): boolean
  {
    return this.requestPayload.rentals.some(rental => rental.actioned_quantity == 0);
  }

  async save(): Promise<void>
  {
    this.saving = true;

    this.request.rentals.forEach(rental => {
      rental.actioned_quantity = rental.temps._confirmed_quantity;
    });

    this.requestPayload = this.request.copy();

    if (!! this.returnDate)
    {
      this.requestPayload.returned_at = moment(this.returnDate).unix();
    }
      
    if (this.isDelivery && this.hasZeroQuantityRentals())
    {
      const dialogData = {
        title: 'Confirm Zero Quantity Rental?',
        textContent: 'You are about to return and override pricing on one or more rentals from this request. Are you sure you would like continue?',
      };

      if (! await this.dialogNotification.confirm(dialogData)) 
      {
        this.saving = false;
        return;
      }
    }

    try
    {
      await this.requestPayload.review();
      await this.onUpdate();
      this.dialogRef.close();
    }
    finally
    {
      this.saving = false;
    }
  }

  protected _sortItems(): void
  {
    // TODO Jira BL-718/BL-720 update for flattened rentals
    this.request.rentals.sort(orderBy('source.position'))
  }
}
