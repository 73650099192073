import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { DispatchedItemReport } from '@beaconlite/models/reports/DispatchedItemReport.model';
import { DispatchedReportItem } from '@beaconlite/models/reports/contracts/DispatchedReportItem.interface';

@Component({
  selector: 'dispatched-item-workorder-row',
  templateUrl: './dispatched-item-work-order-row.component.html',
})
export class DispatchedItemWorkOrderRowComponent implements OnInit, DoCheck {

  @Input() report: DispatchedItemReport;
  @Input() items: DispatchedReportItem[];
  @Input() layer: string;

  nestedLayer: string = '';
  tableColumns = ['serial_id', 'quantity'];

  constructor() { }
  ngOnInit(): void { }

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: DispatchedReportItem): DispatchedReportItem[] | null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }
}
