import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Client, Invoice, RentalRequest, Service, WorkOrder } from '@beaconlite/models';
import { EmailEditorService } from '../../../../components/editors/email-editor/email-editor.service';
import { ReasonEditorService } from '../../../../components/editors/reason-editor/reason-editor.service';
import { LockVaultService } from '../../../../services/lock-vault.service';
import { EmailHistoryService } from '@beaconlite/components/email-history/email-history.service'

@Component({
  selector: 'app-invoice-single',
  templateUrl: './invoice-single.component.html',
  styleUrls: ['./invoice-single.component.scss'],
})
export class InvoiceSingleComponent implements OnInit, OnDestroy {

  @Input() modelId: string;

  loaded = false;
  sending = false;

  title: string;
  invoice = new Invoice();

  formatRentalSerialId = RentalRequest.formatSerialId;
  formatServiceSerialId = Service.formatSerialId;

  constructor(
    public lockVault: LockVaultService,
    protected $state: StateService,
    protected emailEditor: EmailEditorService,
    protected reasonEditor: ReasonEditorService,
    protected emailHistory: EmailHistoryService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    if (!!this.modelId && this.modelId != 'new')
    {
      try
      {
        this.invoice = await Invoice.get(this.modelId);
        this.title = `Invoice #${this.invoice.serial_id}`;   

        await this.lockVault.requestLock(this.invoice);
      }
      finally
      {
        this.loaded = true;
      }
    }
  }

  ngOnDestroy(): void
  {
    this.lockVault.revokeLocks();
  }

  onCancel(): void
  {
    this.$state.go('protected.invoices.schedule');
  }

  onPrint(): void
  {
    const url = this.$state.href('print.invoice', { modelId: this.invoice.id } );
    window.open(url, '_blank');
  }

  canVoid(): boolean
  {
      return this.invoice.voided === false
      && this.invoice.discarded === false;
  }

  async onSend(): Promise<void>
  {
    this.sending = true;

    try
    {
      await this.emailEditor.open({
        defaults: {
          to: [ (await Client.get(this.invoice.client_id)).email ],
          appendableModels: this.invoice.dispatches
        },
        sendableModel: this.invoice,
      });
    }
    finally
    {
      this.sending = false;
    }
  }

  async onShowEmailHistory(): Promise<void>
  {
	  await this.emailHistory.open({
    sendableModel: this.invoice,
    });
  }

  async onMarkSent(): Promise<void>
  {
    await this.invoice.markSent();
  }

  async onDownload(): Promise<void>
  {
    const invoice = await this.invoice.getPdf();
    invoice.pdf.download();
  }

  async onVoid(): Promise<void|null>
  {
    const reason: string = await this.reasonEditor.open();

    if (! reason) return;

    this.invoice.void(reason);
  }

  canDiscard(): boolean
  {
      return this.invoice.discarded === false;
  }

  async onDiscard(): Promise<void|null>
  {
    const reason: string = await this.reasonEditor.open();

    if (! reason) return;

    this.invoice.discard(reason);
  }

  getWorkOrderSerial(): string
  {
      return WorkOrder.formatSerialId( this.invoice.work_order.serial_id );
  }
}
