import { Component, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Helpers as $helpers } from '@beaconlite/services/helpers.service';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { Sage50MappedAccount, Sage50MappedAccountValue } from '../sage50-inferaces/sage50-interfaces';

export interface Sage50AccountEditorData {
  original: Sage50MappedAccount;
  existingAccounts: Sage50MappedAccount[];
  accountUsed: boolean;
  fallbackAccountName: string;
  onUpdate: (accountValue: Sage50MappedAccountValue, key: string) => Promise<void>;
  onDelete: (key: string) => Promise<void>;
}

@Injectable({
  providedIn: 'root'
})
export class Sage50AccountEditorService {

  constructor(protected dialog: MatDialog) {}

  async open(data: Sage50AccountEditorData): Promise<boolean> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data
    }

    return this.dialog.open(Sage50AccountEditorComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-sage50-account-editor',
  templateUrl: './sage50-account-editor.component.html',
  styleUrls: ['./sage50-account-editor.component.scss']
})
export class Sage50AccountEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  original = this.data.original;
  existingAccounts = this.data.existingAccounts;
  accountUsed = this.data.accountUsed;
  fallbackAccountName = this.data.fallbackAccountName;
  onUpdate = this.data.onUpdate;
  onDelete = this.data.onDelete;

  isSaving: boolean = false;
  key: string;
  accountValue: Sage50MappedAccountValue;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: Sage50AccountEditorData,
    public dialogRef: MatDialogRef<Sage50AccountEditorComponent>,
    protected dialogNotification: DialogNotificationService
  ) { }

  ngOnInit(): void {
    if (!! this.original)
    {
      // Convert back into settings.account format.
      this.key = this.original.key;
      this.accountValue = {
        'id': this.original.value.id,
        'description': this.original.value.description
      };
    }
    else
    {
      this.key  = $helpers.generateDirtyId(10);
      this.accountValue = {
        id: '',
        description: ''
      };
    }
  }

  accountIdChange(event): void
  {
    this.accountValue.id = event;

    if (this.existingAccounts.find(element => element.value.id === this.accountValue.id ))
    {
      this.editorForm.controls.accountValueId.setErrors({ alreadyPresent: true });
    }
  }

  async save(): Promise<void>
  {
    if (this.editorForm.invalid) { return; }

    this.isSaving = true; 

    try
    {
      await this.onUpdate(this.accountValue, this.key);
      this.dialogRef.close();
    }
    finally
    {
      this.isSaving = false;
    }
  }

  async delete(): Promise<void>
  {
    this.isSaving = true; 

    if (await this.dialogNotification.removeConfirm())
    { 
      if (this.accountUsed)
      {
        const warningTitle = 'Account In Use';
        const warningMessage = `This account, <strong>${this.original.value.id}</strong>, is currently being used by one or more Branches or Item Overrides. The <strong>${this.fallbackAccountName}</strong> account will replace it in those locations.`;
        
        if (await this.dialogNotification.customWarning(warningTitle, warningMessage))
        {
          try
          {
            await this.onDelete(this.key);
            this.dialogRef.close(); 
          }
          finally
          {
            this.isSaving = false;
          }
        }
      }
      else
      {
        try
        {
          await this.onDelete(this.key);
          this.dialogRef.close(); 
        }
        finally
        {
          this.isSaving = false;
        }
      }
    }
  }
}
