import { HttpResponse } from "../../services/network/http-response";
import { RentalDefinition } from "../RentalDefinition.model";
import { BaseCollection } from "./Base.collection";
import { AppInjector } from "../../services/app-injector.service";
import { RouteMap } from "../../services/network/route-map.service";

export class RentalDefinitionCollection extends BaseCollection<RentalDefinition> {
    
    constructor(options?: any) {
        super(RentalDefinition, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchRentalDefinitions(params);
    }
}
