import * as moment from 'moment';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Branch, Client, Department, Note, Service, VariantService } from '@beaconlite/models';
import { ServiceEditorData } from './service-editor-data.interface';
import { Helpers as $helpers } from '../../../../../../services/helpers.service';
import { AppData } from '../../../../../../services/data/shared-data.departments';
import { DialogNotificationService } from '../../../../../../services/notification/dialog-notification.service';
import { SyncErrorStateMatcher } from '@beaconlite/utilities/sync-error-state-matcher.utility';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { VariantServiceCollection } from '@beaconlite/models/collections';
import { prefs } from '@beaconlite/services/preferences.service';

@Component({
  selector: 'app-service-editor',
  templateUrl: './service-editor.component.html',
  styleUrls: ['./service-editor.component.scss'],
})
export class ServiceEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('serviceSearchTextField') serviceSearchTextField: NgModel;

  workOrder = this.data.workOrder;
  original = this.data.original;
  onUpdate = this.data.onServiceUpdate;
  onRemove = this.data.onServiceRemove;

  loading = false;
  autoFocus = true;
  serviceSearchText = '';
  minDate: Date = null;
  firstNote = new Note({type: Note.TYPE_DISPATCH});
  qtyInputStep = Service.QTY_INPUT_STEP
  qtyStepError = Service.QTY_STEP_ERROR
  service = new Service({});
  selectedService: VariantService = null;
  client: Client;
  branch: Branch;
  departments: Department[] = [];
  promisedServices: Promise<VariantService[]>;

  syncErrorStateMatcher = new SyncErrorStateMatcher();

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ServiceEditorData,
    public dialogRef: MatDialogRef<ServiceEditorComponent>,
    protected appData: AppData, 
    protected dialogNotifications: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    if (this.original.exists())
    {
      this.autoFocus = false;
    }

    // Get departments
    this.departments = await this.appData.departments.getAll();

    this.client = this.workOrder.client;
    this.branch = await Branch.get(this.workOrder.branch.id);

    this.minDate = moment.unix(this.workOrder.minimum_allowable_date).toDate();

    if (!! this.original)
    {
      this.service = this.original.copy();
      this.serviceSearchText = this.service.name;
      this.firstNote = Note.getFirstNote(this.service.notes, Note.TYPE_DISPATCH);
    }

    if (! this.service.department_id)
    {
      this.service.department_id = this.departments.find((department: Department) => department.default).id
    }

    // Pre-load preferenes
    prefs();

    this._sortItems();
  }

  onQueryServices(): void
  {
    this.promisedServices = (new VariantServiceCollection()).all({
      name: this.serviceSearchText,
      order: 'asc',
      exclude_disabled: true,
    }); 
  }

  async calculateTimes(): Promise<void>
  {
    this._validateServiceDates();

    // Abort if Service times should not be automatically calculated
    if (this.service.pricing_type == 'manual') return

    if ( this.service.pricing_type == 'auto' && !this.service.time_override )
    {
        var overTimeSeconds     = 0;
        var regularTimeSeconds  = 0;

        if ( this.service.started_at && this.service.ended_at )
        {
            var startedAt   = moment.unix( this.service.started_at );
            var endedAt     = moment.unix( this.service.ended_at );

            if ( this.service.overtime_rate_applied )
            {
                var overtimeStart  = await prefs('company.business_hours.end');   
                var overtimeEnd    = await prefs('company.business_hours.start');

                if ( this.client.overtime_start )
                {
                    overtimeStart = $helpers.secondsFromMidnight(this.client.overtime_start);
                    overtimeEnd = $helpers.secondsFromMidnight(this.client.overtime_end);
                }

                // Calculate overtime and deduce working time
                overTimeSeconds     = $helpers.calculateOvertime(overtimeStart, overtimeEnd, startedAt, endedAt);
                regularTimeSeconds  = endedAt.diff(startedAt, 'seconds') - overTimeSeconds;
            }
            else
            {
                regularTimeSeconds  = endedAt.diff(startedAt, 'seconds');
            }
        }

        this.service.over_time     = overTimeSeconds;
        this.service.regular_time  = regularTimeSeconds;
    }

    this.calculateTotal();
  }

  calculateTotal() 
  {
    var multiplier      = this.service.quantity || 0;

    var travelHours      = this.service.travel_time / 3600;

    var regularTotal    = 0;
    var regular_rate    = this.service.regular_rate_override;
    var regularHours    = this.service.regular_time / 3600;

    var overtimeTotal   = 0;
    var overtimeRate    = this.service.overtime_rate_override;
    var overtimeHours   = this.service.over_time / 3600;

    if ( this.service.pricing_type == 'unit' )
    {
        this.service.estimated_total = Math.round( multiplier * regular_rate );
        return;
    }

    // Abort if Service total should not be automatically calculated
    if ( this.service.pricing_type != 'auto' )
    {
        return;
    }

    // Calculate regular rate/time total if possible
    if ( !isNaN(regular_rate) && !isNaN(regularHours) )
    {
        regularTotal = regular_rate * (regularHours + travelHours);
    }

    // Calculate overtime rate/time total if possible
    if ( !isNaN(overtimeRate) && !isNaN(overtimeHours) )
    {
        // Calculate using overtime rate if enabled
        if ( this.service.overtime_rate_applied )
        {
            overtimeTotal = overtimeRate * overtimeHours;
        }

        // Otherwise calculate using regular rate if possible
        else if ( !isNaN(regular_rate) )
        {
            overtimeTotal = regular_rate * overtimeHours;
        }
    }

    this.service.estimated_total = Math.round( multiplier * (regularTotal + overtimeTotal) );
  }

  _validateServiceDates()
  {
    if (!! this.service.ended_at &&
           this.service.ended_at <= this.service.started_at)
    {
      this.editorForm.controls.endedAtField.markAsTouched();
      this.editorForm.controls.endTimeField.markAsTouched();

      this.editorForm.controls.endedAtField.setErrors({ 'minDate': true });
      this.editorForm.controls.endTimeField.setErrors({ 'minDate': true });

      return;
    }

    this.editorForm.controls.endedAtField.setErrors(null);
    this.editorForm.controls.endTimeField.setErrors(null);
  }

  areTimesDisabled(): boolean
  {
    return this.service.locked ||
           (this.service.pricing_type == 'auto' && !this.service.time_override);
  }

  onTypeChanged() 
  {
    if (this.service.pricing_type != 'manual')
    {
        this.calculateTimes();
    }
    else
    {
        this.service.regular_rate_override = this.selectedService.regular_rate;
        this.service.overtime_rate_override = this.selectedService.overtime_rate;
        this.service.regular_time = 0;
        this.service.over_time = 0;
        this.service.estimated_total = 0;
    }

    this.changeQuantityInputAttributes();
  }

  changeQuantityInputAttributes()
  {
      if (this.service.pricing_type !== 'unit')
      {
          this.qtyInputStep = Service.QTY_INPUT_STEP
          this.qtyStepError = Service.QTY_STEP_ERROR
      }
      else
      {
          this.qtyInputStep = Service.QTY_INPUT_STEP_UNIT
          this.qtyStepError = Service.QTY_STEP_ERROR_UNIT
      }
  }

  isQuantityDisabled(): boolean
  {
      if (this.service.pricing_type !== 'unit' )
      {
          return this.service.locked || this.service.lock_level >= 1;
      }

      return this.service.locked || this.service.lock_level >= 2;
  }

  displaySelectedService = (service?: VariantService|Service): string =>
  {
    if (!!this.original && this.serviceSearchTextField?.pristine)
    {
      this.serviceSearchTextField.control.setErrors(null);
      return this.original.name;
    }

    return (service as VariantService)?.display_name;
  }

  onServiceSelected(variant: VariantService)
  {
    this.selectedService = variant;
    this.service.resetDefinition();

    if (!! variant)
    {
      this.service.inheritVariant(variant);

      const override = 
        this.client.getServiceOverride(variant.id) || 
        this.branch.getServiceOverride(variant.id);

      if (!! override)
      {
        this.service.applyOverride(override);
      }
    }

    this.calculateTotal();
  }

  canRemove(): boolean
  {
   return !!this.data.original && !this.data.original.locked;
  }

  async remove(): Promise<void>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotifications.removeConfirm())
      {
        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.loading = false;
    }
  }

  async save(): Promise<void|boolean> 
  {
    this._validateServiceDates(); 

    if (! this.service.variant_service_id)
    {
      this.serviceSearchTextField.control.setErrors({ required: true });
    }

    if (this.editorForm.invalid) return false;

    // Add new note to the service
    if( this.firstNote.content && !this.service.hasNote(this.firstNote) )
    {
      this.service.addNote(this.firstNote);
    }
    // Remove the note from the service
    else if (!this.firstNote.content)
    {
        this.service.removeNote(this.firstNote);
    }

    this.loading = true;

    try
    {
      await this.onUpdate(this.service);
      this.dialogRef.close(this.service);
    }
    finally
    {
      this.loading = false;
    }
  }

  protected _sortItems(): void
  {
    this.departments?.sort(orderBy('name'));
  }
}
