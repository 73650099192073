import { Component, Input, OnInit } from '@angular/core';
import { Dispatch } from '@beaconlite/models';
import { AuthenticationService } from '@beaconlite/services/auth/authentication.service';

@Component({
  selector: 'app-upcoming-dispatches-single',
  templateUrl: './upcoming-dispatches-single.component.html',
  styleUrls: ['./upcoming-dispatches-single.component.scss']
})
export class UpcomingDispatchesSingleComponent implements OnInit {

  @Input() modelId: string;

  loading: boolean;
  canEdit: boolean;
  dispatch: Dispatch;

  constructor(
    protected authService: AuthenticationService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.dispatch = await Dispatch.get(this.modelId);
    const account = await this.authService.getCurrentUser();
    const isAdmin = account.isAdmin();
    const isAssignedToDispatch = this.dispatch.employees.some(employee => employee.id === account.employee_id);
    this.canEdit = (isAdmin || isAssignedToDispatch) ? true : false;
    this.loading = false;
  }
}
