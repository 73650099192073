import * as moment from 'moment'
import { interval, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { Account, Branch, Client, Dispatch } from '@beaconlite/models';
import { BranchCollection, DispatchCollection, ClientCollection } from '@beaconlite/models/collections';
import { AuthenticationService } from '../../../../services/auth/authentication.service'
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';
import { Helpers as $helpers } from '../../../../services/helpers.service';

@Component({
  selector: 'app-user-dispatches-index',
  templateUrl: './user-dispatches-index.component.html',
  styleUrls: ['./user-dispatches-index.component.scss']
})
export class UserDispatchesIndexComponent implements OnInit, OnDestroy {

  searchInterval: Subscription;
  isOnIndex = false;
  loading = false;
  collection = new DispatchCollection();
  dispatches = new CollectionDataSource(this.collection);
  selectedModelId: string = this.$stateGlobals.params.modelId;
  account: Account = null;
  selectedBranch: Branch = null;
  branchSearchText = '';
  branches: Branch[];
  selectedClient: Client = null;
  clientSearchText = '';
  clients: Client[];
  selectedStartDate: Date = null; 
  selectedEndDate: Date = null; 
  orderBy = 'date';
  order = 'desc';
  keyword: string;
  tag: string;

  constructor(
    protected $stateGlobals: UIRouterGlobals,
    protected $state: StateService,
    protected $transitions: TransitionService,
    protected auth: AuthenticationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {

    if (this.$state.is('protected.user-dispatches'))
    {
      this.isOnIndex = true;
    }

    this.$transitions.onSuccess({to: 'protected.user-dispatches'}, () => {
      this.isOnIndex = true;
    });

    this.$transitions.onSuccess({from: 'protected.user-dispatches'}, () => {
        this.isOnIndex = false;
    });

    this.account = await this.auth.getCurrentUser();
    this.onSearch();
    this.searchInterval = interval(120000).subscribe(t => this.onSearch());
  }

  ngOnDestroy()
  {
    this.searchInterval.unsubscribe();
  }

  onSearch()
  {
    // // TODO: Should we do something about non-employee accounts?
    if(!this.account.employee_id) {return }

    var params = {
        order:      this.order, 
        order_by:   this.orderBy,
        state:      'active',
        employees:  [this.account.employee_id],
    } as any

    if (this.keyword)
    {
        params.keyword = this.keyword;
    }

    if (this.selectedBranch)
    {
        params.branch_id = this.selectedBranch.id;
    }

    if (this.selectedClient)
    {
        params.client_id = this.selectedClient.id;
    }

    if (this.tag)
    {
        params.tag = this.tag;
    }

    if (this.selectedStartDate)
    {
        params.date_start = $helpers.dateToTimestamp(this.selectedStartDate);
    }

    if (this.selectedEndDate)
    {
        params.date_end = moment(this.selectedEndDate).endOf('day').unix();
    }

    this.collection.search(params);
  }

  // function resize()
  // {
  //     $timeout(function(){ angular.element(window).triggerHandler('resize'); }, 350);
  // }

  view(dispatch: Dispatch)
  {
    this.selectedModelId = dispatch.id;
    this.$state.go('protected.user-dispatches.single', { modelId: dispatch.id });
  }

  async queryBranches(searchText: string)
  {
    this.branches = await (new BranchCollection()).all({name:searchText});
  }

  async queryClients(searchText: string)
  {
    this.clients = await (new ClientCollection()).all({name:searchText});
  }

  clearSearchText(selection: Client | Branch)
  {  
      if (selection instanceof Client)
      {
        this.clientSearchText = '';
      }

      if (selection instanceof Branch)
      {
        this.branchSearchText = '';
      }
  }

  shouldShowDateSeparator(dispatch: Dispatch): boolean
  {
    if (dispatch.id === this.dispatches.cachedData[0].id)
    {
      return true;
    }

    const prevDispatchIndex = this.dispatches.cachedData.findIndex((collectionDispatch) => collectionDispatch.id === dispatch.id) - 1;
    const prevDispatchStartDate = moment.unix(this.dispatches.cachedData[prevDispatchIndex].scheduled_at).date();
    const currDispatchStartDate = moment.unix(dispatch.scheduled_at).date();

    if (prevDispatchStartDate !== currDispatchStartDate)
    {
      return true;
    }
  
    return false;
  }
}
