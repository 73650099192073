import { Ng2StateDeclaration } from '@uirouter/angular';
import { QuickbooksSettingsComponent } from './quickbooks-settings.component';

export const QuickbooksSettingsState: Ng2StateDeclaration = {
  name: 'protected.settings-quickbooks',
  url: '/settings/quickbooks',
  component: QuickbooksSettingsComponent,
  // TODO: make sure this is requiring auth properly
  data: { 
    pageTitle: 'Quickbooks Settings', 
    requiresAuth: true,
  },
}
