import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({ template: '' })
export abstract class GraphBase implements OnInit, OnDestroy
{
    protected _windowListeners: Array<() => void> = []

    constructor(
        protected renderer: Renderer2
    ) { }

    ngOnInit(): void
    {
        this._windowListeners.push(
            this.renderer.listen(window, 'resize', () => this.onResize() )
        );
    }

    ngOnDestroy(): void 
    {
        this._windowListeners.forEach( remove => remove() );
    }

    protected abstract render(): void

    abstract onResize(): void

}
