import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client, Department } from '@beaconlite/models';
import { LineItemRequest } from '@beaconlite/models/LineItemRequest.model';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import * as moment from 'moment';
import { LineItemRequestEditorData } from './line-item-request-editor-data.interface';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

@Component({
    selector: 'app-line-item-request-editor',
    templateUrl: './line-item-request-editor.component.html',
  })
export class LineItemRequestEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  workOrder = this.data.workOrder;
  original = this.data.original;
  onRequestUpdated = this.data.onRequestUpdated;

  loading = false;
  request = new LineItemRequest();

  client: Client = null;
  minDate: Date = null;
  defaultDepartment: Department = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: LineItemRequestEditorData,
    public dialogRef: MatDialogRef<LineItemRequestEditorComponent>,
    protected dialogNotifications: DialogNotificationService,
    protected appData: AppData,
    protected snackbarNotification: SnackbarNotificationService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this.client = this.workOrder.client;
    this.minDate = moment.unix(this.workOrder.minimum_allowable_date).toDate();

    const departments = await this.appData.departments.getAll();
    this.defaultDepartment = departments.find(department => department.default)

    if (!! this.data.original)
    {
      this.request = this.original.copy();
    } 
    else 
    {
      this.request = new LineItemRequest({ 
        ordered_by: this.workOrder.ordered_by,
        started_at: this.workOrder.default_request_start,
        work_order_id: this.workOrder.id,
        department_id: this.defaultDepartment.id
      });
    }
  }

  canRemove(): boolean
  {
    return !!this.data.original && this.data.original.exists() && !this.data.original.locked;
  }

  async save(): Promise<void>
  {
    if (this.editorForm.invalid) { return; }

    this.loading = true;

    try
    {
      await this.request.save();
      this.onRequestUpdated();
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }
}
