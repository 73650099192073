import { Component, Inject, Injectable, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Helpers as $helpers } from "@beaconlite/services/helpers.service";
import { PreferencesData } from "@beaconlite/services/preferences.service";

interface EditorData {
    original: PreferencesData,
    onUpdate: (updatedPrefs: PreferencesData) => Promise<void>,
}

@Component({
    selector: 'app-discount-settings-editor',
    templateUrl: './discount-settings-editor.component.html',
})

export class DiscountSettingsEditorComponent implements OnInit {
  
    @ViewChild('editorForm') editorForm: NgForm;

    original = this.data.original;
    onUpdate = this.data.onUpdate;
    preferences: PreferencesData;
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: EditorData,
        public dialogRef: MatDialogRef<DiscountSettingsEditorService>,
    ) { }

    ngOnInit(): void 
    {
        this.preferences = $helpers.deepCopy( this.original );
    }

    async onSave(): Promise<void>
    {
        if (this.editorForm.invalid) { return }

        this.loading = true;
        
        try
        {
            await this.onUpdate(this.preferences)
            this.dialogRef.close();
        }
        finally
        {
            this.loading = false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class DiscountSettingsEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: EditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            width: '100%',
            maxWidth: 800,
            data,
        }

        return this.dialog.open(DiscountSettingsEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
