<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<mat-card class="medium-card item-definition__card" fxLayout="column" *ngIf="!this.loading">
    <div class="disabled-banner" fxLayout="row" fxLayoutAlign="space-between center" [fxShow]="this.original.disabled">
        This item cannot be added to service requests.
    </div>
    <mat-card-title fxFlex fxLayoutAlign="space-between center">

        <div class="thumbnail-container" *ngIf="!this.original.variants_enabled">
            <img *ngIf="this.original.thumbnail !== null" 
                [src]="this.original.thumbnail.small.url">
            <mat-icon *ngIf="this.original.thumbnail === null" color="primary">image</mat-icon>
        </div>
        
        <span class="mat-headline">{{ this.original.name }}</span>
        <span class="mat-caption service-state-label" *ngIf="this.original.discarded">
            <span>Discarded</span>
        </span>

        <div fxLayoutAlign="center center">
            <button mat-icon-button [matMenuTriggerFor]="mainMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #mainMenu="matMenu">
                <button mat-menu-item
                    *ngIf="!this.original.discarded && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.edit()">
                    <mat-icon>edit</mat-icon>
                    Edit
                </button>
                <button mat-icon-button
                    *ngIf="this.original.discarded"
                    [disabled]="this.loading"
                    (click)="this.restore()"
                    color="primary">
                    <mat-icon>restore</mat-icon>
                    Restore
                </button>
                <button mat-menu-item
                    *ngIf="!this.original.discarded && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.discard()">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>
            </mat-menu>
        </div>
    </mat-card-title>

    <div class="item-definition__card-inner">
        <mat-divider></mat-divider>
        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="100" layoutPadding>
                <span class="mat-caption">Description</span>
                <p class="mat-body-1">{{ this.original.description }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Code</span>
                <p class="mat-body-1">{{ this.original.code }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Tags</span>
                <p class="mat-body-1">{{ this.original.tags }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" *ngIf="this.departments?.length > 1">
            <div fxLayout="column" fxFlex="32" layoutPadding>
                <span class="mat-caption">Default Department</span>
                <p class="mat-body-1">{{ this.departmentName }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Default Pricing</span>
                <p class="mat-body-1">{{ this.original.getPriceTypeLabel(this.original.default_pricing_type) }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Regular Rate</span>
                <p class="mat-body-1">{{ this.original.regular_rate| currencyFilter}}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding *ngIf="this.original.default_pricing_type === this.PRICING_HOUR">
                <span class="mat-caption">Overtime Rate</span>
                <p class="mat-body-1">{{ this.original.overtime_rate | currencyFilter}}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Unit &mdash; Long Form</span>
                <p class="mat-body-1">{{ this.original.unit_long }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Unit &mdash; Short Form</span>
                <p class="mat-body-1">{{ this.original.unit_short }}</p>
            </div>
        </div>

    </div>
</mat-card>

<ng-container *ngIf="!this.loading">
    <app-variant-configuration *ngIf="this.original.exists()"
        [itemDefinition]="this.original"
        [onVariantConfigUpdate]="this.onVariantConfigUpdate">
    </app-variant-configuration>

    <app-variant-prop-list *ngIf="this.original.exists() && this.original.variants_enabled"
        [itemDefinition]="this.original"
        [onPropUpdate]="this.onPropUpdate"
        [onPropRemove]="this.onPropRemove">
    </app-variant-prop-list>

    <app-variant-list *ngIf="this.original.exists() && this.original.variants_enabled"
        [itemDefinition]="this.original"
        [onVariantUpdate]="this.onVariantUpdate"
        [onVariantRemove]="this.onVariantRemove">
    </app-variant-list>
</ng-container>
