import { Constructor } from "@beaconlite/types";
import { BaseModel } from "../Base.model";
import { dto } from "./Dto.decorators";

export function HasItemLevel<T extends Constructor<BaseModel>>( Base: T )
{
    class HasItemLevelMixin extends Base {
        @dto() item_level: string = null;
    }

    return HasItemLevelMixin;
}

export interface HasItemLevel {
    item_level: string
}