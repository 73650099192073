<mat-card class="large-card dispatch dispatch-summary {{ this.headerClass() }}" fxLayout="column" (click)="this.onView()">
    <mat-card-title  fxLayout="row" fxLayoutAlign=" enter">
        <div fxLayout="column">
            <span class="mat-headline">{{ this.dispatch.client.name }}</span>
            <span class="mat-caption">{{ this.displayType() }} | {{ this.dispatch.formatted_serial_id }}</span>
        </div>

        <span fxFlex></span>

        <div class="toolbar-status" fxLayout="column">
            <span class="toolbar-status__current capitalize">{{ this.dispatch.state_name }}</span>
        </div>
    </mat-card-title>

    <!-- Dispatch properties -->

    <div class="large-card__lead">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="25">
                <span class="mat-caption">Delivery Time</span>
                <p class="mat-body-1">{{ this.dispatch.scheduled_at | dateFilter:'dateTimeMedium' }}</p>
            </div>

            <div fxLayout="column" fxFlex="25" *ngIf="!this.dispatch.scheduled_end_at"></div>
            <div fxLayout="column" fxFlex="25" *ngIf="this.dispatch.scheduled_end_at">
                <span class="mat-caption">End Time</span>
                <p class="mat-body-1">{{ this.dispatch.scheduled_end_at | dateFilter:'dateTimeMedium' }}</p>
            </div>
      
            
            <div fxLayout="column" fxFlex="25" *ngIf="!this.dispatch.travel_time"></div>
            <div fxLayout="column" fxFlex="25" *ngIf="this.dispatch.travel_time">
                <span class="mat-caption">Travel Time Required</span>
                <p class="mat-body-1">{{ this.dispatch.travel_time | timeFilter:'minutes' }}</p>
            </div>
       
            <div fxLayout="column" fxFlex="25" [fxShow]="this.dispatch.truck_identifier">
                <span class="mat-caption">Vehicle</span>
                <p class="mat-body-1">{{ this.dispatch.truck_identifier }}</p>
            </div>
        </div>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50">
                <span class="mat-caption">Location</span>
                <p class="mat-body-1">{{ this.dispatch.location }}</p>
            </div>
        </div>

        <mat-divider [fxShow]="shouldShowDepartment"></mat-divider>

        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50" *ngIf="shouldShowDepartment" layoutPadding>
                <span class="mat-caption">Departments</span>
                <p class="mat-body-1">{{ this.displayDepartments }}</p>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="employees-section" fxLayout="row" 
            [style.visibility]="this.dispatch.hasEmployees() ? 'visible' : 'hidden'"> 
            <div fxLayout="column" fxFlex>
                <span class="mat-caption">Assigned to</span>
                <p class="mat-body-1" fxLayout="row">
                    <span *ngFor="let employee of this.dispatch.employees; last as isLast" fxLayout="row" fxLayoutAlign=" center">
                        <span class="employee-notification__status employee-notification__status--{{ employee.temps.notification_status.status_name }}"
                            *ngIf="!employee.dispatch_details.last_viewed_at && !!employee.temps.notification_status"
                            [matTooltip]="employee.temps.notification_status.status_name"
                            matTooltipPosition="above"
                            matTooltipClass="employee-notification-status-tooltip">
                            {{ employee.temps.notification_status.status_letter }}
                        </span>
                        <mat-icon *ngIf="employee.dispatch_details.last_viewed_at" 
                            color="primary"
                            matTooltip="Accessed"
                            matTooltipPosition="above">
                            visibility
                        </mat-icon>
                        <p class="mat-body-1">{{ employee.fullname }}</p>
                        <span *ngIf="!isLast">,&nbsp;&nbsp;</span>
                    </span>
                </p>     
            </div>
        </div>
    </div>
</mat-card>
