<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.onSave()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Discount Settings</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Week Length</mat-label>
                <input type="number"
                [(ngModel)]="this.preferences.pricing.week_threshold"
                name="weekThreshold"
                #weekThreshold="ngModel"
                [minNum]="1"
                [maxNum]="this.preferences.pricing.month_threshold - 1"
                matInput
                required>
                <mat-error *ngIf="this.weekThreshold.hasError('required')">Week length is required</mat-error>
                <mat-error *ngIf="this.weekThreshold.hasError('minNum')">Week length must be greater than 1 day.</mat-error>
                <mat-error *ngIf="this.weekThreshold.hasError('maxNum')">Week length must be less than 1 month.</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Month Length</mat-label>
                <input type="number"
                [(ngModel)]="this.preferences.pricing.month_threshold"
                name="monthThreshold"
                #monthThreshold="ngModel"
                [minNum]="this.preferences.pricing.week_threshold + 1"
                matInput
                required>
                <mat-error *ngIf="this.monthThreshold.hasError('required')">Month length is required</mat-error>
                <mat-error *ngIf="this.monthThreshold.hasError('minNum')">Month length must be greater than 1 week.</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner mode="indeterminate" *ngIf="this.loading" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>
