<mat-card class="large-card" fxLayout="column">
    <mat-card-title fxLayout="row" fxLayoutAlign=" center">
        <div fxLayout="column">
            <span class="mat-headline">Rental Request</span>
            <span class="mat-caption">{{ request.formatted_serial_id }}</span>
        </div>

        <span fxFlex></span>

        <div class="toolbar-status" fxLayout="column">
            <span class="toolbar-status__current">{{ request.state_name }}</span>
        </div>
        <mat-icon *ngIf="request.locked">lock_outline</mat-icon>

        <button mat-icon-button [matMenuTriggerFor]="requestMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #requestMenu="matMenu">
            <button mat-menu-item (click)="onEdit()">
                <mat-icon>edit</mat-icon>
                Edit
            </button>

            <button mat-menu-item (click)="onDuplicate()">
                <mat-icon>content_copy</mat-icon>
                Duplicate
            </button>

            <button mat-menu-item [disabled]="!request.canAddRequestItems()" (click)="onAddRequestItem()">
                <mat-icon>add</mat-icon>
                Add Rental Item
            </button>

            <button mat-menu-item [disabled]="!canAddSheets()" (click)="onAddSheet()">
                <mat-icon>reply</mat-icon>
                Return Items
            </button>

            <button mat-menu-item [disabled]="!request.canAddCharges()" (click)="onAddCharge()">
                <mat-icon>attach_money</mat-icon>
                Additional Charge
            </button>

            <button mat-menu-item [disabled]="!canAddNotes()" (click)="onAddNote()">
                <mat-icon>note_add</mat-icon>
                Add Note
            </button>

            <button mat-menu-item *ngIf="!request.locked" (click)="onRequestRemove()">
                <mat-icon>delete</mat-icon>
                Delete
            </button>
        </mat-menu>
    </mat-card-title>

    <div class="large-card__lead">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50" layoutPadding>
                <span class="mat-caption">Location</span>
                <p class="mat-body-1">{{ request.location }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Start Date</span>
                <p class="mat-body-1">{{ request.started_at | dateFilter:'dateMedium' }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">End Date</span>
                <p class="mat-body-1">{{ displayEndDate() }}</p>
            </div>
        </div>

        <div fxLayout="row" *ngIf="request.ordered_by">
            <div fxLayout="column" fxFlex="50" layoutPadding>
                <span class="mat-caption">Ordered By</span>
                <p class="mat-body-1">{{ request.ordered_by }}</p>
            </div>
        </div>

        <div fxLayout="row" *ngIf="shouldShowDepartment">
            <div fxLayout="column" fxFlex="50" layoutPadding>
                <span class="mat-caption">Department</span>
                <p class="mat-body-1">{{ request.department?.name }}</p>
            </div>
        </div>

        <mat-divider></mat-divider>
    </div>

    <mat-card-content class="large-card__content">

        <!--  Rentals -->
        <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="request.hasRequestItems()">
            <span class="mat-subheading-2">Rentals</span>
            <app-flattened-view-toggle
                [isFlattenedView]="isFlattenedView"
                (onToggled)="onFlattenedViewToggledHandler($event)">
            </app-flattened-view-toggle>
        </div>

        <ng-container *ngIf="this.isFlattenedView; then flattenedTable; else groupedTable">
        </ng-container>

        <ng-template #flattenedTable>

            <mat-table class="mat-table"
                #requestItemTable
                [dataSource]="getArrayFromMapIterable(this.flattenedRentals.values())"
                [fxShow]="request.hasRequestItems()">

                <ng-container matColumnDef="item">
                    <mat-header-cell *matHeaderCellDef fxFlex="35">Item</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem" fxFlex="35">{{ requestItem.rentals[0].name }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="detail">
                    <mat-header-cell *matHeaderCellDef>Detail</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem">{{ requestItem.rentals[0].detail }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem">{{ requestItem.quantity }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="remaining">
                    <mat-header-cell *matHeaderCellDef>Remaining</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem">{{ requestItem.quantity_remaining }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="pricing">
                    <mat-header-cell *matHeaderCellDef>Pricing</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem">{{ requestItem.pricing_type }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="rate">
                    <mat-header-cell *matHeaderCellDef>Rate</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem">{{ requestItem.daily_rate_override | currencyFilter }} / unit</mat-cell>
                </ng-container>
                
                <mat-header-row *matHeaderRowDef="flatRequestItemTableColumns"></mat-header-row>
                <mat-row *matRowDef="let requestItem; columns: flatRequestItemTableColumns"
                [matTooltip]="'Editing/Adding disabled in flattened view'"
                matTooltipPosition="above"
                [matTooltipShowDelay]="500">
                </mat-row>

            </mat-table>
        </ng-template>

        <ng-template #groupedTable>
            <mat-table class="mat-selectable-table mat-draggable-table"
                #requestItemTable
                [dataSource]="request.request_items"
                cdkDropList 
                [cdkDropListData]="request.request_items"
                (cdkDropListDropped)="onRequestItemDropped($event)"
                [fxShow]="request.hasRequestItems()"
                multiTemplateDataRows>
                <ng-container matColumnDef="drag">
                    <mat-header-cell *matHeaderCellDef fxFlex="3"></mat-header-cell>
                    <mat-cell *matCellDef="let requestItem" fxFlex="3">
                        <mat-icon class="drag-indicator" *ngIf="!request.locked" cdkDragHandle color="primary">drag_indicator</mat-icon>
                        <mat-icon [fxShow]="requestItem.invalid" color="warn">error_outline</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="item">
                    <mat-header-cell *matHeaderCellDef fxFlex="35">Item</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem" fxFlex="35">{{ requestItem.name }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="detail">
                    <mat-header-cell *matHeaderCellDef>Detail</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem"><span *ngIf="!requestItem?.rentals">{{ requestItem.detail }}</span></mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem"><span *ngIf="!requestItem?.rentals">{{ requestItem.quantity }}</span></mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="remaining">
                    <mat-header-cell *matHeaderCellDef>Remaining</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem"><span *ngIf="!requestItem?.rentals">{{ requestItem.quantity_remaining }}</span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="pricing">
                    <mat-header-cell *matHeaderCellDef>Pricing</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem"><span *ngIf="!requestItem?.rentals">{{ requestItem.pricing_type }}</span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="rate">
                    <mat-header-cell *matHeaderCellDef>Rate</mat-header-cell>
                    <mat-cell *matCellDef="let requestItem"><span *ngIf="!requestItem?.rentals">{{ requestItem.daily_rate_override | currencyFilter }} / unit</span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="groupItem">
                    <mat-cell *matCellDef="let requestItem" [fxShow]="requestItem?.rentals">
                        <mat-table class="mat-selectable-table"
                            fxFlex="100"
                            [dataSource]="requestItem.rentals">

                            <ng-container matColumnDef="item">
                                <mat-cell *matCellDef="let groupItem" fxFlex="35">{{ groupItem.name }}</mat-cell>
                            </ng-container>
                            
                            <ng-container matColumnDef="detail">
                                <mat-cell *matCellDef="let groupItem">{{ groupItem.detail }}</mat-cell>
                            </ng-container>
                            
                            <ng-container matColumnDef="quantity">
                                <mat-cell *matCellDef="let groupItem">{{ groupItem.quantity }}</mat-cell>
                            </ng-container>
                            
                            <ng-container matColumnDef="remaining">
                                <mat-cell *matCellDef="let groupItem">{{ groupItem.quantity_remaining }}</mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="pricing">
                                <mat-cell *matCellDef="let groupItem">{{ groupItem.pricing_type }}</mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="rate">
                                <mat-cell *matCellDef="let groupItem">{{ groupItem.daily_rate_override | currencyFilter }} / unit</mat-cell>
                            </ng-container>

                            <mat-row *matRowDef="let groupItem; columns: groupItemTableColumns" (click)="onEditRequestItem(groupItem)">
                            </mat-row>
                        </mat-table>
                    </mat-cell>
                </ng-container>
                
                <mat-header-row *matHeaderRowDef="requestItemTableColumns"></mat-header-row>
                <mat-row *matRowDef="let requestItem; columns: requestItemTableColumns" 
                    (click)="onEditRequestItem(requestItem)"
                    cdkDragLockAxis="y"
                    cdkDrag
                    [cdkDragData]="requestItem">
                </mat-row>

                <mat-row *matRowDef="let groupItem; columns: ['groupItem']" class="inner-row"></mat-row>

            </mat-table>
        </ng-template>

        <ng-container *ngIf="this.isFlattenedView; then flattenedViewButton; else groupedViewButton">
        </ng-container>

        <ng-template #flattenedViewButton>
            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-mini-fab
                    [matTooltip]="'Editing/Adding disabled in flattened view'"
                    matTooltipPosition="above"
                    [disabled]="true"
                    (click)="void"
                    color="secondary">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </ng-template>

        <ng-template #groupedViewButton>
            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-mini-fab 
                    [disabled]="!request.canAddRequestItems()"
                    (click)="onAddRequestItem()"
                    color="secondary">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </ng-template>

        <!--  Returns -->

        <mat-divider *ngIf="hasReturns()"></mat-divider>

        <div fxLayout="row" *ngIf="hasReturns()">
            <span class="mat-subheading-2">Returns</span>
        </div>

        <mat-table class="mat-selectable-table"
            [dataSource]="returnLineItems" 
            [fxShow]="hasReturns()">

            <ng-container matColumnDef="error">
                <mat-header-cell fxFlex="6" mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell fxFlex="6" *matCellDef="let sheet">
                    <mat-icon [fxShow]="sheet.invalid" color="warn">error_outline</mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                <mat-cell *matCellDef="let sheet">{{ sheet.returned_at | dateFilter:'dateMedium' }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="returned">
                <mat-header-cell *matHeaderCellDef>Returned</mat-header-cell>
                <mat-cell *matCellDef="let sheet">{{ sheet.quantity_returned }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="lost">
                <mat-header-cell *matHeaderCellDef>Lost</mat-header-cell>
                <mat-cell *matCellDef="let sheet">{{ sheet.quantity_lost }}</mat-cell>
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="returnTableColumns"></mat-header-row>
            <mat-row *matRowDef="let sheet; columns: returnTableColumns" (click)="onEditSheet(sheet)"></mat-row>
        </mat-table>

        <!-- Charges -->

        <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="request.hasCharges()">
            <span class="mat-subheading-2">Charges</span>
        </div>
        <app-charges-table [fxShow]="request.hasCharges()"
            [charges]="request.charges"
            (chargeSelected)="onEditCharge($event)">
        </app-charges-table>

        <!-- Notes -->

        <div fxLayout="row" fxLayoutAlign="space-between start" [fxShow]="request.hasNotes()">
            <span class="mat-subheading-2">Notes</span>
        </div>
        <app-notes-table [fxShow]="request.hasNotes()"
            [notes]="request.notes"
            (noteSelected)="onEditNote($event)">
        </app-notes-table>
    </mat-card-content>
</mat-card>
