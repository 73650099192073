import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentalReportItem } from '@beaconlite/models/reports/contracts/RentalReportItem.interface';
import { RentalItemReport } from '@beaconlite/models/reports/RentalItemReport.model';
@Component({
  selector: 'rental-item-workorder-row',
  templateUrl: './work-order-row.component.html',
})
export class WorkOrderRowComponent implements OnInit, DoCheck {

  @Input() report: RentalItemReport;
  @Input() items: RentalReportItem[];
  @Input() layer: string;
  @Output() itemClick = new EventEmitter<any>();

  nestedLayer = '';
  tableColumns = ['serial', 'rented', 'max', 'lost'];
  
  constructor() { }
  ngOnInit(): void {}

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: RentalReportItem): RentalReportItem[]|null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }

  onItemClicked(item: RentalReportItem)
  {
    this.itemClick.emit(item);
  }
}
