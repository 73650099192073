import { Component, Input, OnInit } from '@angular/core';
import { WorkOrder } from '@beaconlite/models';
import { LineItemRequestEditorService } from '@beaconlite/views/protected/work-orders/work-order-single/tab-line-items/line-item-request-editor/line-item-request-editor.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-tab-line-items',
  templateUrl: './tab-line-items.component.html',
  styleUrls: ['./tab-line-items.component.scss']
})
export class TabLineItemsComponent implements OnInit {

  @Input() workOrder: WorkOrder;
  @Input() shouldShowDepartment: boolean;
  @Input() onReload: () => Promise<void>;

  constructor(
    protected lineItemRequestEditor: LineItemRequestEditorService,
  ) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  async onAddLineItemRequest(): Promise<boolean>
  {
    if (! this.workOrder.canAddLineItemRequests()) { return false; }

    return this.lineItemRequestEditor.open({
      workOrder: this.workOrder,
      original: null,
      onRequestUpdated: this.onRequestUpdated,
    });
  }

  onRequestUpdated = async (): Promise<void> => 
  {
    this.onReload();
  }

  protected _sortItems(): void
  {
    this.workOrder.line_item_requests.sort(orderBy('started_at'));
  }

}
