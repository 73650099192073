import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { DispatchEditorService } from './dispatch-editor/dispatch-editor.service';
import { WorkOrder } from '@beaconlite/models';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'work-order-tab-dispatches',
  templateUrl: './tab-dispatches.component.html',
})
export class TabDispatchesComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;
  @Input() shouldShowDepartment: boolean;

  constructor(protected dispatchEditor: DispatchEditorService) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  async onAddDispatch()
  {
    if (! this.workOrder.canAddDispatches()) return false;

    return this.dispatchEditor.open({
      workOrder: this.workOrder,
      original: null,
      onDispatchUpdate: this.onDispatchUpdate,
      onDispatchRemove: this.onDispatchRemove, 
    });
  }

  onDispatchUpdate = async (): Promise<void> => 
  {
    await this.workOrder.reload();
  }

  onDispatchRemove = async (): Promise<void> => 
  {
    await this.workOrder.reload();
  }

  protected _sortItems(): void
  {
    this.workOrder.dispatches.sort(orderBy('-scheduled_at', '-created_at')); 
  }
}
