<mat-selection-list (selectionChange)="this.onOptionSelected($event)">
    <mat-list-option *ngFor="let item of this.items" [value]="item.document">
        <div matListAvatar class="doc-list-item__avatar">
            <mat-icon>image</mat-icon>
        </div>
        <div class="mat-list-text" fxLayout="column">
            <h3>{{ item.document.attachment.name }}</h3>
            <p>{{ item.document.attachment.description }}</p>
        </div>
    </mat-list-option>
</mat-selection-list>
