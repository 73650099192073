<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="send()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="start center">
        <h2 class="mat-title">Email</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding" fxLayout="column">
        <mat-form-field>
            <mat-label>To</mat-label>
            <mat-chip-list #toChips>
                <ng-container *ngFor="let address of this.to">
                    <mat-chip *ngIf="address"
                        [removable]="true"
                        (removed)="this.removeChip(this.to, address)">
                        {{ address }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
                <input [matChipInputAddOnBlur]="true"
                    [matChipInputFor]="toChips"
                    (matChipInputTokenEnd)="addChip(this.to, $event)">
            </mat-chip-list>
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>Cc</mat-label>
            <mat-chip-list #ccChips>
                <ng-container *ngFor="let address of this.cc">
                    <mat-chip *ngIf="address"
                        [removable]="true"
                        (removed)="this.removeChip(this.cc, address)">
                        {{ address }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
                <input [matChipInputAddOnBlur]="true"
                    [matChipInputFor]="ccChips"
                    (matChipInputTokenEnd)="addChip(this.cc, $event)">
            </mat-chip-list>
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>Bcc</mat-label>
            <mat-chip-list #bccChips>
                <mat-container *ngFor="let address of this.bcc">
                    <mat-chip *ngIf="address"
                        [removable]="true"
                        (removed)="this.removeChip(this.bcc, address)">
                        {{ address }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-container>
                <input [matChipInputAddOnBlur]="true"
                    [matChipInputFor]="bccChips"
                    (matChipInputTokenEnd)="addChip(this.bcc, $event)">
            </mat-chip-list>
        </mat-form-field>

        <mat-checkbox [(ngModel)]="this.shouldAppendModel"
            name="shouldAppendModelCheckbox"
            [disabled]="!this.appendableModels.length">
            <span>Append dispatches</span>
        </mat-checkbox>
        <p class="mat-body-2" *ngIf="!!this.appendableModels.length">
            <span *ngFor="let model of this.appendableModels; last as isLast">
                {{ this.formatDispatchSerialId(model.dispatch_serial_id) }}
                <span *ngIf="!isLast">,&nbsp;</span>
            </span>
        </p>    
        <p class="mat-body-2" *ngIf="!this.appendableModels.length">
            No associated dispatches found for this invoice.
        </p>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.sending" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.sending">
            Send
        </button>
    </mat-dialog-actions>
</form>
