import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Invoice, RentalRequest, Service, WorkOrder } from '@beaconlite/models';

@Component({
  selector: 'app-invoice-print',
  templateUrl: './invoice-print.component.html',
})
export class InvoicePrintComponent implements OnInit {

  @Input() modelId: string;

  loaded = false;

  invoice: Invoice;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
  ) {}

  async ngOnInit(): Promise<void> 
  {
    if (!! this.modelId)
    {
      try
      {
        this.invoice = await Invoice.get(this.modelId);
        const filename: string = this.invoice.client.name.toLowerCase() + '-' + this.invoice.formatted_serial_id;
        this.document.title = filename.replace(/\s/g, '_');
      }
      finally
      {
        this.loaded = true;
      }
    }
  }

  getType(): string
  {
      return "Invoice";
  }

  // TODO: the following functions are duplicates from invoice component. 
  //  - Create a base component?
  //  - Create a base service?

  formatRentalSerialId(serialId: string): string
  {
    return RentalRequest.formatSerialId(serialId);
  }

  formatServiceSerialId(serialId: string): string
  {
    return Service.formatSerialId(serialId);
  }

  getWorkOrderSerial(): string
  {
      return WorkOrder.formatSerialId(this.invoice.work_order.serial_id);
  }

}
