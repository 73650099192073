<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="onSave()" novalidate>

    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Charge Editor</h2>
        <div fxLayoutAlign="center center">
            <div class="toolbar-status" *ngIf="charge.invoiced">
                <span class="toolbar-status__current">Invoiced</span>
            </div>
            <mat-icon *ngIf="charge.locked">lock_outline</mat-icon>        
        </div>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Select or create a charge</mat-label>
                <input type="text"
                    [(ngModel)]="chargeSearchText"
                    name="chargeSearchTextField"
                    #chargeSearchTextField="ngModel"
                    [matAutocomplete]="chargeAuto"
                    (ngModelChange)="onQueryDefinitions()"
                    matInput
                    required
                    [disabled]="charge.locked">
                <mat-autocomplete #chargeAuto="matAutocomplete"
                    (optionSelected)="onDefinitionSelected($event.option.value)"
                    [displayWith]="displaySelectedCharge">
                    <mat-option *ngFor="let definition of promisedDefinitions | async" [value]="definition">
                        {{ definition.label }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="chargeSearchTextField.hasError('required')">Charge is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Description</mat-label>
                <textarea rows="4"
                    [(ngModel)]="charge.description"
                    name="descriptionField"
                    #descriptionField="ngModel"
                    matInput
                    [disabled]="charge.locked">
                </textarea>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="charge.discount"
                    name="chargeTypeField"
                    #chargeTypeField="ngModel"
                    [disabled]="charge.locked"
                    required>
                    <mat-option [value]="true">Discount</mat-option>
                    <mat-option [value]="false">Charge</mat-option>
                </mat-select>
                <mat-error *ngIf="chargeTypeField.hasError('required')">Type is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>Value Type</mat-label>
                <mat-select [(ngModel)]="charge.percent"
                    name="chargeValueTypeField"
                    #chargeValueTypeField="ngModel"
                    [disabled]="charge.locked"
                    required>
                    <mat-option [value]="true">Percent</mat-option>
                    <mat-option [value]="false">Dollar</mat-option>
                </mat-select>
                <mat-error *ngIf="chargeValueTypeField.hasError('required')">Value Type is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex *ngIf="!charge.percent">
                <mat-label>Value</mat-label>
                <input type="text"
                    [(ngModel)]="charge.flat_value"
                    name="flatValueField"
                    #flatValueField="ngModel"
                    currencyInput
                    minNum="0"
                    [disabled]="charge.locked"
                    required
                    matInput>
                <mat-error *ngIf="flatValueField.hasError('required')">Value is required.</mat-error>
                <mat-error *ngIf="flatValueField.hasError('minNum')">Value must be at least $ 0.00.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex *ngIf="charge.percent">
                <mat-label>Value</mat-label>
                <input type="text"
                    [(ngModel)]="charge.percent_value"
                    name="percentValueField"
                    #percentValueField="ngModel"
                    percentInput
                    minNum="0"
                    maxNum="1"
                    [disabled]="charge.locked"
                    required
                    matInput>
                <mat-error *ngIf="percentValueField.hasError('required')">Value is required.</mat-error>
                <mat-error *ngIf="percentValueField.hasError('minNum')">Value must be at least 0.00 %.</mat-error>
                <mat-error *ngIf="percentValueField.hasError('maxNum')">Value cannot exceed 100.00 %.</mat-error>
            </mat-form-field>

        </div>
        
        <div class="fieldset" fxLayout="column" fxLayoutGap="20px" *ngIf="allowRecurrence">
            <div fxLayout="row">
                <h2 class="mat-subheading-2">Recurrence Settings</h2>
            </div>

            <div fxLayout="row">
                <mat-slide-toggle [(ngModel)]="charge.recurring" 
                    name="recurringField"
                    [disabled]="charge.locked">
                    Apply to every Invoice
                </mat-slide-toggle>
            </div>

            <div fxLayout="row">
                <mat-slide-toggle fxFlexAlign="center"
                    [(ngModel)]="recurringWithEnd" 
                    name="recurringwithEndField"
                    [disabled]="charge.invoiced || !charge.recurring">
                    Only apply to Invoices starting before: &nbsp;
                </mat-slide-toggle>

                <mat-form-field fxFlex="25">
                    <mat-label>Date</mat-label>
                    <input matInput 
                        [matDatepicker]="recurringUntilPicker" 
                        [(ngModel)]="charge._dates.recurring_until.date" 
                        (focus)="recurringUntilPicker.open()"
                        name="recurringUntilField"
                        #recurringUntilField="ngModel"
                        [disabled]="isRecurringUntilDisabled()"
                        [min]="minimumDate"
                        [required]="recurringWithEnd && !isRecurringUntilDisabled()">
                    <mat-datepicker-toggle matSuffix [for]="recurringUntilPicker"></mat-datepicker-toggle>
                    <mat-datepicker #recurringUntilPicker></mat-datepicker>
                    <mat-error *ngIf="recurringUntilField.hasError('required')">This is required.</mat-error>
                    <mat-error *ngIf="recurringUntilField.hasError('matDatepickerMin')">Date is too early.</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" *ngIf="charge.locked && !charge.invoiced">
                <button mat-raised-button 
                    type="button"
                    (click)="stopRecurrence()">
                    Stop Recurring
                </button>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <button type="button" mat-icon-button *ngIf="canRemove()" (click)="remove()">
            <mat-icon color="primary">delete</mat-icon>
        </button>
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="charge.invoiced || loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
