import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Account } from '@beaconlite/models';
import { StateService } from '@uirouter/angular';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { SettingsService } from '../../services/settings.service';
import { LoginState } from '../public/login/login.state';
import { CompanySettings } from './company-settings.interface';

@Component({
  selector: 'app-protected-view',
  templateUrl: './protected-view.component.html',
  styleUrls: ['./protected-view.component.scss'],
  // Turned off encapsulation for flyout menu styling.
  encapsulation: ViewEncapsulation.None,
})
export class ProtectedViewComponent implements OnInit {

  currentNavItem: string = 'invoices';
  company?: CompanySettings;
  account?: Account;

  constructor(
    protected authService: AuthenticationService,
    protected settingsService: SettingsService,
    protected $state: StateService,
  ) { }

  async ngOnInit() 
  {
    this.company = this.settingsService.get('company_config');
    this.account = await this.authService.getCurrentUser();
  }

  isAdmin(): boolean 
  {
    return this.account?.isAdmin();
  }

  onLogout(event: MouseEvent): void 
  {
    event.preventDefault();
    this.authService.logout();

    // TODO: use Ng2StateDeclaration object (rather than string name) in the rest of app
    this.$state.go(LoginState);
  }

}
