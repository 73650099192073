import { AfterContentChecked, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms"; 

/* 

This directive sets step value on an input, and validates that the inputs value conforms to step value
Most of the code here was taken directly from AngularJs input step validation

https://docs.angularjs.org/api/ng/input/input%5Bnumber%5D
https://github.com/angular/angular.js/blob/6e55b890de282ed5ad8984b9494eea2b3e4929c5/src/ng/directive/input.js#L1610
*/
@Directive({
    selector: '[blStep][ngModel]',
})
export class BlInputStepDirective implements AfterContentChecked, OnChanges {

    @Input('blStep') step: number;

    constructor(
        protected elementRef: ElementRef,
        protected control: NgControl,
    ) {}

    ngAfterContentChecked(): void 
    {
        this.elementRef.nativeElement.step = this.step;
    }

    /**
     * Listens to ngModel changes on host element
     * 
     */
    @HostListener('ngModelChange')
    modelChange()
    {
        this.validateStep()
    }
    
    /**
     * Listens to step value changes
     * 
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges): void 
    {   
        this.elementRef.nativeElement.step = this.step
        this.validateStep()
    }

    /**
     * Sets error on host element if value does not conform to value of provided step.
     * 
     */
    validateStep()
    {
        let value = Number(this.control.value);
        let stepBase = this.step;
        let step = this.step;

        const isNonIntegerValue = !this._isNumberInteger(value);
        const isNonIntegerStepBase = !this._isNumberInteger(stepBase);
        const isNonIntegerStep = !this._isNumberInteger(step);
      
        // Due to limitations in Floating Point Arithmetic (e.g. `0.3 - 0.2 !== 0.1` or
        // `0.5 % 0.1 !== 0`), we need to convert all numbers to integers.
        if (isNonIntegerValue || isNonIntegerStepBase || isNonIntegerStep) 
        {
            const valueDecimals = isNonIntegerValue ? this._countDecimals(value) : 0;
            const stepBaseDecimals = isNonIntegerStepBase ? this._countDecimals(stepBase) : 0;
            const stepDecimals = isNonIntegerStep ? this._countDecimals(step) : 0;
      
            const decimalCount = Math.max(valueDecimals, stepBaseDecimals, stepDecimals);
            const multiplier = Math.pow(10, decimalCount);
      
            value = value * multiplier;
            stepBase = stepBase * multiplier;
            step = step * multiplier;
      
            if (isNonIntegerValue) value = Math.round(value);
            if (isNonIntegerStepBase) stepBase = Math.round(stepBase);
            if (isNonIntegerStep) step = Math.round(step);
        }

        const isStepError: boolean =  !((value - stepBase) % step === 0);

        if (isStepError)
        {
            this.control.control.setErrors({ blStep: true });
        }
        else
        {
            let errors = this.control.control.errors

            if (errors?.blStep)
            {
                delete errors.blStep
            }

            if (!!errors && !Object.keys(errors).length)
            {
                errors = null;
            }

            this.control.control.setErrors(null);
            this.control.control.setErrors(errors);
        }
    }

    private _countDecimals(num: number = 0): number
    {
        const numString = num.toString();
        const decimalSymbolIndex = numString.indexOf('.');
      
        if (decimalSymbolIndex === -1) {
          if (-1 < num && num < 1) {
            // It may be in the exponential notation format (`1e-X`)
            var match = /e-(\d+)$/.exec(numString);
      
            if (match) {
              return Number(match[1]);
            }
          }
      
          return 0;
        }
      
        return numString.length - decimalSymbolIndex - 1;
    }

    private _isNumberInteger(num: number): boolean
    {
        // See http://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript#14794066
        // (minus the assumption that `num` is a number)
        
        // eslint-disable-next-line no-bitwise
        return (num | 0) === num;
    }
}
