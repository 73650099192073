import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Note } from '@beaconlite/models';

@Component({
  selector: 'app-notes-table',
  templateUrl: './notes-table.component.html',
})
export class NotesTableComponent implements OnInit {

  @Input() notes: Note[];
  @Output() noteSelected: EventEmitter<Note> = new EventEmitter<Note>();

  notesTableColumns = ['type', 'date', 'content'];

  constructor() { }

  ngOnInit(): void {}

  onNoteSelected(note: Note)
  {
    this.noteSelected.emit(note);
  }

}
