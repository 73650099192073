import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Account, Department, DispatchRegion, Employee } from '@beaconlite/models';
import { Role } from '@beaconlite/models/contracts/Role.interface';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { AppData } from '../../../../services/data/shared-data.departments';
import { UnsavedChangesService } from '@beaconlite/services/unsaved-changes.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'app-employee-single',
  templateUrl: './employee-single.component.html',
  styleUrls: ['./employee-single.component.scss']
})
export class EmployeeSingleComponent implements OnInit {

  @Input() modelId: string;

  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('passwordField') passwordField: NgModel;
  @ViewChild('passwordConfirmField') passwordConfirmField: NgModel;
  @ViewChild('roleField') roleField: NgModel;

  loading = false;
  locked = false;
  title = 'New Employee';
  employee = new Employee();
  account = new Account({ type: 'admin', active: false });
  roles: any[];
  departments: Department[];
  dispatchRegions: DispatchRegion[];
  
  constructor(
    protected appData: AppData,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
    protected unsavedChanges: UnsavedChangesService,
    protected $state: StateService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.lockAndLoad();

    try
    {
      this.roles = await Account.getAllRoles();
      this.departments = await this.appData.departments.getAll();
      this.dispatchRegions = await DispatchRegion.getAll();

      if (this.departments.length == 1)
      {
        this.employee.department_id = this.departments[0].id;
      }

      this._sortItems();

      if (!! this.modelId && this.modelId != 'new')
      {
        this.employee = await Employee.get(this.modelId);
        this.title = this.employee.fullname;
        this.account = this.employee.account || this.account;
      }

    }
    finally
    {
      this.unlockAndUnload();
    }

    // Register unsaved changes guard
    this.unsavedChanges.setDiscardCheck(() => !this.editorForm.pristine && !this.editorForm.submitted)
  }

  isPasswordRequired(): boolean
  {
    return !this.account.exists() && this.account.active;
  }

  lockAndLoad(): void
  {
      this.loading = true;
      this.locked = true;
  }

  unlockAndUnload(): void
  {
      this.loading = false;
      this.locked = this.employee.discarded;
  }

  triggerFormSubmit()
  {
    this.editorForm.onSubmit(undefined);
  }

  async save(): Promise<void|boolean>
  {
    if (this.isPasswordRequired())
    {
      this.validatePasswordFields();
    }

    if (this.editorForm.invalid) return false;

    // Associating a new account
    if ( !!this.account && !this.account.exists() && this.account.active )
    {
      this.employee.account = this.account;
    }

    const modelExists: boolean = this.employee.exists();

    this.lockAndLoad();

    try
    {
      await this.employee.save();
      this.snackbarNotification.saved();

      if (! modelExists)
      {
        this.$state.go('protected.employees.single', { modelId: this.employee.id });
        return;
      }

      this.title = this.employee.fullname;
      this.account = this.employee.account || this.account;
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  async discard(): Promise<void|boolean>
  {
    if (! await this.dialogNotification.discardConfirm()) return false;

    this.lockAndLoad();

    try
    {
      await this.employee.discard();
      this.$state.go('protected.employees.single', { modelId: 'new' });
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  async restore(): Promise<void|boolean>
  {
    if (! await this.dialogNotification.recoverConfirm()) return false;

    this.lockAndLoad();

    try
    {
      await this.employee.recover();
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  validatePasswordFields()
  {
    if (!!this.editorForm && this.passwordConfirmField.dirty)
    {
      // Reset validity
      this.passwordConfirmField.control.setErrors(null);

      const password = this.account.credentials.password;
      const passwordConfirm = this.account.credentials.password_confirm;

      // Test password strength: length
      if ( password.length < 12 )
      {
        this.passwordConfirmField.control.setErrors({ passwordLength: true });
      }

      // Test password strength: numeric
      if ( !/[0-9]/.test(password) )
      {
          this.passwordConfirmField.control.setErrors({ passwordNumeric: true });
      }

      // Test password strength: alpha lowercase
      if ( !/[a-z]/.test(password) )
      {
          this.passwordConfirmField.control.setErrors({ passwordAlphaLower: true });
      }

      // Test password strength: alpha uppercase
      if ( !/[A-Z]/.test(password) )
      {
          this.passwordConfirmField.control.setErrors({ passwordAlphaUpper: true });
      }

      // Test password mismatch
      if ( password !== passwordConfirm )
      {
          this.passwordConfirmField.control.setErrors({ passwordConfirm: true });
      }
    }
  }

  protected _sortItems(): void
  {
    this.roles?.sort(orderBy('name'));
    this.departments?.sort(orderBy('name'));
  }

  roleCompare( option: Role, value: Role ) : boolean {
    return option.id === value.id;
  }
}
