<div class="request-tab" fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="16px">

    <div class="request-tab__no-requests" 
        *ngIf="!workOrder.hasServices()"
        (click)="onAddService()">
        <img class="request-tab__no-requests__jerry-cone" src="assets/img/jerry-cone.png" />
        <p>You have no services, would you like to create one?</p>
        <img class="request-tab__no-requests__keyboard-shortcut" src="assets/img/keyboard-shortcut__option-s.png" />
    </div>

    <service-component class="large-card"
        *ngFor="let service of workOrder.services"
        [workOrder]="workOrder"
        [service]="service"
        [shouldShowDepartment]="shouldShowDepartment"
        [onUpdate]="onServiceUpdate"
        [onRemove]="onServiceRemove">
    </service-component>

    <div fxLayout="row" 
        fxLayoutAlign="end end" 
        class="request-tab__add-button-wrapper">
        <button mat-fab
            [disabled]="!workOrder.canAddServices()" 
            (click)="onAddService()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    
</div>    
