import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Rental, RentalDefinition } from '@beaconlite/models';
import { VariantRentalCollection } from '@beaconlite/models/collections';
import { GroupItem } from '@beaconlite/models/contracts/GroupItem.interface';
import { RequestItem } from '@beaconlite/models/contracts/RequestItem.interface';
// TODO: Jira BL-708 Make component generic to work with services potentially. TBD
@Component({
    selector: 'app-rental-editor-group-item',
    templateUrl: './rental-editor-group-item.component.html',
    styleUrls: ['./rental-editor-group-item.component.scss']
})
export class RentalEditorGroupItem  {
    @ViewChild('itemSearchTextField') itemSearchTextField: NgModel;
    @Input() item: Rental & RentalDefinition;
    @Input() quantity: number;
    @Input() groupQuantity: number;
    @Output() onRentalGroupItemChanged = new EventEmitter<Rental & RentalDefinition>();
    promisedItems: Promise<RequestItem[]>;
    itemSearchText = '';
    displayQuantity = 0;
    isRentalDefinition = false;
    collection = null;

    async ngOnInit(): Promise<void> 
    {
        if (this.item?.variants) 
        {
            // TODO Jira BL-704: handle groups that have item definitions and not just variants. WIP here.
            this.isRentalDefinition = true;
            this.item as RentalDefinition;
            this.collection = new VariantRentalCollection();
        } else {
            this.item as Rental;
        }

        this.itemSearchText = this.item.name;
        this.displayQuantity = this.item.quantity/this.groupQuantity

    }

    onQueryItems(): void
    {
        // TODO Jira BL-704: handle groups that have item definitions and not just variants. Want to variants based on an item definition id.
        this.promisedItems = this.collection.all({
            keyword: this.itemSearchText,
            order: 'asc',
        });
    }

    displaySelectedItem = (item?: RequestItem): string =>
    {
        // If there is an item already selected, use its name
        if (!!this.item && this.itemSearchTextField.pristine)
        {
            this.itemSearchTextField.control.setErrors(null);
            return this.item.name;
        }

        // Otherwise use the item was set in the input, or empty if no item was set.
        return item?.name || '';
    }

    onItemSelected(item: Rental)
    {
        // TODO Jira BL-704: handle groups that have item definitions. WIP.
        const [quantity, position] = [this.item.quantity, this.item.position];
        [this.item as Rental, this.item.quantity, this.item.position] = [item, quantity, position];
        this.onItemUpdated();
    }

    onQuantityChanged(): void
    {
        // Don't want to set anything until a Rental is set.
        if (this.isRentalDefinition) { return; }
        
        this.item.quantity = this.displayQuantity * this.groupQuantity;
        this.onItemUpdated();
    }

    onItemUpdated()
    {
        this.onRentalGroupItemChanged.emit(this.item);
    }
}
