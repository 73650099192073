<div class="request-tab"  fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="16px">

    <app-issue class="large-card"
        *ngFor="let issue of workOrder.unresolved_issues"
        [workOrder]="workOrder"
        [issue]="issue"
        [onUpdate]="onSave">
    </app-issue>

    <mat-card class="large-card" fxLayout="column">
        <mat-card-title fxFlex fxLayoutAlign="space-between center">
            <span class="mat-headline">Details</span> 
            <div fxLayoutAlign="center center">

                <mat-icon [fxShow]="lockVault.hasResourceLock(workOrder)">lock_outline</mat-icon>

                <button mat-icon-button [matMenuTriggerFor]="mainMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #mainMenu="matMenu">
                    <button mat-menu-item (click)="onEdit()">
                        <mat-icon>edit</mat-icon>
                        Edit
                    </button>

                    <button mat-menu-item [disabled]="!workOrder.canAddCharges()" (click)="onAddCharge()">
                        <mat-icon>attach_money</mat-icon>
                        Additional Charge
                    </button>

                    <button mat-menu-item [disabled]="!workOrder.canAddNotes()" (click)="onAddNote()">
                        <mat-icon>note_ad</mat-icon>
                        Add Note
                    </button>

                    <button mat-menu-item (click)="onAddDocument()">
                        <mat-icon>attach_file</mat-icon>
                        Add Document
                    </button>
                </mat-menu>
            </div>
        </mat-card-title>

        <!-- Work Order Properties -->

        <div class="large-card__lead">
            <div class="large-card__lead--suspended" fxLayout="row" *ngIf="workOrder.discarded_at">
                <div fxLayout="column" fxFlex="20" layoutPadding>
                    <span class="mat-caption">Discarded by</span>
                    <p class="mat-body-1">{{ workOrder.reason_discarded.account.name }}</p>
                    
                </div>
                <div fxLayout="column" fxFlex="80" layoutPadding>
                    <span class="mat-caption">Reason</span>
                    <p class="mat-body-1">{{ workOrder.reason_discarded.reason }}</p>
                </div>
            </div>
            <mat-divider *ngIf="workOrder.discarded_at"></mat-divider>

            <div class="large-card__lead--notice" fxLayout="row" *ngIf="workOrder.client?.notice">
                <div fxLayout="column" fxFlex layoutPadding>
                    <span class="mat-caption">Notice about the client</span>
                    <p class="mat-body-1">{{ workOrder.client?.notice }}</p>
                </div>
            </div>
            <mat-divider *ngIf="workOrder.client?.notice"></mat-divider>

            <div fxLayout="row" *ngIf="shouldShowDepartment">
                <div fxLayout="column" fxFlex="50" layoutPadding>
                    <span class="mat-caption">Departments</span>
                    <p class="mat-body-1">{{ displayDepartments() }}</p>
                </div>
            </div>

            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30" layoutPadding>
                    <span class="mat-caption">Branch</span>
                    <p class="mat-body-1">{{ workOrder.branch?.name }}</p>
                </div>
                <div fxLayout="column" fxFlex="20" layoutPadding>
                    <span class="mat-caption">Internal Job Code</span>
                    <p class="mat-body-1">{{ workOrder.job_code }}</p>
                </div>
                <div fxLayout="column" fxFlex="20" layoutPadding>
                    <span class="mat-caption">Taxes</span>
                    <p class="mat-body-1">{{ workOrder.taxes }}</p>
                </div>
                <div fxLayout="column" fxFlex="40" layoutPadding>
                    <span class="mat-caption">Assigned To</span>
                    <p class="mat-body-1">{{ workOrder.employee?.fullname }}</p>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30" layoutPadding>
                    <span class="mat-caption">Client</span>
                    <p class="mat-body-1">{{ workOrder.client?.name }}</p>
                </div>
                <div fxLayout="column" fxFlex="20" layoutPadding>
                    <span class="mat-caption">Date</span>
                    <p class="mat-body-1">{{ workOrder.started_at | dateFilter:'dateShort' }} - {{ workOrder.ended_at | dateFilter:'dateShort':'TBD' }}</p>
                </div>
                <div fxLayout="column" fxFlex="50" layoutPadding>
                    <span class="mat-caption">Location</span>
                    <p class="mat-body-1">{{ workOrder.location }}</p>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row">
                <div fxLayout="column" fxFlex="50" layoutPadding>
                    <span class="mat-caption">Main Contact</span>
                    <p class="mat-body-1">{{ workOrder.main_contact }}</p>
                </div>
                <div fxLayout="column" fxFlex="50" layoutPadding>
                    <span class="mat-caption">Ordered By</span>
                    <p class="mat-body-1">{{ workOrder.ordered_by }}</p>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row">
                <div fxLayout="column" fxFlex layoutPadding>
                    <span class="mat-caption">Client Reference Numbers</span>
                    <p class="mat-body-1">
                        <span *ngFor="let externalId of workOrder.external_ids; last as isLast">{{ externalId.value }}<span *ngIf="!isLast">, </span></span>
                    </p>
                </div>
            </div>
            
            <mat-divider *ngIf="workOrder.hasCharges()"></mat-divider>
        </div>
        
        <!-- Work Order Charges -->


        <mat-card-content class="large-card__content" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between start" [fxShow]="workOrder.hasCharges()">
                <span class="mat-subheading-2">Charges</span>
                <span *ngIf="workOrder.invoiced"><mat-icon>lock_outline</mat-icon></span>
            </div>
            <app-charges-table [fxShow]="workOrder.hasCharges()"
                [charges]="workOrder.charges"
                (chargeSelected)="onEditCharge($event)">
            </app-charges-table>

            <!-- Work Order Notes -->

            <mat-divider *ngIf="workOrder.hasNotes()"></mat-divider>

            <div fxLayout="row" fxLayoutAlign="space-between start" [fxShow]="workOrder.hasNotes()">
                <span class="mat-subheading-2">Notes</span>
                <span *ngIf="workOrder.invoiced"><mat-icon>lock_outline</mat-icon></span>
            </div>
            <app-notes-table [fxShow]="workOrder.hasNotes()"
                [notes]="workOrder.notes"
                (noteSelected)="onEditNote($event)">
            </app-notes-table>
        </mat-card-content>
    </mat-card>

    <!-- Documents -->
    <mat-card class="large-card" *ngIf="workOrder.hasDocuments()" fxLayout="column">
        <mat-card-title fxFlex fxLayoutAlign="space-between center">
            <span class="mat-headline">Documents</span> 
        </mat-card-title>
        <mat-card-content class="large-card__content" fxLayout="column">
            <div class="doc-list-item" 
                fxFlex 
                fxLayout="row" 
                fxLayoutAlign=" center"
                fxLayoutGap="20px"
                *ngFor="let doc of this.workOrder.documents"
                (click)="this.onPreviewDocument(doc)">
                <div class="doc-list-item__avatar">
                    <mat-icon>image</mat-icon>
                </div>
                <div fxLayout="column">
                    <span>{{ doc.attachment?.name }}</span>
                    <span>{{ doc.created_at | dateFilter:'dateMedium' }}</span>
                    <span class="mat-caption">{{ doc.attachment?.description }}</span>
                </div>
                <span fxFlex></span>
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="documentMenu" (click)="$event.stopPropagation();">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #documentMenu="matMenu">
                        <button mat-menu-item (click)="onEditDocument(doc)">
                            <mat-icon>edit</mat-icon>
                            Edit
                        </button>

                        <button mat-menu-item (click)="onRemoveDocument(doc)">
                            <mat-icon>delete</mat-icon>
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-mini-fab (click)="onAddDocument()" color="secondary">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <!-- Resolved Issues -->
    <!-- TODO: proper ordering -->
    <app-issue class="large-card"
        *ngFor="let issue of workOrder.resolved_issues"
        [workOrder]="workOrder"
        [issue]="issue"
        [onUpdate]="onSave">
    </app-issue>
    
</div>
