import * as moment from "moment";
import { BaseModel } from './Base.model'
import { Branch } from './Branch.model'
import { Charge } from './Charge.model'
import { Client } from './Client.model'
import { Department } from './Department.model'
import { Dispatch } from './Dispatch.model'
import { Document } from './Document.model'
import { ExternalId } from './ExternalId.model'
import { Invoice } from './Invoice.model'
import { Issue, IssueType } from './Issue.model'
import { Note } from './Note.model'
import { RentalRequest } from './RentalRequest.model'
import { ResourceLock } from './ResourceLock.model'
import { Service } from './Service.model'
import { Request } from "./contracts/Request.interface";
import { Discardable } from './mixins/Discardable.mixin'
import { Suspendable } from './mixins/Suspendable.mixin'
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from '../services/app-injector.service'
import { env } from '../services/env.service'
import { RouteMap } from '../services/network/route-map.service'
import { ShortIdUtility } from './utilities/ShortId.utility'
import { orderBy } from "@beaconlite/utilities/Sort.utility";
import { Employee } from "./Employee.model";
import { LineItemRequest } from "./LineItemRequest.model";

const MixinBase = Suspendable(
                  Discardable( BaseModel ) );
export class WorkOrder extends MixinBase {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }

    protected readonly _prefix: string = WorkOrder._prefix;
    protected static readonly _prefix: string = ShortIdUtility.getPrefix(ShortIdUtility.TYPE_WORK_ORDER);

    @dto() id?: string = null;
    @dto() serial_id?: string = null;
    @dto() branch_id?: string = null;
    @dto() client_id?: string = null;
    @dto() employee_id?: string = null;
    @dto() estimate?: boolean = false;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() issue_severity?: string = null;
    @dto() taxes?: string = env('taxes', 'ON');
    @dto() location?: string = null;
    @dto() job_code?: string = null;
    @dto() main_contact?: string = null;
    @dto() ordered_by?: string = null;
    @dto() @date started_at?: number = null;
    @dto() @date ended_at?: number = null;
    @dto() @date previous_invoice?: number = null;
    @dto() @date next_invoice?: number = null;
    @dto() reason_discarded?: string = null;
    @dto() lockType: string = ResourceLock.TYPE_WORK_ORDER;

    @dto(Branch) branch?: Branch = null;
    @dto(Client) client?: Client = null;
    @dto(Employee) employee?: Employee = null;
    @dto(ExternalId) external_ids?: ExternalId[] = [];
    @dto(RentalRequest) rental_requests?: RentalRequest[] = [];
    @dto(Service) services?: Service[] = [];
    @dto(LineItemRequest) line_item_requests?: LineItemRequest[] = [];
    @dto(Charge) charges?: Charge[] = [];
    @dto(Note) notes?: Note[] = [];
    @dto(Dispatch) dispatches?: Dispatch[] = [];
    @dto(Invoice) invoices?: Invoice[] = [];
    @dto(Issue) issues?: Issue[] = [];
    @dto(Invoice) quotes?: Invoice[] = [];
    @dto(Document) documents?: Document[] = [];

    static async get(id: string): Promise<WorkOrder> 
    {
        const response = await AppInjector.get(RouteMap).getWorkOrder(id);
        return new WorkOrder( response.data() );
    }

    static formatSerialId(serialId: string)
    {
        const prefix = this._prefix || WorkOrder._prefix;
        return `${prefix}-${serialId}`;
    }

    get formatted_serial_id(): string 
    {
        if (! this.serial_id) return '';

        return WorkOrder.formatSerialId(this.serial_id);
    }

    get state_name() 
    {
        if (!! this.discarded_at)
        {
            return 'discarded';
        }
        else if (this.issue_severity === Issue.SEVERITY_QUARANTINE)
        {
            return 'quarantined';
        }
        else if (this.invoiced)
        {
            return 'invoiced';
        }
        else if (this.estimate)
        {
            return 'estimate';
        } 
        else if (this.started_at != null && this.started_at < moment().unix())
        {
            return 'ongoing';
        }
        else
        {
            return 'pending';
        }
    }

    get state_letter() 
    {
        return this.state_name[0];
    }

    get departments(): Department[]
    {
        // TODO: why was this copy added? 
        // If we want to make things immutable we should probably just copy the departments prior to returning them
        // const services = $helpers.deepCopy(this.services);
        const requests = (<Request[]>this.services).concat(this.rental_requests);
        const departments = [];

        requests.forEach( request => {
            if(! request.department) return 

            if ( departments.findIndex( department => department.id == request.department.id ) == -1 )
            {
                departments.push(request.department)
            }
        });

        return departments;
    }

    get minimum_allowable_date(): number | null 
    {
        return WorkOrder.getMinimumAllowableDate(this);
    }

    static getMinimumAllowableDate(workOrder: WorkOrder): number | null
    {
        // If there is a previous invoice, we need to add a day to get the minimum
        if (!! workOrder.previous_invoice)
        {
            return moment.unix(workOrder.previous_invoice).add(1, 'seconds').unix()   
        }
            
        return null;
    }

    get default_request_start(): number 
    {
        return WorkOrder.getDefaultRequestStart(this);
    }

    static getDefaultRequestStart(workOrder: WorkOrder): number
    {
        //Ongoing: default to the current date or next invoiceable date.
        //Pending: default to the WO start or the next invoiceable date.

        const now = moment().startOf('day').unix()
        // const nextInvoiceStart = _getMinimumAllowableDate.call(this) 
        const nextInvoiceStart = workOrder.minimum_allowable_date; 
        const startDate = ( workOrder.started_at > now ) ? workOrder.started_at : now;

        return startDate < nextInvoiceStart ? nextInvoiceStart : startDate;
    }

    get resolved_issues(): Issue[]
    {
        return this.issues.filter(issue => !!issue.resolved_at);
    }

    get unresolved_issues(): Issue[]
    {
        return this.issues.filter(issue => !issue.resolved_at);
    }

    async save(): Promise<WorkOrder> 
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateWorkOrder(this.id, this.flush())
            : await this.routeMap.createWorkOrder(this.flush());

        return this.map(response.data());
    }

    async discard(reason: string): Promise<WorkOrder> 
    {
        const response = await this.routeMap.discardWorkOrder(this.id, { reason_discarded: reason });
        return this.map(response.data());
    }

    async recover(): Promise<WorkOrder> 
    {
        const response = await this.routeMap.recoverWorkOrder(this.id);
        return this.map(response.data());
    }

    async reload(): Promise<WorkOrder> 
    {
        const response = await this.routeMap.getWorkOrder(this.id);
        return this.map(response.data());
    }

    canAddExternalIds(): boolean 
    {
        return !this.invoiced;
    }

    hasExternalIds(): boolean 
    {
        return !!this.external_ids?.length;
    }

    addExternalId(externalId: ExternalId = new ExternalId()): ExternalId 
    {

        this.external_ids.push(externalId);

        return externalId;
    }

    removeExternalId(model: ExternalId): void 
    {
        const index = this.external_ids.indexOf(model);

        if (index >= 0)
        {
            this.external_ids.splice(index, 1);
        }
    }

    canAddRentalRequests(): boolean 
    {
        return !this.invoiced;
    }

    hasRentalRequests(): boolean 
    {
        return !!this.rental_requests?.length;
    }

    addRentalRequest(request: RentalRequest = new RentalRequest()): RentalRequest 
    {
        this.rental_requests.push(request);

        return request;
    }

    removeRentalRequest(model: RentalRequest): void 
    {
        const index = this.rental_requests.indexOf(model);

        if (index >= 0)
        {
            this.rental_requests.splice(index, 1);
        }
    }

    canAddServices(): boolean 
    {
        return !this.invoiced;
    }

    hasServices(): boolean 
    {
        return !!this.services?.length;
    }

    addService(service: Service = new Service()): Service 
    {
        this.services.push(service);

        return service;
    }

    removeService(model: Service): void 
    {
        const index = this.services.indexOf(model);

        if (index >= 0)
        {
            this.services.splice(index, 1);
        }
    }

    hasLineItemRequests(): boolean
    {
        return !!this.line_item_requests?.length;
    }

    canAddLineItemRequests(): boolean
    {
        return !this.invoiced;
    }

    canAddCharges(): boolean 
    {
        return !this.invoiced;
    }

    hasCharges(): boolean 
    {
        return !!this.charges?.length;
    }

    addCharge(charge: Charge = new Charge()): Charge 
    {
        this.charges.push(charge);

        return charge;
    }

    removeCharge(model: Charge): void 
    {
        const index = this.charges.indexOf(model);

        if (index >= 0)
        {
            this.charges.splice(index, 1);
        }
    }

    canAddNotes(): boolean 
    {
        return true;
    }

    hasNotes(): boolean 
    {
        return !!this.notes?.length;
    }

    addNote(note: Note = new Note()): Note 
    {
        this.notes.unshift(note);

        this.notes.sort( orderBy('created_at') );

        return note;
    }

    removeNote(model: Note): void 
    {
        const index = this.notes.indexOf(model);

        if (index >= 0)
        {
            this.notes.splice(index, 1);
        }
    }

    canAddDispatches(): boolean 
    {
        return this.hasRentalRequests() || this.hasServices();
    }

    hasDispatches(): boolean 
    {
        return !!this.dispatches?.length;
    }

    canBeEstimate(): boolean 
    {
        return !( !!this.previous_invoice || !!this.dispatches.length )
    }

    hasInvoices(): boolean 
    {
        return !!this.invoices?.length;
    }

    hasQuotes(): boolean 
    {
        return !!this.quotes?.length;
    }

    async addDocument(doc: Document): Promise<Document> 
    {
        const response = await this.routeMap.createWorkOrderDocument(this.id, doc.flush())
        doc.map(response.data());
        this.documents.push(doc);
        
        return doc;
    }

    async addDocuments(documents: Document[]): Promise<Document[]> {
        const data = documents.map( doc => doc.flush());
        const response = await this.routeMap.createWorkOrderDocument(this.id, data);
        let docs = response.data().map( data => new Document(data));

        return this.documents.splice(this.documents.length, 0, docs);
    }

    hasDocuments(): boolean 
    {
        return !!this.documents?.length;
    }

    hasUnresolvedIssues(severity?: IssueType)
    {
        return severity
            ? this.unresolved_issues.some(issue => issue.severity === severity)
            : !!this.unresolved_issues.length;
    }

    duplicate(startDate: number, resetTimes: boolean): WorkOrder 
    {
        let duplicate = new WorkOrder( this.flushAttributes() );

        // Override the date to start now.
        duplicate.started_at = moment.unix(startDate).startOf('day').unix() || moment().startOf('day').unix();

        // Reset computed and unique values back to model defaults
        duplicate.id                = null;
        duplicate.serial_id         = null;
        duplicate.locked            = false;
        duplicate.invoiced          = false;
        duplicate.issue_severity    = null;
        duplicate.ended_at          = null;
        duplicate.previous_invoice  = null;
        duplicate.next_invoice      = null;
        duplicate.reason_discarded  = null;
        duplicate.created_at        = null;
        duplicate.updated_at        = null;
        duplicate.discarded_at      = null;

        this.external_ids.forEach( externalId => {
            duplicate.addExternalId( externalId.duplicate() );
        });

        this.rental_requests.forEach( request => {
            const start = resetTimes ? duplicate.started_at : this.adjustTime(duplicate.started_at, this.started_at, request.started_at);
            duplicate.addRentalRequest( request.duplicate({started_at: start, ended_at: null}) );
        });

        this.services.forEach( service => {
            const start = resetTimes ? duplicate.started_at : this.adjustTime(duplicate.started_at, this.started_at, service.started_at);
            duplicate.addService( service.duplicate({started_at: start, ended_at: null}) );
        });

        this.charges.forEach( charge => {
            duplicate.addCharge( charge.duplicate() );
        });

        this.notes.forEach( note => {
            duplicate.addNote( note.duplicate() );
        });

         // We are not duplicating dispatches

         return duplicate;
    }

    async close(): Promise<WorkOrder> 
    {
        const response = await this.routeMap.closeWorkOrder(this.id);
        return this.map(response.data());
    }

    protected adjustTime(target: number, baseline: number, offsetMark: number) 
    {
        const offset = moment.duration( moment.unix(offsetMark).diff( moment.unix(baseline) ))
        return moment.unix(target).add(offset).unix();
    }

}
