import { Ng2StateDeclaration } from "@uirouter/angular";
import { Transition } from "@uirouter/core";
import { EmployeeScheduleSingleComponent } from "./employee-single.component";

export const EmployeeScheduleSingleState: Ng2StateDeclaration = {
    name: 'protected.employee-schedule.single',
    url: '/:modelId',
    component: EmployeeScheduleSingleComponent,
    data: {
        pageTitle: 'Employee',
        requiresAuth: true,
    },
    params: {
        modelId: 'new',
    },
    resolve: [
        { 
          token: 'modelId', 
          deps: [Transition],
          resolveFn: (transition: Transition) => transition.params().modelId,
        },
      ],
}
