import { Ng2StateDeclaration } from "@uirouter/angular";
import { PrintComponent } from "./print.component";

export const PrintState: Ng2StateDeclaration = {
    abstract: true,
    name: 'print',
    component: PrintComponent,
    data: {
        pageTitle: 'Print',
        requiresAuth: true,
    },
}
