import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Charge, Document, Issue, Note, WorkOrder } from '@beaconlite/models';
import { LockVaultService } from '../../../../../services/lock-vault.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { ChargeEditorService } from '../../../../../components/editors/charge-editor/charge-editor.service';
import { WorkOrderEditorService } from './work-order-editor/work-order-editor.service';
import { NoteEditorService } from '../../../../../components/editors/note-editor/note-editor.service';
import { SnackbarNotificationService } from '../../../../../services/notification/snackbar-notification.service';
import { DocumentUploadService } from '@beaconlite/components/document/document-upload/document-upload.service';
import { DocumentEditorService } from '@beaconlite/components/document/document-editor/document-editor.service';
import { DocumentPreviewService } from '@beaconlite/components/document/document-preview/document-preview.service';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import * as moment from 'moment';

@Component({
  selector: 'work-order-tab-main',
  templateUrl: './tab-main.component.html',
})
export class TabMainComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;
  @Input() shouldShowDepartment: boolean;
  @Input() onSave: () => Promise<void>;

  loading = false;
  minimumDate: Date = null;

  constructor(
    public lockVault: LockVaultService,
    protected workOrderEditor: WorkOrderEditorService,
    protected chargeEditor: ChargeEditorService,
    protected noteEditor: NoteEditorService,
    protected snackbarNotifications: SnackbarNotificationService,
    protected documentUploader: DocumentUploadService,
    protected documentEditor: DocumentEditorService,
    protected documentPreviewer: DocumentPreviewService,
    protected dialogNotification: DialogNotificationService,
  ) {}

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void
  {
    this._sortItems();
  }

  async onEdit(): Promise<void>
  {

    const onUpdate = async (workOrder: WorkOrder) =>
    {
      await workOrder.save();
      await this.workOrder.reload();
      this.snackbarNotifications.saved();
    }

    await this.workOrderEditor.open({
      original: this.workOrder,
      onUpdate: onUpdate
    });
  }

  async onAddCharge(): Promise<boolean> 
  {
    return this.onEditCharge();
  }

  async onEditCharge(charge: Charge = null): Promise<boolean>
  { 
    const onChargeUpdate = async (updatedCharge: Charge): Promise<void> => {
      charge?.exists()
        ? charge.mapAttributes(updatedCharge.flushAttributes())
        : this.workOrder.addCharge(updatedCharge);
    
      await this.onSave();
    }

    const onChargeRemove = async (): Promise<void> => {
      this.workOrder.removeCharge(charge);
      await this.onSave();
    }

    let minimumDate = this.workOrder.minimum_allowable_date;
    this.minimumDate = moment.unix(minimumDate).toDate();

    return this.chargeEditor.open({
      minimumDate: this.minimumDate,
      original: charge,
      onChargeUpdate: onChargeUpdate,
      onChargeRemove: onChargeRemove,
      allowRecurrence: true
    });
  }

  async onAddNote(): Promise<void> 
  {
    await this.onEditNote();
  }

  async onEditNote(note: Note = null)
  {
    const onNoteUpdate = async (updatedNote: Note) => {
      updatedNote?.exists()
        ? note.mapAttributes(updatedNote.flushAttributes())
        : this.workOrder.addNote(updatedNote);
      
      await this.onSave();
    }

    const onNoteRemove = async () => {
      this.workOrder.removeNote(note);
      await this.onSave();
    }

    let minimumDate = this.workOrder.minimum_allowable_date;
    this.minimumDate = moment.unix(minimumDate).toDate();

    return this.noteEditor.open({
      original: note,
      allowRecurrence: true,
      minimumDate: this.minimumDate,
      onNoteUpdate,
      onNoteRemove,
    });
  }

  async onAddDocument(): Promise<void> 
  {
    await this.documentUploader.open({
      documentableModel: this.workOrder,
      onDocumentUpdate: this.onDocumentUpdate,
      onDocumentRemove: this.onDocumentRemove,
    });
  }

  async onEditDocument(doc: Document): Promise<void>
  {
    await this.documentEditor.open({
      original: doc,
      documentableModel: this.workOrder,
      onDocumentUpdate: this.onDocumentUpdate,
      onDocumentRemove: this.onDocumentRemove,
    });
  }

  async onPreviewDocument(doc: Document): Promise<void>
  {
    await this.documentPreviewer.open({
      original: doc,
      documentableModel: this.workOrder,
      onDocumentUpdate: this.onDocumentUpdate,
      onDocumentRemove: this.onDocumentRemove,
    });
  }

  onDocumentUpdate = async (): Promise<void> =>
  {
    await this.workOrder.reload();
  }

  onDocumentRemove = async (): Promise<void> =>
  {
    await this.workOrder.reload();
  }

  async onRemoveDocument(doc: Document): Promise<void> 
  {
    // Show for any associations
    if (!! doc.associations.length)
    {
      const shouldContinue: boolean = await this.dialogNotification.confirm({
        title: 'Confirm Linked Document Deletion?',
        textContent: `This Document is linked to these items: ${doc.associations.join(', ')} <br><br> Are you sure you would like delete it?`,
      });

      if (! shouldContinue) return;
    }

    const shouldContinue: boolean = await this.dialogNotification.confirm({
      title: 'Confirm Document Deletion?',
      textContent: 'Are you sure you would like delete this Document from the Work Order?',
    });

    if (! shouldContinue) return;

    await doc.delete();
    await this.onDocumentRemove();
  }

  displayDepartments(): string
  {
    return this.workOrder.departments.map(deparmtent => deparmtent.name)
      .sort()
      .join(', ')
  }

  compareIssuesBySeverity = (a: Issue, b: Issue): number => 
  {
    if (a.severity === Issue.SEVERITY_QUARANTINE && b.severity === Issue.SEVERITY_QUARANTINE) return 0;
    if (a.severity === Issue.SEVERITY_QUARANTINE) return -1;

    return 1; 
  }

  protected _sortItems(): void
  {
    this.workOrder.charges?.sort(orderBy('created_at'));
    this.workOrder.notes?.sort(orderBy('noted_at'));
    this.workOrder.documents?.sort(orderBy('created_at'));

    // FIXME: Can't get sort function working properly.
    //        This currently sorts by severity level, but not by date if severity levels are equal   
    this.workOrder.issues.sort(this.compareIssuesBySeverity || orderBy('-created_at'))
  }
}
