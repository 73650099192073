import { RentalReturn } from "./RentalReturn.model";

export class ReturnRowItem {

    constructor(returns: RentalReturn[], returned_at: number, invalid: boolean) {
        this.invalid = invalid;
        this.returned_at = returned_at;
        this.returns = returns;
    }

    invalid: boolean = false;
    returned_at: number = null;
    returns: RentalReturn[] = [];

    get quantity_returned(): number 
    {
        return this.returns.reduce( (accumulator, rentalReturn) => {
            return rentalReturn.lost === true
                ? accumulator
                : accumulator + rentalReturn.quantity;
        }, 0);
    }

    get quantity_lost(): number 
    {
        return this.returns.reduce( (accumulator, rentalReturn) => {
            return rentalReturn.lost === false
                ? accumulator
                : accumulator + rentalReturn.quantity;
        }, 0);
    }
}
