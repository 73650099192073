import { BaseModel } from "./Base.model";
import { Setting } from "./Setting.model";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'
import { AppInjector } from "../services/app-injector.service";
import { RouteMap } from "../services/network/route-map.service";

export class Integration extends BaseModel {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() name?: string = null;
    @dto() slug?: string = null;
    @dto() enabled?: boolean = false;
    @dto() @date linked_at?: number = null;

    @dto(Setting) settings?: Setting[] = null;
    
    static SAGE_50 = 'sage50';
    static QUICKBOOKS = 'quickbooks';

    static async get(id: string): Promise<Integration> 
    {
        const response = await AppInjector.get(RouteMap).getIntegration(id);
        return new Integration(response.data());
    }

    static async getBySlug(slug: string): Promise<Integration> 
    {
        const response = await AppInjector.get(RouteMap).getIntegrationBySlug(slug);
        return new Integration(response.data());
    }

    static async getActiveAccountingModel(): Promise<Integration> 
    {
        const response = await AppInjector.get(RouteMap).getActiveAccountingIntegration();
        return new Integration(response.data());
    }

    async save(): Promise<Integration> {
        if (! this.exists()) throw "Invalid integration creation method";

        const response = await this.routeMap.updateIntegration(this.id, this.flush());
        return this.map(response.data());
    }

}