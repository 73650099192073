import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-version-update-bar',
  template: `
    <div class="update-bar" fxLayout="row" fxLayoutAlign="center center">
      Update Available. <a href="#" (click)="this.reload()">Click Here</a>
    </div>
  `,
  styleUrls: ['./version-update-bar.component.scss']
})
export class VersionUpdateBarComponent implements OnInit {

  constructor() { }
  ngOnInit(): void { }

  reload(): void
  {
    window.location.reload();
  }
}
