<mat-card class="medium-card">
<div class="card-inner" fxLayout="row wrap">
    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-title">Variant Properties</div>
    </div> 

    <div fxLayout="column" fxLayoutAlign="center center" [fxHide]="this.itemDefinition.variants_enabled">
        Variants must be enabled before creating variant properties.
    </div>

    <div class="table" fxFlex="100" [fxShow]="this.itemDefinition.variants_enabled && !!this.itemDefinition.variant_property_definitions.length">
        <div class="table__header-row" fxLayout="row" fxLayoutAlign=" center" fxFlex="100">
            <span fxFlexOffset="3" fxFlex="12">Displayed</span>
            <span fxFlex="20">Type</span>
            <span fxFlex>Name</span>
        </div>

        <div class="property-list" fxLayout="column" fxFlex
            cdkDropList
            (cdkDropListDropped)="onPropDropped($event)">
            <div class="table__row table__row--draggable" fxFlex="100" fxLayout="column" 
                *ngFor="let prop of this.itemDefinition.variant_property_definitions"
                (click)="this.onEdit(prop)"
                cdkDrag>
                <div fxLayout="row" fxFlex fxLayoutAlign=" center">
                    <div fxFlex="3">
                        <mat-icon class="drag-indicator" color="primary">drag_indicator</mat-icon>
                    </div>
                    <div fxFlex="12">
                        <mat-icon color="primary" *ngIf="prop.is_displayed">done</mat-icon>
                        <!-- Blank icon so row height is calculated equally when padding top/bottom is applied -->
                        <mat-icon *ngIf="!prop.is_displayed"></mat-icon>
                    </div>
                    <div class="capitalize" fxFlex="20">{{ prop.type }}</div>
                    <div fxFlex>{{ prop.name }}</div>
                </div>
                <div fxLayout="row" fxFlex="100" *ngIf="prop.type === 'dropdown'">
                    <div fxFlexOffset="15" fxFlex="70">
                        <div class="table table--options">
                            <div class="table__header-row" fxLayoutAlign=" center">
                                <div fxFlex="40">Name</div>
                                <ng-container *ngIf="this.itemDefinition.variant_rates_enabled">
                                    <div class="capitalize" fxFlex="20" 
                                        *ngFor="let attribute of this.itemDefinition.variant_property_fields">
                                        {{ this.formatAttribute(attribute) }}
                                    </div>
                                </ng-container>
                            </div>
                            <div class="table__row" *ngFor="let option of prop.options">
                                <span class="capitalize" fxFlex="40">{{ option.name }}</span>
                                <ng-container *ngIf="this.itemDefinition.variant_rates_enabled">
                                    <span fxFlex="20" 
                                        *ngFor="let attribute of this.itemDefinition.variant_property_fields">
                                        {{ option[attribute] | currencyFilter }}
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end end">
        <button type="button"
            mat-mini-fab
            (click)="this.onAdd()"
            [disabled]="!this.itemDefinition.variants_enabled">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
</mat-card>
