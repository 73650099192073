<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign=" center">
        <h2 class="mat-title">Service Override Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Select Item</mat-label>
                <input type="text"
                    [(ngModel)]="this.searchText"
                    name="searchTextField"
                    #searchTextField="ngModel"
                    [matAutocomplete]="serviceAuto"
                    (ngModelChange)="this.onQueryServices()"
                    [disabled]="this.serviceOverride.exists()"
                    matInput
                    required>
                <mat-autocomplete #serviceAuto="matAutocomplete"
                    (optionSelected)="this.onServiceSelected($event.option.value)"
                    [displayWith]="this.displaySelectedService">
                    <mat-option *ngFor="let service of promisedServices | async" [value]="service">
                        {{ service.display_name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field fxFlex="50">
                <mat-label>Override Type</mat-label>
                <mat-select [(ngModel)]="this.serviceOverride.type"
                    name="overrideTypeField"
                    #overrideTypeField="ngModel"
                    [disabled]="!this.selectedService"
                    required>
                    <mat-option selected [value]="this.overrideTypes.rate">Rate</mat-option>
                    <mat-option [value]="this.overrideTypes.percent">Percentage</mat-option>
                </mat-select>
                <mat-error *ngIf="overrideTypeField.hasError('required')">Override type is required.</mat-error>
            </mat-form-field>
            <div fxFlex="50"></div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px"
            *ngIf="this.serviceOverride.type === this.overrideTypes.percent" >
            <mat-form-field fxFlex="50">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="serviceOverride.discount"
                    name="typeField"
                    #typeField="ngModel"
                    [disabled]="!this.selectedService"
                    required>
                    <mat-option selected [value]="true">Discount</mat-option>
                    <mat-option [value]="false">Charge</mat-option>
                </mat-select>
                <mat-error *ngIf="typeField.hasError('required')">Type is required.</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <mat-label>Value</mat-label>
                <input type="text"
                    [(ngModel)]="serviceOverride.percent_value"
                    name="percentValueField"
                    #percentValueField="ngModel"
                    percentInput
                    minNum="0"
                    maxNum="1"
                    [disabled]="!this.selectedService"
                    required
                    matInput>
                <mat-error *ngIf="percentValueField.hasError('required')">Value is required.</mat-error>
                <mat-error *ngIf="percentValueField.hasError('minNum')">Value must be at least 0.00 %.</mat-error>
                <mat-error *ngIf="percentValueField.hasError('maxNum')">Value cannot exceed 100.00 %.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px"
            *ngIf="this.serviceOverride.type === this.overrideTypes.rate" >
            <mat-form-field fxFlex>
                <mat-label>Rate</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceOverride.regular_rate"
                    name="regularRateField"
                    #regularRateField="ngModel"
                    required
                    currencyInput
                    minNum="0"
                    matInput>
                <mat-error *ngIf="regularRateField.hasError('required')">Rate is required.</mat-error>
                <mat-error *ngIf="regularRateField.hasError('minNum')">Rate must be at least $ 0.00.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>Overtime Rate</mat-label>
                <input type="text"
                    [(ngModel)]="this.serviceOverride.overtime_rate"
                    name="overtimeRateField"
                    #overtimeRateField="ngModel"
                    required
                    currencyInput
                    minNum="0"
                    matInput>
                <mat-error *ngIf="overtimeRateField.hasError('required')">Overtime rate is required.</mat-error>
                <mat-error *ngIf="overtimeRateField.hasError('minNum')">Overtime rate must be at least $ 0.00.</mat-error>
            </mat-form-field>
        </div>

    </mat-dialog-content>


    <mat-dialog-actions fxLayout="row">
        <button mat-icon-button *ngIf="this.canRemove()" (click)="this.remove()" [disabled]="this.loading" color="primary">
            <mat-icon>delete</mat-icon>
        </button>

        <span fxFlex></span>

        <button mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.loading">
            Save
        </button>
    </mat-dialog-actions>
</form>