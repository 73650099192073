import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { RentalOverrideEditorData } from "./rental-override-editor-data.interface";
import { RentalOverrideEditorComponent } from "./rental-override-editor.component";

@Injectable({
    providedIn: 'root'
})
export class RentalOverrideEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: RentalOverrideEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,
            minWidth: 800,
            data
          }

        return this.dialog.open(RentalOverrideEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
