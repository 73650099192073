import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { ActiveItemReport } from '@beaconlite/models/reports/ActiveItemReport.model';
import { ActiveReportItem } from '@beaconlite/models/reports/contracts/ActiveReportItem.interface';

@Component({
  selector: 'active-item-rental-row',
  templateUrl: './active-item-rental-row.component.html',
})
export class ActiveItemRentalRowComponent implements OnInit, DoCheck {

  @Input() report: ActiveItemReport;
  @Input() items: ActiveReportItem[];
  @Input() layer: string;

  nestedLayer: string = '';
  tableColumns = ['name', 'ongoing'];

  constructor() { }
  ngOnInit(): void {}

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: ActiveReportItem): ActiveReportItem[]|null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }
}
