<div class="request-tab" fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="16px">

    <div class="request-tab__no-requests" 
        *ngIf="!workOrder.hasRentalRequests()"
        (click)="onAddRentalRequest()">
        <img class="request-tab__no-requests__jerry-cone" src="assets/img/jerry-cone.png" />
        <p>You have no rental requests, would you like to create one?</p>
        <img class="request-tab__no-requests__keyboard-shortcut" src="assets/img/keyboard-shortcut__option-r.png" />
    </div>

    <rental-request-component class="large-card"
        *ngFor="let request of workOrder.rental_requests"
        [workOrder]="workOrder"
        [request]="request"
        [shouldShowDepartment]="shouldShowDepartment"
        [onUpdate]="onRequestUpdate"
        [onRemove]="onRequestRemove">
    </rental-request-component>

    <div fxLayout="row" 
        fxLayoutAlign="end end" 
        class="request-tab__add-button-wrapper">
        <button mat-fab
            [disabled]="!workOrder.canAddRentalRequests()" 
            (click)="onAddRentalRequest()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

</div>
