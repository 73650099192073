<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.onSave()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mat-title">Dispatch Settings Editor</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex hideRequiredMarker>
                <mat-label>Projected duration</mat-label>
                    <input type="text"
                        name="projectedDurationField"
                        #projectedDurationField="ngModel"
                        [(ngModel)]="this.preferences.dispatch.projected_duration"
                        required
                        timeInput
                        [maxNum]="this.maxProjectedDuration" 
                        matInput>
                    <mat-error *ngIf="this.projectedDurationField.hasError('required')">Projected duration is required.</mat-error>
                    <mat-error *ngIf="this.projectedDurationField.hasError('maxNum')">Projected duration cannot exceed 10 hours.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex hideRequiredMarker>
                <mat-label>Week starts on</mat-label>
                <mat-select name="weekStartField"
                    [(ngModel)]="this.preferences.dispatch.week_start"
                    required>
                    <mat-option [value]="0">Sunday</mat-option>
                    <mat-option [value]="1">Monday</mat-option>
                    <mat-option [value]="2">Tuesday</mat-option>
                    <mat-option [value]="3">Wednesday</mat-option>
                    <mat-option [value]="4">Thursday</mat-option>
                    <mat-option [value]="5">Friday</mat-option>
                    <mat-option [value]="6">Saturday</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner mode="indeterminate" *ngIf="this.loading" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>
