import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Branch, Client, Department, Rental, RentalDefinition, RentalGroup, VariantRental } from '@beaconlite/models';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { prefs } from "@beaconlite/services/preferences.service";
import { RentalEditorData } from './rental-editor-data.interface';
import { RentalResource } from '@beaconlite/models/RentalResource.model';
import { InventoryConstants } from '@beaconlite/models/constants/InventoryConstants';
import { RentalRequestResourceCollection } from '@beaconlite/models/collections/rental-resource-collections/RentalRequestResources.collection';
import { RentalGroupDefinition } from '@beaconlite/models/RentalGroupDefinition.model';
import { RequestItem } from '@beaconlite/models/contracts/RequestItem.interface';

@Component({
  selector: 'app-rental-editor',
  templateUrl: './rental-editor.component.html',
})
export class RentalEditorComponent implements OnInit {
  
  @ViewChild('editorForm') editorForm: NgForm;
  @ViewChild('rentalSearchTextField') rentalSearchTextField: NgModel;

  workOrder = this.data.workOrder;
  request = this.data.originalRequest;
  original = this.data.original;
  onUpdate = this.data.onRequestItemUpdate;
  onRemove = this.data.onRequestItemRemove;

  updating = false;
  loading = false;
  rentalSearchText = '';
  isGroup = false;
  defaultDepartment: Department = null;
  promisedRentals: Promise<RentalResource[]>;
  rental = new Rental();
  rentalGroup = new RentalGroup();
  client: Client = null;
  branch: Branch = null;
  isPercentDiscount = true;
  weeklyDiscount = 0;
  monthlyDiscount = 0;
  weeklyDiscountOverride = 0;
  monthlyDiscountOverride = 0;
  weekThreshold: number;
  monthThreshold: number;
  prevGroupQuantity: number;

  readonly PRICING_RATED = RentalDefinition.PRICING_RATED;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: RentalEditorData,
    public dialogRef: MatDialogRef<RentalEditorComponent>,
    protected appData: AppData,
    protected dialogNotifications: DialogNotificationService,
  ) { }
  
  async ngOnInit(): Promise<void> 
  {
    this.loading = true;

    if (!! this.original)
    {
      if (this.original?.rentals) {
        this.rentalGroup = (this.original as RentalGroup).copy();
        this.rentalSearchText = this.rentalGroup.name;
        this.isGroup = true;
        this.prevGroupQuantity = this.rentalGroup.quantity;
      } else {
        this.rental = (this.original as Rental).copy();
        this.rentalSearchText = this.rental.name;
      }
    }
    else
    {
      this.rental.position = this.request.request_items.length;
      this.rentalGroup.position = this.request.request_items.length;
    }

    let departments;
    [departments, this.branch, this.weekThreshold, this.monthThreshold] = await Promise.all([
      this.appData.departments.getAll(),
      Branch.get(this.workOrder.branch.id),
      prefs('pricing.week_threshold'), 
      prefs('pricing.month_threshold')
    ]);
    this.defaultDepartment = departments.find(department => department.id);
    this.client = this.workOrder.client;
    this.loading = false;
  }

  onQueryRentals(): void
  {
    if (!this.rentalSearchText) { 
      this.promisedRentals = null;
      return;
    }

    this.promisedRentals = (new RentalRequestResourceCollection()).all({
      name: this.rentalSearchText,
      order: 'asc',
      exclude_disabled: true,
      client_id: this.client.id,
    });
  }

  onDailyRateChange(): void
  {
    if ( !(this.rental.pricing_type === this.PRICING_RATED)
      || this.isPercentDiscount) 
    {
      return;
    }

    // Refresh the values for $/day, $/week, $/month to reflect the updated this.rental.daily_rate.
    this.onWeeklyDiscountPriceChange();
    this.onMonthlyDiscountPriceChange();
  }

  onIsPercentDiscountChange(isPercentDiscount: boolean): void
  {
    // From $ to %.
    if (isPercentDiscount)
    {
      return;
    } 
    // From % to $.
    // Calculate base weekly and monthly discounts in dollars.
    this.weeklyDiscount = this._getDiscountPriceFromPercent(this.rental.weekly_discount, this.rental.daily_rate);
    this.monthlyDiscount = this._getDiscountPriceFromPercent(this.rental.monthly_discount, this.rental.daily_rate);
    // Sync this.weeklyDiscountOverride / this.monthlyDiscountOverride values to correspond to this.rental.weekly_discount / this.rental.monthly_discount values
    this.weeklyDiscountOverride = this._getDiscountPriceFromPercent(this.rental.weekly_discount_override, this.rental.daily_rate_override);
    this.monthlyDiscountOverride = this._getDiscountPriceFromPercent(this.rental.monthly_discount_override, this.rental.daily_rate_override);
    // Refresh the values for $/day, $/week, $/month to reflect the value in this.weeklyDiscountOverride / this.monthlyDiscountOverride.
    this.onWeeklyDiscountPriceChange();
    this.onMonthlyDiscountPriceChange();
  }

  onWeeklyDiscountPriceChange(): void
  {
    this.rental.weekly_discount_override = this._getDiscountPercentFromPrice(this.weeklyDiscountOverride, this.rental.daily_rate_override);
  }

  onMonthlyDiscountPriceChange(): void
  {
    this.rental.monthly_discount_override = this._getDiscountPercentFromPrice(this.monthlyDiscountOverride, this.rental.daily_rate_override);
  }

  calculateRate(discount: number, days: number): number
  {
    return RentalDefinition.calculateRateFromDiscount(this.rental.daily_rate_override, discount, days);
  }
  
  displaySelectedRental = (rental?: RentalResource | RequestItem): string =>
  {
    if (!!this.original && this.rentalSearchTextField.pristine)
    {
      this.rentalSearchTextField.control.setErrors(null);
      return this.original.name;
    }

    return this.getNameWithPrefix(rental as RentalResource);
  }

  async onRentalResourceSelected(item: RentalResource)
  {
    this.loading = true;

    if (item.item_level == InventoryConstants.INV_VAR_ITEM) 
    {
      const variantRentalDef = await VariantRental.get(item.id);

      this.rental.resetDefinition();

      if (!! variantRentalDef)
      {
        this.rental.inheritVariant(variantRentalDef);

        const override = 
          this.client.getRentalOverride(variantRentalDef.id) ||
          this.branch.getRentalOverride(variantRentalDef.id);

        if (!! override)
        {
          this.rental.applyOverride(override);
        }

        this.rental.name = this.getNameWithPrefix(item);
      }
      this.isGroup = false;

    }
    else
    {
      const rentalGroupDef = await RentalGroupDefinition.get(item.id);

      this.rentalGroup.reset();
      this.rentalGroup.copyDefinitionValues(rentalGroupDef);
      this.prevGroupQuantity = this.rentalGroup.quantity;

      // Jira BUG BL-754:  When we get the definition, we don't have any prefix info for the items. Cannot display prefix info until after editor is saved.
      // for (let i = 0; i < this.rentalGroup.rentals.length; i += 1) {
      //   const rental = this.rentalGroup.rentals[i];
      //   rental.name = this.getNameWithPrefix(rental);
      // }

      this.isGroup = true;
    }

    this.loading = false;
  }

  getNameWithPrefix(rental?: RentalResource): string {
    if (!rental?.name) {
      return '';
    }

    const prefix = rental?.prefix ?? null;
    return prefix ? `${prefix} ${rental.name}` : rental.name;
  }

  canRemove(): boolean
  {
    return this.data.original && !this.data.original.locked;
  }

  async remove(): Promise<void> 
  {
    this.updating = true;
    this.loading = true;

    if (await this.dialogNotifications.removeConfirm())
    {
      if (!this.isGroup)
      {
        await this.onRemove(this.rental);
      } 
      else
      {
        await this.onRemove(this.rentalGroup);
      }

      this.dialogRef.close();
    }

    this.updating = false;
    this.loading = false;
  }

  async save(): Promise<void|boolean> 
  {
    if (this.editorForm.invalid) return false;

    this.updating = true;
    this.loading = true;
    this.rental.applyPricingDefaults();

    if (!this.isGroup)
    {
      await this.onUpdate(this.rental);
    }
    else
    {
      await this.onUpdate(this.rentalGroup);
    }
    this.dialogRef.close();

    this.updating = false;
    this.loading = false;
  }

  setRentalGroupItem(updatedItem: Rental): void
  {
    const idx = this.rentalGroup.rentals.findIndex(item => item.position == updatedItem.position)
    this.rentalGroup.rentals[idx] = updatedItem;
  }

  onGroupQuantityChange(): void
  {
    if (this.rentalGroup.quantity == null) { return; }
    this.rentalGroup.rentals.forEach(rental => rental.quantity = rental.quantity / this.prevGroupQuantity * this.rentalGroup.quantity);
    this.prevGroupQuantity = this.rentalGroup.quantity;
  }

  protected _getDiscountPercentFromPrice(discount: number, dailyRate: number): number
  {
    if (dailyRate == 0)
    {
      return 0;
    }

    const decimalPlaces = 4;
    const discountPercentage = this._roundDecimals( ( 1 - ( discount / dailyRate ) ), decimalPlaces);
    return discountPercentage;
  }

  protected _getDiscountPriceFromPercent(discount: number, dailyRate: number): number {
    return ((1 - discount) * dailyRate);
  }

  protected _roundDecimals (number: number, digits: number): number
  {
    const multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        roundedNum = Math.round(adjustedNum);
    return roundedNum / multiplier;
  }
}
