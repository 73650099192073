import { Component, OnInit } from '@angular/core';
import { Branch, Client, Integration, TaxRate } from '@beaconlite/models';
import { BranchCollection } from '@beaconlite/models/collections';
import { RouteMap } from '@beaconlite/services/network/route-map.service';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { IntegrationBranchDefaultsEditorService } from '../integration-branch-defaults-editor/integration-branch-defaults-editor.service';
import { Sage50AccountEditorService } from './sage50-account-editor/sage50-account-editor.component';
import { SingleTaxRate } from '@beaconlite/models/contracts/SingleTaxRate.interface';
import { Sage50TaxEditorService } from './sage50-tax-editor/sage50-tax-editor.service';
import { Sage50ExcludedClientService } from './sage50-excluded-client-editor/sage50-excluded-client-editor.component';
import { Sage50AdditionalSettingsService } from './sage50-additional-settings-editor/sage50-additional-settings-editor.component';
import { Sage50BranchSettings, Sage50ItemTypeOverrides, ExcludedClient, Sage50IntegrationSettings, Sage50MappedItemDefOverride, Sage50OverrideEditorAccount, Sage50MappedVariantOverride, Sage50CachedOverrides, OverridesMeta, Sage50MappedAccount, Sage50MappedAccountValue } from './sage50-inferaces/sage50-interfaces';
import { Sage50OverrideEditorService } from './sage50-override-editor/sage50-override-editor.service';
import { ItemType, ItemTypeConstants } from '@beaconlite/models/constants/ItemTypes';

@Component({
  selector: 'app-sage50-settings',
  templateUrl: './sage50-settings.component.html',
  styleUrls: ['./sage50-settings.component.scss']
})
export class Sage50SettingsComponent implements OnInit {
  taxes: SingleTaxRate[] = [];
  branches: Branch[] = [];
  integration = new Integration({
    slug: Integration.SAGE_50,
  });
  settings: Sage50IntegrationSettings = {taxes:{}, accounts:{}, branches:{}, excluded_clients:{}, additional_settings:{invoice_number_start: null}};

  mappedAccounts: Sage50MappedAccount[] = [];
  excludedClients: ExcludedClient[] = [];
  loadingIntegration: boolean;
  cachedOverrides: Sage50CachedOverrides = {};
  shouldRefreshCachedOverrides: OverridesMeta = {}
  isRecachingOverrides = true;
  hasOverrides: OverridesMeta = {}

  accountTableColumns = ['id', 'description'];
  taxTableColumns = ['group', 'name', 'external-name'];
  itemDefOverrideTableColumns = ['item', 'account'];
  variantOverrideTableColumns = ['item', 'account']
  excludedClientsTableColumn = ['name'];

  readonly RENTALS = ItemTypeConstants.RENTALS;
  readonly SERVICES = ItemTypeConstants.SERVICES;
  readonly CHARGES = ItemTypeConstants.CHARGES;
  readonly ITEM_TYPES: ItemType[] = [this.RENTALS, this.SERVICES, this.CHARGES];

  constructor(
    protected routeMap: RouteMap,
    protected dialogNotifications: DialogNotificationService,
    protected snackbarNotifications: SnackbarNotificationService,
    protected branchDefaultsEditor: IntegrationBranchDefaultsEditorService,
    protected OverrideEditor: Sage50OverrideEditorService,
    protected accountEditor: Sage50AccountEditorService,
    protected taxEditor: Sage50TaxEditorService,
    protected excludedClientEditor: Sage50ExcludedClientService,
    protected additionalSettingsEditor: Sage50AdditionalSettingsService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this.loadingIntegration = true;

    await this._fetchIntegration();

    if (this.integration.enabled)
    {
      await this._setUpTaxesBranchesAccountsClients();
    }

    this.loadingIntegration = false;
  }

  protected async _fetchIntegration(): Promise<void>
  {
    this.integration = await Integration.getBySlug(Integration.SAGE_50);
    // TODO: Jira task BL-510.
    this.settings = this.integration.settings[0].value;
  }

  protected async _setUpTaxesBranchesAccountsClients(): Promise<void>
  {
    const groupedTaxes: TaxRate[] = await TaxRate.getAll();
    this.taxes = this._ungroupTaxes(groupedTaxes);
    this.branches = await (new BranchCollection()).all();
    this._castSettingsItemsTypesToObjects();
    this._mapAccounts();
    this._mapExcludedClients();
    this._setUpCachedOverrides();
    this._sortItems();
  }

  protected _ungroupTaxes(groupedTaxes: TaxRate[]): SingleTaxRate[]
  {
    return groupedTaxes.flatMap((taxGroup) => (taxGroup.rates));
  }

  // TODO Jira: BL-547 Backend takes empty objects and saves them as arrays. Recast back to object. Figure out why and remove this.
  protected _castSettingsItemsTypesToObjects() {
    this.settings.excluded_clients = Array.isArray(this.settings.excluded_clients) ? {} : this.settings.excluded_clients;

    for (const branch of this.branches) {
      for (const itemType of this.ITEM_TYPES) {
        const itemTypeOverrides = this.settings.branches[branch.id][itemType].item_definition_overrides;
        this.settings.branches[branch.id][itemType].item_definition_overrides = Array.isArray(itemTypeOverrides) ? {} : itemTypeOverrides;

        for (const key in itemTypeOverrides) {
          const variantOverrides = itemTypeOverrides[key].variant_overrides;
          this.settings.branches[branch.id][itemType].item_definition_overrides[key].variant_overrides = Array.isArray(variantOverrides) ? {} : variantOverrides;
        }
      }
    }
  }

  protected _mapAccounts(): void
  {
    this.mappedAccounts = Object.entries(this.settings.accounts)
      .map( ([key, value]) => ({ 'key':key, 'value':value}) );
  }

  async integrationEnabledChanged(): Promise<void>
  {
    this.loadingIntegration = true;

    if (this.integration.enabled)
    {
      await this.onSave();
      await this._fetchIntegration();
      await this._setUpTaxesBranchesAccountsClients();
    }
    else
    {
      await this.onSave();
    }

    this.loadingIntegration = false;
  }

  async onEditTax(taxRate: SingleTaxRate): Promise<void>
  {
    const onUpdate = async (taxName: string): Promise<void> => {
      this.settings.taxes[taxRate.id] = taxName;
      await this.onSave();
    }

    await this.taxEditor.open({
      taxRate: taxRate,
      settings: this.settings,
      onUpdate: onUpdate,
    });
  }

  async onEditBranchDefaults(branch: Branch): Promise<void>
  {
    const onUpdate = async (branchSettings: Sage50BranchSettings): Promise<void> => 
    {
      this.settings.branches[branch.id] = branchSettings;
      await this.onSave();
    }

    await this.branchDefaultsEditor.open({
      branch: branch,
      accounts: this.mappedAccounts,
      original: this.settings.branches[branch.id],
      onUpdate: onUpdate,
    });
  }

  async onEditOverride(branch: Branch, itemType?: ItemType, itemDefOverride?: Sage50MappedItemDefOverride, variantOverride?: Sage50MappedVariantOverride): Promise<void>
  {
    const onOverrideUpdate = async (override: Sage50OverrideEditorAccount, itemType: ItemType): Promise<void> => {
      const itemDefOverrides = this.settings.branches[branch.id][itemType].item_definition_overrides;
      // Only variants have item definition id.
      const isItemDefOverride = !override.itemDefId;
      const itemDefId = isItemDefOverride ? override.id : override.itemDefId;
      const itemDefName = isItemDefOverride ? override.name : override.itemDefName;

      // If nothing exists on the item definition level, add it.
      if (!itemDefOverrides?.[itemDefId]) {
        itemDefOverrides[itemDefId] = {
          name: itemDefName,
          account: "",
          variant_overrides: {},
        }
      }

      if (isItemDefOverride) {
        itemDefOverrides[itemDefId].account = override.account;
      } else {
        itemDefOverrides[itemDefId].variant_overrides[override.id] = {
          name: override.name,
          account: override.account,
          itemDefId: override.itemDefId
        }
      }

      await this.onSave();
    }

    const onOverrideRemove = async (): Promise<void> => 
    {
      const itemDefOverrides = this.settings.branches[branch.id][itemType].item_definition_overrides;
      const isItemDefOverride = !variantOverride;

      if (isItemDefOverride) {
        itemDefOverrides[itemDefOverride.id].account = "";
      } else {
        delete itemDefOverrides[itemDefOverride.id].variant_overrides[variantOverride.id];
      }

      const doesItemDefHaveOverride = (!!itemDefOverrides[itemDefOverride.id].account);
      const doesItemDefHaveVariantOverrides = (Object.keys(itemDefOverrides[itemDefOverride.id].variant_overrides).length > 0);
      const shouldDeleteItemDef = !(doesItemDefHaveOverride || doesItemDefHaveVariantOverrides);

      if (shouldDeleteItemDef) {
        delete itemDefOverrides[itemDefOverride.id];
      }

      await this.onSave();
    }

    let overrideEditorAccount: Sage50OverrideEditorAccount = null;

    if (!!variantOverride) {
      overrideEditorAccount = {
        id: variantOverride.id,
        account: variantOverride.account,
        name: variantOverride.name,
        itemDefId: itemDefOverride.id,
        itemDefName: itemDefOverride.name
      }
    } else if (!!itemDefOverride) { 
      overrideEditorAccount = {
        id: itemDefOverride.id,
        account: itemDefOverride.account,
        name: itemDefOverride.name,
        itemDefId: '',
        itemDefName: ''
      }
    }

    await this.OverrideEditor.open({
      original: overrideEditorAccount,
      itemType: itemType,
      branch: branch,
      accounts: this.mappedAccounts,
      branchOverrides: this.settings.branches[branch.id],
      onOverrideUpdate, 
      onOverrideRemove,
    });
  }

  getBranchAccountName(branch: Branch, type: ItemType): string
  {
    if (!this.settings.branches[branch.id]){ return; }
    
    let accountId = this.settings.branches[branch.id].default_account;

    if (!this.settings.accounts[accountId]){ return; }

    if (this.settings.branches[branch.id][type] && 
      this.settings.branches[branch.id][type].default_account)
    {
      accountId = this.settings.branches[branch.id][type].default_account;
    }

    return this.settings.accounts[accountId].description
      ? this.settings.accounts[accountId].id  + ' - ' + this.settings.accounts[accountId].description
      : this.settings.accounts[accountId].id;
  }

  protected _setUpCachedOverrides(): void
  {
    this.isRecachingOverrides = true;

    for (const branch of this.branches) {
      this.cachedOverrides[branch.id] = {};
      this.hasOverrides[branch.id] = {};
      this.shouldRefreshCachedOverrides[branch.id] = {};
      for (const itemType of this.ITEM_TYPES) {
        this.cachedOverrides[branch.id][itemType] = [];
        this.shouldRefreshCachedOverrides[branch.id][itemType] = true;
        this.hasOverrides[branch.id][itemType] = !!this.settings.branches[branch.id][itemType].item_definition_overrides ? true : false;
      };
    };

    this.isRecachingOverrides = false;
  }

  getOverrides(branch: Branch, itemType: ItemType): Sage50MappedItemDefOverride[]
  {
    if (!this.shouldRefreshCachedOverrides[branch.id][itemType]){ 
      return this.cachedOverrides[branch.id][itemType];
    }

    this.cachedOverrides[branch.id][itemType] = this._getItemDefOverrides(branch, itemType);
    this.shouldRefreshCachedOverrides[branch.id][itemType] = false;

    return this.cachedOverrides[branch.id][itemType];
  }

  protected _getItemDefOverrides(branch: Branch, itemType: ItemType): Sage50MappedItemDefOverride[]
  { 
    const itemDefOverrides = this.settings.branches[branch.id][itemType].item_definition_overrides;
    let mappedItemDefOverrides: Sage50MappedItemDefOverride[] = [];

    for (const [key, val] of Object.entries(itemDefOverrides)) {      
      let itemDefOverride: Sage50MappedItemDefOverride = {
        id: key,
        name: val.name,
        account: val.account,
        variant_overrides: [],
      };

      const variantOverrides = val.variant_overrides;
      
      for (const [key, val] of Object.entries(variantOverrides)) {
        const variantOverride = {
          id: key,
          name: val.name,
          account: val.account,
        };
        
        itemDefOverride.variant_overrides.push(variantOverride);
      }

      mappedItemDefOverrides.push(itemDefOverride);
    }
    return mappedItemDefOverrides;
  }

  _resetShouldRefreshCachedOverrides(): void
  {
    for (const branch of this.branches) {
      for (const itemType of this.ITEM_TYPES) {
        this.shouldRefreshCachedOverrides[branch.id][itemType] = true;
      };
    };
  }

  showBranchOverrides(branch: Branch) {
    for (const itemType of this.ITEM_TYPES) {
      if (this.showOverrides(branch, itemType)) {
        return true;
      }
    }
    return false;
  }

  showOverrides(branch: Branch, itemType: ItemType): boolean {
    if (this.isRecachingOverrides) { return false; }

    return this.hasOverrides[branch.id][itemType];
  }

  async editAccount(account: Sage50MappedAccount): Promise<void>
  {
    const isAccountUsed = this._isAccountUsed(account);
    let fallbackAccountName = '';

    if (!!account && this.mappedAccounts.length > 1)
    {
      fallbackAccountName = this.mappedAccounts[0].key === account.key 
        ? this.mappedAccounts[1].value.id 
        : this.mappedAccounts[0].value.id;
    }

    const onUpdate = async (accountValue: Sage50MappedAccountValue, key: string): Promise<void> => 
    {
      this.settings.accounts[key] = accountValue ;
      await this.onSave();
      this._mapAccounts();
      this._sortItems();
    }

    const onDelete = async (key: string): Promise<void> =>
    {
      if (isAccountUsed)
      {
        this._setDefaultAndOverrideAccountsToFallbackAcccount(key);
      }

      delete this.settings.accounts[key];
      await this.onSave();
      this._mapAccounts();
      this._sortItems();
    }

    await this.accountEditor.open({
      original: account,
      existingAccounts: this.mappedAccounts,
      accountUsed: isAccountUsed,
      fallbackAccountName: fallbackAccountName,
      onUpdate: onUpdate,
      onDelete: onDelete
    });
  }

  protected _isAccountUsed(account: Sage50MappedAccount): boolean
  {
    if (account == null) { return false; }

    const targetKey = account.key;

    for (const branchID in this.settings.branches) {
      for (const itemType in this.settings.branches[branchID]) {
        const itemTypeOverridesOrBranchDefault: string | Sage50ItemTypeOverrides = this.settings.branches[branchID][itemType];
        // default_account for branch
        if (typeof(itemTypeOverridesOrBranchDefault) === "string") {
          if (itemTypeOverridesOrBranchDefault === targetKey) {
            return true;
          }
        } else {
          if (this._doesItemTypeUseAccount(itemTypeOverridesOrBranchDefault, targetKey)) {
            return true;
          }
        }
      }
    }
    return false; 
  }

  protected _doesItemTypeUseAccount(itemTypeOverrides: Sage50ItemTypeOverrides, targetKey: string): boolean
  {
    // item type default_account
    if (itemTypeOverrides.default_account === targetKey) {
      return true;
    }

    // item type overrides
    for (const itemDefinitionOverride of Object.values(itemTypeOverrides.item_definition_overrides)) {
      if (itemDefinitionOverride.account === targetKey) {
        return true;
      }

      for (const variantOverride of Object.values(itemDefinitionOverride.variant_overrides)) {
        if (variantOverride.account === targetKey) {
          return true;
        }
      }
    }

    return false;
  }

  protected _setDefaultAndOverrideAccountsToFallbackAcccount(targetKey: string) {
    const replacementKey = this.mappedAccounts[0].key === targetKey 
      ? this.mappedAccounts[1].key 
      : this.mappedAccounts[0].key;

    for (const branchID in this.settings.branches) {
      for (const itemType in this.settings.branches[branchID]) {
        const itemTypeOverrides: string | Sage50ItemTypeOverrides = this.settings.branches[branchID][itemType];

        // default_account for branch
        if (typeof(itemTypeOverrides) === "string") {
          if (itemTypeOverrides === targetKey) {
            this.settings.branches[branchID][itemType] = replacementKey;
          }
        // itemDefinitionOverride data
        } else {
          this._setItemTypeAccountsToFallback(itemTypeOverrides, targetKey, replacementKey, branchID, itemType);
        }
      }
    }
  }

  protected _setItemTypeAccountsToFallback (itemTypeOverrides: Sage50ItemTypeOverrides, targetKey: string, replacementKey: string, branchID: string, itemType: string): void {
    // item type default_account.
    if (itemTypeOverrides.default_account === targetKey) {
      this.settings.branches[branchID][itemType].default_account = replacementKey;
    }
    // Set account for overrides.
    for (const itemDefinitionOverride of Object.values(itemTypeOverrides.item_definition_overrides)) {
      if (itemDefinitionOverride.account === targetKey) {
        itemDefinitionOverride.account = replacementKey;
      }

      for (const variantOverride of Object.values(itemDefinitionOverride.variant_overrides)) {
        if (variantOverride.account === targetKey) {
          variantOverride.account = replacementKey;
        }
      }
    }
  }

  async onEditExcludedClient(client: Client): Promise<void>
  {
    const onUpdate = async (client: Client): Promise<void> =>
    {
      this.settings.excluded_clients[client.id] = {'name': client.name};
      await this.onSave();
      this._mapExcludedClients();
      this._sortItems();
    }

    const onDelete = async (id: string): Promise<void> =>
    {
      delete this.settings.excluded_clients[id];
      await this.onSave();
      this._mapExcludedClients();
      this._sortItems();
    }

    await this.excludedClientEditor.open({
      original: client,
      excludedClients: this.excludedClients,
      onUpdate: onUpdate,
      onDelete: onDelete
    });
  }

  protected _mapExcludedClients(): void
  {
    this.excludedClients = Object.entries(this.settings.excluded_clients)
      .map( ([key, value]) => ({'id': key, 'name': value.name}) );
  }

  async onEditStartInvoiceNumber(): Promise<void> {
    const onUpdate = async(invoiceNumber: number): Promise<void> => {
      this.settings.additional_settings.invoice_number_start = invoiceNumber;
      await this.onSave();
    }

    await this.additionalSettingsEditor.open({
      original: this.settings.additional_settings?.invoice_number_start,
      onUpdate: onUpdate,
    });
  }

  async onSave(): Promise<void>
  {
    this.integration.settings[0].value = this.settings;
    await this.integration.save();
    this._resetShouldRefreshCachedOverrides();
    this.snackbarNotifications.saved();
  }

  protected _sortItems(): void
  {
    this.branches.sort(orderBy('name'));
    this.mappedAccounts.sort(orderBy('value.id'));
    this.excludedClients.sort(orderBy('name'));
    this._sortOverrides();
  }

  protected _sortOverrides(): void
  {
    // TODO: Jira BL-577
    // for (const branch of this.branches) {
    //   for (const itemType of this.ITEM_TYPES) {
    //     let itemDefOverrides = this.settings.branches[branch.id][itemType].item_definition_overrides;
    //     itemDefOverrides.sort(orderBy('value.name'));
    //   }
    // }
  }
}
