<form class="mat-dialog-editor service-quantities-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign=" center">
        <h2 class="mat-title">{{ this.original.source.formatted_serial_id }} | {{ this.original.source.name }}</h2>
    </mat-toolbar>

    <mat-dialog-content fxLayout="column">

        <!-- AUTO/MANUAL PRICING TYPE -->
        <div *ngIf="this.service.source.pricing_type != 'unit'">
            <div class="dialog-padding" fxLayout="column">
                <h4>Planned</h4>

                <div fxLayout="column">
                    <span class="mat-caption">Start</span>
                    <p class="mat-body-1">
                        {{ this.original.dispatched_started_at | dateFilter:'dateTimeMedium' }}
                        <span *ngIf="this.original.dispatched_ended_at">&ndash;{{ this.original.dispatched_ended_at | dateFilter:'time' }}</span>
                    </p>
                </div>

                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="20">
                        <span class="mat-caption">Time</span>
                        <p class="mat-body-1">{{ this.original.source.regular_time | timeFilter }}</p>
                    </div>
                    <div fxLayout="column" fxFlex="20">
                        <span class="mat-caption">Overtime</span>
                        <p class="mat-body-1">{{ this.original.source.over_time | timeFilter }} </p>
                    </div>
                    <div fxLayout="column" fxFlex="20">
                        <span class="mat-caption">Travel</span>
                        <p class="mat-body-1">{{ this.original.source.travel_time | timeFilter }} </p>
                    </div>                   
                </div>
            </div>

            <mat-divider></mat-divider>

            <div class="dialog-padding" fxLayout="column">
                <h4>Worksheet</h4>

                <p *ngIf="!this.original.actioned_started_at">N/A</p>

                <div fxLayout="row" *ngIf="this.original.actioned_started_at">
                    <div fxLayout="column" fxFlex="60">

                        <div fxLayout="column">
                            <span class="mat-caption">Start</span>
                            <p class="mat-body-1">
                                {{ this.original.actioned_started_at | dateFilter:'dateTimeMedium':'N/A' }}
                                <span *ngIf="this.original.actioned_ended_at">&ndash;{{ this.original.actioned_ended_at | dateFilter:'time' }}</span> 
                            </p>
                        </div>

                        <div fxLayout="row">
                            <div fxLayout="column" fxFlex>
                                <span class="mat-caption">Time</span>
                                <p class="mat-body-1">{{ this.original.regular_time | timeFilter }}</p>
                            </div>
                            <div fxLayout="column" fxFlex>
                                <span class="mat-caption">Overtime</span>
                                <p class="mat-body-1">{{ this.original.over_time | timeFilter }}</p>
                            </div>
                            <div fxLayout="column" fxFlex>
                                <span class="mat-caption">Travel</span>
                                <p class="mat-body-1">{{ this.original.travel_time | timeFilter }}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="time-sheet__list" fxLayout="column" fxFlex="40" *ngIf="this.original.timesheet.length">
                        <span class="mat-caption">Timesheet</span>
                        <span class="mat-body-1" *ngFor="let entry of this.original.timesheet" >
                            {{ entry.timed_at | dateFilter:'time'}} &mdash; <span class="capitalize">{{ entry.task }}</span>
                        </span>
                    </div> 
                </div>
            </div>

            <div class="billable-section">
                <div class="dialog-padding" fxLayout="row" fxLayoutAlign="space-between">
                    <h4>Billable</h4>
                    <mat-slide-toggle [(ngModel)]="this.useWorksheet" 
                        *ngIf="this.hasWorksheet"
                        name="useWorksheetField"
                        (ngModelChange)="this.onUseWorksheetChanged()">
                        <span class="mat-caption">Use Worksheet Values</span>
                    </mat-slide-toggle>
                </div>

                <!-- Using Worksheet Data -->
                <div class="dialog-padding" [fxShow]="this.useWorksheet">
                    <div fxLayout="column">
                        <span class="mat-caption">Start</span>
                        <p class="mat-body-1">
                            {{ this.original.started_at | dateFilter:'dateTimeMedium'}}<span *ngIf="this.original.ended_at">&ndash;{{ this.original.ended_at | dateFilter:'time' }}</span>
                        </p>
                    </div>

                    <div fxLayout="row">
                        <div fxLayout="column" fxFlex="20">
                            <span class="mat-caption">Time</span>
                            <p class="mat-body-1">{{ this.original.regular_time | timeFilter }}</p>
                        </div>
                        <div fxLayout="column" fxFlex="20">
                            <span class="mat-caption">Overtime</span>
                            <p class="mat-body-1">{{ this.original.over_time | timeFilter }}</p>
                        </div>
                        <div fxLayout="column" fxFlex="20">
                            <span class="mat-caption">Travel</span>
                            <p class="mat-body-1">{{ this.original.travel_time | timeFilter }}</p>
                        </div>
                    </div>
                </div>

                <!-- Using Planned Data -->
                <div [fxHide]="this.useWorksheet">
                    <div class="dialog-padding" fxLayout="row" fxLayoutAlign="space-between start">
                        <mat-form-field fxFlex="22">
                            <mat-label>Start Date</mat-label>
                            <input matInput 
                                [matDatepicker]="startDatePicker" 
                                [(ngModel)]="service._dates.actioned_started_at.date" 
                                (ngModelChange)="this.calculateTimes()"
                                name="startDateField"
                                #startDateField="ngModel"
                                (focus)="startDatePicker.open()"
                                [min]="this.minDate"
                                [max]="this.service._dates.actioned_ended_at.date"
                                required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error *ngIf="startDateField.hasError('required')">Start date is required.</mat-error>
                            <mat-error *ngIf="startDateField.hasError('matDatepickerMin')">Date is too early.</mat-error>
                            <mat-error *ngIf="startDateField.hasError('matDatepickerMax')">Date is too late.</mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="22">
                            <mat-label>Start Time</mat-label>
                            <input type="time"
                                [(ngModel)]="this.service._dates.actioned_started_at.time"
                                name="startTimeField"
                                #startTimeField="ngModel"
                                (ngModelChange)="this.calculateTimes()"
                                required
                                matInput>
                            <mat-error *ngIf="startTimeField.hasError('required')">Start time is required.</mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="22">
                            <mat-label>End Date</mat-label>
                            <input matInput 
                                [matDatepicker]="endDatePicker" 
                                [(ngModel)]="service._dates.actioned_ended_at.date" 
                                (ngModelChange)="this.calculateTimes()"
                                name="endDateField"
                                #endDateField="ngModel"
                                (focus)="endDatePicker.open()"
                                [min]="this.service._dates.actioned_started_at.date">
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <mat-error *ngIf="endDateField.hasError('matDatepickerMin')">Date is too early.</mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="22">
                            <mat-label>End Time</mat-label>
                            <input type="time"
                                [(ngModel)]="this.service._dates.actioned_ended_at.time"
                                name="endTimeField"
                                #endTimeField="ngModel"
                                (ngModelChange)="this.calculateTimes()"
                                matInput>
                                <mat-error *ngIf="endDateField.hasError('matDatepickerMin')">Date is too early.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="dialog-padding" fxLayout="row">
                        <mat-slide-toggle [(ngModel)]="this.allowTimeOverride" 
                            name="allowTimeOverrideField"
                            (ngModelChange)="this.onTimeOverrideChanged()">
                        <span class="mat-caption">Time Override</span>
                        </mat-slide-toggle>
                    </div>

                    <div class="dialog-padding" fxLayout="row" fxLayoutAlign="space-between start">
                        <mat-form-field fxFlex="30">
                            <mat-label>Time</mat-label>
                            <input type="text"
                                [(ngModel)]="this.service.regular_time"
                                name="regularTimeField"
                                [disabled]="!this.allowTimeOverride"
                                timeInput
                                matInput>
                        </mat-form-field>

                        <mat-form-field fxFlex="30">
                            <mat-label>Overtime</mat-label>
                            <input type="text"
                                [(ngModel)]="this.service.over_time"
                                name="overTimeField"
                                [disabled]="!this.allowTimeOverride"
                                timeInput
                                matInput>
                        </mat-form-field>

                        <mat-form-field fxFlex="30">
                            <mat-label>Travel Time</mat-label>
                            <input type="text"
                                [(ngModel)]="this.service.travel_time"
                                name="TravelTimeField"
                                timeInput
                                matInput>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- UNIT PRICING TYPE -->
        <div *ngIf="this.service.source.pricing_type == 'unit'">
            <div class="dialog-padding" fxLayout="row">
                <h4>Planned</h4>
            </div>

            <div fxLayout="row">
                <div class="dialog-padding" fxLayout="column" fxFlex="33">
                    <span class="mat-caption">Quantity</span>
                    <p class="mat-body-1">{{ this.original.source.quantity }} {{ this.original.source.unit_short }}</p>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div fxLayout="column">
                <div class="dialog-padding" fxLayout="row">
                    <h4>Worksheet</h4>
                </div>

                <div class="dialog-padding" fxFlex="33" fxLayout="column">
                    <span class="mat-caption">Quantity</span>
                    <p class="mat-body-1">{{ this.original.actioned_quantity  }} {{ this.original.source.unit_short }}</p>
                </div>
            </div>

            <div class="billable-section" fxLayout="column">
                <div class="dialog-padding" fxFlex fxLayout="row" fxLayoutAlign="space-between">
                    <h4>Billable</h4>
                    <mat-slide-toggle [(ngModel)]="this.useWorksheet" 
                        name="useWorksheetField"
                        (ngModelChange)="this.onUseWorksheetChanged()">
                        <span class="mat-caption">Use Worksheet Values</span>
                    </mat-slide-toggle>
                </div>

                <!-- Using Worksheet Data -->
                <div [fxShow]="this.useWorksheet">
                    <div class="dialog-padding" fxLayout="column">
                        <span class="mat-caption">Quantity</span>
                        <p class="mat-body-1">{{ this.original.actioned_quantity }}</p>
                    </div>
                </div>

                <!-- Using Planned/Override Data -->
                <div [fxHide]="this.useWorksheet">
                    <div class="dialog-padding" fxLayout="row">
                        <mat-form-field>
                            <mat-label>Quantity</mat-label>
                            <input type="number"
                                [(ngModel)]="this.service.actioned_quantity"
                                name="actionedQuantityField"
                                #actionedQuantityField="ngModel"
                                [blStep]="this.qtyInputStep"
                                [errorStateMatcher]="syncErrorStateMatcher"
                                minNum="0"
                                required
                                matInput>
                            <mat-error *ngIf="this.actionedQuantityField.hasError('required')">Quantity is required.</mat-error>
                            <mat-error *ngIf="this.actionedQuantityField.hasError('minNum')">Must be at least 0.</mat-error>
                            <mat-error *ngIf="this.actionedQuantityField.hasError('blStep')">{{ qtyStepError }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button [mat-dialog-close]>
            Cancel
        </button>   
        <button type="submit" 
            mat-raised-button 
            color="accent"
            [disabled]="this.saving || this.hasIssueRestraints()">
            Apply
        </button>   
    </mat-dialog-actions>
</form>