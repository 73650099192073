import { dto } from './mixins/Dto.decorators';
import { Variant } from './Variant.model';
import { VariantRentalProperty } from './VariantRentalProperty.model';
import { VariantRentalPropertyDefinition } from './VariantRentalPropertyDefinition.model';
import { GroupMeta } from './contracts/GroupMeta.interface';
import { AppInjector } from '@beaconlite/services/app-injector.service';
import { RouteMap } from '@beaconlite/services/network/route-map.service';

export class VariantRental extends Variant {

    constructor(attributes: object = {}) 
    {
        super();
        this.init(attributes);
    }

    @dto() quantity: number = 0;
    @dto() daily_rate: number = 0;
    @dto() retail_cost: number = 0;
    @dto() default_pricing_type: string = '';
    // Can't pass constructor to dto() due to circular reference. Constructor should be RentalDefinition.
    @dto() rental_definition: any = null;
    @dto(VariantRentalProperty) properties: VariantRentalProperty[] = [];

    // Pivot table properties
    @dto() group_meta: GroupMeta = null;

    addProps(definitions: VariantRentalPropertyDefinition[]) {
        this._addProps(VariantRentalProperty, definitions);
    }

    static async get(id: string): Promise<VariantRental> 
    {
        const response = await AppInjector.get(RouteMap).getVariantRental(id);
        return new VariantRental( response.data() );
    }

    async save(): Promise<VariantRental>
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateVariantRental(this.id, this.flush())
            : await this.routeMap.createVariantRental(this.flush())

        return this.map( response.data() );
    }

    async discard(): Promise<void>
    {
        await this.routeMap.discardVariantRental(this.id);
    }
}
