import { Ng2StateDeclaration } from '@uirouter/angular';
import { LostItemComponent } from './lost-item.component';

export const LostItemsReportState: Ng2StateDeclaration = {
  name: 'protected.reports.lost-item',
  url: '/lost-item',
  component: LostItemComponent,
  data: { 
    pageTitle: 'Lost Item Report', 
    requiresAuth: true,
  },
}
