<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
    <div class="login-form">
        <img src="/assets/img/{{ company.logo_colour }}" alt="Company Logo" class="login-form-logo">
        <mat-card>
        <mat-progress-bar mode="indeterminate" [fxShow]="pending"></mat-progress-bar>
        <div fxLayout="column">
            <mat-card-title class="text-center">
                <span class="md-headline">Admin Portal</span>
            </mat-card-title>
            <mat-card-content>
                <form #passwordResetForm="ngForm" (ngSubmit)="onResetPassword()">
                    <mat-form-field class="md-block" appearance="outline" hideRequiredMarker>
                        <mat-label>Password</mat-label>
                        <input [(ngModel)]="user.password"
                            name="password"
                            #password="ngModel"
                            type="password"
                            matInput 
                            required>
                        <mat-error *ngIf="password.hasError('required')">Password is required.</mat-error>
                        <mat-error *ngIf="password.hasError('passwordStrength')">Password is too weak.</mat-error>
                    </mat-form-field>
                    <app-password-strength [passwordModel]="password"></app-password-strength>
      
                    <mat-form-field class="md-block" appearance="outline" hideRequiredMarker>
                        <mat-label>Confirm Password</mat-label>
                        <input [(ngModel)]="user.passwordConfirm"
                            name="passwordConfirm"
                            #passwordConfirm="ngModel"
                            type="password"
                            matInput
                            required
                            valueMustMatch="password">
                        <mat-error *ngIf="passwordConfirm.hasError('required')">Password confirm is required.</mat-error>
                        <mat-error *ngIf="passwordConfirm.hasError('valueMustMatch')">Passwords must match.</mat-error>
                    </mat-form-field>
      
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button type="submit" color="accent" [disabled]="pending">
                            Reset password
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </div>
        </mat-card>
      </div>

<!-- Padding to offset vertical centering -->
<span fxFlex="25"></span>

</div>
