import { Component, OnInit, Injectable, Inject, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RentalDefinition, Thumbnail, Department } from '@beaconlite/models';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

export interface RentalDefinitionEditorData {
  original: RentalDefinition,
  departments: Department[],
  weekThreshold: number,
  monthThreshold: number,
  onUpdate: (rentalDefinition: RentalDefinition) => Promise<void>
}

@Injectable({
  providedIn: 'root'
})
export class RentalDefinitionEditorService {

  constructor(protected dialog: MatDialog) {}

  async open(data?: RentalDefinitionEditorData): Promise<RentalDefinition> 
  {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      width: '100%',
      maxWidth: 800,
      data,
    }

    return this.dialog.open(RentalDefinitionEditorComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }
}

@Component({
  selector: 'app-rental-definition-editor',
  templateUrl: './rental-definition-editor.component.html',
  styleUrls: ['./rental-definition-editor.component.scss']
})
export class RentalDefinitionEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;
  @Input() modelId: string;

  original = this.data.original;
  departments = this.data.departments;
  weekThreshold = this.data.weekThreshold;
  monthThreshold = this.data.monthThreshold;
  onUpdate = this.data.onUpdate;

  loading = false;
  locked = false;
  isPercentDiscount = true;
  weeklyDiscount = 0;
  monthlyDiscount = 0;
  rentalDefinition: RentalDefinition;

  readonly PRICING_RATED = RentalDefinition.PRICING_RATED;
  readonly PRICING_FIXED = RentalDefinition.PRICING_FIXED;
  readonly PRICING_FLAT  = RentalDefinition.PRICING_FLAT;

  pricingTypes = [
    this.PRICING_RATED,
    this.PRICING_FIXED,
    this.PRICING_FLAT
  ];

  uploadThumbnail: () => Promise<Thumbnail>;
  removeThumbnail: () => Promise<void>;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: RentalDefinitionEditorData,
    public dialogRef: MatDialogRef<RentalDefinitionEditorComponent>,
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.rentalDefinition = this.original.copy();
  }

  getPriceTypeLabel(priceType) 
  {
    return this.rentalDefinition.getPriceTypeLabel(priceType);
  }

  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.rentalDefinition.discarded;
  }

  onDailyRateChange(): void
  {
    if ( !(this.rentalDefinition.default_pricing_type === this.PRICING_RATED )
      || this.isPercentDiscount) 
    {
      return;
    }
    // Refresh the values for $/day, $/week, $/month to reflect the updated this.rentalDefinition.daily_rate.
    this.onWeeklyDiscountPriceChange();
    this.onMonthlyDiscountPriceChange();
  }

  onIsPercentDiscountChange(isPercentDiscount: boolean): void
  {
    // From $ to %.
    if (isPercentDiscount)
    {
      return;
    } 
    // From % to $.
    // Sync this.weeklyDiscount / this.monthlyDiscount values to correspond to this.rentalDefinition.weekly_discount / this.rentalDefinition.monthly_discount values
    this.weeklyDiscount = this._getDiscountPriceFromPercent(this.rentalDefinition.weekly_discount, this.rentalDefinition.daily_rate);
    this.monthlyDiscount = this._getDiscountPriceFromPercent(this.rentalDefinition.monthly_discount, this.rentalDefinition.daily_rate);
    // Refresh the values for $/day, $/week, $/month to reflect the value in this.weeklyDiscount / this.monthlyDiscount.
    this.onWeeklyDiscountPriceChange();
    this.onMonthlyDiscountPriceChange();
  }

  onWeeklyDiscountPriceChange(): void
  {
    this.rentalDefinition.weekly_discount = this._getDiscountPercentFromPrice(this.weeklyDiscount, this.rentalDefinition.daily_rate);
  }

  onMonthlyDiscountPriceChange(): void
  {
    this.rentalDefinition.monthly_discount = this._getDiscountPercentFromPrice(this.monthlyDiscount, this.rentalDefinition.daily_rate);
  }

  calculateRate(discount: number, days: number): number
  {
    return RentalDefinition.calculateRateFromDiscount(this.rentalDefinition.daily_rate, discount, days);
  }

  async save(): Promise<RentalDefinition>
  {
    if (this.editorForm.invalid) { return; }

    this.rentalDefinition.applyPricingDefaults();

    try
    {
      this.lockAndLoad();
      await this.uploadThumbnail?.();
      await this.removeThumbnail?.();
      await this.onUpdate(this.rentalDefinition);
      this.snackbarNotification.saved();
      this.dialogRef.close();
    }
    finally
    {
      this.unlockAndUnload();
    }

    return (this.rentalDefinition);
  }

  async onClose(): Promise<void>
  {
    this.dialogRef.close();
  }

  onThumbnailSelected(uploadThumbnail: () => Promise<Thumbnail>)
  {
    this.uploadThumbnail = uploadThumbnail;
    this.removeThumbnail = null;
  }

  onThumbnailRemoved(removeThumbnail: () => Promise<void>)
  {
    this.removeThumbnail = removeThumbnail;
    this.uploadThumbnail = null;
    this.rentalDefinition.thumbnail = null;
  }

  onThumbnailUpdated(thumbnail: Thumbnail): void
  {
    this.rentalDefinition.thumbnail = thumbnail;
  }

  protected _getDiscountPercentFromPrice(discount: number, dailyRate: number): number
  {
    if (dailyRate == 0)
    {
      return 0;
    }

    const decimalPlaces = 4;
    const discountPercentage = this._roundDecimals( ( 1 - ( discount / dailyRate ) ), decimalPlaces);
    return discountPercentage;
  }

  protected _getDiscountPriceFromPercent(discount: number, dailyRate: number): number 
  {
    return ((1 - discount) * dailyRate);
  }

  protected _roundDecimals (number: number, digits: number): number
  {
    const multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        roundedNum = Math.round(adjustedNum);
    return roundedNum / multiplier;
  }
}
