import { BaseModel } from "./Base.model";
import { Service } from "./Service.model";
import { DispatchedRequest } from "./contracts/DispatchedRequest.interface";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'

export class DispatchedService extends BaseModel implements DispatchedRequest {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() dispatch_id?: string = null;
    @dto() service_id?: string = null;
    @dto() dispatched_quantity?: number = 1;
    @dto() actioned_quantity?: number = null;
    @dto() reviewed_quantity?: number = null;
    //pricing_type: 'auto';
    //overtime_rate_applied: true;
    @dto() time_override?: boolean = false;
    @dto() timed_task?: string = null;
    @dto() @date timed_at?: number = null;
    @dto() @date dispatched_started_at?: number = null;
    @dto() @date dispatched_ended_at?: number = null;
    @dto() @date actioned_started_at?: number = null;
    @dto() @date actioned_ended_at?: number = null;
    @dto() @date reviewed_started_at?: number = null;
    @dto() @date reviewed_ended_at?: number = null;
    @dto() regular_time?: number = 0;
    @dto() over_time?: number = 0;
    @dto() travel_time?: number = 0;
    // TODO: should type this with {'timed_at': request.timed_at,'task': task}.  Figure out if those are the only
    //       properties we'll need
    @dto() timesheet?: Array<any> = [];
    @dto() @date reviewed_at?: number = null;

    @dto(Service) source?: Service = null;

    static fromService(source: Service | DispatchedService): DispatchedService 
    {
        // Already the right type
        if ( source instanceof DispatchedService ) { return source; }

        let service = new DispatchedService();
        service.source = source;
        service.service_id = source.id;
        service.dispatched_started_at = source.started_at;
        service.dispatched_ended_at = source.ended_at;
        return service;     
    }

    async review(reviewedService: DispatchedService): Promise<DispatchedService> 
    {
        const response = await this.routeMap.reviewDispatchedService(this.id, reviewedService.flush());
        return this.map(response.data());
    } 
}
