import { HttpResponse } from "@beaconlite/services/network/http-response";
import { LineItem } from "../LineItem.model";
import { BaseCollection } from "./Base.collection";
import { AppInjector } from "@beaconlite/services/app-injector.service";
import { RouteMap } from "@beaconlite/services/network/route-map.service";


export class LineItemCollection extends BaseCollection<LineItem> {
    
    constructor(options?: any) {
        super(LineItem, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return AppInjector.get(RouteMap).searchLineItemDefinitions(params);
    }
}
