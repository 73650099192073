import { HttpResponse } from "../../services/network/http-response";
import { Export } from "../Export.model";
import { BaseCollection } from "./Base.collection";

export class ExportCollection extends BaseCollection<Export> {

    constructor(options?: any) {
        super(Export, options)
    }

    async searchFn(params): Promise<HttpResponse> {
        return this._routeMap.searchExports(params);
    }
}
