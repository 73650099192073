import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class Helpers {

    constructor() { }

    /**
     * Evaluate the data type of a Javascript variable.
     *
     * @param {*} value
     * @param {String} type
     * @returns {boolean}
     */
    static isDataType(value: any, type: string): boolean
    {
        type.toLowerCase();

        var expectedType = '[object ' + type.charAt(0).toUpperCase() + type.slice(1) + ']';
        var actualType = Object.prototype.toString.call(value);

        return expectedType === actualType;
    }

    /**
     * Evaluate if a value is of type Boolean.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isBoolean(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Boolean]';
    }

    /**
     * Evaluate if a value is of type Null.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isNull(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Null]';
    }

    /**
     * Evaluate if a value is of type Undefined.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isUndefined(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Undefined]';
    }

    /**
     * Evaluate if a value is of type Number.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isNumber(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Number]';
    }

    /**
     * Evaluate if a value is of type Number.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isInteger(value: any): boolean
    {
        return this.isNumber(value) && value % 1 === 0;
    }

    /**
     * Evaluate if a value is of type String.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isString(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object String]';
    }

    /**
     * Evaluate if a value is of type Object.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isObject(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Object]';
    }

    /**
     * Evaluate if a value is of type Array.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isArray(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Array]';
    }

    /**
     * Evaluate if a value is of type Function.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isFunction(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Function]';
    }

    /**
     * Evaluate if a value is of type Date.
     *
     * @param {*} value
     * @returns {boolean}
     */
    static isDate(value: any): boolean
    {
        return Object.prototype.toString.call(value) === '[object Date]';
    }

    /**
     * Create a deep copy of the provided object.
     * 
     * @param {*} source 
     * @returns {*}
     */
    static deepCopy(source: any): any 
    {
        if (source === undefined) { return null; }
        return JSON.parse(JSON.stringify(source));
    }

    /**
     * Create a shallow copy of the provided object.
     * 
     * @param {*} source 
     * @returns {*}
     */
    static shallowCopy(source: any): any {
        return Object.assign({}, source);
    }

    /**
     * Evaluate if an object inherits from (or is a subclass of) a specified class/constructor.
     *
     * @param {Object} instance
     * @param {Function} clazz
     * @returns {boolean}
     */
    static instanceOf(instance: object, clazz: Function): boolean
    {
        if ( !this.isObject(instance) )
        {
            return false;
        }

        if ( !this.isFunction(clazz) )
        {
            return false;
        }

        return clazz.prototype.isPrototypeOf(instance);
    }

    /**
     * Round a float value to a specified precision.
     *
     * @param {Number} value
     * @param {Number} precision
     * @returns {Number}
     */
    static roundFloat(value: number, precision: number = 0): number
    {
        var magnitude = Math.pow(10, precision);
        var rounded = Math.round(value * magnitude) / magnitude;

        return rounded;
    }

    /**
     * Converts a JS Date object into a unix timestamps
     *
     * @param {Date} date
     * @returns {Number}
     */
    static dateToTimestamp(date: Date): number
    {
        return Math.floor( date.valueOf() / 1000 );
    }

    /**
     * Performs a look up on a JS object provided a dot notation string
     * "b.c" {b:{c:true}}
     *
     * @param {Object} object
     * @param {string} path
     * @returns {string}
     */
    static dotNotationLookup(object: object, path: string): string
    {
        function index(obj: any,i: string | number) {return obj[i]}
        
        return path.split('.').reduce(index, object);
    }

    /**
     * Get the seconds from midnight
     * 
     * @param {number} timestamp 
     * @returns {number}
     */
    static secondsFromMidnight(timestamp: number): number
    {
        var midnight = moment.unix(timestamp).startOf('day');
        return timestamp - midnight.unix();
    }

    /**
     * Generate a 'unique' string of n length
     * 
     * Dirty because JS is only pseudo-random.
     * 
     * https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
     * 
     * @param {number} length 
     * @returns {string}
     */
    static generateDirtyId(length: number): string 
    {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    /**
     * Left zero pad a number
     * 
     * @param {Number} value 
     * @param {Number} size 
     * @returns {string}
     */
    static zeroPadLeft(value: number | string, size: number): string
    {
        if (typeof value == 'string')
        {
            value = parseInt(value);
        }

        let padded = ''+value
        
        // Value large enough. Nothing to pad.
        if ( padded.length >= size ) { return padded; }
        
        
        let pad = '';

        for(let i=0; i<size; i++)
        {
            pad += '0';
        }

        padded = (pad + padded).slice(-size)
        
        return padded;
    }


    /**
     * Calculate the amount of overtime in seconds between 2 moments
     * 
     * @param {number} OVERTIME_START 
     * @param {number} OVERTIME_END
     * @param {moment.Moment} periodStart
     * @param {moment.Moment} periodEnd
     * @returns {number}
     */
    static calculateOvertime(OVERTIME_START: number, OVERTIME_END: number, periodStart: moment.Moment, periodEnd: moment.Moment): number
    {
        let seconds = 0;

        // Moving boundary variables for calculating time duration (initialize to periodStart)
        let boundaryStart   = periodStart.clone();
        let boundaryEnd     = periodStart.clone();
        let rangeStart      = periodStart.clone().startOf('day').seconds(OVERTIME_START);
        let rangeEnd        = periodStart.clone().startOf('day').seconds(OVERTIME_END);

        // Initial adjustment to ensure rangeStart is in the future (i.e. the next occurrence of rangeStart after periodStart)
        if ( rangeStart.isBefore(boundaryStart) )
        {
            rangeStart.add(1, 'days');
        }

        // Initial adjustment to ensure rangeEnd is in the future (i.e. the next occurrence of rangeEnd after periodStart)
        if ( rangeEnd.isSameOrBefore(boundaryStart) )
        {
            rangeEnd.add(1, 'days');
        }

        // Set the current boundary's end to whichever occurs first (rangeEnd or periodEnd)
        let firstRange = rangeStart.isBefore(rangeEnd) ? rangeStart : rangeEnd;
        if ( periodEnd.isAfter(firstRange) )
        {
            boundaryEnd = firstRange.clone();
        }
        else
        {
            boundaryEnd = periodEnd.clone();
        }

        // Starting outside business hours count the OT and adjust the bounds
        // Handle leading exception:
        // If periodStart starts partially through the specified range (i.e. between rangeStart and rangeEnd)
        if ( rangeEnd.isBefore(rangeStart) )
        {
            // Count time
            seconds += boundaryEnd.diff(boundaryStart, 'seconds');

            // Adjust rangeEnd if applicable (to next applicable rangeEnd occurrence)
            if ( rangeEnd.isBefore(rangeStart) )
            {
                rangeEnd.add(1, 'days');
            }

            boundaryStart = boundaryEnd.clone();
            boundaryEnd =  periodEnd.isAfter(rangeStart) ? rangeStart.clone() : periodEnd.clone();

        }

        // Exit early if we have already reached the end
        if ( boundaryStart.isSameOrAfter(periodEnd) )
        {
            return seconds;
        }

        // Starting during business hours
        // Weekend
        if ( boundaryStart.day() == 0 || boundaryStart.day() == 6 )
        {
            // Count time anyway
            seconds += boundaryEnd.diff(boundaryStart, 'seconds');
        }

        // Keep counting time until the next applicable rangeStart exceeds the given periodEnd
        while ( rangeStart.isBefore(periodEnd) )
        {
            // Adjust boundaries for the next countable period
            boundaryStart = rangeStart.clone();
            boundaryEnd = rangeEnd.clone();

            // Move boundaryEnd up if we reach the periodEnd
            if ( periodEnd.isBefore(boundaryEnd) )
            {
                boundaryEnd = periodEnd.clone();
            }

            // Count time
            seconds += boundaryEnd.diff(boundaryStart, 'seconds');
            
            // Move to next applicable range
            rangeStart.add(1, 'days');
            rangeEnd.add(1, 'days');

            if ( boundaryEnd.day() == 0 || boundaryEnd.day() == 6 )
            {          
                if ( rangeStart.isBefore(periodEnd) )
                {
                    seconds += rangeStart.diff(boundaryEnd, 'seconds');
                }
                else
                {
                    seconds += periodEnd.diff(boundaryEnd, 'seconds');
                }
            }

        }

        return seconds;
    }

}
