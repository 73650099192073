import { Ng2StateDeclaration } from '@uirouter/angular';
import { EmployeeScheduleComponent } from './employee-schedule.component';

export const EmployeeScheduleState: Ng2StateDeclaration = {
    name: 'protected.employee-schedule',
    url: '/employee-schedule',
    component: EmployeeScheduleComponent,
    data: {
        pageTitle: 'Employee Schedule',
        requiresAuth: true,
    },
}
