<form class="mat-dialog-editor dispatch-slip-service-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate fxFlexFill fxLayout="column">

    <mat-toolbar fxLayout="row">
        <h2 class="mat-title">{{ this.service.source.name }}</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div>Quantity</div>
        <div fxLayout="row">
            <mat-form-field>
                <input type="number"
                    [(ngModel)]="this.service.actioned_quantity"
                    name="qtyField"
                    #qtyField="ngModel"
                    [blStep]="this.qtyInputStep"
                    [errorStateMatcher]="syncErrorStateMatcher"
                    minNum="0"
                    matInput>
                <mat-error *ngIf="qtyField.hasError('minNum')">Quantity must be at least 0.</mat-error>
                <mat-error *ngIf="qtyField.hasError('blStep')">{{ this.qtyStepError }}</mat-error>
            </mat-form-field>
            <span class="unit-label">{{ this.service.source.unit_long }}</span>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <mat-progress-spinner *ngIf="this.saving" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>Cancel</button>
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>
