<mat-table class="mat-selectable-table mat-no-header-table" [dataSource]="notes">
    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell fxFlex="15" *matCellDef="let note">
            <b class="note__type-indicator note__type-indicator--{{note.type}}"></b>
            {{ note.displayType() }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
        <mat-cell fxFlex="20" *matCellDef="let note">{{ note.displayDate() }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="content">
        <mat-header-cell *matHeaderCellDef>Content</mat-header-cell>
        <mat-cell *matCellDef="let note" class="multiline">{{ note.content }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="notesTableColumns"></mat-header-row>
    <mat-row *matRowDef="let note; columns: notesTableColumns" (click)="onNoteSelected(note)"></mat-row>
</mat-table>
