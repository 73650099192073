import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';
import { StateService } from '@uirouter/core';
import { ChargeDefinition } from '@beaconlite/models';
import { UnsavedChangesService } from '@beaconlite/services/unsaved-changes.service';

@Component({
  selector: 'app-charge-single',
  templateUrl: './charge-single.component.html',
  styleUrls: ['./charge-single.component.scss']
})
export class ChargeSingleComponent implements OnInit {

  @Input() modelId: string;
  @ViewChild('editorForm') editorForm: NgForm;

  loading = false;
  locked = false;
  title = 'New Charge';
  charge = new ChargeDefinition();

  constructor(
    protected snackbarNotification: SnackbarNotificationService,
    protected dialogNotification: DialogNotificationService,
    protected $state: StateService,
    protected unsavedChanges: UnsavedChangesService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    if (!! this.modelId && this.modelId != 'new')
    {
      this.lockAndLoad();

      try
      {
        this.charge = await ChargeDefinition.get(this.modelId);
        this.title = this.charge.label;
      }
      finally
      {
        this.unlockAndUnload();
      }
    }

    this.unsavedChanges.setDiscardCheck(() => !this.editorForm.pristine && !this.editorForm.submitted);
  }

  async save(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) return false;

    const modelExists: boolean = this.charge.exists();
    this.lockAndLoad();

    try
    {
      await this.charge.save();
      this.snackbarNotification.saved();

      if (! modelExists)
      {
        this.$state.go('protected.charges.single', { modelId: this.charge.id });
      }
    }
    finally
    {
      this.unlockAndUnload();
    }

  }

  async discard(): Promise<void|boolean>
  {
    if (! await this.dialogNotification.discardConfirm()) return false;

    this.lockAndLoad();

    try
    {
      await this.charge.discard();
      this.$state.go('protected.charges.single', { modelId: 'new' });
    }
    finally
    {
      this.unlockAndUnload();
    }
  }

  async restore(): Promise<void|boolean>
  {
    if (! await this.dialogNotification.recoverConfirm()) return false;

    this.lockAndLoad();

    try
    {
      await this.charge.recover();
    }
    finally
    {
      this.unlockAndUnload();
    }
  }


  lockAndLoad(): void
  {
    this.loading = true;
    this.locked = true;
  }

  unlockAndUnload(): void
  {
    this.loading = false;
    this.locked = this.charge.discarded;
  }
}
