import { Ng2StateDeclaration } from '@uirouter/angular';
import { UserDispatchesIndexComponent } from './user-dispatches-index.component';

export const UserDispatchesIndexState: Ng2StateDeclaration = {
  name: 'protected.user-dispatches',
  url: '/user-dispatches',
  component: UserDispatchesIndexComponent,
  // TODO: make sure this is requiring auth properly
  data: { 
    pageTitle: 'My Dispatches', 
    requiresAuth: true,
  },
}