import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[blAutofocus]'
})
export class BlAutofocusDirective implements AfterViewInit {

    @Input('blAutofocus') shouldAutofocus: boolean = true;

    constructor(protected elementRef: ElementRef) {}
    
    ngAfterViewInit()
    {
        if (!this.shouldAutofocus) return;

        setTimeout(() => this.elementRef.nativeElement.focus())
    }
}
