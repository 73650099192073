import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CurrencyInputDirective } from './directives/currency-input.directive';
import { MinNumValidatorDirective } from './directives/min-num-validator.directive';
import { ValueMustMatchValidatorDirective } from './directives/value-must-match-validator.directive';
import { PercentInputDirective } from './directives/percent-input.directive';
import { TimeInputDirective } from './directives/time-input.directive';
import { MaterialModule } from './material.module';
import { DateFilterPipe } from './pipes/date-filter.pipe';
import { CurrencyFilterPipe } from './pipes/currency-filter.pipe';
import { PercentFilterPipe } from './pipes/percent-filter.pipe';
import { BytesFilterPipe } from './pipes/bytes-filter.pipe';
import { TimeFilterPipe } from './pipes/time-filter.pipe';
import { LayoutPaddingDirective } from './directives/layout-padding.directive';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { BlAutofocusDirective } from './directives/bl-autofocus.directive';
import { BlInputStepDirective } from './directives/bl-input-step.directive';
import { MaxNumValidatorDirective } from './directives/max-num.directive';
import { TimeOfDayInputDirective } from './directives/time-of-day-input.directive';
import { TimeOfDayFilterPipe } from './pipes/time-of-day-filter.pipe';
import { InnerHTMLBodyContent } from './pipes/inner-body-html-content.pipe';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
    ],
    declarations: [
        // App directives
        CurrencyInputDirective,
        TimeOfDayInputDirective,
        MinNumValidatorDirective,
        MaxNumValidatorDirective,
        ValueMustMatchValidatorDirective,
        PercentInputDirective,
        TimeInputDirective,
        BlAutofocusDirective,
        BlInputStepDirective,
        DateFilterPipe,
        CurrencyFilterPipe,
        PercentFilterPipe,
        TimeFilterPipe,
        BytesFilterPipe,
        TimeOfDayFilterPipe,
        LayoutPaddingDirective,
        PasswordStrengthComponent,
        InvoiceComponent,
        InnerHTMLBodyContent,
    ],
    providers: [
        DatePipe,
        CurrencyPipe,
        PercentPipe,
        DateFilterPipe,
        DecimalPipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        MaterialModule,

        // App directives
        CurrencyInputDirective,
        TimeOfDayInputDirective,
        MinNumValidatorDirective,
        ValueMustMatchValidatorDirective,
        MaxNumValidatorDirective,
        PercentInputDirective,
        TimeInputDirective,
        BlAutofocusDirective,
        BlInputStepDirective,
        DateFilterPipe,
        CurrencyFilterPipe,
        PercentFilterPipe,
        TimeFilterPipe,
        BytesFilterPipe,
        TimeOfDayFilterPipe,
        LayoutPaddingDirective,
        PasswordStrengthComponent,
        InvoiceComponent,
        InnerHTMLBodyContent,
    ]
})
export class SharedModule { }
