import { VariantOption } from "./VariantOption.model";
import { dto } from "./mixins/Dto.decorators";

export class VariantServiceOption extends VariantOption {

    constructor(attributes: object = {})
    {
        super();
        this.map(attributes);
    }

    @dto() regular_rate?: number = 0;
    @dto() overtime_rate?: number = 0;
}
