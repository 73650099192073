import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { InvoiceEditorService } from '../../../../../components/editors/invoice-editor/invoice-editor.service';
import { Invoice, WorkOrder } from '@beaconlite/models';
import { orderBy } from '@beaconlite/utilities/Sort.utility';

@Component({
  selector: 'work-order-tab-invoices',
  templateUrl: './tab-invoices.component.html',
})
export class TabInvoicesComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;

  invoiceTableColumns = ['date-issued', 'number', 'invoice-period', 'last-sent', 'synced', 'grand-total'];

  constructor(protected invoiceEditor: InvoiceEditorService) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  onAddInvoice()
  {
    if (this.workOrder.invoiced) return false;

    return this.invoiceEditor.open({
      workOrder: this.workOrder,
      original: null,
      isInvoiceType: true,
      onInvoiceUpdate: this.onInvoiceUpdate,
    });
  }

  async onEditInvoice(invoice: Invoice = null): Promise<boolean>
  {
    return this.invoiceEditor.open({
      workOrder: this.workOrder,
      original: invoice,
      isInvoiceType: true,
      onInvoiceUpdate: this.onInvoiceUpdate,
      onInvoiceRemove: this.onInvoiceRemove,
    });
  }

  onInvoiceUpdate = async (): Promise<void> => 
  {
    await this.workOrder.reload();
  }

  onInvoiceRemove = async (): Promise<void> => 
  {
    await this.workOrder.reload();
  }

  getMarkedSentLabel(invoice: Invoice): string {
    return (!invoice.last_sent_at && invoice.marked_as_sent_at) ? 'Marked Sent - ' :  '';
  }

  getSentOrMarkedSentAt(invoice: Invoice): number {
    return invoice.last_sent_at ? invoice.last_sent_at : invoice.marked_as_sent_at;
  }

  protected _sortItems(): void
  {
    this.workOrder.invoices.sort(orderBy('-issued_at', '-started_at'));
  }
}
