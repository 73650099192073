<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" >
        <h2>Rental Editor</h2>
        <span *ngIf="this.rental.locked">
            <mat-icon color="primary">lock_outline</mat-icon>
        </span>
    </mat-toolbar> 
    <mat-progress-bar mode="indeterminate" [fxShow]="loading && !updating"></mat-progress-bar> 

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap=20px>
            <mat-form-field fxFlex="75">
                <mat-label>Select Item</mat-label>
                <input type="text"
                    [(ngModel)]="rentalSearchText"
                    name="rentalSearchTextField"
                    #rentalSearchTextField="ngModel"
                    [matAutocomplete]="searchTextAuto"
                    (ngModelChange)="onQueryRentals()"
                    required
                    matInput
                    [disabled]="request.locked || request.lock_level >= 1 || loading || !!this.rental.rental_group_id">
                <mat-autocomplete #searchTextAuto="matAutocomplete"
                    (optionSelected)="onRentalResourceSelected($event.option.value)"
                    [displayWith]="displaySelectedRental">
                    <mat-option *ngFor="let rental of promisedRentals | async" [value]="rental">
                        <app-request-search-option 
                            [text]="rental.name"
                            [thumbnail]="rental.thumbnail"
                            [icon]="rental.icon">
                    </app-request-search-option>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="rentalSearchTextField.hasError('required')">Rental item is required.</mat-error>
            </mat-form-field>

            <ng-container *ngIf="isGroup; then rentalGroupQuantityField; else rentalQuantityField">
            </ng-container>
            <ng-template #rentalGroupQuantityField>
                <mat-form-field fxFlex>
                    <mat-label>Quantity</mat-label>
                    <input type="number"
                        step="1"
                        [(ngModel)]="this.rentalGroup.quantity"
                        name="quantityField"
                        #quantityField="ngModel"
                        required
                        (ngModelChange)="this.onGroupQuantityChange()"
                        [minNum]="1"
                        matInput
                        [disabled]="request.locked || request.lock_level >= 1 || loading">
                        <mat-error *ngIf="quantityField.hasError('required')">Quantity is required.</mat-error>
                        <mat-error *ngIf="quantityField.hasError('minNum')">Quantity must be at least 1.</mat-error>
                </mat-form-field>
            </ng-template>
            <ng-template #rentalQuantityField>
                <mat-form-field fxFlex>
                    <mat-label>Quantity</mat-label>
                    <input type="number"
                        step="1"
                        [(ngModel)]="this.rental.quantity"
                        name="quantityField"
                        #quantityField="ngModel"
                        required
                        [minNum]="1"
                        matInput
                        [disabled]="request.locked || request.lock_level >= 1 || !!this.rental.rental_group_id || loading">
                        <mat-hint *ngIf="this.rental.planned_quantity">Planned: {{ this.rental.planned_quantity }}</mat-hint>
                        <mat-error *ngIf="quantityField.hasError('required')">Quantity is required.</mat-error>
                        <mat-error *ngIf="quantityField.hasError('minNum')">Quantity must be at least 1.</mat-error>
                </mat-form-field>
            </ng-template>
        </div>

        <ng-container *ngIf="isGroup; then rentalGroupFields; else rentalFields">
        </ng-container>

        <ng-template #rentalGroupFields>
            <div fxLayout="row" fxLayoutGap=20px *ngFor="let groupItem of this.rentalGroup.rentals">
                <app-rental-editor-group-item                        
                    [item]="groupItem"
                    [groupQuantity]="this.rentalGroup.quantity"
                    (onRentalGroupItemChanged)="this.setRentalGroupItem($event)">
                </app-rental-editor-group-item>
            </div>
        </ng-template>

        <ng-template #rentalFields>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Detail</mat-label>
                    <input type="text"
                        [(ngModel)]="this.rental.detail"
                        name="detailField"
                        #detailField="ngModel"
                        maxlength="255"
                        matInput
                        [disabled]="this.rental.locked || loading">
                    <mat-hint align="end">{{ this.rental.detail?.length || 0 }} / 255</mat-hint>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" fxFlex>
                    <p class="form-instruction">&nbsp;<br>&nbsp;</p>
                    <mat-form-field fxFlex="20">
                        <mat-label>Pricing</mat-label>
                        <mat-select [(ngModel)]="this.rental.pricing_type"
                            name="pricingTypeField"
                            #pricingTypeField="ngModel"
                            required
                            [disabled]="this.rental.locked || loading">
                            <mat-option value="rated">Rated</mat-option>
                            <mat-option value="fixed">Fixed</mat-option>
                            <mat-option value="flat">Flat</mat-option>
                        </mat-select>
                        <mat-error *ngIf="pricingTypeField.hasError('required')">Pricing type is required.</mat-error>
                    </mat-form-field>    
                </div>

                <div fxLayout="column" fxFlex>
                    <p class="form-instruction">
                        {{ this.rental.pricing_type === 'flat' ? '' : 'Daily ' }}Rate<br>
                        {{ this.rental.daily_rate | currencyFilter }}
                    </p>
                    <mat-form-field fxFlex="20">
                        <mat-label>Override</mat-label>
                        <input 
                            [(ngModel)]="this.rental.daily_rate_override"
                            (change)="this.onDailyRateChange()"
                            name="dailyRateOverrideField"
                            #dailyRateOverrideField="ngModel"
                            required
                            currencyInput
                            [minNum]="0"
                            [disabled]="this.rental.locked || loading"
                            matInput>
                        <mat-error *ngIf="dailyRateOverrideField.hasError('required')">Daily rate is required.</mat-error>
                        <mat-error *ngIf="dailyRateOverrideField.hasError('minNum')">Daily rate must be at least $ 0.00.</mat-error>
                    </mat-form-field>    
                </div>

                <div fxLayout="column" fxFlex [fxShow]="this.rental.pricing_type !== 'flat'">
                    <p class="form-instruction">Per Item Min <br>{{ this.rental.minimum_charge | currencyFilter }}</p>
                    <mat-form-field fxFlex="20">
                        <mat-label>Override</mat-label>
                        <input [(ngModel)]="this.rental.minimum_charge_override"
                            name="perItemMinField"
                            #perItemMinField="ngModel"
                            required
                            currencyInput
                            [minNum]="0"
                            [disabled]="this.rental.locked || loading"
                            matInput>
                        <mat-error *ngIf="perItemMinField.hasError('required')">Per item min is required.</mat-error>
                        <mat-error *ngIf="perItemMinField.hasError('minNum')">Per item min must be at least $ 0.00.</mat-error>
                    </mat-form-field>    
                </div>
            </div>

            <div fxLayout="column" class="fieldset" [fxShow]="this.rental.pricing_type == 'rated'">
                <div fxLayout="row" fxLayoutGap="15px">
                    <h3 class="mat-subheading-2">Discount Overrides</h3>
                </div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <mat-form-field fxFlex="30">
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="this.isPercentDiscount"
                            (selectionChange)="this.onIsPercentDiscountChange($event.value)"
                            name="chargeTypeField"
                            #isPercentDiscountField="ngModel"
                            [disabled]="this.rental.locked || loading"
                            required>
                            <mat-option [value]="true">Percent Discount</mat-option>
                            <mat-option [value]="false">Discounted Dollar Rate</mat-option>
                        </mat-select>
                        <mat-error *ngIf="isPercentDiscountField.hasError('required')">Type is required.</mat-error>
                    </mat-form-field>
                    <div fxFlex fxFlexAlign="center">
                        <p class="form-instruction">
                            Dollar values displayed approximate.
                        </p>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxLayout="column" fxFlex>
                        <p class="form-instruction" *ngIf="this.isPercentDiscount">Weekly<br>{{ this.rental.weekly_discount | percentFilter }} /day</p>
                        <p class="form-instruction" *ngIf="!this.isPercentDiscount">Weekly<br>{{ this.weeklyDiscount | currencyFilter }}</p>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <mat-form-field *ngIf="this.isPercentDiscount">
                                <mat-label>Override</mat-label>
                                <input type="text"
                                    [(ngModel)]="this.rental.weekly_discount_override"
                                    name="weeklyDiscountOverrideField"
                                    #weeklyDiscountOverrideField="ngModel"
                                    required
                                    percentInput
                                    [minNum]="0"
                                    [maxNum]="1"
                                    matInput
                                    [disabled]="this.rental.locked || loading">
                                <mat-error *ngIf="weeklyDiscountOverrideField.hasError('required')">Override is required.</mat-error>
                                <mat-error *ngIf="weeklyDiscountOverrideField.hasError('minNum')">Override must be at least 0.00 %.</mat-error>
                                <mat-error *ngIf="weeklyDiscountOverrideField.hasError('maxNum')">Override cannot exceed 100.00 %. </mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="!this.isPercentDiscount">
                                <mat-label>Override Dollar</mat-label>
                                <input type="text"
                                    [(ngModel)]="this.weeklyDiscountOverride"
                                    (change)="this.onWeeklyDiscountPriceChange()"
                                    name="weeklyDiscountOverrideField"
                                    #weeklyDiscountOverrideField="ngModel"
                                    [disabled]="this.rental.locked || loading"
                                    currencyInput
                                    [minNum]="0"
                                    [maxNum]="this.rental.daily_rate_override"
                                    required
                                    matInput>
                                <mat-error *ngIf="weeklyDiscountOverrideField.hasError('required')">Weekly discount is required.</mat-error>
                                <mat-error *ngIf="weeklyDiscountOverrideField.hasError('minNum')">Weekly discount must be at least $0.00.</mat-error>
                                <mat-error *ngIf="weeklyDiscountOverrideField.hasError('maxNum')">Weekly discount cannot exceed price of rental or rental override.</mat-error>
                            </mat-form-field>
                            <p class="form-instruction" fxFlexAlign="center">
                                {{ calculateRate(this.rental.weekly_discount_override, 1) | currencyFilter }} /day<br>
                                {{ calculateRate(this.rental.weekly_discount_override, this.weekThreshold) | currencyFilter }} /week ({{ this.weekThreshold }} days)
                            </p>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex>
                        <p class="form-instruction" *ngIf="this.isPercentDiscount">Monthly<br>{{ this.rental.monthly_discount | percentFilter }} /day</p>
                        <p class="form-instruction" *ngIf="!this.isPercentDiscount">Monthly<br>{{ this.monthlyDiscount | currencyFilter }}</p>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <mat-form-field *ngIf="this.isPercentDiscount">
                                <mat-label>Override</mat-label>
                                <input type="text"
                                    [(ngModel)]="this.rental.monthly_discount_override"
                                    name="monthlyDiscountOverrideField"
                                    #monthlyDiscountOverrideField="ngModel"
                                    required
                                    percentInput
                                    [minNum]="0"
                                    [maxNum]="1"
                                    matInput
                                    [disabled]="this.rental.locked || loading">
                                <mat-error *ngIf="monthlyDiscountOverrideField.hasError('required')">Override is required.</mat-error>
                                <mat-error *ngIf="monthlyDiscountOverrideField.hasError('minNum')">Override must be at least 0.00 %.</mat-error>
                                <mat-error *ngIf="monthlyDiscountOverrideField.hasError('maxNum')">Override cannot exceed 100.00 %. </mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="!this.isPercentDiscount">
                                <mat-label>Override Dollar</mat-label>
                                <input type="text"
                                    [(ngModel)]="this.monthlyDiscountOverride"
                                    (change)="this.onMonthlyDiscountPriceChange()"
                                    name="monthlyDiscountOverrideField"
                                    #monthlyDiscountOverrideField="ngModel"
                                    [disabled]="this.rental.locked || loading"
                                    currencyInput
                                    [minNum]="0"
                                    [maxNum]="this.rental.daily_rate_override"
                                    required
                                    matInput>
                                <mat-error *ngIf="monthlyDiscountOverrideField.hasError('required')">Monthly discount is required.</mat-error>
                                <mat-error *ngIf="monthlyDiscountOverrideField.hasError('minNum')">Monthly discount must be at least $0.00.</mat-error>
                                <mat-error *ngIf="monthlyDiscountOverrideField.hasError('maxNum')">Monthly discount cannot exceed price of rental or rental override.</mat-error>
                            </mat-form-field>
                            <p class="form-instruction" fxFlexAlign="center">
                                {{ calculateRate(this.rental.monthly_discount_override, 1) | currencyFilter }} /day<br>
                                {{ calculateRate(this.rental.monthly_discount_override, this.monthThreshold) | currencyFilter }} /month ({{ this.monthThreshold }} days)
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" class="fieldset">
                <h3 class="mat-subheading-2">Replacement Cost</h3>

                <div fxLayout="row">
                    <div fxFlex="33" class="form-static-txt">
                        <span class="form-static-txt__label">Retail Cost</span>
                        <span class="form-static-txt__content">{{ this.rental.retail_cost | currencyFilter }}</span>
                    </div>
                    <div fxFlex="33" class="form-static-txt">
                        <span class="form-static-txt__label">Discount</span>
                        <span class="form-static-txt__content">{{ this.rental.replacement_discount_override | percentFilter }}</span>
                    </div>
                    <div fxFlex="33" class="form-static-txt">
                        <span class="form-static-txt__label">Discount</span>
                        <span class="form-static-txt__content">{{ this.rental.retail_cost * (1 - this.rental.replacement_discount) | currencyFilter }}</span>
                    </div>
                </div>
                
                <div fxLayout="row">
                    <div fxFlex="33" class="form-static-txt">
                        <span class="form-static-txt__label">Rental Offset</span>
                        <span class="form-static-txt__content">{{ this.rental.replacement_rental_offset_override | percentFilter }}</span>
                    </div>
                    <div class="form-instruction" fxFlex="33">
                        The rental offset uses a percentage of the total rental cost and subtracts it from the&nbsp;replacement&nbsp;cost.
                    </div>
                </div>
            </div>
        </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <button type="button" mat-icon-button (click)="remove()" *ngIf="canRemove()" [disabled]="loading || request.lock_level >= 1 || !!this.rental.rental_group_id">
            <mat-icon color="primary">delete</mat-icon>
        </button>
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="updating" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button [mat-dialog-close]>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="loading">
            Save
        </button>
    </mat-dialog-actions>
</form>