import { HttpClient } from '@angular/common/http';
import { HttpResponse } from './http-response';
import { Helpers as $helpers } from '@beaconlite/services/helpers.service';

export type ResponseType = 'arraybuffer' | 'blob' | 'json' | 'text' | null; 
export type ResponseHandler = (response: HttpResponse) => void;

export class HttpRequest {

  private _method: string = '';

  private _url: {base: string, path: string} = {
    base: '',
    path: ''
  };

  private _options: { 
    responseType: ResponseType, 
    params: {}, 
    headers: {}, 
    body: {}
  };
  
  private _promises: Array<Promise<any>> = [];
  
  private _handlers: {success: Array<ResponseHandler>, failure: Array<ResponseHandler>} = {
    success: [],
    failure: []
  };

  constructor(private http: HttpClient, options: {})
  {
    this._options = {
      responseType: null,
      params: {},
      headers: {},
      body: {},
      ...options
    };
  }

  /**
   * Set the HTTP method to be used on the request.
   *
   * @param {string} method
   * @returns {this}
   */
  method(method: string): this
  {
    this._method = method;

    return this;
  }

  /**
   * Set the URL to be used on the request.
   *
   * @param {string} url
   * @returns {this}
   */
  url(url: string): this
  {
    this._url.base = url;
    this._url.path = '';

    return this;
  }

  /**
   * Set the URL base (domain) to be used on the request.
   *
   * @param {string} base
   * @returns {this}
   */
  base(base: string): this
  {
    this._url.base = base;

    // Remove trailing slashes for consistency
    this._url.base = this._url.base.replace(/\/+$/g, '');

    return this;
  }

  /**
   * Set the URL path to be used on the request.
   *
   * @param {string} path
   * @returns {this}
   */
  path(path: string): this
  {
    this._url.path = path;

    // Remove leading slashes for consistency
    this._url.path = this._url.path.replace(/^\/+/g, '');

    return this;
  }

  /**
   * Set a param on the request.
   *
   * @param {string} key
   * @param {any} value
   * @returns {this}
   */
  param(key: string, value: any): this
  {
    value = this.serializeArrayParam(value);

    this._options.params[key] = value || null;

    return this;
  }

  /**
   * Set params on the request.
   * The merge flag toggles between replacing and merging new parameters with existing parameters.
   *
   * @param {Object} params
   * @param {boolean} [merge]
   * @returns {this}
   */
  params(params: {}, merge?: boolean): this
  {

    for(const param in params)
    {
      params[param] = this.serializeArrayParam(params[param]);
    }

    this._options.params = merge === true
      ? { ...this._options.params, ...params }
      : params

    return this;
  }

  /**
   * Set the body on the request.
   * The merge flag toggles between replacing and merging new parameters with existing parameters.
   *
   * @param {Object} params
   * @param {boolean} [merge]
   * @returns {this}
   */
  data(data: {}, merge?: boolean): this
  {
    this._options.body = merge === true
      ? { ...this._options.body, ...data }
      : data;
    
    return this;
  }

  /**
   * Add a header to the request.
   *
   * @param {string} key
   * @param {string} value
   * @returns {this}
   */
  header(key: string, value: string): this
  {
    this._options.headers[key] = value;

    return this;
  }

  /**
   * Set the headers on the request.
   * The merge flag toggles between replacing and merging new headers with existing headers.
   *
   * @param {Object} headers
   * @param {boolean} [merge]
   * @returns {this}
   */
  headers(headers: {}, merge: boolean): this
  {
    this._options.headers = merge === true 
      ? { ...this._options.headers, ...headers }
      : headers;

    return this;
  }

  /**
   * Set responseType for the request.
   *
   * @param {ResponseType} value
   * @returns {this}
   */
  responseType(value: ResponseType): this
  {
    this._options.responseType = value;

    return this;
  }

  /**
   * Add a promise that the request should wait on before sending.
   *
   * @param {Promise} promise
   * @returns {this}
   */
  promise(promise: Promise<any>): this
  {
    this._promises.push(promise);

    return this;
  }

  /**
   * Add a handler function to be appended to the request promise's success chain ( $q.then() ).
   *
   * @param {Function} callback
   * @returns {this}
   */
  addSuccessHandler(callback: ResponseHandler): this
  {
    this._handlers.success.push(callback);

    return this;
  }

  /**
   * Add a handler function to be appended to the request promise's failure chain ( $q.catch() ).
   *
   * @param {Function} callback
   * @returns {this}
   */
  // TODO: this is failing in ApiClient because we're trying to pass an ajs service to it.
  addFailureHandler(callback: ResponseHandler): this
  {
    this._handlers.failure.push(callback);

    return this;
  }

  /**
   * Replace all URL variables with values matched from the request's URL parameters.
   *
   * @param {string} url
   * @param {string} path
   * @param {object} params
   * @returns {string}
   * @throws Throws an error if there are some unresolved URL variables.
   */
  private buildUrl(base: string, path: string, params: object = {}): string
  {
    let url = `${base}/${path}`
    let values = params;
    const regex = new RegExp('/:([a-zA-Z0-9-_]+)', 'gi');

    return url.replace(regex, (match, p1, offset, string) => {
      if (values.hasOwnProperty(p1))
      {
        let value = values[p1];

        delete values[p1];

        return `/${value}`;
      }
      else
      {
        console.error(`[HttpRequest] Unresolved URL variables: ${string}`)
      }
    });
  }

  /**
   * Converts an array to a comma seperated string.
   * 
   * @param {string|Array} value 
   * @returns {string}
   */
   private serializeArrayParam(value: string|Array<string>): string 
   {
     if( !$helpers.isArray(value) )
     {
       return (value as string);
     }
 
     return (value as Array<string>).join(',');
   }

  /**
   * Send the HTTP request and return the resulting promise.
   *
   * @returns {Promise}
   */
  async send(): Promise<HttpResponse>
  {
    const url = this.buildUrl(this._url.base, this._url.path, this._options.params);
 
    const handleResolution = resolution => {
      const promise = this.http.request(this._method, url, this._options)
        .toPromise()
        .then(response => new HttpResponse(response))
        .catch(response => Promise.reject(new HttpResponse(response)));

      // Append success handlers to promise
      this._handlers.success.forEach( (handler: ResponseHandler) => {
        promise.then(handler);
      });

      // Append failure handlers to promise
      this._handlers.failure.forEach( (handler: ResponseHandler) => {
        promise.catch(handler);
      });

      return promise;
    }

    const handleRejection = (response: HttpResponse) => Promise.reject(response);

    // Wait on all registered promises to resolve before sending the request
    try 
    {
      const resolution = await Promise.all(this._promises);
      return handleResolution(resolution);
    } 
    catch (rejection) 
    {
      return handleRejection(rejection);
    }
  }

  async get(): Promise<HttpResponse>
  {
    return this.method('GET').send();
  }

  async post(): Promise<HttpResponse>
  {
    return this.method('POST').send();
  }

  async put(): Promise<HttpResponse>
  {
    return this.method('PUT').send();
  }

  async patch(): Promise<HttpResponse>
  {
    return this.method('PATCH').send();
  }

  async delete(): Promise<HttpResponse>
  {
    return this.method('DELETE').send();
  }
}
