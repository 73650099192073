import { Ng2StateDeclaration } from '@uirouter/angular';
import { RentalIndexComponent } from './rental-index.component';


export const RentalIndexState: Ng2StateDeclaration = {
  name: 'protected.rentals',
  url: '/rentals',
  component: RentalIndexComponent,
  // TODO: make sure this is requiring auth properly
  data: { 
    pageTitle: 'Rental Index', 
    requiresAuth: true,
  },
}