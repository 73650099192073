import { Ng2StateDeclaration } from '@uirouter/angular';
import { InvoiceIndexComponent } from './invoice-index.component';

export const InvoiceIndexState: Ng2StateDeclaration = {
  name: 'protected.invoices',
  url: '/invoices',
  abstract: true,
  component: InvoiceIndexComponent,
  data: { 
    pageTitle: 'Invoices', 
    requiresAuth: true,
  },
}
