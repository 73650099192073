import * as moment from 'moment';
import { AfterViewChecked, AfterViewInit, Component, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Branch, Client, Department, Dispatch, WorkOrder } from '@beaconlite/models';
import { BranchCollection, ClientCollection, DispatchCollection } from '@beaconlite/models/collections';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';
import { AppData } from '@beaconlite/services/data/shared-data.departments';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/angular';
import { Helpers as $helpers } from '../../../../services/helpers.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Datum, GanttDispatchComponent } from './components/dispatch-gantt/dispatch-gantt.component';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { DispatchEditorService } from '@beaconlite/views/protected/work-orders/work-order-single/tab-dispatches/dispatch-editor/dispatch-editor.service';

@Component({
  selector: 'app-dispatch-schedule',
  templateUrl: './dispatch-schedule.component.html',
  styles: [':host{height:100%}'],
  styleUrls: ['./dispatch-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DispatchScheduleComponent implements OnInit, AfterViewInit, AfterViewChecked,OnChanges {

  @ViewChild('indexContainer') indexContainer: CdkVirtualScrollViewport
  @ViewChild('summaryContainer') summaryContainer: CdkVirtualScrollViewport
  @ViewChild(GanttDispatchComponent) graphComponent: GanttDispatchComponent;

  readonly SCHEDULE_VIEW_GANTT   = 'gantt';
  readonly SCHEDULE_VIEW_SUMMARY = 'summary';

  scheduleView = this.SCHEDULE_VIEW_GANTT;
  loading = false;
  isOnIndex = false;
  collection = new DispatchCollection();
  dispatchSource = new CollectionDataSource(this.collection);

  selectedModelId: string = this.$stateGlobals.params.modelId;
  
  branchSearchText: string;
  clientSearchText: string;
  
  keyword: string;
  searchAll = false;
  departments: Department[] = [];
  selectedBranch: Branch;
  selectedClient: Client;
  selectedDepartment: string;
  selectedState: string;
  selectedType: string;
  selectedDispatch: string;
  tag: string;
  selectedStartDate;
  selectedEndDate;
  orderBy = 'date';
  order = 'desc';

  promisedBranches: Promise<Branch[]>;
  promisedClients: Promise<Client[]>;

  indexItemSize = 70;
  summaryItemSize = 236;

  private _isSyncScrolling = false;

  constructor(
    protected appData: AppData,
    protected renderer: Renderer2,
    protected $stateGlobals: UIRouterGlobals,
    protected stateService: StateService,
    protected transitionService: TransitionService,
    private dispatchEditorService: DispatchEditorService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.departments = await this.appData.departments.getAll();
    this._sortItems();

    if ( this.stateService.is( 'protected.dispatch-schedule' ) )
    {
      this.isOnIndex = true;
    }

    this.transitionService.onSuccess( { to: 'protected.dispatch-schedule' }, () =>
    {
      this.isOnIndex = true;
    } );

    this.transitionService.onSuccess( { from: 'protected.dispatch-schedule' }, () =>
    {
      this.isOnIndex = false;
    } );

    this.onSearch();
  }

  ngAfterViewInit() 
  {
      this._synchronizeScrolling();  
  }

  ngAfterViewChecked(): void 
  {
    // this.isOnIndex is initially false, and is set to true after awaiting the departments 
    // promise in OnInit. #summaryContainer virtual scroll viewport is initially hidden due to
    // [fxShow]="isOnIndex" == false.  Because of this, the initial viewport height the virtual scroll 
    // measures is 0. We need to manually re-measure the viewport size after view has been checked.

    if (this.summaryContainer.getViewportSize() > 0) { return }

    this.summaryContainer.checkViewportSize()
  }
  
  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.isOnIndex.currentValue)
    {
      this.graphComponent.onRescale();
    }
  }

  _synchronizeScrolling()
  {
    this.renderer.listen( this.indexContainer.elementRef.nativeElement, 'scroll', ( event ) =>
    {
      if ( this._isSyncScrolling )
      {
        this._isSyncScrolling = false;
        return;
      }

      this._synchronizeGanttScrolling(event);
      this._synchronizeSummaryScrolling(event);
      
      this._isSyncScrolling = true;
    } );
  }

  _synchronizeGanttScrolling(event: any)
  {
    const graphContainer = this.graphComponent.graphContainer.nativeElement;

    if ( graphContainer.scrollTop == event.target.scrollTop ) { return }

    graphContainer.scrollTop = event.target.scrollTop
  }

  _synchronizeSummaryScrolling(event: any)
  {
    const summaryContainer = this.summaryContainer.elementRef.nativeElement;

    if ( summaryContainer.scrollTop == event.target.scrollTop ) { return }

    summaryContainer.scrollTop = event.target.scrollTop * (this.summaryItemSize / this.indexItemSize);
  }

  onScheduleScroll = ( event: any ) =>
  {
    if ( this._isSyncScrolling )
    {
      this._isSyncScrolling = false;
      return;
    }

    const indexContainer = this.indexContainer.elementRef.nativeElement;

    if ( indexContainer.scrollTop == event.target.scrollTop ) { return }

    switch ( this.scheduleView )
    {
      case this.SCHEDULE_VIEW_GANTT:
        indexContainer.scrollTop = event.target.scrollTop;
      break;
      case this.SCHEDULE_VIEW_SUMMARY:
        indexContainer.scrollTop = event.target.scrollTop / (this.summaryItemSize / this.indexItemSize)
      break;
    }

    this._isSyncScrolling = true;
  }

  onScheduleViewChanged()
  {
    const indexContainer = this.indexContainer.elementRef.nativeElement;
    const fakeEvent = { target: { scrollTop: indexContainer.scrollTop } };

    setTimeout(async () => {

      switch ( this.scheduleView )
      {
        case this.SCHEDULE_VIEW_GANTT:
          await this.graphComponent.onRescale()
          this._synchronizeGanttScrolling(fakeEvent);
        break;
        case this.SCHEDULE_VIEW_SUMMARY:
          this._synchronizeSummaryScrolling(fakeEvent);
        break;
      }

    })
  }

  onSearch()
  { 
    const params: any = {
      search_all: this.searchAll,
      order:      this.order, 
      order_by:   this.orderBy,
      // TODO: Jira BL-566. Update this when API is updated. 
      with:       ['employees', 'client', 'work_order', 'services', 'rental_requests'],
    }

    if (this.keyword)
    {
        params.keyword = this.keyword;
    }

    if (this.selectedBranch)
    {
        params.branch_id = this.selectedBranch.id;
    }

    if (this.selectedClient)
    {
        params.client_id = this.selectedClient.id;
    }

    if (this.selectedDepartment)
    {
        params.department_ids = [this.selectedDepartment]
    }

    if (this.tag)
    {
        params.tag = this.tag;
    }

    if (this.selectedState)
    {
        params.state = this.selectedState;
    }

    if (this.selectedType)
    {
        params.type = this.selectedType;
    }

    // if (this.selectedDispatch)
    // {
    //     params.dispatch = this.selectedDispatch;
    // }

    if (this.selectedStartDate)
    {
        params.date_start = $helpers.dateToTimestamp(this.selectedStartDate);
    }

    if (this.selectedEndDate)
    {
        params.date_end = moment(this.selectedEndDate).endOf('day').unix();
    }

    this.collection.search(params);
  }

  getWOSerial(dispatch: Dispatch): string
  {
    return WorkOrder.formatSerialId(dispatch.work_order.serial_id)
  }

  onQueryClients(): void
  {
    this.promisedClients = (new ClientCollection()).all({name:this.clientSearchText})
  }

  onQueryBranches(): void
  {
    this.promisedBranches = (new BranchCollection()).all({name:this.branchSearchText})
  }

  clearSearchText(selection: Branch|Client, field: 'branch'|'client')
  {
    if (!! selection) return;

    switch(field)
    {
      case 'client':
        this.clearClientSelection();
        break;
      case 'branch':
        this.clearBranchSelection();
        break;
    }
  }

  clearBranchSelection()
  {
    this.branchSearchText = '';
    this.selectedBranch = null;
  }

  clearClientSelection()
  {
    this.clientSearchText = '';
    this.selectedClient = null;
  }

  onBranchOptionSelected(branch: Branch)
  {
    this.selectedBranch = branch;
  }

  onClientOptionSelected(client: Client)
  {
    this.selectedClient = client;
  }

  displaySelectedBranch = (branch?: Branch): string|null => 
  {
    return branch?.name || null;
  }

  displaySelectedClient = (client?: Client): string|null => 
  {
    return client?.name || null;
  }

  onView(dispatch: Dispatch)
  { 
    this.stateService.go('protected.dispatch-schedule.single', { modelId: dispatch.id })
  }

  onGanttItemClick = async ( event: any, datum: Datum ) => 
  {
      const dispatchPromise = Dispatch.get( datum.id )
      const workOrderPromise = WorkOrder.get( datum.work_order_id )

      return Promise.all( [ dispatchPromise, workOrderPromise ] )
          .then( async ( [ dispatch, workOrder ] ) =>
          {

              var locals = {
                  workOrder: workOrder,
                  original: dispatch,
                  onDispatchRemove: async () => this.onSearch(),
              };
              // TODO: on update, it does not update the display on the Gantt chart or summary when time is modified.\
              const updatedDispatch = await this.dispatchEditorService.open( locals )
              //Update the local
              dispatch.map( updatedDispatch.flush() )
              // TODO: figure out if this client copy was required
              // dispatch.client = updatedDispatch.client
          } );
  }  

  protected _sortItems()
  {
    this.departments?.sort( orderBy( 'name' ) );
  }
}
