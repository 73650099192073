import { dto } from './mixins/Dto.decorators';
import { Variant } from './Variant.model';
import { VariantServiceProperty } from './VariantServiceProperty.model';
import { VariantServicePropertyDefinition } from './VariantServicePropertyDefinition.model';

export class VariantService extends Variant {

    constructor(attributes: object = {}) 
    {
        super();
        this.init(attributes);
    }

    @dto() regular_rate: number = 0;
    @dto() overtime_rate: number = 0;
    @dto() default_pricing_type: string = '';
    // Can't pass constructor to dto() due to circular reference. Constructor should be ServiceDefinition.
    @dto() service_definition: any = null;
    @dto(VariantServiceProperty) properties: VariantServiceProperty[] = [];

    addProps(definitions: VariantServicePropertyDefinition[]) {
        this._addProps(VariantServiceProperty, definitions);
    }

    async save(): Promise<VariantService>
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateVariantService(this.id, this.flush())
            : await this.routeMap.createVariantService(this.flush())

        return this.map( response.data() );
    }

    async discard(): Promise<void>
    {
        await this.routeMap.discardVariantService(this.id);
    }
}
