<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<mat-card class="medium-card">
    <div class="title-row" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
            <span class="mat-headline">{{ this.title }}</span>
            <span class="mat-caption client-state-label" *ngIf="this.client.suspended">
                <span>Suspended</span>
            </span>
            <span class="mat-caption client-state-label" *ngIf="this.client.discarded">
                <span>Discarded</span>
            </span>
        </div>
        <div fxLayout="column">
            <mat-slide-toggle fxFlexAlign="center"
                [(ngModel)]="this.client.suspended" 
                name="suspendedField"
                [disabled]="this.client.discarded">
                <span>Suspended</span>
            </mat-slide-toggle>
            <div fxLayout="row" *ngIf="this.client.hasAccountingExportable()" fxLayoutAlign="end center">
                <span fxLayout="row" fxLayoutAlign=" center">
                    <mat-icon *ngIf="!this.client.getAccountingExportable().invalidated_at" color="primary">sync</mat-icon>
                    <mat-icon *ngIf="this.client.getAccountingExportable().invalidated_at" color="primary">sync_problem</mat-icon>
                    <span class="mat-caption">Sycned</span>
                </span>
            </div>
        </div>
    </div>

    <form #editorForm="ngForm" (ngSubmit)="this.save()" novalidate>
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Name</mat-label>
                <input type="text" 
                    [(ngModel)]="this.client.name"
                    name="nameField"
                    #nameField="ngModel"
                    required
                    [disabled]="this.locked"
                    maxlength="255"
                    matInput>
                <mat-hint align="end">{{ this.client.name?.length || 0 }} / 255</mat-hint>
                <mat-error *ngIf="nameField.hasError('required')">Name is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
                <mat-label>Address 1</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.address_1"
                    name="address1Field"
                    #address1Field="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="address1Field.hasError('required')">Address 1 is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>Address 2</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.address_2"
                    name="address2Field"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
                <mat-label>City</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.city"
                    name="cityField"
                    #cityField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="cityField.hasError('required')">City is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>Province</mat-label>
                <mat-select [(ngModel)]="this.client.region"
                    name="regionField"
                    #regionField="ngModel"
                    [disabled]="this.locked"
                    required>
                    <mat-optgroup label="Provinces">
                        <mat-option value="AB">Alberta</mat-option>
                        <mat-option value="BC">British Columbia</mat-option>
                        <mat-option value="MB">Manitoba</mat-option>
                        <mat-option value="NL">Newfoundland and Labrador</mat-option>
                        <mat-option value="NB">New Brunswick</mat-option>
                        <mat-option value="NS">Nova Scotia</mat-option>
                        <mat-option value="ON">Ontario</mat-option>
                        <mat-option value="PE">Prince Edward Island</mat-option>
                        <mat-option value="QC">Quebec</mat-option>
                        <mat-option value="SK">Saskatchewan</mat-option>
                    </mat-optgroup>
                    <mat-optgroup label="Territories">
                        <mat-option value="NT">Northwest Territories</mat-option>
                        <mat-option value="NU">Nunavut</mat-option>
                        <mat-option value="YT">Yukon</mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="regionField.hasError('required')">Region is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
                <mat-label>Country</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.country"
                    name="countryField"
                    #countryField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="countryField.hasError('required')">Country is required.</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>Postal Code</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.postal_code"
                    name="postalCodeField"
                    #postalCodeField="ngModel"
                    [disabled]="this.locked"
                    required
                    matInput>
                <mat-error *ngIf="postalCodeField.hasError('required')">Postal code is required.</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="33">
                <mat-label>Email</mat-label>
                <input type="email"
                    [(ngModel)]="this.client.email"
                    name="emailField"
                    #emailField="ngModel"
                    [disabled]="this.locked"
                    email
                    matInput>
                <mat-error *ngIf="emailField.hasError('email')">Email is invalid.</mat-error>
            </mat-form-field>

            <!-- TODO: valid phone # validation -->
            <mat-form-field fxFlex="33">
                <mat-label>Phone</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.phone"
                    name="phoneField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>

            <mat-form-field fxFlex="33">
                <mat-label>Fax</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.fax"
                    name="faxField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Notice</mat-label>
                <textarea [(ngModel)]="this.client.notice" 
                    name="noticeField"
                    [disabled]="this.locked"
                    rows="3"
                    matInput>
                </textarea>
            </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" layoutPadding>
                <div class="mat-title">Default Tax</div>
            </div>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex="25">
                <mat-label>Tax</mat-label>
                <mat-select [(ngModel)]="this.client.default_tax"
                    name="defaultTaxField"
                    [disabled]="this.locked">
                    <mat-option value="">Default</mat-option>
                    <mat-option *ngFor="let tax of this.taxes" [value]="tax.tag">
                        {{ tax.tag }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>                

        <mat-divider></mat-divider>


        <div fxLayout="row">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" layoutPadding>
                <div class="mat-title">Item Prefixes</div>
            </div>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex="25">
                <mat-label>Rental Item Prefix</mat-label>
                <input type="text"
                    [(ngModel)]="this.client.prefix"
                    name="clientField"
                    [disabled]="this.locked"
                    matInput>
            </mat-form-field>
        </div>                

        <mat-divider></mat-divider>

        <div fxLayout="row" layoutPadding>
            <div fxLayout="column" fxFlex>
                <div class="mat-title">Business Hours Overrides (Overtime)</div>
                <div class="mat-caption">Regular {{ this.businessStart | timeOfDayFilter }} - {{ this.businessEnd | timeOfDayFilter }}</div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
                <mat-label>Start Time</mat-label>
                <input type="time"
                    [(ngModel)]="this.client._dates.overtime_end.time"
                    name="startTimeField"
                    matInput>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>End Time</mat-label>
                <input type="time"
                    [(ngModel)]="this.client._dates.overtime_start.time"
                    name="endTimeField"
                    matInput>
            </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <div class="action-row" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-icon-button
                *ngIf="this.client.discarded"
                [disabled]="this.loading"
                (click)="this.restore()"
                color="primary">
                <mat-icon>restore</mat-icon>
            </button>
            <button mat-icon-button
                *ngIf="!this.client.discarded && this.client.exists()"
                [disabled]="this.loading || !this.client.exists()"
                (click)="this.discard()"
                color="primary">
                <mat-icon>delete</mat-icon>
            </button>
            <span fxFlex></span>

            <button mat-raised-button color="accent" type="submit">
                Save
            </button>
        </div>
    </form>
</mat-card>

<app-rental-overrides modelLabel="Client"
    [overridableModel]="this.client" 
    [onRentalUpdate]="this.onRentalUpdate"
    [onRentalRemove]="this.onRentalRemove">
</app-rental-overrides>

<app-service-overrides modelLabel="Client"
    [overridableModel]="this.client"
    [onServiceUpdate]="this.onServiceUpdate"
    [onServiceRemove]="this.onServiceRemove">
</app-service-overrides>

<!-- CLIENT ACCOUNTS -->

<mat-card class="medium-card client-accounts-card">
    <div class="client-accounts-card-inner" fxFlex fxLayout="row wrap">
        <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="mat-title">Client Accounts</div>
                <div class="mat-caption">{{ this.client.accounts.length }} Accounts</div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
            <div [fxHide]="this.client.exists()">Client must first be created before adding accounts.</div>
            <div [fxShow]="this.client.suspended">Cannot add accounts to suspended clients.</div>
            <div [fxShow]="this.client.discarded">Cannot add accounts to discarded clients.</div>
        </div>

        <mat-table class="mat-selectable-table"
            fxFlex="100"
            [dataSource]="this.client.accounts" 
            [fxShow]="this.client.hasAccounts()">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                <mat-cell *matCellDef="let account">{{ account.fullname }}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                <mat-cell *matCellDef="let account">{{ account.person.email }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="clientAccountsTableColumns"></mat-header-row>
            <mat-row *matRowDef="let account; columns: clientAccountsTableColumns" 
                (click)="onEditAccount(account)">
            </mat-row>
        </mat-table>

        <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end end">
            <button mat-mini-fab
                (click)="this.onEditAccount()"
                [disabled]="!this.canAddAccounts()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</mat-card>
