<mat-card class="large-card">
    <mat-card-title>Settings</mat-card-title>

    <mat-divider></mat-divider>

    <mat-card-content>
        <!-- ACCOUNTING SETTINGS -->
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">Accounting</span>
            <button mat-icon-button color="primary" (click)="this.onEditAccountingSettings()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div fxLayout="column">
            <span class="mat-subheading-1">Close the books</span>
            <span class="mat-caption"
                *ngIf="this.accounting_settings.period_lock_enabled">
                {{ this.accounting_settings.period_lock_date | dateFilter:'dateMedium' }}
            </span>
            <span class="mat-caption"
                *ngIf="!this.accounting_settings.period_lock_enabled">
                Disabled
            </span>
        </div>

        <mat-divider></mat-divider>

        <!-- DISPATCH SETTINGS -->
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">Dispatch</span>
            <button mat-icon-button color="primary" (click)="this.onEditDispatchSettings()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div fxLayout="column">
            <span class="mat-subheading-1">Projected duration</span>
            <span class="mat-caption">
                {{ this.prefs.data?.dispatch?.projected_duration | timeFilter }}
            </span>
            <span class="mat-subheading-1">Week starts on</span>
            <span class="mat-caption">
                {{ this.formattedDayOfWeek( this.prefs.data?.dispatch?.week_start ) }}
            </span>
        </div>
        <mat-divider></mat-divider>

        <!-- COMPANY SETTINGS -->
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">Company</span>
            <button mat-icon-button color="primary" (click)="this.onEditCompanySettings()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="25">
                    <span class="mat-subheading-1">Business Hours</span>
                    <span class="mat-caption">
                        {{ this.prefs.data?.company?.business_hours.start | timeOfDayFilter }} - {{ this.prefs.data?.company?.business_hours.end | timeOfDayFilter }}
                    </span>    
                </div>
                <div fxLayout="column">
                    <span class="mat-subheading-1">Rental Request Minimum</span>
                    <span class="mat-caption">
                        {{ this.prefs.data?.company?.rental_request_minimum | currencyFilter }}
                    </span>
                </div>
            </div>
            <span class="mat-subheading-1">SMS</span>
            <span class="mat-caption">
                {{ this.prefs.data?.company?.sms_enabled ? 'Enabled' : 'Disabled' }}
            </span>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">Invoice Settings</span>
            <button mat-icon-button color="primary" (click)="this.onEditInvoiceSettings()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="25">
                    <span class="mat-subheading-1">Invoice Grace Period</span>
                    <span class="mat-caption">
                        {{ this.prefs.data?.invoice?.grace_period }}
                    </span>    
                </div>
                <div fxLayout="column" fxFlex="25">
                    <span class="mat-subheading-1">Invoice Interval</span>
                    <span class="mat-caption">
                        {{ this.prefs.data?.invoice?.invoice_interval }}
                    </span>    
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-title">Discount Settings</span>
            <button mat-icon-button color="primary" (click)="this.onEditDiscountSettings()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="25">
                    <span class="mat-subheading-1">Week Length</span>
                    <span class="mat-caption">
                        {{ this.prefs.data?.pricing?.week_threshold }}
                    </span>    
                </div>
                <div fxLayout="column" fxFlex="25">
                    <span class="mat-subheading-1">Month Length</span>
                    <span class="mat-caption">
                        {{ this.prefs.data?.pricing?.month_threshold }}
                    </span>    
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- DISPATCH REGIONS -->
<mat-card class="large-card" fxLayout="row wrap">
    <mat-card-title fxFlex="noshrink">Dispatch Regions</mat-card-title>

    <div fxLayout="row" fxFlex="100" [fxShow]="!!this.dispatchRegions.length">
        <mat-divider></mat-divider>
    </div>

    <mat-card-content fxFlex>
        <mat-table class="mat-selectable-table" 
        #dispatchRegionsTable
        [dataSource]="this.dispatchRegions"
        [fxShow]="!!this.dispatchRegions.length">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                <mat-cell *matCellDef="let region">{{ region.name }}</mat-cell>
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="dispatchRegionTableColumns"></mat-header-row>
            <mat-row *matRowDef="let region; columns: dispatchRegionTableColumns" (click)="onEditDispatchRegion(region)"></mat-row> 
        </mat-table>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-mini-fab 
                (click)="onAddDispatchRegion()"
                color="secondary">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card-content>
</mat-card>


<div class="mat-caption settings__version">v{{ this.appVersion }}</div>
