import { BaseModel } from "./Base.model";
import { dto } from './mixins/Dto.decorators'
import { date } from './mixins/Date.decorators'
import { RentalGroup } from "./RentalGroup.model";
import { Rental } from "./Rental.model";
import IRentalReturn from "./contracts/IRentalReturn.interface";
import { ReturnItem } from "./contracts/ReturnItem.interface";

export class RentalReturn extends BaseModel implements IRentalReturn, ReturnItem {

    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id?: string =  null;
    @dto() rental_id?: string = null;
    @dto() locked?: boolean = false;
    @dto() invoiced?: boolean = false;
    @dto() invoice_id?: string = null;
    @dto() lost?: boolean = false;
    @dto() quantity?: number = 0;
    @dto() @date returned_at?: number = null;
    @dto() invalid?: boolean = null;
    @dto() position?: number = 0;

    @dto() rate_adjustment?: any = null;
    @dto() minimum_adjustment?: any = null;

    rental: Rental = null;

    isUngrouped(): boolean {
        return !(this.rental.rental_group_id || this.rental.rental_group_tmp_id);
    }
}
