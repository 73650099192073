import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dispatch, Notification } from '@beaconlite/models';
import { SmsNotificationsData } from './sms-notifications-data.interface';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { MatChipInputEvent } from '@angular/material/chips';
import { SnackbarNotificationService } from '@beaconlite/services/notification/snackbar-notification.service';

@Component({
  selector: 'app-sms-notifications',
  templateUrl: './sms-notifications.component.html',
  styleUrls: ['./sms-notifications.component.scss']
})
export class SmsNotificationsComponent implements OnInit {

  loading = false;
  sending = false;

  originalSendableModel = this.data.originalSendableModel;
  originalMessage = this.data.originalMessage;
  originalTo = this.data.originalTo;
  onUpdate = this.data.onSmsNotificationUpdate;

  selectedNotification = null;
  message: string = null;
  to: string[] = [];

  recipientsTableColumns = ['name', 'number', 'received', 'confirmed'];

  // TODO: create a sendable model type or interface.
  sendableModel: Dispatch;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: SmsNotificationsData,
    public dialogRef: MatDialogRef<SmsNotificationsComponent>,
    protected snackbarNotification: SnackbarNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.sendableModel = this.originalSendableModel.copy();

    await this.sendableModel.getNotifications(this.sendableModel.id);

    this._sortItems();

    this.sendableModel.hasNotifications()
      ? this.selectNotification(this.sendableModel.latest_notification)
      : this.newNotification();
  }

  async onSend(): Promise<void>
  {
    this.sending = true;

    const smsData = {
        to: this.to,
        message: this.message,
    }

    try
    {
      await this.sendableModel.notify(smsData);
      this.snackbarNotification.saved('Sent');
      await this.onUpdate();
      this.dialogRef.close();
    }
    finally
    {
      this.sending = false;
    }
  }

  newNotification()
  {
      this.selectedNotification = null
      this.message = this.originalMessage
      this.to = this.originalTo
  }

  duplicateNotification()
  {
      this.message = this.selectedNotification.message 
      this.to = this.selectedNotification.to    
      this.selectedNotification = null
  }
  
  selectNotification(notification: Notification)
  {
      this.selectedNotification = notification
      this.message = notification.message 
      this.to = notification.to 
  }

  getEmployeeName(recipient)
  {
      const employee = this.sendableModel.employees.find( employee => employee.person.phone == recipient.phone)
      return employee ? employee.fullname : 'N/A'
  }

  getErrorCodeLabel(recipient): string
  {
    return `Error: ${recipient.error_code}`;
  }

  messageCharacterCounter()
  {
      const charsPerPage = 140

      if (! this.message) return `${charsPerPage} / 1`

      const messageLength = this.message.length
      const pages = Math.ceil(messageLength / charsPerPage) 
      const charsRemaining = charsPerPage - Math.abs(messageLength % charsPerPage)

      return `${charsRemaining !== charsPerPage ? charsRemaining : 0 } / ${pages}`
  }

  addChip(toArray: string[], event: MatChipInputEvent): void
  {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim())
    {
      toArray.push(value);
    }

    if (input)
    {
      input.value = ''
    }
  }
  
  removeChip(toArray: string[] ,phone: string)
  {
    const index = toArray.indexOf(phone)

    if (index < 0) return;

    toArray.splice(index, 1);
  }

  protected _sortItems(): void
  {
    this.sendableModel.notifications.sort(orderBy('sent_at'));
  }
}
