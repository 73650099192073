import { Ng2StateDeclaration } from '@uirouter/angular';
import { InvoiceScheduleComponent } from './invoice-schedule.component';


export const InvoiceScheduleState: Ng2StateDeclaration = {
  name: 'protected.invoices.schedule',
  url: '/schedule',
  component: InvoiceScheduleComponent,
  data: { 
    pageTitle: 'Invoice Scheduling', 
    requiresAuth: true,
  },
}