import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Charge } from '@beaconlite/models';

@Component({
  selector: 'app-charges-table',
  templateUrl: './charges-table.component.html',
})
export class ChargesTableComponent implements OnInit {

  @Input() charges: Charge[];
  @Output() chargeSelected: EventEmitter<Charge> = new EventEmitter<Charge>();

  chargesTableColumns = ['type', 'label', 'description', 'value'];

  constructor() { }

  ngOnInit(): void { }

  onChargeSelected(charge: Charge)
  {
    this.chargeSelected.emit(charge);
  }
}
