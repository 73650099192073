import type { Constructor } from '@beaconlite/types';

export interface ModelRelations {
    [index: string]: Constructor<Object>
}

export interface DtoMap {
    attributes: {}, 
    relations: ModelRelations 
}

export interface HasDto {
    _dto: DtoMap;
}

export function dto(constructor?: Constructor<Object>) {    

    function decorator(target: HasDto, property: string): void {

        if ( !target._dto )
        {
            target._dto = { 
                attributes: {}, 
                relations: {} 
            };
        }

        if ( !target.hasOwnProperty("_dto") )
        {
            const attributes = Object.assign({}, target._dto.attributes);
            const relations = Object.assign({}, target._dto.relations);

            target._dto = { 
                attributes,
                relations 
            };
        }
        
        if ( constructor === undefined ) {
            target._dto.attributes[property] = true
        }
        else {
            target._dto.relations[property] = constructor
        }
        
    }

    return decorator;
}