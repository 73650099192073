import { AppInjector } from "../../services/app-injector.service";
import { HttpResponse } from "../../services/network/http-response";
import { RouteMap } from "../../services/network/route-map.service";
import type { Constructor } from '@beaconlite/types';
import { Paginator, Page } from "./Paginator.service";

export interface PaginationOptions {
    perPage?: number,
    bufferSize?: number,
    params?: {}
    [key: string]: any
}
export abstract class BaseCollection<T> {

    protected _modelClass: Constructor<T> = null;
    protected _defaultParams: object = {};
    protected _defaults: PaginationOptions = { perPage: 50, bufferSize: 2 };
    protected _resetListeners: Array<Function> = [];
    protected _searchListeners: Array<Function> = [];
    protected _paginator: Paginator<T>;
    protected _routeMap: RouteMap = AppInjector.get(RouteMap);

    constructor(modelClass: Constructor<T>, options: PaginationOptions = {}) {
        options = {...this._defaults, ...options}

        this._modelClass = modelClass;
        this._paginator = new Paginator(this.searchFn, this._modelClass, this._onReset, options.perPage, options.bufferSize)
        this._defaultParams = options.params || {};
    }

    protected abstract searchFn(params: object): Promise<HttpResponse>;

    get paginator(): Paginator<T> {
        return this._paginator;
    }

    // TODO: Harmonize this functionality with the Paginator, this was a short-term solution.
    /**
     * Shortcut for fetching all records for a collection's resource.
     *
     * @param {object} [params={}]
     * @returns {Promise<Array<T>>}
     */
    async all(params: object = {}): Promise<Array<T>> {
        this.clear();
        
        params = { ...params, per_page: -1, current_page: 1 };

        const response = await this.searchFn(params);
        const castCollection = response.data().collection.map( data => new this._modelClass(data));

        return castCollection;
    }

    /**
     * Add a callback to be run when the collection's paginator is cleared.
     *
     * @param {Function} callback
     */
    addResetListener(callback: Function): void { 
        this._resetListeners.push(callback)
    }

    /**
     * Add a callback to be run when the collection's search function is called.
     *
     * @param {Function} callback
     */
     addSearchListener(callback: Function): void { 
        this._searchListeners.push(callback)
    }

    /**
     * Clear the collection of pagination and page data.
     *
     */
    clear(): void {
        this._paginator.reset();
    }

    /**
     * Fetch data for the extending collection that match the search criteria.
     *
     * @param {object} [params={}]
     * @returns {Promise<Page<T>>}
     */
    search(params: object = {}): Promise<Page<T>> {

        this.clear();

        this._paginator.params = { ...params, ...this._defaultParams };

        const searchPromise = this.paginator.toFirstPage();

        this._onSearch();

        return searchPromise;
    }

    /**
    * Shortcut for getting the paginator's current page from buffer.
    *
    * @returns {Page<T>}
    */
    getCurrentPage(): Page<T> {
        return this._paginator.getPage();
    }

    /**
    * Shortcut for getting the paginator's current page's elements from buffer.
    *
    * @returns {Array<T>}
    */
    getCurrentPageElements(): Array<T> {
        const page = this.getCurrentPage();

        if (page && page.elements) return page.elements;

        return [];
    }

    /* 
    This syntax initializes 'this' in the current context, and not from the calling context thanks to
    the arrow function. This is an alternative to binding 'this' explicitly when passing the function as a param.
    
    Ex. we can pass the callback to paginator like: 
        new Paginator(..., this._resetCallback)
    Rather than having to do: 
        new Paginator(..., this._resetCallback.bind(this))
    */
    /**
     * Callback triggered by the paginator reseting that will run the registered reset callbacks.
     *
     */
    protected _onReset = (): void => {
        this._resetListeners.forEach( callback => { callback() });
    }

    protected _onSearch = (): void => {
        this._searchListeners.forEach( callback => { callback() });
    }
}
