import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BaseCollection } from '../collections/Base.collection'

export class CollectionDataSource<T> extends DataSource<T>
{
    private _pageSize: number;
    private _cachedData = new Array<T>();
    private _fetchedPages = new Set<number>();
    private readonly _dataStream = new BehaviorSubject<T[]>( this._cachedData );
    private readonly _subscription = new Subscription();
    private _loading: Array<any> = []

    constructor( private _collection: BaseCollection<T> )
    {
        super()
        this._collection.addResetListener( this._clear.bind( this ) );
        this._collection.addSearchListener( this._onSearch.bind( this ) );
        this._pageSize = _collection.paginator.perPage
    }

    get cachedData(): Array<T> {
        return this._cachedData;
    }

    get loading(): boolean
    {
        return this._loading.length > 0;
    }

    connect( collectionViewer: CollectionViewer ): Observable<T[]>
    {
        // Handle displayed range changes
        this._subscription.add( collectionViewer.viewChange.subscribe( range =>
        {
            // Prevent mat-table from triggering with max value
            // Issue: https://github.com/angular/components/blob/21bb4d5a6a60b9f5883121341295506db77a1154/src/cdk/table/table.ts#L467
            if ( range.end === Number.MAX_VALUE ) { return;}

            const pageIndex = this._inferPageIndex( range.end - 1 );

            // Make sure the current and next pages are loaded
            this._fetchPage( pageIndex );
            this._fetchPage( pageIndex + 1 );

        } ) );
        return this._dataStream;
    }

    disconnect(): void
    {
        this._subscription.unsubscribe();
    }

    loadMore()
    {
        this._fetchPage([...this._fetchedPages].pop() + 1)
    }

    private _clear(): void
    {
        this._fetchedPages = new Set();
        this._cachedData = [];
    }

    private _onSearch(): void
    {
        this._fetchPage( 0 )
    }

    private _inferPageIndex( index: number ): number
    {
        return Math.floor( index / this._pageSize );
    }

    private async _fetchPage( pageIndex: number ): Promise<void>
    {
        if ( this._fetchedPages.has( pageIndex ) ) { return; }
        this._fetchedPages.add( pageIndex );

        this._loading.push(true);

        const page = await this._collection.paginator.toPage( pageIndex + 1 );
        if ( !page || !page.promise ) { this._loading.pop(); return; }
        await page.promise;

        this._cachedData.splice( pageIndex * this._pageSize, this._pageSize, ...page.elements );
        this._dataStream.next( this._cachedData );

        this._loading.pop();
    }
}
