import { BaseModel } from './Base.model'
import { VariantDefinition } from './contracts/VariantDefinition.interface';
import { dto } from './mixins/Dto.decorators';
import { VariantProperty } from './VariantProperty.model';
import { VariantPropertyDefinition } from './VariantPropertyDefinition.model';
import { Thumbnail } from './Thumbnail.model';
import type { Constructor } from '@beaconlite/types';
import { OverrideableItem } from './contracts/OverrideableItem.interface';

export abstract class Variant extends BaseModel implements OverrideableItem {
    @dto() id?: string = null;
    @dto() variant_configuration_id: string = null;
    @dto() variant_id: string = null;
    @dto() name: string = null;
    @dto() display_name: string = null;
    @dto() description: string = null;
    @dto() code: string = null;
    @dto() tags: string = null;
    abstract default_pricing_type: string;    
    abstract properties?: VariantProperty[];
    @dto(Thumbnail) thumbnail: Thumbnail = null;

    /**
     * Add new properties to the current model based on an array of VariantPropertyDefinition's
     * 
     * @param {array} definitions 
     */
    abstract addProps(definitions: VariantPropertyDefinition[]): void;

    protected _addProps(constructor: Constructor<VariantProperty>, definitions: VariantPropertyDefinition[]): void
    {
        definitions.forEach((definition: VariantPropertyDefinition) => 
            this.properties.push(
                new constructor({ 
                    variant_property_definition_id: definition.id,
                    variant_id: this.variant_id,
                })
            )
        );
    }

    getDisplayName(): string
    {
        return this.display_name;
    }

    abstract save(definition: VariantDefinition): Promise<Variant>;
    abstract discard(definition: VariantDefinition): Promise<void>;
}
