import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { RentalRequestEditorService } from './rental-request-editor/rental-request-editor.service';
import { RentalRequest, WorkOrder } from '@beaconlite/models';
import { orderBy } from '../../../../../utilities/Sort.utility';

@Component({
  selector: 'work-order-tab-rentals',
  templateUrl: './tab-rentals.component.html',
  styleUrls: ['./tab-rentals.component.scss']
})
export class TabRentalsComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;
  @Input() shouldShowDepartment: boolean;
  @Input() onSave: () => Promise<void>;

  constructor(protected rentalRequestEditor: RentalRequestEditorService) { }

  ngOnInit(): void 
  {
    this._sortItems();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  async onAddRentalRequest(): Promise<boolean>
  {
    if (! this.workOrder.canAddRentalRequests()) return false;

    const newRentalRequest = new RentalRequest({ 
      location:   this.workOrder.location,
      ordered_by: this.workOrder.ordered_by,
      started_at: this.workOrder.default_request_start,
    });

    return this.rentalRequestEditor.open({
      workOrder: this.workOrder,
      original: newRentalRequest,
      onRequestUpdate: this.onRequestUpdate,
      onRequestRemove: null, 
    });
  }

  onRequestUpdate = async (request: RentalRequest): Promise<any> => 
  {
    if (! request.exists())
    {
      this.workOrder.addRentalRequest(request);
    }

    await this.onSave();
  }

  onRequestRemove = async (request: RentalRequest): Promise<any> =>
  {
    this.workOrder.removeRentalRequest(request);
    await this.onSave();
  }

  protected _sortItems()
  {
    this.workOrder.rental_requests?.sort(orderBy('started_at'));
  }
}
