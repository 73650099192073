import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Issue, WorkOrder } from '@beaconlite/models';
import { SnackbarNotificationService } from '../../../../../../services/notification/snackbar-notification.service';
import { IssueEditorData } from './issue-editor-data.interface';

@Component({
  selector: 'app-issue-editor',
  templateUrl: './issue-editor.component.html',
})
export class IssueEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  readonly SEVERITY_MAJOR = Issue.SEVERITY_MAJOR;
  readonly SEVERITY_QUARANTINE = Issue.SEVERITY_QUARANTINE;

  workOrder: WorkOrder = this.data.workOrder;
  original: Issue = this.data.original;
  issue: Issue;
  // onRemove: Function = this.data.onRemove;

  saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: IssueEditorData,
    public dialogRef: MatDialogRef<IssueEditorComponent>,
    protected snackbarNotifications: SnackbarNotificationService,
  ) { }

  ngOnInit(): void 
  {
    if (!! this.original)
    {
      this.issue = this.original.copy();
    }
    else
    {
      this.issue = new Issue({
        work_order_id: this.workOrder.id,
      });
    }
  }

  getSeverityDescription(): string
  {
    if (this.issue.isQuarantine())
    {
      return 'Quarantined stops all processing on a work order.  Including dispatching and invoicing.';
    }

    if (this.issue.isMajor())
    {
      return 'Flagged stops from invoicing only.';
    }

    return '';
  }

  async save(): Promise<void|boolean>
  {
    if (this.editorForm.invalid) return false;

    this.saving = true;
    
    try
    {
      await this.issue.save();
      this.snackbarNotifications.saved();
      this.dialogRef.close(this.issue);
    }
    finally
    {
      this.saving = false;
    }
  }

}
