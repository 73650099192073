<mat-table fxFlex="100" [dataSource]="this.items" multiTemplateDataRows>
    <ng-container matColumnDef="customer">
        <mat-header-cell *matHeaderCellDef>Client</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="rented">
        <mat-header-cell *matHeaderCellDef>Rented</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.rented }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="max">
        <mat-header-cell *matHeaderCellDef>Max</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.max }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lost">
        <mat-header-cell *matHeaderCellDef>Lost</mat-header-cell>
        <mat-cell *matCellDef="let item">{{ item.lost }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="workOrderDetail" *ngIf="this.nestedLayer == 'work_orders'">
        <mat-cell *matCellDef="let item">
            <rental-item-workorder-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </rental-item-workorder-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rentalDetail" *ngIf="this.nestedLayer == 'items'">
        <mat-cell *matCellDef="let item">
            <rental-item-rental-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </rental-item-rental-row>         
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestDetail" *ngIf="this.nestedLayer == 'rental_requests'">
        <mat-cell *matCellDef="let item">
            <rental-item-request-row fxFlex="100"
                [report]="this.report"
                [items]="this.getNestedItems(item)"
                [layer]="this.nestedLayer">
            </rental-item-request-row>         
        </mat-cell>
    </ng-container>

  
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let item; columns: tableColumns"></mat-row>

    <ng-container *ngIf="this.nestedLayer == 'work_orders'">
        <mat-row *matRowDef="let nested; columns: ['workOrderDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'items'">
        <mat-row *matRowDef="let nested; columns: ['rentalDetail']" ></mat-row>
    </ng-container>

    <ng-container *ngIf="this.nestedLayer == 'rental_requests'">
        <mat-row *matRowDef="let nested; columns: ['requestDetail']" ></mat-row>
    </ng-container>
</mat-table>
