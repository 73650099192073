import { dto } from "./mixins/Dto.decorators";
import { VariantPropertyDefinition } from "./VariantPropertyDefinition.model";
import { VariantServiceOption } from "./VariantServiceOption.model";

export class VariantServicePropertyDefinition extends VariantPropertyDefinition {

    constructor(attributes: object = {})
    {
        super(attributes);
        this.init(attributes);
    }

    @dto(VariantServiceOption) options?: VariantServiceOption[] = [];

    addOption(): void
    {
        this.options.push(
            new VariantServiceOption({ ordinal: this.options.length })
        );
    }
}