<div class="index-sidebar" fxFlex fxLayout="column">
    <form class="bl-search" #searchForm="ngForm" (ngSubmit)="this.onSearch()" novalidate>
        <div fxLayout="column" fxLayoutGap="10px">
            <mat-form-field>
                <mat-label class="mat-icon-label">
                    <mat-icon>search</mat-icon>
                </mat-label>
                <input type="text" 
                    name="keywordField"
                    [(ngModel)]="this.keyword"
                    matInput>
            </mat-form-field>

            <div class="tab" fxLayout="row wrap">
                <mat-checkbox class="search-all" 
                    [(ngModel)]="this.searchAll"
                    #searchAllField="ngModel"
                    name="searchAllField">
                    <span class="mat-caption">Search all fields</span>
                </mat-checkbox>
                <input id="tab-one" type="checkbox" name="tabs">
                <label class="tab-one-label" for="tab-one" fxFlex>More Options</label>

                <div class="tab-content" fxLayout="column" fxFlex="100">
                    <mat-form-field fxFlex>
                        <mat-label>Filter by Department</mat-label>
                        <mat-select [(ngModel)]="this.selectedDepartment" name="selectedDepartmentField">
                            <mat-option value="''">All</mat-option>
                            <mat-option *ngFor="let department of this.departments" [value]="department.id">
                                {{ department.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" color="accent">
                <button type="submit" mat-raised-button color="primary">Search</button>
            </div>
        </div>
    </form>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [fxShow]="this.employeeSource.loading"></mat-progress-bar>

    <cdk-virtual-scroll-viewport itemSize="70">
        <mat-list dense>
            <mat-list-item class="index-list"
                *cdkVirtualFor="let employee of this.employeeSource" 
                [class.index-list__item--selected]="selectedModelId === employee.id"
                (click)="this.onView(employee)">
                <div class="icon-list-item" matListAvatar><mat-icon>person</mat-icon></div>
                <div class="mat-list-text">
                    <div fxLayout="row">
                        <h3 fxFlex>{{ employee.fullname }}</h3>
                        <p>{{ employee.person.phone }}</p>
                    </div>
                    <h4>{{ employee.person.email }}</h4>
                    <p>{{ employee.formattedAddress }}</p>
                </div>
            </mat-list-item>
        </mat-list>
    </cdk-virtual-scroll-viewport>
</div>

<mat-divider vertical></mat-divider>

<ui-view fxFlex [fxHide]="this.isOnIndex"></ui-view>

<div fxLayout="column" fxFlex>
    <div class="spacer"></div>

    <gantt-employee 
        fxFlex
        [fxShow]="this.isOnIndex"
        [sourceData]="this.employeeSource.cachedData" 
        [scrollHandler]="this.onScheduleScroll"
        [clickHandler]="this.onScheduleItemClick"
        yHeight="70" ></gantt-employee>
</div>
