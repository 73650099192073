<div fxflex fxLayout="column">

<form class="bl-padding" #reportForm="ngForm" (ngSubmit)="this.onGenerate()" fxLayout="column" fxFlex="100" novalidate>
    <div fxLayout="row" fxLayoutGap="20px">
        <div class="report-filter__fieldset" fxLayout="column">
            <h4 class="mat-subheading-1">Report Type</h4>
            <mat-form-field>
                <mat-select [(ngModel)]="this.reportType"
                    name="reportTypeField"
                    (ngModelChange)="this.onReportTypeChanged()"
                    required
                    hideRequiredMarker>
                    <mat-option value="active-item">Active Rentals</mat-option>
                    <mat-option value="lost-item">Lost Items</mat-option>
                    <mat-option value="rental-item">Rental Items</mat-option>
                    <mat-option value="service-hours">Service Hours</mat-option>
                    <mat-option value="invoiced-items">Invoiced Items</mat-option>
                    <mat-option value="dispatched-item">Dispatched Items</mat-option>
                    <mat-option value="dispatched-request">Dispatched Request</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="report-filter__fieldset" fxLayout="column">
            <h4 class="mat-subheading-1">Branch</h4>
            <mat-form-field>
                <mat-label>Branch</mat-label>
                <input type="text"
                    [(ngModel)]="this.branchSearchText"
                    name="branchSearchTextField"
                    #branchSearchTextField="ngModel"
                    (ngModelChange)="this.onQueryBranches()"
                    (blur)="this.clearSearchText(this.selectedBranch, 'branch')"
                    [matAutocomplete]="branchAuto"
                    matInput>
                <mat-autocomplete #branchAuto="matAutocomplete"
                    (optionSelected)="this.onBranchSelected($event.option.value)"
                    [displayWith]="this.displaySelectedBranch">
                    <mat-option *ngFor="let branch of promisedBranches | async" [value]="branch">
                        {{ branch.name }}
                    </mat-option>
                </mat-autocomplete>
                <button type="button" 
                    mat-icon-button 
                    matSuffix
                    *ngIf="this.branchSearchText"
                    (click)="this.onClearBranchSelection()"> 
                    <mat-icon color="primary">close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="report-filter__fieldset" fxLayout="column">
            <h4 class="mat-subheading-1">Date Range</h4>
            <div fxLayout="row" fxFlex fxLayoutGap="20px">
                <mat-form-field fxFlex="50">
                    <mat-label>Start Date</mat-label>
                    <input [(ngModel)]="this.selectedStartDate"
                        name="startDateField"
                        [matDatepicker]="startDatePicker"
                        (focus)="startDatePicker.open()"
                        [max]="this.selectedEndDate"
                        matInput>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex="50">
                    <mat-label>End Date</mat-label>
                    <input [(ngModel)]="this.selectedEndDate"
                        name="endDateField"
                        [matDatepicker]="endDatePicker"
                        (focus)="endDatePicker.open()"
                        [min]="this.selectedStartDate"
                        matInput>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="report-filter__fieldset" fxLayout="column">
            <h4 class="mat-subheading-1">List Levels</h4>
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>Level 1</mat-label>
                    <mat-select class="report-filter__list-level-select"
                        [(ngModel)]="this.listLevels[0]"
                        name="listLevel0Field"
                        (ngModelChange)="this.onListChange(0)"
                        required>
                        <mat-option value="items">Rental Items</mat-option>
                        <mat-option value="clients">Clients</mat-option>
                        <mat-option value="work_orders">Work Orders</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field [fxShow]="this.listLevel >= 1">
                    <mat-label [fxShow]="this.listLevel >= 1">Level 2</mat-label>
                    <mat-select name="listBy1"
                        class="report-filter__list-level-select"
                        [fxShow]="this.listLevel >= 1"
                        [(ngModel)]="this.listLevels[1]"
                        (ngModelChange)="this.onListChange(1)"
                        [disabled]="this.listLevel < 1">
                        <mat-option value="null">--</mat-option>
                        <mat-option *ngIf="this.isListOption('items', 1)" value="items" >Rental Items</mat-option>
                        <mat-option *ngIf="this.isListOption('clients', 1)" value="clients">Clients</mat-option>
                        <mat-option *ngIf="this.isListOption('work_orders', 1)" value="work_orders">Work Orders</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="list-levels__skeleton" [fxShow]="this.listLevel < 1">
                    <button type="button"
                        class="list-levels__add-button"
                        mat-mini-fab
                        color="accent"
                        [fxShow]="this.listLevel == 0"
                        (click)="this.addListLevel()"
                        matTooltip="Add List Level">
                    <mat-icon>add</mat-icon>
                </button>
                </div>
                <mat-form-field [fxShow]="this.listLevel >= 2">
                    <mat-label [fxShow]="this.listLevel >= 2">Level 3</mat-label>
                    <mat-select name="listBy2"
                        class="report-filter__list-level-select"
                        [fxShow]="this.listLevel >= 2"
                        [(ngModel)]="this.listLevels[2]"
                        (ngModelChange)="this.onListChange(2)"
                        [disabled]="this.listLevel < 2">
                        <mat-option value="null">--</mat-option>
                        <mat-option *ngIf="this.isListOption('items', 2)" value="items" >Rental Items</mat-option>
                        <mat-option *ngIf="this.isListOption('clients', 2)" value="clients">Clients</mat-option>
                        <mat-option *ngIf="this.isListOption('work_orders', 2)"value="work_orders">Work Orders</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="list-levels__skeleton" [fxShow]="this.listLevel < 2">
                    <button type="button"
                        class="list-levels__add-button"
                        mat-mini-fab
                        color="accent"
                        [fxShow]="this.listLevel == 1"
                        (click)="this.addListLevel()"
                        matTooltip="Add List Level"
                        [disabled]="this.listLevels[1] == null">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div> 
        </div>

        <div fxLayout="column">
            <h4 class="mat-subheading-1">&nbsp;</h4>
            <button type="button"
                class="report-filter__add-filter-button"
                mat-mini-fab
                color="primary"
                (click)="this.addFilter()"
                matTooltip="Filters">
                <mat-icon>filter_list</mat-icon>
            </button>
        </div>
    </div>
    <div class="report-filter__fieldset report-filter__filter-chips" fxLayout="column" *ngIf="this.filters.length">
        <h4 class="mat-subheading-1">Filters</h4>
        <div fxLayout="row">
            <mat-chip-list #filtersList>
                <mat-chip *ngFor="let filter of this.filters"
                    removable
                    (removed)="this.onRemoveFilterChip(filter)">
                    {{ filter.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px">
        <button type="submit" mat-raised-button color="primary">Generate</button>
        <button type="button" mat-raised-button [matMenuTriggerFor]="exportMenu" color="primary">Export</button>
        <mat-menu #exportMenu="matMenu">
            <button mat-menu-item (click)="this.onExport()">
                Nested Export
            </button>
            <button mat-menu-item (click)="this.onExport('flat')">
                Flat Export
            </button>
        </mat-menu>
    </div>

</form>

<mat-divider></mat-divider>
<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<div fxFlex fxFlexFill>
    <dispatched-item-client-row
        *ngIf="this.report?.params.list_by[0] == 'clients'"
        [report]="this.report"
        [items]="this.report.items"
        [layer]="this.report.params.list_by[0]">
    </dispatched-item-client-row>

    <dispatched-item-workorder-row
        *ngIf="this.report?.params.list_by[0] == 'work_orders'"
        [report]="this.report"
        [items]="this.report.items"
        [layer]="this.report.params.list_by[0]">
    </dispatched-item-workorder-row>

    <dispatched-item-rental-row
        *ngIf="this.report?.params.list_by[0] == 'items'"
        [report]="this.report"
        [items]="this.report.items"
        [layer]="this.report.params.list_by[0]">
    </dispatched-item-rental-row>
</div>
</div>

