<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="save()" novalidate>
    <mat-toolbar>
        <h2>Line Item Request Editor</h2>
        <span fxFlex></span>
        <mat-icon *ngIf="request.locked" color="primary">lock_outline</mat-icon>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <mat-label>Ordered By</mat-label>
                <input type="text"
                    [(ngModel)]="request.ordered_by"
                    name="orderedByField"
                    #orderedByField="ngModel"
                    maxlength="255"
                    [disabled]="request.locked"
                    matInput>
                    <mat-hint align="end">{{ request.ordered_by?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Order Date</mat-label>
                <input [(ngModel)]="request._dates.started_at.date"
                    name="startedAtField"
                    #startedAtField="ngModel"
                    [matDatepicker]="startedAtPicker"
                    [min]="minDate"
                    required
                    (focus)="startedAtPicker.open()"
                    [disabled]="request.locked"
                    matInput>
                <mat-datepicker-toggle matSuffix [for]="startedAtPicker"></mat-datepicker-toggle>
                <mat-datepicker #startedAtPicker></mat-datepicker>
                <mat-error *ngIf="startedAtField.hasError('required')">Start date is required.</mat-error>
                <mat-error *ngIf="startedAtField.hasError('matDatepickerMin')">Start date is too early.</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent">
            Save
        </button>
    </mat-dialog-actions>
</form>
