import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ChargeEditorService } from '../editors/charge-editor/charge-editor.service';
import { DispatchEditorService } from '../../views/protected/work-orders/work-order-single/tab-dispatches/dispatch-editor/dispatch-editor.service';
import { RentalQuantitiesEditorService } from '../../views/protected/work-orders/work-order-single/tab-dispatches/rental-quantities-editor/rental-quantities-editor.service';
import { ServiceQuantitiesEditorService } from '../../views/protected/work-orders/work-order-single/tab-dispatches/service-quantities-editor/service-quantities-editor.service';
import { EmailEditorService } from '../editors/email-editor/email-editor.service';
import { NoteEditorService } from '../editors/note-editor/note-editor.service';
import { Charge, Dispatch, DispatchedRentalRequest, DispatchedService, Document, Issue, Note, Signature, WorkOrder } from '@beaconlite/models';
import { SignatureCaptureEditorService } from '../editors/signature-capture-editor/signature-capture-editor.service';
import { DateFilterPipe } from '../../pipes/date-filter.pipe';
import { SmsNotificationsService } from '../../views/protected/work-orders/work-order-single/tab-dispatches/sms-notifications/sms-notifications.service';
import { DialogNotificationService } from '../../services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '../../services/notification/snackbar-notification.service';
import { DocumentEditorService } from '@beaconlite/components/document/document-editor/document-editor.service';
import { DocumentUploadService } from '@beaconlite/components/document/document-upload/document-upload.service';
import { DocumentPreviewService } from '@beaconlite/components/document/document-preview/document-preview.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { prefs } from '@beaconlite/services/preferences.service';
import { EmailHistoryService } from '@beaconlite/components/email-history/email-history.service'

@Component({
  selector: 'dispatch-component',
  templateUrl: './dispatch.component.html',
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `dispatch.component.scss` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,
})
export class DispatchComponent implements OnInit, DoCheck {

  @Input() workOrder: WorkOrder;
  @Input() dispatch: Dispatch;
  @Input() shouldShowDepartment: boolean;
  @Input() isAdmin: boolean;
  @Input() canEdit: boolean;
  @Input() onUpdate: () => Promise<void>;
  @Input() onRemove: () => Promise<void>;

  smsEnabled = false;

  loading = false;
  dispatching = true;
  displayType: string;
  headerClass: string;

  // Tables
  requestTableColumns = ['id', 'item-count', 'start-end', 'location', 'reviewed'];
  serviceTableColumns = ['id', 'name', 'start-end', 'location', 'reviewed'];

  constructor(
    protected $state: StateService,
    protected dateFilter: DateFilterPipe,
    protected noteEditor: NoteEditorService,
    protected chargeEditor: ChargeEditorService,
    protected dispatchEditor: DispatchEditorService,
    protected emailEditor: EmailEditorService,
    protected rentalQuantitiesEditor: RentalQuantitiesEditorService,
    protected serviceQuantitiesEditor: ServiceQuantitiesEditorService,
    protected signatureCaptureEditor: SignatureCaptureEditorService,
    protected documentEditor: DocumentEditorService,
    protected documentUploader: DocumentUploadService,
    protected documentPreviewer: DocumentPreviewService,
    protected smsNotifications: SmsNotificationsService,
    protected dialogNotification: DialogNotificationService,
    protected snackbarNotifications: SnackbarNotificationService,
    protected emailHistory: EmailHistoryService,
    ) { }

  async ngOnInit(): Promise<void> 
  {
    if (this.dispatch.hasSignatures())
    {
      this.dispatch.signatures.forEach(signature => { signature.attachment.getUrl() })
    }

    this.displayType = this.dispatch.getDisplayType();
    this.headerClass = this.dispatch.getHeaderClass();

    this.smsEnabled = await prefs('company.sms_enabled')

    this._sortItems()
    this._mapNotificationStatusToEmployees();
  }

  ngDoCheck(): void 
  {
    this._sortItems();
  }

  displayDispatcher(): string
  {
    if (! this.dispatch.dispatcher) return;
    return this.dispatch.dispatcher.fullname;
  }

  displayCompleter(): string
  {
    if (! this.dispatch.completer) return;
    return this.dispatch.completer.fullname;
  }


  displayDepartments(): string
  {
    return this.dispatch.departments.map( department => department.name)
        .sort()
        .join(', ');
  }

  isWorkOrderView(): boolean
  {
    return this.$state.is('protected.work-orders.single');
  }

  hasIssueRestraints(): boolean
  {
    return this.workOrder.hasUnresolvedIssues(Issue.SEVERITY_QUARANTINE);
  }

  // Main menu items are blocked in template so no permissions check is done for these functions.
  async onEdit(): Promise<void>
  {
    const updatedDispatch: Dispatch = 
      await this.dispatchEditor.open({
        workOrder: this.workOrder,
        original: this.dispatch,
        onDispatchRemove: this.remove,
      });

    if (! updatedDispatch) return;

    this.dispatch.map(updatedDispatch.flush());
  }

  async remove(): Promise<void>
  {
    if (! await this.dialogNotification.removeConfirm()) return;

    this.loading = true;
    
    try
    {
      await this.dispatch.discard();
      await this.onRemove();
    }
    finally
    {
      this.loading = false;
    }
  }

  async onSend(): Promise<boolean>
  {
    return this.emailEditor.open({
      defaults: {
        to: [ this.workOrder.client.email ]
      },
      sendableModel: this.dispatch,
    });
  }

	async onMarkSent(): Promise<void>
	{
		await this.dispatch.markSent();
		await this.onUpdate();
	}

  shouldShowSentInfo(): boolean {
    return Boolean(this.dispatch.last_sent_at || this.dispatch.marked_as_sent_at);
  }

  getSentInfoLabel(): string {
    return this.dispatch.last_sent_at ? "Last Sent" : "Marked Sent";
  }

  getSentName(): string {
    return this.dispatch.last_sent ? this.dispatch.last_sent.sender.fullname : '';
  }

  getSentOrMarkedSentAt(): number {
    return this.dispatch.last_sent_at ? this.dispatch.last_sent_at : this.dispatch.marked_as_sent_at;
  }

	async onShowEmailHistory(): Promise<void>
	{
		await this.emailHistory.open({
      sendableModel: this.dispatch,
    });
	}

  async onNotify(): Promise<void>
  {
    await this.smsNotifications.open({
      originalSendableModel: this.dispatch,
      originalTo: this.dispatch.employees.map( employee => employee.person.phone ).filter( phone => !!phone),
      originalMessage: this._buildSmsMessage(),
      onSmsNotificationUpdate: this.onUpdate,
    });
  }

  _buildSmsMessage(): string
  {
    const date = this.dateFilter.transform(this.dispatch.scheduled_at, 'dateMedium');
    const timeOnSite = this.dateFilter.transform(this.dispatch.scheduled_at, 'time');
    const employees = this.dispatch.employees.reduce( (accumulator: any, employee, index) => { 
        if(index != 0 )
        {
            accumulator += '\n'
        }
        return accumulator += employee.fullname
    }, [])
    let workOrderNotes = this.workOrder.notes.filter( note => note.type == Note.TYPE_DISPATCH )
    this.dispatch.dispatch_notes.concat(workOrderNotes)
    const dispatchNotes = this.dispatch.dispatch_notes.concat(workOrderNotes).reduce( (accumulator: any, note, index) => { 
        if(index != 0 )
        {
            accumulator += '\n\n'
        }
        return accumulator += note.content
    }, [])

    const dispatchSlipUrl = this.$state.href('protected.user-dispatches.single', { modelId: this.dispatch.id }, { absolute: true })

    let message = `${date}`;
    message += this.dispatch.time_specified ? `\nTime on site: ${timeOnSite}` : '';
    message += `\n${employees}`;
    message += this.dispatch.truck_identifier ? `\nVehicle: ${this.dispatch.truck_identifier}` : '';
    message += `\nClient: ${this.workOrder.client.name}`;
    message += `\nLocation: ${this.dispatch.location}`;
    message += `\nSite Contact: ${this.workOrder.ordered_by}`;
    message += `\nDispatch Slip: ${dispatchSlipUrl}`; 
    message += dispatchNotes.length ? `\nDispatch Notes: ${dispatchNotes}` : '';

    return message;
  }

  _mapNotificationStatusToEmployees(): void
  {
    this.dispatch.employees.forEach(employee => {
      const notificationStatus = this.dispatch.notification_summary?.find( status => employee.id === status.recipient_id)
      employee.temps.notification_status = notificationStatus || null
    })
  }

  onViewSlip(): void
  {
    // TODO Jira BL-565. Is there a way to link this so the url is /dispatch-schedule/dispatch-slip/:id (for admins)?
    const url = this.$state.href('protected.user-dispatches.single', { modelId: this.dispatch.id });
    window.open(url, '_blank');
  }

  onPrint(): void
  {
    var url = this.$state.href('print.dispatch', { modelId: this.dispatch.id } );
    window.open(url, '_blank');
  }

  async onDownload(): Promise<void>
  {
    await this.dispatch.getPdf();
    this.dispatch.pdf.download();
  }

  async onDoDispatch(): Promise<void>
  {
    this.dispatching = true;

    try
    {
      await this.dispatch.dispatch();
      await this.onUpdate();
    }
    finally
    {
      this.dispatching = false;
    }
  }

  async onCancel(): Promise<void>
  {
    this.dispatching = true;

    const shouldCancel: boolean = await this.dialogNotification.confirm({
      title: 'Confirm Undispatch?',
      textContent: 'Are you sure you would like to undispatch?',
    });

    if (! shouldCancel) return;

    try
    {
      await this.dispatch.cancel();
      await this.onUpdate();
    }
    finally
    {
      this.dispatching = false;
    }
  }

  async onComplete(): Promise<void>
  {
    this.dispatching = true;

    try
    {
      await this.dispatch.complete();
      await this.onUpdate();
    }
    finally
    {
      this.dispatching = false;
    }
  }

  async onUncomplete(): Promise<void>
  {
    this.dispatching = true;
     
    const shouldUncomplete: boolean = await this.dialogNotification.confirm({
      title: 'Confirm Uncomplete?',
      textContent: 'Are you sure you would like to uncomplete the dispatch?',
    });

    if (! shouldUncomplete) return;

    try
    {
      await this.dispatch.uncomplete();
      await this.onUpdate();
    }
    finally
    {
      this.dispatching = false;
    }
  }

  onDocumentUpdate = async (): Promise<void> => 
  {
    await this.onUpdate();
  }

  onDocumentRemove = async (): Promise<void> => 
  {
    await this.onDocumentUpdate();
  }

  async onAddDocument(): Promise<void>
  {
    await this.documentUploader.open({
      associables: this.workOrder.documents,
      documentableModel: this.dispatch,
      onDocumentUpdate: this.onDocumentUpdate
    });
  }

  async onEditDocument(doc: Document): Promise<void>
  {
    await this.documentEditor.open({
      original: doc,
      linkOnly: true,
      documentableModel: this.workOrder,
      onDocumentUpdate: this.onDocumentUpdate,
      onDocumentRemove: this.onDocumentRemove, 
    });
  }

  async onPreviewDocument(doc: Document): Promise<void>
  {
    await this.documentPreviewer.open({original: doc});
  }

  async onRemoveDocument(doc: Document): Promise<void>
  {
    // Doc must be at least association with this dispatch.
    // Show if more than one association.
    if (doc.associations.length > 1)
    {
      const shouldContinue: boolean = await this.dialogNotification.confirm({
        title: 'Confirm Linked Document Deletion?',
        textContent: `This Document is linked to these items: ${doc.associations.join(', ')} <br><br> Are you sure you would like delete it?`,
      });

      if (! shouldContinue) return;
    }

    const shouldContinue: boolean = await this.dialogNotification.confirm({
      title: 'Confirm Document Deletion?',
      textContent: 'Are you sure you would like delete this Document from the Work Order?',
    });

    if (! shouldContinue) return;

    await doc.delete();
    await this.onDocumentRemove();
  }

  async onUnlinkDocument(doc: Document): Promise<void>
  {
    const shouldContinue: boolean = await this.dialogNotification.confirm({
      title: 'Confirm Document Unlinking?',
      textContent: 'Are you sure you would like unlink this Document from the Dispatch?',
    });

    if (! shouldContinue) return;

    await this.dispatch.unlinkDocument(doc);
  }

  onReviewRequestValues(request: DispatchedRentalRequest)
  {
    if (!this.dispatch.actioned_at || 
        !!this.dispatch.completed_at ||
        !!request.reviewed_at)
    {
      return;
    }

    return this.rentalQuantitiesEditor.open({
      workOrder: this.workOrder,
      dispatch: this.dispatch,
      original: request,
      onDispatchUpdate: this.onUpdate,
    });
  }

  onReviewServiceValues(service: DispatchedService)
  {
    if (!this.dispatch.actioned_at || 
        !!this.dispatch.completed_at ||
        !!service.reviewed_at)
    {
      return; 
    }

    return this.serviceQuantitiesEditor.open({
      workOrder: this.workOrder,
      dispatch: this.dispatch,
      original: service,
      onDispatchUpdate: this.onUpdate,
    });
  }

  onAddCharge()
  {
    return this.onEditCharge();
  }

  onEditCharge(charge: Charge = null)
  {
    const onChargeUpdate = async (updatedCharge: Charge) => {
      updatedCharge?.exists()
        ? charge.mapAttributes(updatedCharge.flushAttributes())
        : this.dispatch.addCharge(updatedCharge);
      
      await this.dispatch.save();
      await this.onUpdate();
    }

    const onChargeRemove = async () => {
      this.dispatch.removeCharge(charge)
      await this.dispatch.save();
      await this.onUpdate();
    }

    return this.chargeEditor.open({
      original: charge,
      onChargeUpdate,
      onChargeRemove,
    });
  }

  onAddNote()
  {
    return this.onEditNote();
  }

  onEditNote(note: Note = null)
  {
    // Pass a null edit function to component instead of explicitly giving component permissions level.
    if (!this.canEdit) { return; }

    const onNoteUpdate = async (updatedNote: Note) => {
      updatedNote?.exists()
        ? note.mapAttributes(updatedNote.flushAttributes())
        : this.dispatch.addNote(updatedNote);

      await this.dispatch.save();
      this.snackbarNotifications.saved();
      this.onUpdate();
    }

    const onNoteRemove = async () => {
      this.dispatch.removeNote(note);

      await this.dispatch.save();
      this.snackbarNotifications.saved();
      this.onUpdate();
    }

    return this.noteEditor.open({
      original: note,
      options: {defaultType: Note.TYPE_DISPATCH},
      onNoteUpdate,
      onNoteRemove,
    });
  }

  async onCaptureSignature(signature: Signature): Promise<void>
  {
    // Pass a null edit function instead of opening editor.
    if (!this.canEdit) { return; }

    const onSignatureUpdate = async (updaptedSignature: Signature) => 
    {
      await signature.attachment.getUrl();
      this.snackbarNotifications.saved();
      await this.onUpdate();
    }

    const onSignatureRemove = async () => 
    {
      this.snackbarNotifications.saved();
      await this.onUpdate();
    }

    this.signatureCaptureEditor.open({
      original: signature,
      relatedModel: this.dispatch,
      onSignatureUpdate,
      onSignatureRemove,
    })
  }

  protected _sortItems(): void
  {
    this.dispatch.rental_requests?.sort(orderBy('dispatched_started_at', 'source.formatted_serial_id'));
    this.dispatch.services?.sort(orderBy('dispatched_started_at', 'source.formatted_serial_id'));
    this.dispatch.charges?.sort(orderBy('created_at'));
    this.dispatch.notes?.sort(orderBy('noted_at'));
    this.dispatch.documents?.sort(orderBy('created_at'));
    this.dispatch.signatures?.sort(orderBy('captured_at'));
  }
}
