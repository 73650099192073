import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { InvoiceEditorData } from "./invoice-editor-data.interface";
import { InvoiceEditorComponent } from "./invoice-editor.component";

@Injectable({
    providedIn: 'root'
})
export class InvoiceEditorService {

    constructor(protected dialog: MatDialog) {}

    async open(data: InvoiceEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            data,
        }

        return this.dialog.open(InvoiceEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
  