import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Account, Dispatch, Document, DispatchedRentalRequest, DispatchedService, Note, Signature, WorkOrder, DispatchedRental } from '@beaconlite/models';
import { EmailEditorService } from '../editors/email-editor/email-editor.service';
import { NoteEditorService } from '../editors/note-editor/note-editor.service';
import { DateFilterPipe } from '../../pipes/date-filter.pipe';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { SignatureCaptureEditorService } from '../editors/signature-capture-editor/signature-capture-editor.service';
import { DialogNotificationService } from '../../services/notification/dialog-notification.service';
import { SnackbarNotificationService } from '../../services/notification/snackbar-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import { DispatchSlipRequestEditorService } from './dispatch-slip-request-editor/dispatch-slip-request-editor.service';
import { DispatchSlipServiceEditorService } from './dispatch-slip-service-editor/dispatch-slip-service-editor.service';
import { PunchTask } from './punch-button/punch-task.interface';
import { DocumentUploadService } from '@beaconlite/components/document/document-upload/document-upload.service';
import { DocumentEditorService } from '@beaconlite/components/document/document-editor/document-editor.service';
import { DocumentPreviewService } from '@beaconlite/components/document/document-preview/document-preview.service';
import { MatTable } from '@angular/material/table';
import { prefs } from '@beaconlite/services/preferences.service';
import { DispatchedFlattenedRentals, FlattenedRentalsValue } from '@beaconlite/models/contracts/DispatchedFlattenedRentals.interface';
import { RentalFlattenBuilder } from '@beaconlite/services/RentalFlattenBuilder.service';

@Component({
  selector: 'app-dispatch-slip',
  templateUrl: './dispatch-slip.component.html',
  styleUrls: ['./dispatch-slip.component.scss']
})
export class DispatchSlipComponent implements OnInit {

  @ViewChild('rentalTable') rentalTable: MatTable<DispatchedRental[]>;

  @Input() dispatch: Dispatch;
  @Input() workOrder: WorkOrder;
  @Input() onUpdate: () => Promise<void>;

  title: string;
  finishing = false;
  account = new Account({});
  dispatchNotes: Note[] = [];
  companyBusinessHours: {start: number, end: number};
  isAdmin: boolean;
  isReadOnly: boolean;
  actionLabel: string;
  displayType: string;
  headerClass: string;
  displayDate: string;
  canEditRequests: boolean;
  isFlattenedView: boolean[];
  requestFlattenedRentals: Map<string, DispatchedFlattenedRentals> = new Map();

  tasks: PunchTask[] = [
    { name: 'Work', slug: 'working', id: 'working'},
    { name: 'Travel', slug: 'travel', id: 'travel'},
    { name: 'End Work', slug: 'end', id: 'end'},
  ];

  tableColumns = ['item', 'quantity', 'actioned-quantity'];

  constructor(
    protected auth: AuthenticationService,
    protected dateFilter: DateFilterPipe,
    protected $state: StateService,
    protected emailEditor: EmailEditorService,
    protected noteEditor: NoteEditorService,
    protected signatureCaptureEditor: SignatureCaptureEditorService,
    protected dialogNotification: DialogNotificationService,
    protected snackbarNotification: SnackbarNotificationService,
    protected dispatchSlipRequstEditor: DispatchSlipRequestEditorService,
    protected dispatchSlipServiceEditor: DispatchSlipServiceEditorService,
    protected documentUploader: DocumentUploadService,
    protected documentEditor: DocumentEditorService,
    protected documentPreviewer: DocumentPreviewService,
    protected rentalFlattenBuilder: RentalFlattenBuilder,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.isFlattenedView = Array(this.dispatch?.rental_requests.length).fill(false);
    this.setRequestFlattenedRentals();

    this.account = await this.auth.getCurrentUser();
    this._initPermissions();

    this.actionLabel = this.dispatch.getActionLabel();
    this.displayType = this.dispatch.getDisplayType();
    this.headerClass = this.dispatch.getHeaderClass();
    this.displayDate = this.dispatch.time_specified ? this.dateFilter.transform(this.dispatch.scheduled_at, 'dateTimeMedium') : this.dateFilter.transform(this.dispatch.scheduled_at, 'dateMedium');

    let workOrderNotes = this.workOrder.notes.filter( note => note.type == Note.TYPE_DISPATCH )
    this.dispatchNotes = this.dispatch.dispatch_notes.concat(workOrderNotes)

    if (this.dispatch.hasSignatures())
    {
      this.dispatch.signatures.forEach(signature => { signature.attachment.getUrl() })
    }

    this.companyBusinessHours = await prefs('company.business_hours');
    this._sortItems();
  }
  
  onFlattenedViewToggledHandler($event: boolean, idx: number): void {
    this.isFlattenedView[idx] = $event;
  }

  setRequestFlattenedRentals(): void {
    for (let i = 0; i < this.dispatch.rental_requests.length; i+=1) {
      const rentalRequest = this.dispatch.rental_requests[i];
      this.requestFlattenedRentals.set(rentalRequest.id, this.rentalFlattenBuilder.computeDispatchedFlattenedRentals(rentalRequest));
    }
  }

  punchCallback(request: DispatchedService): (selection: any) => Promise<void>
  {
    return (selection: any) => {
      return this.captureTime(request, selection.task.slug);
    }
  }

  onPrintDispatch(): void
  {
    const url = this.$state.href('print.dispatch', { modelId: this.dispatch.id } );
    window.open(url, '_blank');
  }

  async onDownload(): Promise<void>
  {
    await this.dispatch.getPdf();
    this.dispatch.pdf.download();
  }

  async onSend(): Promise<boolean>
  {
    return this.emailEditor.open({
      defaults: {
        to: [ this.workOrder.client.email ]
      },
      sendableModel: this.dispatch,
    });
  }

  async onPreviewDocument(doc: Document): Promise<void>
  {
    await this.documentPreviewer.open({
      original: doc,
      linkOnly: true,
      documentableModel: this.dispatch,
      onDocumentUpdate: this.onDocumentUpdate,
      onDocumentRemove: this.onDocumentRemove,
    });
  }

  async onAddDocument(): Promise<void>
  {
    await this.documentUploader.open({
      associables: this.dispatch.documents,
      documentableModel: this.dispatch,
      onDocumentUpdate: this.onDocumentUpdate,
    });
  }

  async onEditDocument(doc: Document): Promise<void>
  {
    await this.documentEditor.open({
      original: doc,
      linkOnly: true,
      documentableModel: this.dispatch,
      onDocumentUpdate: this.onDocumentUpdate,
      onDocumentRemove: this.onDocumentRemove,
    });
  }

  async onRemoveDocument(doc: Document): Promise<void>
  {
    // Doc must be at least association with this dispatch.
    // Show if more than one association.
    if (doc.associations.length > 1)
    {
      const shouldContinue: boolean = await this.dialogNotification.confirm({
        title: 'Confirm Linked Document Deletion?',
        textContent: `This Document is linked to these items: ${doc.associations.join(', ')} <br><br> Are you sure you would like delete it?`,
      });

      if (! shouldContinue) return;
    }

    const shouldContinue: boolean = await this.dialogNotification.confirm({
      title: 'Confirm Document Deletion?',
      textContent: 'Are you sure you would like delete this Document from the Work Order?',
    });

    if (! shouldContinue) return;

    await doc.delete();
    await this.onDocumentRemove();
  }

  onDocumentUpdate = async (): Promise<void> =>
  {
    await this.onUpdate();    
  }

  onDocumentRemove = async (): Promise<void> =>
  {
    await this.onDocumentUpdate();
  }

  onAddNote()
  {
    return this.onEditNote();
  }

  onEditNote(note: Note = null)
  {
    const onNoteUpdate = async (updatedNote: Note) => 
    {
      updatedNote?.exists()
        ? note.mapAttributes(updatedNote.flushAttributes())
        : this.dispatch.addNote(updatedNote);

      await this.dispatch.save();
      this.snackbarNotification.saved();
      await this.onUpdate();
    }

    const onNoteRemove = async () => 
    {
      this.dispatch.removeNote(note);
      await this.dispatch.save();
      this.snackbarNotification.saved();
      await this.onUpdate();
    }

    return this.noteEditor.open({
      original: note,
      options: {types: [Note.TYPE_FIELD]},
      onNoteUpdate,
      onNoteRemove,
    });
  }

  async captureRentalQuantity(request: DispatchedRentalRequest, idx: number): Promise<void>
  {
    const onQuantityUpdate = () => {
      this.setRequestFlattenedRentals();
    }
  

    await this.dispatchSlipRequstEditor.open({
      original: request,
      dispatch: this.dispatch,
      isFlattenedView: this.isFlattenedView[idx],
      onQuantityUpdate
    });
  }

  async captureServiceQuantity(service: DispatchedService): Promise<void>
  {
    await this.dispatchSlipServiceEditor.open({
      original: service,
      dispatch: this.dispatch,      
    });
  }

  async captureTime(service: DispatchedService, task: string): Promise<void>
  {
    if(service.temps.isCapturingTime){return;}
    service.temps.isCapturingTime = true;

    this.dispatch.captureTime(service, task, this.companyBusinessHours);

    try
    {
      await this.dispatch.save();
      await this.onUpdate();
      this.snackbarNotification.saved();
    }
    finally
    {
      service.temps.isCapturingTime = false;
    }
  }

  isDocumentModifiable(doc: Document): boolean
  {
    const isOriginalUploader = doc.uploaded_by === this.account.id;
    
    return (this.isAdmin || isOriginalUploader) && !this.isReadOnly;
  }

  shouldShowSection(countable: Array<unknown>): boolean
  {
    return !(this.isReadOnly && countable.length === 0);
  }

  captureSignature(signature: Signature)
  {
    if ( this.isReadOnly ) { return; }

    const onSignatureUpdate = async (updaptedSignature: Signature) => 
    {
      try
      {
        await updaptedSignature.attachment.getUrl();
        this.snackbarNotification.saved();
      }
      finally
      {
        await this.onUpdate();
      }
    }

    const onSignatureRemove = async () =>
    {
      this.snackbarNotification.saved();
      await this.onUpdate();
    }

    this.signatureCaptureEditor.open({
      original: signature,
      relatedModel: this.dispatch,
      onSignatureUpdate,
      onSignatureRemove,
    });
  }

  hasUnactionedServices(): boolean
  {
    return this.dispatch.services.some(service => {
        if (service.source.pricing_type !== 'unit')
        {
            return !service.actioned_started_at
        }

        if (service.source.pricing_type === 'unit')
        {
            return service.actioned_quantity === null
        }
    })
  }

  hasUnactionedRentals(): boolean
  {
    return this.dispatch.rental_requests.some(request => {
        return request.rentals.some(rental => {
            return rental.actioned_quantity === null
        })
    })
  }

  hasRunningServices(): boolean
  {
    return this.dispatch.services
      .filter(service => service.source.pricing_type === 'auto')
      .some(service => service.actioned_started_at && !service.actioned_ended_at )
  }

  async checkAndDisplayIncompleteWarning(): Promise<boolean|void>
  {
      if(!this.hasUnactionedServices() && !this.hasUnactionedRentals())
      {
        return Promise.resolve();
      }

      const shouldContinue: boolean = await this.dialogNotification.confirm({
          title: 'Incomplete Data',
          textContent: 'Some requests are missing values. Would you still like to finish this dispatch?'
      });

      if (shouldContinue) return Promise.resolve();

      return Promise.reject();
  }

  async checkAndDisplayServiceRunningWarning(): Promise<boolean|void>
  {   
    if (! this.hasRunningServices())
    {
      return Promise.resolve();
    }
    
    const shouldEndServices: boolean = await this.dialogNotification.confirm({
        title: 'Incomplete Data',
        textContent: 'Some timers are still running. Would you like to end them and finish this dispatch?'
    });

    if (! shouldEndServices) return Promise.reject();

    const ongoingServices = this.dispatch.services.filter(service => {
        if (service.source.pricing_type === 'auto')
        {
            return service.actioned_started_at && !service.actioned_ended_at
        }
    });

    ongoingServices.forEach( service => this.dispatch.captureTime(service, 'end', this.companyBusinessHours) )
  
    await this.dispatch.save();
  }

  async finish(): Promise<void>
  {   
      this.finishing = true;

      try
      {
        await this.checkAndDisplayIncompleteWarning();
        await this.checkAndDisplayServiceRunningWarning();
        await this.dispatch.action();
        await this.onUpdate();

        this.snackbarNotification.saved();
      }
      finally
      {
        this.finishing = false;
      }
  }

  protected async _initPermissions(): Promise<void>
  {
    this.isAdmin = this.account.isAdmin();
    const isAssignedToDispatch = this.dispatch.employees.some(employee => employee.id === this.account.employee_id);
    this.isReadOnly = (!this.isAdmin && !isAssignedToDispatch);
    this.canEditRequests = !this.dispatch.actioned_at && !this.dispatch.completed_at && !this.isReadOnly;
  }

  protected _sortItems(): void
  {
    this.dispatchNotes?.sort(orderBy('noted_at'));
    this.dispatch.documents?.sort(orderBy('created_at'));
    this.dispatch.field_notes?.sort(orderBy('noted_at'));
    this.dispatch.signatures?.sort(orderBy('captured_at'));

    this.dispatch.rental_requests
      ?.sort(orderBy('started_at', 'formatted_serial_id'))
      .forEach(request => {
        request.source.notes?.sort(orderBy('noted_at'));
        request.rentals?.sort(orderBy('source.position'));
      });

    this.dispatch.services
      ?.sort(orderBy('actioned_started_at', 'formatted_serial_id'))
      .forEach(service => {
        service.source.notes?.sort(orderBy('noted_at'));
      });

    this.rentalTable?.renderRows();
  }

  getFlattenedRentalsForRequest (request: DispatchedRentalRequest): FlattenedRentalsValue[] {
    return Array.from((this.requestFlattenedRentals.get(request.id)).values())
  }

}
