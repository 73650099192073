import { Component, DoCheck, Input, OnInit, Output } from '@angular/core';
import { RentalReportItem } from '@beaconlite/models/reports/contracts/RentalReportItem.interface';
import { RentalItemReport } from '@beaconlite/models/reports/RentalItemReport.model';

@Component({
  selector: 'rental-item-client-row',
  templateUrl: './client-row.component.html',
})
export class ClientRowComponent implements OnInit, DoCheck  {

  @Input() report: RentalItemReport;
  @Input() items: RentalReportItem[];
  @Input() layer: string;

  nestedLayer = '';
  tableColumns = ['customer', 'rented', 'max', 'lost'];
  
  constructor() { }
  ngOnInit(): void {}

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: RentalReportItem): RentalReportItem[]|null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }
}
