export class DispatchConstants {
    static TYPE_DELIVERY        = 'delivery';
    static TYPE_PICKUP          = 'pickup';

    static ACTION_DELIVER       = 'Deliver';
    static ACTION_DELIVERED     = 'Delivered';
    static ACTION_DELIVERY      = 'Delivery';
    static ACTION_RETRIEVE      = 'Retrieve';
    static ACTION_RETRIEVED     = 'Retrieved';
    static ACTION_RETRIEVAL     = 'Retrieval';

    static STATE_DRAFT          = 'draft';
    static STATE_REQUESTED      = 'requested';
    static STATE_DISPATCHED     = 'dispatched';
    static STATE_ACTIONED       = 'actioned';
    static STATE_COMPLETED      = 'completed';

    static TASK_WORKING         = 'working';
    static TASK_TRAVEL          = 'travel';
    static TASK_END             = 'end';

    static PRICING_UNIT         = 'unit';

    // as const used to allow us to use this in an union type.
    static RENTAL_REQUESTS = 'rental_requests' as const;
    static SERVICE_REQUESTS = 'services' as const;
}
