import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'document-tab-upload',
  templateUrl: './tab-upload.component.html',
})
export class TabUploadComponent implements OnInit {

  @Input() uploader: FileUploader;

  @Output() fileAdded = new EventEmitter<void>();
  @Output() fileRemoved = new EventEmitter<FileItem>();

  isOverDropzone = false;

  constructor() { }
  ngOnInit(): void { }

  onFileSelected()
  {
    this.fileAdded.emit();
  }

  onFileRemoved(item: FileItem)
  {
    this.fileRemoved.emit(item);
  }

}
