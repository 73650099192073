<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="this.save()" fxLayout="column" novalidate>
    <mat-toolbar>
        <h2>Customer Signature</h2>
    </mat-toolbar>

    <mat-dialog-content class="dialog-padding signature-capture__section">
        <!-- SIGNATURE FORM STEP -->
        <div fxLayout="row" *ngIf="this.isSignatureFormStep">
            <mat-form-field fxFlex>
                <mat-label>Name</mat-label>
                <input type="text" 
                    [(ngModel)]="this.signature.name" 
                    name="nameField" 
                    #nameField="ngModel"
                    required 
                    maxlength="255"
                    matInput>
                <mat-error *ngIf="nameField.hasError('required')">A name is required</mat-error>
                <mat-hint align="end">{{ this.signature.name?.length || 0 }} / 255</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="column" *ngIf="this.isSignatureFormStep">
            <img class="signature__image" [src]="this.signature.captured || this.EMPTY_IMAGE">
            <span class="mat-caption signature__label">Customer Signature</span>
        </div>

        <!-- SIGNATURE CAP STEP -->
        <div  class="signature-pad-container" #sigPadContainer *ngIf="!this.isSignatureFormStep">
            <button class="signature-capture__clear" 
                type="button"
                mat-icon-button 
                color="primary" 
                (click)="this.onClear()">
                <mat-icon>backspace</mat-icon>
            </button>
            <signature-pad></signature-pad>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row">
        <button mat-raised-button
            type="button"
            *ngIf="!this.original"
            (click)="this.onRecapture()"
            [fxShow]="this.isSignatureFormStep">
            Recapture
        </button>
        <button mat-icon-button 
            *ngIf="this.original" 
            (click)="this.remove()"
            [fxShow]="this.isSignatureFormStep" 
            [disabled]="this.loading">
            <mat-icon color="primary">delete</mat-icon>
        </button>

        <span fxFlex></span>

        <mat-progress-spinner *ngIf="this.loading" mode="indeterminate" diameter="36"></mat-progress-spinner>

        <button mat-raised-button mat-dialog-close>Cancel</button>

        <button mat-raised-button
            type="button"
            color="accent"
            [fxShow]="!this.isSignatureFormStep" 
            (click)="this.onNext()" >
            Next
        </button>

        <button type="submit" 
            mat-raised-button 
            color="accent" 
            [fxShow]="this.isSignatureFormStep"
            [disabled]="this.loading">
            Save
        </button>
    </mat-dialog-actions>
</form>
    