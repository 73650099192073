import { Ng2StateDeclaration } from "@uirouter/angular";
import { UpcomingDispatchesComponent } from "./upcoming-dispatches.component";

export const UpcomingDispatchesState: Ng2StateDeclaration = {
  name: 'protected.upcoming-dispatches',
  url: '/upcoming-dispatches',
  component: UpcomingDispatchesComponent,
  data: {
    pageTitle: 'Upcoming Dispatches',
    requiresAuth: true,
  },
}
