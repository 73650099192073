import { Injectable } from '@angular/core';
import { BackendClientService } from '../network/backend-client.service';
import { HttpResponse } from '../network/http-response';

@Injectable({
  providedIn: 'root'
})
export class AuthRouteMapService {

  constructor(private backendClient: BackendClientService) {}

  async retrieveAccessToken(): Promise<HttpResponse> {
    return this.backendClient.request()
      .path('/auth')
      .get();
  }

  async requestAccessToken(username: string, password: string): Promise<HttpResponse> {
    return this.backendClient.request()
      .path('/auth')
      .data({username, password})
      .post();
  }

  async refreshAccessToken(): Promise<HttpResponse> {
    return this.backendClient.request()
      .path('/auth/refresh')
      .post();
  }

  async revokeAccessToken(): Promise<HttpResponse> {
    return this.backendClient.request()
      .path('/auth')
      .delete();
  }
}
