import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad';
import { Signature } from '@beaconlite/models';
import { DialogNotificationService } from '../../../services/notification/dialog-notification.service';
import { SignatureCaptureEditorData } from './signature-capture-editor-data.interface';

@Component({
  selector: 'app-signature-capture-editor',
  templateUrl: './signature-capture-editor.component.html',
  styleUrls: ['./signature-capture-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignatureCaptureEditorComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @ViewChild('sigPadContainer') sigPadContainer: ElementRef;
  @ViewChild('editorForm') editorForm: NgForm;

  readonly EMPTY_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjgAAADcCAQAAADXNhPAAAACIklEQVR42u3UIQEAAAzDsM+/6UsYG0okFDQHMBIJAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcCQADAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDkQAwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAegeayZAN3dLgwnAAAAAElFTkSuQmCC';

  original: Signature = this.data.original;
  relatedModel = this.data.relatedModel;
  onUpdate = this.data.onSignatureUpdate;
  onRemove = this.data.onSignatureRemove;

  loading = false;
  isSignatureFormStep = false;

  signature = new Signature({});

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: SignatureCaptureEditorData,
    public dialogRef: MatDialogRef<SignatureCaptureEditorComponent>,
    protected dialogNotification: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    if (!! this.original)
    {
      this.isSignatureFormStep = true;
  
      this.signature = this.original.copy();
      this.signature.captured = await this.signature.attachment.getUrl();
    }
  }

  ngAfterViewInit() 
  {
    if (! this.original)
    {
      this._onWindowResize();
    }
    else
    {
      if (! this.isSignatureFormStep)
      {
        this.signaturePad.set('minWidth', 1);
      }
    }
  }
  
  @HostListener('window:resize', ['$event'])
  protected _onWindowResize()
  {
    setTimeout(() => 
    {
      this.signaturePad.set('canvasWidth', this.sigPadContainer.nativeElement.clientWidth); // set szimek/signature_pad options at runtime;
      const canvas = document.querySelector('signature-pad canvas') as any;

      if (! canvas) return;

      const context = canvas.getContext("2d");

      if (window.matchMedia("(orientation: portrait)").matches &&
          window.matchMedia("(max-width: 767px").matches) 
      {
          context.rotate(90 * Math.PI / 180);
          context.translate(0,-canvas.clientWidth);
      }
      else 
      {
          // Reset transformation matrix to the identity matrix
          context.setTransform(1, 0, 0, 1, 0, 0);
      }
    })
  }

  onNext(): void
  {
    this.isSignatureFormStep = true;
    this.signature.captured = this.signaturePad.toDataURL();
  }

  onRecapture(): void
  {
    this.isSignatureFormStep = false;
    this._onWindowResize();
  }

  onClear(): void
  {
    this.signaturePad.clear();
  }

  async save(): Promise<void>
  { 
    if (this.editorForm.invalid) return;

    this.loading = true;

    const deferred: Promise<Signature> = this.signature?.exists()
      ? this.signature.save()
      : this.relatedModel.sign(this.signature);

    try
    {
      const signature = await deferred;
      await this.onUpdate(signature);
      this.dialogRef.close();
    }
    finally
    {
      this.loading = false;
    }
  }

  async remove(): Promise<void>
  {
    this.loading = true;

    try
    {
      if (await this.dialogNotification.removeConfirm())
      {
        await this.signature.discard();
        await this.onRemove();
        this.dialogRef.close();
      }
    }
    finally
    {
      this.loading = false;
    }
  }
}
