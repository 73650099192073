import { Ng2StateDeclaration } from '@uirouter/angular';
import { EmployeeIndexComponent } from './employee-index.component';


export const EmployeeIndexState: Ng2StateDeclaration = {
  name: 'protected.employees',
  url: '/employees',
  component: EmployeeIndexComponent,
  data: { 
    pageTitle: 'Employee Index', 
    requiresAuth: true,
  },
}