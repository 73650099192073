import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReasonEditorData } from './reason-editor-data.interface';

@Component({
  selector: 'app-reason-editor',
  templateUrl: './reason-editor.component.html',
})
export class ReasonEditorComponent implements OnInit {

  reason: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ReasonEditorData,
    public dialogRef: MatDialogRef<ReasonEditorComponent>,
  ) { }

  ngOnInit(): void {
    this.reason = this.data?.reason;
  }

  isLongEnough(): boolean
  {
    return this.reason?.length >= 10;
  }

  async onAccept(): Promise<any>
  {
    return this.dialogRef.close(this.reason)
  }
}
