import { Component, OnInit } from '@angular/core';
import { WorkOrderCollection } from '@beaconlite/models/collections';
import * as moment from 'moment';
import { WorkOrder } from '@beaconlite/models';
import { LockVaultService } from '../../../../services/lock-vault.service';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';
import { InvoiceEditorService } from '@beaconlite/components/editors/invoice-editor/invoice-editor.service';
import { prefs } from "@beaconlite/services/preferences.service";

@Component({
  selector: 'app-invoice-schedule',
  templateUrl: './invoice-schedule.component.html',
  styleUrls: ['./invoice-schedule.component.scss'],
})
export class InvoiceScheduleComponent implements OnInit {

  customEndDate: Date = null;
  minimumCustomEndDate: Date = null;
  invoiceGracePeriod: number;

  // Scheduled-mode
  scheduled_work_orders = [];

  collection = 
    new WorkOrderCollection({
      params: {
          state:'invoiceable',
          order_by:'next_invoice'
        }
      });
  
  workOrders = new CollectionDataSource<WorkOrder>(this.collection);
  issueDate = moment().startOf('day').toDate();

  scheduleTableColumns = ['work-order', 'client', 'last-invoice', 'next-invoice', 'lock'];
            

  constructor(
    protected lockVault: LockVaultService,
    protected invoiceEditor: InvoiceEditorService,
    ) { }

  async ngOnInit(): Promise<void> 
  {
    this.onRefreshList();
    this.invoiceGracePeriod = await prefs('invoice.grace_period');
  }

  onRefreshList()
  {
    // TODO Jira BL-758: actually pass params to search.
    this.collection.search();
  }

  // Proper dataSource viewChange support is not yet implmented in mat-table
  // This is a workaround infinite scroll solution
  // Workaround: https://github.com/angular/components/issues/9858
  // Issue: https://github.com/angular/components/blob/21bb4d5a6a60b9f5883121341295506db77a1154/src/cdk/table/table.ts#L467
  onTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight 
    const tableScrollHeight = e.target.scrollHeight
    const scrollLocation = e.target.scrollTop;
    
    // If the user has scrolled within the buffer of the bottom, add more data
    const buffer = 300;
    const limit = tableScrollHeight - tableViewHeight - buffer;    
    if (scrollLocation > limit) {
      this.workOrders.loadMore()
    }
  }

  async previewScheduledInvoice(workOrder: WorkOrder): Promise<void>
  {
    await this.openInvoiceDialog(workOrder)
    this.onRefreshList();
  }

  async openInvoiceDialog(workOrder: WorkOrder)
  {
    let endDate = null
    let issueDate = null;

    if ( moment.isDate(this.customEndDate) )
    {
      endDate = this.customEndDate
    }

    if ( moment.isDate(this.issueDate) )
    {
      issueDate = this.issueDate
    }
    
    const locals = {
        workOrder,
        presets: {
            issueDate,
            endDate
        },
        isInvoiceType: true,
    }
    
    await this.invoiceEditor.open(locals)
  }

  hasResourceLock(workOrder: WorkOrder): boolean
  {
    return this.lockVault.hasResourceLock(workOrder)
  }

  getLockOwner(workOrder: WorkOrder): string
  {
    return this.lockVault.getLockOwner(workOrder);
  }

}
