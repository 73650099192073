import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SnackbarNotificationComponent } from './services/notification/snackbar-notification.service';
import { DialogErrorNotificationComponent, DialogWarningNotificationComponent, DialogNoticeNotificationComponent } from './services/notification/dialog-notification.service';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  declarations: [
    SnackbarNotificationComponent,
    DialogWarningNotificationComponent,
    DialogErrorNotificationComponent,
    DialogNoticeNotificationComponent
  ],
})
export class NotificationModule {}
