import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DocumentPreviewData } from "./document-preview-data.interface";
import { DocumentPreviewComponent } from "./document-preview.component";

@Injectable({
    providedIn: 'root'
})
export class DocumentPreviewService {

    constructor(protected dialog: MatDialog) {}

    async open(data: DocumentPreviewData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            maxHeight: '90vh',
            height: '100%',
            width: '100%',
            panelClass: ['bl-dialog', 'document-preview-dialog'],
            data
          }

        return this.dialog.open(DocumentPreviewComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
