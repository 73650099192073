import { Component, DoCheck, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';

@Component({
  selector: 'app-reports-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss']
})
export class ReportsViewComponent implements OnInit {

  // Default
  // reportType = 'active-item';

  constructor(
    protected $state: StateService,
    protected $stateGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void 
  {
    let stateType = null
      
    // Set the report type based on the current state
    switch(this.$stateGlobals.current.name)
    {
        case 'protected.reports.lost-item':
            stateType = 'lost-item'
            break
        case 'protected.reports.active-item':
            stateType = 'active-item'
            break
        case 'protected.reports.rental-item':
            stateType = 'rental-item'
            break
        case 'protected.reports.service-hours':
            stateType = 'service-hours'
            break
        case 'protected.reports.invoiced-items':
            stateType = 'invoiced-items'
            break
        case 'protected.reports.dispatched-item':
          stateType = 'dispatched-item'
          break
          case 'protected.reports.dispatched-request':
            stateType = 'dispatched-request'
            break
        default:
            stateType = 'active-item';
    }

    this.$state.go(`protected.reports.${stateType}`);
  }

  // ngDoCheck(): void
  // {
  //     let stateType = null
      
  //     // Set the report type based on the current state
  //     switch(this.$stateGlobals.current.name)
  //     {
  //         case 'protected.reports.lost-item':
  //             stateType = 'lost-item'
  //             break
  //         case 'protected.reports.active-item':
  //             stateType = 'active-item'
  //             break
  //         case 'protected.reports.rental-item':
  //             stateType = 'rental-item'
  //             break
  //         case 'protected.reports.service-hours':
  //             stateType = 'service-hours'
  //             break
  //         default:
  //             stateType = null;
  //     }

  //     const type = this.reportType || stateType || 'active-item'
 
  //     if( type !== null && // Not nothing
  //         type != this.reportType ) // Has changed
  //     {    
  //       this.onTypeChanged(type);
  //     }
  // }

  // onTypeChanged(type: string): void
  // {
  //   this.reportType = type || this.reportType
  //   this.$state.go(`protected.reports.${this.reportType}`);
  // }

}
