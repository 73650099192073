import * as moment from 'moment';
import * as pluralize from 'pluralize';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client, DispatchedService, Issue, Service } from '@beaconlite/models';
import { ServiceQuantitiesEditorData } from './service-quantities-editor-data.interface';
import { Helpers as $helpers } from '../../../../../../services/helpers.service';
import { SyncErrorStateMatcher } from '@beaconlite/utilities/sync-error-state-matcher.utility';
import { prefs } from '@beaconlite/services/preferences.service';

@Component( {
  selector: 'app-service-quantities-editor',
  templateUrl: './service-quantities-editor.component.html',
  styleUrls: [ './service-quantities-editor.component.scss' ]
} )
export class ServiceQuantitiesEditorComponent implements OnInit
{

  @ViewChild( 'editorForm' ) editorForm: NgForm;

  original = this.data.original;
  workOrder = this.data.workOrder;
  dispatch = this.data.dispatch;
  onUpdate = this.data.onDispatchUpdate;

  saving = false;
  isReview = false; // TODO: still need this?
  hasWorksheet = false;
  useWorksheet = false;
  allowTimeOverride = false;
  isOvertimeEnabled = true;
  qtyInputStep = Service.QTY_INPUT_STEP_UNIT
  qtyStepError = Service.QTY_STEP_ERROR_UNIT

  service: DispatchedService;
  client: Client;

  syncErrorStateMatcher = new SyncErrorStateMatcher();

  constructor(
    @Inject( MAT_DIALOG_DATA ) protected data: ServiceQuantitiesEditorData,
    public dialogRef: MatDialogRef<ServiceQuantitiesEditorComponent>,
  ) { }

  ngOnInit(): void 
  {
    this.service = this.original.copy();

    if ( this.original.actioned_quantity !== null ||
      this.original.actioned_started_at !== null )
    {
      this.hasWorksheet = true;
      this.useWorksheet = true;
    }

    this.client = this.workOrder.client

    this.onUseWorksheetChanged();

    // Pre-load preferenes
    prefs();
  }

  getPluralUnit()
  {
    return pluralize( this.service.source.unit_long );
  }

  onUseWorksheetChanged()
  {
    let baseRequest: any = this.data.original.source;

    if ( this.useWorksheet === true )
    {
      baseRequest = this.data.original;
    }

    this.allowTimeOverride = baseRequest.time_override;
    this.service.actioned_started_at = this.useWorksheet ? baseRequest.actioned_started_at : baseRequest.started_at;
    this.service.actioned_ended_at = this.useWorksheet ? baseRequest.actioned_ended_at : baseRequest.ended_at;
    this.service.regular_time = baseRequest.regular_time;
    this.service.over_time = baseRequest.over_time;
    this.service.travel_time = baseRequest.travel_time;
  }

  onTimeOverrideChanged()
  {
    if ( this.allowTimeOverride === false )
    {
      this.calculateTimes();
    }
  }

  // TODO: Create component for Date range bucket paradigm
  async calculateTimes(): Promise<void>
  {
    this._validateServiceDates();

    if ( this.allowTimeOverride === true ) { return; }

    let overTimeSeconds = 0;
    let regularTimeSeconds = 0;

    if ( !!this.service.actioned_started_at && !!this.service.actioned_ended_at )
    {
      let startedAt = moment.unix( this.service.actioned_started_at );
      let endedAt = moment.unix( this.service.actioned_ended_at );

      regularTimeSeconds = endedAt.diff( startedAt, 'seconds' );

      if ( this.isOvertimeEnabled )
      {
        let overtimeStart = await prefs( 'company.business_hours.end' );
        let overtimeEnd = await prefs( 'company.business_hours.start' );

        if ( this.client.overtime_start )
        {
          overtimeStart = $helpers.secondsFromMidnight( this.client.overtime_start );
          overtimeEnd = $helpers.secondsFromMidnight( this.client.overtime_end );
        }

        // Calculate overtime and deduce working time 
        overTimeSeconds = $helpers.calculateOvertime( overtimeStart, overtimeEnd, startedAt, endedAt );
        regularTimeSeconds -= overTimeSeconds;
      }
    }

    this.service.over_time = overTimeSeconds;
    this.service.regular_time = regularTimeSeconds;
  }

  hasIssueRestraints(): boolean
  {
    return this.workOrder.hasUnresolvedIssues( Issue.SEVERITY_QUARANTINE )
  }

  _validateServiceDates()
  {
    if ( this.service.actioned_ended_at &&
      this.service.actioned_ended_at <= this.service.actioned_started_at )
    {
      this.editorForm.controls.endDateField.markAllAsTouched();
      this.editorForm.controls.endDateField.setErrors( { minDate: true } );
      this.editorForm.controls.endTimeField.markAllAsTouched();
      this.editorForm.controls.endTimeField.setErrors( { minDate: true } );
      return;
    }

    this.editorForm.controls.endDateField.setErrors( null );
    this.editorForm.controls.endTimeField.setErrors( null );
  }

  async save(): Promise<void | boolean>
  {
    if ( this.editorForm.invalid ) return false;

    this.saving = true;

    try
    {
      await this.original.review( this.service );
      await this.onUpdate();
      this.dialogRef.close();
    }
    finally
    {
      this.saving = false;
    }
  }
}
