import { BaseModel } from "./Base.model";
import { dto } from './mixins/Dto.decorators'

export class Setting extends BaseModel {

    constructor(attributes: object) {
        super();
        this.init(attributes);
    }

    @dto() id?: string = null;
    @dto() key?: string = null;
    @dto() value?: any = null;
}