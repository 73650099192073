import { Ng2StateDeclaration } from '@uirouter/angular';
import { ProtectedViewComponent } from './protected-view.component';

export const ProtectedViewState: Ng2StateDeclaration = {
  abstract: true,
  name: 'protected',
  component: ProtectedViewComponent,
  data: { 
    requiresAuth: true 
  }
}
