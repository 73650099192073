import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChargeDefinition, Client, RentalDefinition, ServiceDefinition, Variant, VariantRental, VariantService } from '@beaconlite/models';
import { ChargeDefinitionCollection, ClientCollection, VariantRentalCollection, VariantServiceCollection } from '@beaconlite/models/collections';
import { ReportFilter } from '../report-filter.interface';
import { ReportFilterEditorData } from './report-filter-editor-data.interface';

@Component({
  selector: 'app-report-filter-editor',
  templateUrl: './report-filter-editor.component.html',
})
export class ReportFilterEditorComponent implements OnInit {

  @ViewChild('editorForm') editorForm: NgForm;

  filterTypes = this.data.filterTypes;
  onAddition = this.data.onAddition; 

  loading = false;
  searchText: string = null;
  filterBy = 'rentals';

  promisedRentals: Promise<VariantRental[]>;
  promisedClients: Promise<Client[]>;
  promisedServices: Promise<VariantService[]>;
  promisedCharges: Promise<ChargeDefinition[]>;

  itemTypes = [
    {id: 'charge', name: 'Charges'},
    {id: 'rental', name: 'Rentals'},
    {id: 'service', name: 'Services'},
  ];

  rentalSearchText: string;
  clientSearchText: string;
  serviceSearchText: string;
  chargeSearchText: string;
  
  selectedRental: RentalDefinition = null;
  selectedClient: Client = null;
  selectedService: ServiceDefinition = null;
  selectedCharge: ChargeDefinition = null;
  selectedItemType: any;
  

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ReportFilterEditorData,
    public dialogRef: MatDialogRef<ReportFilterEditorComponent>,
  ) { }

  ngOnInit()
  {
    this.filterBy = this.filterTypes[0];
  }

  getTypeDescriptor(type: string): string 
  {
    let descriptor = null;

    switch(type)
    {
      case 'services':
          descriptor = "Service Items";
          break
      case 'rentals':
          descriptor = "Rental Items";
          break
      case 'clients':
          descriptor = "Clients";
          break
      case 'charges':
          descriptor = "Charge Items";
          break
      case 'item_types':
          descriptor = "Item Type";
          break
    }

    return descriptor;
  }

  hasFilterType(type: string): boolean
  {
    return this.filterTypes.indexOf(type) >= 0;
  }

  onQueryClients(): void
  {
    this.promisedClients = (new ClientCollection()).all({
      order: 'asc',
      name: this.clientSearchText,
    });
  }

  onQueryRentals(): void
  {
    this.promisedRentals = (new VariantRentalCollection()).all({
      order: 'asc',
      name: this.rentalSearchText,
    });
  }

  onQueryServices(): void
  {
    this.promisedServices = (new VariantServiceCollection()).all({
      order: 'asc',
      name: this.serviceSearchText,
    });
  }

  onQueryCharges(): void
  {
    this.promisedCharges = (new ChargeDefinitionCollection()).all({
      order: 'asc',
      name: this.chargeSearchText,
    });
  }

  displaySelected(item: Variant|ChargeDefinition|Client): string
  {
    if (item instanceof ChargeDefinition)
    {
      return item?.label || null;
    }

    if (item instanceof Client)
    {
      return item?.name || null;
    }

    return (item as Variant)?.display_name || null
  }

  onSave()
  {
    if (this.editorForm.invalid) return false;

    let filter: ReportFilter = {id: null,type: null,name:null};

    switch(this.filterBy)
    {
      case 'services':
          filter.type = 'service'
          filter.id = this.selectedService.id
          filter.name = this.selectedService.name
          break
      case 'rentals':
          filter.type = 'rental'
          filter.id = this.selectedRental.id
          filter.name = this.selectedRental.name
          break
      case 'clients':
          filter.type = 'client'
          filter.id = this.selectedClient.id
          filter.name = this.selectedClient.name
          break
      case 'charges':
          filter.type = 'charge'
          filter.id = this.selectedCharge.id
          filter.name = this.selectedCharge.label
          break
      case 'item_types':
          filter.type = 'item_type'
          filter.id = this.selectedItemType.id
          filter.name = this.selectedItemType.name
          break
    }

    this.onAddition(filter);
    this.dialogRef.close();
  }
}
