import * as d3 from 'd3';
import * as moment from 'moment';
import { Component, ElementRef, Input, Renderer2} from '@angular/core';
import { ScaleBand, ScaleTime } from 'd3';
import { GraphBase } from '../graph-base/graph-base';
import { UnixTimestamp } from '@beaconlite/types';

@Component({ template: '' })
export abstract class GanttBase<T> extends GraphBase
{
    @Input() yHeight = 60 // Equal to the height of a list item
    @Input() sourceData: any[];

    // @ViewChild()
    abstract graphContainer: ElementRef<any> 

    //------------------------------------- Configurable Values -------------------------------------//
    // Formatting 
    protected hourFormat = d3.timeFormat('%I:%M %p');
    protected dateFormat = d3.timeFormat('%e %b, %Y');

    // Ticks per day
    protected _scale = [1, 2, 4, 6, 8, 10, 12];
    protected _scaleIndex = 2;

    protected _tickWidth = 150;
    protected _xAxisHeight = 50;
    
    protected _radius = 5; // Radius used for the band bookends
    protected _desiredBandWidth = 38; // Thickness of the band

    // Matches the padding at the start of the virtual repeat container
    // Used as an offset to line up the band in middle of the list item
    protected _indexListOffset = 4; 
    // Set bottom margin to match one list item to match the blank entry at the bottom of the virtual repeater)
    protected _margin = {top: 0, right: 0, bottom: this.yHeight, left: 0};

    // Each chunk should be at least 3 times wider than the viewport
    protected _minChunkWidth = window.innerWidth * 3;
    protected _daysPerChunk = 7;
    protected _chunkWidth = 0;
    //----------------------------------- End Configurable Values ------------------------------------//
    

    protected _cachedData = new Map<UnixTimestamp, T>();
    protected _rendered_start = moment().subtract(10, 'days').startOf('day');
    protected _rendered_end = moment().add(10, 'days').endOf('day');
    
    // Render state flags
    protected _isLoadingMore = false;

    // Computed Render Values
    protected _width            = 0;
    protected _height           = 0;
    protected _viewWidth        = 0;
    protected _viewHeight       = 0;
    protected _contentWidth     = 0;
    protected _contentHeight    = 0;
    protected _xFunction: ScaleTime<number, number, never>;
    protected _yFunction: ScaleBand<string>;


    constructor(
        protected renderer: Renderer2
    ) { super(renderer) }


    protected _xInvert(position: number): Date
    {
        return this._xFunction.invert(position);
    }

    protected _scrollTo(date: Date): void
    {
        this.graphContainer.nativeElement.scrollLeft = this._xFunction( date ) - (this._viewWidth / 2);
    }

    protected get ticksPerDay(): number {
        return this._scale[this._scaleIndex];
    }

    protected get ticks(): d3.TimeInterval
    {   
        return d3.timeHour.every(24 / this.ticksPerDay);
    }

    canScaleUp(): boolean
    {
        return this._scaleIndex === this._scale.length-1 ? false : true;
    }

    canScaleDown(): boolean
    {
        return this._scaleIndex === 0 ? false : true;
    }

    scaleUp(): void
    {
        if ( !this.canScaleUp() ){ return }
        this._scaleIndex++;
        this.onRescale();
    }

    scaleDown(): void
    {
        if ( !this.canScaleDown() ){ return }
        this._scaleIndex--;
        this.onRescale();
    }

    async onRescale(date?: Date): Promise<void>
    {
        // Reset the value affected by scale
        this.primeScaleValues();

        if ( !date )
        {
            // Figure out the center date
            date = this._xInvert( this.graphContainer.nativeElement.scrollLeft + (this._viewWidth / 2) );
        }

        // Scale affects chunk size. Re-initialize them
        await this.loadInitialChunks(date)

        this.render()
        this._scrollTo(date);
    }

    protected primeScaleValues()
    {
        const dayWidth = this._tickWidth * this.ticksPerDay;

        // Min 7 days per render chunk
        this._daysPerChunk = Math.max( Math.ceil( this._minChunkWidth / dayWidth ), 7 );

        this._chunkWidth = this._daysPerChunk * dayWidth;
    }


    onResize(): void
    {
        // Update the minimum chunk width
        this._minChunkWidth = window.innerWidth * 3;

        const boundingRect = this.graphContainer.nativeElement.getBoundingClientRect();
        this._viewWidth = boundingRect.width;
        this._viewHeight = boundingRect.height;
    }

    onGraphScroll( ...args: any[] ): void
    {
        return;
    }

    async loadInitialChunks(date: Date): Promise<T[]>
    {
        // Reset the cached data
        this._cachedData = new Map<UnixTimestamp, T>();

        // Offset to have 'today' near the middle of a chunk
        const startOffset = Math.floor( this._daysPerChunk / 2 );
        this._rendered_start = moment(date).startOf('day').subtract(this._daysPerChunk + startOffset, 'days');

        const promises: Promise<T>[] = [];
        for ( let i = 0; i < 3; i++ )
        {
            const start = moment(this._rendered_start).add(i * this._daysPerChunk, 'days');
            const end = moment(start).add(this._daysPerChunk, 'days').subtract(1, 'seconds');

            promises.push( this.fetchChunk( start.unix(), end.unix() ) );

            if ( i == 2 )
            {
                this._rendered_end = end;
            }
        }

        return await Promise.all(promises);
    }


    abstract fetchChunk(start:UnixTimestamp, end: UnixTimestamp): Promise<T>


    protected checkAndUpdateLoadedChunks() 
    {
        const scrollLeft = this.graphContainer.nativeElement.scrollLeft;
        const threshold = window.innerWidth * 2
        const remainingScroll = this._width - scrollLeft - this._viewWidth
        
        let shouldAdvance: boolean;
        if ( remainingScroll <= threshold )
        {
            shouldAdvance = true;
        }
        else if ( scrollLeft <= threshold )
        {
            shouldAdvance = false;
        }
        else
        {
            return;
        }

        this.updateLoadedChunks(shouldAdvance)
    }


    protected async updateLoadedChunks(shouldAdvance: boolean): Promise<void>
    {
        if ( this._isLoadingMore ) { return; }

        this._isLoadingMore = true;

        let newChunkStart: moment.Moment;
        let newChunkEnd: moment.Moment;

        // Calculate the boundaries for the incoming chunk
        if ( shouldAdvance ) 
        {
            newChunkStart = moment(this._rendered_end).add(1, 'seconds');
            newChunkEnd = moment(this._rendered_end).add(this._daysPerChunk, 'days');
        }
        else 
        {
            newChunkStart = moment(this._rendered_start).subtract(this._daysPerChunk, 'days')
            newChunkEnd = moment(this._rendered_start).subtract(1, 'seconds');
        }

        //_getScheduledDispatches
        await this.fetchChunk( newChunkStart.unix(), newChunkEnd.unix() )
        
        // Update the render range
        if ( shouldAdvance )  
        {
            this._rendered_start = this._rendered_start.add(this._daysPerChunk, 'days'); 
            this._rendered_end = newChunkEnd;
        }
        else 
        {
            this._rendered_start = newChunkStart; 
            this._rendered_end = this._rendered_end.subtract(this._daysPerChunk, 'days');
        }
 
        this.render();

        const offsetAdjustment = (shouldAdvance) ? -this._chunkWidth : this._chunkWidth;
        this.graphContainer.nativeElement.scrollLeft += offsetAdjustment;

        this._isLoadingMore = false
    }

}
