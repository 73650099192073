<mat-progress-bar mode="indeterminate" [fxShow]="this.loading"></mat-progress-bar>

<mat-card class="medium-card item-definition__card" fxLayout="column" *ngIf="!this.loading">
    <div class="disabled-banner" fxLayout="row" fxLayoutAlign="space-between center" [fxShow]="this.original.disabled">
        This item cannot be added to rental requests.
    </div>
    <mat-card-title fxFlex fxLayoutAlign="space-between center">

        <div class="thumbnail-container" *ngIf="!this.original.variants_enabled">
            <img *ngIf="this.original.thumbnail !== null" 
                [src]="this.original.thumbnail.small.url">
            <mat-icon *ngIf="this.original.thumbnail === null" color="primary">image</mat-icon>
        </div>

        <span class="mat-headline">{{ this.original.name }}</span>
        <span class="mat-caption rental-state-label" *ngIf="this.original.discarded">
            <span>Discarded</span>
        </span>

        <div fxLayoutAlign="center center">
            <button mat-icon-button [matMenuTriggerFor]="mainMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #mainMenu="matMenu">
                <button mat-menu-item
                    *ngIf="!this.original.discarded && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.edit()">
                    <mat-icon>edit</mat-icon>
                    Edit
                </button>
                <button mat-icon-button
                    *ngIf="this.original.discarded"
                    [disabled]="this.loading"
                    (click)="this.restore()"
                    color="primary">
                    <mat-icon>restore</mat-icon>
                    Restore
                </button>
                <button mat-menu-item
                    *ngIf="!this.original.discarded && this.original.exists()"
                    [disabled]="this.loading || !this.original.exists()"
                    (click)="this.discard()">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>
            </mat-menu>
        </div>
    </mat-card-title>
    <div class="item-definition__card-inner">
        <mat-divider></mat-divider>
        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="100" layoutPadding>
                <span class="mat-caption">Description</span>
                <p class="mat-body-1">{{ this.original.description }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Quantity</span>
                <p class="mat-body-1">{{ this.original.quantity }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Code</span>
                <p class="mat-body-1">{{ this.original.code }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Tags</span>
                <p class="mat-body-1">{{ this.original.tags }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" *ngIf="this.departments?.length > 1">
            <div fxLayout="column" fxFlex="32" layoutPadding>
                <span class="mat-caption">Default Department</span>
                <p class="mat-body-1">{{ this.departmentName }}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="row" class="item-definition__row">
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Default Pricing</span>
                <p class="mat-body-1">{{ this.original.getPriceTypeLabel(this.original.default_pricing_type) }}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">{{ this.original.default_pricing_type === this.PRICING_FLAT ? '' : 'Daily ' }}Rate</span>
                <p class="mat-body-1">{{ this.original.daily_rate | currencyFilter}}</p>
            </div>
            <div fxLayout="column" fxFlex="25" layoutPadding>
                <span class="mat-caption">Minimum Charge</span>
                <p class="mat-body-1">{{ this.original.minimum_charge | currencyFilter}}</p>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="column" class="item-definition__row" [fxShow]="this.original.default_pricing_type === this.PRICING_RATED" >
            <div fxLayout="row" layoutPadding>
                <h3 class="mat-subheading-2 rental-data-group-title">Discounts</h3>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="25" layoutPadding>
                    <span class="mat-caption">Weekly Percent Discount</span>
                    <p class="mat-body-1">{{ this.original.weekly_discount | percentFilter }}</p>
                </div>
                <div fxLayout="column" fxFlex="25" layoutPadding>
                    <span class="mat-caption">Weekly Dollar Rates</span>
                    <p class="mat-body-1">
                        {{ this.calculateRate(this.original.weekly_discount, 1) | currencyFilter }} /day<br>
                        {{ this.calculateRate(this.original.weekly_discount, this.weekThreshold) | currencyFilter }} /week ({{ this.weekThreshold }} days)
                    </p>
                </div>
                <div fxLayout="column" fxFlex="25" layoutPadding>
                    <span class="mat-caption">Monthly Percent Discount</span>
                    <p class="mat-body-1">{{ this.original.monthly_discount | percentFilter }}</p>
                </div>
                <div fxLayout="column" fxFlex="25" layoutPadding>
                    <span class="mat-caption">Monthly Dollar Rates</span>
                    <p class="mat-body-1">
                        {{ this.calculateRate(this.original.monthly_discount, 1) | currencyFilter }} /day<br>
                        {{ this.calculateRate(this.original.monthly_discount, this.monthThreshold) | currencyFilter }} /month ({{ this.monthThreshold }} days)
                    </p>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        
        <div fxLayout="column" class="item-definition__row" [fxShow]="this.original.default_pricing_type === this.PRICING_RATED" >
            <div fxLayout="row" layoutPadding>
                <h3 class="mat-subheading-2 rental-data-group-title">Replacement Cost</h3>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="25" layoutPadding>
                    <span class="mat-caption">Retail Cost</span>
                    <p class="mat-body-1">{{ this.original.retail_cost | currencyFilter }}</p>
                </div>
                <div fxLayout="column" fxFlex="25" layoutPadding>
                    <span class="mat-caption">Discount Percent</span>
                    <p class="mat-body-1">{{ this.original.replacement_discount | percentFilter }}</p>
                </div>
                <div fxLayout="column" fxFlex="25" layoutPadding>
                    <span class="mat-caption">Total Replacement Cost</span>
                    <p class="mat-body-1">{{ this.original.retail_cost * (1 - this.original.replacement_discount) | currencyFilter}}</p>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="25" layoutPadding >
                    <span class="mat-caption">Rental Offset</span>
                    <p class="mat-body-1">{{ this.original.replacement_rental_offset | percentFilter}}</p>
                    <span class="mat-caption">The rental offset uses a percentage of the total rental cost and subtracts it from the replacement cost.</span>
                </div>
            </div>
        </div>
    </div>
</mat-card>
<ng-container *ngIf="!this.loading">
    <app-variant-configuration *ngIf="this.original.exists()"
        [itemDefinition]="this.original"
        [onVariantConfigUpdate]="this.onVariantConfigUpdate">
    </app-variant-configuration>

    <app-variant-prop-list *ngIf="this.original.exists() && this.original.variants_enabled"
        [itemDefinition]="this.original"
        [onPropUpdate]="this.onPropUpdate"
        [onPropRemove]="this.onPropRemove">
    </app-variant-prop-list>

    <app-variant-list *ngIf="this.original.exists() && this.original.variants_enabled"
        [itemDefinition]="this.original"
        [onVariantUpdate]="this.onVariantUpdate"
        [onVariantRemove]="this.onVariantRemove">
    </app-variant-list>
</ng-container>
