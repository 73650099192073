<mat-toolbar fxLayoutAlign="space-between center" color="primary">
    <span class="mat-headline">{{ this.invoice.formatted_serial_id }}</span>

    <span fxFlex></span>

    <div fxLayout="row">
        <div *ngIf="this.invoice.hasAccountingExportable()" fxLayoutAlign=" center">
            <mat-icon *ngIf="!this.invoice.getAccountingExportable().invalidated_at" color="primary">sync</mat-icon>
            <mat-icon *ngIf="this.invoice.getAccountingExportable().invalidated_at" color="primary">sync_problem</mat-icon>
        </div>

        <span class="mat-caption" 
            fxLayoutAlign=" center" 
            [fxShow]="this.lockVault.hasResourceLock(this.invoice)">
            <mat-icon>lock_outline</mat-icon> Locked by &nbsp;<strong>{{ this.lockVault.getLockOwner(this.invoice) }}</strong>
        </span>
    </div>

    <!-- <mat-progress-spinner color="accent" *ngIf="this.sending" mode="indeterminate" diameter="36"></mat-progress-spinner> -->
    
    <button mat-icon-button [matMenuTriggerFor]="invoiceMenu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #invoiceMenu>
        <button mat-menu-item (click)="this.onSend()" [disabled]="!this.invoice.canSend() || this.sending">
            <mat-icon>send</mat-icon>
            {{ this.invoice.sent_at ? "Resend" : "Send" }}
        </button>
        <button mat-menu-item 
            *ngIf="this.invoice.canMarkSent()"
            [disabled]="this.sending"
            (click)="this.onMarkSent()">
            <mat-icon>arrow_circle_right</mat-icon>
            Mark Sent
        </button>
        <button mat-menu-item 
            *ngIf="this.invoice.sent_at"
            (click)="this.onShowEmailHistory()">
            <mat-icon>dynamic_feed</mat-icon>
            Email History
        </button>

        <button mat-menu-item (click)="this.onPrint()">
            <mat-icon>print</mat-icon>
            Print
        </button>

        <button mat-menu-item (click)="this.onDownload()">
            <mat-icon>cloud_download</mat-icon>
            Download as PDF
        </button>

        <button mat-menu-item 
            (click)="this.onVoid()" 
            *ngIf="!this.invoice.locked && !this.invoice.voided"
            [disabled]="!this.invoice.canVoid()">
            <mat-icon>highlight_off</mat-icon>
            Void
        </button>

        <button mat-menu-item 
            (click)="this.onDiscard()" 
            *ngIf="!this.invoice.locked && !this.invoice.discarded_at"
            [disabled]="!this.invoice.canDiscard()">
            <mat-icon>delete</mat-icon>
            Delete
        </button>
    </mat-menu>
</mat-toolbar>

<mat-divider></mat-divider>

<div class="invoice-container">
    <app-invoice [invoice]="this.invoice" [loaded]="this.loaded"></app-invoice>
</div>