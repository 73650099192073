import { Ng2StateDeclaration } from '@uirouter/angular';
import { InvoicedItemsComponent } from './invoiced-items.component';

export const InvoicedItemsReportState: Ng2StateDeclaration = {
  name: 'protected.reports.invoiced-items',
  url: '/invoiced-items',
  component: InvoicedItemsComponent,
  data: { 
    pageTitle: 'Invoiced Items Report', 
    requiresAuth: true,
  },
}
