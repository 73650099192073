import { Injectable } from "@angular/core";
import * as moment from "moment";
import { AppInjector } from "./app-injector.service";
import { Helpers as $helpers } from "./helpers.service";
import { RouteMap } from "./network/route-map.service";

export interface PreferencesData {
    company: { 
        rental_request_minimum: number, 
        sms_enabled: boolean, 
        business_hours: { 
            start: number, 
            end: number 
        } 
    },
    dispatch: { 
        projected_duration: number, 
        week_start: number, 
    },
    invoice: {
        grace_period: number,
        invoice_interval: number,
    }
    pricing: {
        week_threshold: number,
        month_threshold: number,
    },
    expires_at?: number,
}

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {

    data: PreferencesData;

    constructor(protected routeMap: RouteMap) {}

    /**
     * Load preferences from API. 
     * 
     * @returns {Promise<void>}
     */
    async load(): Promise<void>
    {
        const response = await this.routeMap.getCoreSettings();

        this.data = response.data();

        this._setExpiration();
    }

    /**
     * Retrieve a preference by dot notation.
     * 
     * @param {string} key 
     * @returns {Promise<void>}
     */
    async get(key: string): Promise<any>
    {
        if (this.isExpired())
        {
            await this.load();
        }

        if (!key.length)
        {
            return this.data;
        }

        return $helpers.dotNotationLookup(this.data, key);
    }

    /**
     * Save preferences to API.
     * 
     * @param {PreferencesData} data 
     * @returns {Promise<void>}
     */
    async save(data: PreferencesData): Promise<void>
    {
        delete data.expires_at;

        const response = await this.routeMap.updateCoreSettings(data);

        this.data = response.data();

        this._setExpiration();
    }

    /**
     * Check if preferences data is stale.
     * 
     * @returns {boolean}
     */
    isExpired(): boolean
    {
        return !this.data?.expires_at || moment.unix( this.data.expires_at ).isBefore( moment() );
    }    

    /**
     * Set expiration time on preferences data.
     */
    private _setExpiration()
    {
        this.data.expires_at = moment().add(15, 'minutes').unix();
    }
}

/**
 * Helper function for interacting with perferences service.
 * 
 * @param {string} key 
 * @returns {Promise<any>}
 */
export const prefs = async (key = ''): Promise<any> =>
{
    return AppInjector.get(PreferencesService).get(key);
}
