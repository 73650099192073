import { Ng2StateDeclaration } from '@uirouter/angular';
import { RentalItemComponent } from './rental-item.component';

export const RentalItemsReportState: Ng2StateDeclaration = {
  name: 'protected.reports.rental-item',
  url: '/rental-item',
  component: RentalItemComponent,
  data: { 
    pageTitle: 'Rental Item Report', 
    requiresAuth: true,
  },
}
