<div fxLayout="row" fxLayoutGap=20px>
    <mat-form-field fxFlex="75">
        <mat-label>Item</mat-label>
        <input type="text"
            [(ngModel)]="itemSearchText"
            name="itemSearchTextField-{idx}"
            #itemSearchTextField="ngModel"
            [matAutocomplete]="searchTextAuto"
            (ngModelChange)="onQueryItems()"
            required
            matInput>
        <mat-autocomplete #searchTextAuto="matAutocomplete"
            (optionSelected)="onItemSelected($event.option.value)"
            [displayWith]="this.displaySelectedItem">
            <mat-option *ngFor="let item of promisedItems | async" [value]="item">
                <!-- TODO: Jira BL-262: add thumbnail support -->
                <app-request-search-option 
                    [text]="item.name"
                    [thumbnail]="item.thumbnail"
                    [icon]="item.icon">
                </app-request-search-option>
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="itemSearchTextField.hasError('required')">Item is required.</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
        <mat-label>Quantity</mat-label>
        <input type="number"
            step="1"
            [(ngModel)]="this.item.quantity"
            name="quantityField"
            #quantityField="ngModel"
            (ngModelChange)="onItemUpdated()"
            required
            [minNum]="1"
            matInput>
            <mat-error *ngIf="quantityField.hasError('required')">Quantity is required.</mat-error>
            <mat-error *ngIf="quantityField.hasError('minNum')">Quantity must be at least 1.</mat-error>
    </mat-form-field>
    <button *ngIf="this.canRemoveItem" type="button" mat-icon-button color="primary" (click)="this.onItemRemoved(item)">
        <mat-icon class="remove-option-icon">remove_circle_outline</mat-icon>
    </button> 
</div>