import { Constructor } from "@angular/material/core/common-behaviors/constructor";
import { BaseModel } from "@beaconlite/models/Base.model";
import { InventoryConstants } from "@beaconlite/models/constants/InventoryConstants";
import { GroupItemConstants } from "@beaconlite/models/contracts/GroupItem.interface";
import { GroupDefinition } from "@beaconlite/models/contracts/GroupDefinition";
import { HasItemLevel } from "./HasItemLevel.mixin";

export function HasIcon<T extends Constructor<BaseModel & HasItemLevel>>( Base: T )
{
    class HasIconMixin extends Base {
        get icon(): string
        {
            switch (this.item_level) {
                case InventoryConstants.INV_ITEM_DEF:
                    return GroupItemConstants.ITEM_DEF_ICON;
                case InventoryConstants.INV_VAR_ITEM:
                    return GroupItemConstants.VARIANT_ITEM_ICON;
                case InventoryConstants.INV_GROUP_DEF:
                    return GroupDefinition.GROUP_DEFINITION_ICON;
            }

            return this.item_level == InventoryConstants.INV_ITEM_DEF ? GroupItemConstants.ITEM_DEF_ICON : GroupItemConstants.VARIANT_ITEM_ICON;
        }
    }

    return HasIconMixin;
}
