import * as moment from "moment";
import { BaseModel } from "./Base.model";
import { Discardable } from "./mixins/Discardable.mixin";
import { date } from './mixins/Date.decorators'
import { dto } from './mixins/Dto.decorators'

const MixinBase = Discardable( BaseModel );

export class Attachment extends MixinBase {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }
   
    @dto() id?: string = null;
    @dto() locked?: boolean = false;
    @dto() type?: string = null;
    @dto() name?: string = null;
    @dto() description?: string = null;
    @dto() filename?: string = null;
    @dto() key?: string = null;
    @dto() bytes?: string = null;
    @dto() url?: string = null;
    @dto() @date url_expires_at?: number = null;

    async getUrl(): Promise<string>
    {
        if (!! this.url &&
               this.url_expires_at > moment().add(1, 'minutes').unix())
        {
            return this.url;
        }

        const response = await this.routeMap.presignAttachmentUrl(this.id);
        this.map(response.data());

        return this.url;
    }

    async download(): Promise<void> 
    {
        const url = await this.getUrl();
        const win = window.open(url, '_blank');
        return win.focus();
    }
}
