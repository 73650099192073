import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'innerHTMLBodyContent'
})
export class InnerHTMLBodyContent implements PipeTransform {
    
    transform(content_html: string): string
    {
        // Get all content from inside <body></body>.
        return content_html.match(/\<body[^>]*\>([^]*)\<\/body/m)[1];
    }
}
