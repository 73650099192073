import { Component, Input, OnInit } from "@angular/core";
import { RentalGroupDefinition } from "@beaconlite/models/RentalGroupDefinition.model";

@Component({
    selector: "app-rental-group-single",
    templateUrl: "./rental-group-single.component.html",
    styleUrls: ["./rental-group-single.component.scss"],
})
export class RentalGroupSingleComponent implements OnInit {
    @Input() modelId: string;

    original: RentalGroupDefinition;
    loading = true;
    childLoading = true;

    async ngOnInit(): Promise<void> {
        try {
            this.original = await RentalGroupDefinition.get(this.modelId);
        } finally {
            this.loading = false;
        }
    }

    setOriginal(updatedDef: RentalGroupDefinition): void {
        this.original = updatedDef;
    }

    setLoading(loading: boolean): void {
        this.childLoading = loading;
    }
}
