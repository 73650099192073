import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { DispatchedItemReport } from '@beaconlite/models/reports/DispatchedItemReport.model';
import { DispatchedReportItem } from '@beaconlite/models/reports/contracts/DispatchedReportItem.interface';

@Component({
  selector: 'dispatched-item-rental-row',
  templateUrl: './dispatched-item-rental-row.component.html',
})
export class DispatchedItemRentalRowComponent implements OnInit, DoCheck {

  @Input() report: DispatchedItemReport;
  @Input() items: DispatchedReportItem[];
  @Input() layer: string;

  nestedLayer: string = '';
  tableColumns = ['name','details', 'scheduled', 'quantity'];

  constructor() { }
  ngOnInit(): void { }

  ngDoCheck(): void
  {
    this.nestedLayer = this.report.nextLayer(this.layer);
  }

  getNestedItems(item: DispatchedReportItem): DispatchedReportItem[] | null
  {
    if (! this.nestedLayer) return;

    return Object.values(item[this.nestedLayer]);
  }
}
