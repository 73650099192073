import { AppInjector } from "@beaconlite/services/app-injector.service";
import { RouteMap } from "@beaconlite/services/network/route-map.service";
import { BaseModel } from "./Base.model";
import { dto } from "./mixins/Dto.decorators";

export class DispatchRegion extends BaseModel {

    constructor(attributes: object = {}) {
        super();
        this.init(attributes);
    }
    
    @dto() id?: string = null;
    @dto() name?: string = null;

    static async get(id: string): Promise<DispatchRegion> 
    {
        const response = await AppInjector.get(RouteMap).getDispatchRegion(id);

        return new DispatchRegion( response.data() );
    }

    static async getAll(): Promise<DispatchRegion[]> 
    {
        const response = await AppInjector.get(RouteMap).allDispatchRegions();
        const castCollection = response.data().map((data: any) => new DispatchRegion(data));

        return castCollection;
    }

    async save(): Promise<DispatchRegion> 
    {
        const response = ( this.exists() )
            ? await this.routeMap.updateDispatchRegion(this.id, this.flush())
            : await this.routeMap.createDispatchRegion(this.flush());

        return this.map(response.data());
    }

    async delete(): Promise<void> 
    {
        await this.routeMap.deleteDispatchRegion(this.id);
    }
}
