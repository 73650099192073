import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Service } from '@beaconlite/models';
import { ServiceEditorData } from "./service-editor-data.interface";
import { ServiceEditorComponent } from "./service-editor.component";

@Injectable({
    providedIn: 'root'
})
export class ServiceEditorService {

constructor(protected dialog: MatDialog) {}

    async open(data: ServiceEditorData): Promise<Service|void>
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            width: '100%',
            maxWidth: 800,
            data,
        }

        return this.dialog.open(ServiceEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}