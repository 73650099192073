import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { GroupItem } from '@beaconlite/models/contracts/GroupItem.interface';
import { ResourceItem, ResourceItemCollection } from '@beaconlite/models/contracts/ResourceItem.interface';

@Component({
    selector: 'app-group-editor-item',
    templateUrl: './group-editor-item.component.html',
    styleUrls: ['./group-editor-item.component.scss']
})
export class GroupEditorItem  {
    @ViewChild('itemSearchTextField') itemSearchTextField: NgModel;
    @Input() item: GroupItem;
    @Input() quantity: number;
    @Input() canRemoveItem: boolean;
    @Output() onGroupItemChange = new EventEmitter<GroupItem>();
    @Output() onGroupItemRemoved = new EventEmitter<GroupItem>();
    promisedItems: Promise<ResourceItem[]>;
    itemSearchText = '';
    collection: ResourceItemCollection;

    async ngOnInit(): Promise<void> 
    {
        // group-editor-item is always passed an item that at least knows its item_type.
        this.itemSearchText = this.item.name;
        this.collection = this.item.getCollection();
    }

    onQueryItems(): void
    {
        this.promisedItems = this.collection.all({
            keyword: this.itemSearchText,
            order: 'asc',
        });
    }

    displaySelectedItem = (item?: GroupItem): string =>
    {
        // If there is an item already selected, use its name
        if (!!this.item && this.itemSearchTextField.pristine)
        {
            this.itemSearchTextField.control.setErrors(null);
            return this.item.name;
        }

        // Otherwise use the item was set in the input, or empty if no item was set.
        return item?.name || '';
    }

    onItemSelected(item: ResourceItem)
    {
        this.item.id = item.id;
        this.item.name = item.name;
        this.item.thumbnail = item.thumbnail;
        this.item.item_level = item.item_level;
        this.onItemUpdated();
    }

    onItemUpdated()
    {
        this.onGroupItemChange.emit(this.item);
    }

    onItemRemoved(item: GroupItem)
    {
        this.onGroupItemRemoved.emit(item);
    }
}
