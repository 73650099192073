import { Uuid } from "@beaconlite/types";
import { dto } from "./mixins/Dto.decorators";
import { Thumbnail } from "./Thumbnail.model";
import { AppInjector } from "@beaconlite/services/app-injector.service";
import { RouteMap } from "@beaconlite/services/network/route-map.service";
import { GroupDefinition } from "./contracts/GroupDefinition";
import { date } from "./mixins/Date.decorators";
import { RentalGroupItem } from "./RentalGroupItem.model";

export class RentalGroupDefinition extends GroupDefinition {
    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }

    @dto() id?: Uuid = null;
    @dto() name: string = null;
    @dto() code: string = null;
    @dto() description: string = null;
    @dto() tags: string = null;
    @dto() department_id: string = null;
    @dto() @date deleted_at?: number = null

    @dto(RentalGroupItem) group_items: RentalGroupItem[] = [];
    @dto(Thumbnail) thumbnail?: Thumbnail = null;

    async save(): Promise<RentalGroupDefinition> {
        const response = this.exists()
        ? await this.routeMap.updateRentalGroupDefinition( this.id, this.flush() )
        : await this.routeMap.createRentalGroupDefinition( this.flush() );

        return this.map( response.data() )
    }

    async delete(): Promise<void>
    {
        await this.routeMap.deleteRentalGroupDefinition( this.id );
    }

    static async get(id: string): Promise<RentalGroupDefinition> 
    {
        const response = await AppInjector.get(RouteMap).getRentalGroupDefinition(id);
        return new RentalGroupDefinition( response.data() );
    }

    async reload(): Promise<RentalGroupDefinition>
    {
        const response = await this.routeMap.getRentalGroupDefinition(this.id);
        return this.map( response.data() );
    }

    getNewGroupItem(): RentalGroupItem {
        return new RentalGroupItem({quantity: 1, ordinal: this.group_items.length});
    }
}
