<mat-card class="dispatch dispatch-slip large-card {{ this.headerClass }}" fxLayout="column">
    <mat-card-title fxLayout="row" fxLayoutAlign=" center">

        <div fxLayout="column">
            <span class="mat-headline">{{ this.workOrder.client?.name }}</span>
            <span class="mat-caption">{{ this.displayType }} | {{ this.dispatch.formatted_serial_id }}</span>
        </div>

        <span fxFlex></span>

        <div class="toolbar-status" fxLayout="column">
            <span class="toolbar-status__current capitalize">{{ this.dispatch.state_name }}</span>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="dispatchMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #dispatchMenu="matMenu">
            <button mat-menu-item *ngIf="this.dispatch.dispatched_at" (click)="this.onSend()">
                <mat-icon>send</mat-icon>
                {{ this.dispatch.sent_at ? "Resend" : "Send" }}
            </button>

            <button mat-menu-item *ngIf="this.dispatch.dispatched_at" [disabled]="this.sending" (click)="this.onPrintDispatch()">
                <mat-icon>print</mat-icon>
                Print
            </button>

            <button mat-menu-item *ngIf="this.dispatch.dispatched_at" (click)="this.onDownload()">
                <mat-icon>cloud_download</mat-icon>
                Download as PDF
            </button>
        </mat-menu>
    </mat-card-title>

    <!-- Dispatch Properties -->
    <div class="large-card__lead" layoutPadding>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" layoutPadding>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="50">
                <span class="mat-caption">Scheduled For</span>
                <p class="mat-body-1">
                    {{ this.displayDate }}
                    <span *ngIf="this.dispatch.scheduled_end_at"> &ndash; {{ this.dispatch.scheduled_end_at | dateFilter:'time' }}</span>
                </p>
            </div>
            <div fxLayout="column" fxFlex="25" fxHide fxShow.gt-xs  *ngIf="!this.dispatch.travel_time"></div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="75" fxFlex.gt-sm="25" *ngIf="this.dispatch.travel_time">
                <span class="mat-caption">Travel Time Required</span>
                <p class="mat-body-1">{{ this.dispatch.travel_time | timeFilter:'minutes'}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="25">
                <span class="mat-caption">Vehicle</span>
                <p class="mat-body-1">{{ this.dispatch.truck_identifier || "Unassigned" }}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="75">   
                <span class="mat-caption">Location</span>            
                <p class="mat-body-1">{{ this.dispatch.location }}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="25">
                <span class="mat-caption">Work Order</span>            
                <p class="mat-body-1">{{ this.workOrder.formatted_serial_id }}</p>
            </div>
            <div fxLayout="column" fxFlex="100" *ngIf="!!this.dispatch.dispatch_region">
                <span class="mat-caption">Dispatch Region</span>            
                <p class="mat-body-1">{{ this.dispatch.dispatch_region.name }}</p>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row wrap" fxLayoutGap="16px grid" layoutPadding>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" *ngIf="this.dispatch.dispatched_at">
                <span class="mat-caption">Dispatched By</span>
                <p class="mat-body-1">{{ this.dispatch.dispatched_at | dateFilter:'dateTimeMedium' }} &mdash; {{ this.dispatch?.dispatcher.fullname }}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" *ngIf="!this.dispatch.site_contact"></div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" *ngIf="this.dispatch.site_contact"> 
                <span class="mat-caption">Site Contact</span>
                <p class="mat-body-1">{{ this.dispatch.site_contact }}</p> 
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" *ngIf="this.dispatch.work_order.external_ids?.length">
                <span class="mat-caption">Customer Reference</span>
                <p class="mat-body-1">
                    <span *ngFor="let external_id of this.dispatch.work_order.external_ids; last as isLast">
                        {{ external_id.value }}{{ !isLast ? ', ' : '' }}
                    </span>
                </p>
            </div>
       
            <div fxLayout="column" fxFlex="100">
                <span class="mat-caption">Employees</span>
                <p class="mat-body-1">
                    <span *ngFor="let employee of this.dispatch.employees; last as isLast">{{ employee.fullname }}<span *ngIf="!isLast">,&nbsp;</span></span>
                    <span *ngIf="!this.dispatch.hasEmployees()">Unassigned</span>
                </p>     
            </div>
        </div>

        <mat-divider *ngIf="this.dispatchNotes.length"></mat-divider>

        <div fxLayout="column"  fxLayoutGap="16px" layoutPadding flex="100" *ngIf="this.dispatchNotes.length">
            <div class="mat-subheading-1">Notes</div>

            <div fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" *ngFor="let note of this.dispatchNotes">
                <div class="note__date mat-caption" fxFlex="100" fxFlex.gt-xs="15" fxFlex.gt-md="10" >{{ note.noted_at | dateFilter: 'dateTimeShort'  }}</div>
                <div class="note__content mat-body-1" fxFlex="100" fxFlex.gt-xs="85" fxFlex.gt-md="90">{{ note.content }}</div>
            </div>
        </div>
    </div>

    <!-- Rentals -->
    <div class="dispatch-main" *ngIf="this.dispatch.hasRentalRequests()">
    <div *ngFor="let request of this.dispatch.rental_requests; index as idx">
        <div class="section-header" fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column">
                <div class="mat-subheading-1">Rental Request</div>
                <div class="mat-caption">{{ request.source.formatted_serial_id }}</div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <app-flattened-view-toggle
                    [isFlattenedView]="isFlattenedView[idx]"
                    (onToggled)="onFlattenedViewToggledHandler($event, idx)">
                </app-flattened-view-toggle>

                <button mat-icon-button 
                    [fxHide]="!this.canEditRequests" 
                    (click)="this.captureRentalQuantity(request, idx)">
                    <mat-icon color="primary">create</mat-icon>
                </button>
            </div>
        </div>

        <div class="section-content" fxLayout="row wrap">
            <div fxLayout="column" fxFlex="100">
                <span class="mat-caption labelled-data__label">Scheduled</span>
                <p class="mat-body-1 labelled-data__value">
                    {{ request.dispatched_started_at | dateFilter:'dateMedium' }}
                    <span *ngIf="request.ended_at"> &ndash; {{ request.dispatched_ended_at | dateFilter:'dateMedium' }}</span>
                </p>
            </div>
            <div fxLayout="column" fxFlex="100">
                <span class="mat-caption labelled-data__label">Location</span>
                <p class="mat-body-1 labelled-data__value">{{ request.source.location }}</p>
            </div>
            <div fxLayout="column" fxFlex="100" *ngIf="request.source.ordered_by">
                <span class="mat-caption labelled-data__label">Ordered By</span>
                <p class="mat-body-1 labelled-data__value">{{ request.source.ordered_by }}</p>
            </div>
            <div fxLayout="column" fxFlex="100" *ngIf="request.source.dispatch_notes.length">
                <span class="mat-caption labelled-data__label">Notes</span>
                <div class="note_row" fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" 
                    *ngFor="let note of request.source.dispatch_notes">
                    <div class="note__date mat-caption" fxFlex="100" fxFlex.gt-xs="15" fxFlex.gt-md="10" >{{ note.noted_at | dateFilter:'dateTimeShort' }}</div>
                    <div class="note__content mat-body-1" fxFlex="100" fxFlex.gt-xs="85" fxFlex.gt-md="90">{{ note.content }}</div>
                </div>
            </div>

            <ng-container *ngIf="this.isFlattenedView[idx]; then flattenedTable; else groupedTable">
            </ng-container>

            <ng-template #flattenedTable>
                <mat-table class="mat-mobile-table" fxFlex="100" 
                    [dataSource]="getFlattenedRentalsForRequest(request)"
                    #rentalTable>
                    <ng-container matColumnDef="item">
                        <mat-header-cell fxFlex="65" *matHeaderCellDef>Item</mat-header-cell>
                        <mat-cell fxFlex="65" *matCellDef="let rental">
                            <span>{{ rental.dispatchedRentals[0].source.name }}</span><br>
                            <span>{{ rental.dispatchedRentals[0].source.detail }}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <mat-header-cell  class="mat-header-cell--numeric" *matHeaderCellDef>Quantity</mat-header-cell>
                        <mat-cell  class="mat-cell--numeric" *matCellDef="let rental">{{ rental.dispatched_quantity }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actioned-quantity">
                        <mat-header-cell  class="mat-header-cell--numeric actioned-quantity-label" *matHeaderCellDef>{{ this.actionLabel }} Quantity</mat-header-cell>
                        <mat-cell  class="mat-cell--numeric actioned-quantity-field" *matCellDef="let rental">{{ rental.actioned_quantity }}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                    <mat-row *matRowDef="let rental; columns: tableColumns"></mat-row>
                </mat-table>
            </ng-template>

            <ng-template #groupedTable>
                <mat-table class="mat-mobile-table" fxFlex="100" 
                    [dataSource]="request.request_items"
                    #rentalTable
                    multiTemplateDataRows>
                    <ng-container matColumnDef="item">
                        <mat-header-cell fxFlex="65" *matHeaderCellDef>Item</mat-header-cell>
                        <mat-cell fxFlex="65" *matCellDef="let requestItem">
                            <span>{{ requestItem.source.name }}</span><br>
                            <span *ngIf="!requestItem?.rentals">{{ requestItem.source.detail }}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <mat-header-cell  class="mat-header-cell--numeric" *matHeaderCellDef>Quantity</mat-header-cell>
                        <mat-cell  class="mat-cell--numeric" *matCellDef="let requestItem"><span *ngIf="!requestItem?.rentals">{{ requestItem.dispatched_quantity }}</span></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actioned-quantity">
                        <mat-header-cell  class="mat-header-cell--numeric actioned-quantity-label" *matHeaderCellDef>{{ this.actionLabel }} Quantity</mat-header-cell>
                        <mat-cell  class="mat-cell--numeric actioned-quantity-field" *matCellDef="let requestItem"><span *ngIf="!requestItem?.rentals">{{ requestItem.actioned_quantity }}</span></mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="groupItem">
                        <mat-cell *matCellDef="let requestItem" [fxShow]="requestItem?.rentals?.length">
                            <mat-table class="mat-mobile-table inner-table"
                                fxFlex="100"
                                [dataSource]="requestItem.rentals">
                                <ng-container matColumnDef="item">
                                    <mat-cell fxFlex="65" *matCellDef="let groupItem">
                                        <span>{{ groupItem.source.name }}</span><br>
                                        <span>{{ requestItem.source.detail }}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="quantity">
                                    <mat-cell  class="mat-cell--numeric" *matCellDef="let groupItem">{{ groupItem.dispatched_quantity }}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="actioned-quantity">
                                    <mat-cell  class="mat-cell--numeric actioned-quantity-field" *matCellDef="let groupItem">{{ groupItem.actioned_quantity }}</mat-cell>
                                </ng-container>
                                <mat-row *matRowDef="let groupItem; columns: tableColumns"></mat-row>
                            </mat-table>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                    <mat-row *matRowDef="let requestItem; columns: tableColumns"></mat-row>
                    <mat-row *matRowDef="let groupItem; columns: ['groupItem']" class="inner-row"></mat-row>

                </mat-table>
            </ng-template>
        </div>
    </div>
    <mat-divider></mat-divider>
    </div> 

    <!-- Services -->
    <div class="dispatch-main" *ngIf="this.dispatch.hasServices()">
    <div *ngFor="let service of this.dispatch.services">
        <div class="section-header" fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column">
                <div class="mat-subheading-1">{{ service.source.name }}</div>
                <div class="mat-caption labelled-data__label">{{ service.source.formatted_serial_id }}</div>
            </div>
            <div fxLayout="column">
                <button mat-icon-button
                    *ngIf="service.source.pricing_type === 'unit' "  
                    [fxHide]="!this.canEditRequests"
                    (click)="this.captureServiceQuantity(service)">
                    <mat-icon color="primary">create</mat-icon>
                </button>
            </div>
        </div>

        <div class="section-content" fxLayout="row wrap" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="60" fxFlex.gt-sm="70">
                <span class="mat-caption labelled-data__label">Scheduled</span>
                <p class="mat-body-1 labelled-data__value">
                    {{ service.dispatched_started_at | dateFilter:'dateTimeMedium' }}
                    <span *ngIf="service.ended_at">  &ndash; {{ service.dispatched_ended_at | dateFilter:'time' }}</span>
                </p>
                <span class="mat-caption labelled-data__label">Location</span>
                <p class="mat-body-1 labelled-data__value">{{ service.source.location }}</p>
                <div *ngIf="service.source.ordered_by">
                    <span class="mat-caption labelled-data__label">Ordered By</span>
                    <p class="mat-body-1 labelled-data__value">{{ service.source.ordered_by }}</p>
                </div>
            </div>

            <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="40" fxFlex.gt-sm="30">
                <div fxLayout="column" *ngIf="service.source.pricing_type === 'unit' ">
                    <span class="mat-caption labelled-data__label">Quantity</span>
                    <p class="mat-body-1 labelled-data__value">{{ service.dispatched_quantity }}</p>
                </div>
                <div class="punch-button" 
                     fxLayout="column"
                     *ngIf="service.source.pricing_type !== 'unit' ">
                    <div class="request-timer" 
                         fxLayout="row"
                         fxLayoutGap="10px" 
                         [fxHide]="!this.canEditRequests">
                        <app-punch-button radius="25" [currentTask]="service.timed_task" [tasks]="this.tasks" [callback]="this.punchCallback(service)"></app-punch-button>
                        <app-task-timer [punchtime]="service.timed_at" [task]="service.timed_task"></app-task-timer>
                    </div>
                    <div class="punch-button__times-row" fxLayout="row">
                        <div fxLayout="column" fxFlex="33">
                            <span class="mat-caption labelled-data__label">Time</span>
                            <p class="mat-body-1 labelled-data__value">{{ service.regular_time | timeFilter}} </p>
                        </div>
                        <div fxLayout="column" fxFlex="33">
                            <span class="mat-caption labelled-data__label">Overtime</span>
                            <p class="mat-body-1 labelled-data__value">{{ service.over_time | timeFilter}} </p>
                        </div>
                        <div fxLayout="column" fxFlex="33">
                            <span class="mat-caption labelled-data__label">Travel</span>
                            <p class="mat-body-1 labelled-data__value">{{ service.travel_time | timeFilter}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxLayout="column" fxFlex="100" *ngIf="service.source.dispatch_notes.length">
            <span class="mat-caption labelled-data__label">Notes</span>
            <div class="note_row" fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" 
                *ngFor="let note of service.source.dispatch_notes">
                <div class="note__date mat-caption" fxFlex="100" fxFlex.gt-xs="15" fxFlex.gt-md="10" >{{ note.noted_at | dateFilter: 'dateTimeShort' }}</div>
                <div class="note__content mat-body-1" fxFlex="100" fxFlex.gt-xs="85" fxFlex.gt-md="90">{{ note.content }}</div>
            </div>
        </div>
        <div fxLayout="row"><mat-divider></mat-divider></div>
    </div>
    </div>

    <!-- Documents -->

    <div class="dispatch-main" *ngIf="this.shouldShowSection(this.dispatch.documents)">
    <div fxLayout="column">
        <div class="section-header" fxLayout="row" fxFlex>
            <div class="mat-subheading-1">Documents</div>
        </div>
        <div class="section-content" fxLayout="column wrap" fxFlex="100">
            <div class="mat-body-2" [fxShow]="!this.dispatch.hasDocuments()">You haven't added any documents yet.</div>
            <div class="doc-list-item" 
                [fxShow]="this.dispatch.hasDocuments()"
                fxFlex 
                fxLayout="row" 
                fxLayoutAlign=" center"
                fxLayoutGap="20px"
                *ngFor="let doc of this.dispatch.documents"
                (click)="this.onPreviewDocument(doc)">
                <div class="doc-list-item__avatar">
                    <mat-icon>image</mat-icon>
                </div>
                <div fxLayout="column">
                    <span>{{ doc.attachment?.name }}</span>
                    <span>{{ doc.created_at | dateFilter:'dateMedium' }}</span>
                    <span class="mat-caption">{{ doc.attachment?.description }}</span>
                </div>
                <span fxFlex></span>
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="documentMenu" (click)="$event.stopPropagation();">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #documentMenu="matMenu">
                        <button mat-menu-item (click)="this.onEditDocument(doc)">
                            <mat-icon>edit</mat-icon>
                            Edit
                        </button>

                        <button mat-menu-item (click)="this.onRemoveDocument(doc)">
                            <mat-icon>delete</mat-icon>
                            Delete
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end end" layoutPadding>
            <button mat-mini-fab  
                color="primary"
                [fxHide]="this.isReadOnly"
                (click)="this.onAddDocument()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    </div>

    <!-- Field Notes -->
    <div class="dispatch-main" *ngIf="this.shouldShowSection(this.dispatch.field_notes)">
    <div fxLayout="column">
        <div class="section-header" fxLayout="row" fxFlex>
            <div class="mat-subheading-1">Field Notes</div>
        </div>
        <div class="section-content" fxLayout="column wrap" fxFlex="100">
            <div class="note_row" fxLayout="row wrap" fxLayoutAlign="space-between center"  
                *ngFor="let note of this.dispatch.field_notes">
                <div class="note__date mat-caption" fxFlex>{{ note.noted_at | dateFilter: 'dateTimeShort' }}</div>
                <button mat-icon-button
                    fxFlexOrder="0"
                    fxFlexOrder.gt-xs="1"
                    [fxHide]="this.dispatch.actioned_at || this.isReadOnly"
                    (click)="this.onEditNote(note)">
                    <mat-icon color="primary">create</mat-icon>
                </button>
                <div class="note__content mat-body-1" fxFlex="100" fxFlex.gt-xs="80" fxFlex.gt-md="85">{{ note.content }}</div>
            </div>
            <div class="mat-body-2" [fxShow]="!this.dispatch.field_notes.length"> You haven't added any field notes yet.</div>
        </div>

        <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="end end" layoutPadding>
            <button mat-mini-fab
                [fxHide]="this.isReadOnly" 
                (click)="this.onAddNote()"
                color="primary">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    </div>

    <!-- Signatures -->
    <div class="dispatch-main" *ngIf="this.shouldShowSection(this.dispatch.signatures)">
    <div fxLayout="column">
        <div class="section-header" fxLayout="row" fxFlex>
            <div class="mat-subheading-1">Signatures</div>
        </div>
        <div class="section-content" fxLayout="column">
            <div fxLayout="row">
                <button mat-raised-button
                    color="accent"
                    [disabled]="this.loading"
                    [fxHide]="this.isReadOnly"
                    (click)="this.captureSignature()">
                    Capture Signature
                </button>
            </div>
            <div class="static-data-component" fxLayout="row wrap"
                *ngFor="let signature of this.dispatch.signatures"
                (click)="this.captureSignature(signature)">
                <div fxLayout="column" fxFlex="100" fxFlex.gt-xs="40">
                    <img class="displayed-signature" [src]="signature.attachment.url">
                </div>
                <div fxLayout="column" fxFlex="50" fxFlex.gt-xs="40">
                    <span class="mat-caption">Customer Name</span>
                    <p class="mat-body-1">{{ signature.name }}</p>
                </div>
                <div fxLayout="column" fxFlex="50" fxFlex.gt-xs="20">
                    <span class="mat-caption">Date Signed</span>
                    <p class="mat-body-1">{{ signature.captured_at | dateFilter : 'dateMedium' }}</p>
                </div>
            </div>
        </div>

    </div>
    </div>

    <div class="dispatch-main" fxLayout="row" [fxHide]="this.isReadOnly" layoutPadding>
        <span fxFlex></span>
        <mat-progress-spinner *ngIf="this.finishing" mode="indeterminate" diameter="36"></mat-progress-spinner>
        <button mat-raised-button
            color="primary"
            [disabled]="this.finishing || this.dispatch.actioned_at || this.dispatch.completed_at"
            (click)="this.finish()">
            Finish Dispatch
        </button>
    </div>
</mat-card>