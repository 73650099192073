import * as moment from 'moment';
import { Helpers as $helpers } from '../../../../services/helpers.service';
import { Component, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Branch, Client, Invoice, WorkOrder } from '@beaconlite/models';
import { BranchCollection, ClientCollection, InvoiceCollection } from '@beaconlite/models/collections';
import { CollectionDataSource } from '@beaconlite/models/utilities/CollectionDataSource.utility';

@Component({
  selector: 'app-invoice-index',
  templateUrl: './invoice-index.component.html',
  styles: [':host{height:100%}']
})
export class InvoiceIndexComponent implements OnInit {

  loading = false;
  collection = new InvoiceCollection();
  invoices = new CollectionDataSource(this.collection);

  searchAll = false;
  selectedBranch = null;
  branchSearchText = '';
  selectedClient = null;
  clientSearchText = '';
  selectedState = ''; 
  orderBy = 'id';
  order = 'desc'; 
  keyword: string = null;

  selectedStartDate;
  selectedEndDate;


  promisedBranches: Promise<Branch[]>;
  promisedClients: Promise<Client[]>;

  selectedModelId: string = this.$stateGlobals.params.modelId;
  
  constructor(
    protected $stateGlobals: UIRouterGlobals,
    protected $state: StateService,
  ) { }

  async ngOnInit(): Promise<void> 
  {
    this.onSearch();
  }

  onSearch()
  {
    const params: any = {
      search_all: this.searchAll,
      order:      this.order, 
      order_by:   this.orderBy,
    } 

    if (this.keyword)
    {
        params.keyword = this.keyword;
    }

    if (this.selectedBranch)
    {
        params.branch_id = this.selectedBranch.id;
    }

    if (this.selectedClient)
    {
        params.client_id = this.selectedClient.id;
    }

    if (this.selectedStartDate)
    {
        params.date_start = $helpers.dateToTimestamp(this.selectedStartDate);
    }

    if (this.selectedEndDate)
    {
        params.date_end = moment(this.selectedEndDate).endOf('day').unix();
    }

    if (this.selectedState)
    {
        params.state = this.selectedState;
    }

    this.collection.search(params);
  }

  getWOSerial(model: Invoice): string
  {
    return WorkOrder.formatSerialId(model.work_order.serial_id)
  }

  onEdit(invoice: Invoice): void
  {
    this.selectedModelId = invoice.id;
    this.$state.go('protected.invoices.single', { modelId: invoice.id });
  }

  goToSchedule(): void
  {
    this.$state.go('protected.invoices.schedule');
  }

  onQueryBranches(): void
  {
    this.promisedBranches = (new BranchCollection()).all({name:this.branchSearchText});
  }

  onQueryClients(): void
  {
    this.promisedClients = (new ClientCollection()).all({name:this.clientSearchText});
  }

  clearSearchText(selection: Branch|Client, field: 'branch'|'client')
  {
    if (!! selection) return;

    switch(field)
    {
      case 'client':
        this.clearClientSelection();
        break;
      case 'branch':
        this.clearBranchSelection();
        break;
    }
  }
  
  clearBranchSelection()
  {
    this.branchSearchText = '';
    this.selectedBranch = null;
  }

  clearClientSelection()
  {
    this.clientSearchText = '';
    this.selectedClient = null;
  }

  onBranchOptionSelected(branch: Branch)
  {
    this.selectedBranch = branch;
  }

  onClientOptionSelected(client: Client)
  {
    this.selectedClient = client;
  }

  displaySelectedBranch = (branch: Branch|null): string|null => 
  {
    return branch?.name || null;
  }

  displaySelectedClient = (client: Client|null): string|null => 
  {
    return client?.name || null;
  }
}
