import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { RentalQuantitiesEditorData } from "./rental-quantities-editor-data.interface";
import { RentalQuantitiesEditorComponent } from "./rental-quantities-editor.component";

@Injectable({
    providedIn: 'root'
})
export class RentalQuantitiesEditorService {
    
    constructor(protected dialog: MatDialog) {}

    async open(data: RentalQuantitiesEditorData): Promise<boolean> 
    {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
            minWidth: 700,
            maxWidth: 700,
            data,
        }

        return this.dialog.open(RentalQuantitiesEditorComponent, dialogConfig)
            .afterClosed()
            .toPromise();
    }
}
  