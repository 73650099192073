import { Ng2StateDeclaration } from "@uirouter/angular";
import { GeneralSettingsComponent } from "./general-settings.component";

export const GeneralSettingsState: Ng2StateDeclaration = {
    name: 'protected.settings',
    url: '/settings',
    component: GeneralSettingsComponent,
    data: {
        pageTitle: 'Settings',
        requiresAuth: true,
    },
}
