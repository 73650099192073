<form class="mat-dialog-editor" #editorForm="ngForm" (ngSubmit)="onSave()" novalidate>
    <mat-toolbar fxLayout="row">
        <h2 class="mat-title">Add a Filter</h2>
    </mat-toolbar>    

    <mat-dialog-content class="dialog-padding" fxLayout="column">
        <mat-form-field fxFlex>
            <mat-label>Filter By</mat-label>
            <mat-select name="filterType"
                [(ngModel)]="this.filterBy">
                <mat-option *ngFor="let type of this.filterTypes" [value]="type">
                    {{ this.getTypeDescriptor(type) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="this.hasFilterType('rentals') && this.filterBy == 'rentals'" fxFlex>
            <mat-label>Rentals</mat-label>
            <input type="text"
                [(ngModel)]="this.rentalSearchText"
                name="rentalSearchTextField"
                (ngModelChange)="this.onQueryRentals()"
                [matAutocomplete]="rentalAuto"
                matInput>
            <mat-autocomplete #rentalAuto="matAutocomplete"
                (optionSelected)="this.selectedRental = $event.option.value"
                [displayWith]="this.displaySelected">
                <mat-option *ngFor="let rental of promisedRentals | async" [value]="rental">
                    {{ rental.display_name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="this.hasFilterType('clients') && this.filterBy == 'clients'" fxFlex>
            <mat-label>Clients</mat-label>
            <input type="text"
                [(ngModel)]="this.clientSearchText"
                name="clientSearchTextField"
                (ngModelChange)="this.onQueryClients()"
                [matAutocomplete]="clientAuto"
                matInput>
            <mat-autocomplete #clientAuto="matAutocomplete"
                (optionSelected)="this.selectedClient = $event.option.value"
                [displayWith]="this.displaySelected">
                <mat-option *ngFor="let client of promisedClients | async" [value]="client">
                    {{ client.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="this.hasFilterType('services') && this.filterBy == 'services'" fxFlex>
            <mat-label>Services</mat-label>
            <input type="text"
                [(ngModel)]="this.serviceSearchText"
                name="serviceSearchTextField"
                (ngModelChange)="this.onQueryServices()"
                [matAutocomplete]="serviceAuto"
                matInput>
            <mat-autocomplete #serviceAuto="matAutocomplete"
                (optionSelected)="this.selectedService = $event.option.value"
                [displayWith]="this.displaySelected">
                <mat-option *ngFor="let service of promisedServices | async" [value]="service">
                    {{ service.display_name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="this.hasFilterType('charges') && this.filterBy == 'charges'" fxFlex>
            <mat-label>Charges</mat-label>
            <input type="text"
                [(ngModel)]="this.chargeSearchText"
                name="chargeSearchTextField"
                (ngModelChange)="this.onQueryCharges()"
                [matAutocomplete]="chargeAuto"
                matInput>
            <mat-autocomplete #chargeAuto="matAutocomplete"
                (optionSelected)="this.selectedCharge = $event.option.value"
                [displayWith]="this.displaySelected">
                <mat-option *ngFor="let charge of promisedCharges | async" [value]="charge">
                    {{ charge.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="this.hasFilterType('item_types') && this.filterBy == 'item_types'" fxFlex>
            <mat-label>Item Type</mat-label>
            <mat-select name="itemTypeField"
                [(ngModel)]="this.selectedItemType">
                <mat-option *ngFor="let type of this.itemTypes" [value]="type">
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
        <button type="button" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="this.loading">
            Add
        </button>
    </mat-dialog-actions>
</form>
