import { Export, Integration } from '@beaconlite/models';
import { Component, OnInit } from '@angular/core';
import { ExportCollection } from '@beaconlite/models/collections';
import { DialogNotificationService } from '@beaconlite/services/notification/dialog-notification.service';
import { orderBy } from '@beaconlite/utilities/Sort.utility';
import * as moment from 'moment';

type Sage50FileNames = ( 'sage50_import' | 'sage50_import_summary' | 'excluded_invoices_summary' );
type ExportType = ('Automatic' | 'Manual');

@Component({
  selector: 'app-sage50-exports',
  templateUrl: './sage50-exports.component.html',
  styleUrls: ['./sage50-exports.component.scss']
})
export class Sage50ExportsComponent implements OnInit {

  loading = true;
  exporting = false;
  downloading = false;
  isDateRangeAuto = true;
  startDate: Date = null;
  endDate: Date = null;
  exports: Export[] = [];
  isIntegrationEnabled = false;

  exportTableColumns = ['info', 'export_type', 'count', 'exported_by', 'created_at', 'sage50_import', 'sage50_import_summary', 'excluded_invoices_summary'];

  constructor(
    private dialogNotifications: DialogNotificationService,
  ) { }

  async ngOnInit(): Promise<void>
  {
    this.loading = true;
    await this._setIsIntegrationEnabled();

    if (this.isIntegrationEnabled)
    {
      await this._getExports();
      this._sortItems();
    }

    this.loading = false;
  }

  protected async _setIsIntegrationEnabled(): Promise<void>
  {
    const integration = await Integration.getBySlug(Integration.SAGE_50);
    this.isIntegrationEnabled = integration.enabled;
  } 

  protected async _getExports(): Promise<void>
  {
    this.exports = await (new ExportCollection()).all();
  }

  async create(): Promise<void>
  {
    this.exporting = true;

    let params = {
      integration_slug: 'sage50',
      is_manual: !this.isDateRangeAuto,
      start_date: this.startDate ? moment(this.startDate).unix() : null,
      end_date: this.endDate ? moment(this.endDate).unix() : null
    };

    try
    {
      const response = await Export.create(params);

      if (response === false)
      {
        const customTitle = 'Export not created';
        const customMessage = this.isDateRangeAuto ? 'No new invoices have been created since previous export.' : 'No invoices were issued in the given date range.';
        await this.dialogNotifications.showNotice(customTitle, customMessage);
        this.exporting = false;
      }
      else
      {
        await this._getExports();
      }
    }
    finally
    {
      this.exporting = false;
    }
  }

  showDownload(xport: Export, attachmentType: Sage50FileNames, extention: ".CSV" | ".IMP"): boolean
  {
    return !!xport.attachments.find( attachment =>  attachment.filename.includes(extention) && attachment.filename.includes(attachmentType));
  }

  download(xport: Export, attachmentType: Sage50FileNames, extention: ".CSV" | ".IMP"): void
  {
    this.downloading = true;
    const attachment = xport.attachments.find( attachment => 
      {
        return (attachment.filename.includes(extention) && attachment.filename.includes(attachmentType))
      });
    attachment.download();
    this.downloading = false;
  }

  exportType(xport: Export): ExportType
  {
    return xport.is_manual? 'Manual' : 'Automatic';
  }
  
  onDateRangeManualChange(): void 
  {
    if (this.isDateRangeAuto)
    {
      this.startDate = null;
    }
  }

  discard()
  {
    // TODO: implement. Jira task BL-512.
  }

  protected _sortItems(): void
  {
    this.exports.sort(orderBy('-created_at'));
  }
}
