import { BaseModel } from "./Base.model";
import { Thumbnail } from "./Thumbnail.model";
import { ResourceItem } from "./contracts/ResourceItem.interface";
import { dto } from "./mixins/Dto.decorators";
import { HasIcon } from "./mixins/HasIcon.mixin";
import { HasItemLevel } from "./mixins/HasItemLevel.mixin";

const MixinBase = HasIcon(
    HasItemLevel( BaseModel )
);

export class RentalResource extends MixinBase implements ResourceItem {
    constructor(attributes: object = {} ) {
        super();
        this.init(attributes);
    }
    @dto() id: string = null;
    @dto() name: string = null;
    @dto() code: string = null;
    @dto() description: string = null;
    @dto() prefix: string = null;
    @dto(Thumbnail) thumbnail?: Thumbnail = null;
}
